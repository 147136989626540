<div class="container w-100 custom-height overflow-auto" style="background-color: transparent;">
    <iframe allow="camera; microphone;screenobtainer; obtainscreenfromgetdisplaymedia;clipboard-read; clipboard-write" #iframeRef [src]=src id="integration-widget-value" width="100%" height="100%" style="min-height: 90vh;"
        [ngClass]="{'d-none': !iframeLoaded}"></iframe>
</div>
<div class="container w-100 custom-height d-none"><div class="container w-100 custom-height">
    <mat-card class="mt-3 border-radius-8">
        <mat-card-header>
            <div class="d-flex align-items-center">
                <img src="../../../../assets/images/zapier.png" alt="gmail_icon" width="26px"
                    height="24px" class="me-3">
                <h5 class="mb-0 text-primary d-flex align-items-center">Connect Zapier integrations</h5>
            </div>
        </mat-card-header>
        <mat-card-content class="p-4">
            <p class="">Connect Zapier with any of your favorite apps in just a few clicks. Design, build,
                and automate anything for your work by integrating apps like Zapier to create visual
                automated workflows. Choose from thousands of ready-made apps or use our no-code toolkit to
                connect to apps not yet in our library.
            </p>
        </mat-card-content>
        <mat-card-actions class="d-flex align-items-center justify-content-end px-4">
            <button class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2"
                (click)="openZapier()">Connect</button>
        </mat-card-actions>
    </mat-card>

    <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom bg-white w-100 mt-0 me-3 d-none"
        (navChange)="tabChange($event)">
        <li [ngbNavItem]="1" class="me-2">
            <a ngbNavLink class="d-flex">
                <span class="d-sm-block font-size-14">Integrations</span>
            </a>
            <ng-template ngbNavContent>
                <!-- <ng-container *ngIf="loggedInUser.data.role === 'ADMIN'" class="d-none">
                    <mat-card class="border-radius-8 mt-3">
                        <mat-card-header>
                            <div class="d-flex align-items-center">
                                <img src="../../../../assets/images/zoom_logo.svg" alt="zoom_icon" width="24px"
                                    height="24px" class="me-3">
                                <h5 class="mb-0 text-primary d-flex align-items-center">Zoom <span
                                        class="ms-1 mt-1 p-1 border-radius-4 font-size-10"
                                        style="background-color: rgb(153, 255, 153, 0.4);"
                                        *ngIf="zoom_form.value.id">Connected</span></h5>
                            </div>
                        </mat-card-header>
                        <mat-card-content class="p-4">
                            <p class="">At the moment of booking, automatically create Zoom meetings for the linked
                                account.A booking confirmation email with the Zoom meeting details will be sent to both
                                the customer and employee.
                            </p>
                        </mat-card-content>
                        <mat-card-actions class="d-flex align-items-center justify-content-end px-4">
                            <button class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 px-4 me-2"
                                (click)="openZoomModal(zoom)" *ngIf="!zoom_form.value.id">Connect</button>
                            <button class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 px-4 me-2"
                                (click)="openZoomModal(zoom)" *ngIf="zoom_form.value.id">Update</button>
                            <button class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 px-4 me-2"
                                (click)="zoomDelete()" *ngIf="zoom_form.value.id">Disconnect</button>
                        </mat-card-actions>
                    </mat-card>
                </ng-container> -->

                <mat-card class="mt-3 border-radius-8">
                    <mat-card-header>
                        <div class="d-flex align-items-center">
                            <img src="../../../../assets/images/google-gmail.png" alt="gmail_icon" width="26px"
                                height="24px" class="me-3">
                            <h5 class="mb-0 text-primary d-flex align-items-center">Google Gmail &nbsp;
                                <span class="mt-1 p-1 border-radius-4 font-size-10"
                                    style="background-color: rgb(153, 255, 153, 0.4);" *ngIf="googleConnected">Connected
                                    <small class="font-size-12"
                                        *ngIf="connectedGmail">({{connectedGmail}})</small></span>
                                <span class="font-size-12 text-center text-black"
                                    *ngIf="outlookData?.is_connected">(You've Connected to
                                    Outlook, disconnect outlook to connect Google)</span>
                            </h5>
                        </div>
                    </mat-card-header>
                    <mat-card-content class="p-4">
                        <p class="">Link your gmail account and streamline all your communications. You can compose,
                            send, receive your emails by connecting your gmail with Brexa which enhances your
                            productivity and user connectivity.
                        </p>
                    </mat-card-content>
                    <mat-card-actions class="d-flex align-items-center justify-content-end px-4">
                        <button (click)="connectTogmail()" [disabled]="googleConnected || outlookData?.is_connected"
                            [matTooltip]="googleConnected || outlookData?.is_connected ? 'You have Connected to Outlook' : ''"
                            class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2">{{googleConnected
                            ? 'Connected' : 'Connect'}}</button>
                        <button (click)="disconnectgoogler('gmail')" *ngIf="googleConnected"
                            class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2">Disconnect</button>
                    </mat-card-actions>
                </mat-card>
                <mat-card class="mt-3 border-radius-8">
                    <mat-card-header>
                        <div class="d-flex align-items-center">
                            <img src="../../../../assets/images/Google_Calendar_icon.png" alt="gmail_icon" width="26px"
                                height="24px" class="me-3">
                            <h5 class="mb-0 text-primary d-flex align-items-center">Google Calendar &nbsp;
                                <span class="mt-1 p-1 border-radius-4 font-size-10"
                                    style="background-color: rgb(153, 255, 153, 0.4);"
                                    *ngIf="googleCalendarConnected">Connected
                                    <small class="font-size-12" *ngIf="connectedCalendarmail">({{connectedCalendarmail}})</small>
                                </span>
                                <span class="font-size-12 text-center text-black"
                                    *ngIf="outlookData?.is_connected">(You've Connected to
                                    Outlook, disconnect outlook to connect Google)</span>
                            </h5>
                        </div>
                    </mat-card-header>
                    <mat-card-content class="p-4">
                        <p class="">Connecting your google calendar with Brexa helps you in efficient scheduling of
                            appointments/ meetings and in event management. It facilitates automated reminders and event
                            updates which helps in time management
                        </p>
                    </mat-card-content>
                    <mat-card-actions class="d-flex align-items-center justify-content-end px-4">
                        <button (click)="connectToCalender()"
                            [disabled]="googleCalendarConnected || outlookData?.is_connected"
                            [matTooltip]="googleCalendarConnected || outlookData?.is_connected ? 'You have Connected to Outlook' : ''"
                            class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2">{{googleCalendarConnected
                            ? 'Connected' : 'Connect'}}</button>
                        <button (click)="disconnectgoogler('calendar')" *ngIf="googleCalendarConnected"
                            class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2">Disconnect</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mt-3 border-radius-8">
                    <mat-card-header>
                        <div class="d-flex align-items-center">
                            <img src="../../../../assets/images/outlook-calendar.svg" alt="gmail_icon" width="26px"
                                height="24px" class="me-3">
                            <h5 class="mb-0 text-primary d-flex align-items-center">Outlook Mail & Calendar &nbsp;
                                <span class="mt-1 p-1 border-radius-4 font-size-10"
                                    style="background-color: rgb(153, 255, 153, 0.4);"
                                    *ngIf="outlookData?.is_connected">Connected</span>
                                <span class="font-size-12 text-center"
                                    *ngIf="googleConnected || googleCalendarConnected">(You've Connected to
                                    Google,
                                    disconnect google to connect Outlook)</span>
                            </h5>
                        </div>
                    </mat-card-header>
                    <mat-card-content class="p-4">
                        <p class="">Create appointments in Brexa and add booked appointments directly to your
                            Outlook
                            Calendar.
                        </p>
                    </mat-card-content>
                    <mat-card-actions class="d-flex align-items-center justify-content-end px-4">
                        <button (click)="connecttoOutlook('connect')"
                            [disabled]="googleConnected || googleCalendarConnected || outlookData?.is_connected"
                            class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2">{{!outlookData
                            || !outlookData?.is_connected ?
                            'Connect' : 'Connected'}}</button>
                        <button class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2"
                            (click)="disconnectfromOutlook()" *ngIf="outlookData?.is_connected">Disconnect</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="my-3 border-radius-8 d-none">
                    <mat-card-header>
                        <div class="d-flex align-items-center">
                            <img src="../../../../assets/images/microsoft-outlook.svg" alt="gmail_icon" width="26px"
                                height="24px" class="me-3">
                            <h5 class="mb-0 text-primary">Microsoft Email</h5>
                        </div>
                    </mat-card-header>
                    <mat-card-content class="p-4">
                        <p class="">Store and sync emails from Microsoft email directly in Brexa contact records to
                            view
                            a history of your correspondence.
                        </p>
                    </mat-card-content>
                    <mat-card-actions class="d-flex align-items-center justify-content-end px-4">
                        <button
                            class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2">Coming
                            soon</button>
                    </mat-card-actions>
                </mat-card>
            </ng-template>
        </li>
        <li [ngbNavItem]="2" class="me-2">
            <a ngbNavLink class="d-flex">
                <span class="d-sm-block font-size-14">Mail Domain</span>
            </a>
            <ng-template ngbNavContent *ngIf="domainTemplate">
                <div class="d-flex align-items-center my-3"
                    [ngClass]="domainsArray && domainsArray.length > 0 ? 'justify-content-between' : 'justify-content-end'">
                    <p *ngIf="domainsArray && domainsArray.length > 0 && unVerifiedMails" class="m-0"><b
                            style="color: black;">Note: </b>Verify your domain in 7 days to avoid it being
                        deleted.<b style="color: black;">Need help?</b> Contact us at <b style="color: black;"><a
                                href="mailto:support@brexa.ai" class="text-decoration-none"
                                target="_blank">support@brexa.ai</a></b></p>
                    <button class="btn btn-primary" (click)="addDomain(domainCreation)">Add Domain</button>
                </div>
                <div class="table-responsive table-height fixed-table" *ngIf="domainsArray.length > 0">
                    <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100 mt-2">
                        <thead>
                            <tr class="border-top-table bg-white" style="z-index: 999;">
                                <th scope="col" *ngFor="let col of filtersLables"
                                    class="text-primary font-size-14 pb-3 table-sticky-header bg-white"
                                    style="z-index: 999;">
                                    {{col}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of domainsArray; let i = index"
                                [ngClass]="item.status === 'unverified' ? '' : 'cursor'"
                                (click)="openDomainEmails(item)">
                                <td>
                                    {{ item?.domain ? item.domain : "NA" }}
                                </td>
                                <td>{{ item?.status ? item.status : "NA" }}
                                    <button type="button" class="btn btn-primary ms-2" (click)="verifyDomains(item?.id)"
                                        *ngIf="item.status === 'unverified'">Verify</button>
                                </td>
                                <td><mat-icon *ngIf="item.status === 'unverified'"
                                        (click)="openInstructions(instructions,item)"
                                        class="me-2 cursor">info</mat-icon>
                                    <mat-icon (click)="onDeleteDomain($event,item?.id)" class="cursor">delete</mat-icon>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex align-items-center justify-content-center no-data-found mt-5 pt-5"
                    *ngIf="noDomainFound">
                    <div class="text-center">
                        <img class="border-radius-8" src="../../../../assets/images/nodata_found.svg" height="160px"
                            width="160px">
                        <h4 class="mt-4">
                            No Data Available
                        </h4>
                    </div>
                </div>
                <!-- <div>
                    <mat-paginator [length]="leadsObj?.total || leadsObj?.count" [pageSize]="page_size"
                        [pageIndex]="pageIndex" (page)="page($event, 'ACTIVE')" aria-label="Select page"
                        hidePageSize="true" showFirstLastButtons>
                    </mat-paginator>
                </div> -->
            </ng-template>
            <ng-template ngbNavContent *ngIf="!domainTemplate">
                <div class="my-3">
                    <div class="d-flex text-light">
                        <label class="cursor" (click)="domainTemplateChange(true)">{{selectedDomain ?
                            selectedDomain?.domain : 'Domain'}}</label> &nbsp;>&nbsp;
                        <label class="d-flex align-items-center">Emails</label>
                    </div>

                    <div class="d-flex justify-content-between my-3">
                        <mat-form-field appearance="outline" class="me-3 mat-hint-none">
                            <input style="outline: none" matInput placeholder="Search" [(ngModel)]="search"
                                (ngModelChange)="searchName($event)" />
                            <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
                  alt=""></mat-icon>
                        </mat-form-field>
                        <button class="btn btn-primary" (click)="addEmailsToDomain(emailCreation)">Add Emails</button>
                    </div>
                    <div class="table-responsive table-height fixed-table">
                        <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100 mt-2">
                            <thead>
                                <tr class="border-top-table bg-white" style="z-index: 999;">
                                    <th scope="col" class="text-primary font-size-14 pb-3 table-sticky-header bg-white"
                                        style="z-index: 999;"> S.No</th>
                                    <th scope="col" class="text-primary font-size-14 pb-3 table-sticky-header bg-white"
                                        style="z-index: 999;"> Name</th>
                                    <th scope="col" class="text-primary font-size-14 pb-3 table-sticky-header bg-white"
                                        style="z-index: 999;"> Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="cursor" *ngFor="let item of mailsArray; let i = index">
                                    <td>{{i+1}}</td>
                                    <td>
                                        {{ item?.name ? item.name : "NA" }}
                                    </td>
                                    <td>{{ item?.email ? item.email : "NA" }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="customNav" class="d-none"></div>
</div>

<ng-template #zoom let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-primary">
            Zoom Integrations
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="zoom_form" (ngSubmit)="onSubmit()">
            <div class="row mt-3">
                <div class="col-12">
                    <label class="fw-600 font-size-14 mb-2">Zoom API Key <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="" formControlName="api_key" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['api_key'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['api_key'].errors['required']">Api key is required</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label class="fw-600 font-size-14 mb-2">Zoom Secret Key <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="{{zoomSecretKey || ''}}"
                            formControlName="secret_key" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['secret_key'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['secret_key'].errors['required']">Secret key is required</span>

                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <button type="submit" class="btn btn-primary mt-4" *ngIf="zoom_form.value.id">Update</button>
                <button type="submit" class="btn btn-primary mt-4" *ngIf="!zoom_form.value.id">Add</button>
            </div>
        </form>
    </div>
</ng-template>


<ng-template #domainCreation let-modal>
    <form [formGroup]="domainForm" (ngSubmit)="submitDomain()">
        <div class="modal-header">
            <h5 class="modal-title mt-0">Add New Domain</h5>
            <button class="btn-close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" formControlName="domain" matInput placeholder="Domain Name" type="text"
                    autofocus="autofocus" />
                <mat-error class="error text-danger d-flex mt-1" *ngIf="domainSubmitted && dForm['domain'].errors">
                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                    <span *ngIf="dForm['domain'].errors['required']">Domain is required</span>
                    <span *ngIf="dForm['domain'].errors['pattern']">Invalid Domain name</span>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="modal-footer">
            <div class="text-end">
                <button class="btn btn-light me-3" (click)="modal.dismiss('Cross click')">
                    Close
                </button>
                <button class="btn btn-primary" type="submit">Save</button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #instructions let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Follow these steps to verify your domain</h5>
        <button class="btn-close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <h6>Text record</h6>
        <div class="table-responsive table-height mb-5">
            <table class="table align-middle table-nowrap table-hover mb-0 file-table mt-2">
                <thead>
                    <tr class="border-top-table bg-white" style="z-index: 999;">
                        <th scope="col" class="text-primary font-size-14 pb-3 table-sticky-header bg-white">
                            Status</th>
                        <th scope="col" class="text-primary font-size-14 pb-3 table-sticky-header bg-white">
                            Hostname</th>
                        <th scope="col" class="text-primary font-size-14 pb-3 table-sticky-header bg-white">
                            Enter this value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let records of selectedDomain.txt_records; let i = index">
                        <td>
                            {{ records?.valid === 'unknown' ? 'Unverified' : "Verified" }}
                        </td>
                        <td>{{ records?.name ? records.name : "NA" }}</td>
                        <td>
                            <div class="d-flex align-items-center">
                                <label class="text-break w-90">{{ records?.value ? records.value : "NA" }}</label>
                                <mat-icon class="cursor font-size-14 d-flex align-items-center ms-2"
                                    matTooltip="Copy to clipboard" matTooltipPosition="above"
                                    [cdkCopyToClipboard]="records?.value" #tp="matTooltip">content_copy</mat-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h6>CNAME</h6>
        <div class="table-responsive table-height">
            <table class="table align-middle table-nowrap table-hover mb-0 file-table mt-2">
                <thead>
                    <tr class="border-top-table bg-white" style="z-index: 999;">
                        <th scope="col" class="text-primary font-size-14 pb-3 table-sticky-header bg-white">
                            Status</th>
                        <th scope="col" class="text-primary font-size-14 pb-3 table-sticky-header bg-white">
                            Hostname</th>
                        <th scope="col" class="text-primary font-size-14 pb-3 table-sticky-header bg-white">
                            Enter this value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{ selectedDomain.cname_records?.valid === 'unknown' ? 'Unverified' : "Verified" }}
                        </td>
                        <td>{{ selectedDomain.cname_records?.name ? selectedDomain.cname_records.name : "NA" }}</td>
                        <td class="d-flex align-items-center">
                            <label class="text-break w-90">{{ selectedDomain.cname_records?.value ?
                                selectedDomain.cname_records.value : "NA" }}</label>
                            <mat-icon class="cursor font-size-14 d-flex align-items-center ms-2"
                                matTooltip="Copy to clipboard" matTooltipPosition="above"
                                [cdkCopyToClipboard]="selectedDomain.cname_records?.value"
                                #tp="matTooltip">content_copy</mat-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="p-2 mt-3 rounded" style="background-color: #bf6565;">Sometimes it takes up to 36 hours for the
                domain to be verified.</p>
        </div>

    </div>
    <div class="modal-footer">
        <div class="text-end">
            <button class="btn btn-light me-3" (click)="modal.dismiss('Cross click')">
                Close
            </button>
        </div>
    </div>
</ng-template>

<ng-template #emailCreation let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Add New Emails</h5>
        <button class="btn-close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <!-- For Multiple emails adding -->
        <!-- <label class="mb-2 fw-600">Emails</label> -->
        <!-- <ng-select class="form-control" [items]="[]" bindLabel="name" [addTag]="true"
            [multiple]="true" placeholder='Select ' [selectOnTab]="true"
            [isOpen]="false" [(ngModel)]="selected_group">
        </ng-select> -->
        <form [formGroup]="email_form">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">Name <small class="text-danger">*</small></label>

                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder='Enter name' formControlName="name"
                            #myFirstInput />
                        <mat-error class="error text-danger d-flex" *ngIf="isSubmitted && emailForm['name'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="emailForm['name'].errors['required']">Name
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">Email <small class="text-danger">*</small></label>
                    <div class="d-flex">
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder='Enter email' formControlName="email" />
                            <mat-error class="error text-danger d-flex"
                                *ngIf="isSubmitted && emailForm['email'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span *ngIf="emailForm['email'].errors['required']">Email {{errorjson.required}}</span>
                                <span *ngIf="emailForm['email'].errors['pattern']">Invalid Email</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput formControlName="email_extension" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="map_staff_email" placeholder="Select Staff">
                            <mat-option *ngFor="let item of staff_list" [value]="item.email">
                                {{item.fname}} {{item?.lname}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <div class="modal-footer">
            <div class="text-end">
                <button class="btn btn-light me-3" (click)="modal.dismiss('Cross click')">
                    Close
                </button>
                <button class="btn btn-primary" (click)="addEmails()">Save</button>
            </div>
        </div>
    </div>
</ng-template>
<div class="container-fluid">
    <div class="row px-2 profile-div-height pb-3" *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="col-md-12">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId"
                class="nav-tabs nav-tabs-custom bg-white w-100 mt-0 me-3" (navChange)="tabChange($event)">
                <li [ngbNavItem]="1" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">General</span>
                    </a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="generalSettingsForm" (ngSubmit)="onGeneralSettingsDetailsSubmit()">
                            <div class="row mt-3">
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.country}}</label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='Select {{companysettings.country}}'
                                            formControlName="country">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                    placeholderLabel="Search"
                                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of filteredBanks | async"
                                                value={{country.Entity}}>{{country.Entity}}</mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submited && f['country'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span *ngIf="f['country'].errors['required']">{{companysettings.country}}
                                                {{errorjson.required}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.timezone}}</label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='Select {{companysettings.timezone}}'
                                            formControlName="timezone">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="bankFilterTimezoneCtrl"
                                                    placeholderLabel="Search"
                                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-optgroup *ngFor="let time_zone of filteredTimezoneBanks | async"
                                                [label]="time_zone.name">
                                                <mat-option class="mb-2 mat-hint-none"
                                                    *ngFor="let countries of time_zone.countries"
                                                    value="{{countries.timezone}}">{{countries.name}}</mat-option>
                                            </mat-optgroup>
                                            <!-- <mat-option *ngFor="let time_zone of filteredTimezoneBanks | async"
                                                value="{{time_zone.utc[0]}}">{{time_zone.text}}</mat-option> -->
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submited && f['timezone'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span *ngIf="f['timezone'].errors['required']">{{companysettings.timezone}}
                                                {{errorjson.required}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.ccode}}</label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='{{companysettings.ccode}}' formControlName="ccode">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                    placeholderLabel="Search"
                                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of filteredBanks | async"
                                                [ngClass]="{'d-none': country.IAC === ''}"
                                                value={{country.IAC}}>{{country.IAC + " - " + country.Entity
                                                }}</mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submited && f['ccode'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span *ngIf="f['ccode'].errors['required']">{{companysettings.ccode}}
                                                {{errorjson.required}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.dateformat}}</label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='Select {{companysettings.dateformat}}'
                                            formControlName="dateformat">
                                            <mat-option value="MMMM dd YYYY">MMMM DD, YYYY</mat-option>
                                            <mat-option value="MMM dd,YYYY">MMM DD, YYYY</mat-option>
                                            <mat-option value="YYYY-MM-dd">YYYY-MM-DD</mat-option>
                                            <mat-option value="MM/dd/YYYY">MM/DD/YYYY</mat-option>
                                            <mat-option value="dd/MM/YYYY">DD/MM/YYYY</mat-option>
                                            <mat-option value="YYYY/MM/dd">YYYY/MM/DD</mat-option>
                                            <mat-option value="dd.MM.YYYY">DD.MM.YYYY</mat-option>
                                            <mat-option value="MM.dd.YYYY">MM.DD.YYYY</mat-option>
                                            <mat-option value="dd MMM YYYY">DD MMM YYYY</mat-option>
                                            <mat-option value="dd MMMM YYYY">DD MMMM YYYY</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!-- <div class="col-sm-12 col-md-6 d-none">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.timeformat}}</label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='Select {{companysettings.timeformat}}'
                                            formControlName="timeformat">
                                            <mat-option value="H:MM A">12h</mat-option>
                                            <mat-option value="H:MM">24h</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div> -->
                                <div class="row mt-3">
                                    <label class="fw-600 mb-3">{{companysettings.booking_settings}}</label>
                                    <div class="col-sm-12 col-md-6">
                                        <label
                                            class="fw-600 font-size-14 mb-2">{{companysettings.slot_duration}}</label>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-select placeholder='{{companysettings.slot}}'
                                                formControlName="slot_duration">
                                                <mat-option value="15">15 MIN </mat-option>
                                                <mat-option value="30">30 MIN </mat-option>
                                                <mat-option value="45">45 MIN </mat-option>
                                                <mat-option value="60">60 MIN </mat-option>
                                            </mat-select>
                                            <mat-error class="error text-danger d-flex"
                                                *ngIf="submited && f['slot_duration'].errors">
                                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                                <span
                                                    *ngIf="f['slot_duration'].errors['required']">{{companysettings.slot}}
                                                    {{errorjson.required}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <!-- <div class="row mt-3">
                                    <label class="fw-600 mb-3">{{companysettings.terms_and_conditions}}</label>
                                    <div class="col-sm-12 col-md-12 ">
                                        <ckeditor [editor]="Editor" formControlName="terms_and_conditions"
                                            [config]="ckconfigQue"></ckeditor>

                                    </div>
                                </div> -->
                                <div class="mt-3 text-end">
                                    <button class="btn btn-light me-3" type="button"
                                        *ngIf="activatedRouter === '/onboarding'" (click)="onActiveChange(2)">
                                        Skip
                                    </button>
                                    <button class="btn btn-primary" type="submit">
                                        {{ activatedRouter === '/settings' ? companysettings.save_btn :
                                        companysettings.save_proceed_btn}}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">{{'Organisation'}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="companySettingsForm" (ngSubmit)="onCompanyDetailsSubmit()">
                            <div class="row mt-3 company-setting">
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.org_name}} <small
                                            class="text-danger">*</small></label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <input style="outline: none" matInput placeholder='{{companysettings.org_name}}'
                                            formControlName="name" #myFirstInput autofocus="autofocus" />
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isCompanySubmitted && companyForm['name'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span
                                                *ngIf="companyForm['name'].errors['required']">{{companysettings.org_name}}
                                                {{errorjson.required}}</span>
                                            <span *ngIf="companyForm['name'].errors['pattern']">{{errorjson.invalid}}
                                                {{companysettings.org_name}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.org_email}} <small
                                            class="text-danger">*</small></label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <input style="outline: none" matInput
                                            placeholder='{{companysettings.org_email}}' formControlName="email" />
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isCompanySubmitted && companyForm['email'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span
                                                *ngIf="companyForm['email'].errors['required']">{{companysettings.org_email}}
                                                {{errorjson.required}}</span>
                                            <span *ngIf="companyForm['email'].errors['pattern']">{{errorjson.invalid}}
                                                {{companysettings.org_email}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.org_domain}} <small
                                            class="text-danger">*</small>
                                    </label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <input style="outline: none" matInput (focusout)="getCompanyDetailsBylinkedIn()"
                                            placeholder='{{companysettings.org_domain}}' formControlName="website" />
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isCompanySubmitted && companyForm['website'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span
                                                *ngIf="companyForm['website'].errors['required']">{{companysettings.org_domain}}
                                                {{errorjson.required}}</span>
                                            <span *ngIf="companyForm['website'].errors['pattern']">{{errorjson.invalid}}
                                                {{companysettings.org_domain}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.language}}</label>
                                    <mat-form-field appearance="outline" class="w-100 text-capitalize disable">
                                        <!-- <mat-select placeholder='Select {{companysettings.language}}'
                                            formControlName="language">
                                            <mat-option value="english">English</mat-option>
                                        </mat-select> -->
                                        <input style="outline: none" matInput formControlName="language"
                                            placeholder='{{companysettings.language}}' />
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6 d-none">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.industry_type}}</label>
                                    <mat-form-field appearance="outline" class="w-100 mat-hint-none mb-2">
                                        <input style="outline: none" matInput formControlName="industry_type"
                                            placeholder='{{companysettings.industry_type}}' />
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6 d-none">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.business_type}}</label>
                                    <mat-form-field appearance="outline" class="w-100 mat-hint-none mb-2">
                                        <input style="outline: none" matInput formControlName="business_type"
                                            placeholder='{{companysettings.business_type}}' />
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.dsp}}</label>
                                    <ckeditor [editor]="Editor" placeholder="Description"
                                        [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'ck-error':''"
                                        #editorComponent formControlName="description" [config]="ckconfigQue"
                                        class="ckeditor-height" (ready)="onReady($event,editorComponent)"
                                        (keyup)="editorkeyEvent(editorComponent)"></ckeditor>
                                    <small
                                        [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                                        class="font-size-10">Maximum 1000 characters are accepted.</small><small
                                        [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                                        class="font-size-10 ps-1">(Character limit:
                                        {{ckeditorLimit.actualLimit}}/{{ckeditorLimit.maxLimit}})</small>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.social_links}}</label>
                                    <mat-form-field appearance="outline" class="w-100 mat-hint-none mb-2">
                                        <input style="outline: none" matInput formControlName="instagram_url"
                                            placeholder='{{companysettings.social_links_insta}}' />
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-100 mat-hint-none mb-2">
                                        <input style="outline: none" matInput formControlName="twitter_url"
                                            placeholder='{{companysettings.social_links_twitter}}' />
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-100 mat-hint-none mb-2">
                                        <input style="outline: none" matInput formControlName="linkedin_url"
                                            placeholder='{{companysettings.social_links_linkedin}}' />
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <input style="outline: none" matInput
                                            placeholder='{{companysettings.social_links_utube}}' />
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.customer_primary_login}} <small
                                        class="text-danger">*</small></label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='{{companysettings.customer_primary_login}}'
                                            formControlName="customer_primary_login_type">
                                            <mat-option value="email">Email</mat-option>
                                            <mat-option value="phone_number">Phone Number</mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isCompanySubmitted && companyForm['customer_primary_login_type'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span *ngIf="companyForm['customer_primary_login_type'].errors['required']">{{companysettings.customer_primary_login}}
                                                {{errorjson.required}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.gst_number}}</label>
                                    <mat-form-field appearance="outline" class="w-100 mat-hint-none mb-2">
                                        <input style="outline: none" matInput formControlName="gst_number"
                                            placeholder='{{companysettings.gst_number}}' />
                                    </mat-form-field>
                                </div> -->
                                <div class="col-sm-12 col-md-6">
                                    <label
                                        class="fw-600 font-size-14 mb-2">{{companysettings.generate}}{{companysettings.business_url}}</label>
                                    <div
                                        class="bg-light border-radius-4 text-white d-flex align-items-center justify-content-between p-2">
                                        <p class="font-size-12 mb-0">{{companySettingsForm.value.slug}}</p>
                                        <!--Iframe URL-->
                                        <div class="">
                                            <!---Flutter URL-->
                                            <!-- <mat-icon class="cursor"
                                                (cdkCopyToClipboardCopied)="onClipboardCopy($event)"
                                                matTooltip="Copy to clipboard" matTooltipPosition="above"
                                                #tp="matTooltip"
                                                [cdkCopyToClipboard]="Iframe_url">content_copy</mat-icon> -->
                                            <!--Iframe URL-->
                                            <mat-icon class="cursor"
                                                (cdkCopyToClipboardCopied)="onClipboardCopy($event)"
                                                matTooltip="Copy to clipboard" matTooltipPosition="above"
                                                #tp="matTooltip"
                                                [cdkCopyToClipboard]="'https://'+companySettingsForm.value.slug+src">content_copy</mat-icon>
                                            <mat-icon class="ms-2 cursor"
                                                (click)="businessURLUpdate(true)">edit</mat-icon>
                                        </div>
                                    </div>
                                    <mat-form-field appearance="outline" class="w-100 mt-3" *ngIf="editBusinessUrl">
                                        <input style="outline: none" matInput
                                            placeholder='{{companysettings.business_url}}' formControlName="slug" />
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isCompanySubmitted && companyForm['slug'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span
                                                *ngIf="companyForm['slug'].errors['required']">{{companysettings.business_url}}
                                                {{errorjson.required}}</span>
                                            <span *ngIf="companyForm['slug'].errors['pattern']">
                                                {{errorjson.invalid}} {{companysettings.business_url}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <label class="ms-1 mb- text-secondary mb-2 font-size-12"
                                        *ngIf="editBusinessUrl">{{companysettings.bussinessValidationText}}</label>
                                </div>
                                <div class="col-sm-12 mt-4">
                                    <button class="btn btn-primary" type="button" (click)="addTaxes()">Add Tax</button>
                                    <div class="mt-4" formArrayName="tax">
                                        <div *ngFor="let item of taxes_array.controls; let i=index">
                                            <div class="row" [formGroupName]="i" [ngClass]="i >= 1 ? 'mt-4' : ''">
                                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.tax_name}}
                                                        <small class="text-danger">*</small> </label>
                                                    <mat-form-field appearance="outline"
                                                        class="w-100 mat-hint-none mb-2">
                                                        <input style="outline: none" matInput formControlName="label"
                                                            placeholder='{{companysettings.tax_name}}' />
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    <div class="d-flex justify-content-between">
                                                        <label
                                                            class="fw-600 font-size-14 mb-2">{{companysettings.tax_value}}
                                                            <small class="text-danger">*</small>
                                                        </label>
                                                        <mat-icon class="font-size-14 cursor"
                                                            (click)="removeTax(i)">close</mat-icon>
                                                    </div>
                                                    <mat-form-field appearance="outline"
                                                        class="w-100 mat-hint-none mb-2">
                                                        <input style="outline: none" matInput formControlName="value"
                                                            placeholder='{{companysettings.tax_value}}' />
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.compony_logo}} <small
                                            class="text-danger">*</small></label>
                                    <div *ngIf="companySettingsForm.value.image" class="w-50">
                                        <img src="{{companySettingsForm.value.image}}" alt="" width="150px">
                                    </div>
                                    <ngx-dropzone accept="image/jpeg,image/jpg,image/png,image/gif"
                                        (change)="onSelect($event)" [multiple]="false" class="bg-gray-light"
                                        [ngClass]="{'mt-4': companySettingsForm.value.image}"><!-- style="height: 100px;"-->
                                        <ngx-dropzone-label>{{companysettings.dropzone_label_1}} <span
                                                class="text-primary fw-500">{{companysettings.dropzone_label_2}}</span>
                                            {{companysettings.dropzone_label_3}}
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)"
                                            ngProjectAs="ngx-dropzone-preview" *ngFor="let f of company_logo"
                                            [file]="f">
                                        </ngx-dropzone-image-preview>
                                    </ngx-dropzone>
                                    <p class="font-size-10 text-light p-1">Files of jpeg, jpg, png, gif formats with max
                                        of 15MB are allowed.</p>
                                    <div class="error d-flex mt-2" *ngIf="isCompanySubmitted && !this.fileName">
                                        <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                                        <span> Logo {{errorjson.required}}</span>
                                    </div>
                                </div>
                                <div class="mt-3 text-end">
                                    <button class="btn btn-light me-3" type="button"
                                        *ngIf="activatedRouter === '/onboarding'" (click)="onActiveChange(3)">
                                        Skip
                                    </button>
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit">
                                        {{activatedRouter === '/settings' ? companysettings.save_btn :
                                        companysettings.save_proceed_btn}}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Price</span>
                    </a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="priceSettingsForm" (ngSubmit)="onPriceDetailsSubmit()">
                            <div class="row mt-3">
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.currency}} </label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='Select {{companysettings.currency}}'
                                            formControlName="currency">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="bankFilterCurrencyCtrl"
                                                    placeholderLabel="Search"
                                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let currency of filteredCurrencyBanks | async"
                                                value='{{currency.abbreviation +", "+currency.symbol}}'
                                                (onSelectionChange)="onCurrencyChange(currency)">{{currency.currency
                                                + " (" + currency.abbreviation + "," + currency.symbol +
                                                ")"}}</mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isPriceSubmitted && priceForm['currency'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span
                                                *ngIf="priceForm['currency'].errors['required']">{{companysettings.currency}}
                                                {{errorjson.required}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.price_symbol_position}}
                                    </label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='Select {{companysettings.price_symbol_position}}'
                                            formControlName="price_symbol_position">
                                            <mat-option value="before">Before</mat-option>
                                            <mat-option value="after">After</mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isPriceSubmitted && priceForm['price_symbol_position'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span
                                                *ngIf="priceForm['price_symbol_position'].errors['required']">{{companysettings.price_symbol_position}}
                                                {{errorjson.required}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.decimals}} <small
                                            class="text-danger">*</small></label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <input style="outline: none" (keydown)="keyDownEvent($event,1)" type="number"
                                            (wheel)="$event.preventDefault()" matInput
                                            placeholder='{{companysettings.decimals}}' formControlName="no_of_decimals"
                                            min="0" max="9" />
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isPriceSubmitted && priceForm['no_of_decimals'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span
                                                *ngIf="priceForm['no_of_decimals'].errors['required']">{{companysettings.decimals}}
                                                {{errorjson.required}}</span>
                                            <span
                                                *ngIf="priceForm['no_of_decimals'].errors['min'] || priceForm['no_of_decimals'].errors['max']">{{companysettings.decimals}}
                                                must be having 0 to 9</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.cod}}
                                    </label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='Select {{companysettings.cod}}'
                                            formControlName="cod_status">
                                            <mat-option [value]=true>Yes</mat-option>
                                            <mat-option [value]=false>No</mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isPriceSubmitted && priceForm['cod_status'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span
                                                *ngIf="priceForm['cod_status'].errors['required']">{{companysettings.timezone}}
                                                {{errorjson.required}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="mt-3 text-end">
                                    <button class="btn btn-light me-3" type="button"
                                        *ngIf="activatedRouter === '/onboarding'" (click)="onActiveChange(4)">
                                        Skip
                                    </button>
                                    <button class="btn btn-primary" type="submit">
                                        {{ activatedRouter === '/settings' ? companysettings.save_btn :
                                        companysettings.save_proceed_btn}}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">{{locationsettings.heading}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            <label class="fw-600 font-size-14">{{locationsettings.branch_location}}</label>
                            <button class="btn btn-primary d-flex align-items-center me-3"
                                (click)="addLocation(locationModel)">
                                <mat-icon>add</mat-icon>{{locationsettings.add_btn}}
                            </button>
                        </div>
                        <div class="table-responsive table-height mt-4" style="white-space: normal;">
                            <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="3"
                                [fromLocation]="'LOCATION'"></app-table-skeleton>
                            <table *ngIf="!tableSkeletonLoader"
                                class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-primary pb-3">{{locationsettings.col_name}}</th>
                                        <th scope="col-3" class="text-primary pb-3" style="width: 50%;">
                                            {{locationsettings.col_address}}</th>
                                        <!-- <th scope="col" class="text-primary pb-3">{{locationsettings.col_status}}</th> -->
                                        <th scope="col" class="text-primary pb-3">{{locationsettings.col_action}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of branches; let i = index">
                                        <td class="text-capitalize">
                                            {{ item.name }}
                                        </td>
                                        <td>
                                            <p class="">{{ item.location }}</p>
                                        </td>
                                        <td>
                                            <div class="d-flex">
                                                <label class="switch me-3 cursor" *ngIf="!item.default">
                                                    <input type="checkbox" [(ngModel)]="item.status"
                                                        (change)="toggleVisibility(item)">
                                                    <span class="slider round"></span>
                                                </label>
                                                <img src="../../../assets/images/edit-board-icon.svg"
                                                    (click)="addLocation(locationModel,item)" class="cursor">
                                                <img _ngcontent-bie-c262="" class="ms-2 cursor"
                                                    (click)="confirm(item?.id)" *ngIf="!item.default"
                                                    src="../../../assets/images/delete-board-icon.svg">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex align-items-center justify-content-center no-data-found mt-2"
                                *ngIf="noDataFound">
                                <div class="text-center">
                                    <img class="border-radius-8" src="../../../../assets/images/nodata_found.svg"
                                        height="160px" width="160px">
                                    <h4 class="mt-4">
                                        No Data Available
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 text-end" *ngIf="activatedRouter === '/onboarding'">
                            <button class="btn btn-light me-3" type="button" (click)="onActiveChange(5)">
                                Skip
                            </button>
                            <button class="btn btn-primary" type="button" (click)="onActiveChange(5)">
                                {{locationsettings.proceed_btn}}
                            </button>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="5" class="me-2" *ngIf="activatedRouter !== '/onboarding'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Working Hours</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-work-hours></app-work-hours>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
    <ng-container *ngIf="loggedInUser.data.role !== 'ADMIN'">
        <div class="d-flex align-items-center justify-content-center">
            <h6 class="text-secondary">You are not authorized to view this.</h6>
        </div>
    </ng-container>
</div>

<ng-template #locationModel let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">
            <span>{{locationData.value.id ? locationsettings.update_btn : locationsettings.add_btn}}</span>
            {{locationsettings.add_location}}
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Close click')"></button>
    </div>
    <div class="modal-body">
        <form class="mt-3" [formGroup]="locationData">
            <div class="">
                <div class="row mt-3 d-flex align-items-center">
                    <div class="col-sm-12 col-md-12 text-end" *ngIf="locationData.value.id">
                        <label class="switch ms-3">
                            <input type="checkbox" [value]="locationData.value.status" formControlName="status">
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <label class="fw-600 font-size-14 mb-2">{{locationsettings.branch_location}} <small
                                class="text-danger">*</small></label>

                        <ng-select class="form-control" [items]="googleLocationList"
                            bindLabel="description" bindValue="description" [multiple]="false"
                            (search)="searchLocation.next($event)" placeholder='{{locationsettings.branch_location}}'
                            formControlName="location">
                        </ng-select>
                        <!-- <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder='{{locationsettings.branch_location}}'
                                id="pac-input-1" autocorrect="off" autocapitalize="off" spellcheck="off"
                                formControlName="location" (input)="initAutocomplete($event)" class="branch-loc"
                                #locationFirstElement />
                            <mat-error class="error text-danger d-flex"
                                *ngIf="locationSubmit && locationForm['location'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span
                                    *ngIf="locationForm['location'].errors['required']">{{locationsettings.branch_location}}
                                    {{errorjson.required}}</span>
                            </mat-error>
                        </mat-form-field> -->
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <label class="fw-600 font-size-14 mb-2">{{locationsettings.branch_name}} <small
                                class="text-danger">*</small></label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder='{{locationsettings.branch_name}}'
                                formControlName="name" />
                            <mat-error class="error text-danger d-flex"
                                *ngIf="locationSubmit && locationForm['name'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span *ngIf="locationForm['name'].errors['required']">{{locationsettings.branch_name}}
                                    {{errorjson.required}}</span>
                                <span *ngIf="locationForm['name'].errors['pattern']">{{locationsettings.branch_name}}
                                    {{errorjson.invalid}}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <label class="fw-600 font-size-14 mb-2">{{locationsettings.email_id}} <small
                                class="text-danger">*</small></label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder='{{locationsettings.email_id}}'
                                formControlName="email" />
                            <mat-error class="error text-danger d-flex"
                                *ngIf="locationSubmit && locationForm['email'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span *ngIf="locationForm['email'].errors['required']">{{locationsettings.email_id}}
                                    {{errorjson.required}}</span>
                                <span *ngIf="locationForm['email'].errors['pattern']">{{locationsettings.email_id}}
                                    {{errorjson.invalid}}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <label class="fw-600 font-size-14 mb-2">{{locationsettings.phone_number}} <small
                                class="text-danger">*</small></label>
                        <div class="d-flex">
                            <mat-form-field appearance="outline" class="me-2">
                                <mat-select formControlName="ccode"
                                    placeholder="{{locationsettings.enter_placeholder}} Country Code">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                            noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let country of filteredBanks | async"
                                        [ngClass]="{'d-none': country.IAC === ''}"
                                        value={{country.IAC}}>{{country.IAC}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" type="text" matInput
                                    placeholder='{{locationsettings.phone_number}}' formControlName="phone_number"
                                    (keydown)="keyDownEvent($event,12)" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="locationSubmit && locationForm['phone_number'].errors">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                    <span
                                        *ngIf="locationForm['phone_number'].errors['required']">{{locationsettings.phone_number}}
                                        {{errorjson.required}}</span>
                                    <span
                                        *ngIf="locationForm['phone_number'].errors['pattern'] || locationForm['phone_number'].errors['minlength'] || locationForm['phone_number'].errors['maxlength']">{{errorjson.invalid}}
                                        {{locationsettings.phone_number}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12" *ngIf="locationData.value.id">
                        <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                formControlName="default" />
                            <label class="ms-1 text-secondary font-size-12" for="remember-me">
                                {{locationsettings.check_box_default}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-light me-2" type="button" (click)="modal.dismiss('Cross click')">
                    {{locationsettings.close_btn}}
                </button>
                <button class="btn btn-primary" type="submit"
                    (click)="onLocationSubmit()">{{locationsettings.save_btn}}</button>
            </div>
        </form>
    </div>
</ng-template>
<div class="modal-header">
  <h5 *ngIf="!contact?.id" class="modal-title fw-600">Add Contact</h5>
  <h5 *ngIf="contact?.id" class="modal-title fw-600">Update Contact</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>

<div class="modal-body">
  <form [formGroup]="contactForm">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <label class="fw-600 mb-2 ">{{addcontact.label_first_name}} <small class="text-danger">*</small></label>
        <mat-form-field appearance="outline" class="w-100">
          <input style="outline: none" matInput placeholder="{{addcontact.label_first_name}}"
            formControlName="first_name" #myInput />
          <mat-error class="error text-danger d-flex" *ngIf="submitted && f['first_name'].errors">
            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
            <span *ngIf="f['first_name'].errors['required']"> {{addcontact.label_first_name}}
              {{errorJson.required}}</span>
            <span *ngIf="f['first_name'].errors['pattern']">Only alphanumeric are accepted.</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <label class="fw-600 mb-2 ">{{addcontact.label_last_name}}
          <!-- <small class="text-danger">*</small> -->
        </label>
        <mat-form-field appearance="outline" class="w-100">
          <input style="outline: none" matInput placeholder="{{addcontact.label_last_name}}"
            formControlName="last_name" />
          <mat-error class="error text-danger d-flex" *ngIf="submitted && f['last_name'].errors">
            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
            <!-- <span *ngIf="f['last_name'].errors['required']"> {{addcontact.label_last_name}}
              {{errorJson.required}}</span> -->
            <span *ngIf="f['last_name'].errors['pattern']">Invalid last name</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
        <label class="fw-600 mb-2 ">{{addcontact.label_email}} <small class="text-danger"
            *ngIf="hasRequiredValidator('email')">*</small></label>
        <mat-form-field appearance="outline" class="w-100">
          <input style="outline: none" matInput placeholder="{{addcontact.label_email}}" formControlName="email" />
          <mat-error class="error text-danger d-flex" *ngIf="submitted && f['email'].errors">
            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
            <span *ngIf="f['email'].errors['required']"> {{addcontact.label_email}} {{errorJson.required}}</span>
            <span *ngIf="f['email'].errors['pattern']">{{errorJson.invalid}} {{addcontact.label_email}}</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
        <label class="fw-600 mb-2 ">{{addcontact.label_phone}} <small class="text-danger"
            *ngIf="hasRequiredValidator('phone_number')">*</small></label>
        <div class="d-flex align-items-center">
          <mat-form-field appearance="outline" class="me-1">
            <mat-select placeholder="Country code" formControlName="ccode">
              <mat-option>
                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                  noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of filteredBanks | async" [ngClass]="{'d-none': country.IAC === ''}"
                value={{country.IAC}}>{{country.IAC}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
              placeholder="{{addcontact.label_phone}}" formControlName="phone_number" />
            <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phone_number'].errors">
              <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
              <span *ngIf="f['phone_number'].errors['required']"> {{addcontact.label_phone}}
                {{errorJson.required}}</span>
              <span
                *ngIf="f['phone_number'].errors['pattern'] || f['phone_number'].errors['minlength'] || f['phone_number'].errors['maxlength']">{{errorJson.invalid}}
                {{addcontact.label_phone}}</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
        <label class="fw-600 mb-2 ">Profile pic</label>
        <div class="on-delete-img-hover d-flex" *ngIf="contact?.id && contactForm.value.display_picture">
          <img src="{{contactForm.value.display_picture}}" alt="" width="150px" class="mb-2"
            (error)="onErrorLoadImage($event,presignedurl,contactForm.value.display_picture)">
            <mat-icon class="visiblity-on-hover-delete text-danger cursor" (click)="onRemoveImage()">
                delete
            </mat-icon>
        </div>
        <input type="file" style="outline: none" placeholder="Profile pic"
          (change)="onProfilePicUpload($event)" [accept]="'image/*'" />
        <div class="error d-flex align-items-center" *ngIf="submitted && f['display_picture'].errors">
          <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
          <span class="text-capitalize">Profile pic </span>&nbsp;{{errorJson.required}}
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2 d-none"
        *ngIf="featuresData['create_company'] && featuresData['company']">
        <!-- <label class="fw-600 mb-2 ">{{addcontact.label_company}}</label>
        <ng-select class="form-control" [items]="companiesListArray" bindLabel="name" bindValue="id"
          [addTag]="addNewCompanyName" [multiple]="false" (search)="searchSubject.next($event)"
          (change)="onSelectCompany($event)" (clear)="clearCompany($event)" placeholder="Company name"
          formControlName="company_id">
        </ng-select> -->
        <!-- <ng-select *ngIf="contactForm.value.id" class="form-control" [items]="companiesListArray" bindLabel="name"
          bindValue="id" [multiple]="false" (search)="searchCompany($event)" (change)="onSelectCompany($event)"
          placeholder="Company name" formControlName="company_id">
        </ng-select> -->
        <!-- <mat-form-field appearance="outline" class="w-100" *ngIf="contactForm.value.id">
          <mat-select formControlName="company_id" placeholder="{{addcontact.label_company}}">
            <mat-option *ngFor="let company of companiesListArray" [value]="company.id">{{company.name}}</mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
        <label class="fw-600 mb-2 ">{{addcontact.label_tag}}</label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="tags" placeholder="{{addcontact.label_tag}}">
            <mat-option [value]="item.id" *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">

        <label class="fw-600 mb-2 ">{{addcontact.Source}}</label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="channel" placeholder={{addcontact.Source}}>
            <mat-option *ngFor="let item of channels" [value]="item.value">{{item.display_name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <label class="fw-600 mb-2 ">{{addcontact.owner}} <small class="text-danger">*</small></label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="owner" placeholder={{addcontact.owner}}>
            <mat-option *ngFor="let staff of staffMembers" (click)="onOwnerSelect(staff)"
              [value]="staff.id">{{staff.fname}}
              {{staff.lname}}</mat-option>
          </mat-select>
          <mat-error class="error text-danger d-flex" *ngIf="submitted && f['owner'].errors">
            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
            <span *ngIf="f['owner'].errors['required']">
              {{addcontact.owner}} {{errorJson.required}}
            </span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <label class="fw-600 mb-2 ">Assignee</label>
        <ng-select class="form-control"
          [ngClass]="{'disabled-field': contact?.owner && (contact.owner !== loggedInUser.data.id && loggedInUser.data.role !== 'ADMIN')}"
          [items]="staffMembers" bindValue="id" bindLabel="fname" [addTag]="false" [multiple]="true"
          placeholder="Select staff" formControlName="assignee">
        </ng-select>
        <!-- <div class="error d-flex mt-2" *ngIf="submitted && f['assignee'].errors">
            <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
            <span *ngIf="f['assignee'].errors['required']"> Staff
                {{errorJson.required}}</span>
        </div> -->
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <label class="fw-600 mb-2 ">{{addcontact.contact_type }} </label>

        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="contact_type" placeholder={{addcontact.contact_type}}>
            <mat-option [value]="type.id" *ngFor="let type of contact_type">{{type.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div formArrayName="system_contact_fields" class="row"> -->
      <ng-container *ngFor="let field of system_contact_fields.controls; let i=index">
        <ng-container [ngTemplateOutlet]="fields"
          [ngTemplateOutletContext]="{field:field, i:i, formArrayName: 'system_contact_fields',fieldType:'sys'}">
        </ng-container>
      </ng-container>
      <!-- </div> -->

      <hr class="mt-2">
      <h5>
        Additional Fields
      </h5>
      <!-- <div formArrayName="custom_contact_fields" class="row"> -->
      <ng-container *ngFor="let field of custom_contact_fields.controls; let i=index">
        <ng-container [ngTemplateOutlet]="fields" *ngIf="field.value.on_form"
          [ngTemplateOutletContext]="{field:field, i:i, formArrayName: 'custom_contact_fields',fieldType:'custm'}">
        </ng-container>
      </ng-container>
      <!-- </div> -->

    </div>
  </form>
</div>

<div class="modal-footer mt-3 d-flex justify-content-between">
  <div class="d-flex cursor" [routerLink]="['settings/custom-fields']" [queryParams]="{ activeLink: 'Contact'}"
    (click)="ngbActiveModal.dismiss()">
    <span class="material-icons text-primary">settings_applications</span> {{addcontact.customize_text}}
  </div>
  <div class="d-flex">
    <button class="btn btn-light me-2" type="button" (click)="ngbActiveModal.dismiss()">
      {{addcontact.cancel_btn}}
    </button>
    <button class="btn btn-primary" type="submit" (click)="onSubmit()"
      [disabled]="loading">{{addcontact.save_btn}}</button>
  </div>
</div>


<ng-template #fields let-field='field' let-i="i" let-formArrayName="formArrayName" let-fieldType="fieldType">
  <form [formGroup]="contactForm" class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
    <div [formArrayName]="formArrayName">
      <div *ngIf="field.value.on_form">
        <div class="row" [formGroupName]="i">
          <div>
            <label class="fw-600 mb-2 ">{{field.value.label.replace("_", " ") | titlecase}}</label>&nbsp;
            <small class="text-danger" *ngIf="field.value.is_mandatory">*</small>

            <ng-container *ngIf="field.value.data_type === 'TEXT'  ">
              <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" matInput placeholder="{{field.value.label}}" formControlName="data" />
                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                  <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                    class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                  {{errorJson.required}}
                </mat-error>
              </mat-form-field>
            </ng-container>
            <!-- [ngClass]="ckeditorLimit_2.actualLimit > ckeditorLimit_2.maxLimit ? 'ck-error':''" -->
            <ng-container *ngIf="field.value.data_type === 'TEXT_AREA'">
              <ckeditor [editor]="Editor" placeholder="write a message" formControlName="data" [config]="ckconfigQue"
                class="ckeditor-height" #editorComponents (ready)="onReady($event,fieldType+i)"
                [ngClass]="ckeditorLimitLists[fieldType+i]?.actualLimit > ckeditorLimitLists[fieldType+i]?.maxLimit ? 'ck-error':''"
                (keyup)="editorkeyEvent($event,editorComponents,fieldType+i)">
              </ckeditor>
              <small
                [ngClass]="ckeditorLimitLists[fieldType+i]?.actualLimit > ckeditorLimitLists[fieldType+i]?.maxLimit ? 'text-danger':''"
                class="font-size-10">Maximum
                1000 characters are accepted.</small><small
                [ngClass]="ckeditorLimitLists[fieldType+i]?.actualLimit > ckeditorLimitLists[fieldType+i]?.maxLimit ? 'text-danger':''"
                class="font-size-10 ps-1">(Character
                limit:
                {{ckeditorLimitLists[fieldType+i]?.actualLimit}}/{{ckeditorLimitLists[fieldType+i]?.maxLimit}})</small>
              <!-- <textarea class="form-control background-color" rows="2" cols="30" formControlName="data"
                placeholder="write a message"></textarea> -->
              <div class="error d-flex align-items-center" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                <span class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;{{errorJson.required}}
              </div>
            </ng-container>

            <ng-container *ngIf="field.value.data_type === 'CHECK_BOX'">
              <div *ngFor="let options of field.value.options">
                <mat-checkbox [value]="options" (change)="toggleCheckbox(options, i)">
                  {{options}}
                </mat-checkbox>
              </div>
            </ng-container>

            <ng-container *ngIf="field.value.data_type === 'SELECT_DROPDOWN' ">
              <div>
                <ng-container *ngIf="field.value.options.length > 0">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="data">
                      <mat-option [value]="null">
                        None
                      </mat-option>
                      <mat-option *ngFor="let options of field.value.options" [value]="options">
                        {{options}}
                      </mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                      <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                      <span class="text-capitalize">{{field.value.label.replace("_", "
                        ")}}</span>&nbsp;{{errorJson.required}}
                    </mat-error>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="field.value.options.length === 0">
                  <mat-form-field appearance="outline" class="w-100" *ngIf="field.value.label === 'country'">
                    <mat-select formControlName="data">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                          noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let country of filteredBanks | async"
                        value={{country.Entity}}>{{country.Entity}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                      <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                      <span class="text-capitalize">{{field.value.label.replace("_", "
                        ")}}</span>&nbsp;{{errorJson.required}}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-100" *ngIf="field.value.label === 'time_zone'">
                    <mat-select formControlName="data">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="bankFilterTimezoneCtrl" placeholderLabel="Search"
                          noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                      </mat-option>
                      <mat-optgroup *ngFor="let time_zone of filteredTimezoneBanks | async" [label]="time_zone.name">
                        <mat-option class="mb-2 mat-hint-none" *ngFor="let countries of time_zone.countries"
                          value="{{countries.timezone}}">{{countries.name}}</mat-option>
                      </mat-optgroup>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                      <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                      <span class="text-capitalize">{{field.value.label.replace("_", "
                        ")}}</span>&nbsp;{{errorJson.required}}
                    </mat-error>
                  </mat-form-field>
                  <ng-select class="form-control" [items]="companiesListArray" bindLabel="name" bindValue="id"
                    [addTag]="addNewCompanyName" [multiple]="false" (search)="searchSubject.next($event)"
                    *ngIf="field.value.label === 'company' && featuresData['create_company'] && featuresData['company']"
                    (change)="onSelectCompany($event)" (clear)="clearCompany($event)" placeholder="Company name"
                    formControlName="data">
                  </ng-select>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="field.value.data_type === 'MULTI_SELECT_DROPDOWN'">
              <div *ngIf="field.value.options.length > 0">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select formControlName="data" multiple>
                    <mat-option *ngFor="let options of field.value.options" [value]="options">
                      {{options}}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                    <span class="text-capitalize">{{field.value.label.replace("_", "
                      ")}}</span>&nbsp;{{errorJson.required}}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>

            <ng-container *ngIf="field.value.data_type === 'RADIO_BUTTON'">
              <div>
                <ng-container *ngIf="field.value.options.length > 0">
                  <mat-radio-group formControlName="data">
                    <mat-radio-button value="">None</mat-radio-button>
                    <mat-radio-button *ngFor="let options of field.value.options" [value]="options">
                      {{options}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <div class="error d-flex align-items-center" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                    <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                    <span class="text-capitalize">{{field.value.label.replace("_", "
                      ")}}</span>&nbsp;{{errorJson.required}}
                  </div>
                </ng-container>

                <ng-container *ngIf="field.value.options.length === 0">
                  <mat-radio-group formControlName="data">
                    <mat-radio-button [value]="true">
                      Required
                    </mat-radio-button>
                    <mat-radio-button [value]="false">
                      Not Required
                    </mat-radio-button>
                  </mat-radio-group>
                  <div class="error d-flex align-items-center" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                    <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                    <span class="text-capitalize">{{field.value.label.replace("_", "
                      ")}}</span>&nbsp;{{errorJson.required}}
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <ng-container *ngIf="field.value.data_type === 'TOGGLE_BUTTON' ">
              <label class="switch d-none">
                <input type="checkbox" formControlName="data">
                <span class="slider round"></span>
              </label>
            </ng-container>

            <ng-container *ngIf="field.value.data_type === 'NUMBER' ">
              <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" matInput placeholder="{{field.value.label}}" formControlName="data"
                  type="number" (keydown)="keyDownCustomField($event,0,'custom_field')"
                  (wheel)="$event.preventDefault()" />
                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                  <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                    class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                  {{errorJson.required}}
                </mat-error>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="field.value.data_type === 'WEBSITE'">
              <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" type="url" matInput placeholder="{{field.value.label}}"
                  formControlName="data" />
                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                  <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                    class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                  {{errorJson.required}} or Invalid
                </mat-error>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="field.value.data_type === 'DATE' ">
              <mat-form-field appearance="outline" class="w-100">
                <input matInput type="text" matInput [matDatepicker]="picker" formControlName="data"
                  placeholder="{{field.value.label}}">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                  <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                    class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                  {{errorJson.required}}
                </mat-error>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="field.value.data_type === 'DATE_TIME' ">
              <mat-form-field appearance="outline" class="w-100">
                <input matInput [ngxMatDatetimePicker]="dateTimePicker" placeholder="Choose a date"
                  formControlName="data">
                <mat-datepicker-toggle matSuffix [for]="$any(dateTimePicker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #dateTimePicker>
                </ngx-mat-datetime-picker>
                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                  <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                    class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                  {{errorJson.required}}
                </mat-error>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="field.value.data_type === 'DATE_RANGE' ">
              <mat-form-field appearance="outline" class="w-100">
                <mat-date-range-input [rangePicker]="dateRangepicker">
                  <input matStartDate formControlName="start" placeholder="Start date">
                  <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="dateRangepicker"></mat-datepicker-toggle>
                <mat-date-range-picker #dateRangepicker></mat-date-range-picker>
                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                  <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                    class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                  {{errorJson.required}}
                </mat-error>
              </mat-form-field>
            </ng-container>

            <ng-container *ngIf="field.value.data_type === 'FILE'">
              <div class="" *ngIf="contact?.id">
                <img src="{{field.value?.data?.path}}" alt="" width="150px" class="mb-2"
                  (error)="onErrorLoadImage($event,presignedurl,field.value?.data?.file_name)">
                <a href="{{field.value?.data?.path}}" target="_blank" class="ms-2 text-dark fw-medium mt-3"
                  download>{{field.value?.data?.file_name}}</a>
              </div>
              <input type="file" style="outline: none" placeholder="{{field.value.label}}"
                (change)="onFileUpload($event,i,field)" [accept]="getAcceptAttribute(field)" />
              <div class="error d-flex align-items-center" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                <span class="text-capitalize">{{field.value.label.replace("_", "
                  ")}}</span>&nbsp;{{errorJson.required}}
              </div>
              <!-- <ngx-dropzone (change)="onFileUpload($event,i)" [multiple]="false" class="bg-gray-light">
                <ngx-dropzone-label>Drag a <span class="text-primary fw-500">{{field.value.label}}</span>
                  to upload
                </ngx-dropzone-label>
                <ngx-dropzone-image-preview [removable]="true" 
                ngProjectAs="ngx-dropzone-preview" *ngFor="let f of company_logo" [file]="f">
              </ngx-dropzone-image-preview>
              </ngx-dropzone> -->
            </ng-container>

            <ng-container *ngIf="field.value.data_type === 'PHONE_NUMBER'">
              <div class="d-flex">
                <mat-form-field appearance="outline" class="me-1">
                  <mat-select placeholder="Country code" formControlName="start">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                        noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let country of filteredBanks | async" [ngClass]="{'d-none': country.IAC === ''}"
                      value={{country.IAC}}>{{country.IAC}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100">
                  <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                    placeholder="{{field.value.label}}" formControlName="end" />
                  <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                    <span class="text-capitalize">{{field.value.label}} {{errorJson.required}}</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="error d-flex align-items-center" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                <span class="text-capitalize">{{field.value.label.replace("_", "
                  ")}}</span>&nbsp;{{errorJson.required}}
              </div>
            </ng-container>

            <ng-container *ngIf="field.value.data_type === 'EMAIL'">
              <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" matInput placeholder="{{field.value.label}}" formControlName="data" />
                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(formArrayName,i)">
                  <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                    class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                  {{errorJson.required}}
                </mat-error>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
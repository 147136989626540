<div class="container-fluid ms-2">
    <p class="m-0 heading font-size-18 fw-700 text-primary mb-4 mt-3">Import data to brexa -
        <b>{{file_name}}</b><!--Enquiries.csv-->
    </p>
    <div class="table-responsive table-height fixed-table">
        <form [formGroup]="mappingForm" (ngSubmit)="onSubmit()">
            <table class="table align-middle table-nowrap table-hover mb-0 w-100" (mouseleave)="hoveredIndex = null">
                <thead>
                    <tr class="border-top-table">
                        <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                            Fields in file
                        </th>
                        <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                            Fields in Brexa
                        </th>
                        <th scope="col" class="table-sticky-header bg-white w-30" style="z-index: 999;">
                            Replace with
                        </th>
                        <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;" colspan="2">
                            Sample data from file</th>
                    </tr>
                </thead>
                <tbody formArrayName="fields_mapping">
                    <tr class="cursor" *ngFor="let item of field_data; let i = index" (mouseenter)="hoveredIndex = i"
                        [formGroupName]="i">
                        <td>{{item?.label}}</td>
                        <td>
                            <mat-form-field class="w-100 mat-hint-none">
                                <mat-select formControlName="mapped_field" (click)="onIndexChange(i)"
                                    (selectionChange)="onChange($event,i,item)"><!-- [(value)]="item.Label"-->
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                            noEntriesFoundLabel="No matching found" id="i"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option value="">None</mat-option>
                                    <mat-optgroup [label]="group.name" *ngFor="let group of filteredBanks[i] | async">
                                        <mat-option *ngFor="let field of group.fields" [value]="field.label"
                                            (onSelectionChange)="dropdownchange(field,i)"><!--[disabled]="item?.data_type ? item.data_type !== field.data_type : false"-->
                                            {{field.label?.replace('_', ' ')}} <small
                                                *ngIf="showType !== 'company' && (field.label === this.customer_primary_login_type)"
                                                class="text-danger">*</small>
                                            <small *ngIf="showType === 'company' && field.label === 'name'"
                                                class="text-danger">*</small>
                                            <small *ngIf="showType === 'enquiry' && field.label === 'requirement_title'"
                                                class="text-danger">*</small>
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <div class="d-flex">
                                <div class="me-2"
                                    *ngIf="(FormItems.at(i).get('replace_text')?.value && FormItems.at(i).get('mapped_field')?.value ) || (hoveredIndex === i && FormItems.at(i).get('mapped_field')?.value)">
                                    <mat-form-field class="mat-hint-none">
                                        <input style="outline: none" matInput placeholder="Replace Empty values"
                                            type="text" formControlName="replace_text" />
                                    </mat-form-field>
                                </div>
                                <div *ngIf="item?.data_type === 'DATE'">
                                    <mat-form-field class="mat-hint-none">
                                        <mat-select placeholder="Choose data format">
                                            <mat-option *ngFor="let date of date_formats" [value]="date">
                                                {{date}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </td>
                        <ng-container *ngFor="let data of item.sample_data | slice:0: 2">
                            <td>{{ data }}</td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
            <button type="submit" class="btn btn-primary fixed-btn">Save</button>
        </form>
    </div>
</div>
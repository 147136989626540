<div class="d-flex flex-column justify-content-between" style="height: calc(100vh - 130px);">
    <div class="table-responsive table-height fixed-table max-h-full">
        <table class="table align-middle table-nowrap table-hover mb-0 users-table w-100 max-h-full">
            <thead>
                <tr>
                    <th scope="col" *ngFor="let col of table_cols" class="text-primary pb-3">
                        {{col}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let details of transaction_details">
                    <td class="text-capitalize">{{details?.display_id}}</td>
                    <td>{{ details?.gateway }}</td>
                    <td>{{ details?.currency_symbol }} {{ details?.amount }}</td>
                    <td>{{ details?.created_at | dateConversion }}</td>
                    <td>
                        <a href="{{ details?.invoice_pdf_url }}" target="_blank" *ngIf="details?.invoice_pdf_url">
                            <img src="./assets/images/today-tasks.svg" width="18" matTooltip="Invoice">
                        </a>
                        <a href="{{ details?.receipt_link_url }}" target="_blank" class="ms-2"
                            *ngIf="details?.receipt_link_url">
                            <img src="./assets/images/myboard_activity_log.svg" width="18" matTooltip="Recepit">
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="mat-page-custom">
        <mat-paginator #paginator [length]="this.totalRecords" [pageSize]="page_size" (page)="page($event)"
            aria-label="Select page" hidePageSize="true" showFirstLastButtons>
        </mat-paginator>
    </div>
</div>
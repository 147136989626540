<img src="assets/images/icon_blue.svg" width="35px" height="35px" class="mt-1 mb-2 d-flex m-auto cursor"
  [routerLink]="[menuList[0].link]" (click)="resetToFirstTab()">
<!-- <div class="side-divider"></div> -->
<div class="overflow-auto" style="height: calc(100vh - 90px);">
  <ng-container *ngFor="let menuGroup of menuList;let i = index">
    <div
      *ngIf="menuGroup?.isEnable"
      class="text-black cursor position-relative">
      <!-- <div class="active-class-design" *ngIf="currentTab === menuGroup?.label"></div> -->
      <a class="text-black text-d-none" (click)="resetToFirstTab(menuGroup,$event)">
        <div class="d-flex flex-column align-items-center menu-group position-relative m-1 p-2"
          [ngClass]="{'is_active': currentTab === menuGroup?.label}" *ngIf="!menuGroup?.subItems"
          (mouseenter)="buttonEnter()">
          <!-- <mat-icon class="icon">{{menuGroup.icon}}</mat-icon> -->
          <label class="avatar-xsss d-flex justify-content-between chat-count z-index-max"
            *ngIf="menuGroup.label === 'Live Chat' && liveChatCount !== 0 && (loggedInUser.data.role === 'ADMIN' || loggedInUser.data.role === 'AGENT')">
            <span class="avatar-title rounded-circle text-uppercase font-size-9 bg-count">{{liveChatCount}}</span>
          </label>
          <label class="avatar-xsss d-flex justify-content-between chat-count z-index-max"
            *ngIf="menuGroup.label === 'Bot Chat' && botChatCount !== 0 && (loggedInUser.data.role === 'ADMIN' || loggedInUser.data.role === 'AGENT')">
            <span class="avatar-title rounded-circle text-uppercase font-size-9 bg-count">{{botChatCount}}</span>
          </label>
          <label class="avatar-xsss d-flex justify-content-between chat-count z-index-max"
            *ngIf="menuGroup.label === 'Messages' && conversationCount !== 0">
            <span class="avatar-title rounded-circle text-uppercase font-size-9 bg-count">{{conversationCount}}</span>
          </label>
          <label class="avatar-xsss d-flex justify-content-between chat-count z-index-max"
            *ngIf="menuGroup.label === 'Teamchat' && teamChatCount !== 0">
            <span class="avatar-title rounded-circle text-uppercase font-size-9 bg-count">{{teamChatCount}}</span>
          </label>
          <img src="{{ currentTab !== menuGroup.label ? menuGroup.image : menuGroup.white_image}}" width="24"
            height="24" alt="{{ menuGroup.label }}" class="pt-1" [matTooltip]="menuGroup.label" matTooltipPosition="right">
          <!-- <span class="font-size-11 fw-600 pt-1 group-label"> {{ menuGroup.label }}</span> -->
        </div>

        <div class="d-flex flex-column align-items-center" [ngClass]="{'margin-bottom-7': menuGroup?.slug === 'more'}"
          *ngIf="menuGroup?.subItems?.length > 0 && menuGroup?.isEnable" aria-expanded="false">
          <button #button mat-button [matMenuTriggerFor]="menu" #levelOneTrigger="matMenuTrigger"
            (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)"
            (mouseenter)="levelOneTrigger.openMenu()" (menuOpened)="menuOpened(levelOneTrigger)"
            (menuClosed)="menuClosed(levelOneTrigger)" [ngClass]="{'is_active': currentTab === menuGroup?.label}"
            class="d-flex flex-column p-2 align-items-center menu-btn text-black">
            <!-- <mat-icon class="icon">{{menuGroup.icon}}</mat-icon> -->
            <img src="{{currentTab !== menuGroup.label ? menuGroup.image : menuGroup.white_image}}" width="24"
              height="24" class="pt-1">
            <!-- <span class="font-size-11 fw-600 pt-1"> {{ menuGroup.label }}</span> -->
          </button>
          <mat-menu #menu="matMenu" xPosition="before" class="matmenu-list" hasBackdrop="false">
            <span (mouseleave)="levelOneTrigger.closeMenu()">
              <ng-container *ngFor="let subItem of menuGroup.subItems">
                <li [routerLink]="subItem.link" [queryParams]="subItem?.queryParams" (click)='onSubMENUClicked(menuGroup?.label)' class="p-0"
                  *ngIf="subItem && featuresData && featuresData[subItem?.name]" mat-menu-item>
                  <a class="nav-link font-size-13" style="background: none;">{{subItem.label}}
                  </a>
                </li>
              </ng-container> 
            </span>
          </mat-menu>
        </div>
      </a>
    </div>
    <!-- <div *ngIf="i !== menuList.length - 1 && ((menuGroup.label === 'Live Chat' || menuGroup.label === 'Bot Chat') && (loggedInUser.data.role === 'ADMIN' || loggedInUser.data.role === 'AGENT') || (menuGroup.label !== 'Live Chat' && menuGroup.label !== 'Bot Chat')) && menuGroup?.isEnable" class="side-divider"></div> -->
  </ng-container>
</div>
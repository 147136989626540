<div class="container-fluid p-4 pt-0">
    <form [formGroup]="terms_and_conditions_form" (ngSubmit)="onSubmit()">
        <label class="fw-600 font-size-13 my-3">Terms And Conditions</label>
        <ckeditor [editor]="Editor" placeholder="Description" formControlName="terms_conditions" [config]="ckconfigQue"
            #editorComponent>
        </ckeditor>
        <div *ngIf="submitted && f['terms_conditions'].errors"
            class="text-danger error font-size-12 d-flex align-items-center">
            <mat-icon class="font-size-12 d-flex align-items-center me-1">info</mat-icon>
            <span *ngIf="f['terms_conditions'].errors['required']">Terms and Conditions are required</span>
        </div>
        <label class="fw-600 font-size-13 my-3">Contact Us Info</label>
        <ckeditor [editor]="Editor" [config]="ckconfigQue" formControlName="footer_contact_us" #editorComponentFooter>
        </ckeditor>
        <div *ngIf="submitted && f['footer_contact_us'].errors"
            class="text-danger error font-size-12 d-flex align-items-center">
            <mat-icon class="font-size-12 d-flex align-items-center me-1">info</mat-icon>
            <span *ngIf="f['footer_contact_us'].errors['required']">Contact Us Info is required</span>
        </div>
        <div class="d-flex align-items-center">
            <button class="btn btn-primary d-flex align-items-center font-size-13 mt-4" type="submit">
                Update
            </button>
        </div>
    </form>
import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { CalendarComponent } from './calendar/calendar.component';
// import { FinanceComponent } from './finance/finance.component';
// import { CreateFormComponent } from './form-builder/create-form/create-form.component';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { FormSettingsComponent } from './form-builder/form-settings/form-settings.component';
import { HeaderCalendarComponent } from './header-calendar/header-calendar.component';
import { HomeComponent } from './home/home.component';
import { SettingsComponent } from './settings/settings.component';
import { FormSubmissionsComponent } from './form-builder/form-submissions/form-submissions.component';
import { FinanceComponent } from './finance/finance.component';
import { HomeManagementComponent } from './home/home-management/home-management.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { OnboardingScreensComponent } from './onboarding-screens/onboarding-screens.component';
import { ConversationComponent } from './conversation/conversation.component';
import { TeamChatComponent } from './team-chat/team-chat.component';
import { BotChatComponent } from './bot-chat/bot-chat.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { map } from 'rxjs';
import { AuthGuardService } from '../core/service/auth-guard.service';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { PeopleComponent } from './people/people.component';
import { CrmSidebarComponent } from './crm/crm-sidebar/crm-sidebar.component';
import { ChatbotStoresComponent } from './chatbot-stores/chatbot-stores.component';

const routes: Routes = [
  { path: '', redirectTo: 'crm', pathMatch: 'full' },
  {
    path: 'home', component: HomeComponent, loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).moduleFeatureAccessPermission('home').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'onboarding', component: OnboardingScreensComponent, canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('onboarding').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'home/task/home-management', component: HomeManagementComponent, canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('home_task_management', 'home_task').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'home/activity/home-management', component: HomeManagementComponent, canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('home_activity_management', 'home_activity').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  // { path: 'home/calls/home-management', component: HomeManagementComponent },
  // { path: 'home/email/home-management', component: HomeManagementComponent },
  // { path: 'home/meetings/home-management', component: HomeManagementComponent },
  {
    path: 'home/appointments/home-management', component: HomeManagementComponent, canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('home_appointments_management', 'home_appointments').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'home/orders/home-management', component: HomeManagementComponent, canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('home_orders_management', 'home_orders').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'home/calendar-bookings/home-management', component: HomeManagementComponent, canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('home_calendar_bookings_management', 'home_calendar_bookings').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'crm', component: CrmSidebarComponent, loadChildren: () => import('./crm/crm.module').then(m => m.CrmModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).moduleFeatureAccessPermission('crm').pipe(map(isAccessible => isAccessible || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'crm-pipeline', loadChildren: () => import('./crm/crm.module').then(m => m.CrmModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).moduleFeatureAccessPermission('crm').pipe(map(isAccessible => isAccessible || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'setup', loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).moduleFeatureAccessPermission('setup').pipe(map(isAccessible => isAccessible || router.createUrlTree(['/unauthorised'])));
    }]
  },
    
  // { path: 'inbox', loadChildren: () => import('./inbox/inbox.module').then(m => m.InboxModule) },
  {
    path: 'clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).moduleFeatureAccessPermission('client').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  // { path: 'finance', component: FinanceComponent, loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule) },
  {
    path: 'finance', component: FinanceComponent, loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('finance').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  { path: 'settings', component: SettingsComponent, loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
  {
    path: 'calendar', component: CalendarComponent, loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('meetings').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'campaigns', loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).moduleFeatureAccessPermission('campaign').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },

  {
    path: 'live_chat', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule), canMatch: [() => {
      const router: Router = inject(Router);
      const loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
      return inject(AuthGuardService).featureAccessPermission('live_chat').pipe(map(is_assessable => is_assessable && (loggedInUser.data.role !== 'MANAGER' && loggedInUser.data.role !== 'STAFF') || router.createUrlTree(['/unauthorised'])));
    }]
  },

  {
    path: 'conversation', component: ConversationComponent, canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('conversation').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },

  {
    path: 'teamchat', component: TeamChatComponent, canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('teamchat').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'unibox', component: ChatBotComponent, canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('uni_box').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'bot_chat', component: BotChatComponent
  },

  {
    path: 'pipelines', loadChildren: () => import('./pipeline/pipeline.module').then(m => m.PipelineModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('pipeline').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }] },

  {
    path: 'header-calendar', component: HeaderCalendarComponent, canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('header_calendar').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },

  {
    path: 'user-profile', component: UserProfileComponent
  },

  {
    path: 'global-taskboard', loadChildren: () => import('./global-taskboard/global-taskboard.module').then(m => m.GlobalTaskboardModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('global_taskboard').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },

  {
    path: 'reports', loadChildren: () => import('./reports/reports.routing.module').then(m => m.ReportsRoutingModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('reports').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'taskboard', loadChildren: () => import('./global-taskboard/global-taskboard.module').then(m => m.GlobalTaskboardModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('taskboard').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },

  {
    path: 'filemanager', loadChildren: () => import('./file-manager/file-manager.module').then(m => m.FileManagerModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('file_manager').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'people', component: PeopleComponent, canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('people').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'orders', component: ChatbotStoresComponent
  },
  {
    path: 'reportsv1',
    loadChildren: () => import('../pages/reportsv1/reportsv1.module').then(m => m.Reportsv1Module)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }

<div class="container-fluid p-0 bg-white" *ngIf="featuresData?.enquiry && getRolePermissions('leads','can_read')">
  <div class="d-flex align-items-center new-design-padding justify-content-between bg-white pb-2 pt-1">
    <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" (navChange)="tabChange($event)"
      class="nav-tabs nav-tabs-custom mt-0 border-none auto-hide-scroll-bar leftMaxWidth flex-nowrap">
      <li [ngbNavItem]="1" class="me-2">
        <a ngbNavLink class="d-flex">
          <span class="d-sm-block font-size-12">Open</span>
        </a>
        <ng-template ngbNavContent>
          <div
            class="custom-table-div-crm-height enquiry-screen ps-1 d-flex flex-column justify-content-between">
            <div class="table-responsive table-height fixed-table">
              <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="7"
                [fromLocation]="'CRM'"></app-table-skeleton>
              <table *ngIf="!tableSkeletonLoader"
                class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                <thead>
                  <tr class="table-header-color">
                    <th scope="col">
                      <mat-checkbox (change)="$event ? masterToggle(currentPage,$event) : null" class="mat_checkbox"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                    </th>
                    <ng-container *ngFor="let col of originalTableColumnLists">
                      <th *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                        scope="col" class="text-capitalize" style="min-width: 100px;max-width: 150px;">
                        {{col.field_name === 'created_at' ? 'Created On' : col.field_name!.split("_").join(" ")}}</th>
                    </ng-container>
                    <th class="" style="min-width: 60px;">
                      Move
                      To</th>
                    <th class="" style="min-width: 60px;">
                      Actions 
                      <img src="assets/images/newUI/MENU/HEADER/add_icon.svg" alt="" width="20" matTooltip="Add Custom Field"
                        class="cursor ms-2" (click)="openCustomFieldModal()" *ngIf="loggedInUser.data.role === 'ADMIN'">
                    </th>
                  </tr>
                </thead>
                <tbody *ngIf="!display_no_data">
                  <tr class="cursor" *ngFor="let item of leadsListArray; let i = index"
                    [routerLink]="['../crm-details']" [ngClass]="{'inactiveClass': !item?.is_contact_active }"
                    [queryParams]="{ id: item.id, showtype: 'lead',contact_id: item.contact,contact_active: item?.is_contact_active }">
                    <td style="padding: 8px 16px !important; border-left: 0px;" (click)="$event.stopPropagation()">
                      <mat-checkbox class="mat_checkbox"
                        (change)="$event ? selection.toggle(item) && individualRecordToogle(item) : null"
                        [checked]="selection.isSelected(item)"></mat-checkbox>
                    </td>
                    <ng-container *ngTemplateOutlet="table_lists;context:{item:item}"></ng-container>
                    <!-- <td>{{item?.display_id ? item.display_id : "-"}}</td>
                                    <td class="home-text-color">
                                        <app-table-popover [inputType]="'text'" [inputValue]="item.requirement_title"
                                            [labelName]="'Name'" [formName]="'requirement_title'"
                                            (update)="onUpdatePopOver($event, item.id)"></app-table-popover>

                                    </td>
                                    <td>
                                        {{ item?.name ? item.name : "NA" }}
                                    </td> -->
                    <!-- <td>
                                    {{ item.next_activity }}
                                </td> -->
                    <!-- <td>{{ item.email ? item.email : "NA" }}</td>
                                    <td><span *ngIf="item?.phone_number">{{item.ccode}}&nbsp;</span>{{item.phone_number
                                        ?
                                        item.phone_number : "NA"}}</td> -->
                    <!-- <td>{{ item.tags ? item.tags : "-" }}</td> -->
                    <!-- <td class="text-capitalize"><span class="py-1 px-2 border-radius-4 w-100 d-flex"
                                            [ngStyle]="{'background-color': item?.channel ? 'rgba(233, 247, 255, 1)' : ''}">{{
                                            item.channel ? (item.channel.replace("_", " ") |
                                            titlecase) : "NA" }}</span></td>
                                    <td>
                                        {{(item.created_at ? (item.created_at | dateConversion ) : "NA")}}
                                    </td> -->
                    <!-- <td>{{ item.updated_at | date: 'd/M/yy, h:mm a' }}</td> -->
                    <!-- <td *ngIf="item.info.owner_info">{{ item.info ? item.info.owner_info?.fname : "" }}
                                    {{item.info ?
                                    item.info.owner_info?.lname : ""}}</td> -->
                    <!--<td *ngIf="!item.info.owner_info">-</td> -->
                    <!-- <td>
                                        <app-table-popover [inputType]="'dropdown'"
                                            [editEnable]="(item.owner === loggedInUser.data.id || loggedInUser.data.role === 'ADMIN')"
                                            [inputValue]="item.owner_info?.fname + ' ' + item.owner_info?.lname"
                                            [formName]="'info.owner_info'" [labelName]="'Owner'"
                                            [dropDownItems]="staffMembers" [selectedItem]="item.owner"
                                            (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                    </td>
                                    <td>
                                        <app-table-popover [inputType]="'dateTime'" [inputValue]="item.due_date"
                                            [labelName]="'Due Date'" [formName]="'due_date'"
                                            (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                    </td> -->
                    <!-- <td>{{ item.company_id}}</td>
                                <td>website</td>
                                <td>Mailing Street</td>
                                <td>Mailing Address</td>
                                <td>Mailing City</td>
                                <td>Mailing Country</td>
                                <td>Mailing State</td>
                                <td>Shipping Address</td>
                                <td>Shipping City</td>
                                <td>Shipping Country</td>
                                <td>Zip Code</td>-->
                    <td (click)="$event.stopPropagation()" class="mat-hint-none mat-customised-sec">
                      <mat-form-field appearance="outline" class="w-75" *ngIf="getRolePermissions('leads','can_update')">
                        <mat-select placeholder='Select' [(ngModel)]="item.type" [disabled]="!item?.is_contact_active">
                          <mat-option (click)="moveLeadTo(item,'LOST','ACTIVE')" value="LOST">Move
                            to Lost</mat-option>
                          <mat-option (click)="moveLeadTo(item,'WON','ACTIVE')" value="WON">Move
                            to Won</mat-option>
                          <mat-option (click)="moveLeadTo(item,'JUNK','ACTIVE')" value="JUNK">
                            Move to Junk</mat-option>
                          <mat-option (click)="moveLeadTo(item,'PIPELINE','ACTIVE')" value="PIPELINE">
                            Move to Flow</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td (click)="$event.stopPropagation()"
                      [ngStyle]="{'cursor':!isSelected.multiple ? 'pointer' : 'auto'}">
                      <div class="dropdown" ngbDropdown>
                        <img class="action-btn bg-white border-radius-8" width="32"
                          src="assets/images/newUI/CRM/action_icon.svg" style="z-index: 99;"
                          [ngStyle]="{'opacity':!isSelected.multiple ? '1' : '0.5'}"
                          [matMenuTriggerFor]="!isSelected.multiple ? menuadd : null" alt="">
                        <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                          <button mat-menu-item (click)="addLeadModal(item)" class="py-2" [disabled]="!getRolePermissions('leads','can_update')">
                            Edit
                          </button>
                          <button mat-menu-item (click)="confirmDelete(item.id,enquiryType)" class="py-2" [disabled]="!getRolePermissions('leads','can_delete')">
                            Delete
                          </button>
                          <!-- <button mat-menu-item (click)="moveLeadTo(item,'LOST','ACTIVE')"
                                                class="py-2">
                                                Move to Lost
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'WON','ACTIVE')"
                                                class="py-2">
                                                Move to Won
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'JUNK','ACTIVE')"
                                                class="py-2">
                                                Move to Junk
                                            </button> -->
                        </mat-menu>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
            </div>
            <div>
              <mat-paginator [length]="leadsObj?.total || leadsObj?.count" [pageSize]="page_size"
                [pageIndex]="pageIndex" (page)="page($event,enquiryType)" aria-label="Select page" hidePageSize="true"
                showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" class="me-2">
        <a ngbNavLink class="d-flex">
          <span class="d-sm-block font-size-12">Lost</span>
        </a>
        <ng-template ngbNavContent>
          <!-- <ng-container *ngTemplateOutlet="filterSection"></ng-container> -->
          <div
            class="custom-table-div-crm-tabs-height enquiry-screen ps-1 d-flex flex-column justify-content-between">
            <div class="table-responsive table-height fixed-table">
              <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="7"
                [fromLocation]="'CRM-LOST'"></app-table-skeleton>
              <table *ngIf="!tableSkeletonLoader"
                class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                <thead>
                  <tr class="table-header-color">
                    <!-- <th scope="col">
                                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                                </th> -->
                    <ng-container *ngFor="let col of originalTableColumnLists">
                      <th *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                        scope="col" class=" font-size-13 pb-3  text-capitalize"
                        style="min-width: 100px;max-width: 150px;">
                        {{col.field_name === 'created_at' ? 'Created On' : col.field_name!.split("_").join(" ")}}</th>
                    </ng-container>
                    <th class="" style="min-width: 60px;">
                      Lost Reason</th>
                    <th class="" style="min-width: 60px;">
                      Move
                      To</th>
                    <th class="" style="min-width: 60px;">
                      Actions</th>
                  </tr>
                </thead>
                <tbody *ngIf="!display_no_data">
                  <tr class="cursor" *ngFor="let item of leadsListArray; let i = index"
                    [routerLink]="['../crm-details']" [ngClass]="{'inactiveClass': !item?.is_contact_active }"
                    [queryParams]="{ id: item.id, showtype: 'lead',contact_id: item.contact}">
                    <ng-container *ngTemplateOutlet="table_lists;context:{item:item}"></ng-container>
                    <!-- <td>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(item.id) : null"
                                        [checked]="selection.isSelected(item.id)"></mat-checkbox>
                                </td> -->
                    <!-- <td>{{item?.display_id ? item.display_id : "-"}}</td>
									<td class="home-text-color"> -->
                    <!-- {{ item.requirement_title ? item.requirement_title : "-" }} -->
                    <!-- <app-table-popover [inputType]="'text'" [inputValue]="item.requirement_title"
											[formName]="'requirement_title'" [labelName]="filtersLables[0].lableName"
											(update)="onUpdatePopOver($event, item.id)"></app-table-popover>
									</td>
									<td>
										{{ item?.name ? item.name : "NA" }}
									</td> -->
                    <!-- <td>
                                    {{ item.next_activity }}
                                </td> -->
                    <!-- <td>
										{{ item.email ? item.email : "NA" }}
									</td>
									<td><span *ngIf="item?.phone_number">{{item.ccode}}&nbsp;</span>{{item.phone_number
										?
										item.phone_number : "NA"}}</td> -->
                    <!-- <td>{{ item.tags ? item.tags : "-" }}</td> -->
                    <!-- <td class="text-capitalize"><span class="py-1 px-2 border-radius-4 w-100 d-flex"
											[ngStyle]="{'background-color': item?.channel ? 'rgba(233, 247, 255, 1)' : ''}">{{
											item.channel ? (item.channel.replace("_", " ") |
											titlecase) : "NA" }}</span></td>
									<td>{{ (item.created_at ? (item.created_at| dateConversion) : "NA")}}</td>
									<td>
										<app-table-popover [inputType]="'dropdown'"
											[inputValue]="item.owner_info?.fname + ' ' + item.owner_info?.lname"
											[formName]="'info.owner_info'" [labelName]="'Owner'" [dropDownItems]="staffMembers"
											[selectedItem]="item.owner" (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
									</td> -->
                    <!-- <td>{{ item.updated_at | date: 'd/M/yy, h:mm a' }}</td> -->
                    <!-- <td *ngIf="item.info.owner_info">{{ item.info ? item.info.owner_info?.fname : "" }}
                                    {{item.info ?
                                    item.info.owner_info?.lname : ""}}</td> -->
                    <!-- <td *ngIf="!item.info.owner_info">-</td> -->
                    <!-- <td>
										<app-table-popover [inputType]="'dateTime'" [inputValue]="item.due_date" [formName]="'due_date'"
											[labelName]="filtersLables[7].lableName"
											(update)="onUpdatePopOver($event, item.id)"></app-table-popover>
									</td> -->
                    <!-- <td *ngIf="item.due_date">{{(item.due_date ? item.due_date : "") | dateConversion }}</td> -->
                    <!-- <td *ngIf="!item.due_date">-</td> -->
                    <!-- <td>{{ item.company_id}}</td>
                                <td>website</td>
                                <td>Mailing Street</td>
                                <td>Mailing Address</td>
                                <td>Mailing City</td>
                                <td>Mailing Country</td>
                                <td>Mailing State</td>
                                <td>Shipping Address</td>
                                <td>Shipping City</td>
                                <td>Shipping Country</td>
                                <td>Zip Code</td>
                                <td>Title</td> -->
                    <td>{{item?.lost_reason_text}}</td>
                    <td (click)="$event.stopPropagation()" class="mat-hint-none">
                      <mat-form-field appearance="outline" class="w-75" *ngIf="getRolePermissions('leads','can_update')">
                        <mat-select placeholder='Select' [(ngModel)]="item.type" [disabled]="!item?.is_contact_active">
                          <mat-option (click)="moveLeadTo(item,'ACTIVE','LOST')" value="ACTIVE">Move to
                            Open</mat-option>
                          <mat-option (click)="moveLeadTo(item,'WON','LOST')" value="WON">Move
                            to
                            Won</mat-option>
                          <mat-option (click)="moveLeadTo(item,'JUNK','LOST')" value="JUNK">
                            Move
                            to Junk</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td (click)="$event.stopPropagation()">
                      <div class="dropdown" ngbDropdown>
                        <img class="action-btn bg-white border-radius-8" width="32"
                          src="assets/images/newUI/CRM/action_icon.svg" style="z-index: 99;"
                          [matMenuTriggerFor]="menuadd" alt="">
                        <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                          <button mat-menu-item (click)="addLeadModal(item)" class="py-2" *ngIf="getRolePermissions('leads','can_update')">
                            Edit
                          </button>
                          <button mat-menu-item (click)="confirmDelete(item.id,'LOST')" class="py-2" *ngIf="getRolePermissions('leads','can_delete')">
                            Delete
                          </button>
                          <!-- <button mat-menu-item (click)="moveLeadTo(item,'ACTIVE','LOST')"
                                                class="py-2">
                                                Move to Open
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'WON','LOST')" class="py-2">
                                                Move to Won
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'JUNK','LOST')"
                                                class="py-2">
                                                Move to Junk
                                            </button> -->
                        </mat-menu>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
            </div>
            <div>
              <mat-paginator [length]="leadsObj.total" [pageSize]="20" (page)="page($event, 'LOST')"
                aria-label="Select page" hidePageSize="true" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3" class="me-2">
        <a ngbNavLink class="d-flex">
          <span class="d-sm-block font-size-12">Junk</span>
        </a>
        <ng-template ngbNavContent>
          <ng-container *ngTemplateOutlet="filterSection"></ng-container>
          <div
            class="custom-table-div-crm-tabs-height d-flex ps-1 enquiry-screen flex-column justify-content-between">
            <div class="table-responsive table-height fixed-table">
              <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="7"
                [fromLocation]="'CRM-LOST'"></app-table-skeleton>
              <table *ngIf="!tableSkeletonLoader"
                class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                <thead>
                  <tr class="table-header-color">
                    <!-- <th scope="col">
                                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                                </th> -->
                    <ng-container *ngFor="let col of originalTableColumnLists">
                      <th *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                        scope="col" class=" font-size-13 pb-3  text-capitalize"
                        style="min-width: 100px;max-width: 150px;">
                        {{col.field_name === 'created_at' ? 'Created On' : col.field_name!.split("_").join(" ")}}</th>
                    </ng-container>
                    <th class=" font-size-13 pb-3 " style="min-width: 60px;">
                      Move
                      To</th>
                    <th class=" font-size-13 pb-3 " style="min-width: 60px;">
                      Actions</th>
                  </tr>
                </thead>
                <tbody *ngIf="!display_no_data">
                  <tr class="cursor" *ngFor="let item of leadsListArray; let i = index"
                    [routerLink]="['../crm-details']" [ngClass]="{'inactiveClass': !item?.is_contact_active }"
                    [queryParams]="{ id: item.id, showtype: 'lead',contact_id: item.contact}">
                    <!-- <td>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(item.id) : null"
                                        [checked]="selection.isSelected(item.id)"></mat-checkbox>
                                </td> -->
                    <ng-container *ngTemplateOutlet="table_lists;context:{item:item}"></ng-container>
                    <!-- <td>{{item?.display_id ? item.display_id : "-"}}</td>
									<td class="home-text-color"> -->
                    <!-- {{ item.requirement_title ? item.requirement_title : "-" }} -->
                    <!-- <app-table-popover [inputType]="'text'" [inputValue]="item.requirement_title"
											[formName]="'requirement_title'" [labelName]="filtersLables[0].lableName"
											(update)="onUpdatePopOver($event, item.id)"></app-table-popover>
									</td>
									<td>
										{{ item?.name ? item.name : "NA" }}
									</td> -->
                    <!-- <td>
                                    {{ item.next_activity }}
                                </td> -->
                    <!-- <td>{{ item.email ? item.email : "NA" }}</td>
									<td><span *ngIf="item?.phone_number">{{item.ccode}}&nbsp;</span>{{item.phone_number
										?
										item.phone_number : "NA"}}</td> -->
                    <!-- <td>{{ item.tags ? item.tags : "-" }}</td> -->
                    <!-- <td class="text-capitalize"><span class="py-1 px-2 border-radius-4 w-100 d-flex"
											[ngStyle]="{'background-color': item?.channel ? 'rgba(233, 247, 255, 1)' : ''}">{{
											item.channel ? (item.channel.replace("_", " ") |
											titlecase) : "NA" }}</span></td>
									<td>{{ (item.created_at ? (item.created_at | dateConversion ): "NA")}}</td>
									<td>
										<app-table-popover [inputType]="'dropdown'"
											[inputValue]="item.owner_info?.fname + ' ' + item.owner_info?.lname"
											[formName]="'info.owner_info'" [labelName]="'Owner'" [dropDownItems]="staffMembers"
											[selectedItem]="item.owner" (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
									</td>
									<td>
										<app-table-popover [inputType]="'dateTime'" [inputValue]="item.due_date" [formName]="'due_date'"
											[labelName]="filtersLables[7].lableName"
											(update)="onUpdatePopOver($event, item.id)"></app-table-popover>
									</td> -->
                    <!-- <td>{{ item.updated_at | date: 'd/M/yy, h:mm a' }}</td> -->
                    <!-- <td *ngIf="item.info.owner_info">{{ item.info ? item.info.owner_info?.fname : "" }}
                                    {{item.info ?
                                    item.info.owner_info?.lname : ""}}</td>
                                <td *ngIf="!item.info.owner_info">-</td> -->
                    <!-- <td *ngIf="item.due_date">{{(item.due_date ? item.due_date : "") | dateConversion }}
                                </td>
                                <td *ngIf="!item.due_date">-</td> -->
                    <!-- <td>{{ item.company_id}}</td>
                                <td>website</td>
                                <td>Mailing Street</td>
                                <td>Mailing Address</td>
                                <td>Mailing City</td>
                                <td>Mailing Country</td>
                                <td>Mailing State</td>
                                <td>Shipping Address</td>
                                <td>Shipping City</td>
                                <td>Shipping Country</td>
                                <td>Zip Code</td>
                                <td>Title</td> -->
                    <td (click)="$event.stopPropagation()" class="mat-hint-none">
                      <mat-form-field appearance="outline" class="w-75" *ngIf="getRolePermissions('leads','can_update')">
                        <mat-select placeholder='Select' [(ngModel)]="item.type" [disabled]="!item?.is_contact_active">
                          <mat-option (click)="moveLeadTo(item,'ACTIVE','JUNK')" value="ACTIVE">Move
                            to Open</mat-option>
                          <mat-option (click)="moveLeadTo(item,'WON','JUNK')" value="WON">Move
                            to
                            Won</mat-option>
                          <mat-option (click)="moveLeadTo(item,'LOST','JUNK')" value="LOST">
                            Move
                            to
                            Lost</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td (click)="$event.stopPropagation()">
                      <div class="dropdown" ngbDropdown>
                        <img class="action-btn bg-white border-radius-8" width="32"
                          src="assets/images/newUI/CRM/action_icon.svg" style="z-index: 99;"
                          [matMenuTriggerFor]="menuadd" alt="">
                        <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                          <button mat-menu-item (click)="addLeadModal(item)" class="py-2" *ngIf="getRolePermissions('leads','can_update')">
                            Edit
                          </button>
                          <button mat-menu-item (click)="confirmDelete(item.id,'JUNK')" class="py-2" *ngIf="getRolePermissions('leads','can_delete')">
                            Delete
                          </button>
                          <!-- <button mat-menu-item (click)="moveLeadTo(item,'ACTIVE','JUNK')"
                                                class="py-2">
                                                Move to Open
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'WON','JUNK')" class="py-2">
                                                Move to Won
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'LOST','JUNK')"
                                                class="py-2">
                                                Move to Lost
                                            </button> -->
                        </mat-menu>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
            </div>
            <div>
              <mat-paginator [length]="leadsObj.total" [pageSize]="20" (page)="page($event, 'JUNK')"
                aria-label="Select page" hidePageSize="true" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4" class="me-2">
        <a ngbNavLink class="d-flex">
          <span class="d-sm-block font-size-12">Won</span>
        </a>
        <ng-template ngbNavContent>
          <!-- <ng-container *ngTemplateOutlet="filterSection"></ng-container> -->
          <div
            class="custom-table-div-crm-tabs-height d-flex ps-1 enquiry-screen flex-column justify-content-between">
            <div class="table-responsive table-height fixed-table">
              <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="7"
                [fromLocation]="'CRM-LOST'"></app-table-skeleton>
              <table *ngIf="!tableSkeletonLoader"
                class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                <thead>
                  <tr class="table-header-color">
                    <!-- <th scope="col">
                                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                                </th> -->
                    <ng-container *ngFor="let col of originalTableColumnLists">
                      <th *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                        scope="col" class=" font-size-13 pb-3  text-capitalize"
                        style="min-width: 100px;max-width: 150px;">
                        {{col.field_name === 'created_at' ? 'Created On' : col.field_name!.split("_").join(" ")}}</th>
                    </ng-container>
                    <th class=" font-size-13 pb-3 " style="min-width: 60px;">
                      Move
                      To</th>
                  </tr>
                </thead>
                <tbody *ngIf="!display_no_data">
                  <tr class="cursor" *ngFor="let item of leadsListArray; let i = index"
                    [routerLink]="['../crm-details']" [ngClass]="{'inactiveClass': !item?.is_contact_active }"
                    [queryParams]="{ id: item.id, showtype: 'lead',contact_id: item.contact}">
                    <ng-container *ngTemplateOutlet="table_lists;context:{item:item}"></ng-container>
                    <!-- <td>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(item.id) : null"
                                        [checked]="selection.isSelected(item.id)"></mat-checkbox>
                                </td> -->
                    <!-- <td>{{item?.display_id ? item.display_id : "-"}}</td> -->
                    <!-- <td class="home-text-color">
										<app-table-popover [inputType]="'text'" [inputValue]="item.requirement_title"
											[formName]="'requirement_title'" [labelName]="filtersLables[0].lableName"
											(update)="onUpdatePopOver($event, item.id)"></app-table-popover>
									</td>
									<td>
										{{ item?.name ? item.name : "NA" }}
									</td>
									<td>{{ item.email ? item.email : "NA" }}</td>
									<td><span *ngIf="item?.phone_number">{{item.ccode}}&nbsp;</span>{{item.phone_number
										?
										item.phone_number : "NA"}}</td> -->
                    <!-- <td>{{ item.tags ? item.tags : "-" }}</td> -->
                    <!-- <td class="text-capitalize"><span class="py-1 px-2 border-radius-4 w-100 d-flex"
											[ngStyle]="{'background-color': item?.channel ? 'rgba(233, 247, 255, 1)' : ''}">{{
											item.channel ? (item.channel.replace("_", " ") |
											titlecase) : "NA" }}</span>
									</td>
									<td>{{ (item.created_at ? (item.created_at | dateConversion) : "NA")}}</td>
									<td>
										<app-table-popover [inputType]="'dropdown'"
											[inputValue]="item.owner_info?.fname + ' ' + item.owner_info?.lname"
											[formName]="'info.owner_info'" [labelName]="'Owner'" [dropDownItems]="staffMembers"
											[selectedItem]="item.owner" (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
									</td>
									<td>
										<app-table-popover [inputType]="'dateTime'" [inputValue]="item.due_date" [formName]="'due_date'"
											[labelName]="filtersLables[7].lableName"
											(update)="onUpdatePopOver($event, item.id)"></app-table-popover>
									</td> -->
                    <!-- <td>{{ item.updated_at | date: 'd/M/yy, h:mm a' }}</td> -->
                    <!-- <td>{{ item.company_id}}</td>
                                <td>website</td>
                                <td>Mailing Street</td>
                                <td>Mailing Address</td>
                                <td>Mailing City</td>
                                <td>Mailing Country</td>
                                <td>Mailing State</td>
                                <td>Shipping Address</td>
                                <td>Shipping City</td>
                                <td>Shipping Country</td>
                                <td>Zip Code</td>
                                <td>Title</td> -->
                    <td (click)="$event.stopPropagation()" class="mat-hint-none">
                      <mat-form-field appearance="outline" class="w-75" *ngIf="getRolePermissions('leads','can_update')">
                        <mat-select placeholder='Select' [(ngModel)]="item.type" [disabled]="!item?.is_contact_active">
                          <mat-option (click)="moveLeadTo(item,'ACTIVE','WON')" value="ACTIVE">Move to
                            Open</mat-option>
                          <mat-option (click)="moveLeadTo(item,'LOST','WON')" value="LOST">Move to
                            Lost</mat-option>
                          <mat-option (click)="moveLeadTo(item,'JUNK','WON')" value="JUNK">
                            Move
                            to
                            Junk</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <!-- <td (click)="$event.stopPropagation()">
                                        <div class="dropdown" ngbDropdown>
                                            <mat-icon style="z-index: 99;">more_vert</mat-icon>
                                            <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                                                <button mat-menu-item (click)="addLeadModal(item)" class="py-2">
                                                    Edit
                                                </button>
                                                <button mat-menu-item (click)="confirmDelete(item.id,'WON')"
                                                    class="py-2">
                                                    Delete
                                                </button>
                                            </mat-menu>
                                        </div>
                                    </td> -->
                  </tr>
                </tbody>
              </table>
              <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
            </div>
            <div>
              <mat-paginator [length]="leadsObj.total" [pageSize]="20" (page)="page($event, 'WON')"
                aria-label="Select page" hidePageSize="true" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="5" class="me-2" style="min-width: fit-content;">
        <a ngbNavLink class="d-flex">
          <span class="d-sm-block font-size-12">Moved to Flow</span>
        </a>
        <ng-template ngbNavContent>
          <!-- <ng-container *ngTemplateOutlet="filterSection"></ng-container> -->
          <div class="custom-table-div-crm-tabs-height ps-1 d-flex flex-column justify-content-between">
            <div class="table-responsive table-height fixed-table">
              <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="7"
                [fromLocation]="'CRM-LOST'"></app-table-skeleton>
              <table *ngIf="!tableSkeletonLoader"
                class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                <thead>
                  <tr class="table-header-color">
                    <ng-container *ngFor="let col of originalTableColumnLists">
                      <th *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                        scope="col" class=" font-size-13 pb-3  text-capitalize"
                        style="min-width: 100px;max-width: 150px;">
                        {{col.field_name === 'created_at' ? 'Created On' : col.field_name!.split("_").join(" ")}}</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody *ngIf="!display_no_data">
                  <tr class="cursor" *ngFor="let item of leadsListArray; let i = index"
                    [routerLink]="['../crm-details']" [ngClass]="{'inactiveClass': !item?.is_contact_active }"
                    [queryParams]="{ id: item.id, showtype: 'lead',contact_id: item.contact,enquiryType: 'movedto_pipeline'}">
                    <ng-container *ngTemplateOutlet="table_lists;context:{item:item}"></ng-container>

                    <!-- <td>{{item?.display_id ? item.display_id : "-"}}</td>
									<td class="home-text-color">
										{{item.requirement_title}}
									</td>
									<td>
										{{ item?.name ? item.name : "NA" }}
									</td>
									<td>{{ item.email ? item.email : "NA" }}</td>
									<td><span *ngIf="item?.phone_number">{{item.ccode}}&nbsp;</span>{{item.phone_number
										?
										item.phone_number : "NA"}}</td> -->
                    <!-- <td>{{ item.tags ? item.tags : "-" }}</td> -->
                    <!-- <td class="text-capitalize"><span class="py-1 px-2 border-radius-4 w-100 d-flex"
											[ngStyle]="{'background-color': item?.channel ? 'rgba(233, 247, 255, 1)' : ''}">{{
											item.channel ? (item.channel.replace("_", " ") |
											titlecase) : "NA" }}</span>
									</td>
									<td>{{ (item.created_at ? (item.created_at | dateConversion) : "NA")}}</td>
									<td>
										{{item.owner_info?.fname + ' ' + item.owner_info?.lname}}
									</td>
									<td>
										{{item.due_date}}
									</td> -->
                  </tr>
                </tbody>
              </table>
              <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
            </div>
            <div>
              <mat-paginator [length]="leadsObj.total" [pageSize]="20" (page)="page($event, 'PIPELINE')"
                aria-label="Select page" hidePageSize="true" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <ng-container *ngTemplateOutlet="filterSection"></ng-container>
  </div>
  <div style="border: 1px solid rgba(211, 211, 211, 0.6784313725);"></div>
  <div [ngbNavOutlet]="customNav"></div>
</div>

<ng-template #filterSection>
  <div
    class="right-controls-sec d-flex align-items-center auto-hide-scroll-bar ps-1 pb-1 bg-white customised-sec position-fixed">
    <div class="d-flex p-0 align-items-center height-max-content">
      <mat-form-field appearance="outline"
        class="margin-right-14 main-filter position-relative mat-hint-none main-filter"
        style="max-width: 150px;min-width: auto;">
        <mat-select placeholder="Leads" class="font-size-12" (ngModelChange)="onFilterChange($event)"
          [(ngModel)]="filter">
          <mat-option class="font-size-12" [value]="list.value" *ngFor="let list of leadsdropDown">{{
            list.label }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="margin-right-14 mat-hint-none position-relative"
        style="width: 212px;">
        <input style="outline: none" class="font-size-13" id="search-box" matInput placeholder="Search Here..."
          [(ngModel)]="search_global" (ngModelChange)="searchSubject.next(search_global)" />
        <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
            alt=""></mat-icon>
      </mat-form-field>
      <div matTooltip="Sort By" class="btn custom-filter-btn margin-right-14 d-flex align-items-center font-size-16"
        (click)="openFiltersModal('sort')">
        <img src="assets/images/sort_logo.svg" alt="" width="20">
        <!-- <span class="ms-2 limit-line-length-1">Sort By</span> -->
      </div>
      <div matTooltip="Filter" class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14"
        (click)="openFiltersModal('filters')">
        <img src="assets/images/filter_log.svg" alt="" width="18">
        <!-- <span class="ms-2 limit-line-length-1">Filter By</span> -->
      </div>
      <button class="btn custom-filter-btn d-flex align-items-center font-size-13 margin-right-14"
        (click)="clearCustomFilter()" *ngIf="selectedSortOptions || column_filters.length > 0"
        [disabled]="isSelected.multiple">
        <span class="limit-line-length-1">Clear filters</span>

      </button>
      <div *ngIf="enquiryType === 'ACTIVE'" matTooltip="Export"
        class="btn custom-filter-btn d-flex align-items-center font-size-13 cursor margin-right-14"
        (click)="downloadEnquiries()">
        <img src="assets/images/newUI/CRM/export_icon.svg" alt="" width="18">
      </div>
      <button *ngIf="enquiryType === 'ACTIVE' &&  (getRolePermissions('leads','can_create') || getRolePermissions('leads','can_update'))" matTooltip="Import"
        class="btn custom-filter-btn d-flex align-items-center font-size-13 cursor margin-right-14"
        (click)="importModalDialog(importModel)" [disabled]="isSelected.multiple">
        <img src="assets/images/newUI/CRM/import.svg" alt="" width="18">
      </button>
      <div matTooltip="Uploaded Files" *ngIf="activeId === 1" [routerLink]="['/crm/import-files']"
        [queryParams]="{type: 'enquiry',showType:'upload'}"
        class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14 cursor">
        <img src="assets/images/newUI/CRM/uploaded_files.svg" width="18" alt="">
      </div>
        <div matTooltip="Downloaded Files" *ngIf="activeId === 1" [routerLink]="['/crm/import-files']"
          [queryParams]="{type: 'lead',showType:'download'}"
          class="btn custom-filter-btn d-flex align-items-center font-size-13 margin-right-14 cursor">
          <img src="assets/images/newUI/CRM/downloaded_icons.svg" width="18" alt="">
        </div>
      
      <div class="btn custom-filter-btn d-flex align-items-center px-2 margin-right-14 contacts-filter-lable">
        <!-- <span class="me-3 " (click)="checkedContacts()">{{contactsJson.Filter_label}}</span> -->
        <span mat-raised-button class="menuTrigger  material-icons cursor font-size-20" [matMenuTriggerFor]="filter_search_menu"
          matTooltip="Table Columns">filter_list</span>

        <mat-menu #filter_search_menu="matMenu" class="tableColumnLists customised-sec">
          <mat-form-field (click)="$event.stopPropagation()" appearance="outline" id="inputEle"
            class="px-3 py-2 mat-hint-none searching-box">
            <input (keyup)="onColumnFilterSearch($event)" [(ngModel)]="columnFilterSearch" style="outline: none"
              matInput placeholder="Search Column" />
            <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
                alt=""></mat-icon>
          </mat-form-field>
          <div class="list-sec" (click)="$event.stopPropagation()">
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
              <div cdkDrag *ngFor="let item of tableColumns;let i = index">
                <button mat-menu-item id="{{ i }}" (click)="$event.stopPropagation()"
                  *ngIf="item.field_name !== 'ccode' && item.field_name !== 'assignee'"><!--[disabled]="['enquiry_id','requirement_title','email','tags'].includes(item?.field_name)"-->
                  <mat-checkbox [checked]="item?.status" (change)="selectFilterLable($event,item,i)"
                    class="text-capitalize">{{item?.field_name === 'created_at' ? 'Created On' : (item?.field_name!.split("_").join(" ") | titlecase) ||
                    ''}}</mat-checkbox>
                </button>
              </div>
            </div>
            <div *ngIf="tableColumnNoResFound" class="d-flex align-items-center justify-content-center"
              style="height:100%">
              No Result Found
            </div>
          </div>
          <div class="d-flex justify-content-end apply-btn-sec px-3">
            <button class="btn btn-primary d-flex align-items-center font-size-13" [disabled]="tableColumnNoResFound"
              (click)="!tableColumnNoResFound && onUpdateTableColumns()">Apply
              & Save</button>
          </div>
        </mat-menu>
      </div>
      <button class="btn btn-primary d-flex align-items-center margin-right-14 font-size-13"
        *ngIf="enquiryType === 'ACTIVE' && featuresData['enquiry_add'] && createEnquiry && featuresData['contact'] && getRolePermissions('leads','can_create')"
        [disabled]="isSelected.multiple" (click)="addLeadModal()">
        <img src="assets/images/newUI/CRM/add-icon.svg" alt="">
        <span class="ms-1 limit-line-length-1 text-left">{{'Lead'}}</span>
      </button>
      <button class="btn btn-primary d-flex align-items-center font-size-13 me-1"
        *ngIf="loggedInUser.data.role === 'ADMIN' && enquiryType === 'ACTIVE'" [disabled]="isSelected.multiple"
        (click)="openTaskBoardPreview()">
        <img src="assets/images/newUI/CRM/add-icon.svg" alt="">
        <span class="ms-1 limit-line-length-1 text-left">Stage</span>
      </button>
      <!-- <mat-form-field appearance="outline" class="contactsfilter ms-2 mat-hint-none main-filter">
        <mat-select placeholder="{{ leadJson.heading_label }}" (ngModelChange)="onFilterChange($event)"
          [(ngModel)]="filter">
          <mat-option class="font-size-12" [value]="list.value" *ngFor="let list of leadsdropDown">{{
            list.label }}</mat-option>
        </mat-select>
      </mat-form-field>
      <button class="btn btn-primary d-flex align-items-center font-size-13 ms-2" (click)="openFiltersModal('sort')"
        [disabled]="isSelected.multiple">
        Sort
      </button>
      <button class="btn btn-primary d-flex align-items-center font-size-13 ms-2" (click)="openFiltersModal('filters')"
        [disabled]="isSelected.multiple">
        Filters
      </button>
      <button class="btn btn-primary d-flex align-items-center font-size-13 ms-2" (click)="clearCustomFilter()"
        *ngIf="selectedSortOptions || column_filters.length > 0" [disabled]="isSelected.multiple">
        Clear filters
      </button> -->
    </div>
    <div class="d-flex p-0 justify-content-end align-items-center height-max-content">
      <!-- <mat-form-field appearance="outline" class="me-2 mat-hint-none position-relative searching-box">
        <input style="outline: none" matInput placeholder={{leadJson.search_placeholder}} [(ngModel)]="search_global"
          (ngModelChange)="searchSubject.next(search_global)" />
        <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
            alt=""></mat-icon>
      </mat-form-field> -->

      <!-- <button mat-raised-button matTooltip="Add Task Board Stages" [disabled]="isSelected.multiple"
        *ngIf="loggedInUser.data.role === 'ADMIN' && enquiryType === 'ACTIVE'"
        aria-label="Button that displays a tooltip when focused or hovered over" type="button"
        class="btn border-0 p-0 d-flex align-items-center"
        (click)="openTaskBoardPreview()">
        <span class="p-1 me-2 rounded bg-gray-light d-flex align-items-center justify-content-center">
          <img src="../../../assets/images/icons_add.svg" class="cursor" width="16">
        </span>
      </button> -->
      <!-- <button mat-raised-button matTooltip="Export" *ngIf="enquiryType === 'ACTIVE'"
        aria-label="Button that displays a tooltip when focused or hovered over" type="button"
        class="btn border-0 p-0 d-flex align-items-center">
        <mat-icon class="me-2 " (click)="downloadEnquiries()">exit_to_app</mat-icon>
      </button> -->
      <!-- <button mat-raised-button matTooltip="Import" *ngIf="enquiryType === 'ACTIVE'" [disabled]="isSelected.multiple"
        aria-label="Button that displays a tooltip when focused or hovered over" type="button"
        class="btn border-0 p-0 d-none align-items-center">
        <mat-icon class="me-2 " (click)="importModalDialog(importModel)">move_to_inbox</mat-icon>
      </button> -->


      <!-- <mat-icon class="me-3 ">local_offer</mat-icon> -->
      <!-- <mat-icon class="me-3 ">cloud_upload</mat-icon> -->
      <!-- <mat-icon class="me-3"> reorder</mat-icon> -->
      <!-- <div class="d-flex contacts-filter-lable">
             <span class="me-3 ">{{leadJson.Filter_label}}</span>
             <span mat-raised-button class="menuTrigger  material-icons cursor"
            [matMenuTriggerFor]="filter_search_menu">filter_list</span>
                    
            <mat-menu #filter_search_menu="matMenu">
            <mat-form-field appearance="outline" class="mx-3 my-2 mat-hint-none"
            (click)="$event.stopPropagation()">
            <input style="outline: none" matInput placeholder={{leadJson.search_placeholder}} />
            <mat-icon matPrefix>search</mat-icon></mat-form-field>
            <div class="" *ngFor="let item of filtersLables; let i = index">
            <button mat-menu-item id="{{ i }}" (click)="selectFilterLable($event)">
            <mat-checkbox [checked]="item.checked">{{ item.lableName }}</mat-checkbox>
            </button>
            </div>
            </mat-menu>
            </div> -->
    </div>
  </div>
</ng-template>

<ng-template #importModel let-modal>
  <div class="modal-header">
    <h5 class="modal-title ">
      Import Lead
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeImportModal()"></button>
  </div>
  <div class="modal-body">
    <div class="" *ngIf="stepper === 1">
      <div class="contact-dotted-border text-center mb-3">
        <input type="file" class="form-control" (change)="onFileChange($event)" />
      </div>
      <a class="cursor" (click)="downloadSampleFile()">Download Sample
        File</a>

      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-primary" (click)="importLeads()">
          Import
        </button>
      </div>
    </div>
    <div class="d-none" *ngIf="stepper === 2">
      <h6 class="fw-600">What do you want to do with the records in the file?</h6>
      <mat-radio-group [(ngModel)]="operation">
        <mat-radio-button value="CREATE" *ngIf="getRolePermissions('leads','can_create')">Add as new Lead</mat-radio-button>
        <mat-radio-button value="UPDATE" *ngIf="getRolePermissions('leads','can_update')">Update existing Lead only</mat-radio-button>
        <mat-radio-button value="BOTH" *ngIf="getRolePermissions('leads','can_create') && getRolePermissions('leads','can_update')">Both</mat-radio-button>
      </mat-radio-group>

      <div class="d-flex justify-content-end mt-4">
        <button type="button" class="btn btn-light me-2" (click)="closeImportModal()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="fieldsMapping()">
          Next
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-item="item" #table_lists>
  <ng-container *ngFor="let col of originalTableColumnLists">
    <td *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')" style="max-width: 200px;"
      [ngStyle]="{'min-width': item[col.field_name] && item[col.field_name].length < 10 ? '110px' : !item[col.field_name] ? '100px' : '150px'}">
      <div *ngIf="col.system_field">
        <span *ngIf="col.field_name === 'phone_number'">{{item[col.field_name] ?
          item.ccode :
          ''}}&nbsp;{{item[col.field_name] || 'NA'}}</span>
        <span class="limit-line-length-1" *ngIf="col.field_name === 'owner'">{{(item?.owner_info?.fname
          || '-') + ' ' +
          (item?.owner_info?.lname || '')}}</span>
        <span class="limit-line-length-1" *ngIf="col.field_name === 'company'">{{item.company_name ||
          'NA'}}</span>
        <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE_TIME'">
          {{item[col.field_name]
          | dateConversion }} {{ item[col.field_name] | date: 'h:mm a' || 'NA'}}
        </span>
        <div *ngIf="col.field_name === 'tags'" class="d-flex">
          <span class="text-name m-0" *ngIf="!item['tag_name']" style="width: -webkit-fill-available;">NA</span>
          <p class="text-name text-wrap w-100 cursor" style="margin-bottom: 0px !important;">
            <app-table-popover [inputType]="'dropdown'" [inputValue]="item?.tag_name" [formName]="'tags'"
              [labelName]="'Tag'" [dropDownItems]="tagsListArray" [selectedItem]="item.tags"
              (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
          </p>
          <!-- <p *ngIf="item['tag_name']" class="text-name m-0 d-inline-flex align-items-center border-radius-50">
            <span class="limit-line-length-1 word-break"> {{item?.tag_name}}</span>
          </p> -->

        </div>
        <div *ngIf="col.field_name === 'channel'">
          <p *ngIf="item['channel']" style="background-color: #FCEED8;color: #73321B;"
            class="text-name m-0 d-inline-flex align-items-center px-2 border-radius-50">
            <span class="border-radius-50 m-0 tag-color me-1" style="height: 2px; padding: 3px;"
              [style]="{'background-color': '#73321B'}"></span>
            <span class="limit-line-length-1 word-break"> {{item['channel']}}</span>
          </p>
          <p class="text-name m-0" *ngIf="!item['channel']">NA</p>
        </div>
        <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE'">
          {{item[col.field_name]
          | dateConversion }}
        </span>
        <span class="limit-line-length-1" *ngIf="col.field_name === 'contact'">
          {{ item.name || 'NA'}}
        </span>
        <a class="limit-line-length-1" target="_blank" *ngIf="col.data_type === 'WEBSITE' && item[col.field_name]" [href]="item[col.field_name]" (click)="$event.stopPropagation()">
          {{item[col.field_name]}}
        </a>
        <span class="limit-line-length-1" *ngIf="col.data_type === 'WEBSITE' && !item[col.field_name]">NA</span>
        <span class="limit-line-length-1 word-break"
          *ngIf="col.field_name !== 'contact' && col.field_name !== 'requirement_title' && col.field_name !== 'tags' && col.data_type !== 'WEBSITE' && col.field_name !== 'channel' && col.field_name !== 'phone_number' && col.data_type !== 'DATE_TIME' && col.data_type !== 'DATE' && col.field_name !== 'ccode' && col.field_name !== 'owner' && col.field_name !== 'company'"
          [innerHTML]="item[col.field_name]
          || 'NA'"></span>
        <span *ngIf="col.field_name === 'requirement_title'">
          <app-table-popover [inputType]="'text'" [inputValue]="item[col.field_name]" [labelName]="'Name'"
            [formName]="'requirement_title'" (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
        </span>
      </div>
      <div *ngIf="!col.system_field">
        <ng-container *ngFor="let customField of item?.custom_lead_fields">
          <ng-container *ngIf="customField.label === col.field_name">
            <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE'">
              {{customField.data ? (customField.data | dateConversion) : 'NA'}}
            </span>
            <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE_TIME'">
              {{customField.data
              | dateConversion }} {{ customField.data | date: 'h:mm a' || 'NA'}}
            </span>
            <span *ngIf="col.data_type === 'DATE_RANGE'">
              <span class="limit-line-length-1" *ngIf="customField.data && customField.data.split('&').length > 1">
                {{customField?.data.split("&")[0].split("-").reverse().join("/") + ' - ' +
                customField?.data.split("&")[1].split("-").reverse().join("/")}}
              </span>
              <span *ngIf="!customField.data">
                NA
              </span>
            </span>
            <span class="limit-line-length-1" *ngIf="col.data_type === 'PHONE_NUMBER'">
              {{customField?.data?.custom_ccode ?
              customField?.data?.custom_ccode :
              ''}}&nbsp;{{customField?.data?.custom_phone_number || 'NA'}}
            </span>
            <span class="limit-line-length-1" *ngIf="col.data_type === 'FILE'">
              {{customField?.data?.file_name || 'NA'}}
            </span>
            <a class="limit-line-length-1" target="_blank" *ngIf="col.data_type === 'WEBSITE' && customField.data" [href]="customField.data" (click)="$event.stopPropagation()">
              {{customField.data}}
            </a>
            <span class="limit-line-length-1" *ngIf="col.data_type === 'WEBSITE' && !customField.data">NA</span>
            <span class="limit-line-length-1 word-break"
              *ngIf="col.data_type !== 'PHONE_NUMBER' && col.data_type !== 'DATE' && col.data_type !== 'WEBSITE' && col.data_type !== 'DATE_TIME' && col.data_type !== 'DATE_RANGE' && col.data_type !== 'FILE'"
              [innerHTML]="customField.data || 'NA'">
            </span>

          </ng-container>
        </ng-container>
      </div>
    </td>
  </ng-container>
</ng-template>
<ng-template let-item="item" #export_item_selections>
  
</ng-template>

<div #dynamicComponentContainer></div><!--For customForm field modal opening-->
<div class="container-fluid custom-height tags">
    <ng-container *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="card pt-2">
            <div class="d-flex justify-content-between">
                <div class="d-inline-flex text-wrap">
                    <nav mat-tab-nav-bar [backgroundColor]="background" [tabPanel]="tabPanel" mat-stretch-tabs="false"
                        mat-align-tabs="start" animationDuration="0ms">
                        <a mat-tab-link *ngFor="let link of listOfModuleNames" (click)="onTabChange(link.value)"
                            [active]="activeLink === link.value" class="text-primary">
                            {{link.label}}
                        </a>
                    </nav>
                </div>
                <div class="mt-1 me-2">
                    <button class="btn btn-primary btn-sm px-4 d-flex align-items-center"
                        (click)="openAddObjectsModal(addObjects)">
                        <mat-icon>add</mat-icon>Add Record
                    </button>
                </div>
            </div>
        </div>
        <mat-tab-nav-panel #tabPanel>
            <div class="d-flex flex-column justify-content-between" style="height: calc(100vh - 216px);">
                <div class="card p-2 h-100 mb-0 pb-0 " style="overflow-y: auto;">
                    <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="column_names.length"
                        [fromLocation]="'OBJECTS'"></app-table-skeleton>
                    <table *ngIf="!tableSkeletonLoader" class="table align-middle mb-0 file-table w-100">
                        <thead>
                            <tr>
                                <th scope="col" class="text-primary pb-3" *ngFor="let col of column_names">{{col}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="" *ngFor="let item of objectsListArray; let i = index">
                                <td class="text-capitalize">
                                    {{ item.name }}
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <mat-icon class="me-2 font-size-18 cursor"
                                            (click)="openAddObjectsModal(addObjects,item)">edit</mat-icon>
                                        <mat-icon class="font-size-18 cursor"
                                            (click)="deleteSelectedObject(item.id)">delete</mat-icon>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <mat-paginator #paginator [length]="this.totalObjects" [pageSize]="page_size" (page)="page($event)"
                    aria-label="Select page" hidePageSize="true" showFirstLastButtons>
                </mat-paginator>
            </div>
        </mat-tab-nav-panel>
    </ng-container>
    <ng-container *ngIf="loggedInUser.data.role !== 'ADMIN'">
        <div class="d-flex align-items-center justify-content-center">
            <h6 class="text-secondary">You are not authorized to view this.</h6>
        </div>
    </ng-container>
</div>
<ng-template #addObjects let-modal>
    <div class="modal-header pb-0 border-0">
        <h6 class="modal-title fw-600" id="label">{{addObjectForm.value.id ? 'Update' : 'Add'}} Record</h6>
        <mat-icon class="cursor" (click)="modalRef.close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <form [formGroup]="addObjectForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-12">
                    <label class="mb-2 fw-500">Record Name <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="Enter object name" formControlName="name" />
                        <mat-error class="error d-flex align-item-center" *ngIf="submitted && f['name'].errors">
                            <mat-icon class="font-size-12 mt-1 me-2">info</mat-icon>
                            <span *ngIf="f['name'].errors['required']">Record name required</span>
                            <span *ngIf="f['name'].errors['pattern']">Invalid Record name</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <label class="mb-2 fw-500">Record Plural Name</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="Enter plural name"
                            formControlName="name_plural" />
                        <mat-error class="error d-flex align-item-center" *ngIf="submitted && f['name_plural'].errors">
                            <mat-icon class="font-size-12 mt-1 me-2">info</mat-icon>
                            <span *ngIf="f['name_plural'].errors['required']">Record plural name required</span>
                            <span *ngIf="f['name_plural'].errors['pattern']">Invalid record plural name</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <label class="mb-2 fw-500">Primary Login Type </label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select name="" id="" formControlName="primary_login_type" placeholder="Select Login Type">
                            <mat-option value="email">Email</mat-option>
                            <mat-option value="phone_number">Phone Number</mat-option>
                            <mat-option value="no_auth">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 d-flex align-items-center">
                    <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                        formControlName="is_delete_record" />
                    <span class="ms-1">
                        Delete Record
                    </span>
                </div>
                <div class="col-sm-12 mt-2" *ngIf="addObjectForm.value.is_delete_record">
                    <label class="mb-2 fw-500">No of Days</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="days" formControlName="delete_record_ttl" />
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <label class="mb-2 fw-500">Features </label>
                    <div class="d-flex align-items-center mb-1">
                        <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                            formControlName="activities" />
                        <span class="ms-1">
                            Activities
                        </span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                        <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                            formControlName="task_board" />
                        <span class="ms-1">
                            Task Board
                        </span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                        <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                            formControlName="notes" />
                        <span class="ms-1">
                            Notes
                        </span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                        <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                            formControlName="files" />
                        <span class="ms-1">
                            Files
                        </span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                        <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                            formControlName="calls" />
                        <span class="ms-1">
                            Calls
                        </span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                        <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                            formControlName="orders" />
                        <span class="ms-1">
                            Orders
                        </span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                        <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                            formControlName="schedulers" />
                        <span class="ms-1">
                            Schedulers
                        </span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                        <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                            formControlName="flow_cards" />
                        <span class="ms-1">
                            Flow Cards
                        </span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                        <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                            formControlName="meetings" />
                        <span class="ms-1">
                            Meetings
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end align-items-center mt-3">
                <button type="submit" class="btn btn-primary"><span *ngIf="addObjectForm.value?.id">Update</span><span
                        *ngIf="!addObjectForm.value?.id">Save</span></button>
            </div>
        </form>
    </div>
</ng-template>
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject, debounceTime, takeUntil } from 'rxjs';
import { countryData } from 'src/app/core/data/countryData';
import { currencyJson } from 'src/app/core/data/currencyData';
import { time_zone_json } from 'src/app/core/data/mobile_json';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { COMPANYSETTINGS, LOCATIONS } from 'src/app/core/data/settings_json';
import { SettingsService } from 'src/app/core/service/settings.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { environment } from 'src/environments/environment';
import { MatTooltip } from '@angular/material/tooltip';
import { AuthService } from 'src/app/core/service/auth.service';
import { Router } from '@angular/router';
import { maximumNumberAllowed, onOnlyNumbers, onParseDomValue, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  countryjson = countryData;
  currencyjson = currencyJson;
  timezonejson = time_zone_json;
  errorjson = ERRORJSON[0];
  companysettings = COMPANYSETTINGS[0];
  locationsettings = LOCATIONS[0];
  loggedInUser: any;
  activeId = 1;
  public bankFilterCurrencyCtrl: FormControl = new FormControl();
  public filteredCurrencyBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onCurrencyDestroy = new Subject<void>();
  public bankFilterTimezoneCtrl: FormControl = new FormControl();
  public filteredTimezoneBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onTimezoneDestroy = new Subject<void>();
  companySettingsForm!: FormGroup;
  submited = false;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  company_logo: File[] = [];
  locationData!: FormGroup;
  generalSettingsForm!: FormGroup;
  isCompanySubmitted = false;
  branches: any = [];
  locationSubmit = false;
  editBusinessUrl = false;
  fileName!: string;
  presignedurl = environment.preSendUrl;
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  @ViewChild('locationFirstElement') locationFirstElement!: ElementRef;
  @ViewChild('tp') _matTooltip!: MatTooltip;
  chatBotId: any;
  activatedRouter = '';
  @Output() onfinish: EventEmitter<any> = new EventEmitter();
  priceSettingsForm!: FormGroup;
  isPriceSubmitted = false;
  src = '';
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };
  noDataFound = false;
  tableSkeletonLoader = true;
  searchLocation: Subject<any> = new Subject<any>();
  googleLocationList: any[] = [];


  constructor(private formBuilder: FormBuilder, private settingsService: SettingsService, private modalService: NgbModal,
    private confirmationMsz: ConfirmationMszService, private awsUtilService: AwsUtilsService,
    private authService: AuthService, private router: Router, private toastrService: ToastrService) {
    this.searchLocation.pipe(
      debounceTime(300)
    ).subscribe((searchTerm: any) => {
      if (searchTerm.term.length >= 3) {
        this.initAutocomplete(searchTerm.term);
      }
    });
  }
  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditorLimit = Object.assign(this.ckeditorLimit, { ...contentLength });
    });
  }

  ngOnInit() {
    this.src = window.location.hostname.includes('app.brexa.ai') ? '.brexa.ai' : window.location.hostname.includes('demo.brexa.ai') ? '.demo.brexa.ai' : '.stage.brexa.ai';
    this.activatedRouter = this.router.url;
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.filteredCurrencyBanks.next(this.currencyjson.slice());
    this.bankFilterCurrencyCtrl.valueChanges
      .pipe(takeUntil(this._onCurrencyDestroy))
      .subscribe(() => {
        this.filterCurrencyBanks();
      });
    this.filteredTimezoneBanks.next(this.timezonejson.slice());
    this.bankFilterTimezoneCtrl.valueChanges
      .pipe(takeUntil(this._onTimezoneDestroy))
      .subscribe(() => {
        this.filterTimezoneBanks();
      });
    this.Initilize_General_Settings_Form();
    this.getGeneralSettingsDetails();
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
  }

  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();
    const dataWithoutTags = onParseDomValue(inputData);
    this.ckeditorLimit = Object.assign(this.ckeditorLimit, { actualLimit: dataWithoutTags.length || 0 });
    // const editorElement = document.querySelector('.ck-editor__editable_inline');
    // if (editorElement) {
    //   editorElement.scrollTop = editorElement.scrollHeight;
    // }
  }

  tabChange(event: any) {
    this.activeId = event.nextId;
    if (this.activeId === 1) {
      // this.getGeneralSettingsDetails();
    } else if (this.activeId === 2) {
      this.Intilizie_Company_settings_form();
      this.getCompanySettingsDetails();
      this.editBusinessUrl = false;

      setTimeout(() => {
        this.myFirstInput?.nativeElement.focus();
      }, 0);
    } else if (this.activeId === 3) {
      this.Intilizie_price_settings_form();
      this.getPriceDetails();
    } else if (this.activeId === 4) {
      this.intilizeLocationForm();
      this.getLocationDetails();
    }
  }

  Initilize_General_Settings_Form(data?: any) {
    this.generalSettingsForm = this.formBuilder.group({
      country: [data ? data.country : '', Validators.required],
      // currency: [data ? data.currency : '', Validators.required],
      timezone: [data ? data.timezone : '', Validators.required],
      ccode: [data ? data.ccode : '+91', [Validators.required]],
      dateformat: [data ? data.dateformat : 'MM/dd/YYYY'],
      slot_duration: [data ? data.slot_duration : '30 MIN']
    });
  }

  Intilizie_Company_settings_form(data?: any) {
    this.companySettingsForm = this.formBuilder.group({
      id: [data ? data.id : ''],
      name: [data ? data.name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)]],
      email: [data ? data.email : '', Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])],//eslint-disable-line 
      website: [data ? data.website : '', Validators.compose([Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')])],
      description: [data ? data.description : ''],
      language: [data ? 'english' : 'english'],
      image: [data ? data.image : ''],
      website_url: [data ? data.website_url : ''],
      instagram_url: [data ? data.instagram_url : ''],
      twitter_url: [data ? data.twitter_url : ''],
      linkedin_url: [data ? data.linkedin_url : ''],
      youtube_url: [data ? data.youtube_url : ''],
      business_type: [data ? { value: data.business_type, disabled: true } : ''],
      industry_type: [data ? { value: data.industry_type, disabled: true } : ''],
      // gst_number: [data ? data.gst_number : ''],
      slug: [data ? data.slug : '', [Validators.required, Validators.pattern(/^[a-z][a-z0-9]{0,}$/)]],
      customer_primary_login_type: [data ? data?.customer_primary_login_type : ''],// [Validators.required]
      tax: new FormArray([])
    });
    this.fileName = data ? data.image : '';
  }

  get taxes_array(): FormArray {
    return this.companySettingsForm.get("tax") as FormArray;
  }

  addTaxes(data?: any) {
    if (this.taxes_array.length < 3) {
      this.taxes_array.push(this.intilizeTax(data));
    } else {
      this.toastrService.error("Cannot add more than three taxes");
    }
  }

  removeTax(index: number) {
    this.taxes_array.removeAt(index);
  }

  intilizeTax(data?: any) {
    return new FormGroup({
      label: new FormControl(data ? data.label : '', Validators.required),
      value: new FormControl(data ? data.value : '', Validators.required)
    });
  }

  Intilizie_price_settings_form(data?: any) {
    this.priceSettingsForm = this.formBuilder.group({
      id: [data ? data.id : ''],
      currency: [data ? data.currency : '', [Validators.required]],
      currency_name: [data ? data.currency : ''],
      price_symbol_position: [data ? data.price_symbol_position : '', [Validators.required]],
      no_of_decimals: [data ? data.no_of_decimals : '', [Validators.required]],
      rounding_method: [data ? data.rounding_method : ''],
      // price_seperator: [data ? data.price_seperator : ''],
      cod_status: [data ? data.cod_status : false, [Validators.required]]
    });
    if (data) this.settingsService.getInvoiceCount().subscribe((resp: any) => {
      if (resp.data.count !== 0) this.priceSettingsForm.controls['currency'].disable();
      else this.priceSettingsForm.controls['currency'].enable();
    });

  }

  get f() {
    return this.generalSettingsForm.controls;
  }

  get companyForm() {
    return this.companySettingsForm.controls;
  }

  get priceForm() {
    return this.priceSettingsForm.controls;
  }

  onGeneralSettingsDetailsSubmit() {
    this.submited = true;
    if (this.generalSettingsForm.valid) {
      this.settingsService.postGeneralSettings(this.generalSettingsForm.value).subscribe(() => {
        this.getGeneralSettingsDetails();
        this.authService.getData();
        if (this.activatedRouter === '/onboarding') this.onActiveChange(2);
      });
    }
  }

  getGeneralSettingsDetails() {
    this.settingsService.getGeneralSettings().subscribe((resp: any) => {
      this.Initilize_General_Settings_Form(resp.data);

    });
  }

  onCompanyDetailsSubmit() {
    this.isCompanySubmitted = true;
    if (!this.fileName) this.fileName = this.companySettingsForm.value.image;
    if (this.companySettingsForm.valid && this.fileName) {
      this.companySettingsForm.controls['image'].setValue(this.fileName);
      this.companySettingsForm.controls['slug'].setValue((this.companySettingsForm.value.slug.replace(" ", "")).toLowerCase());
      this.companySettingsForm.controls['email'].setValue(this.companySettingsForm.value.email.toLowerCase());
      this.settingsService.postCompanySettings(this.companySettingsForm.value).subscribe(() => {
        this.company_logo = [];
        this.getCompanySettingsDetails();
        this.editBusinessUrl = false;
        if (this.activatedRouter === '/onboarding') this.onActiveChange(3);
      });
    }
  }

  getCompanySettingsDetails() {
    this.settingsService.getCompanySettings().subscribe((resp: any) => {
      this.Intilizie_Company_settings_form(resp.data);
      const dataWithoutTags = onParseDomValue(resp.data?.description);
      this.ckeditorLimit['actualLimit'] = dataWithoutTags?.length;
      resp.data.tax.forEach((element: any) => {
        this.addTaxes(element);
      });
    });
  }
  businessURLUpdate(value: boolean) {
    this.editBusinessUrl = value;
  }
  onSelect(event: any) {
    this.company_logo = [...event.addedFiles];
    if (this.company_logo && this.company_logo.length >= 1) {
      this.onRemove(this.company_logo[0]);
    }
    if (event.addedFiles[0].size > 15 * 1048576) {
      this.toastrService.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    this.company_logo = [...event.addedFiles];
    this.awsUtilService.getUploadFileLink(this.company_logo[0].type, this.company_logo[0].name.split('.')[1]).subscribe((resp: any) => {
      this.fileName = this.presignedurl + resp.data.file_name;
      this.awsUtilService.uploadFile(this.company_logo[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
      });
    });
  }

  onRemove(event: any) {
    this.fileName = '';
    this.company_logo.splice(this.company_logo.indexOf(event), 1);
  }

  getCompanyDetailsBylinkedIn() {
    const name = this.companySettingsForm.value.website.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/$/, '');
    this.settingsService.getLinkedInByCompanyName(name).subscribe((resp: any) => {
      this.companySettingsForm.patchValue({ twitter_url: 'https://x.com/' + resp.data?.twitter?.handle, linkedin_url: 'https://www.linkedin.com/' + resp.data?.linkedin?.handle, description: resp.data?.description, company_logo: resp.data?.logo });
    });
  }
  // For Google Search
  initAutocomplete(eventData: any) {
    this.settingsService.getGoogleLocations(eventData).subscribe((resp: any) => {
      this.googleLocationList = resp.data.predictions;
    });
    // const input = document.getElementById('pac-input-1') as HTMLInputElement;
    // const searchBox = new google.maps.places.SearchBox(input);
    // searchBox.addListener("places_changed", () => {
    //   const places = searchBox.getPlaces();
    //   if (places!.length === 0) {
    //     return;
    //   } else if (places?.length === 1) {
    //     this.locationData.controls['location'].setValue(places[0].name + ',' + places[0].formatted_address);
    //   }
    // });
  }

  intilizeLocationForm(data?: any) {
    this.locationData = this.formBuilder.group({
      id: [data ? data.id : ''],
      name: [data ? data.name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)]],
      location: [data ? data.location : '', Validators.required],
      status: [data ? data.status : 'true', Validators.required],
      default: [data ? data.default : 'false', Validators.required],
      email: new FormControl(data?.email ? data?.email : '', Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])),//eslint-disable-line
      phone_number: new FormControl(data?.phone_number ? data?.phone_number : '', Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])),
      ccode: new FormControl(data?.ccode ? data?.ccode : this.authService.getCompanyData().ccode, [Validators.required])
    });
  }

  get locationForm() {
    return this.locationData.controls;
  }

  addLocation(model: any, data?: any) {
    this.intilizeLocationForm(data);
    this.modalService.open(model, { size: 'md' });

    setTimeout(() => {
      document.getElementById('pac-input-1')?.focus();
    }, 20);
  }

  confirm(deleteId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteLocation(deleteId);
      }
    });
  }

  deleteLocation(id: string) {
    this.settingsService.deleteLocationSettings(id).subscribe(() => {
      this.getLocationDetails();
    });
  }

  onLocationSubmit() {
    this.locationSubmit = true;
    if (this.locationData.valid) {
      this.locationData.controls['email'].setValue(this.locationData.value.email.toLowerCase());
      if (!this.locationData.value.id) {
        this.settingsService.postLocationSettings(this.locationData.value).subscribe(() => {
          this.getLocationDetails();
        });
      } else {
        this.settingsService.putLocationSettings(this.locationData.value).subscribe(() => {
          this.getLocationDetails();
        });
      }
      this.modalService.dismissAll();
    }
  }

  toggleVisibility(item: any) {
    this.settingsService.putLocationSettings(item).subscribe(() => {
      this.getLocationDetails();
    });
  }

  getLocationDetails() {
    this.tableSkeletonLoader = true;
    this.settingsService.getLocationSettings().subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.branches = resp.data;
      this.branches.length === 0 ? (this.noDataFound = true) : (this.noDataFound = false);
    });
  }

  getPriceDetails() {
    this.settingsService.getPriceSettings().subscribe((resp: any) => {
      this.Intilizie_price_settings_form(resp.data);
    });
  }

  onPriceDetailsSubmit() {
    this.isPriceSubmitted = true;
    if (this.priceSettingsForm.valid) {
      this.settingsService.postPriceSettings(this.priceSettingsForm.value).subscribe(() => {
        this.getPriceDetails();
        this.authService.getData();
        if (this.activatedRouter === '/onboarding') this.onActiveChange(4);
      });
    }
  }

  onCurrencyChange(ev: any) {
    this.priceSettingsForm.controls['currency_name'].setValue(ev.currency);
  }
  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterCurrencyBanks() {
    if (!this.currencyjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCurrencyCtrl.value;
    if (!search) {
      this.filteredCurrencyBanks.next(this.currencyjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCurrencyBanks.next(
      this.currencyjson.filter(bank => (bank.currency.toLowerCase().indexOf(search) > -1) ||
        bank.abbreviation.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterTimezoneBanks() {
    if (!this.timezonejson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterTimezoneCtrl.value;
    if (!search) {
      this.filteredTimezoneBanks.next(this.timezonejson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    const filteredTimezones: any = this.timezonejson.map((element: any) => {
      const filteredCountries = element.countries.filter((bank: any) => bank.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
      return { ...element, countries: filteredCountries };
    }).filter((element: any) => element.countries.length > 0);
    // filter the banks
    this.filteredTimezoneBanks.next(filteredTimezones);
  }
  //*******************************Search filters END******************************************** *//
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onCurrencyDestroy.next();
    this._onCurrencyDestroy.complete();
    this._onTimezoneDestroy.next();
    this._onTimezoneDestroy.complete();
  }

  onClipboardCopy(e: any) {
    setTimeout(() => {
      this._matTooltip.show();
      this._matTooltip.message = e ? 'Copied!' : 'Copy to clipboard';
    });
  }

  onActiveChange(activeId: number) {
    if (activeId === 2) {
      this.activeId = activeId;
      this.Intilizie_Company_settings_form();
      this.getCompanySettingsDetails();
      this.editBusinessUrl = false;
      setTimeout(() => {
        this.myFirstInput.nativeElement.focus();
      }, 0);
      this.onfinish.emit(false);
    } else if (activeId === 3) {
      this.activeId = activeId;
      this.Intilizie_price_settings_form();
      this.getPriceDetails();
    } else if (activeId === 4) {
      this.activeId = activeId;
      this.intilizeLocationForm();
      this.getLocationDetails();
    } else if (activeId === 5) {
      this.onfinish.emit(true);
    }
  }

  keyDownEvent(event: any, maxLength: number) {
    const maxNum = maximumNumberAllowed(event, maxLength);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }
}

<div class="modal-header">
    <h5 *ngIf="!company?.id" class="modal-title fw-600">Add Company</h5>
    <h5 *ngIf="company?.id" class="modal-title fw-600">Update Company</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>
<div class="modal-body">
    <div class="row mb-2">
        <form [formGroup]="companyForm">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 ">{{companiesJson.feild_webiste}}
                        <!-- <small
                            class="text-danger">*</small> -->
                    </label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder={{companiesJson.feild_webiste}}
                            formControlName="website"  #myFirstInput (focusout)="getCompanyDetailsBylinkedIn()"/>
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['website'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <!-- <span *ngIf="f['website'].errors['required']">{{companiesJson.feild_webiste}}
                                {{errorjson.required}}</span> -->
                            <span *ngIf="f['website'].errors['pattern']">Valid URL is
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 ">{{companiesJson.feild_company_name}} <span
                            class="text-danger">*</span></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder={{companiesJson.feild_company_name}}
                            formControlName="name" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['name'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['name'].errors['required']">{{companiesJson.feild_company_name}}
                                {{errorjson.required}}</span>
                            <span *ngIf="f['name'].errors['pattern']">Only alphanumeric are accepted.</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                    <label class="fw-600 mb-2 ">Email
                        <!-- <small class="text-danger">*</small> -->
                    </label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="Email" formControlName="email" />
                        <mat-error class="error d-flex" *ngIf="submitted && f['email'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <!-- <span *ngIf="f['email'].errors['required']">Email
                                {{errorjson.required}}</span> -->
                            <span *ngIf="f['email'].errors['pattern']">{{errorjson.invalid}} Email</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                    <label class="fw-600 mb-2 ">Phone Number
                        <!-- <small class="text-danger">*</small> -->
                    </label>
                    <div class="d-flex align-items-center">
                        <mat-form-field appearance="outline" class="me-1">
                            <mat-select placeholder="Country code" formControlName="ccode">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                        noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let country of filteredBanks | async"
                                    [ngClass]="{'d-none': country.IAC === ''}"
                                    value={{country.IAC}}>{{country.IAC}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                                placeholder="Phone number" formControlName="phonenumber" />
                            <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phonenumber'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <!-- <span *ngIf="f['phonenumber'].errors['required']">Phone Number
                                    {{errorjson.required}}</span> -->
                                <span
                                    *ngIf="f['phonenumber'].errors['pattern'] || f['phonenumber'].errors['minlength'] || f['phonenumber'].errors['maxlength']">{{errorjson.invalid}}
                                    Phone Number</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                    <label class="fw-600 mb-2 ">Company logo</label>
                    <div class="" *ngIf="company?.id && companyForm.value.logo">
                        <img src="{{companyForm.value.logo}}" alt="" width="150px" class="mb-2"
                            (error)="onErrorLoadImage($event,presignedurl,companyForm.value.logo)">
                        <mat-icon class="visiblity-on-hover-delete text-danger cursor" (click)="onRemoveImage()">
                            delete
                        </mat-icon>
                    </div>
                    <input type="file" style="outline: none" placeholder="Company logo"
                        (change)="onCompanylogoUpload($event)" [accept]="'image/*'" />
                    <div class="error d-flex align-items-center" *ngIf="submitted && f['logo'].errors">
                        <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                        <span class="text-capitalize">Company logo </span>&nbsp;{{errorjson.required}}
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                    <label class="fw-600 mb-2 ">Owner
                        <!-- <small class="text-danger">*</small> -->
                    </label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="owner" placeholder="Owner">
                            <mat-option *ngFor="let member of staffMembers" (click)="onOwnerSelect(member)"
                                [value]="member.id">{{member.fname}}
                                {{member.lname}}</mat-option>
                        </mat-select>
                        <!-- <mat-error class="error text-danger d-flex" *ngIf="submitted && f['owner'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['owner'].errors['required']">Owner
                                {{errorjson.required}}</span>
                        </mat-error> -->
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2 ">Assignee</label>
                    <ng-select class="form-control" [items]="staffMembers" bindValue="id" bindLabel="fname"
                        [addTag]="false"
                        [ngClass]="{'disabled-field': company?.owner && (company.owner !== loggedInUser.data.id && loggedInUser.data.role !== 'ADMIN')}"
                        [multiple]="true" placeholder="Select staff" formControlName="assignee">
                    </ng-select>
                    <!-- <div class="error d-flex mt-2" *ngIf="submitted && f['assignee'].errors">
                        <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                        <span *ngIf="f['assignee'].errors['required']"> Staff
                            {{errorJson.required}}</span>
                    </div> -->
                </div>
                <!-- <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 ">Related Contact Name</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="Enter Name"
                            formControlName="related_contact" />
                    </mat-form-field>
                </div> -->

                <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 ">Address
                        <!-- <small class="text-danger">*</small> -->
                    </label>
                    <mat-form-field appearance="outline" class="w-100">
                        <textarea style="outline: none" matInput placeholder="Address" formControlName="address"
                            style="height:100px"></textarea>
                        <!-- <mat-error class="error text-danger d-flex" *ngIf="submitted && f['address'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['address'].errors['required']">Address
                                {{errorjson.required}}</span>
                        </mat-error> -->
                    </mat-form-field>
                </div>

                <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 ">City
                        <!-- <span class="text-danger">*</span> -->
                    </label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="City" formControlName="city" />
                        <!-- <mat-error class="error text-danger d-flex" *ngIf="submitted && f['city'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['city'].errors['required']">City
                                {{errorjson.required}}</span>
                        </mat-error> -->
                    </mat-form-field>
                </div>

                <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 ">Zip Code
                        <!-- <span class="text-danger">*</span> -->
                    </label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" (keydown)="keyDownEvent($event,9)" matInput placeholder="Zip Code"
                            formControlName="zipcode" type="number" (wheel)="$event.preventDefault()" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['zipcode'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <!-- <span *ngIf="f['zipcode'].errors['required']">Zip Code
                                {{errorjson.required}}</span> -->
                            <span *ngIf="f['zipcode'].errors['pattern']">Invalid zip code</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                    <label class="fw-600 font-size-14 mb-2">Country
                        <!-- <small class="text-danger">*</small> -->
                    </label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select placeholder='Select Country' formControlName="country">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredBanks | async"
                                value={{country.Entity}}>{{country.Entity}}</mat-option>
                        </mat-select>
                        <!-- <mat-error class="error d-flex" *ngIf="submitted && f['country'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['country'].errors['required']">Country
                                {{errorjson.required}}</span>
                        </mat-error> -->
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2 ">Channel</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="channel" placeholder="Select channel">
                            <mat-option *ngFor="let item of channels"
                                [value]="item.value">{{item.display_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                    <label class="fw-600 mb-2 ">Tag</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="tags" placeholder="Select Tag">
                            <mat-option [value]="item.id" *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-6 col-sm-12" *ngIf="!companyForm.value.id">
                    <label class="fw-600 mb-2 ">Contact Name
                        <!-- <span class="text-danger">*</span> -->
                    </label>

                    <ng-select class="form-control" [items]="contactListArray" bindLabel="first_name"
                        [addTag]="addNewName" [multiple]="false" (change)="onContactChange($event,'name')"
                        placeholder="Contact Name" formControlName="contact_name"
                        (search)="searchSubject.next({text: $event.term, type: 'name'})"
                        (clear)="clearContactFields($event)">
                    </ng-select>
                    <div class="error d-flex mt-2" *ngIf="submitted && f['contact_name'].errors">
                        <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                        <!-- <span *ngIf="f['contact_name'].errors['required']">Contact Name
                            {{errorjson.required}}</span> -->
                        <span *ngIf="f['contact_name'].errors['pattern']">Only alphanumeric are accepted.</span>
                    </div>
                    <!-- <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="contact_id" placeholder="Contact Name" class="pt-1"
                            (selectionChange)="onContactChange($event)">
                            <mat-option>
                                <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option [value]="item.id" *ngFor="let item of contactListArray">{{item.first_name
                                + ' ' +
                                item.last_name}}</mat-option>
                        </mat-select>
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['contact_id'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['contact_id'].errors['required']">Contact Name
                                {{errorjson.required}}</span>
                            <span *ngIf="f['contact_id'].errors['pattern']">Invalid Contact Name</span>
                        </mat-error>
                    </mat-form-field> -->
                </div>

                <div class="col-md-6 col-sm-12" *ngIf="!companyForm.value.id">
                    <label class="fw-600 mb-2 ">Contact Email
                        <!-- <span class="text-danger">*</span> -->
                    </label>
                    <ng-select class="form-control" [items]="contactListArray" bindLabel="email" [addTag]="addNewEmail"
                        [multiple]="false" (change)="onContactChange($event,'email')" placeholder="Contact Email"
                        formControlName="contact_email"
                        (search)="searchSubject.next({text: $event.term, type: 'email'})"
                        (clear)="clearContactFields($event)">
                    </ng-select>
                </div>

                <div class="col-md-6 col-sm-12" *ngIf="!companyForm.value.id">
                    <label class="fw-600 mb-2 ">Contact Phone Number
                        <!-- <span
                            class="text-danger">*</span> -->
                    </label>
                    <div class="d-flex">
                        <mat-form-field appearance="outline" class="me-1">
                            <mat-select placeholder="Country code" formControlName="contact_ccode">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                        noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let country of filteredBanks | async"
                                    [ngClass]="{'d-none': country.IAC === ''}"
                                    value={{country.IAC}}>{{country.IAC}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <ng-select class="form-control" [items]="contactListArray" style="height: fit-content;"
                            bindLabel="phone_number" [addTag]="addNewphonenumber" [multiple]="false"
                            (change)="onContactChange($event,'phone_number')" placeholder="Phone Number"
                            (search)="searchSubject.next({text: $event.term, type: 'phone_number'})"
                            formControlName="contact_number" (clear)="clearContactFields($event)">
                        </ng-select>
                        <div *ngIf="submitted && f['contact_number'].errors"
                            class="text-danger error font-size-12 d-flex align-items-center">
                            <mat-icon class="font-size-12 d-flex align-items-center w-3 me-1">info</mat-icon>
                            <span *ngIf="f['contact_number'].errors['required']">Phone Number
                                {{errorjson.required}}</span>
                            <span *ngIf="f['contact_number'].errors['pattern'] || f['contact_number'].errors['minlength'] || f['contact_number'].errors['maxlength']">Phone Number
                                {{errorjson.required}}</span>
                        </div>
                    </div>
                </div>
                <ng-container *ngFor="let field of system_company_fields.controls; let i=index">
                    <ng-container [ngTemplateOutlet]="fields"
                        [ngTemplateOutletContext]="{field:field, i:i, formArrayName: 'system_company_fields',fieldType:'sys'}">
                    </ng-container>
                </ng-container>
                <div class="col-md-12 mb-3 col-sm-12">
                    <label class="fw-600 mb-2 ">Description</label>
                    <!-- <mat-form-field appearance="outline" class="w-100"> -->
                    <ckeditor [editor]="Editor" placeholder="Description" formControlName="description"
                        [config]="ckconfigQue" class="ckeditor-height" #editorComponent
                        [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'ck-error':''"
                        (ready)="onReady($event,editorComponent)" (keyup)="editorkeyEvent(editorComponent)">
                    </ckeditor>
                    <small [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                        class="font-size-10">Maximum
                        1000 characters are accepted.</small><small
                        [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                        class="font-size-10 ps-1">(Character limit:
                        {{ckeditorLimit.actualLimit}}/{{ckeditorLimit.maxLimit}})</small>
                    <!-- <textarea style="outline: none" matInput placeholder="Description" formControlName="description" style="height:100px" maxlength="1000"></textarea> -->
                    <!-- </mat-form-field> -->
                    <!-- <mat-form-field appearance="outline" class="w-100">
                        <textarea style="outline: none" matInput placeholder="Description" formControlName="description" style="height:100px" maxlength="1000"></textarea>
                        </mat-form-field> -->
                </div>

                <hr class="mt-2">
                <h5>
                    Additional Fields
                </h5>
                <ng-container *ngFor="let field of custom_company_fields.controls; let i=index">
                    <ng-container [ngTemplateOutlet]="fields"
                        [ngTemplateOutletContext]="{field:field, i:i, formArrayName: 'custom_company_fields',fieldType:'custm'}">
                    </ng-container>
                </ng-container>

            </div>
        </form>
    </div>
</div>
<div class="d-flex align-items-center justify-content-between modal-footer mt-2">
    <div class="d-flex cursor" [routerLink]="['settings/custom-fields']" [queryParams]="{ activeLink: 'Company'}"
        (click)="ngbActiveModal.dismiss()">
        <span class="material-icons text-primary">settings_applications</span> {{companiesJson.modal_settings}}
    </div>
    <div class="d-flex align-items-center justify-content-end">
        <button class="btn btn-light me-2" type="button" (click)="ngbActiveModal.dismiss()">
            {{companiesJson.btn_cancel}}
        </button>
        <button class="btn btn-primary" [disabled]="loading || (ckeditorLimit.actualLimit > ckeditorLimit.maxLimit)"
            (click)="ckeditorLimit.actualLimit <= ckeditorLimit.maxLimit && onSubmit()"
            type="submit">{{companiesJson.btn_save}}</button>
    </div>
</div>

<ng-template #fields let-field='field' let-i="i" let-formArrayName="formArrayName" let-fieldType="fieldType">
    <form [formGroup]="companyForm" class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
        <div [formArrayName]="formArrayName">
            <div *ngIf="field.value.on_form">
                <div class="row" [formGroupName]="i">
                    <div>
                        <label class="fw-600 mb-2 ">{{field.value.label.replace("_", " ") |
                            titlecase}}</label>&nbsp;
                        <small class="text-danger" *ngIf="field.value.is_mandatory">*</small>

                        <ng-container *ngIf="field.value.data_type === 'TEXT'  ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorjson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'TEXT_AREA'">
                            <ckeditor [editor]="Editor" placeholder="write a message" formControlName="data"
                                [config]="ckconfigQue" class="ckeditor-height" #customEditorComponents
                                (ready)="onCustReady($event,fieldType+i)"
                                [ngClass]="ckeditorLimitLists[fieldType+i]?.actualLimit > ckeditorLimitLists[fieldType+i]?.maxLimit ? 'ck-error':''"
                                (keyup)="custEditorkeyEvent($event,customEditorComponents,fieldType+i)">
                            </ckeditor>
                            <small
                                [ngClass]="ckeditorLimitLists[fieldType+i]?.actualLimit > ckeditorLimitLists[fieldType+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10">Maximum
                                1000 characters are accepted.</small><small
                                [ngClass]="ckeditorLimitLists[fieldType+i]?.actualLimit > ckeditorLimitLists[fieldType+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10 ps-1">(Character
                                limit:
                                {{ckeditorLimitLists[fieldType+i]?.actualLimit}}/{{ckeditorLimitLists[fieldType+i]?.maxLimit}})</small>
                            <!-- <textarea class="form-control background-color" rows="2" cols="30" formControlName="data"
                                placeholder="write a message"></textarea> -->
                            <div class="error d-flex align-items-center"
                                *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", "
                                    ")}}</span>&nbsp;{{errorjson.required}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'CHECK_BOX'">
                            <div *ngFor="let options of field.value.options">
                                <mat-checkbox [value]="options" (change)="toggleCheckbox(options, i)">
                                    {{options}}
                                </mat-checkbox>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'SELECT_DROPDOWN' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select formControlName="data">
                                    <mat-option [value]="null">
                                      None
                                    </mat-option>
                                    <mat-option *ngFor="let options of field.value.options" [value]="options">
                                        {{options}}
                                    </mat-option>
                                </mat-select>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorjson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'MULTI_SELECT_DROPDOWN'">
                            <div *ngIf="field.value.options.length > 0">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-select formControlName="data" multiple>
                                        <mat-option *ngFor="let options of field.value.options" [value]="options">
                                            {{options}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error class="error text-danger d-flex"
                                        *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", "
                                            ")}}</span>&nbsp;{{errorjson.required}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'RADIO_BUTTON'">
                            <div>
                                <ng-container *ngIf="field.value.options.length > 0">
                                    <mat-radio-group formControlName="data">
                                        <mat-radio-button value="">None</mat-radio-button>
                                        <mat-radio-button *ngFor="let options of field.value.options" [value]="options">
                                            {{options}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <div class="error d-flex align-items-center"
                                        *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                        <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", "
                                            ")}}</span>&nbsp;{{errorjson.required}}
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="field.value.options.length === 0">
                                    <mat-radio-group formControlName="data">
                                        <mat-radio-button [value]="true">
                                            Required
                                        </mat-radio-button>
                                        <mat-radio-button [value]="false">
                                            Not Required
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <div class="error d-flex align-items-center"
                                        *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                        <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", "
                                            ")}}</span>&nbsp;{{errorjson.required}}
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'TOGGLE_BUTTON' ">
                            <label class="switch d-none">
                                <input type="checkbox" formControlName="data">
                                <span class="slider round"></span>
                            </label>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'NUMBER' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" type="number"
                                    (keydown)="keyDownCustomField($event,0,'custom_field')"
                                    (wheel)="$event.preventDefault()" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorjson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'WEBSITE'">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" type="url" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorjson.required}} or Invalid
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="text" matInput [matDatepicker]="picker" formControlName="data"
                                    placeholder="{{field.value.label}}">
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorjson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE_TIME' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput [ngxMatDatetimePicker]="dateTimePicker" placeholder="Choose a date"
                                    formControlName="data">
                                <mat-datepicker-toggle matSuffix [for]="$any(dateTimePicker)"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #dateTimePicker>
                                </ngx-mat-datetime-picker>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorjson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE_RANGE' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-date-range-input [rangePicker]="dateRangepicker">
                                    <input matStartDate formControlName="start" placeholder="Start date">
                                    <input matEndDate formControlName="end" placeholder="End date">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matIconSuffix [for]="dateRangepicker"></mat-datepicker-toggle>
                                <mat-date-range-picker #dateRangepicker></mat-date-range-picker>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorjson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'FILE'">
                            <div class="" *ngIf="company?.id">
                                <img src="{{field.value?.data?.path}}" alt="" width="150px" class="mb-2"
                                    (error)="onErrorLoadImage($event,presignedurl,field.value?.data?.file_name)">
                                <a href="{{field.value?.data?.path}}" target="_blank"
                                    class="ms-2 text-dark fw-medium mt-3" download>{{field.value?.data?.file_name}}</a>
                            </div>
                            <input type="file" style="outline: none" placeholder="{{field.value.label}}"
                                (change)="onFileUpload($event,i)" />
                            <div class="error d-flex align-items-center"
                                *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", "
                                    ")}}</span>&nbsp;{{errorjson.required}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'PHONE_NUMBER'">
                            <div class="d-flex">
                                <mat-form-field appearance="outline" class="me-1">
                                    <mat-select placeholder="Country code" formControlName="start">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let country of filteredBanks | async"
                                            [ngClass]="{'d-none': country.IAC === ''}"
                                            value={{country.IAC}}>{{country.IAC}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                                        placeholder="{{field.value.label}}" formControlName="end" />
                                    <mat-error class="error text-danger d-flex"
                                        *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", " ") }}
                                            {{errorjson.required}}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'EMAIL'">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorjson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
<div class="container-fluid">
    <div class="row mb-2 mt-3">
        <div class="col-sm-6">
            <div class="heading font-size-18 fw-700 d-flex" style="width: fit-content;">
                <mat-icon class="text-primary cursor" (click)="onBack()">{{COMPONENT_LABELS.back_icon}}
                </mat-icon>
                <p>
                    {{COMPONENT_LABELS.submissions_data}}
                </p>
            </div>
        </div>
    </div>
    <div class="d-flex">
        <div class="table-responsive me-2 w-100">
            <table class="table align-middle table-nowrap table mb-0  table-bordered">
                <thead>
                    <tr>
                        <th>{{COMPONENT_LABELS.col_tab_1}}</th>
                        <th *ngFor="let item of headers;">{{item?.name}}</th>
                        <th>Visited from</th>
                        <th>Submitted At</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let form of submissions; let i=index;" class="tr cursor"
                        (click)="onOpenFormView(form_view_submission, form)">
                        <td>
                            {{ i+ 1 }}
                        </td>
                        <ng-container *ngFor="let ele of headers;">
                            <ng-container class="" *ngIf="!isObject(form.data[ele.id]?.value)">
                                <ng-container><!--*ngIf="!form.data[ele.id]?.value?.includes('s3.amazonaws')"-->
                                    <td *ngIf="form.data[ele.id]?.key !== 'MULTI_SELECT_DROPDOWN'">
                                        {{form.data[ele.id]?.value ? form.data[ele.id].value : ""}}
                                    </td>
                                    <td *ngIf="form.data[ele.id]?.key === 'MULTI_SELECT_DROPDOWN'">
                                        <label *ngFor="let item of form.data[ele.id]?.value">{{item.name}}</label>
                                    </td>
                                </ng-container>
                                <!-- <ng-container *ngIf="form.data[ele.id]?.value?.includes('s3.amazonaws')">
                                    <td>
                                        <a href="{{form.data[ele.id].value}}" target="_blank" rel="noopener noreferrer">
                                            View file
                                        </a>
                                    </td>
                                </ng-container> -->
                            </ng-container>
                            <ng-container *ngIf="isObject(form.data[ele.id]?.value)">
                                <td *ngIf="!form.data[ele.id]?.value?.hasOwnProperty('path')">{{form.data[ele.id]?.value ? form.data[ele.id].value.name : ""}}</td>
                                <td *ngIf="form.data[ele.id]?.value?.hasOwnProperty('path')">
                                    <a href="{{form.data[ele.id].value?.path}}" target="_blank" rel="noopener noreferrer">
                                        {{form.data[ele.id].value.file_name}}
                                    </a>
                                </td>
                            </ng-container>

                        </ng-container>
                        <ng-container>
                            <td>{{ form?.source_url ? form.source_url : ''}}</td>
                        </ng-container>
                        <ng-container>
                            <td>{{ form?.created_at | dateConversion}} {{form?.created_at | date: 'h:mm a'}}</td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <div style="width: 8%;">
            <table class="table align-middle table-nowrap table mb-0 table-bordered">
                <thead>
                    <tr >
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let form of submissions; let i=index;" class="trv">
                        <td >
                            <div class="d-flex align-items-center justify-content-center">
                            <p class="cursor badge bg-primary text-wrap fs-6 m-0" (click)="onOpenFormView(form_view_submission,form)">
                                {{'View'}}</p>
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div> -->
    </div>
    <ng-template #form_view_submission let-modal>
        <div class="form-view-submission ">
            <div class="mb-4 col-11 m-auto">
                <div class="title position-sticky pt-4 bg-white">
                    <div class="text-center text-white py-2">
                        <h6 class="m-0 py-2">{{submittedFormView?.form?.name}}</h6>
                    </div>
                </div>

                <div class='form-view-body mt-4'>
                    <div class="" *ngIf="submittedFormView?.formFields">
                        <div *ngFor="let data of submittedFormView.formFields"
                            class="card-body d-flex flex-column p-3 my-3 border-radius-10">
                            <label class="mb-2 fw-500 text-primary text-capitalize">{{data.name}} </label>
                            <div>
                                <input type="text" *ngIf="data.key === 'DATE_TIME'" class="input-box w-100" readonly
                                    disabled [value]="data.value | dateConversion">
                                <textarea *ngIf="data.key === 'TEXT_AREA'" name="" id=""
                                    class="input-box border-bottom w-100" cols="30" rows="6" readonly disabled
                                    [value]="data.value"></textarea>
                                <ng-container *ngIf="data.key !== 'DATE_TIME' && data.key !== 'TEXT_AREA'">
                                    <div class="input-box border-bottom">
                                        <div [ngSwitch]="getType(data.value)">
                                            <ng-container *ngSwitchCase="'array'">
                                                <p class="mb-1 d-inline" *ngFor="let value of data.value;let i = index">
                                                    {{value?.name}}<span>{{getLength(data.value,i) ? ',' :
                                                        ''}}</span>
                                                </p>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'object'">
                                                <div *ngFor="let val of [data.value! | keyvalue]">
                                                    <p class="mb-1">{{val[1].value}}</p>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'string'">
                                                <input type="text" class="input-box w-100" readonly disabled
                                                    [value]="data.value">
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                <input type="input-box text" class="w-100" readonly disabled
                                                    [value]="data.value">
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
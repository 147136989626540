import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { onOnlyNumbers, onlyNumberDecimal } from 'src/app/core/common/common-functions';
import { INVOICE } from 'src/app/core/data/finance_module_json';
import { AuthService } from 'src/app/core/service/auth.service';
import { InvoiceService } from 'src/app/core/service/invoice.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  public selection = new SelectionModel<any>(true, []);
  table_cols = [
    'Invoice Id',
    // 'Created At',
    'Name',
    'Email',
    'Phone No',
    'Invoice Type',
    'Related To',
    // 'Payment Status',
    'Total Amount',
    'Due Amount',
    'Created On',
    'Invoice Status',
    'Actions'
  ];
  invoiceJson = INVOICE[0];
  total_count!: number;
  invoiceArray: any = [];
  currentPage = 0;
  pageEvent!: PageEvent;
  display_no_data = false;
  search_global: any;
  create_quotation = false;
  type_quotation = 'INVOICE';
  pageIndex = 0;
  searchSubject: Subject<string> = new Subject<string>();
  tableSkeletonLoader = true;
  featuresData: any;
  selectedInvoiceId: any;
  partial_amount!: number;
  decimal_value = 0;
  invoice_due_amount: any;
  price_symbol_postion: any;

  constructor(private service: InvoiceService, private authService: AuthService, private modalService: NgbModal,private toaster: ToastrService) {
    this.searchSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      if (searchTerm.length >= 3) {
        this.getInvoiceListBySearch(searchTerm);
      } else if (searchTerm.length < 1) {
        this.getInvoices(1);
      }
    });
  }

  getInvoiceListBySearch(searchTerm: string) {
    this.tableSkeletonLoader = true;
    this.service.getInvoiceListBySearch(searchTerm, this.currentPage).subscribe({
      next: (resp: any) => {
        this.tableSkeletonLoader = false;
        this.total_count = resp.count;
        this.invoiceArray = resp.data;
        if (this.invoiceArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      }
    });
  }


  ngOnInit(): void {
    this.getFeatues();
    this.getInvoices(1);
    this.decimal_value = this.authService.getPriceData()?.no_of_decimals;
    this.price_symbol_postion = this.authService.getPriceData()?.price_symbol_position;
  }

  getInvoices(page: number) {
    if (page === 1) {
      this.currentPage = 1;
      this.pageIndex = 0;
    }
    this.tableSkeletonLoader = true;
    this.service.getInvoiceList(page).subscribe({
      next: (resp: any) => {
        // console.log(resp);
        this.tableSkeletonLoader = false;
        this.total_count = resp.count;
        this.invoiceArray = resp.data;
        if (this.invoiceArray.length === 0) this.display_no_data = true;
        else this.display_no_data = false;
      }
    });
  }

  retryInvoice(invoice_id: string) {
    this.service.retryForInvoice(invoice_id).subscribe(() => {
      this.getInvoices(1);
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.invoiceArray.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.invoiceArray.forEach((row: any) => {
      this.selection.select(row);
    });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
      this.pageIndex = e.pageIndex++;
    } else if (this.pageEvent.pageIndex === 0) {
      this.currentPage = 1;
      this.pageIndex = 0;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
      this.pageIndex = e.pageIndex--;
    }
    if (this.search_global?.length >= 3) {
      this.getInvoiceListBySearch(this.search_global);
    } else {
      this.getInvoices(this.currentPage);
    }
  }

  // search(name: any) {
  //   if (name.length >= 3) {
  //     this.service.getInvoiceListBySearch(name).subscribe({
  //       next: (resp: any) => {
  //         this.total_count = resp.count;
  //         this.invoiceArray = resp.data;
  //         if (this.invoiceArray.length === 0) {
  //           this.display_no_data = true;
  //         } else {
  //           this.display_no_data = false;
  //         }
  //       }
  //     });
  //   } else if (name.length < 1) {
  //     this.getInvoices(1);
  //   }
  // }

  rolebackFunction() {
    this.create_quotation = false;
    this.selectedInvoiceId = null;
    this.getInvoices(1);
  }

  editInvoice(item: any) {
    this.selectedInvoiceId = item.id;
    this.type_quotation = item.invoice_type;
    this.create_quotation = true;
  }
  openCreateInvoice(type: string) {
    this.selectedInvoiceId ='';
    this.type_quotation = type;
    this.create_quotation = true;
  }

  invoiceConvertion(id: string) {
    this.service.convertProformaToInvoice(id).subscribe(() => {
      this.getInvoices(1);
    });
  }
  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }

  partialInvoice(modal: any, item: any) {
    this.invoice_due_amount = item.currency_symbol + item.due_amount;
    this.selectedInvoiceId = item.id;
    this.modalService.open(modal);
  }

  partialInvoiceCreation() {
    if (this.partial_amount) {
      const body = {
        id: this.selectedInvoiceId,
        offline_amount: this.partial_amount
      };
      this.service.partialInvoice(body).subscribe(() => {
        this.modalService.dismissAll();
      });
    } else{
      this.toaster.error("Please enter the amount");
    }
  }
  keyDownEvent(event: any) {
    if (event.target.value.length === 0) {
      return onOnlyNumbers(event);
    } else {
      return onlyNumberDecimal(event, this.decimal_value - 1, 'any');
    }
  }

  getRolePermissions(item: any,permission:any) {
    return this.authService.rolefeatureShow(item,permission);
  }
}

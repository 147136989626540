<div class="container-fluid">
  <div class="row px-2">
    <div class="col-md-12">
      <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0"
        (navChange)="tabChange($event)">
        <li [ngbNavItem]="1" class="me-2">
          <a ngbNavLink class="d-flex">
            <span class="d-sm-block font-size-12">{{teamjson.heading}}</span>
          </a>
          <ng-template ngbNavContent *ngIf="templateTeamsList">
            <div class="mt-3">
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-9 d-flex justify-content-end align-items-center">
                  <button *ngIf="loggedInUser.data.role === 'ADMIN'"
                    class="btn btn-primary d-flex align-items-center me-3" (click)="addTeam(false)">
                    <mat-icon>add</mat-icon>
                    {{teamjson.add_btn}}
                  </button>
                </div>

              </div>
              <div class="table-responsive table-height mt-4 roles-table user-control-div-height">
                <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="table_teams_cols.length"
                  [fromLocation]="'USERS'"></app-table-skeleton>
                <table *ngIf="!tableSkeletonLoader"
                  class="table align-middle table-nowrap table-hover mb-0 users-table w-100">
                  <thead>
                    <tr>
                      <ng-container *ngFor="let col of table_teams_cols">
                        <th scope="col" *ngIf="col.is_visible" class="text-primary pb-3">
                          {{col.name}}</th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of teamsList; let i = index">
                      <td class="text-capitalize">{{ item.name }}</td>
                      <td>{{ item.users_count }}</td>
                      <td *ngIf="table_teams_cols[2].is_visible">
                        <div class="dropdown" ngbDropdown>
                          <mat-icon [matMenuTriggerFor]="menuadd" class="cursor">more_vert</mat-icon>
                          <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                            <button mat-menu-item (click)="addTeam(false,item)" class="py-2">
                              {{teamjson.edit_btn}}
                            </button>
                            <button mat-menu-item (click)="confirmTeams(item.id)" class="py-2">
                              {{teamjson.delete_btn}}
                            </button>
                          </mat-menu>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex align-items-center justify-content-center no-data-found mt-2" *ngIf="noDeptFound">
                  <div class="text-center">
                    <img class="border-radius-8" src="../../../../assets/images/nodata_found.svg" height="160px"
                      width="160px">
                    <h4 class="mt-4">
                      No Data Available
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbNavContent *ngIf="!templateTeamsList">
            <div class="my-3 w-50">
              <div class="d-flex text-light">
                <label class="cursor" (click)="addTeam(true)">{{teamjson.heading}}</label>&nbsp;>&nbsp;<label><span
                    *ngIf="!addTeamForm.value.id">{{teamjson.add_btn}}</span>
                  <span *ngIf="addTeamForm.value.id">{{teamjson.update_btn}}</span>
                  {{teamjson.department}}</label>
              </div>
              <form [formGroup]="addTeamForm" (ngSubmit)="addnewTeamSubmit()" class="mt-3">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">{{teamjson.field_name}} <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput
                        placeholder='{{staffjson.enter_placeholder}} {{teamjson.field_name}}' formControlName="name"
                        #myFirstInput />
                      <mat-error class="error text-danger d-flex" *ngIf="isteamSubmitted && teamForm['name'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="teamForm['name'].errors['required']">{{teamjson.field_name}}
                          {{errorjson.required}}</span>
                        <span *ngIf="teamForm['name'].errors['pattern']">{{errorjson.invalid}}
                          {{teamjson.field_name}}
                        </span>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-12 col-md-12 d-none">
                    <label class="fw-600 mb-2">{{teamjson.field_people}} <small class="text-danger">*</small></label>
                    <div class="d-flex align-items-center">
                      <div class="avatar-group d-flex align-items-center">
                        <div
                          class="avatar-xs avatar-group-item bg-gray-dark fw-600 border-radius-50 d-flex align-items-center justify-content-center text-capitalize"
                          *ngFor="let item of teamsSelectedStaff">
                          {{item.fname[0]}}</div>
                      </div>
                      <mat-icon (click)="openStaffModel(selectStaffModal)">add</mat-icon>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-light me-3" type="button"
                    (click)="addTeam(true)">{{teamjson.close_btn}}</button>
                  <button type="submit" class="btn btn-primary">{{teamjson.save_btn}}</button>
                </div>
              </form>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" class="me-2">
          <a ngbNavLink class="d-flex">
            <span class="d-sm-block font-size-12">{{staffjson.heading}}</span>
          </a>
          <ng-template ngbNavContent *ngIf="templateStaffListType">
            <div class="mt-3">
              <div class="row search-customised-sec">
                <div class="col-md-3">
                  <!-- <mat-form-field appearance="outline" class="mat-hint-none">
                    <mat-select placeholder="Department" (selectionChange)="onFilterChange($event)" class="pt-1">
                      <mat-option class="font-size-12" [value]="opt.id"
                        *ngFor="let opt of teamsList">{{opt.name}}</mat-option>
                    </mat-select>
                  </mat-form-field> -->
                  <mat-form-field appearance="outline" class="me-3 mat-hint-none">
                    <input style="outline: none" matInput placeholder="Search" [(ngModel)]="name_search"
                      (ngModelChange)="searchName($event)" />
                    <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
                        alt=""></mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-9 d-flex justify-content-end align-items-center">
                  <button class="btn btn-primary d-flex align-items-center me-3"
                    *ngIf="loggedInUser.data.role === 'ADMIN' || loggedInUser.data.role === 'MANAGER'"
                    (click)="addStaff(false)">
                    <mat-icon>add</mat-icon>
                    {{staffjson.add_btn}}
                  </button>
                </div>

              </div>
              <div>
                <div class="table-responsive table-height mt-4 user-control-div-height-user">
                  <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="table_cols.length"
                    [fromLocation]="'USERS'"></app-table-skeleton>
                  <table *ngIf="!tableSkeletonLoader"
                    class="table align-middle table-nowrap table-hover mb-0 users-table w-100">
                    <thead>
                      <tr>
                        <ng-container *ngFor="let col of table_cols">
                          <th scope="col" *ngIf="col.is_visible" class="text-primary pb-3">
                            {{col.name}}</th>
                        </ng-container>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of staffListArray; let i = index">
                        <td>{{ item?.display_id }}</td>
                        <td>
                          <div class="d-flex align-items-center">
                            <div class="avatar-xs d-flex justify-content-between me-2 d-none">
                              <img class="rounded-circle avatar-xs" src="../../../../assets/images/gigi-hadid-4.jpg"
                                alt="">
                            </div>
                            {{ item?.fname + ' ' + item?.lname }}
                          </div>
                        </td>
                        <td>{{ item?.email }}</td>
                        <td>{{ item?.department_info ? item.department_info?.name : '-' }}</td>
                        <td>{{ item?.location_info ? item.location_info[0]?.name : '-' }}</td>
                        <td>{{ item?.role_info?.name }}</td>
                        <!-- <td>{{ item?.last_login }}</td> -->
                        <td *ngIf="table_cols[5].is_visible">
                          <div class="d-flex">
                            <label class="switch me-3 cursor"
                              *ngIf="(loggedInUser.data.role === 'ADMIN' && item?.utype !=='ADMIN') || (loggedInUser.data.role === 'MANAGER' && item?.role_info?.name !== 'MANAGER')">
                              <input type="checkbox" [(ngModel)]="item.status" (change)="onStaffStatusChange(item)">
                              <span class="slider round"></span>
                            </label>
                            <button mat-raised-button matTooltip="Edit"
                              aria-label="Button that displays a tooltip when focused or hovered over"
                              *ngIf="(loggedInUser.data.role === 'ADMIN' && item?.utype !=='ADMIN') || (loggedInUser.data.role === 'MANAGER' && item?.role_info?.name !== 'MANAGER')"
                              class="btn header-item border-0 p-0 cursor" mat-button><mat-icon
                                class="ms-2  font-size-16" (click)="addStaff(false,item)">edit</mat-icon></button>
                            <button mat-raised-button matTooltip="services" *ngIf="setupData[0].status && (loggedInUser.data.role === 'ADMIN' && item?.utype !=='ADMIN') || (loggedInUser.data.role === 'MANAGER' && item?.role_info?.name !== 'MANAGER')"
                              aria-label="Button that displays a tooltip when focused or hovered over"
                              class="btn header-item border-0 p-0 cursor" mat-button><mat-icon class="font-size-20 me-1"
                                (click)="openAptSerivce(staffAssignedSerices,item)">card_travel</mat-icon></button>
                            <button mat-raised-button matTooltip="Day offs" *ngIf="(loggedInUser.data.role === 'ADMIN' && item?.utype !=='ADMIN') || (loggedInUser.data.role === 'MANAGER' && item?.role_info?.name !== 'MANAGER')"
                              aria-label="Button that displays a tooltip when focused or hovered over"
                              class="btn header-item border-0 p-0 cursor" mat-button><mat-icon class="font-size-20 me-1"
                                (click)="openDayOffModel(item.id)">access_time</mat-icon></button>
                            <button mat-raised-button matTooltip="Working hours" *ngIf="setupData[0].status && (loggedInUser.data.role === 'ADMIN' && item?.utype !=='ADMIN') || (loggedInUser.data.role === 'MANAGER' && item?.role_info?.name !== 'MANAGER')"
                              aria-label="Button that displays a tooltip when focused or hovered over"
                              class="btn header-item border-0 p-0 cursor" mat-button><mat-icon
                                (click)="openWorkingHoursModel(item.id)"
                                class="font-size-20">calendar_today</mat-icon></button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex align-items-center justify-content-center no-data-found mt-2"
                    *ngIf="display_no_data">
                    <div class="text-center">
                      <img class="border-radius-8" src="../../../../assets/images/nodata_found.svg" height="160px"
                        width="160px">
                      <h4 class="mt-4">
                        No Data Available
                      </h4>
                    </div>
                  </div>
                </div>
                <div>
                  <mat-paginator [length]="totalStaff" [pageSize]="20" (page)="page($event)" aria-label="Select page"
                    showFirstLastButtons hidePageSize="true">
                  </mat-paginator>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbNavContent *ngIf="!templateStaffListType">
            <div class="my-3 w-50">
              <div class="d-flex text-light">
                <label class="cursor" (click)="addStaff(true)">Users</label>&nbsp;>&nbsp;<label><span
                    *ngIf="!addStaffForm.value.id">{{staffjson.new}}</span> <span
                    *ngIf="addStaffForm.value.id">{{staffjson.update}}</span>
                  {{staffjson.add_btn}}</label>
              </div>
              <div class="no-department text-danger font-size-16 fw-700 p-2" *ngIf="teamsList.length === 0">
                <label>Note: Please add atleast one department to create staff</label>
              </div>
              <form [formGroup]="addStaffForm" (ngSubmit)="addnewStaffSubmit()" class="mt-3">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_name}} <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput
                        placeholder='{{staffjson.enter_placeholder}} {{staffjson.field_name}}' formControlName="fname"
                        #myFirstStaffInput />
                      <mat-error class="error text-danger d-flex" *ngIf="submited && f['fname'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['fname'].errors['required']">{{staffjson.field_name}}
                          {{errorjson.required}}</span>
                        <span *ngIf="f['fname'].errors['pattern']">{{errorjson.invalid}}
                          {{staffjson.field_name}}</span>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_lastname}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_lastname}}"
                        formControlName="lname" />
                      <mat-error class="error text-danger d-flex" *ngIf="submited && f['lname'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['lname'].errors['pattern']">{{errorjson.invalid}}
                          {{staffjson.field_lastname}}</span>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">{{staffjson.field_email}} <small class="text-danger">*</small></label>

                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_email}}" formControlName="email"
                        type="email" />
                      <mat-error class="error text-danger d-flex" *ngIf="submited && f['email'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['email'].errors['required']">{{staffjson.field_email}}
                          {{errorjson.required}}</span>
                        <span *ngIf="f['email'].errors['pattern']">{{errorjson.invalid}}
                          {{staffjson.field_email}}</span>
                      </mat-error>
                    </mat-form-field>
                    <p class="text-light font-size-13" *ngIf="!addStaffForm.value.id">This email is used for user’s
                      account
                      settings and changing it will affect the user’s log in.</p>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">{{staffjson.field_number}} <small class="text-danger">*</small></label>
                    <div class="d-flex">
                      <mat-form-field appearance="outline" class="me-2">
                        <mat-select formControlName="ccode" placeholder="{{staffjson.enter_placeholder}} Country Code">
                          <mat-option>
                            <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                              noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let country of filteredBanks | async"
                            value={{country.country_code}}>{{country.country_code}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                          placeholder="{{staffjson.field_number}}" formControlName="phone_number" type="text" />
                        <mat-error class="error text-danger d-flex" *ngIf="submited && f['phone_number'].errors">
                          <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                          <span *ngIf="f['phone_number'].errors['required']">{{staffjson.field_number}}
                            {{errorjson.required}}</span>
                          <span
                            *ngIf="f['phone_number'].errors['pattern'] || f['phone_number'].errors['minlength'] || f['phone_number'].errors['maxlength']">{{errorjson.invalid}}
                            {{staffjson.field_number}}</span>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>


                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="d-flex align-items-center justify-content-between">
                      <label class="fw-600 mb-2">{{staffjson.field_badge}} </label>
                      <label class="cursor mb-2" style="color: grey;" (click)="manageBadges()">
                        <mat-icon class="d-inline-flex align-items-center justify-content-end"
                          style="font-size: 14px;">settings</mat-icon>
                        <span style="position: relative; bottom: 1px;"
                          class="d-inline-flex align-items-center font-size-14">Manage
                          Badges</span>
                      </label>
                    </div>
                    <ng-select class="form-control" [items]="badgesList" bindLabel="name" bindValue="id"
                      [multiple]="true" placeholder='Select {{staffjson.field_badge}}' formControlName="badges"
                      [clearable]="true">
                    </ng-select>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-checkbox [checked]="addStaffForm.value.enable_for_booking"
                      formControlName="enable_for_booking">{{staffjson.field_booking}}</mat-checkbox>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">{{staffjson.field_role}} <small class="text-danger">*</small></label>

                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select formControlName="role_id" placeholder="Select {{staffjson.field_role}}">
                        <mat-option *ngFor="let role of rolesList" [disabled]="role.name === 'ADMIN' ? true : false"
                          value={{role.id}}>{{role.name}}</mat-option>
                      </mat-select>
                      <mat-error class="error text-danger d-flex" *ngIf="submited && f['role_id'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['role_id'].errors['required']">{{staffjson.field_role}}
                          {{errorjson.required}}</span>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">{{staffjson.field_team}} <small class="text-danger">*</small></label>
                    <ng-select class="form-control" bindLabel="group_name" [multiple]="false" placeholder='Select Team'
                      formControlName="department" [clearable]="false">
                      <ng-option *ngFor="let team of teamsList" value="{{team.id}}">
                        {{team.name}}
                      </ng-option>
                    </ng-select>
                    <div style="height: 22px;">
                      <mat-error class="error d-flex mt-2" *ngIf="submited && f['department'].errors">
                        <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                        <span *ngIf="f['department'].errors['required']">
                          {{staffjson.field_team}}
                          {{errorjson.required}}</span>
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">{{staffjson.field_location}} <small class="text-danger">*</small></label>
                    <ng-select class="form-control" bindLabel="group_name" [multiple]="false"
                      placeholder='Select Location' formControlName="locations" [clearable]="true">
                      <ng-option *ngFor="let item of branches" value="{{item.id}}">
                        {{item.name}}
                      </ng-option>
                    </ng-select>
                    <mat-error class="error d-flex mt-2" *ngIf="submited && f['locations'].errors">
                      <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                      <span *ngIf="f['locations'].errors['required']">
                        {{staffjson.field_location}}
                        {{errorjson.required}}</span>
                    </mat-error>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                    <label class="fw-600 mb-2">{{staffjson.field_timeZone}} <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select placeholder='Select {{staffjson.field_timeZone}}' formControlName="time_zone">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="bankFilterTimezoneCtrl" placeholderLabel="Search"
                            noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-optgroup *ngFor="let time_zone of filteredTimezoneBanks | async" [label]="time_zone.name">
                          <mat-option class="mb-2 mat-hint-none" *ngFor="let countries of time_zone.countries"
                            value="{{countries.timezone}}">{{countries.name}}</mat-option>
                        </mat-optgroup>
                      </mat-select>
                      <mat-error class="error text-danger d-flex" *ngIf="submited && f['time_zone'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['time_zone'].errors['required']">{{staffjson.field_timeZone}}
                          {{errorjson.required}}</span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="call_numbers?.length !== 0">
                    <label class="fw-600 mb-2">{{staffjson.field_call_number}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select placeholder='Select {{staffjson.field_call_number}}' formControlName="number">
                        <mat-option class="mb-2 mat-hint-none" *ngFor="let number of call_numbers"
                          value="{{number}}">{{number}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 password-field mt-3"
                    *ngIf="!addStaffForm.value.id">
                    <label class="fw-600 mb-2">{{staffjson.field_password}} <small
                        class="text-danger">*</small></label><br>
                    <!-- <mat-radio-group aria-label="Select an option" class="d-flex flex-column mb-2"
                                            formControlName="password_type" (change)="isPassword()">
                                            <mat-radio-button value="auto">Auto-generate password</mat-radio-button>
                                            <mat-radio-button value="manual">Create password manually</mat-radio-button>
                                        </mat-radio-group> -->
                    <mat-form-field appearance="outline" class="w-100"><!-- *ngIf="passwordBox"-->
                      <input style="outline: none" matInput
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_password}}"
                        formControlName="password" [type]="hide ? 'password' : 'text'" />
                      <mat-icon (click)="hide = !hide" class="cursor m-auto">{{
                        hide ? "visibility_off" : "visibility"
                        }}</mat-icon>
                      <mat-error class="error text-danger d-flex" *ngIf="submited && f['password'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['password'].errors['required']">{{staffjson.field_password}}
                          {{errorjson.required}}</span>
                        <span *ngIf="f['password'].errors['pattern']">{{errorjson.invalid}}
                          {{staffjson.field_password}}</span>
                      </mat-error>
                    </mat-form-field>
                    <label class="ms-1 mb- text-secondary mb-2 font-size-12">{{staffjson.text}}</label>
                  </div>
                </div>
                <div class="row" *ngIf="addStaffForm.value.id">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">{{staffjson.field_designation}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_designation}}"
                        formControlName="designation" />
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">{{staffjson.field_specialisation}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_specialisation}}"
                        formControlName="specialisation" />
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">{{staffjson.field_qualification}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_qualification}}"
                        formControlName="qualification" />
                      <mat-error class="error text-danger d-flex" *ngIf="submited && f['qualification'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['qualification'].errors['pattern']">{{errorjson.invalid}}
                          {{staffjson.field_qualification}}</span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">{{staffjson.field_experience}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput type="text" (wheel)="$event.preventDefault()"
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_experience}}"
                        (keypress)="validateDecimalPoints($event)" formControlName="experience" />
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">{{staffjson.field_gender}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select formControlName="gender" placeholder="Select">
                        <mat-option value="Female">Female</mat-option>
                        <mat-option value="Male">Male</mat-option>
                        <mat-option value="Other">Other</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label class="fw-600 mb-2">{{staffjson.field_age}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput (keydown)="keyDownEvent($event,2)"
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_age}}" formControlName="age" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-light me-3" type="button"
                    (click)="addStaff(true)">{{staffjson.close_btn}}</button>
                  <button type="submit" class="btn btn-primary">
                    {{staffjson.save_btn}}
                  </button>
                </div>
              </form>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="3" class="me-2"
          *ngIf="loggedInUser.data.role === 'ADMIN' || loggedInUser.data.role === 'MANAGER'">
          <a ngbNavLink class="d-flex">
            <span class="d-sm-block font-size-12">Roles</span>
          </a>
          <ng-template ngbNavContent>
            <app-roles-permissions></app-roles-permissions>
          </ng-template>
        </li>
        <li [ngbNavItem]="4" class="me-2"
          *ngIf="loggedInUser.data.role === 'ADMIN' || loggedInUser.data.role === 'MANAGER'">
          <a ngbNavLink class="d-flex">
            <span class="d-sm-block font-size-12">Day Off</span>
          </a>
          <ng-template ngbNavContent>
            <div class="mt-3">
              <div class="row customised-sec" [ngClass]="{'justify-content-end':noDataFound}">
                <div class="col-md-8 d-flex align-items-center">
                  <mat-form-field appearance="outline" class="me-3 mat-hint-none">
                    <mat-select (selectionChange)="onStaffSelect($event)" placeholder="Select Staff">
                      <mat-option value="All">All</mat-option>
                      <mat-option value="organisation">Organisation</mat-option>
                      <mat-option *ngFor="let item of staffList" [value]="item?.fname">{{item?.fname}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="me-3 mat-hint-none cust-date-picker">
                    <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate placeholder="Start date" (dateChange)="onDateChange($event,'start')">
                      <input matEndDate placeholder="End date" (dateChange)="onDateChange($event,'end')">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                  <input matInput type="time" class=" h-3rem border-radius-5 ps-3 pe-2 me-3 d-none"
                    [(ngModel)]="timeFilter" style="border: 1px solid #9a9797"
                    (ngModelChange)="onDateChange($event,'time')">
                </div>
                <div class="col-md-4 d-flex justify-content-end align-items-center">
                  <button class="btn btn-primary d-flex align-items-center me-3" (click)="addDayoff()"
                    *ngIf="loggedInUser.data.role === 'ADMIN'">
                    <mat-icon>add</mat-icon>
                    Organisation's Dayoff
                  </button>
                </div>

              </div>
              <div class="table-responsive table-height mt-3 user-control-div-height-dayoffs">
                <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="day_table_cols.length"
                  [fromLocation]="'USERS'"></app-table-skeleton>
                <table *ngIf="!tableSkeletonLoader"
                  class="table align-middle table-nowrap table-hover mb-0 users-table w-100">
                  <thead>
                    <tr>
                      <th scope="col" *ngFor="let col of day_table_cols" class="text-primary pb-3">
                        {{col}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of daysListArray; let i = index">
                      <td>{{ item?.title }}</td>
                      <td>
                        <span *ngIf="item?.type === 'staff'">{{ item?.staff_name }}</span>
                        <span *ngIf="item?.type !== 'staff'">Organisation</span>
                      </td>
                      <td>{{ item?.start_date | dateConversion }} - {{ item?.end_date | dateConversion }}
                        ({{item.time_zone}})</td>
                      <td>{{ item?.start_time }} - {{ item?.end_time }}</td>
                      <td>
                        <div class="d-flex">
                          <label class="switch me-3">
                            <input type="checkbox" [(ngModel)]="item.status" (change)="onStatusChange(item)">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </td>
                      <!-- <td>
                                                <mat-icon class="ms-2 cursor font-size-16">edit</mat-icon>
                                            </td> -->
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex align-items-center justify-content-center no-data-found mt-2" *ngIf="noDataFound">
                  <div class="text-center">
                    <img class="border-radius-8" src="../../../../assets/images/nodata_found.svg" height="160px"
                      width="160px">
                    <h4 class="mt-4">
                      No Data Available
                    </h4>
                  </div>
                </div>
                <!-- <div>
                                    <mat-paginator [length]="daysListArray.length" [pageSize]="10" (page)="page($event)"
                                        aria-label="Select page" showFirstLastButtons hidePageSize="true">
                                    </mat-paginator>
                                </div> -->
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="5" class="me-2" *ngIf="loggedInUser.data.role === 'ADMIN'">
          <a ngbNavLink class="d-flex">
            <span class="d-sm-block font-size-12">{{developerjson.heading}}</span>
          </a>
          <ng-template ngbNavContent>
            <div class="mt-3">
              <div class="row customised-sec" [ngClass]="{'justify-content-end':noDataFound}">
                <div class="col-md-6">
                  <div class="d-flex justify-content-between my-2 align-items-center">
                    <div class="border p-2 border-radius-10 text-light w-100 me-3">
                      https://apidocs.brexa.ai/
                    </div>
                    <button class="btn btn-light border d-flex align-items-center float-end"
                      (cdkCopyToClipboardCopied)="onClipboardCopy($event)" #tp="matTooltip"
                      matTooltip="Copy to clipboard" matTooltipPosition="above" cdkCopyToClipboard="https://apidocs.brexa.ai/">
                      <mat-icon class="font-size-14 pt-1">filter_none</mat-icon>
                      Copy
                    </button>
                  </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end align-items-center">
                  <button class="btn btn-primary d-flex align-items-center me-3"
                    (click)="addDeveloperUser(addDeveloperUserTemplate)">
                    <mat-icon>add</mat-icon> {{developerjson.add_btn}}
                  </button>
                </div>
              </div>
              <div class="table-responsive table-height mt-3 user-control-div-height-dayoffs">
                <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="developer_table_cols.length"
                  [fromLocation]="'USERS'"></app-table-skeleton>
                <table *ngIf="!tableSkeletonLoader"
                  class="table align-middle table-nowrap table-hover mb-0 users-table w-100">
                  <thead>
                    <tr>
                      <th scope="col" *ngFor="let col of developer_table_cols" class="text-primary pb-3">
                        {{col}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of developerInfo; let i = index" [ngClass]="{'inactiveClass': !item.status }">
                      <td>{{ item?.fname }} {{item?.lname}}</td>
                      <td>{{ item?.email }} </td>
                      <td><span *ngIf="item?.phone_number">{{ item?.ccode }} {{item?.phone_number}}</span></td>
                      <td>
                        <div class="d-flex align-items-center">
                          <label class="me-2">{{ item?.api_key }}</label>
                          <mat-icon class="cursor w-25" (cdkCopyToClipboardCopied)="onClipboardCopy($event)"
                            matTooltip="Copy to clipboard" matTooltipPosition="above"
                            [cdkCopyToClipboard]="item?.api_key" #tp="matTooltip">content_copy</mat-icon>
                          <mat-icon class="cursor w-25" matTooltipPosition="above" (click)="regenerateAPIkey(item.id)"
                            matTooltip="Refresh">refresh</mat-icon>
                        </div>
                      </td>
                      <td>
                        <div class="dropdown" ngbDropdown>
                          <mat-icon [matMenuTriggerFor]="menuadd" class="cursor">more_vert</mat-icon>
                          <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                            <button mat-menu-item (click)="addDeveloperUser(addDeveloperUserTemplate,item)"
                              class="py-2">
                              {{developerjson.edit_btn}}
                            </button>
                            <button mat-menu-item (click)="confirmDeveloperUser(item.id)" class="py-2">
                              {{developerjson.delete_btn}}
                            </button>
                            <button mat-menu-item (click)="onStatusChangeDeveloperUser(item.id,item.status)"
                              class="py-2">
                              <span *ngIf="item?.status">{{developerjson.inactivate_btn}}</span>
                              <span *ngIf="!item?.status">{{developerjson.activate_btn}}</span>
                            </button>
                            <button mat-menu-item (click)="openModelPswChange(developerPswReset,item.id)" class="py-2">
                              {{developerjson.reset_psw}}
                            </button>
                          </mat-menu>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex align-items-center justify-content-center no-data-found mt-2" *ngIf="noDataFound">
                  <div class="text-center">
                    <img class="border-radius-8" src="../../../../assets/images/nodata_found.svg" height="160px"
                      width="160px">
                    <h4 class="mt-4">
                      No Data Available
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="customNav"></div>
    </div>
  </div>
  <!-- <ng-container *ngIf="!(loggedInUser.data.role === 'ADMIN' || loggedInUser.data.role === 'MANAGER')">
    <div class="d-flex align-items-center justify-content-center">
      <h6 class="text-secondary">You are not authorized to view this.</h6>
    </div>
  </ng-container> -->
</div>

<ng-template #selectStaffModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-primary">{{teamjson.field_member_list}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row px-2 mb-3">
      <label class="mb-2 fw-600 text-primary"> {{teamjson.field_member}} <small class="text-danger">*</small></label>
      <ng-select class="form-control" bindLabel="group_name" [multiple]="true"
        placeholder='{{teamjson.select_placeholder}}' [(ngModel)]="selected_group">
        <ng-option *ngFor="let staff of staffListArray" value="{{staff.id}}">
          {{staff.fname}}
        </ng-option>
      </ng-select>
    </div>
  </div>
  <div class="modal-footer border-0 d-flex justify-content-end mb-5">
    <button type="button" class="d-flex align-items-center btn btn-primary small"
      (click)="addToList()">{{teamjson.save_btn}}
    </button>
  </div>
</ng-template>

<ng-template #staffAssignedSerices let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-primary">Assigned Services</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="table-responsive table-height mt-4" *ngIf="aptServiceList.length > 0 else no_serivce">
      <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="4" [fromLocation]="'USERS'"></app-table-skeleton>
      <table *ngIf="!tableSkeletonLoader" class="table align-middle table-nowrap table-hover mb-0 users-table w-100">
        <thead>
          <tr>
            <th scope="col" class="text-primary pb-3">
              Service
            </th>
            <th scope="col" class="text-primary pb-3">
              Mode
            </th>
            <th scope="col" class="text-primary pb-3">
              Price
            </th>
            <th scope="col" class="text-primary pb-3">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="cursor" *ngFor="let item of aptServiceList; let i = index">
            <td>{{ item?.name }}</td>
            <td>{{item.consultation_data.mode}}</td>
            <td>
              <mat-form-field appearance="outline" class="w-30 mat-hint-none">
                <input matInput type="number" (wheel)="$event.preventDefault()" placeholder="Staff Price"
                  [(ngModel)]="item.consultation_data.staff_data[0].staff_special_price" (blur)="onChange('',item)">
              </mat-form-field>
            </td>
            <td>
              <div class="d-flex">
                <label class="switch me-3">
                  <input type="checkbox" [checked]="item.consultation_data.staff_data[0].is_active"
                    (change)="onChange($event,item)">
                  <span class="slider round"></span>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #no_serivce>
      <div class="d-flex align-items-center justify-content-center no-data-found-sec mt-2">
        <div class="text-center">
          <img class="border-radius-8" src="../../../../assets/images/nodata_found.svg" height="160px" width="160px">
          <h4 class="mt-4">
            No Data Available
          </h4>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #addDeveloperUserTemplate let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-primary">{{developerjson.add_btn}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addDeveloperForm" (ngSubmit)="submitDeveloperUser()" class="mt-3">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <label class="fw-600 mb-2">{{developerjson.field_name}} <small class="text-danger">*</small></label>
          <mat-form-field appearance="outline" class="w-100">
            <input style="outline: none" matInput
              placeholder='{{developerjson.enter_placeholder}} {{developerjson.field_name}}' formControlName="fname"
              #myFirstInput />
            <mat-error class="error text-danger d-flex" *ngIf="isdeveloperSubmitted && d['fname'].errors">
              <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
              <span *ngIf="d['fname'].errors['required']">{{developerjson.field_name}}
                {{errorjson.required}}</span>
              <span *ngIf="d['fname'].errors['pattern']">{{errorjson.invalid}}
                {{developerjson.field_name}}
              </span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <label class="fw-600 mb-2">{{developerjson.field_lastname}}</label>
          <mat-form-field appearance="outline" class="w-100">
            <input style="outline: none" matInput
              placeholder='{{developerjson.enter_placeholder}} {{developerjson.field_lastname}}'
              formControlName="lname" />
            <mat-error class="error text-danger d-flex" *ngIf="isdeveloperSubmitted && d['lname'].errors">
              <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
              <span *ngIf="d['lname'].errors['pattern']">{{errorjson.invalid}}
                {{developerjson.field_lastname}}
              </span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <label class="fw-600 mb-2">{{developerjson.field_email}} <small class="text-danger">*</small></label>
          <mat-form-field appearance="outline" class="w-100">
            <input style="outline: none" matInput
              placeholder='{{developerjson.enter_placeholder}} {{developerjson.field_email}}' formControlName="email" />
            <mat-error class="error text-danger d-flex" *ngIf="isdeveloperSubmitted && d['email'].errors">
              <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
              <span *ngIf="d['email'].errors['required']">{{developerjson.field_email}}
                {{errorjson.required}}</span>
              <span *ngIf="d['email'].errors['pattern']">{{errorjson.invalid}}
                {{developerjson.field_email}}
              </span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <label class="fw-600 mb-2">{{developerjson.field_number}} <small class="text-danger">*</small></label>
          <div class="d-flex">
            <mat-form-field appearance="outline" class="me-2">
              <mat-select formControlName="ccode" placeholder="{{developerjson.enter_placeholder}} Country Code">
                <mat-option>
                  <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let country of filteredBanks | async"
                  value={{country.country_code}}>{{country.country_code}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100">
              <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                placeholder="{{developerjson.field_number}}" formControlName="phone_number" type="text" />
              <mat-error class="error text-danger d-flex" *ngIf="isdeveloperSubmitted && d['phone_number'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="d['phone_number'].errors['required']">{{developerjson.field_number}}
                  {{errorjson.required}}</span>
                <span
                  *ngIf="d['phone_number'].errors['pattern'] || d['phone_number'].errors['minlength'] || d['phone_number'].errors['maxlength']">{{errorjson.invalid}}
                  {{developerjson.field_number}}</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 password-field" *ngIf="!addDeveloperForm.value.id">
          <label class="fw-600 mb-2">{{developerjson.field_psw}} <small class="text-danger">*</small></label>
          <mat-form-field appearance="outline" class="w-100">
            <input style="outline: none" matInput
              placeholder="{{developerjson.enter_placeholder}} {{developerjson.field_psw}}" formControlName="password"
              [type]="hide ? 'password' : 'text'" />
            <mat-icon (click)="hide = !hide" class="cursor m-auto">{{
              hide ? "visibility_off" : "visibility"
              }}</mat-icon>
            <mat-error class="error text-danger d-flex" *ngIf="isdeveloperSubmitted && d['password'].errors">
              <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
              <span *ngIf="d['password'].errors['required']">{{developerjson.field_psw}}
                {{errorjson.required}}</span>
              <span *ngIf="d['password'].errors['pattern']">{{errorjson.invalid}}
                {{developerjson.field_psw}}</span>
            </mat-error>
          </mat-form-field>
          <label class="ms-1 mb- text-secondary mb-2 font-size-12" for="remember-me">Your password must be at least 5
            characters long and should include a combination of uppercase letters, lowercase letters, numbers, and
            special characters.</label>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-light me-3" type="button"
          (click)="modal.dismiss('cross click')">{{developerjson.close_btn}}</button>
        <button type="submit" class="btn btn-primary">{{developerjson.save_btn}}</button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #developerPswReset let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-primary">{{developerjson.reset_psw}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="developerPswRestForm" (ngSubmit)="developerPswChange()" class="mt-3">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 password-field">
          <label class="fw-600 mb-2">{{developerjson.field_psw}} <small class="text-danger">*</small></label>
          <mat-form-field appearance="outline" class="w-100">
            <input style="outline: none" matInput
              placeholder="{{developerjson.enter_placeholder}} {{developerjson.field_psw}}" formControlName="password"
              [type]="hide ? 'password' : 'text'" />
            <mat-icon (click)="hide = !hide" class="cursor m-auto">{{
              hide ? "visibility_off" : "visibility"
              }}</mat-icon>
            <mat-error class="error text-danger d-flex" *ngIf="isdeveloperSubmitted && rest_form['password'].errors">
              <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
              <span *ngIf="rest_form['password'].errors['required']">{{developerjson.field_psw}}
                {{errorjson.required}}</span>
              <span *ngIf="rest_form['password'].errors['pattern']">{{errorjson.invalid}}
                {{developerjson.field_psw}}</span>
            </mat-error>
          </mat-form-field>
          <label class="ms-1 mb- text-secondary mb-2 font-size-12" for="remember-me">Your password must be at least 5
            characters long and should include a combination of uppercase letters, lowercase letters, numbers, and
            special characters.</label>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-light me-3" type="button"
          (click)="modal.dismiss('cross click')">{{developerjson.close_btn}}</button>
        <button type="submit" class="btn btn-primary">{{developerjson.save_btn}}</button>
      </div>
    </form>
  </div>
</ng-template>
export const CHANNELS_LIST = [
  {
    display_name: 'Manual',
    value: 'manual'
  },
  {
    display_name: 'Bot',
    value: 'bot'
  },
  {
    display_name: 'Web Forms',
    value: 'web_forms'
  },  
  {
    display_name: 'Meeting',
    value: 'meeting'
  },
  {
    display_name: 'Call',
    value: 'call'
  },
  {
    display_name: 'Google',
    value: 'google'
  },
  {
    display_name: 'Facebook',
    value: 'facebook'
  },
  {
    display_name: 'Instagram',
    value: 'instagram'
  },
  {
    display_name: 'Iframe',
    value: 'iframe'
  },
  {
    display_name: 'Whatsapp',
    value: 'whatsapp'
  },
  {
    display_name: 'Bulk Upload',
    value: 'bulk_upload'
  },
  {
    display_name: 'Employee Reference',
    value: 'employee_reference'
  },
  {
    display_name: 'Just Dail',
    value: 'just_dial'
  },
  {
    display_name: 'Sulekha',
    value: 'sulekha'
  },
  {
    display_name: 'Classified Listing',
    value: 'classified_listing'
  },
  {
    display_name: 'Word of Mouth',
    value: 'word_of_mouth'
  },
  {
    display_name: 'Walk in Customer',
    value: 'walk_in_customer'
  },
  {
    display_name: 'Preview',
    value: 'preview'
  },
  {
    display_name: 'Smart Forms',
    value: 'smart_forms'
  },
  {
    display_name: 'Work Flow',
    value: 'work_flow'
  },
  {
    display_name: 'Marketing',
    value: 'marketing'
  },
  {
    display_name: 'Zapier',
    value: 'zapier'
  }
];
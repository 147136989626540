import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  activeId = 1;
  loggedInUser: any;
  settings_menu = [
    {
      title: "Organisation Settings",
      slug: "general_settings",
      route: "../settings",
      icon: "mib",
      id: 1,
      viewFeature: false,
      launch_dkly_keys: []
    },
    {
      title: "Users & Controls",
      slug: "user_controls",
      route: "users&controls",
      icon: "mib",
      id: 2,
      viewFeature: false,
      launch_dkly_keys: []
    },
    {
      title: "Communication Settings",
      slug: "communications",
      route: "sms",
      icon: "mib",
      id: 3,
      viewFeature: false,
      launch_dkly_keys: ['appointment']
    },
    {
      title: "Payment Settings",
      slug: "payments_settings",
      route: "payments",
      icon: "mib",
      id: 4,
      viewFeature: false,
      launch_dkly_keys: []
    },
    {
      title: "Custom Fields",
      slug: "form_fields",
      route: "custom-fields",
      icon: "mib",
      id: 6,
      viewFeature: false,
      launch_dkly_keys: []
    },
    {
      title: "Tags",
      slug: "tag",
      route: "tags",
      icon: "mib",
      id: 7,
      viewFeature: false,
      launch_dkly_keys: []
    },
    {
      title: "Contact Types",
      slug: "contact_type",
      route: "contact_types",
      icon: "mib",
      id: 8,
      viewFeature: false,
      launch_dkly_keys: ['contact']
    },
    // {
    //   title: "Custom Doman URL",
    //   slug: "custom_domain",
    //   route: "",
    //   icon: "mib",
    //   id: 8
    // },
    {
      title: "Integrations",
      slug: "integration",
      route: "integrations",
      icon: "mib",
      id: 9,
      viewFeature: false,
      launch_dkly_keys: []
    },
    // {
    //   title: "Data Administration",
    //   slug: "data_administration",
    //   route: "data-administration",
    //   icon: "mib",
    //   id: 10
    // },
    // {
    //   title: "Email",
    //   slug: "email",
    //   route: "",
    //   icon: "mib",
    //   id: 11
    // },
    // {
    //   title: "Whats app",
    //   slug: "whats_app",
    //   route: "",
    //   icon: "mib",
    //   id: 12
    // },
    // {
    //   title: "Developer",
    //   slug: "developer",
    //   route: "",
    //   icon: "mib",
    //   id: 12
    // },
    // {
    //   title: "Security",
    //   slug: "security",
    //   route: "",
    //   icon: "mib",
    //   id: 13
    // },
    // {
    //   title: "Biling",
    //   slug: "biling",
    //   route: "billing",
    //   icon: "mib",
    //   id: 14
    // },
    // {
    //   title: "SMS Notifications",
    //   slug: "sms_notifications",
    //   route: "sms",
    //   icon: "mib",
    //   id: 15
    // },
    {
      title: "Email notifications",
      slug: "email_notifications",
      route: "email-notifications",
      icon: "mib",
      id: 16,
      viewFeature: false,
      launch_dkly_keys: []
    },
    // {
    //   title: "Whatsapp",
    //   slug: "whatsapp",
    //   route: "whatsapp-notificaions",
    //   icon: "mib",
    //   id: 17
    // },
    {
      title: "Activity",
      slug: "activity",
      route: "activity",
      icon: "mib",
      id: 18,
      viewFeature: false,
      launch_dkly_keys: []
    },
    {
      title: "Lost Reasons",
      slug: "lost-reasons",
      route: "lost-reasons",
      icon: "mib",
      id: 19,
      viewFeature: false,
      launch_dkly_keys: []
    },
    {
      title: "Manage My Plans",
      slug: "features",
      route: "subscription",
      icon: "mib",
      id: 5,
      viewFeature: false,
      launch_dkly_keys: ['subscription_plans']
    },
    {
      title: "Current Plans",
      slug: "plan_details",
      route: "plan_details",
      icon: "mib",
      id: 20,
      viewFeature: false,
      launch_dkly_keys: ['subscription_plans']
    },
    {
      title: "Transaction History",
      slug: "transaction_history",
      route: "transaction_history",
      icon: "mib",
      id: 21,
      viewFeature: false,
      launch_dkly_keys: ['subscription_plans']
    }
  ];
  featuresData: any;

  constructor(private router: Router, private authservice: AuthService) {
    this.settings_menu.forEach((item: any) => {
      if (this.router.url.includes(item.route) && item.route !== '') {
        this.activeId = item.id;
      }
    });
    this.getFeatues();
    this.handlePopState = this.handlePopState.bind(this);
    window.addEventListener('popstate', this.handlePopState);
  }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  }
  handlePopState() {
    const arrayOfUrl = window.location.pathname.split('/').filter(item => item !== '');
    this.settings_menu.forEach((data) => {
      if (arrayOfUrl.length === 1) {
        this.activeId = 1;
      } else if (arrayOfUrl[arrayOfUrl.length - 1] === data.route) {
        this.activeId = data.id;
      }
    });
  }
  ngOnDestroy() {
    window.removeEventListener('popstate', this.handlePopState);
  }

  getFeatues() {
    this.authservice.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      this.settings_menu.forEach((item: any) => {
        const launch_dkly_keys = item.launch_dkly_keys.every((launchKeys: string) => this.featuresData[launchKeys]);
        launch_dkly_keys ? (item.viewFeature = true) : (item.viewFeature = false);
      });
    });
  }
}

<div class="container-fluid p-2">
    <ng-container *ngIf="showType === 'campaigns-list'">
        <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-primary d-flex align-items-center" (click)="createCampaign()"><mat-icon class="me-2"
                    style="color: rgb(255, 255, 255);"> campaign </mat-icon>Create campaign</button>
        </div>
        <div class="m-3 p-4 bg-white border-radius-8 ">
            <div class="table-responsive table-height">
                <table class="table table-borderless align-middle table-hover">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Campaign name</th>
                            <th scope="col">Status </th>
                            <th scope="col">Channel </th>
                            <th scope="col">Reports</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of campaignsList; let i = index">
                            <th scope="row">{{i + 1}}.</th>
                            <td>
                                <p class="fw-600 text-success mb-1">{{item.title}}</p>
                                <p class="text-light d-flex align-items-center mb-1">{{item.type}} <mat-icon
                                        class="font-size-8 d-flex align-items-center ms-3">fiber_manual_record</mat-icon>
                                    {{item.segment}}</p>
                                <p class="mb-0">{{item.date}}</p>
                            </td>
                            <td>
                                <p class="d-inline-flex align-items-center px-2 py-1 border-radius-8 mb-0"
                                    [ngClass]="item.status === 'Draft' ? 'bg-draft' : item.status === 'Sent' ? 'bg-sent' : 'bg-failed'">
                                    {{item.status}}</p>
                            </td>
                            <td>{{item.channel}}</td>
                            <td>
                                <mat-form-field appearance="outline" class="ms-3 mat-hint-none">
                                    <mat-select placeholder="Tasks" class="">
                                        <mat-option value="View Report">View Report</mat-option>
                                        <mat-option value="Review & Send">Review & Send</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <mat-paginator class="" [length]="campaignsList.length" [pageSize]="20" aria-label="Select page"
                    showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="showType === 'campaign-channels'">
        <div class="row" style="min-height: 83vh">
            <div class="col-md-12 pe-2 col-sm-12">
                <div class=" mb-0" style="min-height: 83vh">
                    <div class="banner-card bg-primary p-5 d-flex align-items-center justify-content-between mb-4 m-3">
                        <div class="">
                            <h3 class="text-white font-size-16">Launch a one-click marketing campaign</h3>
                            <p class="font-size-13">Reach out to customers and promote your products and offers to
                                them.
                                Launch SMS & Whatsapp
                                marketing
                                campaigns and target your customers using customizable templates to grow your
                                business</p>
                            <button class="btn bg-white text-primary d-flex align-items-center font-size-13"><mat-icon
                                    class="me-2">campaign</mat-icon>How it works</button>
                        </div>
                        <div class="banner_image">
                            <img src="assets/images/campaign.svg">
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mx-4 mb-3">
                        <h5 class="fw-700 text-primary font-size-16">Please choose a Channel to continue</h5>
                        <button class="btn btn-light text-primary d-flex align-items-center mb-3"
                            style="border: 1px solid;"><mat-icon class="me-2">equalizer</mat-icon>Overview
                            Analytics</button>
                    </div>
                    <div class="row mx-3">
                        <div class="col-3 mb-5" *ngFor="let item of channels_list">
                            <div class="channel-card py-3 cursor" (click)="openModel(selectTypeModel,item.name)">
                                <img [src]="item.icon" class="mb-3 mt-4">
                                <h5 class="fw-700 text-primary font-size-16 mt-2">{{item.name}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #selectTypeModel let-modal>
            <div class="modal-header">
                <h4 class="modal-title text-dark fw-500" id="exampleModalLabel">Choose how would you like to proceed
                </h4>
                <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"
                    aria-hidden="true"></button>
            </div>
            <div class="modal-body">
                <div class="row text-center mb-5">
                    <div class="col-6 p-3" style="border-right: 1px solid #9C9C9C80;">
                        <img src="assets/images/book-writing.svg" height="214">
                        <label class="font-size-16">Create, Submit, Manage and send
                            templates for approval</label>
                        <div class="d-flex justify-content-center mt-3">
                            <button class="btn btn-primary d-flex align-items-center"
                                (click)="modelRef.dismiss()"><mat-icon
                                    class="me-2 text-white font-size-18 d-flex align-items-center">border_color</mat-icon>Templates</button>
                        </div>
                    </div>
                    <div class="col-6 p-3">
                        <img src="assets/images/book-reading.svg" height="214">
                        <label class="font-size-16">Create your campaigns and broadcast to
                            wider audience</label>
                        <div class="d-flex justify-content-center mt-3">
                            <button class="btn btn-primary d-flex align-items-center"
                                (click)="modelRef.dismiss()"><mat-icon
                                    class="me-2 text-white font-size-22 d-flex align-items-center">campaign</mat-icon>From
                                Scratch</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</div>
import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CrmService } from 'src/app/core/service/crm.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { Location } from '@angular/common';
import { SettingsService } from 'src/app/core/service/settings.service';
import { countryData } from 'src/app/core/data/countryData';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactsModalComponent } from 'src/app/pages/shared/contacts-modal/contacts-modal.component';
import { LeadModalComponent } from 'src/app/pages/shared/lead-modal/lead-modal.component';
import { CompanyModalComponent } from 'src/app/pages/shared/company-modal/company-modal.component';
import { currencyJson } from 'src/app/core/data/currencyData';
import { time_zone_json } from 'src/app/core/data/mobile_json';
import { PipelineModalComponent } from 'src/app/pages/shared/pipeline-modal/pipeline-modal.component';
import { environment } from 'src/environments/environment';
import { ErrorLoadImage } from 'src/app/core/common/common-functions';
import { ToastrService } from 'ngx-toastr';
import { CustomFieldsService } from 'src/app/core/service/custom-fields.service';
import { ContactType } from 'src/app/core/data/crm_modal_json';
import { CommonService } from 'src/app/core/service/common.service';
import { LinkOpenWarningModalComponent } from 'src/app/pages/shared/link-open-warning-modal/link-open-warning-modal.component';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';

@Component({
  selector: 'app-crm-info',
  templateUrl: './crm-info.component.html',
  styleUrls: ['./crm-info.component.scss']
})
export class CrmInfoComponent implements OnInit {
  panelOpenState = false;
  selected_id!: string;
  selected_type!: string;
  // info_array: any = [];
  contact_info: any;
  company_info: any;
  intro_obj: any = {};
  input_field_fname = false;
  input_field_lname = false;
  input_field_email = false;
  input_field_phno = false;
  input_field_cmpny_name = false;
  input_field_cmpny_email = false;
  input_field_cmpny_phno = false;
  input_field_cmpny_website = false;
  input_field_cmpny_address = false;
  input_field_cmpny_city = false;
  input_field_cmpny_zipcode = false;
  input_field_cmpny_description = false;
  cardFields: any[] = [];
  sliceItem = 5;
  slice_additional_Item = 5;
  storeId: any;
  cardDetails: any;
  enquiry_seemore = true;
  contact_seemore = true;
  company_seemore = true;
  pipeline_seemore = true;
  stagesList: any[] = [];
  cardStageIndex!: number;
  contact_owner: any;
  enquiry_owner: any;
  company_owner: any;
  staffMembers: any = [];
  companiesListArray: any = [];
  countryjson = countryData;
  currencyjson = currencyJson;
  timezonejson = time_zone_json;
  tagsListArray: any;
  product_details: any;
  custom_contact_fields: any[] = [];
  addmore = false;
  custom_field: any[] = [];
  leadAssignee: any;
  contactAssignee: any;
  companyAssignee: any;
  dealAssignee: any;
  priorityItems = [{ id: 'High', name: 'High' }, { id: 'Low', name: 'Low' }, { id: 'Medium', name: 'Medium' }];
  contactTypeItems : any;
  chatbot_Variable: any[] = [];
  pipeline_addFields_seemore = true;
  info_data: any;
  validateUserAccess = false;
  loggedInUser: any;
  contactstagsListArray: any[] = [];
  presignedurl = environment.preSendUrl;
  uuidRegExp = new RegExp(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i
  );
  headerText = '';
  isVisibleDetailsBox = true;
  pipelineDetails: any;
  companytagsListArray: any[] = [];

  detailsBox: {
    detailsBoxTitle: string,
    detailBoxBody: string,
    isTitleCase: boolean
  } = {
      detailsBoxTitle: '',
      detailBoxBody: '',
      isTitleCase: false
    };
  isEditVisible = false;

  constructor(private route: ActivatedRoute, private router: Router, private service: CrmService, private setupService: SetUpService,
    private _location: Location, private settingsService: SettingsService, private modalService: NgbModal, private toaster: ToastrService,
    private _customFields: CustomFieldsService, private commonService: CommonService, private renderer: Renderer2, private awsUtilService: AwsUtilsService) { }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params timeline - ", params);
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      if (this.selected_id) {
        this.getRelatedIdInfo(this.selected_id);
      }
    });
    this.service.registeredEmailId.subscribe((data: any) => {
      this.usersAccess(data);
    });
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any): void {
    console.log(event.target?.offsetParent?.classList.contains('details-box'), this.isVisibleDetailsBox);
    if (!event.target?.offsetParent?.classList.contains('details-box') && !this.isVisibleDetailsBox) {
      this.onCloseDetailsBox();
    }
  }

  seeMore(type: string) {
    if (type === 'enquiry') {
      this.enquiry_seemore = !this.enquiry_seemore;
    } else if (type === 'contact') {
      this.contact_seemore = !this.contact_seemore;
    } else if (type === 'company') {
      this.company_seemore = !this.company_seemore;
    } else if (type === 'pipeline') {
      this.pipeline_seemore = !this.pipeline_seemore;
    }
  }

  getRelatedIdInfo(id: string) {
    this.organisationMembersList();
    this.getCompaniesList();
    if (this.selected_type !== 'contact') this.getTags();
    this.getContactTags();

    if (this.selected_type === 'contact') {
      this.getCompanyTags();
      this.getContactTypes();
      this.service.getContactById(id).subscribe({
        next: (resp: any) => {
          this._customFields.getContactModelFields().subscribe({
            next: (fieldsresp: any) => {
              // this.contact_info = resp.data[0];
              const fields_element: any = {};
              fields_element['custom_contact_fields'] = resp.data[0].custom_contact_fields;
              Object.entries(fieldsresp.data).map((field: any) => {
                if (field[0] !== "contact_fields_order" && field[0] !== "custom_contact_templates" && field[0] !== "id" && field[0] !== "organisation") {
                  if (field[1].on_form || field[1].is_inner_screen) fields_element[field[0]] = resp.data[0][field[0]];
                } else if (field[0] === "custom_contact_templates") {
                  field[1].forEach((element: any) => {
                    if (element.on_form || element.is_inner_screen) {
                      const customFieldIndex = resp.data[0].custom_contact_fields.findIndex((item: any) => item.custom_contact_template === element.id);
                      const custom_field_includes = customFieldIndex !== -1;
                      if (!custom_field_includes) { //this field is new its not created while contact is created 
                        const body = {
                          "id": "",
                          "label": element.label,
                          "data_type": element.data_type,
                          "options": element.options,
                          "on_form": element.on_form,
                          "is_inner_screen": element.is_inner_screen,
                          "data": "",
                          "is_mandatory": element.is_mandatory,
                          "is_active": element.is_active,
                          "custom_contact_template": element.id,
                          "contact": id,
                          "start": "",
                          "end": ""
                        };
                        fields_element['custom_contact_fields'].push(body);
                      } else { // exist contact fields adding options to the element
                        fields_element['custom_contact_fields'][customFieldIndex].options = element.options;
                      }
                    }
                  });
                } else if (field[0] === "id") {
                  fields_element['id'] = resp.data[0].id;
                }
              });
              fields_element.contact_id = resp.data[0].display_id;
              fields_element.contact_type_name = resp.data[0].contact_type_name;
              fields_element.created_at = resp.data[0].created_at;
              fields_element.updated_at = resp.data[0].updated_at;
              fields_element.updated_by_name = resp.data[0].updated_by_name;
              fields_element.created_by_name = resp.data[0].created_by_name;
              fields_element.is_active = resp.data[0].is_active;
              this.contact_info = fields_element;
              this.getStaffDataById(resp.data[0].owner, 'contact');
              this.getStaffByAssignee(resp.data[0].assignee, 'contact');
              if (resp.data[0].tags) this.getTagData(resp.data[0].tags, 'contact');
              console.log(this.contact_info, "contact_info type");
            }
          });
          this.intro_obj = resp.data[0];
          this.onUpdateHeaderTitle();
          // this.contact_info = resp.data[0];
          this.service.registeredEmailId.next(resp.data[0]);
          this.custom_contact_fields = resp.data[0].custom_contact_fields;
          this.getStaffDataById(this.contact_info?.owner, 'contact');

          this.getStaffByAssignee(this.contact_info?.assignee, 'contact');
          this.getChatBotVaribles(id);
          if (this.contact_info?.tags) this.getTagData(this.contact_info.tags, 'contact');
          if (resp.data[0].company) {
            this.service.getCompanyById(resp.data[0].company).subscribe({
              next: (com_resp: any) => {
                if (com_resp.data.length === 0) return;
                this.company_info = com_resp.data[0];
                this.custom_field = com_resp.data[0].custom_company_fields;
                console.log(this.custom_field.length, "customfiled");
                this.getStaffByAssignee(this.company_info.assignee, 'company');

                this.getStaffDataById(this.company_info.owner, 'company');
                if (this.company_info.tags) this.getTagData(this.company_info.tags, 'company');

              }
            });
          }
        }
      });
    } else if (this.selected_type === 'lead') {
      this.service.getLeadById(id).subscribe({
        next: async (resp: any) => {
          this.info_data = resp.data[0];
          this.intro_obj = resp.data[0];
          this.onUpdateHeaderTitle();
          this._customFields.getEnquiryModelFields().subscribe(async (leadFieldResp: any) => {
            const fields_element: any = {};
            fields_element['custom_lead_fields'] = resp.data[0].custom_lead_fields;
            Object.entries(leadFieldResp.data).map((field: any) => {
              if (field[0] !== "lead_fields_order" && field[0] !== "custom_lead_templates" && field[0] !== "id" && field[0] !== "organisation") {
                if (field[1].on_form || field[1].is_inner_screen) fields_element[field[0]] = resp.data[0][field[0]];
              } else if (field[0] === "custom_lead_templates") {
                field[1].forEach((element: any) => {
                  if (element.on_form || element.is_inner_screen) {
                    const customFieldIndex = resp.data[0].custom_lead_fields.findIndex((item: any) => item.custom_lead_template === element.id);
                    const custom_field_includes = customFieldIndex !== -1;
                    if (!custom_field_includes) { //this field is new its not created
                      const body = {
                        "id": "",
                        "label": element.label,
                        "data_type": element.data_type,
                        "options": element.options,
                        "on_form": element.on_form,
                        "is_inner_screen": element.is_inner_screen,
                        "data": "",
                        "is_mandatory": element.is_mandatory,
                        "is_active": element.is_active,
                        "custom_lead_template": element.id,
                        "lead": id,
                        "start": "",
                        "end": ""
                      };
                      fields_element['custom_lead_fields'].push(body);
                    } else { // exist lead fields adding options to the element
                      fields_element['custom_lead_fields'][customFieldIndex].options = element.options;
                    }
                  }
                });
              } else if (field[0] === "id") {
                fields_element['id'] = resp.data[0].id;
              }
            });
            fields_element.enquiry_id = resp.data[0].enquiry_id;
            fields_element.created_at = resp.data[0].created_at;
            fields_element.updated_at = resp.data[0].updated_at;
            fields_element.updated_by_name = resp.data[0].updated_by_name;
            fields_element.created_by_name = resp.data[0].created_by_name;
            fields_element.type = resp.data[0].type;
            fields_element.due_date = resp.data[0].due_date;
            fields_element.lost_reason_text = resp.data[0].lost_reason_text;
            this.intro_obj = fields_element;// console.log("leads -", resp);
            const systemFields = Object.entries(this.intro_obj);
            const promises = systemFields.map(async (field: any) => {
              if (field[1]) {
                if (field[0] === 'scheduler_category') {
                  const results: any = await this.setupService.getAllCategories().toPromise();
                  const selectedItem: any[] = [];
                  results.data.filter((item: any) => {
                    field[1].forEach((element: any) => {
                      if (item.id === element) {
                        selectedItem.push(item.name);
                      }
                    });
                  });
                  if (selectedItem.length !== 0) field[1] = selectedItem;

                } else if (field[0] === "scheduler") {
                  const results: any = await this.setupService.getAllConsulations().toPromise();
                  // field[1] = results.data.name;
                  const selectedItem: any[] = [];
                  results.data.filter((item: any) => {
                    field[1].forEach((element: any) => {
                      if (item.id === element) {
                        selectedItem.push(item.name);
                      }
                    });
                  });
                  if (selectedItem.length !== 0) field[1] = selectedItem;
                } else if (field[0] === "product" || field[0] === "product_category" || field[0] === "service" || field[0] === "service_category") {
                  const store_resp: any = await this.setupService.getStore().toPromise();
                  if (field[0] === "product" || field[0] === "service") {
                    const results: any = await this.setupService.getDropDownProducts(store_resp.data.id, field[0]).toPromise();
                    // field[1] = results.data.name;
                    const selectedItem: any[] = [];
                    results.data.filter((item: any) => {
                      field[1].forEach((element: any) => {
                        if (item.id === element) {
                          selectedItem.push(item.name);
                        }
                      });
                    });
                    if (selectedItem.length !== 0) field[1] = selectedItem;
                  } else {
                    const results: any = await this.setupService.getProductCategories(store_resp.data.id, field[0]).toPromise();
                    // const selectedItem = results.data.filter((item: any) => item.id === field[1]);
                    // if (selectedItem.length !== 0) field[1] = selectedItem[0].name;
                    const selectedItem: any[] = [];
                    results.data.filter((item: any) => {
                      field[1].forEach((element: any) => {
                        if (item.id === element) {
                          selectedItem.push(item.name);
                        }
                      });
                    });
                    if (selectedItem.length !== 0) field[1] = selectedItem;
                  }
                } else if (field[0] === "company") {
                  const results: any = await this.service.getCompanyById(field[1]).toPromise();
                  field[1] = results.data[0]?.name;
                }
              }
            });
            await Promise.all(promises);
            this.intro_obj = Object.fromEntries(systemFields);
            console.log(this.intro_obj);

            this.custom_field = resp.data[0].custom_lead_fields;
            this.getStaffDataById(resp.data[0]?.owner, 'lead');
            this.getStaffByAssignee(resp.data[0].assignee, 'lead');
            if (resp.data[0].tags) this.getTagData(resp.data[0].tags, 'lead');
          });
          // if (this.intro_obj.product.length !== 0) this.getProductById(this.intro_obj.product);
          if (resp.data[0].contact) {
            this.service.getContactById(resp.data[0].contact).subscribe({
              next: (con_resp: any) => {
                this.service.registeredEmailId.next(con_resp?.data[0]);
                // console.log("contacts - ", con_resp);
                this._customFields.getContactModelFields().subscribe({
                  next: (fieldsresp: any) => {
                    const fields_element: any = {};
                    fields_element['custom_contact_fields'] = con_resp.data[0].custom_contact_fields;
                    Object.entries(fieldsresp.data).map((field: any) => {
                      if (field[0] !== "contact_fields_order" && field[0] !== "custom_contact_templates" && field[0] !== "id" && field[0] !== "organisation") {
                        if (field[1].on_form && field[1].is_inner_screen) fields_element[field[0]] = con_resp.data[0][field[0]];
                      } else if (field[0] === "custom_contact_templates") {
                        fields_element['custom_contact_fields'] = con_resp.data[0].custom_contact_fields;
                        this.custom_contact_fields = con_resp.data[0].custom_contact_fields;
                      } else if (field[0] === "id") {
                        fields_element['id'] = con_resp.data[0].id;
                      }
                    });
                    fields_element.contact_id = con_resp.data[0].display_id;
                    fields_element.created_at = con_resp.data[0].created_at;
                    fields_element.updated_at = con_resp.data[0].updated_at;
                    fields_element.updated_by_name = con_resp.data[0].updated_by_name;
                    fields_element.created_by_name = con_resp.data[0].created_by_name;
                    fields_element.is_active = con_resp.data[0].is_active;
                    this.contact_info = fields_element;
                    console.log(this.contact_info, "contact_info");

                    this.getStaffByAssignee(con_resp.data[0].assignee, 'contact');
                    // this.contact_info = con_resp.data[0];
                    this.getStaffDataById(this.contact_info.owner, 'contact');
                    if (this.contact_info.tags) this.getTagData(this.contact_info.tags, 'contact');
                  }
                });


                if (resp.data[0].company) {
                  this.service.getCompanyById(resp.data[0].company).subscribe({
                    next: (comp_resp: any) => {
                      // console.log("companies - ", comp_resp);
                      if (comp_resp.data.length === 0) return;
                      this.company_info = comp_resp.data[0];
                      this.getStaffDataById(this.company_info?.owner, 'company');
                      this.getStaffByAssignee(this.company_info.assignee, 'company');
                      if (this.company_info.tags) this.getTagData(this.company_info.tags, 'company');

                    }
                  });
                }
              }
            });
          }
        }
      });
    } else if (this.selected_type === 'company') {
      this.getCompanyTags();
      this.service.getCompanyById(id).subscribe({
        next: (resp: any) => {
          if (resp.data.length === 0) return;
          this.intro_obj = resp.data[0];
          this.onUpdateHeaderTitle();
          // console.log("companies - ", resp);
          this._customFields.getCompanyModelFields().subscribe((companyFieldResp: any) => {
            const fields_element: any = {};
            fields_element['custom_company_fields'] = resp.data[0].custom_company_fields;
            Object.entries(companyFieldResp.data).map((field: any) => {
              if (field[0] !== "company_fields_order" && field[0] !== "custom_company_templates" && field[0] !== "id" && field[0] !== "organisation") {
                if (field[1].on_form || field[1].is_inner_screen) fields_element[field[0]] = resp.data[0][field[0]];
              } else if (field[0] === "custom_company_templates") {
                field[1].forEach((element: any) => {
                  if (element.on_form || element.is_inner_screen) {
                    const customFieldIndex = resp.data[0].custom_company_fields.findIndex((item: any) => item.custom_company_template === element.id);
                    const custom_field_includes = customFieldIndex !== -1;
                    if (!custom_field_includes) { //this field is new its not created
                      const body = {
                        "id": "",
                        "label": element.label,
                        "data_type": element.data_type,
                        "options": element.options,
                        "on_form": element.on_form,
                        "is_inner_screen": element.is_inner_screen,
                        "data": "",
                        "is_mandatory": element.is_mandatory,
                        "is_active": element.is_active,
                        "custom_company_templates": element.id,
                        "company": id,
                        "start": "",
                        "end": ""
                      };
                      fields_element['custom_company_fields'].push(body);
                    } else { // exist company fields adding options to the element
                      fields_element['custom_company_fields'][customFieldIndex].options = element.options;
                    }
                  }
                });
              } else if (field[0] === "id") {
                fields_element['id'] = resp.data[0].id;
              }
            });
            fields_element.created_at = resp.data[0].created_at;
            fields_element.updated_at = resp.data[0].updated_at;
            fields_element.updated_by_name = resp.data[0].updated_by_name;
            fields_element.created_by_name = resp.data[0].created_by_name;
            fields_element.company_id = resp.data[0].company_id;
            this.company_info = fields_element;// console.log("leads -", resp);
            this.custom_field = resp.data[0].custom_company_fields;
            this.getStaffDataById(resp.data[0].owner, 'company');
            this.getStaffByAssignee(resp.data[0]?.assignee, 'company');
            if (resp.data[0]?.tags) this.getTagData(resp.data[0].tags, 'company');
          });
        }
      });
    } else if (this.selected_type === 'deal') {
      this.getFieldsBasedonCardId();
    }
  }

  getPipelineDetailByid() {
    this.service.getPiplineById(this.cardDetails.pipeline).subscribe({
      next: (resp: any) => {
        this.pipelineDetails = resp.data[0];
        this.commonService.pipelineNameSubs$.next(this.pipelineDetails?.card_name);
      }
    });
  }


  onUpdateHeaderTitle() {
    const headerText = document.querySelector('.header-name-sec-dynamic');
    if (headerText) {
      switch (this.selected_type) {
        case 'contact':
          headerText.innerHTML = this.intro_obj?.first_name + this.intro_obj.last_name;
          break;
        case 'lead':
          headerText.innerHTML = this.intro_obj?.requirement_title;
          break;
        case 'company':
          headerText.innerHTML = this.intro_obj.name;
          break;
        case 'deal':
          headerText.innerHTML = this.cardDetails?.title;
          break;
        default:
          break;
      }

    }

  }

  getStaffDataById(id: string, type: string) {
    if (!id) return;
    this.settingsService.getStaff_infoById(id).subscribe({
      next: (resp: any) => {
        if (type === 'lead') {
          this.enquiry_owner = resp.data;
        } else if (type === 'contact') {
          this.contact_owner = resp.data;
        } else if (type === 'company') {
          this.company_owner = resp.data;
        }
        console.log(this.company_owner, "owner");

      }
    });
  }

  getStaffByAssignee(body: any, type: string) {
    if (body?.length === 0) return;
    if (body && body.length > 0) {
      this.service.getStaffForSource({ id: body }).subscribe((resp: any) => {
        if (type === 'lead') {
          this.leadAssignee = resp.data;
        } else if (type === 'contact') {
          this.contactAssignee = resp.data;
        } else if (type === 'company') {
          this.companyAssignee = resp.data;
        } else if (type === 'deal') {
          this.dealAssignee = resp.data;
        }
      });
    }
  }

  getTagData(id: string, type: string) {
    this.settingsService.getTagById(id).subscribe({
      next: (resp: any) => {
        if (type === 'lead') {
          this.intro_obj.tag_name = resp.data[0].name;
          this.intro_obj.colour = resp.data[0].colour;
        } else if (type === 'contact') {
          this.contact_info.tag_name = resp.data[0].name;
          this.contact_info.colour = resp.data[0].colour;
        } else if (type === 'company') {
          this.company_info.tag_name = resp.data[0].name;
          this.company_info.colour = resp.data[0].colour;
        }
      }
    });
  }

  getContactTypes(){
    this.service.getContactTypes().subscribe((resp: any)=>{
      this.contactTypeItems = resp.data;
    });
  }

  usersAccess(contactDetails: any) {
    if (contactDetails) {
      if (contactDetails.assignee.includes(this.loggedInUser.data.id) || contactDetails?.owner_info?.id === this.loggedInUser?.data?.id || this.loggedInUser.data.role === 'ADMIN') {
        this.validateUserAccess = true;
      } else {
        this.validateUserAccess = false;
      }
      // let assigneeNameIndex = -1;
      // if (contactDetails.assignee.length > 0) {
      //   assigneeNameIndex = contactDetails?.assignee?.findIndex((element: any) => this.loggedInUser.data.id === element);
      // }
      // if (assigneeNameIndex > -1 || contactDetails?.owner_info?.id === this.loggedInUser?.data?.id || this.loggedInUser?.data?.role === 'ADMIN') {
      //   this.validateUserAccess = true;
      // } else {
      //   // const departmentsList = [...contactDetails.assignee_department, contactDetails.owner_department];
      //   // this.settingsService.getStaff_infoById(this.loggedInUser.data.id).subscribe({
      //   //   next: (resp: any) => {
      //   //     this.validateUserAccess = (resp.data?.department && departmentsList.includes(resp.data?.department) && resp.data?.role_info?.name === "MANAGER") ? true : false;
      //   //   }
      //   // });
      //   this.validateUserAccess = false;
      // }
    } else if (this.loggedInUser.data.role === 'ADMIN') {
      this.validateUserAccess = true;
    } else {
      this.validateUserAccess = false;
    }
  }

  getProductById(product_ids: any) {
    this.setupService.getStore().subscribe({
      next: (resp: any) => {
        const produtNamesArray: any = [];
        product_ids.forEach((item: any) => {
          this.setupService.getProductById(resp.data.id, item).subscribe({
            next: (products_resp: any) => {
              console.log(products_resp);
              produtNamesArray.push(products_resp.data);
              this.intro_obj.product_details = produtNamesArray;
              this.product_details = produtNamesArray;
              // this.products_list = products_resp.data;
            }
          });
        });
      }
    });
  }

  conditionalInputs(field: string, status: boolean) {
    if (field === 'input_field_fname') {
      this.input_field_fname = status;
    } else if (field === 'input_field_lname') {
      this.input_field_lname = status;
    } else if (field === 'input_field_email') {
      this.input_field_email = status;
    } else if (field === 'input_field_phno') {
      this.input_field_phno = status;
    } else if (field === 'input_field_cmpny_name') {
      this.input_field_cmpny_name = status;
    } else if (field === 'input_field_cmpny_email') {
      this.input_field_cmpny_email = status;
    } else if (field === 'input_field_cmpny_phno') {
      this.input_field_cmpny_phno = status;
    } else if (field === 'input_field_cmpny_website') {
      this.input_field_cmpny_website = status;
    } else if (field === 'input_field_cmpny_address') {
      this.input_field_cmpny_address = status;
    } else if (field === 'input_field_cmpny_city') {
      this.input_field_cmpny_city = status;
    } else if (field === 'input_field_cmpny_zipcode') {
      this.input_field_cmpny_zipcode = status;
    } else if (field === 'input_field_cmpny_description') {
      this.input_field_cmpny_description = status;
    }
  }

  getContactsForDeals(id: any) {
    this.service;
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.info_array, event.previousIndex, event.currentIndex);
  // }
  /***************************Pipeline START****************************** */
  getFieldsBasedonCardId() {
    this.service.getFieldsbyCardId(this.selected_id).subscribe((resp: any) => {
      this.cardFields = resp.data;
      this.getContactDetails();

    });
  }
  onNavigteToContact(sourceId: string, sourceType: string) {
    const queryParams: any = {
      id: sourceId,
      showtype: sourceType
    };
    if (this.validateUserAccess) {
      this.router.navigate(['/crm/crm-details'], { queryParams: queryParams });
    }
    // [queryParams] = "{ id: contact_info?.id, showtype: 'contact'}"
  }
  onNavigteToCompany(sourceId: string, sourceType: string) {
    const queryParams: any = {
      id: sourceId,
      showtype: sourceType
    };
    if (this.validateUserAccess) {
      this.router.navigate(['/crm/crm-details'], { queryParams: queryParams });
    }
    // [queryParams] = "{ id: contact_info?.id, showtype: 'contact'}"
  }

  getContactDetails(): void {
    this.service.getCardsById(this.selected_id).subscribe(async (cards: any) => {
      this.cardDetails = cards?.data[0];
      this.getPipelineDetailByid();
      this.onUpdateHeaderTitle();
      this.getStaffByAssignee(this.cardDetails.assignee, 'deal');
      const systemFields = Object.entries(cards.data[0]);
      const promises = systemFields.map(async (field: any) => {
        if (field[1]) {
          if (field[0] === 'scheduler_category') {
            const results: any = await this.setupService.getAllCategories().toPromise();
            const selectedItem = results.data.filter((item: any) => item.id === field[1]);
            if (selectedItem.length !== 0) field[1] = selectedItem[0].name;
          } else if (field[0] === "scheduler") {
            const results: any = await this.setupService.getConsultationByID(field[1]).toPromise();
            field[1] = results.data.name;
          } else if (field[0] === "product" || field[0] === "product_category") {
            const store_resp: any = await this.setupService.getStore().toPromise();
            if (field[0] === "product") {
              const results: any = await this.setupService.getProductById(store_resp.data.id, field[1]).toPromise();
              field[1] = results.data.name;
            } else {
              const results: any = await this.setupService.getProductCategories(store_resp.data.id, field[0]).toPromise();
              const selectedItem = results.data.filter((item: any) => item.id === field[1]);
              if (selectedItem.length !== 0) field[1] = selectedItem[0].name;
            }
          } else if (field[0] === "company") {
            const results: any = await this.service.getCompanyById(field[1]).toPromise();
            field[1] = results.data[0]?.name;
          }
        }
      });
      await Promise.all(promises);
      this.cardDetails = Object.fromEntries(systemFields);

      this.getStages();

      this.service.getContactById(this.cardDetails?.contact).subscribe((con_resp: any) => {
        this._customFields.getContactModelFields().subscribe({
          next: (fieldsresp: any) => {
            this.service.registeredEmailId.next(con_resp.data[0]);
            const fields_element: any = {};
            fields_element['custom_contact_fields'] = con_resp.data[0].custom_contact_fields;
            Object.entries(fieldsresp.data).map((field: any) => {
              if (field[0] !== "contact_fields_order" && field[0] !== "custom_contact_templates" && field[0] !== "id" && field[0] !== "organisation") {
                if (field[1].on_form && field[1].is_inner_screen) fields_element[field[0]] = con_resp.data[0][field[0]];
              } else if (field[0] === "custom_contact_templates") {
                fields_element['custom_contact_fields'] = con_resp.data[0].custom_contact_fields;
                this.custom_contact_fields = con_resp.data[0].custom_contact_fields;
              } else if (field[0] === "id") {
                fields_element['id'] = con_resp.data[0].id;
              }
            });
            fields_element.contact_id = con_resp.data[0].display_id;
            fields_element.created_at = con_resp.data[0].created_at;
            fields_element.updated_at = con_resp.data[0].updated_at;
            fields_element.updated_by_name = con_resp.data[0].updated_by_name;
            fields_element.created_by_name = con_resp.data[0].created_by_name;
            fields_element.is_active = con_resp.data[0].is_active;
            this.contact_info = fields_element;
            this.getStaffByAssignee(con_resp.data[0].assignee, 'contact');
            this.getStaffDataById(this.contact_info.owner, 'contact');
            if (this.contact_info.tags) this.getTagData(this.contact_info.tags, 'contact');
          }
        });
      });
    });
  }

  getStages() {
    this.service.getStageByPipelineId(this.cardDetails.pipeline).subscribe((stage: any) => {
      this.stagesList = stage.data;
      this.stagesList.forEach((element: any, i) => {
        if (this.cardDetails.stage === element.id) {
          this.cardStageIndex = i;
        }
      });
    });
  }

  stageUpdate(stageId: string, index: number) {
    this.service.updateCard({ id: this.selected_id, stage: stageId }).subscribe(() => {
      this.cardStageIndex = index;
    });
  }

  seeMoreFields() {
    this.pipeline_addFields_seemore = !this.pipeline_addFields_seemore;
    if (this.cardFields.length === this.sliceItem) {
      this.sliceItem = 5;
    } else {
      this.sliceItem = this.cardFields.length;
    }
  }

  seeMoreAdditionalFields(type: any) {
    this.addmore = !this.addmore;
    if (type === 'contact') {
      if (this.custom_contact_fields.length === this.slice_additional_Item) {
        this.slice_additional_Item = 5;
      } else {
        this.slice_additional_Item = this.custom_contact_fields.length;
      }
    } else {
      if (this.custom_field.length === this.slice_additional_Item) {
        this.slice_additional_Item = 5;
      } else {
        this.slice_additional_Item = this.custom_field.length;
      }
    }
  }

  /**********************************Fields API Starts*************************************/
  getAPTCategoriesList() {
    this.setupService.getAllCategories().subscribe({
      next: (resp: any) => {
        this.cardFields.filter(ele => {
          if (ele.url === '/consultations/category/') {
            const selectedItem = resp.data.filter((item: any) => item.id === ele.data);
            if (selectedItem.length !== 0) ele.data_name = selectedItem[0].name;
          }
        });
      }
    });
  }

  getAppointments(ele: any) {
    return this.setupService.getConsultationByID(ele[1]);
  }

  getContacts(ele: any) {
    this.service.getContactById(ele).subscribe((resp: any) => {
      ele.data_name = resp.data.name;
      this.service.registeredEmailId.next(resp.data[0]);
      // this.cardFields.filter(ele => {
      //   if (ele.url === 'crm_service/Contacts') {
      //     const selectedItem = resp.data.filter((item: any) => item.id === ele.data);
      //     if (selectedItem.length !== 0) ele.data_name = selectedItem[0].first_name;
      //   }
      // });
    });
  }

  getCompanyById(ele: any) {
    this.service.getCompanyById(ele).subscribe((resp: any) => {
      ele.data_name = resp.data[0]?.name;
    });
  }
  /**********************************Fields API Ends**************************************/

  /***************************Pipeline END****************************** */

  goBack() {
    this._location.back();
  }

  getCompaniesList() {
    this.service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.companiesListArray = resp.data;
      }
    });
  }

  updateFieldChanges(body: any, type: string, field?: string) {
    // console.log("update fields - ", body);
    if (type === 'contact') {
      body.custom_contact_fields = [];
      this.service.updateContact(body).subscribe(() => {
        // this.conditionalInputs(field, false);
        this.getRelatedIdInfo(this.selected_id);
      });
    } else if (type === 'company') {
      this.service.updateCompany(body).subscribe(() => {
        // this.conditionalInputs(field, false);
        this.getRelatedIdInfo(this.selected_id);
      });
    } else if (type === 'lead') {
      body.custom_lead_fields = [];
      this.service.updateLead(body).subscribe(() => {
        this.getRelatedIdInfo(this.selected_id);
      });
    }
  }

  updateCustomFields(data: any, type: string) {
    if (type === 'contact') {
      this.service.updateContact(data).subscribe(() => {
        this.getRelatedIdInfo(this.selected_id);
      });
    } else if (type === 'company') {
      this.service.updateCompany(data).subscribe(() => {
        this.getRelatedIdInfo(this.selected_id);
      });
    } else if (type === 'lead') {
      this.service.updateLead(data).subscribe(() => {
        this.getRelatedIdInfo(this.selected_id);
      });
    }
  }

  onOpenLink(link: any) {
    console.log(link);
    const modalRef = this.modalService.open(LinkOpenWarningModalComponent, { size: 'md', centered: true });
    modalRef.componentInstance.linkUrl = link.value;
    modalRef?.result?.then((resp: any) => {
      resp && window.open(link.value, '_blank');
    }).catch(() => {
      console.log('');
    });
  }

  onOpenDetailBox(event: any, data: any) {
    setTimeout(() => {
      this.isVisibleDetailsBox = false;
      console.log(event);
      this.detailsBox.detailsBoxTitle = data?.title ? data.title : '';
      this.detailsBox.detailBoxBody = data?.value ? data.value : '';
      this.detailsBox.isTitleCase = data?.isTitleCase ? data.isTitleCase : false;
      const left = event.srcElement?.offsetLeft + event.srcElement?.clientWidth + 40;
      const top = event.clientY - 60;
      const detailsBox = document.querySelector('.details-box');
      this.renderer.setStyle(detailsBox, 'display', 'block');
      setTimeout(() => {
        if (window.innerHeight < (event.clientY + detailsBox?.clientHeight)) {
          detailsBox && this.renderer.setStyle(detailsBox, 'top', top - detailsBox?.clientHeight + 30 + 'px');
        } else {
          detailsBox && this.renderer.setStyle(detailsBox, 'top', top + 'px');
        }
        detailsBox && this.renderer.setStyle(detailsBox, 'left', left + 'px');
      }, 10);

      setTimeout(() => {
        this.renderer.setStyle(detailsBox, 'opacity', '1');
      }, 200);
    }, 0);
  }

  onScroll() {
    this.isVisibleDetailsBox = false;
    this.onCloseDetailsBox();
  }

  onCloseDetailsBox() {
    if (!this.isVisibleDetailsBox) {
      const detailsBox = document.querySelector('.details-box');
      this.renderer.setStyle(detailsBox, 'opacity', '0');
      setTimeout(() => {
        this.renderer.setStyle(detailsBox, 'display', 'none');
        this.isVisibleDetailsBox = true;
      }, 100);
    }
  }

  onUpdatePopOver(event: any, data: any, type: string, fieldType?: string, customData?: any) {
    if (!data?.is_active && type === "contact") { // Contact is Inactive and tried to update blocking the user
      this.toaster.error("User is not active, You can't update the details");
      return;
    } else if (data?.is_contact_active === false) {
      this.toaster.error("User is not active, You can't update the details");
      return;
    }
    if (type === "lead" && data?.type === 'PIPELINE') this.toaster.error("No more available");//enquiry is moved to pipeline
    const input: any = { id: data.id };
    if (event.type === 'dropdown') {
      if (event.labelName === 'Owner') {
        input.info = {
          "owner_info": {
            "id": event.value.id,
            "fname": event.value.fname,
            "lname": event.value.lname
          }
        };
        input.owner = event.value.id;
        input.owner_department = event.value.department_info?.id ? event.value.department_info?.id : '';
      } else if (event.labelName === 'Company Name') {
        input.company = event.value.id;
        input.company_id = event.value.id;
        input.company_name = event.value.name;
      } else if (event.labelName === "Country") {
        input.country = event.value.Entity;
      } else if (event.labelName === 'Tag' || event.labelName === 'contact_type') {
        input[event.formName] = event.value.id;
      }
    } else
      input[event.formName] = event.value;
    //check if cCode key has some value
    if (event?.cCode) {
      input['ccode'] = event.cCode;
    }
    if (fieldType === 'custom_field') {
      // data.custom_contact_fields.forEach((element: any) => {
      //   element.
      // });
      let custom_fields = {};
      if (!customData.id) {
        customData.data = event.value;
        custom_fields = {
          id: input.id,
          [`custom_${type}_fields`]: [customData]
        };
      } else {
        custom_fields = {
          id: input.id,
          [`custom_${type}_fields`]: [{
            id: event.formName,
            data: event.value,
            data_type: event.type
          }]
        };
      }
      this.updateCustomFields(custom_fields, type);
    } else {
      this.updateFieldChanges(input, type);
    }
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  getTags() {
    this.settingsService.getTags(this.selected_type === 'deal' ? 'pipeline' : this.selected_type).subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }

  getContactTags() {
    this.settingsService.getTags('contact').subscribe((resp: any) => {
      this.contactstagsListArray = resp.data;
    });
  }
  getCompanyTags() {
    this.settingsService.getTags('company').subscribe((resp: any) => {
      this.companytagsListArray = resp.data;
    });
  }

  openContactsModalDialog(contact?: any) {
    const modalRef = this.modalService.open(ContactsModalComponent, { size: 'lg' });
    modalRef.componentInstance.contact = contact;
  }

  openEnquiryModalDialog() {
    const modalRef = this.modalService.open(LeadModalComponent, { size: 'lg' });
    modalRef.componentInstance.lead = this.info_data;
  }

  openPipelineModalDialog(ev: any) {
    ev.stopPropagation();
    const modalRef = this.modalService.open(PipelineModalComponent, { size: 'lg' });
    modalRef.componentInstance.pipelineData = { pipelineId: this.cardDetails.pipeline, selectedstageId: this.cardDetails.stage, cardId: this.selected_id, cardName: this.cardDetails.title };
  }

  openCompanyModalDialog(company?: any) {
    const modalRef = this.modalService.open(CompanyModalComponent, { size: 'lg' });
    modalRef.componentInstance.company = company;
  }

  isObject(value: any): boolean {
    return (typeof value === 'object' && value?.length !== 0);
  }

  getChatBotVaribles(contactId: string) {
    this.service.getChatBotVariables(contactId).subscribe((resp: any) => {
      console.log(resp, "resp");
      let bot_variable, filteredData: any[];
      // eslint-disable-next-line prefer-const
      bot_variable = Object.keys(resp.data).map((ele: any) => {
        if (resp.data[ele] && !this.uuidRegExp.test(resp.data[ele])) return ele;
      });
      // eslint-disable-next-line prefer-const
      filteredData = bot_variable.reduce((acc, key) => {
        if (key) {
          acc[key] = resp.data[key];
        }
        return acc;
      }, {});
      this.chatbot_Variable = filteredData;
    });
  }

  onErrorLoadImage(event: any, target: any, file: any) {
    event.target.src = ErrorLoadImage(target, file);
  }

  FileUpload(ev: any, type: string) {
    this.awsUtilService.getUploadFileLink(ev.target.files[0].type, ev.target.files[0].name.split('.')[1]).subscribe((resp) => {
      this.awsUtilService.uploadFile(ev.target.files[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
        let body;
        if (type === 'contact') {
          body = {
            display_picture: this.presignedurl + resp.data.file_name,
            id: this.contact_info.id
          };
        } else {
          body = {
            logo: this.presignedurl + resp.data.file_name,
            id: this.company_info.id
          };
        }
        this.updateFieldChanges(body, type);
      });
    });
  }

  deletePic(type: string) {
    let body;
    if (type === 'contact') {
      body = {
        display_picture: '',
        id: this.contact_info.id
      };
    } else {
      body = {
        logo: '',
        id: this.company_info.id
      };
    }
    this.updateFieldChanges(body, type);
  }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { ThemePalette } from '@angular/material/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { CrmService } from 'src/app/core/service/crm.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  public selection = new SelectionModel<any>(true, []);
  @ViewChild('paginator') paginator!: MatPaginator;
  modalRef!: NgbModalRef;
  addTagForm!: FormGroup;
  submitted = false;
  column_names = ['Tag Name', 'Source', 'Color', 'Actions'];
  tagColorsArray = ['#ff0000', '#0000ff', '#008000', '#ffff00', '#ffc0cb', '#ffa500', '#808080', '#ff00ff', '#00ff00', '#008080', '#f0ffff', '#5f9ea0', '#d2691e', '#daa520', '#e6e6fa', '#66cdaa', '#808000'];
  tagsListArray: any[] = [];
  totalTags: any;
  currentPage = 1;
  page_size = 20;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  listOfModuleNames = [
    {
      label: 'Contact',
      value: 'contact',
      launchDlKeys: 'contact'
    },
    {
      label: 'Company',
      value: 'company',
      launchDlKeys: 'company'
    },
    {
      label: 'Flow',
      value: 'pipeline',
      launchDlKeys: 'pipeline'
    },
    {
      label: 'Lead',
      value: 'lead',
      launchDlKeys: 'enquiry'
    },
    {
      label: 'Client',
      value: 'client',
      launchDlKeys: 'client'
    },
    {
      label: 'Projects',
      value: 'projects',
      launchDlKeys: 'projects'
    },
    {
      label: 'Task',
      value: 'task',
      launchDlKeys: 'create_task'
    }
  ];
  featuresData: any;
  activeLink = this.listOfModuleNames[0].value;
  background: ThemePalette = undefined;
  tableSkeletonLoader = true;
  pipeline_list: any;
  selected_pipeline: any;
  selected_pipeline_label: any;
  constructor(private modalService: NgbModal, private settingsService: SettingsService,
    private confirmationMsz: ConfirmationMszService, private authService: AuthService, private crmService: CrmService) { }

  ngOnInit() {
    this.initilizeTagForm();
    this.getTags();
    this.getFeatues();
  }

  initilizeTagForm(data?: any) {
    this.addTagForm = new FormGroup({
      name: new FormControl(data ? data.name : '', [Validators.required]),//Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      colour: new FormControl(data ? data.colour : '#000000'),
      id: new FormControl(data ? data.id : ''),
      flow_source: new FormControl(data ? data.flow_source : this.selected_pipeline_label),
      source: new FormControl(data ? data.source : this.activeLink, Validators.required)
    });
  }

  get f() {
    return this.addTagForm.controls;
  }

  getTags() {
    this.tableSkeletonLoader = true;
    if (this.activeLink === 'client' || this.activeLink === 'projects' || this.activeLink === 'task') {
      this.settingsService.getClientTagswithpagination(this.currentPage, this.activeLink).subscribe((resp: any) => {
        this.tableSkeletonLoader = false;
        this.tagsListArray = resp.data;
        this.totalTags = resp?.total;
      });
    } else {
      this.settingsService.getTagswithpagination(this.currentPage, this.activeLink, this.selected_pipeline_label).subscribe((resp: any) => {
        this.tableSkeletonLoader = false;
        this.tagsListArray = resp.data;
        this.totalTags = resp?.count;
      });
    }
  }

  getPipelines() {
    this.tableSkeletonLoader = true;
    this.crmService.getPipelines().subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.pipeline_list = resp.data;
      if (!this.selected_pipeline) {
        this.selected_pipeline = resp.data[0].id;
        this.selected_pipeline_label = resp.data[0].name;
      }
      this.getTags();
    });
  }

  onPipelineChange(ev: any) {
    let pipelineselet: any = '';
    this.selected_pipeline = ev.value;
    pipelineselet = this.pipeline_list.filter((item: any) => item.id === this.selected_pipeline);
    this.selected_pipeline_label = pipelineselet[0].name;
    this.getTags();
  }

  onTabChange(item: string) {
    this.paginator.pageIndex = 0;
    this.currentPage = 1;
    this.activeLink = item;
    if (item === 'pipeline') {
      this.getPipelines();
    } else {
      this.getTags();
    }
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.addTagForm.value);
    if (this.addTagForm.valid) {
      if (this.addTagForm.value.id) {
        if (this.addTagForm.value.source === 'client' || this.addTagForm.value.source === 'projects' || this.addTagForm.value.source === 'task') {
          this.settingsService.putClientTags(this.addTagForm.value).subscribe(() => {
            this.getTags();
            this.modalRef.dismiss();
          });
        } else {
          this.settingsService.putTags(this.addTagForm.value).subscribe(() => {
            this.getTags();
            this.modalRef.dismiss();
          });
        }
      } else {
        if (this.addTagForm.value.source === 'client' || this.addTagForm.value.source === 'projects' || this.addTagForm.value.source === 'task') {
          this.settingsService.postClientTags(this.addTagForm.value).subscribe(() => {
            this.getTags();
            this.modalRef.dismiss();
          });
        } else {
          this.settingsService.postTags(this.addTagForm.value).subscribe(() => {
            this.getTags();
            this.modalRef.dismiss();
          });
        }

      }
    }
  }

  selectedTagColor(color: any) {
    this.addTagForm.patchValue({ colour: color });
  }

  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.currentPage = e.pageIndex + 1;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getTags();
  }

  deleteSelectedTag(id: any, tag_type: string) {
    this.confirmationMsz.customDeleteView({ title: 'Are you sure?', text: 'You want to delete!', button: 'Yes Delete!' })
      .then((data) => {
        if (data.value) {
          if (tag_type === 'client' || tag_type === 'projects' || tag_type === 'task') {
            this.settingsService.deletetClientTags(id).subscribe((resp: any) => {
              this.getTags();
            });
          } else {
            this.settingsService.deletetTags(id).subscribe((resp: any) => {
              this.getTags();
            });
          }
        }
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tagsListArray.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.tagsListArray.forEach((row) => {
      this.selection.select(row);
    });
  }

  openAddTagModal(content: any, data?: any) {
    this.submitted = false;
    this.modalRef = this.modalService.open(content, { size: 'md', centered: true });
    this.initilizeTagForm(data);
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      this.listOfModuleNames = this.listOfModuleNames.filter((elem: any) => {
        if (elem.value === 'projects') {
          return this.featuresData['client'] && this.featuresData[elem.launchDlKeys];
        } else {
          return this.featuresData[elem.launchDlKeys];
        }
      });
      this.activeLink = this.listOfModuleNames[0].value;
      console.log(this.listOfModuleNames, "module name");

    });
  }

}

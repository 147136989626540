import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { APPOINTMENTJSON } from '../../../core/data/appointment_modal_json';
import { ERRORJSON } from '../../../core/data/error_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { HomeService } from 'src/app/core/service/home.service';
import { maximumNumberAllowed, onOnlyNumbers } from 'src/app/core/common/common-functions';
import { ReplaySubject, Subject, debounceTime, finalize, takeUntil } from 'rxjs';
import { countryData } from 'src/app/core/data/countryData';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-appointment-modal',
  templateUrl: './appointment-modal.component.html',
  styleUrls: ['./appointment-modal.component.scss']
})
export class AppointmentModalComponent implements OnInit {
  appointmentJson = APPOINTMENTJSON[0];
  errorJson = ERRORJSON[0];
  aptForm!: FormGroup;
  submitted = false;
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  contactListArray: any;
  staff_info: any;
  categoriesArray: any;
  appointments_list: any;
  selectedconsultation_data: any[] = [];
  locationsDetails: any;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  selectedAppointment: any;
  slotsData: any[] = [];
  selectedCustomer: any;
  selectedLocation = '';
  @Input() appointmentId!: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  userDetails = JSON.parse(sessionStorage.getItem('currentUser')!);
  noslots = false;
  currentTime = '';
  currentUserTimeZone = 'Asia/Calcutta';
  total_price = 0;
  reschedule_staff_id: any;
  transaction_id: any;
  @Input() contact: any; //From Chat 
  @Input() chatId!: string;
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  countryjson = countryData;
  protected _onDestroy = new Subject<void>();
  selectedStaff: any;
  loading = false;

  constructor(private ngbActiveModal: NgbActiveModal, private crm_service: CrmService, private homeService: HomeService,
    private settingService: SettingsService, private setupService: SetUpService, private authService: AuthService, private renderer: Renderer2, private toastAlert: ToastrService) { }

  ngOnInit() {
    this.intilizeForm();
    this.getContactsList();
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.aptForm.get('search')!.valueChanges.pipe(debounceTime(300)).subscribe((change: any) => {
      if (change?.length >= 3) {
        this.crm_service.getContactsBySearch(change).subscribe((result: any) => {
          this.contactListArray = result.data;
        });
      } else if (change?.length === 0) {
        // this.getContactsList();
      }
    });
    this.getCategoriesList();
    if (this.appointmentId) {
      this.homeService.getAppointmentById(this.appointmentId).subscribe((resp: any) => {
        this.intilizeForm(resp.data);
      });
    }
    // this.currentUserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.currentUserTimeZone = this.authService.getCompanyData()?.timezone;
    this.getSettings();
  }

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     this.myFirstInput.nativeElement.focus();
  //   }, 0);
  // }

  clearContactFields(e: any) {
    this.aptForm.patchValue({ email: '', contact_id: '', phone_number: '', name: '' });
  }

  onSelectContact(contact: any) {
    this.aptForm.controls["phone_number"].enable();
    this.aptForm.controls["ccode"].enable();
    this.aptForm.controls["email"].enable();
    if (contact?.id) {
      this.selectedCustomer = contact;
      this.aptForm.patchValue({ email: contact.email, phone_number: contact.phone_number, ccode: contact.ccode, contact_id: contact.id });
      this.aptForm.controls["phone_number"].disable();
      this.aptForm.controls["ccode"].disable();
      this.aptForm.controls["email"].disable();
    } else if (this.aptForm.value.id || this.contact) {
      this.selectedCustomer = contact;
      this.aptForm.patchValue({ email: contact.email, phone_number: contact.phone_number, ccode: contact.ccode, contact_id: contact.id });
      this.aptForm.controls["phone_number"].disable();
      this.aptForm.controls["ccode"].disable();
      this.aptForm.controls["email"].disable();
    } else {
      this.aptForm.patchValue({ contact_id: contact.id, name: contact.first_name });
    }
  }

  searchContact(term: any) {
    if (term.term.length >= 3) {
      this.crm_service.getContactsBySearch(term.term).subscribe((result: any) => {
        this.contactListArray = result.data;
      });
    } else if (term.term.length === 0) {
      this.getContactsList();
    }
  }
  intilizeForm(data?: any): void {
    this.aptForm = new FormGroup({
      id: new FormControl(data ? data.id : ''),
      contact_id: new FormControl(data ? data.contact_id : '', [Validators.required]),
      contact_name: new FormControl(data ? data.contact_name : '', [Validators.required]),
      email: new FormControl(''),
      consultation_id: new FormControl(data ? data.consultation_id.id : '', [Validators.required]),
      phone_number: new FormControl(''),
      ccode: new FormControl(this.authService.getCompanyData().ccode),
      category: new FormControl(data ? data.consultation_id.category_id : '', [Validators.required]),
      staff_id: new FormControl(data ? data.staff_id : ''),
      department: new FormControl(data ? data.department : ''),
      apt_details: new FormControl(data ? data.apt_details : '', [Validators.required]),
      date: new FormControl('', [Validators.required]),
      slot: new FormControl('', [Validators.required]),
      online_meeting_medium: new FormControl(data ? data.online_meeting_medium : ''),
      custom_meeting_url: new FormControl(data ? data.custom_meeting_url : ''),
      meeting_type: new FormControl(data ? data.meeting_type : '', [Validators.required]),
      medium_type: new FormControl(data ? data.medium_type : ''),
      medium_url: new FormControl(data ? data.medium_type : ''),
      org_address_id: new FormControl(data ? data.org_address_id : ''),
      payment_mode: new FormControl('COD'),
      is_paid: new FormControl(true),
      terms_conditions: new FormControl(true),
      search: new FormControl(''),
      source_type: new FormControl('ADMIN_PANEL'),
      ref_id: new FormControl('')
    });
    if (data) {
      this.onCategoryChange(data.consultation_id.category_id);
      this.onAptTypeChange(data.meeting_type);
      this.aptForm.controls['phone_number'].disable();
      this.aptForm.controls['ccode'].disable();
      this.aptForm.controls['email'].disable();
      this.aptForm.patchValue({ apt_details: data.meeting_type === "OFF_LINE" ? data.org_address_id : data.meeting_type === "ON_LINE" ? data.online_meeting_medium : data.medium_type });
      // this.aptForm.controls['contact_id'].disable();
      this.aptForm.controls['contact_name'].disable();
      this.aptForm.controls['contact_name'].clearValidators();
      this.aptForm.controls['contact_name'].updateValueAndValidity();
    }
    if (this.contact) this.aptForm.controls["contact_id"].setValue(this.contact);
    if(this.userDetails.customer_primary_login_type === 'email'){
      this.aptForm.controls['email'].setValidators(Validators.required);
      this.aptForm.controls['email'].updateValueAndValidity();
    } else {
      this.aptForm.controls['phone_number'].setValidators(Validators.required);
      this.aptForm.controls['phone_number'].updateValueAndValidity();
    }
  }

  get f() {
    return this.aptForm.controls;
  }

  hasRequiredValidator(controlName: string): boolean {
    const control = this.aptForm.get(controlName);
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      return !!(validator && validator['required']);
    }
    return false;
  }

  keyDownEvent(event: any, maxLength: number) {
    const maxNum = maximumNumberAllowed(event, maxLength);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }


  getContactById(contact_id: string) {
    this.crm_service.getContactById(contact_id).subscribe({
      next: (resp: any) => {
        const exisit = this.contactListArray.some((contact: any) => contact.id === resp.data[0].id);
        if (!exisit) {
          this.contactListArray.push(resp.data[0]);
          this.aptForm.patchValue({ contact_name: resp.data[0].first_name, email: resp.data[0].email, phone_number: resp.data[0].phone_number, ccode: resp.data[0].ccode });
          this.aptForm.controls['phone_number'].disable();
          this.aptForm.controls['ccode'].disable();
          this.aptForm.controls['email'].disable();
          this.aptForm.controls['contact_name'].disable();
        }
      }
    });
  }

  getContactsList() {
    this.crm_service.getContactsDropdownList().subscribe({
      next: (resp: any) => {
        this.contactListArray = resp.data;
        const exist = this.contactListArray.filter((item: any) => (item.id.includes(this.contact) || item.id === this.aptForm.value?.id));

        if (exist.length !== 0) {
          this.aptForm.controls['phone_number'].disable();
          this.aptForm.controls['ccode'].disable();
          this.aptForm.controls['email'].disable();
          this.aptForm.controls['contact_name'].disable();
          this.aptForm.patchValue({ contact_name: exist[0].first_name, email: exist[0].email, phone_number: exist[0].phone_number, name: exist[0].first_name });
          this.selectedCustomer = exist[0];
        } else if (this.aptForm.value.id || this.contact) {
          this.getContactById(this.aptForm.value.contact_id || this.contact);
        }
      }
    });
  }

  onContactChange(event: any, data: any) {//Not using anymore this method is used for mat-select fields
    console.log(event, "event", data, this.aptForm.value.contact_id);
    if (event.isUserInput) {
      this.selectedCustomer = data;
      this.aptForm.patchValue({ email: data.email, phone_number: data.phone_number, ccode: data.ccode });
      this.aptForm.controls['phone_number'].disable();
      this.aptForm.controls['ccode'].disable();
      this.aptForm.controls['email'].disable();
    } else if (this.aptForm.value.id || this.contact) {
      this.selectedCustomer = data;
      this.aptForm.patchValue({ email: data.email, phone_number: data.phone_number, ccode: data.ccode });
      this.aptForm.controls['phone_number'].disable();
      this.aptForm.controls['ccode'].disable();
      this.aptForm.controls['email'].disable();
      this.aptForm.controls['contact_id'].disable();
    }
  }

  getStaff() {
    this.settingService.getStaff_bookings().subscribe(async (resp: any) => {
      this.staff_info = [];
      await resp?.data.forEach((element: any) => {
        Promise.all(this.selectedAppointment?.consultation_data?.map(async (data: any) => {
          if (this.aptForm.value.meeting_type === data.mode) {
            data.staff_data.forEach((staff: any) => {
              if (element.id === staff.staff_id) this.staff_info.push(element);
            });
          }
        }));
      });
      if (this.aptForm.value.id) {
        this.reschedule_staff_id = this.aptForm.value.staff_id;
        this.aptForm.controls['meeting_type'].disable();
        this.aptForm.controls['staff_id'].disable();
      }
    });
  }

  getCategoriesList() {
    this.setupService.getAllActiveCategories().subscribe({
      next: (resp: any) => {
        this.categoriesArray = resp.data;
      }
    });
  }

  onCategoryChange(ev: any) {
    this.setupService.getConsulationsBycategoryIdActive(ev).subscribe((resp: any) => {
      this.appointments_list = resp.data;
      this.aptForm.patchValue({ ref_id: resp.ref_id });
      // if(!this.aptForm.value.id) this.slotsData = []; this.aptForm.patchValue({ meeting_type: '', staff_id: '', apt_details: '', date: '',slot: ''});
    });
  }

  onAptSelected(item: any, event: any) {
    if (event.isUserInput) {
      this.selectedAppointment = item;
      this.selectedconsultation_data = item.consultation_data.filter((c: any) => c.is_active && c.mode !== "DOOR_STEP");
      // if(!this.aptForm.value.id) this.slotsData = []; this.aptForm.patchValue({ staff_id: '', apt_details: '', date: '',slot: ''});

    } else if (this.aptForm.value.id) {
      this.selectedAppointment = item;
      this.selectedconsultation_data = item.consultation_data.filter((c: any) => c.is_active && c.mode !== "DOOR_STEP");
      this.aptForm.controls['category'].disable();
      this.aptForm.controls['consultation_id'].disable();
      this.aptForm.controls['apt_details'].disable();
      this.getStaff();
    }
  }

  onAptTypeChange(ev: any) {
    if (ev === "OFF_LINE") {
      this.aptForm.get('custom_meeting_url')?.clearValidators();
      this.aptForm.get('custom_meeting_url')?.markAsUntouched();
      this.aptForm.get('custom_meeting_url')?.updateValueAndValidity();
      if (this.aptForm.value.staff_id) {
        this.settingService.getStaffLocationsByStaffId(this.aptForm.value.staff_id).subscribe((resp: any) => {
          this.locationsDetails = resp.data.filter((ele: any) => ele.status);
        });
      } else {
        this.settingService.getActiveLocationSettings().subscribe((resp: any) => {
          this.locationsDetails = resp.data.filter((ele: any) => ele.status);
        });
      }
    } else if (ev === "ON_LINE") {
      const locations = [
        {
          'name': 'Zoom', 'id': 'zoom'
        },
        {
          'name': 'Google Meet', 'id': 'google'
        },
        {
          'name': 'Custom Link', 'id': 'custom'
        }];
      this.locationsDetails = locations;
      // this.locationsDetails = [{ 'name': 'Zoom', 'id': 'Zoom' }];
    }
    this.getStaff();
  }

  locationChange(ev: any) {
    if (this.aptForm.value.meeting_type !== 'ON_LINE') {
      this.selectedLocation = this.locationsDetails.filter((l: any) => l.id === ev.value);
      this.aptForm.controls['org_address_id'].setValue(ev.value);
    } else {
      this.aptForm.patchValue({ online_meeting_medium: ev.value });
      const meetingLink = document.querySelector('.meeting-link');
      if (ev.value !== 'custom') {
        if (ev.value === "google") {
          this.getConnectivityTest('google');
        } else if (ev.value === 'zoom') {
          this.getConnectivityTest('zoom');
        } else {
          this.aptForm.get('custom_meeting_url')?.clearValidators();
          this.aptForm.get('custom_meeting_url')?.markAsUntouched();
          this.aptForm.get('custom_meeting_url')?.updateValueAndValidity();
          if (meetingLink) {
            this.renderer.setStyle(meetingLink, 'opacity', '0');
            setTimeout(() => {
              this.renderer.setStyle(meetingLink, 'display', 'none');
            }, 400);
          }
        }
      } else {
        this.renderer.setStyle(meetingLink, 'display', 'block');
        this.renderer.setStyle(meetingLink, 'opacity', '1');
        this.aptForm.get('custom_meeting_url')?.addValidators([Validators.required]);
        this.aptForm.get('custom_meeting_url')?.updateValueAndValidity();
      }
      this.selectedLocation = ev.value;
    }
  }

  getConnectivityTest(type:string) {
    let connectivityTest;
    let message = '';
    const staffId = this.aptForm.controls['staff_id'].value || this.userDetails.data.id;
    if (type === 'google') {
      connectivityTest = this.settingService.staffConnected(staffId);
      message = 'Staff not connected to Gmail';
    } else {
      connectivityTest = this.settingService.zoomCoonectivity(this.userDetails.data.organisation);
      message = 'The organization is not connected to Zoom.';
    }
    connectivityTest.subscribe({
      next: (resp: any) => {
        if (resp.data && resp.data?.isConnected) {
          const meetingLink = document.querySelector('.meeting-link');
          this.aptForm.get('custom_meeting_url')?.clearValidators();
          this.aptForm.get('custom_meeting_url')?.markAsUntouched();
          this.aptForm.get('custom_meeting_url')?.updateValueAndValidity();
          if (meetingLink) {
            this.renderer.setStyle(meetingLink, 'opacity', '0');
            setTimeout(() => {
              this.renderer.setStyle(meetingLink, 'display', 'none');
            }, 400);
          }
        } else {
          this.toastAlert.error(message);
          this.aptForm.patchValue({ online_meeting_medium: 'custom' });
          this.aptForm.patchValue({ apt_details: 'custom' });
          this.locationChange({ value: 'custom' });
        }
      },
      error: (err: any) => {
        console.log(err);
        this.aptForm.patchValue({ online_meeting_medium: 'custom' });
        this.aptForm.patchValue({ apt_details: 'custom' });
        this.locationChange({ value: 'custom' });
      }
    });
  }

  async getSlots() {
    if ((this.aptForm.value.staff_id || this.reschedule_staff_id) && this.aptForm.value.date) {
      const results: any = await this.settingService.getStaff_infoById(this.aptForm.value.staff_id || this.reschedule_staff_id).toPromise();
      this.selectedStaff = results.data;
      this.aptForm.controls['department'].setValue(results.data.department);
    }
    this.onAptTypeChange(this.aptForm.value.meeting_type);
    if (this.aptForm.value.online_meeting_medium === 'google') {
      this.getConnectivityTest('google');
    }
    let slot = 30;
    this.aptForm.controls['slot'].setValue('');
    if (this.authService.getCompanyData().slot_duration) slot = this.authService.getCompanyData().slot_duration;
    const todaysDate = new Date();
    this.currentTime = '';
    if (this.convert(this.aptForm.value.date) === this.convert(todaysDate)) {
      const currentHour = todaysDate.getHours();
      const currentMinute = todaysDate.getMinutes();
      this.currentTime = (currentHour.toString().length === 1 ? ('0' + currentHour) : currentHour) + ':' + (currentMinute.toString().length === 1 ? ('0' + currentMinute) : currentMinute);
    }
    if (this.aptForm.value.date) {
      this.noslots = false;
      const body = {
        "staff": this.aptForm.value.staff_id ? this.aptForm.value.staff_id : (this.reschedule_staff_id || this.userDetails.data.organisation),
        "date": this.convert(this.aptForm.value.date),
        "appointment_duration": Number(this.selectedAppointment.duration),
        "slot_duration": Number(this.selectedAppointment.duration), //Number(slot)
        "time_zone": (this.aptForm.value.staff_id || this.reschedule_staff_id) ? this.selectedStaff?.time_zone : this.currentUserTimeZone,
        "type": (this.aptForm.value.staff_id || this.reschedule_staff_id) ? 'appointment' : 'organisation',
        "appointment_booking_type": this.aptForm.value.id ? 'reschedule' : 'schedule',
        "category_id": this.aptForm.value.category,
        "organisation": this.userDetails.data.organisation,
        "source_type": "ADMIN_PANEL"
      };
      this.setupService.getSlotsBasedOnStaffId(body).subscribe((resp: any) => {
        this.slotsData = [];
        resp.data.forEach((element: any) => {
          if (element.slot_status) this.slotsData.push({ ...element, startTime: element.start_time.split('T')[1].slice(0, -3) });
        });
        if (this.slotsData.length === 0) this.noslots = true;
      }, () => {
        this.slotsData = [];
        this.noslots = true;
      });
    }
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  convertAptdate(str: any) {//Date formate: Day/month/year
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("-");
  }

  onAptSubmit() {
    const ref_id = this.aptForm.value.ref_id;
    this.submitted = true;
    if (this.aptForm.valid) {
      this.loading = false;
      let selectedStaffDetails;
      const selectedStaff = this.staff_info.filter((c: any) => c.id === this.aptForm.value.staff_id);
      if (selectedStaff.length > 0) {
        selectedStaffDetails = {
          staffName: selectedStaff[0].fname + ' ' + selectedStaff[0].lname,
          staffId: selectedStaff[0].id,
          staffEmail: selectedStaff[0].email
        };
      }
      this.selectedconsultation_data.filter((item: any) => {
        if (item.mode === this.aptForm.value.meeting_type) {
          this.selectedAppointment.consultation_data.filter((data: any) => {
            if (data.mode === this.aptForm.value.meeting_type)
              data.staff_data.forEach((staff: any) => {
                if (this.aptForm.value.staff_id === staff.staff_id) {
                  this.total_price = item.final_price + staff.staff_special_price;
                } else {
                  this.total_price = item.final_price;
                }
              });
          });
        }
      });
      if (this.aptForm.value.id) {
        this.setupService.putAppointmentReschedule(this.aptForm.value.id, { date: this.convertAptdate(this.aptForm.value.date), slot: this.aptForm.value.slot }).subscribe(() => {
          this.passEntry.emit("success");
          this.ngbActiveModal.dismiss();
        });
      } else {
        if (this.aptForm.valid) {
          const body = {
            ...this.aptForm.value,
            date: this.convertAptdate(this.aptForm.value.date),
            info: {
              user: {
                first_name: this.selectedCustomer.first_name,
                last_name: this.selectedCustomer.last_name,
                phone_number: this.selectedCustomer.phone_number,
                email: this.selectedCustomer.email,
                id: this.selectedCustomer.id,
                ccode: this.selectedCustomer.ccode
              },
              staff: selectedStaffDetails,
              address: this.selectedLocation[0]
            },
            user_time_zone: this.aptForm.value.staff_id ? this.selectedStaff?.time_zone : this.currentUserTimeZone
          };
          const payload = {
            gateway_name: "",
            type: "OFFLINE",
            reference_id: ref_id,
            source_type: "ADMIN_PANEL",
            source_id: ref_id,
            mode: "COD",
            post_transaction: {
              amount: this.total_price,
              currency: this.selectedAppointment.currency
            },
            info: {
              // user: this.selectedCustomer,
              staff: selectedStaffDetails
            },
            user_info: this.selectedCustomer
          };
          if (this.transaction_id) {
            this.setupService.postAppointment(body).pipe(finalize(() => this.loading = false)).subscribe(() => {
              this.ngbActiveModal.dismiss();
            });
          } else {
            this.setupService.postTransaction(payload).pipe(finalize(() => this.loading = false)).subscribe((trans: any) => {
              this.transaction_id = trans.data.id;
              setTimeout(() => {
                this.setupService.postAppointment(body).pipe(finalize(() => this.loading = false)).subscribe((resp: any) => {
                  resp.data.chatId = this.chatId;

                  const iframe: any = document.getElementById('chat-widget-value');
                  iframe?.contentWindow.postMessage({ ...resp, type: "appointmentCreated" }, '*');
                  this.ngbActiveModal.dismiss();
                });
              }, 5000);
            });
          }
        }
      }
    }
  }

  cancelAptModal() {
    this.ngbActiveModal.dismiss();
  }

  getSettings() {
    this.setupService.getConsulationSettings().subscribe((resp: any) => {
      const no_of_days = resp.data.number_of_days_available_for_booking_in_advance;
      this.maxDate.setDate(this.maxDate.getDate() + no_of_days);
    });
  }

  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }
}

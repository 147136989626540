import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CrmService } from 'src/app/core/service/crm.service';

@Component({
  selector: 'app-export-item-selection',
  templateUrl: './export-item-selection.component.html',
  styleUrls: ['./export-item-selection.component.scss']
})
export class ExportItemSelectionComponent implements OnInit {
  @Input() payload: any;
  tableColumns: any[] = [];
  disabledItems: any[] = [];
  isSubmitted = false;
  selected_ids: string[] = [];
  export = true;
  message = '';
  CSVlinkLastElement = '';
  ignoreLists: string[] = ['assignee', 'owner', 'product', 'service_category', 'service', 'scheduler_category', 'scheduler', 'product_category'];
  group_contact: any;
  constructor(public ngbActiveModal: NgbActiveModal, private service: CrmService, private toaster: ToastrService, private router: Router) {
  }

  ngOnInit(): void {
    this.tableColumns = JSON.parse(JSON.stringify(this.payload?.data));
    this.message = this.payload.message;
    this.tableColumns.forEach((elem: any) => {
      if (this.ignoreLists.includes(elem.field_name))
        elem.status = false;
    });
    this.disabledItems = this.payload.disabledItems;
    this.selected_ids = Object.keys(this.payload.checkBoxStorageKeeper);
    this.group_contact = this.payload.group_id;
  }
  onExportingData() {
    this.isSubmitted = false;
    const table_coloums = this.tableColumns.reduce((acc: any[], elem: any) => {
      if (elem.status) {
        acc.push({
          field: elem.field_name,
          system_field: elem.system_field
        });
      }
      return acc;
    }, []);
    this.isSubmitted = true;
    const payload:any = {
      csv_type: this.payload.type,
      fields: table_coloums,
      selected_ids: this.selected_ids,
      group_id: this.group_contact
    };
    if (this.payload.type === 'pipeline') {
      payload['pipeline'] = this.payload.pipelineId;
    }
    console.log(payload);
    const serviceMethod = (this.payload.type === 'client' || this.payload.type === 'project')
      ? this.service.exportCLIENTservice(payload)
      : this.service.exportCRMservice(payload);

    serviceMethod.subscribe({
      next: (resp: any) => {
        this.isSubmitted = false;
        setTimeout(() => {
          this.export = false;
        }, 1000);
        // this.onGetCSVfile();
      },
      error: () => {
        this.isSubmitted = false;
        this.export = true;
        console.log('');
      }
    });
  }

  onGetCSVfile() {
    const serviceMethod = (this.payload.type === 'client' || this.payload.type === 'project')
      ? this.service.getCLIENTCSV(this.payload.type)
      : this.service.getCRMCSV(this.payload.type);

    serviceMethod.subscribe({
      next: (resp: any) => {
        if (resp.data && resp.data.length > 0) {
          const dataLength = resp.data.length;
          this.CSVlinkLastElement = resp.data[dataLength - 1]?.csv_file;
        }
      },
      error: () => {
        this.toaster.error("Something went Wrong");
        this.CSVlinkLastElement = '';
      }
    });

  }
  onNavigateToAllDownloads() {
    this.ngbActiveModal.dismiss();
    this.router.navigate(['crm/import-files'], { queryParams: { type: this.payload.type, showType: 'download' } });
  }

  onSelectionChange(item: any, index: number) {
    const obj = JSON.parse(JSON.stringify(item));
    obj.status = !obj.status;
    this.tableColumns[index] = obj;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BillingComponent } from './billing/billing.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { DataAdministrationComponent } from './data-administration/data-administration.component';
import { EmailNotificationsComponent } from './email-notifications/email-notifications.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { SmsSettingsComponent } from './sms-settings/sms-settings.component';
import { TagsComponent } from './tags/tags.component';
import { UsersControlComponent } from './users-control/users-control.component';
import { WhatsappNotificationsComponent } from './whatsapp-notifications/whatsapp-notifications.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { PaymentsComponent } from './payments/payments.component';
import { ActivityComponent } from './activity/activity.component';
import { LostReasonsComponent } from './lost-reasons/lost-reasons.component';
import { SubscriptionPlansComponent } from './subscription-plans/subscription-plans.component';
import { SubscriptionPlanDetailsComponent } from './subscription-plan-details/subscription-plan-details.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { ContactTypesComponent } from './contact-types/contact-types.component';

const routes: Routes = [
  {
    path: '',
    component: GeneralSettingsComponent
  },
  {
    path: 'users&controls',
    component: UsersControlComponent
  },
  {
    path: 'sms',
    component: SmsSettingsComponent
  },
  {
    path: 'custom-fields',
    component: CustomFieldsComponent
  },
  {
    path: 'tags',
    component: TagsComponent
  },
  {
    path: 'billing',
    component: BillingComponent
  },
  {
    path: 'data-administration',
    component: DataAdministrationComponent
  },
  {
    path:'email-notifications',
    component: EmailNotificationsComponent
  },
  {
    path:'whatsapp-notificaions',
    component: WhatsappNotificationsComponent
  },
  {
    path:'integrations',
    component: IntegrationsComponent
  },
  {
    path:'payments',
    component: PaymentsComponent
  },
  {
    path:'activity',
    component:ActivityComponent
  },
  {
    path: 'lost-reasons',
    component: LostReasonsComponent
  },
  {
    path: 'subscription',
    component: SubscriptionPlansComponent
  },
  {
    path: 'plan_details',
    component: SubscriptionPlanDetailsComponent
  },
  {
    path: 'transaction_history',
    component: TransactionHistoryComponent
  },
  {
    path: 'contact_types',
    component: ContactTypesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }

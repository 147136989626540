import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/service/auth.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-contact-types',
  templateUrl: './contact-types.component.html',
  styleUrls: ['./contact-types.component.scss']
})
export class ContactTypesComponent implements OnInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  modalRef!: NgbModalRef;
  addTypeForm!: FormGroup;
  submitted = false;
  column_names = ['Contact Type Name', 'Actions'];
  typeListArray: any[] = [];
  totalTypes: any;
  currentPage = 1;
  page_size = 20;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  listOfModuleNames = [
    {
      label: 'Contact Types',
      value: 'contact',
      launchDlKeys: 'contact'
    }
  ];
  featuresData: any;
  activeLink = this.listOfModuleNames[0].value;
  background: ThemePalette = undefined;
  tableSkeletonLoader = true;
  constructor(private modalService: NgbModal, private settingsService: SettingsService,
    private confirmationMsz: ConfirmationMszService, private authService: AuthService) { }

  ngOnInit() {
    this.initilizeTypeForm();
    this.getTypes();
    this.getFeatues();
  }

  initilizeTypeForm(data?: any) {
    this.addTypeForm = new FormGroup({
      name: new FormControl(data ? data.name : '', [Validators.required]),//Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      id: new FormControl(data ? data.id : '')
    });
  }

  get f() {
    return this.addTypeForm.controls;
  }

  getTypes() {
    this.tableSkeletonLoader = true;
    this.settingsService.getContactTypes(this.currentPage).subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.typeListArray = resp.data;
      this.totalTypes = resp.count;
    });
  }

  onTabChange(item: string) {
    this.paginator.pageIndex = 0;
    this.currentPage = 1;
    this.activeLink = item;
    this.getTypes();
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.addTypeForm.value);
    if (this.addTypeForm.valid) {
      if (this.addTypeForm.value.id) {
        this.settingsService.updateContactType(this.addTypeForm.value).subscribe(() => {
          this.getTypes();
          this.modalRef.dismiss();
        });
      } else {
        this.settingsService.addContactTypes(this.addTypeForm.value).subscribe(() => {
          this.getTypes();
          this.modalRef.dismiss();
        });
      }
    }
  }

  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.currentPage = e.pageIndex + 1;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getTypes();
  }

  deleteSelectedType(id: any) {
    this.confirmationMsz.customDeleteView({ title: 'Are you sure?', text: 'You want to delete!', button: 'Yes Delete!' })
      .then((data) => {
        if (data.value) {
          this.settingsService.deleteContactType(id).subscribe(() => {
            this.getTypes();
          });
        }
      });
  }


  openAddTypeModal(content: any, data?: any) {
    this.submitted = false;
    this.modalRef = this.modalService.open(content, { size: 'md', centered: true });
    this.initilizeTypeForm(data);
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      this.listOfModuleNames = this.listOfModuleNames.filter((elem: any) => {
        return this.featuresData[elem.launchDlKeys];
      });
      this.activeLink = this.listOfModuleNames[0].value;

    });
  }

}

<div class="container-fluid">
    <div class="row px-2" *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="col-md-12">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0"
                (navChange)="tabChange($event)">
                <li [ngbNavItem]="i+1" class="me-2" *ngFor="let plan of subscriptionHeading;let i = index">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">{{plan.name}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="container-fluid overflow-auto">
    <div class="row px-2 profile-div-height pb-3" *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="col-md-12">
            <!-- <h6 class="fw-600">Select subscription billing cycle</h6>
            <h6 class="font-size-13">Save 60% with an annual billing cycle</h6> -->
            <div class="row mt-3" *ngIf="currentViewPlan !== 'life_time'">
                <div class="d-flex px-2 justify-content-end mb-2" *ngIf="currentViewPlan === 'regular'">
                    <div role="group" aria-label="Basic radio toggle button group btn-border" class="btn-group me-2"
                        style="height: 38px;">
                        <input type="radio" name="btnradio" id="btnradio1" autocomplete="off" value="monthly"
                            class="btn-check" [(ngModel)]="show_value" (change)="viewChange($event)">
                        <label for="btnradio1" class="btn btn-light btn-border-left"
                            [ngClass]="{'selected_view': show_value=== 'monthly'}">
                            Monthly
                        </label>
                        <input type="radio" name="btnradio" id="btnradio3" autocomplete="off" class="btn-check"
                            value="yearly" [(ngModel)]="show_value" (change)="viewChange($event)">
                        <label for="btnradio3" class="btn btn-light btn-border-right"
                            [ngClass]="{'selected_view': show_value=== 'yearly'}">
                            Yearly
                        </label>
                    </div>
                </div>
                <div class="col-md-4 pb-4" *ngFor="let item of manage_plans">
                    <div class="card h-100vh h-100">
                        <div class="p-3">
                            <h5 class="fw-600">{{item.name}}</h5>
                            <p class="text-secondary">{{item.short_description}}</p>
                            <div class="d-flex mb-4">
                                <label class="font-size-24" *ngIf="item.name !== 'Custom'">{{item.currency_symbol}}
                                    {{item.price}} <span class="font-size-13 text-secondary">{{ show_value === 'yearly'
                                        ? '/ Year' : '/ Month' }} </span></label>
                                <label class="font-size-24 fw-600" *ngIf="item.name === 'Custom'">Let's talk</label>
                            </div>
                            <button class="btn w-100 dotted-border-1" type="button"
                                *ngIf="currentPlan.includes(item.id) && item.subscription_type !== 'addon'">
                                Current Plan
                            </button>
                            <button class="btn w-100 btn-primary" type="button"
                                *ngIf="item.subscription_type === 'addon' || (!currentPlan.includes(item.id) && item.name !== 'Custom')"
                                (click)="openPaymentDetials(paymentGatewayModal, item)">
                                Subscribe
                            </button>
                            <button class="btn w-100 btn-primary" type="button"
                                *ngIf="!currentPlan.includes(item.id) && item.name === 'Custom'">
                                Contact Us
                            </button>
                        </div>
                        <hr>
                        <div class="p-3 pt-0">
                            <label class="fw-600 mb-2">Features</label>
                            <div class="d-flex align-items-center mt-2" *ngFor="let feature of item.plan_features">
                                <mat-icon
                                    class="me-2 font-size-16 d-flex align-items-center justify-content-center">check</mat-icon>
                                <label>{{feature.feature_text}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="currentViewPlan === 'life_time'">
                <div *ngFor="let item_type of manage_plans" class="row mt-3 border-bottom">
                    <h4 class="fw-600 mb-3">{{item_type.type}}</h4>
                    <div class="col-md-4 pb-4" *ngFor="let item of item_type.values">
                        <div class="card h-100vh h-100">
                            <div class="p-3">
                                <h5 class="fw-600" *ngIf="item.name !== 'doNot_display'">{{item.name}}</h5>
                                <p class="text-secondary">{{item.short_description}}</p>
                                <div class="d-flex mb-4">
                                    <label class="font-size-24">{{item.currency_symbol}} {{item.price}} <span
                                            class="font-size-13 text-secondary">/ Lifetime</span></label>
                                </div>
                                <button class="btn w-100 dotted-border-1" type="button"
                                    *ngIf="currentPlan.includes(item.id) && !['addon', 'Custom'].includes(item.subscription_type)">
                                    Current Plan
                                </button>
                                <button class="btn w-100 btn-primary" type="button"
                                    *ngIf="!currentPlan.includes(item.id)"
                                    (click)="openPaymentDetials(paymentGatewayModal,item)">
                                    Subscribe
                                </button>
                            </div>
                            <hr>
                            <div class="p-3 pt-0">
                                <label class="fw-600 mb-2">Features</label>
                                <div class="d-flex align-items-center mt-2" *ngFor="let feature of item.plan_features">
                                    <mat-icon
                                        class="me-2 font-size-16 d-flex align-items-center justify-content-center">check</mat-icon>
                                    <label>{{feature.feature_text}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template let-modal let-c="close" #planDetailsModal let-planDetails="planDetails">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Subscription Plan Details</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="c('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <h6 class="">
                    Since the billing cycle starts on the 1st of every month, we're only charging
                    <b>{{selectedPlanDetails?.currency_symbol}}{{selectedPlanDetails?.prorated_plan_price}}</b>
                    for the next <b>{{selectedPlanDetails?.remaining_days}}</b> days.
                </h6>
                <div class="d-flex justify-content-end mt-3">
                    <button class="btn btn-primary" type="button"
                        (click)="openPaymentDetials(paymentGatewayModal,selectedPlanData)">
                        Subscribe
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template let-modal let-exit="close" #paymentGatewayModal let-payment="payment">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Select a payment method</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="exit('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-5 card m-2 cursor" *ngFor="let item of payment_gateway" style="height: 125px;"
                [ngClass]="{'selectedPayment': item.payment_key_name === paymentMethod}"
                (click)="paymentMethod = item.payment_key_name">
                <div class="d-flex align-items-center h-100">
                    <img [src]="item.logo_url" width="150">
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end mt-3">
            <button class="btn btn-primary" type="button" [disabled]="!paymentMethod"
                (click)="subscriptionSubmit(selectedPlanData.id,paymentMethod)"><!--(click)="subscriptionSubmit(item.id,item.plan_type)"-->
                Check Out
            </button>
        </div>
    </div>
</ng-template>
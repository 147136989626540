<div>
  <div class="d-flex align-items-center mb-4 px-3">
    <img src="assets/images/newUI/CRM/file_folder_icons.svg" alt="">
    <p class="m-0 ps-2 title-text-clr font-size-16 fw-600">Add Files</p>
  </div>
  <div class="px-3" style="border-bottom: 1px dashed #E1E0E0;">
    <div class="col-12">
      <ngx-dropzone (change)="onSelect($event)" [multiple]="false" [draggable]="false" id="media-dropzone"
        class="file-upload-sec border-radius-8" style="height: 80px; min-height: 80px;"
        accept="image/jpeg,image/jpg,image/png,image/gif,video/mp4,audio/mp3,audio/mpeg,video/mov,video/quicktime,text/plain,.doc,.docx,.txt,.pdf,.csv,.xlsx,.xls">
        <ngx-dropzone-label class="m-0 mx-3 font-size-14"> {{filesJSON.dropfile_placeholder_text1}} <span
            class="text-primary fw-500">{{filesJSON.dropfile_placeholder_text2}}</span>
          {{filesJSON.dropfile_placeholder_text3}}
        </ngx-dropzone-label>
        <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)" ngProjectAs="ngx-dropzone-preview"
          *ngFor="let f of files" [file]="f" style="max-width: 180px;">
        </ngx-dropzone-image-preview>
      </ngx-dropzone>
    </div>
    <p class="fw-400 mb-0 font-size-12 mt-2 pb-2" style="color: #717179;" >Files of JPG, JPEG, PNG,
      GIF,
      PDF, DOC, DOCX, CSV, XLSX, XLS, TXT, MP3 MP4, or
      MOV
      formats with max of
      15MB
      are allowed.</p>
    <div class="d-flex my-3">
      <div class="" style="width:40px;height:40px" *ngIf="show_loader">
        <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
          y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
          <path fill="black"
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50"
              to="360 50 50" repeatCount="indefinite" />
          </path>
        </svg>
      </div>
      <button *ngIf="!show_loader" class="btn border-radius-8 bg-primary fw-500 font-size-14 text-white action-btn"
        type="button" [disabled]="!this.files[0]" (click)="onUpload()">Upload File</button>
    </div>
  </div>


  <div *ngIf="fileId"
    class="dummy-search-box d-flex border-radius-12 width-fit-content px-2 py-2 my-3 align-items-center">
    <p class="m-0 me-2">{{'Filtered By: Selected from Notifications'}}</p>
    <button type="button" class="btn-close" aria-label="Close" (click)="clearFilter()"></button>
  </div>
  <div class="files-div-height d-flex flex-column justify-content-between new_design_2024 px-3 pt-3">
    <div class="table-responsive table-height h-100">
      <table class="table align-middle table-nowrap table-hover mb-0 file-table ">
        <thead>
          <tr class="table-header-color">
            <th scope="col">{{filesJSON.col_one_label}}</th>
            <th scope="col">{{filesJSON.col_seven_label}}</th>
            <th scope="col">{{filesJSON.col_eight_label}}</th>
            <!-- <th scope="col">{{filesJSON.col_two_label}}</th> -->
            <th scope="col">
              {{filesJSON.col_five_label}}</th>
            <th scope="col">{{filesJSON.col_four_label}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filesListArray; let i= index;">
            <td>
              <div class="d-flex align-items-baseline list-name-sec">
                <img src="{{item.media_url}}" (error)="onErrorLoadImage($event,presignedurl,item.name)" height="20px"
                  width="20px">
                <a href="{{item.media_url}}" target="_blank"
                  class="position-relative ms-2 text-dark fw-medium limit-line-length-1" download>
                  {{item.name}}
                </a>
              </div>

              <span class="file-badges font-size-10 ms-2 px-2 py-1 border-radius-6"
                *ngIf="item?.source_type === 'consultation'">From Appointment</span>
            </td>
            <td>
              <span class="limit-line-length-1">
                {{item?.created_by_name || "NA" }}
              </span>
            </td>
            <td>{{item.created_at | dateConversion}} {{item.created_at | date: 'h:mm a'}}</td>
            <!-- <td>
              <span class="limit-line-length-1">
                {{item.updated_at | dateConversion }}
              </span>
            </td> -->
            <td
              [ngStyle]="{'opacity':item?.is_editable ? '1' : '0.5','cursor' :item?.is_editable ? 'pointer':'auto'}">
              <label class="switch" (click)="!(item?.is_editable) ? $event.preventDefault() : ''">
                <input type="checkbox" [id]="item.id" [(ngModel)]="item.is_visible"
                  (change)="toggleVisibility($event,item)">
                <span class="slider round" [ngStyle]="{'cursor' :item?.is_editable ? 'pointer':'auto'}"></span>
              </label>
            </td>
            <td >
              <mat-icon class="cursor d-flex align-items-center"
                [ngStyle]="{'opacity':item.is_editable ? '1' : '0.5','cursor' :item.is_editable ? 'pointer':'auto'}"
                (click)="item.is_editable && confirmDelete(item.id)">delete</mat-icon>
            </td>
          </tr>
          <tr *ngIf="filesListArray && filesListArray.length === 0">
            <div style="transform: translateX(100%); background: transparent;">
              <app-nodata-found></app-nodata-found>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!fileId">
      <mat-paginator [length]="count" [pageSize]="20" (page)="page($event)" aria-label="Select page"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>




<!-- <div>
    <h6 class="text-primary">{{filesJSON.files_heading}}</h6>
    <div class="row">
        <div class="col-10">
            <ngx-dropzone (change)="onSelect($event)" id="media-dropzone" [multiple]="false" [draggable]="false"
                class="bg-gray-light" style="height: 61px;">
                <ngx-dropzone-label><span class="text-primary fw-500">{{filesJSON.dropfile_placeholder_text2}}</span>
                    {{filesJSON.dropfile_placeholder_text3}}
                </ngx-dropzone-label>
                <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)"
                    ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                </ngx-dropzone-image-preview>
            </ngx-dropzone>
        </div>
        <div class="col-2 d-flex align-items-center justify-content-center">
            <div class="" style="width:60px;height:60px" *ngIf="show_loader">
                <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                    <path fill="black"
                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"
                            from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                    </path>
                </svg>
            </div>
            <button *ngIf="!show_loader" class="btn btn-primary px-4" type="button" [disabled]="!this.files[0]"
                (click)="onUpload()">Upload</button>
        </div>
    </div>
    <p class="mt-3 font-size-12">Files of JPG, JPEG, PNG, GIF, PDF, DOC, DOCX, TXT, MP3 MP4, or MOV formats with max of
        15MB
        are allowed.</p>
    <div *ngIf="fileId"
        class="dummy-search-box d-flex border-radius-12 width-fit-content px-2 py-2 my-3 align-items-center">
        <p class="m-0 me-2">{{'Filtered By: Selected from Notifications'}}</p>
        <button type="button" class="btn-close" aria-label="Close" (click)="clearFilter()"></button>
    </div>
    <div class="files-div-height d-flex flex-column justify-content-between">
        <div class="table-responsive table-height">
            <table class="table align-middle table-nowrap table-hover mb-0 file-table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{filesJSON.col_one_label}}</th>
                        <th scope="col">{{filesJSON.col_two_label}}</th>
                        <th scope="col">{{filesJSON.col_seven_label}}</th>
                        <th scope="col">{{filesJSON.col_four_label}}</th>
                        <th scope="col">{{filesJSON.col_five_label}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of filesListArray; let i= index;">
                        <td>
                            {{i+1}}
                        </td>
                        <td>
                            <img src="{{item.media_url}}"
                                (error)="onErrorLoadImage($event,presignedurl,item.name)" height="25px"
                                width="25px">
                            <a href="{{item.media_url}}" target="_blank"
                                class=" ms-2 text-dark fw-medium" download>
                                {{item.name}}
                            </a>
                        </td>
                        <td>{{item?.created_by_name || "NA"}}</td>
                        <td>{{item.updated_at | dateConversion}}</td>
                        <td>
                            <mat-icon
                                [ngStyle]="{'opacity':item?.is_editable ? '1' : '0.5','cursor' :item?.is_editable ? 'pointer':'auto'}"
                                (click)="item.is_editable && confirmDelete(item.id)">delete</mat-icon>
                        </td>
                        <td
                            [ngStyle]="{'opacity':item?.is_editable ? '1' : '0.5','cursor' :item?.is_editable ? 'pointer':'auto'}">
                            <label class="switch" (click)="!(item?.is_editable) ? $event.preventDefault() : ''">
                                <input type="checkbox" [id]="item.id" [(ngModel)]="item.is_visible"
                                    (change)="toggleVisibility($event,item)">
                                <span class="slider round"
                                    [ngStyle]="{'cursor' :item?.is_editable ? 'pointer':'auto'}"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="!fileId">
            <mat-paginator [length]="count" [pageSize]="20" (page)="page($event)" aria-label="Select page"
                showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>
</div> -->
<div class="container-fluid mt-2" ><!--*ngIf="featureShow()"-->
    <!-- <div class="mt-3">
        <h5 class="ms-3 d-flex align-items-center">
            <mat-icon matTooltip="Back" routerLink="/form-builder">{{formtabsJson.backward_icon}}</mat-icon>
            <span class="ms-2 font-size-13">Edit {{form_name || 'Form'}}</span>
        </h5>
    </div> -->
    <div class="row">
        <div class="col-12 col-lg-12">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0 me-3"
                (navChange)="tabChange($event)">                
                <li [ngbNavItem]="1" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">{{formtabsJson.tab_four}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-formlead-settings [formId]="formId"></app-formlead-settings>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">{{formtabsJson.tab_one}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-create-form [formId]="formId"></app-create-form>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">{{formtabsJson.tab_two}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-notification-settings [formId]="formId"></app-notification-settings>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" class="me-2 d-none">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">{{formtabsJson.tab_three}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-form-integartions [formId]="formId"></app-form-integartions>
                    </ng-template>
                </li>
                <li [ngbNavItem]="5" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">{{formtabsJson.tab_five}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-formthank-uscreen [formId]="formId"></app-formthank-uscreen>
                    </ng-template>
                </li>
                <li [ngbNavItem]="6" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">{{formtabsJson.tab_six}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-form-share [formId]="formId"></app-form-share>

                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
</div>
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ADDCLIENTPROJECTJSON } from 'src/app/core/data/client_project_json';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ClientsService } from 'src/app/core/service/clients.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { countryData } from 'src/app/core/data/countryData';
import { ReplaySubject, Subject, finalize, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { CustomFieldsService } from 'src/app/core/service/custom-fields.service';
import { ErrorLoadImage, maximumNumberAllowed, onCheckRequiredFieldStatus, onOnlyNumbers, onParseDomValue, onlyNumberDecimal, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CHANNELS_LIST } from 'src/app/core/data/channels_json';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-client-project-modal',
  templateUrl: './client-project-modal.component.html',
  styleUrls: ['./client-project-modal.component.scss']
})
export class ClientProjectModalComponent implements OnInit, AfterViewInit {
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  @ViewChildren('customEditorComponents') customEditorComponents!: QueryList<CKEditorComponent>;
  countryjson = countryData;
  submitted = false;
  clientForm!: FormGroup;
  addclient = ADDCLIENTPROJECTJSON[0];
  errorJson = ERRORJSON[0];
  channels = CHANNELS_LIST;
  currency!: string;
  clients: any[] = [];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() clientProjectData: any;
  @Input() client_id: any;
  revokedSysFieldsList: { [key: string]: { is_visible: any } } = {};
  showErrorMessage = false;
  products: any;
  staffMembers: any;
  info: any = {};
  minDate = new Date();
  loading = false;
  loggedInUser: any;
  tagsListArray: any;
  customFieldsArray: any = [];
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  systemFieldsArray: any[] = [];
  productCategory: any;
  aptCategoriesArray: any;
  appointmentsArray: any;
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };
  // @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  presignedurl = environment.preSendUrl;
  editorComponentLists: any[] = [];
  ckeditorLimitLists: any = {};
  featuresData: any;
  serviceCategory: any;
  services: any;

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    public ngbActiveModal: NgbActiveModal,
    private clientService: ClientsService,
    private authService: AuthService,
    private _customFields: CustomFieldsService,
    private setupService: SetUpService,
    private awsUtilService: AwsUtilsService,
    private toaster: ToastrService
  ) { }
  ngOnInit() {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    // console.log(this.clientProjectData);
    this.getFeatues();
    this.getCustomFieldsData();

    this.clientForm = this.fb.group({
      client_id: [this.clientProjectData ? this.clientProjectData.client_id : '', [Validators.required]],
      service_ids: [this.clientProjectData ? this.clientProjectData.service_ids : ''],
      name: [this.clientProjectData ? this.clientProjectData.name : '', [Validators.required]],// Validators.pattern(/^[A-Za-z][A-Za-z0-9-'&.#@_\s]{1,}$/)
      phone: [this.clientProjectData ? this.clientProjectData.phone : ''],
      email: [this.clientProjectData ? this.clientProjectData.email : ''],
      tags: [this.clientProjectData ? this.clientProjectData.tags : ''],
      project_status: [this.clientProjectData ? this.clientProjectData.project_status : 'PENDING'],
      assignee: [this.clientProjectData ? this.clientProjectData.assignee : []],
      owner: [this.clientProjectData ? this.clientProjectData.owner : this.loggedInUser.data.id, [Validators.required]],
      description: [this.clientProjectData ? this.clientProjectData.description : ''],
      due_date: [this.clientProjectData ? this.clientProjectData.due_date : null],
      cost: [this.clientProjectData ? this.clientProjectData.cost : '', [Validators.pattern("[0-9]+"), Validators.min(0)]],
      channel: [this.clientProjectData ? this.clientProjectData.channel : 'manual'],
      id: [this.clientProjectData ? this.clientProjectData.id : ''],
      ccode: [this.clientProjectData?.ccode ? (this.clientProjectData.ccode.includes('+') ? this.clientProjectData?.ccode : '+' + this.clientProjectData.ccode) : this.authService.getCompanyData().ccode, [Validators.required]],
      custom_project_fields: this.fb.array([]),
      system_project_fields: this.fb.array([])
    });
    if (this.clientProjectData?.id) {
      const dataWithoutTags = onParseDomValue(this.clientProjectData?.description);
      this.ckeditorLimit['actualLimit'] = dataWithoutTags?.length || 0;
      this.clientForm.controls["client_id"].disable();
      this.clientForm.controls["email"].disable();
      this.clientForm.controls["phone"].disable();
      this.clientForm.controls["ccode"].disable();
      this.clientForm.controls["channel"].disable();
      if (this.clientProjectData.owner !== this.loggedInUser.data.id && this.loggedInUser.data.role !== 'ADMIN') {
        this.clientForm.controls["assignee"].disable();
        this.clientForm.controls["owner"].disable();
      }
    } else {
      this.info['owner_info'] = {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      };
    }
    if (this.client_id) {
      this.getClientById(this.client_id);
    } else {
      this.getAllClients();
    }
    this.getStoreId();
    this.organisationMembersList();
    this.getTags();
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
  }

  keyDownCustomField(event: any, decimalPoint: number, type: string) {
    return onlyNumberDecimal(event, decimalPoint, type);
  }

  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditorLimit = Object.assign(this.ckeditorLimit, { ...contentLength });
    });
  }

  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();
    const dataWithoutTags = onParseDomValue(inputData);
    this.ckeditorLimit = Object.assign(this.ckeditorLimit, { actualLimit: dataWithoutTags.length || 0 });
  }

  custEditorkeyEvent(editor: any, editorComponents: any, index: any) {
    const edittedItem = this.editorComponentLists.find((elem: any) => elem.id === editorComponents.id);
    if (edittedItem) {
      const inputData = edittedItem.editorInstance.getData();
      const dataWithoutTags = onParseDomValue(inputData);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { actualLimit: dataWithoutTags.length || 0 });
    }
  }

  onCustReady(editor: any, index: any) {
    const object = {
      [index]: {
        maxLimit: 1000,
        actualLimit: 0,
        type: false
      }
    };
    Object.assign(this.ckeditorLimitLists, object);
    const dataWithoutTags = onParseDomValue(editor.data.get());
    this.ckeditorLimitLists[index]['actualLimit'] = dataWithoutTags?.length || 0;
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const edittedItem = this.editorComponentLists.find((elem: any) => elem.editorInstance.id === editor.id);
      const contentLength = pasteEventInCkeditor(edittedItem, pasteContent);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { ...contentLength });
    });
  }

  keyDownEvent(event: any, maxLength: number) {
    const maxNum = maximumNumberAllowed(event, maxLength);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }


  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.myFirstInput.nativeElement.focus();
    // }, 0)
    this.editorComponentLists = [];
    this.customEditorComponents.changes.subscribe((components: QueryList<CKEditorComponent>) => {
      components.forEach((editorComponent: any) => {
        this.editorComponentLists.push(editorComponent);
      });
    });
  }


  addOptionToList = (option: any) => ({ name: option });

  get f() {
    return this.clientForm.controls;
  }

  cancelModal() {
    this.ngbActiveModal.dismiss();
  }
  onSubmit() {
    this.submitted = true;
    this.clientForm.markAllAsTouched();
    const inValidCkEditor = Object.entries(this.ckeditorLimitLists).find((ckeditorLimit: any) => ckeditorLimit[1]?.actualLimit > ckeditorLimit[1]?.maxLimit);
    if (!inValidCkEditor) {
      this.clientForm.value.custom_project_fields.forEach((obj: any, i: number) => {
        if (obj.data_type === "DATE_RANGE") {
          if (obj.end) {
            obj.data = this.convert(obj.start) + '&' + this.convert(obj.end);
            this.custom_project_fields.controls[i].patchValue({ data: obj.data });
          } else if (obj.start) {
            obj.data = this.convert(obj.start);
            this.custom_project_fields.controls[i].patchValue({ data: obj.data });
          }
        } else if (obj.data_type === "PHONE_NUMBER") {
          obj.data = { custom_ccode: obj.start, custom_phone_number: obj.end };
        }
      });
      if (this.clientForm.valid) {
        this.loading = true;
        this.clientForm.patchValue({ cost: this.clientForm.controls['cost'].value ? this.clientForm.controls['cost'].value : null });
        const assignee_departments: any = [];
        let valid_assignee: any;
        let valid_owner: any;

        if (this.clientForm.controls['assignee'].status === 'DISABLED') {
          valid_assignee = this.clientForm.controls['assignee'].value;
        } else {
          valid_assignee = this.clientForm.value.assignee;
        }

        if (this.clientForm.controls['owner'].status === 'DISABLED') {
          valid_owner = this.clientForm.controls['owner'].value;
        } else {
          valid_owner = this.clientForm.value.owner;
        }
        if (valid_assignee.length !== 0) {
          this.staffMembers.forEach((item: any) => {
            valid_assignee.filter((assigne: any) => {
              if (assigne === item.id) {
                assignee_departments.push(item.department_info.id);
              }
            });
          });
        }
        const owner_department = this.staffMembers.filter((member: any) => member.id === valid_owner);

        const body = this.clientForm.value;
        body.info = this.info;
        // body.custom_project_fields.forEach((obj: any) => {
        //   if (obj.data === "") {
        //     obj.data = "NA";
        //   }
        // });

        this.clientForm.value.system_project_fields.forEach((fields: any) => {
          if (fields.data_type === "MULTI_SELECT_DROPDOWN" && fields.data === "") fields.data = [];
          body[fields.label] = fields.data;
        });
        delete body.system_project_fields;
        if (this.clientForm.value.id) {
          this.updateClientProject(assignee_departments, valid_assignee, valid_owner, owner_department);
        } else {
          console.log(body);

          this.clientService.createClientProject({ ...body, email: this.clientForm.controls["email"].value, phone: this.clientForm.controls["phone"].value, ccode: this.clientForm.controls["ccode"].value, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe((response: any) => {
            if (response) {
              this.passEntry.emit("success");
              this.ngbActiveModal.dismiss();
            }
          });
        }
      }
    }
  }
  updateClientProject(assignee_departments: any, valid_assignee: any, valid_owner: any, owner_department: any) {
    // const owner_department = this.staffMembers.filter((member: any) => member.id === valid_owner);
    const body = this.clientForm.value;

    if (Object.keys(this.info).length === 0) {
      body.info = this.clientProjectData.info;
    } else {
      body.info = this.info;
    }

    // body.custom_project_fields.forEach((obj: any) => {
    //   if (obj.data === "") {
    //     obj.data = "NA";
    //   }
    // });

    this.clientService.updateClientProject(this.clientProjectData.id, { ...body, email: this.clientForm.controls["email"].value, phone: this.clientForm.controls["phone"].value, ccode: this.clientForm.controls["ccode"].value, client_id: this.clientForm.controls["client_id"].value, channel: this.clientForm.controls["channel"].value, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe((response: any) => {
      if (response) {
        this.passEntry.emit("success");
        this.ngbActiveModal.dismiss();
      }
    });
  }

  getAllClients() {
    this.clientService.getAllClientsForDropdown().subscribe((response: any) => {
      this.clients = response.data;
      if (this.clientProjectData?.id) {
        if (response.data.filter((ele: any) => ele.id === this.clientProjectData?.client_id).length === 0) this.getClientById(this.clientProjectData.client_id);
      }
    });

  }

  getStoreId() {
    this.clientService.getStoreId().subscribe((response: any) => {
      this.getStoreProducts(response.data.id);
    });
  }

  getStoreProducts(id: string) {
    this.clientService.getStoreProducts(id).subscribe((response: any) => {
      this.products = response.data;
    });
  }

  onSelectClient(data: any) {
    console.log(data);
    this.clientForm.patchValue({ email: data.email });
    this.clientForm.patchValue({ phone: data.phone_number, ccode: data.ccode });
    this.clientForm.controls["email"].disable();
    this.clientForm.controls["phone"].disable();
    this.clientForm.controls["ccode"].disable();
  }

  clearClientFields(e: any) {
    this.clientForm.patchValue({ email: '', phone: '', ccode: this.authService.getCompanyData().ccode });
    this.clientForm.controls["email"].enable();
    this.clientForm.controls["phone"].enable();
    this.clientForm.controls["ccode"].enable();
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  selectOwner(data: any) {
    this.info['owner_info'] = {
      fname: data.fname,
      lname: data.lname
    };
  }

  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  getTags() {
    this.settingsService.getClientTags('projects').subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }


  //******************************* Custom Fields START ******************************************** *//
  getCustomFieldsData() {
    this._customFields.getClientProjectModelFields().subscribe({
      next: (resp: any) => {
        this.showErrorMessage = false;
        this.revokedSysFieldsList = {};
        this.onCheckRequiredFieldStatus(resp.data);
        this.systemFieldsArray = Object.entries(resp.data);
        let sysCount = 0;
        this.systemFieldsArray.map((field: any) => {
          if (!field[1].is_default && field[1].on_form) {
            this.system_project_fields.push(this.custom_fields({ ...field[1], label: field[0], data: this.clientProjectData ? this.clientProjectData[field[0]] : "" }));
            if (field[1].is_mandatory) {
              this.system_array(sysCount).controls['data'].setValidators(Validators.required);
              this.system_array(sysCount).controls['data'].updateValueAndValidity();
            }
            sysCount++;
          }
          if (field[0] === 'scheduler_category') {
            this.getAPTCategoriesList();
          } else if (field[0] === 'scheduler') {
            this.getAppointments();
          } else if (field[0] === 'product_category' || field[0] === 'service_category') {
            this.getProductCategory(field[0]);
          } else if (field[0] === 'product' || field[0] === 'service') {
            this.getProducts(field[0]);
          }
        });

        this.customFieldsArray = resp.data.custom_project_templates;
        if (resp.data.custom_project_templates) {
          let count = 0;
          for (const data of resp.data.custom_project_templates) {
            if (data.on_form) {
              this.addfieldsData(data);
              if(data.data_type === 'WEBSITE') {
                this.custom_array(count).controls['data'].setValidators(Validators.pattern(/^(https?:\/\/)?(www\.)?((([a-zA-Z0-9]+\.)+[a-zA-Z]{2,})|localhost)(:[0-9]{1,5})?(\/.*)?$/));
                this.custom_array(count).controls['data'].updateValueAndValidity();
              }
              if (data.is_mandatory) {
                this.custom_array(count).controls['data'].setValidators(Validators.required);
                this.custom_array(count).controls['data'].updateValueAndValidity();
              }
              count++;
            }
          }
        }
        if (this.clientProjectData) {
          this.addFieldValues();
        }
      }
    });
  }

  onCheckRequiredFieldStatus(allFieldData: any) {
    const returnedObj = onCheckRequiredFieldStatus(allFieldData, this.featuresData, this.showErrorMessage);
    this.revokedSysFieldsList = returnedObj.revokedSysFieldsList;
    this.showErrorMessage = returnedObj.showErrorMessage;
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      console.log(this.featuresData);
    });
  }

  get custom_project_fields(): FormArray {
    return this.clientForm.get("custom_project_fields") as FormArray;
  }

  get system_project_fields(): FormArray {
    return this.clientForm.get("system_project_fields") as FormArray;
  }

  addfieldsData(data: any) {
    this.custom_project_fields.push(this.custom_fields(data));
  }

  addFieldValues() {
    const copyCustomFiledArray = this.customFieldsArray.filter((elem: any) => elem.on_form);
    copyCustomFiledArray.forEach(async (field: any, i: any) => {
      const index = this.clientProjectData.custom_project_fields.findIndex(function (item: any) {
        return item.custom_project_template === field.id;
      });
      if (index >= 0) {
        if (this.clientProjectData.custom_project_fields[index].data_type === 'DATE_RANGE') {
          console.log(this.custom_project_fields.controls, this.custom_project_fields.controls[i]);

          this.custom_project_fields.controls[i].patchValue({
            start: this.clientProjectData.custom_project_fields[index].data !== "NA" ? this.clientProjectData.custom_project_fields[index].data.split('&')[0] : "",
            end: this.clientProjectData.custom_project_fields[index].data !== "NA" ? this.clientProjectData.custom_project_fields[index].data.split('&')[1] : "",
            data: this.clientProjectData.custom_project_fields[index].data !== "NA" ? this.clientProjectData.custom_project_fields[index].data : "",
            id: this.clientProjectData.custom_project_fields[index].id
          });
        } else if (this.clientProjectData.custom_project_fields[index].data_type === 'PHONE_NUMBER') {
          this.custom_project_fields.controls[i].patchValue({
            start: this.clientProjectData.custom_project_fields[index].data !== "NA" ? this.clientProjectData.custom_project_fields[index].data?.custom_ccode : "",
            end: this.clientProjectData.custom_project_fields[index].data !== "NA" ? this.clientProjectData.custom_project_fields[index].data.custom_phone_number : "",
            data: this.clientProjectData.custom_project_fields[index].data !== "NA" ? this.clientProjectData.custom_project_fields[index].data : "",
            id: this.clientProjectData.custom_project_fields[index].id
          });
        } else {
          this.custom_project_fields.controls[i].patchValue({
            data: this.clientProjectData.custom_project_fields[index].data !== "NA" ? this.clientProjectData.custom_project_fields[index].data : "",
            id: this.clientProjectData.custom_project_fields[index].id
          });
        }
      }
    });
  }

  custom_fields(data: any): FormGroup {
    return new FormGroup({
      id: new FormControl(""),
      label: new FormControl(data?.label ? data.label : ''),
      data_type: new FormControl(data?.data_type ? data.data_type : ''),
      options: new FormControl(data?.options ? data.options : ''),
      on_form: new FormControl(data?.on_form ? data.on_form : true),
      data: new FormControl(data.data_type !== 'MULTI_SELECT_DROPDOWN' ? (data?.data ? (data.data !== "NA" ? (data.data_type === 'CHECKBOX' || data.data_type === 'MULTI_SELECT_DROPDOWN' ? [] : data.data) : "") : "") : (data ? data.data : [])),
      is_mandatory: new FormControl(data?.is_mandatory ? data.is_mandatory : false),
      is_active: new FormControl(data?.is_active ? data.is_active : true),
      custom_project_template: new FormControl(data?.id ? data.id : ''),
      project: new FormControl(this.clientProjectData?.id),
      organisation: new FormControl(this.loggedInUser.data.organisation),
      start: new FormControl(''),
      end: new FormControl('')
    });
  }

  custom_array(count: number): any {
    return this.custom_project_fields.controls[count] as FormArray;
  }

  system_array(count: number): any {
    return this.system_project_fields.controls[count] as FormArray;
  }

  toggleCheckbox(value: string, i = 0) {
    const selectedOptionsControl = this.custom_array(i).get('data');
    const currentValue = selectedOptionsControl.value;
    if (currentValue.includes(value)) {
      selectedOptionsControl.setValue(currentValue.filter((item: any) => item !== value));
    } else {
      currentValue.push(value);
      selectedOptionsControl.setValue(currentValue);
    }
  }

  getProducts(type: string) {
    this.setupService.getStore().subscribe({
      next: (resp: any) => {
        this.setupService.getDropDownProducts(resp.data.id,type).subscribe({
          next: (products_resp: any) => {
            if(type === 'product') this.products = products_resp.data;
            else this.services = products_resp.data;
            console.log(this.services,"services");
            
          }
        });
      }
    });
  }

  getProductCategory(type: string) {
    this.setupService.getStore().subscribe((result: any) => {
      this.setupService.getProductCategories(result.data.id,type).subscribe((resp: any) => {
        if(type === 'product_category') this.productCategory = resp.data;
        else this.serviceCategory = resp.data;
      });
    });
  }

  getAPTCategoriesList() {
    this.setupService.getAllCategories().subscribe({
      next: (resp: any) => {
        this.aptCategoriesArray = resp.data;
      }
    });
  }

  getAppointments() {
    this.setupService.getAllConsulations().subscribe({
      next: (resp: any) => {
        this.appointmentsArray = resp.data;
      }
    });
  }

  getCustomFieldValidity(i: number) {
    this.clientForm.markAllAsTouched();
    return (<FormArray>this.clientForm.get('custom_project_fields')).controls[i].invalid;
  }

  getSystemFieldValidity(i: number) {
    this.clientForm.markAllAsTouched();
    return (<FormArray>this.clientForm.get('system_project_fields')).controls[i]?.invalid;
  }

  searchClient(term: any) {
    if (term.term.length >= 3) {
      this.clientService.getClientsBySearch(term.term).subscribe({
        next: (resp: any) => {
          this.clients = resp.data;
        }
      });
    } else if (term.term.length === 0) {
      this.getAllClients();
    }
  }

  getClientById(id: any) {
    this.clientService.getClientById(id).subscribe({
      next: (resp: any) => {
        if (resp.data.length > 0) {
          this.clients.push(resp.data[0]);
          this.clients = [...this.clients];
        }
        if (this.client_id) {
          this.clients.push(resp.data);
          this.clients = [...this.clients];
          this.clientForm.patchValue({ client_id: this.client_id, email: resp.data.email, phone: resp.data.phone_number, ccode: resp.data.ccode });
          // this.clientForm.controls["client_id"].disable();
          this.clientForm.controls["email"].disable();
          this.clientForm.controls["phone"].disable();
          this.clientForm.controls["ccode"].disable();
        }
      }
    });
  }

  //custom type File upload function
  onFileUpload(ev: any, index: number) {
    if (!ev.target.files[0]) return;
    if (ev.target.files[0].size > 15 * 1048576) {
      this.toaster.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    this.awsUtilService.getUploadFileLink(ev.target.files[0].type, ev.target.files[0].name.split('.')[1]).subscribe({
      next: (resp) => {
        this.custom_project_fields.controls[index].patchValue({ data: { path: this.presignedurl + resp.data.file_name, file_name: ev.target.files[0].name } });
        this.awsUtilService.uploadFile(ev.target.files[0], resp.data.url).subscribe({
          next: () => {//@typescript-eslint/no-empty-function
          }
        });
      }
    });
  }

  onErrorLoadImage(event: any, target: any, file: any) {
    event.target.src = ErrorLoadImage(target, file);
  }
}

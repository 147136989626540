import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { AiSuggestionModalComponent } from '../ai-suggestion-modal/ai-suggestion-modal.component';
import { ClientsService } from 'src/app/core/service/clients.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {
  @Input() emailData!: any;
  ckconfigQue: any;
  mail_template!: FormGroup;
  submited = false;
  showSchedule = false;
  ccEmailLists: string[] = [];
  bccEmailLists: string[] = [];
  staffMembers: any[] = [];
  allowToAddItem = false;
  sourceDetails: any;
  inputEmailString = '';
  source_email = '';
  connectedMail: any;
  domainsArray: any[] = [];
  selectedDomain: any;
  mailsArray: any[] = [];
  selectedEmails: any;
  showCc = false;
  showBcc = false;
  public Editor: any = ClassicEditor;
  modalRef: any;
  emailAddress: any;

  constructor(private crmService: CrmService, private clientService: ClientsService, public activeModal: NgbActiveModal, private service: SettingsService, private modalService: NgbModal,
    private toastrService: ToastrService
  ) {

  }
  ngOnInit() {
    this.fromAddressGet();
    this.initilizeMailTemplateForm();
    this.selectedDomain = 'workeOrg';
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
    this.getSourceRelatedInfo(this.emailData.source_type);
    this.source_email = this.emailData?.source_email;
    this.crmService.registeredEmailId.subscribe((data: any) => {
      this.source_email = data?.email;
    });
    if (!this.emailData?.source_email) this.getContactById();
    if (this.emailData?.mailType) {
      this.showSchedule = true;
      this.mail_template.get('schedule_mail')?.addValidators([Validators.required]);
      this.mail_template.get('schedule_mail')?.updateValueAndValidity();
    } else {
      this.showSchedule = false;
      this.mail_template.get('schedule_mail')?.clearValidators();
      this.mail_template.get('schedule_mail')?.updateValueAndValidity();
    }
  }
  initilizeMailTemplateForm() {
    this.mail_template = new FormGroup({
      message: new FormGroup({
        to: new FormControl(this.emailData?.source_email ? this.emailData?.source_email : '', Validators.required),
        subject: new FormControl(this.emailData?.subject ? this.emailData?.subject : ''),
        body: new FormControl(this.emailData?.content ? this.emailData?.content : "", Validators.required),
        cc_recipients: new FormControl([]),
        bcc_recipients: new FormControl([]),
        from: new FormControl(this.emailAddress,Validators.required)
      }),
      source_type: new FormControl(this.emailData?.source_type, Validators.required),
      source_id: new FormControl(this.emailData?.source_id, Validators.required),
      schedule_mail: new FormControl(),
      from_text: new FormControl('')
    });
    this.mail_template.get('message.from')?.disable();
  }

  getContactById() {
    this.crmService.getContactById(this.emailData?.contact_id).subscribe((resp: any) => {
      this.source_email = resp.data[0].email;
      (this.mail_template.get('message') as any).controls["to"].setValue(this.source_email);
    });
  }

  getSourceRelatedInfo(type: string) {
    if (type === 'project') {
      this.clientService.getProjectDetailsById(this.emailData.source_id).subscribe((resp: any) => {
        this.sourceDetails = resp.data;
        this.organisationMembersList();
      });
    } else if (type === 'lead') {
      this.crmService.getLeadById(this.emailData.source_id).subscribe({
        next: (resp: any) => {
          this.sourceDetails = resp.data[0];
          this.organisationMembersList();
        }
      });
    } else if (type === 'deal') {
      this.crmService.getCardsById(this.emailData.source_id).subscribe({
        next: (resp: any) => {
          this.sourceDetails = resp.data[0];
          this.organisationMembersList();
        }
      });
    }
  }


  organisationMembersList() {
    this.service.getStaff_info().subscribe({
      next: (resp: any) => {
        console.log(this.sourceDetails);
        this.staffMembers = resp?.data.filter((elem: any) => this.sourceDetails.assignee.includes(elem.id));
      }
    });
  }

  onAddRemoveCcBcc(type?: string) {
    type && (type === 'cc' ? this.showCc = !this.showCc : this.showBcc = !this.showBcc);
    if (this.showBcc) {
      this.mail_template.get('message.bcc_recipients')?.addValidators([Validators.required]);
      this.mail_template.get('message.bcc_recipients')?.updateValueAndValidity();
    } else {
      this.mail_template.get('message.bcc_recipients')?.clearValidators();
      this.mail_template.get('message.bcc_recipients')?.markAsUntouched();
      this.mail_template.get('message.bcc_recipients')?.updateValueAndValidity();
    }
    if (this.showCc) {
      this.mail_template.get('message.cc_recipients')?.addValidators([Validators.required]);
      this.mail_template.get('message.cc_recipients')?.updateValueAndValidity();
    } else {
      this.mail_template.get('message.cc_recipients')?.clearValidators();
      this.mail_template.get('message.cc_recipients')?.markAsUntouched();
      this.mail_template.get('message.cc_recipients')?.updateValueAndValidity();
    }
  }

  onEmailInputChange(event: any) {
    this.inputEmailString = event.target.value;
    const regex = /^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/; //eslint-disable-line 
    if (!regex.test(this.inputEmailString)) {
      this.allowToAddItem = false;
    } else {
      this.allowToAddItem = true;
    }
  }

  onOpenAIModal() {
    this.modalRef = this.modalService.open(AiSuggestionModalComponent, { size: 'lg', scrollable: true, windowClass: 'ai-modal' });
    this.modalRef.result.then((receivedEntry: any) => {
      console.log(receivedEntry);
      if (receivedEntry) {
        (this.mail_template.get('message') as any).controls["body"].setValue(receivedEntry.body);
        (this.mail_template.get('message') as any).controls["subject"].setValue(receivedEntry.subject);
      }
    }).catch((err: any) => {
      console.log(err);
    });
  }

  onMailSubmit(saveType?: string) {
    this.submited = true;
    if (this.mail_template.valid) {
      if(!this.emailAddress){
        this.toastrService.error("You must connect your email address");
        return;
      }
      let content = {};
      content = {
        type: 'sent',
        from_Staff: true
      };
      const payload: any = { ...this.mail_template.value };
      const from = {
        from: this.emailAddress
      };
      // if (this.mail_template.get("message.from")?.status === 'DISABLED') {
      //   payload.message = { ...payload['message'], ...from };
      // } else {
      //   from.from = this.mail_template.get("message.from")?.value;
      payload.message = { ...payload['message'], ...from };
      // }
      // if (saveType === 'draft') {
      //   content = {
      //     type: saveType,
      //     method: 'saved',
      //     from_Staff: true,
      //     from_text: fromText
      //   };
      // } else if (this.emailData?.type === 'draft') {
      //   content = {
      //     type: 'draft',
      //     draft_id: this.emailData?.draftId
      //   };
      // }

      this.crmService.sendCommunicationsMail({ ...payload, ...content }).subscribe((resp: any) => {
        this.activeModal.close('success');
      });
    }
  }

  get f() {
    return (this.mail_template.get('message') as any).controls;
  }

  fromAddressGet() {
    this.service.getOutlook().subscribe((resp: any) => {
      this.emailAddress = resp.data.email;
    });
  }

  onSelectDomainName(event: any) {
    this.selectedDomain = event.value;
    this.selectedEmails = null;
    this.mailsArray = [];
    this.mail_template.get('message.from')?.clearValidators();
    this.mail_template.get('message.from')?.markAsUntouched();
    this.mail_template.get('message.from')?.updateValueAndValidity();
    if (typeof event.value !== 'string') {
      this.getMailsBasedOnDomain();
      this.mail_template.get('message.from')?.enable();
      this.mail_template.get('message.from')?.setValidators([Validators.required]);
      this.mail_template.get('message.from')?.updateValueAndValidity();
      this.mail_template.get('message.from')?.markAsUntouched();
    } else if (event.value === 'GOOGLE') {
      this.mail_template.get('message.from')?.enable();
      this.mailsArray.push(this.connectedMail);
      this.selectedEmails = this.connectedMail;
      this.mail_template.get('from_text')?.setValue('send from connected gmail');
      (this.mail_template.get('message') as any).controls["from"].setValue(this.connectedMail?.email);

    } else if (event.value === 'OUTLOOK') {
      this.mail_template.get('from_text')?.setValue('send from connected outlook');
    } else {
      this.mail_template.get('from_text')?.setValue('');
      this.mail_template.get('message.from')?.disable();
      (this.mail_template.get('message') as any).controls["from"].setValue('');
    }
  }

  onSelectDomainEmails(option: any) {
    (this.mail_template.get('message') as any).controls["from"].setValue(option);
  }

  // getDomains() {
  //   this.service.getDomains().subscribe((domain: any) => {
  //     this.domainsArray = domain.data;
  //   });
  // }
  getMailsBasedOnDomain() {
    this.service.getmails(this.selectedDomain?.id).subscribe((resp: any) => {
      this.mail_template.get('from_text')?.setValue('');
      (this.mail_template.get('message') as any).controls["from"].setValue('');
      this.selectedEmails = null;
      this.mailsArray = resp.data;
    });
  }

  onSaveToDraft() {
    this.onMailSubmit('draft');
  }
}

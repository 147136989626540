import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/service/auth.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { TwilioVoiceService } from 'src/app/core/service/twilio-voice.service';
import { ActivityMarkedModelComponent } from 'src/app/pages/shared/activity-marked-model/activity-marked-model.component';
import { PipelineModalComponent } from 'src/app/pages/shared/pipeline-modal/pipeline-modal.component';
@Component({
  selector: 'app-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.scss']
})
export class DetailsModalComponent implements OnInit, AfterViewInit {
  activeId: any = 1;
  selected_id!: string;
  selected_type!: string;
  featuresData: any;
  featuresType!: string;
  timeline = false;
  task = false;
  notes = false;
  activity = false;
  files = false;
  projects = false;
  contact = false;
  conversations = false;
  emails = false;
  enquiry = false;
  activeTab: any;
  headerHeight = 0;
  tableSkeletonLoader = true;
  display_no_data = false;
  leadsListArray: any;
  originalTableColumnLists: any[] = [];
  tableColumnResponse: any;
  tableColumns: any;
  tableHeader = ['Call Type', 'Duration', 'Purpose', 'Disposition', "Notes", 'Experience'];
  callDetails: any;
  pageEvent!: PageEvent;
  currentPage = 1;
  pageIndex = 0;
  unread_count = 0;
  orderList: any;
  aptList: any;
  pipelineCardsList: any[] = [];
  step: any;
  calendarBookingsList: any;
  pipeline_order_id: any;
  selectedObjectTypeData: any;
  contact_relations: any;

  constructor(private route: ActivatedRoute, private authService: AuthService, private service: CrmService,
    private modalService: NgbModal, private twilioservice: TwilioVoiceService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      this.featuresType = params.showtype;
      this.pipeline_order_id = params?.store_order;
      if (this.pipeline_order_id) {
        this.activeId = 17;
        this.activeTab = 17;
      }
      // this.activeId = 1;
      if (params?.activeId) {
        this.activeTab = +params?.activeId;
        this.activeId = +params?.activeId;
      }
      if (this.selected_type === 'contact') {
        this.getContactById();
      }
    });
    this.getFeatues();
    this.getConversationUnreadCount();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      const headerHieght = document.querySelector('.z-index-header');
      headerHieght && (this.headerHeight = headerHieght.clientHeight - 50);
    });
  }

  tabChange(event: any) {
    this.activeId = event.nextId;
    if (this.activeId === 11) {
      this.getColoumsList();
      this.getLeadsList();
    } else if (this.activeId === 12) {
      this.getCallHistory();
    } else if (this.activeId === 13) {
      this.getOrders();
    } else if (this.activeId === 14) {
      this.getApts();
    } else if (this.activeId === 15) {
      this.getPipleineCards();
    } else if (this.activeId === 16) {
      this.getCalendarBookings();
    } else if(this.activeId === 18) {
      this.getContactRelations();
    }
    // this.router.navigate([],{ relativeTo: this.route,queryParams: { showType: this.activeId } })
  }
  getContactById() {
    this.service.getContactById(this.selected_id).subscribe((con_resp: any) => {
      this.getObjectDetails(con_resp.data[0]?.object_type);
    });
  }
  getObjectDetails(id: string) {
    this.authService.getObjectTypeById(id).subscribe((resp: any) => {
      this.selectedObjectTypeData = resp.data;
      console.log(this.selectedObjectTypeData,"selectedObjectTypeData");
      this.getFeatues();
    });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
      this.pageIndex = e.pageIndex++;
    } else if (this.pageEvent.pageIndex === 0) {
      this.currentPage = 1;
      this.pageIndex = 0;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
      this.pageIndex = e.pageIndex--;
    }
    this.getCallHistory();
  }

  getCallHistory() {
    this.tableHeader = ['Call Type', 'Duration', 'Purpose', 'Disposition', "Notes", 'Experience'];
    this.twilioservice.getContactCallHistory(this.selected_id, this.currentPage).subscribe((resp: any) => {
      this.callDetails = resp.data;
    });
  }
  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      if (this.featuresType === 'deal') {
        this.featuresType = 'pipeline';
      } else if (this.featuresType === 'lead') {
        this.featuresType = 'enquiry';
      }
      if (this.featuresData[this.featuresType + '_timeline']) {
        this.timeline = true;
        this.activeTab === 1 && (this.activeId = this.activeTab);
      }
      if (this.featuresData[this.featuresType + '_task'] && this.featuresData['create_task'] && (this.selected_type === 'contact' ? this.selectedObjectTypeData?.task_board : true)) {
        this.task = true;
        this.activeTab === 2 && (this.activeId = this.activeTab);
      }
      if (this.featuresData[this.featuresType + '_notes'] && (this.selected_type === 'contact' ? this.selectedObjectTypeData?.notes : true)) {
        this.notes = true;
        this.activeTab === 3 && (this.activeId = this.activeTab);
      }
      if (this.featuresData[this.featuresType + '_activities'] && this.featuresData['home_activity'] && (this.selected_type === 'contact' ? this.selectedObjectTypeData?.activities : true)) {
        this.activity = true;
        this.activeTab === 4 && (this.activeId = this.activeTab);
      }
      if (this.featuresData[this.featuresType + '_files'] && (this.selected_type === 'contact' ? this.selectedObjectTypeData?.files : true)) {
        this.files = true;
        this.activeTab === 5 && (this.activeId = this.activeTab);
      }
      if (this.featuresData[this.featuresType + '_projects']) {
        this.projects = true;
        this.activeTab === 7 && (this.activeId = this.activeTab);
      }
      if (this.featuresData[this.featuresType + '_contact']) {
        this.contact = true;
        this.activeTab === 8 && (this.activeId = this.activeTab);
      }
      if (this.featuresData[this.featuresType + '_conversations']) {
        this.conversations = true;
        this.activeTab === 9 && (this.activeId = this.activeTab);
      }
      if (this.featuresData[this.featuresType + '_emails']) {
        this.emails = true;
        (this.activeTab === 10) && (this.activeId = this.activeTab);
      }
      if (this.featuresData[this.featuresType + '_enquiry']) {
        this.enquiry = true;
        (this.activeTab === 11) && (this.activeId = this.activeTab);
      }
      if (this.activeTab !== this.activeId) {
        this.activeId = 1;
        console.log("rams");

      }
    });
  }

  getLeadsList() {
    this.tableSkeletonLoader = true;
    this.service.getLeadsBycontactId(this.selected_id).subscribe({
      next: (resp: any) => {
        this.tableSkeletonLoader = false;
        this.leadsListArray = resp.data;
        if (this.leadsListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      },
      error: () => {
        this.tableSkeletonLoader = false;
      }
    });
  }

  getColoumsList(pipelineId?: string) {
    this.service.getTableColoums(this.activeId === 11 ? 'enquiry' : 'card', '', pipelineId || '').subscribe((resp: any) => {
      this.tableColumnResponse = resp.data;
      this.tableColumns = resp.data.customized_fields;
      this.originalTableColumnLists = JSON.parse(JSON.stringify(this.tableColumns));
    });
  }

  moveLeadTo(body: any, to: string) {
    if (to === 'LOST') {
      const outcome_modal = this.modalService.open(ActivityMarkedModelComponent);
      outcome_modal.componentInstance.type = 'lead';
      outcome_modal.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        if (receivedEntry) {
          console.log(receivedEntry);
          this.service.updateLead({ ...body, ...receivedEntry, type: to, custom_lead_fields: [] }).subscribe(() => {
            // this.getLeadsList('pgn',1, from);
          });
        }
      });
    } else if (to === 'PIPELINE') {
      let modelRef: any;
      // eslint-disable-next-line prefer-const
      modelRef = this.modalService.open(PipelineModalComponent, { size: 'lg', scrollable: true });
      modelRef.componentInstance.type = 'lead';
      modelRef.componentInstance.enquiryData = body;
      // modelRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      //   if (receivedEntry) {
      //     this.getLeadsList();
      //   }
      // });
    } else {
      this.service.updateLead({ ...body, type: to, custom_lead_fields: [] }).subscribe(() => {
        // this.getLeadsList();
      });
    }
  }

  getConversationUnreadCount() {
    this.service.getConversationUnreadCount(this.selected_id).subscribe((resp: any) => {
      this.unread_count = resp?.data?.unreadMessagesCount;
    });
  }

  getRolePermissions(item: any, permission: any) {
    return this.authService.rolefeatureShow(item, permission);
  }

  getOrders() {
    this.tableHeader = ['Order Id', 'Items', 'Status', 'Channel', "Ordered At", 'Price'];
    this.service.getContactOrders(this.selected_id).subscribe((resp: any) => {
      this.orderList = resp.data;
    });
  }

  getApts() {
    this.tableHeader = ['Booking Id', 'Scheduler', 'Type', 'Channel', 'Assigned Staff', "Booked At", 'Scheduled Date', 'Slot', 'Payment Mode'];
    this.service.getAptsForContactId(this.selected_id).subscribe((resp: any) => {
      this.aptList = resp.data;
    });
  }

  getPipleineCards() {
    this.service.getCardsForContactId(this.selected_id).subscribe((resp: any) => {
      this.pipelineCardsList = resp.data;
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  getCalendarBookings() {
    this.tableHeader = ['Booking Id', 'Meeting Name', 'Owner', 'Scheduled Date', 'Slot', "Created on"];
    this.service.getCalendarMettingsForContactId(this.selected_id).subscribe((resp: any) => {
      this.calendarBookingsList = resp.data;
    });
  }

  getContactRelations(){
    this.tableHeader = ['Display Id', 'Label', 'Email', 'Phone Number', 'Object Name', "Data Type"];
    this.service.getContactRelationShips(this.selected_id).subscribe((resp: any) => {
      this.contact_relations = resp.data;
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedRoutingModule } from './shared-routing.module';
import { ContactsModalComponent } from './contacts-modal/contacts-modal.component';
import { MaterialModule } from 'src/app/core/common/material/material.module';
import { NgbDatepickerModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentModalComponent } from './appointment-modal/appointment-modal.component';
import { LeadModalComponent } from './lead-modal/lead-modal.component';
import { CompanyModalComponent } from './company-modal/company-modal.component';
import { PipelineModalComponent } from './pipeline-modal/pipeline-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MobileInputComponent } from './mobile-input/mobile-input.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { BookedappointmentDetailComponent } from './bookedappointment-detail/bookedappointment-detail.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ClientModalComponent } from './client-modal/client-modal.component';
import { ClientProjectModalComponent } from './client-project-modal/client-project-modal.component';
import { ActivityModalComponent } from './activity-modal/activity-modal.component';
import { AddTaskComponent } from './add-task/add-task.component';
import { ManagementModalModule } from '../crm/management-modal/management-modal.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { TaskDetailModalComponent } from './task-detail-modal/task-detail-modal.component';
import { PipesModule } from 'src/app/core/layouts/pipes/pipes.module';
import { PagesModule } from "../pages.module";
import { BadgesComponent } from './badges/badges.component';
import { ActivityMarkedModelComponent } from './activity-marked-model/activity-marked-model.component';
import { PromptNotificationsModalComponent } from './prompt-notifications-modal/prompt-notifications-modal.component';
import { MentionModule } from 'angular-mentions';
import { TaxModalComponent } from './tax-modal/tax-modal.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CustomFiltersComponent } from './custom-filters/custom-filters.component';
import { EmailModalComponent } from './email-modal/email-modal.component';
import { HomeCustomFiltersComponent } from './home-custom-filters/home-custom-filters.component';
import { AiSuggestionModalComponent } from './ai-suggestion-modal/ai-suggestion-modal.component';
import { BulkImportComponent } from './bulk-import/bulk-import.component';
import { ImportedFilesComponent } from './imported-files/imported-files.component';
import { LinkOpenWarningModalComponent } from './link-open-warning-modal/link-open-warning-modal.component';
import { ExportItemSelectionComponent } from './export-item-selection/export-item-selection.component';
import { ObjectModalComponent } from './object-modal/object-modal.component';

@NgModule({
  declarations: [
    ContactsModalComponent,
    AppointmentModalComponent,
    LeadModalComponent,
    CompanyModalComponent,
    PipelineModalComponent,
    MobileInputComponent,
    TaskDetailComponent,
    BookedappointmentDetailComponent,
    OrderDetailComponent,
    ClientModalComponent,
    ClientProjectModalComponent,
    ActivityModalComponent,
    AddTaskComponent,
    TaskDetailModalComponent,
    BadgesComponent,
    ActivityMarkedModelComponent,
    PromptNotificationsModalComponent,
    TaxModalComponent,
    CustomFiltersComponent,
    EmailModalComponent,
    HomeCustomFiltersComponent,
    AiSuggestionModalComponent,
    BulkImportComponent,
    ImportedFilesComponent,
    LinkOpenWarningModalComponent,
    ExportItemSelectionComponent,
    ObjectModalComponent
  ],
  exports: [
    AppointmentModalComponent,
    TaskDetailComponent,
    OrderDetailComponent,
    BookedappointmentDetailComponent,
    TaskDetailModalComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModule,
    FormsModule,
    NgbDatepickerModule,
    NgSelectModule,
    ManagementModalModule,
    NgxDropzoneModule,
    CKEditorModule,
    NgbNavModule,
    MatDatepickerModule,
    PipesModule,
    PagesModule,
    MentionModule
  ]
})
export class SharedModule { }

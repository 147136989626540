<div class="notes-sec d-flex flex-column justify-content-between">
    <div class="custom-table-div-more-height" infinite-scroll [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="1000" (scrolled)="onScroll()">
        <div class="top-add-notes-sec p-3" *ngIf="!showEdit">
            <div class="d-flex align-items-center mb-4">
                <img src="assets/images/newUI/CRM/add_notes_icons.svg" alt="">
                <p class="m-0 ps-2 title-text-clr font-size-16 fw-600">Add Notes</p>
            </div>
            <div class="pt-1">
                <ng-container *ngTemplateOutlet="edit_notes;context:{index:'NA'}"></ng-container>
            </div>
        </div>
        <div *ngIf="notesId"
            class="dummy-search-box d-flex border-radius-12 width-fit-content px-2 py-2 my-3 align-items-center">
            <p class="m-0 me-2">{{'Filtered By: Selected from Notifications'}}</p>
            <button type="button" class="btn-close" aria-label="Close" (click)="clearFilter()"></button>
        </div>
        <ul class="list-group mt-1 border-radius-0">
            <!-- /*    NEW UI MAY-2024    */ -->
            <div class="d-flex align-items-center mt-3 mb-3 px-3">
                <img src="assets/images/newUI/CRM/saved_notes_icons.svg" alt="">
                <p class="m-0 ps-2 title-text-clr font-size-16 fw-600">Saved Notes</p>
            </div>
            <!-- /*    END    */ -->

            <li class="group-item py-2" *ngFor="let note of currentPageNotesList;let i = index">
                <!-- /*    NEW UI MAY-2024    */ -->
                <div class="d-flex align-items-center w-100 px-3">
                    <div class="w-100">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                            <div class="d-flex align-items-center">
                                <div class="name-circle text-capitalize">
                                    <span>{{note.info?.fname[0]}}</span>
                                </div>
                                <p class="m-0 ps-2 font-size-13 fw-500 user-name">
                                    {{note.info?.fname}}
                                    {{note.info?.lname}}
                                </p>
                            </div>
                            <p class="m-0 font-size-12 fw-500" style="color: #8B8B8B;">{{note.customTime}}</p>
                        </div>
                        <div class="p-3 notes-body border-radius-8">
                            <p class="m-0 font-size-13 fw-400  text-break" style="color: #404040;" [id]="i+'pTag'"
                                [innerHTML]="note.notes | safeHtml">
                            </p>
                            <div *ngIf="showEdit && selectedIndex === i">
                                <ng-container *ngTemplateOutlet="edit_notes;context:{index:i}"></ng-container>
                            </div>
                            <div class="notes-control-sec d-flex align-items-center  pt-1"
                                [ngClass]="note.file_name ? 'justify-content-between' : 'justify-content-end'"
                                *ngIf="selectedIndex !== i">
                                <div class="file-section d-flex align-items-center border-radius-8"
                                    *ngIf="note.file_name">
                                    <img src="{{ note.media_url}}"
                                        (error)="onErrorLoadImage($event,presignedurl,note.file_name,note.media_url)"
                                        height="25px" width="25px" *ngIf="note.media_url">
                                    <p class="mb-0 me-2 ms-2 font-size-13 fw-500 label-overflow limit-line-length-1"
                                        style="width:7rem;color : #09090B;">
                                        {{note.file_name}}</p>
                                    <a class="d-flex align-items-center" href="{{note.media_url}}" target="_blank"
                                        download>
                                        <img src="assets/images/newUI/CRM/notes_download_icon.svg" />
                                    </a>
                                </div>
                                <div class="d-flex align-items-center">
                                    <div class="me-3 d-flex align-items-center"
                                        *ngIf="note?.is_editable">
                                        <p class="mb-0 text-inner-info ms-1 font-size-12">Client visibility</p>
                                        <label class="switch ms-2"
                                            [ngStyle]="{'opacity':showEdit ? '0.5' : '1','cursor':showEdit ? 'auto' : 'pointer'}">
                                            <input type="checkbox" [id]="note.id" [disabled]="showEdit"
                                                [(ngModel)]="note.is_visible" (change)="toggleVisibility($event,note)">
                                            <span class="slider round"
                                                [ngStyle]="{'cursor':showEdit ? 'auto' : 'pointer'}"></span>
                                        </label>
                                    </div>
                                    <img src="assets/images/edit_pen.svg" class="p-1 cursor" alt=""
                                        (click)="editNotes(note,i)">
                                    <img src="assets/images/newUI/CRM/delete_icons.svg" class="cursor p-1"
                                        (click)="confirmDelete(note.id)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <div *ngIf="currentPageNotesList && currentPageNotesList.length === 0">

            </div>
        </ul>
    </div>
    <div class="mt-2" *ngIf="!notesId">
        <mat-paginator [length]="notes_total_count" [pageSize]="20" (page)="page($event)" aria-label="Select page"
            showFirstLastButtons>
        </mat-paginator>
    </div>
</div>
<ng-template #edit_notes let-index="index">
    <div class="notes bg-white border-radius-8">
        <div data-text="What's this note about?"
            class="form-control contenteditable-div content-input border-radius-0 m-0 overflow-y font-size-15"
            aria-label="With textarea" [mention]="userLists" [mentionConfig]="mentionConfig"
            (itemSelected)="itemSelected($event)" (click)="expandContent($event)"
            [ngClass]="{'height-class' : contentExpand === true}" contenteditable="true" [id]="index+'myContent'"
            (keyup)="handleInput()"></div>
    </div>
    <p class="fw-400 mb-0 font-size-12 mt-2" style="color: #717179;">Files of JPG, JPEG, PNG, GIF, PDF, DOC, DOCX, TXT,
        MP3
        MP4, or MOV formats with max
        of 15MB are allowed.</p>
    <div class="mt-3 pt-1 notes-input-control" *ngIf="contentExpand">
        <div class="d-flex align-items-center justify-content-between"
            >
            <div class="d-flex align-items-center me-2 border-radius-8 bg-white fw-500 font-size-13 btn-light cursor"
                id="upload-btn" (change)="notesFileUpload($event)">
                <label class="fw-400 cursor d-flex align-items-center" for="attach-file-notes">
                    <mat-icon style="transform: rotate(152deg);" class="me-2">attachment</mat-icon>
                    {{notesJson.attach_file_label}}
                    <input type="file" id="attach-file-notes"
                        accept="image/jpeg,image/jpg,image/png,image/gif,video/mp4,audio/mp3,video/mov,video/quicktime,text/plain,.doc,.docx,.txt,.pdf"
                        hidden /><!--accept="image/*,video/*,audio/*,.pdf,.doc,.docx,.xml,.csv"-->
                </label>
            </div>
            <div class="d-flex align-items-center">
                <div class="d-flex align-items-center cursor border-radius-8 bg-white fw-500 font-size-13 me-2 btn-light"
                    (click)="cancel(index)">
                    <mat-icon>clear</mat-icon>
                    <span class="ms-2">{{notesJson.cancel_btn_label}}</span>
                </div>
                <button class="btn border-radius-8 bg-primary fw-500 font-size-13 text-white btn-primary ms-2"
                    [disabled]="!notesContent" (click)="saveNotes(index)">{{notesJson.save_btn_label}}</button>
            </div>

            <!-- <button class="btn border-0 fw-400 font-size-16" *ngIf="!notesTitleDisplay" (click)="displayNotesTitle()">{{notesJson.title_btn_label}}</button> -->
        </div>
        <!-- <p class="mt-3 font-size-12">Files of JPG, JPEG, PNG, GIF, PDF, DOC, DOCX, TXT, MP3 MP4, or MOV formats with max
      of 15MB are allowed.</p> -->
    </div>
    <div class="mt-3 d-flex align-items-center" *ngIf="fileChosen">
        <img src="{{thumbnail}}" (error)="onErrorLoadImage($event,presignedurl,fileExtensions,thumbnail)" height="50px"
            width="50px">
        <p class="mb-0 me-2 ms-2 font-size-13">{{fileChosen}}</p>
        <div class="progress-bar bg-primary border-radius-8 me-1" role="progressbar" style="width: 50px; height:8px"
            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        <mat-icon (click)="removeFile()">close</mat-icon>
    </div>
</ng-template>




<!-- <div class="notes-sec d-flex flex-column justify-content-between">
    <div class="custom-table-div-more-height" infinite-scroll [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="1000" (scrolled)="onScroll()">
        <div *ngIf="!showEdit" class="mt-3">
            <div class="bg-gray-light border-radius-8 p-3">
                <ng-container *ngTemplateOutlet="edit_notes;context:{index:'NA'}"></ng-container>
            </div>
        </div>
        <div *ngIf="notesId"
            class="dummy-search-box d-flex border-radius-12 width-fit-content px-2 py-2 my-3 align-items-center">
            <p class="m-0 me-2">{{'Filtered By: Selected from Notifications'}}</p>
            <button type="button" class="btn-close" aria-label="Close" (click)="clearFilter()"></button>
        </div>
        <ul class="list-group mt-2">
            <li class="list-group-item" *ngFor="let note of currentPageNotesList;let i = index">
                <div class="row d-flex align-items-center">
                    <div class="col-md-10">
                        <div class="d-flex align-items-center">
                            <h6 class="bg-primary text-white border-radius-50 p-2 me-2 fw-400 font-size-11">
                                {{note.info?.fname[0]}}{{note.info?.lname[0]}}</h6>
                            <h6 class="text-primary fw-600 font-size-16 me-2">{{note.info?.fname}} {{note.info?.lname}}
                            </h6>
                            <h6 class="fw-400 font-size-13">{{note.created_at | dateConversion}}</h6>
                        </div>
                        <div class="ps-4 ms-2">
                            <p class="m-0 font-size-13 text-break" [id]="i+'pTag'" [innerHTML]="note.notes | safeHtml">
                            </p>
                            <div *ngIf="showEdit && selectedIndex === i">
                                <ng-container *ngTemplateOutlet="edit_notes;context:{index:i}"></ng-container>
                            </div>
                            <div class="mt-3 d-flex align-items-center justify-content-around border-1 border-radius-50 px-3 py-1"
                                style="width:12rem;" *ngIf="note.file_name">
                                <img src="{{note.media_url}}"
                                    (error)="onErrorLoadImage($event,presignedurl,note.file_name,note.media_url)" height="25px"
                                    width="25px" *ngIf="note.media_url">
                                <p class="mb-0 me-2 ms-2 font-size-13 label-overflow" style="width:8rem;">
                                    {{note.file_name}}</p>
                                <a class="d-flex align-items-center" href="{{note.media_url}}"
                                    target="_blank" download>
                                    <mat-icon style="color: rgb(0, 0, 0);">file_download_outlined</mat-icon>
                                </a>
                            </div>
                        </div>
                        <div class="mt-4 ps-4 d-flex align-items-center" *ngIf="note?.is_editable">
                            <p class="mb-0 text-inner-info ms-1 font-size-12">Client visibility</p>
                            <label class="switch ms-2" [ngStyle]="{'opacity':showEdit ? '0.5' : '1','cursor':showEdit ? 'auto' : 'pointer'}">
                                <input type="checkbox" [id]="note.id" [disabled]="showEdit" [(ngModel)]="note.is_visible" 
                                (change)="toggleVisibility($event,note)">
                                <span class="slider round" [ngStyle]="{'cursor':showEdit ? 'auto' : 'pointer'}"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-2 visiblity-on-hover" *ngIf="note?.is_editable">
                        <div class="d-flex justify-content-end align-items-center">
                            <div class="bg-white p-2 border-radius-50 d-flex align-items-center"
                                (click)="editNotes(note,i)">
                                <mat-icon style="font-size: 22px;" class="cursor">edit</mat-icon>
                            </div>
                            <mat-icon class="cursor" (click)="confirmDelete(note.id)">delete</mat-icon>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="mt-2" *ngIf="!notesId">
        <mat-paginator [length]="notes_total_count" [pageSize]="20" (page)="page($event)" aria-label="Select page"
            showFirstLastButtons>
        </mat-paginator>
    </div>
</div>
<ng-template #edit_notes let-index="index">
    <div class="notes bg-white border-radius-8">
        <div data-text="What's this note about?"
            class="form-control overflow-y contenteditable-div content-input border-radius-0 m-0 font-size-15"
            aria-label="With textarea" [mention]="userLists" [mentionConfig]="mentionConfig"
            (itemSelected)="itemSelected($event)" (click)="expandContent($event)"
            [ngClass]="{'height-class' : contentExpand === true}" contenteditable="true" [id]="index+'myContent'"
            (keyup)="handleInput()"></div>
    </div>
    <div class="row col-md-8 mt-2" *ngIf="contentExpand">
        <div class="d-flex align-items-center">
            <button class="btn border-radius-50 border-1 bg-primary fw-600 font-size-13 text-white px-4 me-3"
                [disabled]="!notesContent" (click)="saveNotes(index)">{{notesJson.save_btn_label}}</button>
            <button class="btn border-radius-50 border-1 bg-white fw-600 font-size-13 text-primary px-4 me-3"
                (click)="cancel(index)">{{notesJson.cancel_btn_label}}</button>
            <div class="d-flex align-items-center me-2 cursor" id="upload-btn" (change)="notesFileUpload($event)">

                <label class="fw-400 cursor d-flex align-items-center" for="attach-file">
                    <mat-icon style="transform: rotate(152deg);">attachment</mat-icon>
                    {{notesJson.attach_file_label}}
                    <input type="file" id="attach-file" accept="image/jpeg,image/jpg,image/png,image/gif,video/mp4,video/mp3,video/mov,text/plain,.doc,.docx,.txt,.pdf"
                        hidden />
                </label>
            </div>
        </div>
        <p class="mt-3 font-size-12">Files of JPG, JPEG, PNG, GIF, PDF, DOC, DOCX, TXT, MP3 MP4, or MOV formats with max of 15MB are allowed.</p>
    </div>
    <div class="mt-3 d-flex align-items-center" *ngIf="fileChosen">
        <img src="{{thumbnail}}" (error)="onErrorLoadImage($event,presignedurl,fileExtensions,thumbnail)" height="50px"
            width="50px">
        <p class="mb-0 me-2 ms-2 font-size-13">{{fileChosen}}</p>
        <div class="progress-bar bg-primary border-radius-8 me-1" role="progressbar" style="width: 50px; height:8px"
            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        <mat-icon (click)="removeFile()">close</mat-icon>
    </div>
</ng-template> -->
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LEADSJSON } from 'src/app/core/data/leads_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { LeadModalComponent } from '../../shared/lead-modal/lead-modal.component';
import { TaskBoardComponent } from '../../clients/client-managment/task-board/task-board.component';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { SettingsService } from 'src/app/core/service/settings.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CustomFieldsService } from 'src/app/core/service/custom-fields.service';
import { ActivityMarkedModelComponent } from '../../shared/activity-marked-model/activity-marked-model.component';
import { CustomFiltersComponent } from '../../shared/custom-filters/custom-filters.component';
import { RANDOM_CHANNEL_COLORS } from '../../../core/data/colorcode';
import { filterConstant } from 'src/app/core/data/filter-storing-data';
import { AuthService } from 'src/app/core/service/auth.service';
import { PipelineModalComponent } from '../../shared/pipeline-modal/pipeline-modal.component';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ExportItemSelectionComponent } from '../../shared/export-item-selection/export-item-selection.component';
// import { AuthService } from 'src/app/core/service/auth.service';


@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit, AfterViewInit {
  public selection: any = new SelectionModel<any>(true, []);
  modalref!: NgbModalRef;
  previewModal!: NgbModalRef;
  outcome_modal!: NgbModalRef;
  filters_modal!: NgbModalRef;
  leadJson = LEADSJSON[0];
  leftMaxWidth = 250;
  currentPage = 1;
  activeId = 1;
  tableColumns: any[] = [];
  filtersLables = [
    {
      position: 26,
      lableName: 'Lead Id',
      checked: true
    },
    {
      position: 1,
      lableName: 'Lead Title',
      checked: true
    },
    {
      position: 2,
      lableName: 'Next Activity',
      checked: false
    },
    {
      position: 25,
      lableName: 'Contact Name',
      checked: true
    },
    {
      position: 3,
      lableName: 'Email',
      checked: true
    },
    {
      position: 4,
      lableName: 'Phone Number',
      checked: true
    },
    // {
    //   position: 5,
    //   lableName: 'Tag',
    //   checked: true
    // },
    {
      position: 6,
      lableName: 'Channel',
      checked: true
    },
    {
      position: 7,
      lableName: 'Created On',
      checked: true
    },
    // {
    //   position: 8,
    //   lableName: 'Last Modified',
    //   checked: true
    // },
    {
      position: 9,
      lableName: 'Owner',
      checked: true
    },
    {
      position: 24,
      lableName: 'Due Date',
      checked: true
    },
    {
      position: 10,
      lableName: 'Company',
      checked: false
    },
    {
      position: 11,
      lableName: 'Website',
      checked: false
    },
    {
      position: 12,
      lableName: 'Mailing Street',
      checked: false
    },
    {
      position: 13,
      lableName: 'Mailing Address',
      checked: false
    },
    {
      position: 14,
      lableName: 'Mailing City',
      checked: false
    },
    {
      position: 15,
      lableName: 'Mailing Country',
      checked: false
    },
    {
      position: 16,
      lableName: 'Mailing State',
      checked: false
    },
    {
      position: 17,
      lableName: 'Shipping Address',
      checked: false
    },
    {
      position: 18,
      lableName: 'Shipping Street',
      checked: false
    },
    {
      position: 19,
      lableName: 'Shipping City',
      checked: false
    },
    {
      position: 20,
      lableName: 'Shipping Country',
      checked: false
    },
    {
      position: 21,
      lableName: 'Zip Code',
      checked: false
    },
    {
      position: 22,
      lableName: 'Title',
      checked: false
    },
    {
      position: 23,
      lableName: 'Move To',
      checked: true
    },
    {
      position: 24,
      lableName: 'Actions',
      checked: true
    }
  ];
  leadsdropDown = [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Todays',
      value: 'todays'
    },
    {
      label: 'Yesterday',
      value: 'yesterday'
    },
    {
      label: 'Last 7 days',
      value: 'this_week'
    },
    {
      label: 'Iframe',
      value: 'iframe'
    },
    {
      label: 'Chatbot',
      value: 'chatbot'
    },
    {
      label: 'Facebook',
      value: 'facebook'
    },
    {
      label: 'Manual',
      value: 'manual'
    },
    // {
    //   label: 'Forms',
    //   value: 'web_forms'
    // },
    {
      label: 'Web Forms',
      value: 'web_forms'
    },
    {
      label: 'Call',
      value: 'call'
    },
    {
      label: 'Google',
      value: 'google'
    },
    {
      label: 'Instagram',
      value: 'instagram'
    },
    {
      label: 'Whatsapp',
      value: 'whatsapp'
    },
    // {
    //   label: 'Bulk Upload',
    //   value: 'bulk_upload'
    // },
    {
      label: 'Employee Reference',
      value: 'employee_reference'
    },
    {
      label: 'Just Dial',
      value: 'just_dial'
    },
    {
      label: 'Sulekha',
      value: 'sulekha'
    },
    {
      label: 'Classified Listing',
      value: 'classified_listing'
    },
    {
      label: 'Word of Mouth',
      value: 'word_of_mouth'
    },
    {
      label: 'Walk in Customer',
      value: 'walk_in_customer'
    },
    {
      label: 'Preview',
      value: 'preview'
    },
    {
      label: 'Zapier',
      value: 'zapier'
    }
    // {
    //   label: 'Meetings',
    //   value: 'meetings'
    // }
  ];
  filter: any = this.leadsdropDown[0].value;
  selected_filter!: string;
  selected_filter_value: any;
  leadsObj: any = {};
  leadsListArray: any = [];
  pageEvent!: PageEvent;
  search_global!: string;
  page_size = 20;
  loggedInUser: any;
  display_no_data = false;
  staffMembers: any[] = [];
  moved_to: any;
  columnFilters!: FormGroup;
  showExtraFilters = false;
  customFiltersArray: any = [];
  channel_color_codes = RANDOM_CHANNEL_COLORS;
  initial_color_code_index = 0;
  pageIndex = 0;
  sortByDropDown = [
    {
      label: 'Lead Title',
      key: 'requirement_title'

    },
    {
      label: 'Contact Name',
      key: 'contact'

    },
    {
      label: 'Email',
      key: 'email'

    },
    {
      label: 'Due Date',
      key: 'due_date'

    },
    {
      label: 'Created On',
      key: 'created_at'

    }
  ];
  checkBoxStorageKeeper: any = {};
  tableSkeletonLoader = true;
  // @ViewChild('popoverMenu', { static: false }) popoverMenu!: MatMenuTrigger;

  @ViewChild(MatMenuTrigger) popoverMenuTrigger!: MatMenuTrigger;
  @ViewChild(MatMenu) popoverMenu!: MatMenu;
  headerName: any;
  selected_filter_type: any;
  contactListArray: any = [];
  companyListArray: any = [];
  product_categories_array: any = [];
  products_array: any = [];
  listOfTags: any = [];
  departmentsList: any = [];
  column_filters: any[] = [];
  sort_filter: any = {};
  selectedSortOptions: any;
  searchSubject: Subject<string> = new Subject<string>();
  isSelected: any = {
    flag: false,
    pageIndex: [],
    multiple: false
  };
  enquiryType = 'ACTIVE';
  createEnquiry = true;
  featuresData: any;
  enableImportBtn = false;
  selectedFile: any;
  operation = 'CREATE';
  stepper = 1;
  imported_id: any;
  tableColumnResponse: any;
  columnFilterSearch = '';
  columnFieldSearch: any[] = [];
  originalTableColumnLists: any[] = [];
  tableColumnNoResFound = false;
  tagsListArray: any[] = [];

  // featuresData:any;
  constructor(private modalService: NgbModal,
    private service: CrmService,
    private confirmationMsz: ConfirmationMszService,
    private settingsService: SettingsService,
    private custom_fields_service: CustomFieldsService,
    private authService: AuthService,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.searchSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      if (searchTerm.length >= 3) {
        this.filter = this.leadsdropDown[0].value;
        this.selected_filter_value = '';
        this.selected_filter = '';
        if (this.selectedSortOptions || this.column_filters.length > 0) {
          this.selectedSortOptions = null;
          this.column_filters = [];
          // this.filterFormSubmit(payload, 1, 'ACTIVE'); Optional Call
        }
        this.currentPage = 1;
        this.pageIndex = 0;
        this.updateFilterState();
        this.getLeadsBySearch(searchTerm, this.enquiryType);
      } else if (searchTerm.length < 1) {
        this.page_size = 20;
        this.getLeadsList('clear', 1, this.enquiryType);
        this.updateFilterState();
      }
    });
    this.getColoumsList();
  }

  ngAfterViewInit() {
    this.updatingWidth();
  }

  updatingWidth() {
    setTimeout(() => {
      const rightControlsWidth = document.querySelector('.right-controls-sec');
      const leftControlsWidth = document.querySelector(".leftMaxWidth");
      rightControlsWidth && (this.leftMaxWidth = rightControlsWidth.clientWidth);
      leftControlsWidth && (this.renderer.setStyle(leftControlsWidth, `max-width`, `calc(100% - ${this.leftMaxWidth}px)`));
    }, 500);
  }

  ngOnInit() {
    // this.tableColumns = this.filtersLables.filter((elem: any) => elem.checked);
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getFeatues();
    this.organisationMembersList();
    this.getAllLeadsFields();
    if (filterConstant.filterInfoData && filterConstant.filterInfoData.page_name === 'lead') {
      this.updateCurrentPageFiltrData();
    } else {
      this.getLeadsList('clear', 1, 'ACTIVE');
      this.updateFilterState();
    }
    this.getTags();
    this.getColoumsList();
  }

  updateFilterState() {
    filterConstant.filterInfoData = {
      mainFilter: this.column_filters,
      sortValue: this.selectedSortOptions,
      dropdown_filter: this.selected_filter,
      dropdown_filter_value: this.selected_filter_value,
      searchKey: this.search_global,
      page_name: 'lead',
      currentPageIndex: this.currentPage,
      tab: this.enquiryType
    };
  }

  updateCurrentPageFiltrData() {
    this.column_filters = filterConstant.filterInfoData.mainFilter;
    this.selectedSortOptions = filterConstant.filterInfoData.sortValue;
    this.selected_filter = filterConstant.filterInfoData.dropdown_filter;
    this.selected_filter_value = filterConstant.filterInfoData.dropdown_filter_value;
    this.search_global = filterConstant.filterInfoData.searchKey;
    this.currentPage = filterConstant.filterInfoData.currentPageIndex;
    this.pageIndex = filterConstant.filterInfoData.currentPageIndex - 1;
    this.enquiryType = filterConstant.filterInfoData.tab;
    this.filter = this.selected_filter || 'all';
    switch (this.enquiryType) {
      case 'ACTIVE':
        this.activeId = 1;
        break;
      case 'LOST':
        this.activeId = 2;
        break;
      case 'JUNK':
        this.activeId = 3;
        break;
      case 'WON':
        this.activeId = 4;
        break;
      case 'PIPELINE':
        this.activeId = 5;
        break;
      default:
        this.activeId = 1;
        break;
    }
    this.getListsBasedOnFilters(this.enquiryType);
  }

  onFilterChange(e: any) {
    // console.log(e);
    this.search_global = '';
    if (this.selectedSortOptions || this.column_filters.length > 0) {
      this.selectedSortOptions = null;
      this.column_filters = [];
      // const payload = {
      //   filter: [],
      //   sort_by: '',
      //   sort_order: 'desc'
      // };
      // this.filterFormSubmit(payload, 1, 'ACTIVE'); Optional call
    }
    this.filter = e;
    if (this.filter === 'todays') {
      this.selected_filter = 'todays';
      this.selected_filter_value = true;
    } else if (this.filter === 'yesterday') {
      this.selected_filter = 'yesterday';
      this.selected_filter_value = true;
    } else if (this.filter === 'this_week') {
      this.selected_filter = 'weeks';
      this.selected_filter_value = true;
    } else if (this.filter === 'iframe') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'iframe';
    } else if (this.filter === 'forms') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'Form_Builder';
    } else if (this.filter === 'chatbot') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'bot';
    } else if (this.filter === 'manual') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'manual';
    } else if (this.filter === 'facebook') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'facebook';
    } else if (this.filter === 'web_forms') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'web_forms';
    } else if (this.filter === 'call') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'call';
    } else if (this.filter === 'google') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'google';
    } else if (this.filter === 'instagram') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'instagram';
    } else if (this.filter === 'whatsapp') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'whatsapp';
    }
    // else if (this.filter === 'bulk_upload') {
    //   this.selected_filter = 'channel';
    //   this.selected_filter_value = 'bulk_upload';
    // } 
    else if (this.filter === 'employee_reference') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'employee_reference';
    } else if (this.filter === 'just_dial') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'just_dial';
    } else if (this.filter === 'sulekha') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'sulekha';
    } else if (this.filter === 'classified_listing') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'classified_listing';
    } else if (this.filter === 'word_of_mouth') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'word_of_mouth';
    } else if (this.filter === 'walk_in_customer') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'walk_in_customer';
    } else if (this.filter === 'preview') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'preview';
    }
    // else if (this.filter === 'meetings') {
    //   this.selected_filter = 'channel';
    //   this.selected_filter_value = 'meeting';
    // } 
    else {
      this.selected_filter = '';
      this.selected_filter_value = '';
    }
    this.updateFilterState();
    // console.log(this.selected_filter, this.selected_filter_value);
    this.getLeadsList('clear', 1, this.enquiryType, this.selected_filter, this.selected_filter_value);
  }

  tabChange(event: any) {
    this.currentPage = 1;
    this.pageIndex = 0;
    this.filter = this.leadsdropDown[0].value;
    this.selected_filter_value = '';
    this.selected_filter = '';
    this.search_global = '';
    this.clearSelection();
    if (this.selectedSortOptions || this.column_filters.length > 0) {
      this.selectedSortOptions = null;
      this.column_filters = [];
      // this.filterFormSubmit(payload, 1, 'ACTIVE'); Optional Call
    }
    this.activeId = event.nextId;
    if (this.activeId === 1) {
      this.enquiryType = 'ACTIVE';
      this.getLeadsList('clear', 1, 'ACTIVE', this.selected_filter, this.selected_filter_value);
    } else if (this.activeId === 2) {
      this.enquiryType = 'LOST';
      this.getLeadsList('clear', 1, 'LOST');
    } else if (this.activeId === 3) {
      this.enquiryType = 'JUNK';
      this.getLeadsList('clear', 1, 'JUNK');
    } else if (this.activeId === 4) {
      this.enquiryType = 'WON';
      this.getLeadsList('clear', 1, 'WON');
    } else {
      this.enquiryType = 'PIPELINE';
      this.getLeadsList('clear', 1, 'PIPELINE');
    }
    this.updateFilterState();
    this.updatingWidth();
  }


  getLeadsList(pageIndexClear: string, page: any, lead_type: any, filter_param?: string, filter?: any) {
    this.tableSkeletonLoader = true;
    this.service.getAllLeads(page, lead_type, filter_param, filter).subscribe({
      next: (resp: any) => {
        this.tableSkeletonLoader = false;
        if (pageIndexClear === 'clear') {
          this.pageIndex = 0;
          this.currentPage = 1;
          // this.clearSelection();
        }
        // this.selection.clear();
        this.leadsObj = resp;
        this.leadsListArray = resp.data;
        // if (this.isSelected.flag === true && this.getPageIndexValues() > -1) {
        this.masterToggle();
        // }
        if (this.leadsListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      },
      error: () => {
        this.selectedSortOptions = null;
        this.column_filters = [];
        this.search_global = '';
        this.selected_filter_value = '';
        this.selected_filter = '';
        this.tableSkeletonLoader = false;
      }
    });
  }

  page(e: PageEvent, lead_type: string) {
    this.pageEvent = e;
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
      this.pageIndex = e.pageIndex++;
    } else if (this.pageEvent.pageIndex === 0) {
      this.currentPage = 1;
      this.pageIndex = 0;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
      this.pageIndex = e.pageIndex--;
    }
    this.updateFilterState();
    this.getListsBasedOnFilters(lead_type);

    // if (Object.keys(this.column_filters).length > 0) {
    //   this.filterFormSubmit(this.column_filters, this.currentPage);
    // } else {
    //   this.getLeadsList(this.currentPage, lead_type, this.selected_filter, this.selected_filter_value);
    // }
  }

  removeLead(leadId: string, lead_type: string) {
    this.service.deleteLead(leadId).subscribe({
      next: () => {
        this.getListsBasedOnFilters(lead_type);
        // this.getLeadsList('pgn', 1, lead_type, this.selected_filter, this.selected_filter_value);
      }
    });
  }

  confirmDelete(leadId: string, lead_type: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.removeLead(leadId, lead_type);
      }
    });
  }

  getListsBasedOnFilters(lead_type: string) {
    if (this.search_global?.length >= 3) {
      this.getLeadsBySearch(this.search_global, lead_type);
    } else if (Object.keys(this.column_filters).length > 0 || this.selectedSortOptions?.sort_order) {
      const payload = {
        filter: this.column_filters,
        sort_by: this.selectedSortOptions?.sort_by,
        sort_order: this.selectedSortOptions?.sort_order
      };
      this.filterFormSubmit(payload, this.currentPage, lead_type);
    } else if (this.selected_filter && this.selected_filter_value) {
      this.getLeadsList('pgn', this.currentPage, lead_type, this.selected_filter, this.selected_filter_value);
    } else {
      this.getLeadsList('pgn', this.currentPage, lead_type);
    }
  }

  moveLeadTo(body: any, to: string, from: string) {
    if (to === 'LOST') {
      this.outcome_modal = this.modalService.open(ActivityMarkedModelComponent);
      this.outcome_modal.componentInstance.type = 'lead';
      this.outcome_modal.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        if (receivedEntry) {
          console.log(receivedEntry);
          this.service.updateLead({ id: body.id, ...receivedEntry, type: to }).subscribe(() => {
            this.getListsBasedOnFilters(from);
            // this.getLeadsList('pgn',1, from);
          });
        }
      });
    } else if (to === 'PIPELINE') {
      let modelRef: any;
      // eslint-disable-next-line prefer-const
      modelRef = this.modalService.open(PipelineModalComponent, { size: 'lg', scrollable: true });
      modelRef.componentInstance.type = 'lead';
      modelRef.componentInstance.enquiryData = body;
      modelRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        if (receivedEntry) {
          this.getListsBasedOnFilters(from);
          // this.service.updateLead({ ...body, type: to, custom_lead_fields: [] }).subscribe(() => {
          //   this.getListsBasedOnFilters(from);
          // });
        }
      });
    } else {
      this.service.updateLead({ id: body.id, type: to }).subscribe(() => {
        this.getListsBasedOnFilters(from);
        // this.getLeadsList('pgn',1, from);
      });
    }
  }

  // selectFilterLable(event: any) {
  //   event.stopPropagation();
  // $event.preventDefault();
  // if ($event.target) {
  //   $event.target.classList.toggle('selected');
  // }
  // }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.leadsListArray.length;
    return numSelected === numRows;
  }

  masterToggle(currentPage?: number | string, event?: any) {
    const masterIndex = this.isSelected.pageIndex.findIndex((elem: any) => elem.page === currentPage);
    if ((event && !event.checked)) {
      // if (this.isSelected.pageIndex.length > 1 && currentPage) {
      //   if (index > -1) {
      this.leadsListArray.forEach((row: any) => {
        delete this.checkBoxStorageKeeper[row.id];
      });
      this.selection.clear();
      (masterIndex > -1) && this.isSelected.pageIndex.splice(masterIndex, 1);
      //   }
      // } else {
      //   // this.clearSelection();
      //   this.checkBoxStorageKeeper = {};
      // }
    } else if (typeof currentPage === 'number' && event && event.checked) {
      // this.selection.clear();
      this.leadsListArray.forEach((row: any) => {
        const exsistObj = {
          [row.id]: row
        };
        Object.assign(this.checkBoxStorageKeeper, exsistObj);
        this.selection.select(row);
      });
      const index = this.isSelected.pageIndex.findIndex((elem: any) => elem.page === currentPage);
      if (index > -1) {
        this.isSelected.pageIndex[index].qnty = this.leadsListArray?.length;
      } else {
        this.isSelected.pageIndex.push({ page: currentPage, qnty: this.leadsListArray?.length });
        this.isSelected.flag = true;
      }

    }
    else {
      // this.selection.clear();
      this.leadsListArray.forEach((row: any) => {
        if (Object.prototype.hasOwnProperty.call(this.checkBoxStorageKeeper, row.id)) {
          this.selection.select(row);
        }
      });
    }
    this.updateIsSelectedMultipleKey();
  }

  individualRecordToogle(item: any) {
    const exsistObj = {
      [item.id]: item
    };
    if (Object.prototype.hasOwnProperty.call(this.checkBoxStorageKeeper, item.id)) {
      delete this.checkBoxStorageKeeper[item.id];
      const index = this.getPageIndexValues();
      if (index > -1) {
        this.isSelected.pageIndex[index].qnty -= 1;
        if (this.isSelected.pageIndex[index].qnty <= 0) {
          this.isSelected.pageIndex.splice(index, 1);
        }
      }
      if (this.isSelected.pageIndex.length === 0) {
        this.isSelected.flag = false;
      }
    } else {
      Object.assign(this.checkBoxStorageKeeper, exsistObj);
      const index = this.getPageIndexValues();
      if (index > -1) {
        this.isSelected.pageIndex[index].qnty += 1;
        if (this.isSelected.pageIndex[index].qnty <= 0) {
          this.isSelected.pageIndex.splice(index, 1);
        }
        if (this.isSelected.pageIndex.length === 0) {
          this.isSelected.flag = false;
        }
      } else {
        this.isSelected.flag = true;
        this.isSelected.pageIndex.push({ page: this.currentPage, qnty: 1 });
      }
    }
    this.updateIsSelectedMultipleKey();
  }

  updateIsSelectedMultipleKey() {
    (Object.keys(this.checkBoxStorageKeeper).length > 1) ? (this.isSelected.multiple = true) : (this.isSelected.multiple = false);
  }

  clearSelection() {
    this.selection.clear();
    this.isSelected.flag = false;
    this.isSelected.pageIndex = [];
    this.isSelected.multiple = false;
    this.checkBoxStorageKeeper = {};
  }

  addLeadModal(lead?: any) {
    this.modalref = this.modalService.open(LeadModalComponent, { size: 'lg', scrollable: true });
    this.modalref.componentInstance.lead = lead;
    this.modalref.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.clearSelection();
        if (lead) {
          this.getListsBasedOnFilters(this.enquiryType);
        } else {
          this.getLeadsList('clear', 1, 'ACTIVE');
          this.currentPage = 1;
          this.pageIndex = 0;
        }
      }
    });
  }

  openTaskBoardPreview() {
    this.previewModal = this.modalService.open(TaskBoardComponent, { size: 'lg', scrollable: true });
    this.previewModal.componentInstance.crm_type = 'lead';
  }

  // search(name: any) {
  //   if (name.length >= 3) {
  //     this.currentPage = 1;
  //     this.pageIndex = 0;
  //     this.getLeadsBySearch(name);
  //   } else if (name.length < 1) {
  //     this.page_size = 20;
  //     this.getLeadsList('clear', 1, 'ACTIVE');
  //   }
  // }
  getLeadsBySearch(name: string, lead_type: string) {
    this.tableSkeletonLoader = true;
    this.service.getLeadsBySearch(name, this.currentPage, lead_type).subscribe({
      next: (resp: any) => {
        this.leadsObj = resp;
        // this.selection.clear();
        this.tableSkeletonLoader = false;
        // this.page_size = this.leadsObj.total;
        this.leadsListArray = resp.data;

        // if (this.isSelected.flag === true && this.getPageIndexValues() > -1) {
        this.masterToggle();
        // }
        if (this.leadsListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      },
      error: () => {
        this.selectedSortOptions = null;
        this.column_filters = [];
        this.search_global = '';
        this.selected_filter_value = '';
        this.selected_filter = '';
        this.getLeadsList('clear', 1, this.enquiryType);
      }
    });
  }

  getPageIndexValues() {
    return this.isSelected.pageIndex.findIndex((elem: any) => elem.page === this.currentPage);
  }

  downloadEnquiries() {
    let count = this.leadsObj.total;
    const selectedCheckbox = Object.keys(this.checkBoxStorageKeeper).length;
    if (this.checkBoxStorageKeeper && selectedCheckbox > 0) {
      count = selectedCheckbox;
    }
    const modalRef = this.modalService.open(ExportItemSelectionComponent, { size: 'md', centered: true });
    modalRef.componentInstance.payload = {
      type: 'lead',
      data: this.tableColumns,
      disabledItems: ['enquiry_id', 'requirement_title', 'email', 'tags'],
      checkBoxStorageKeeper: this.checkBoxStorageKeeper,
      message: `<b class='blue-class'>${count}</b> ${count < 2 ? 'Enquiry' : 'Enquiries'} will be exported`
    };
    const outputContacts: any = [];
    // if (this.isSelected.flag === false) {
    //   this.service.getAllLeadForExport().subscribe((resp: any) => {
    //     resp.data.forEach((item: any) => {
    //       outputContacts.push({
    //         "Display Id": item.display_id,
    //         "Name": item.requirement_title,
    //         "Email": item.email,
    //         "Phone": item.phone_number,
    //         // "Tags": item.tags,
    //         "Channel": item.channel,
    //         "Date & Time": item.created_at ? this.formatDate(item.created_at) : 'null',
    //         "Owner": item?.owner_info?.fname + " " + item?.owner_info?.lname,
    //         "Due Date": item.due_date ? this.formatDate(item.due_date) : 'null'
    //       });
    //     });
    //     this.service.jsonToCsv(outputContacts, "Leads");
    //   });
    // } else {
    //   Object.values(this.checkBoxStorageKeeper).forEach((item: any) => {
    //     outputContacts.push({
    //       "Name": item.requirement_title,
    //       "Email": item.email,
    //       "Phone": item.phone_number,
    //       "Tags": item.tags,
    //       "Channel": item.channel,
    //       "Date & Time": item.created_at ? this.formatDate(item.created_at) : 'null',
    //       "Owner": item?.info?.owner_info?.fname + " " + item?.info?.owner_info?.lname,
    //       "Due Date": item.due_date ? this.formatDate(item.due_date) : 'null'
    //     });
    //   });
    //   this.service.jsonToCsv(outputContacts, "Leads");
    // }
  }

  formatDate(dateStr: string) {
    const dateString = dateStr;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  onEditClicked(events: any, item: any) {
    events.stopPropagation();
    alert('Edit clicked');
  }

  getTags() {
    this.settingsService.getTags('lead').subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }

  onUpdatePopOver(event: any, Id: any) {
    console.log(event);
    console.log(Id);

    const input: any = {
      id: Id,
      custom_lead_fields: []
    };

    if (event.type === 'dropdown' && event.labelName === 'Owner') {
      input.info = {
        "owner_info": {
          "id": event.value.id,
          "fname": event.value.fname,
          "lname": event.value.lname
        }
      };
      input.owner = event.value.id;
      input.owner_department = event.value.department_info?.id ? event.value.department_info?.id : '';

      // this.staffMembers.forEach((staff: any) => {
      //   if (staff.id === data.value.id) input.owner_department = staff.department_info.id;
      // });
    } else if (event.labelName === 'Tag') {
      input[event.formName] = event.value.id;
    } else
      input[event.formName] = event.value;

    this.service.updateLead(input).subscribe(() => {
      // this.getLeadsList(1, 'ACTIVE', this.selected_filter, this.selected_filter_value);
      if (this.activeId === 1) {
        this.getLeadsList('pgn', this.currentPage, 'ACTIVE', this.selected_filter, this.selected_filter_value);
      } else if (this.activeId === 2) {
        this.getLeadsList('pgn', this.currentPage, 'LOST');
      } else if (this.activeId === 3) {
        this.getLeadsList('pgn', this.currentPage, 'JUNK');
      } else if (this.activeId === 4) {
        this.getLeadsList('pgn', this.currentPage, 'WON');
      } else {
        this.getLeadsList('pgn', this.currentPage, 'PIPELINE');
      }
    });
  }

  getAllLeadsFields() {
    this.customFiltersArray = [];
    this.custom_fields_service.getEnquiryModelFields().subscribe({
      next: (resp: any) => {
        for (const [key, value] of Object.entries(resp.data)) {
          let formatted_obj: any = {};
          let replaced_text: any;
          if (key === 'custom_lead_templates') {
            const custom_fields_list: any = value;
            custom_fields_list.forEach((element: any) => {
              formatted_obj = {};
              formatted_obj['id'] = element.id;
              formatted_obj['display_label'] = element.label;
              formatted_obj['custom_input'] = true;
              formatted_obj['data_type'] = element.data_type;
              formatted_obj['is_mandatory'] = element.is_mandatory;
              formatted_obj['options'] = element.options;
              if (element?.on_form) {
                this.customFiltersArray.push(formatted_obj);
              }
            });
          } else if (key === 'id' || key === 'organisation' || key === 'lead_fields_order') {
            console.log("");

          } else {
            const system_fields_value: any = value;
            if (key.includes('_')) {
              replaced_text = key.replace('_', " ");
            } else {
              replaced_text = key;
            }
            formatted_obj['id'] = key;
            formatted_obj['is_mandatory'] = system_fields_value.is_mandatory;
            formatted_obj['display_label'] = replaced_text;
            formatted_obj['custom_input'] = false;
            formatted_obj['data_type'] = system_fields_value.data_type;
            if (system_fields_value?.on_form || key === 'due_date') {
              this.customFiltersArray.push(formatted_obj);
            }
          }

        }
        const created_at = {
          id: 'created_at',
          is_mandatory: true,
          display_label: 'Created at',
          custom_input: false,
          data_type: 'DATE_TIME'
        };
        const updated_at = {
          id: 'updated_at',
          is_mandatory: true,
          display_label: 'Updated at',
          custom_input: false,
          data_type: 'DATE_TIME'
        };
        this.customFiltersArray.push(created_at);
        this.customFiltersArray.push(updated_at);
      }
    });
  }


  forCustomFieldStatus(field: any, index: number) {
    console.log(field, index);
    this.filter_of_column.controls[index].patchValue({
      custom_field: field.custom_input
    });
    if (field.id === 'owner' || field.id === 'assignee') {
      this.organisationMembersList();
    } else if (field.id === 'tags') {
      this.getTagsOfEnquiry();
    } else if (field.id === 'assignee_department') {
      this.getDepartmentsList();
    }
  }

  initilizeFiltersForm() {
    this.columnFilters = new FormGroup({
      filter: new FormArray([]),
      sort_by: new FormControl(''),
      sort_order: new FormControl('desend')
    });
  }

  get filter_of_column() {
    return this.columnFilters.controls["filter"] as FormArray;
  }

  filtersJsonForm() {
    const filter_form: any = new FormGroup({
      column: new FormControl(''),
      data: new FormControl(''),
      operation: new FormControl('CREATE'),
      custom_field: new FormControl(''),
      search: new FormControl('')
    });

    this.filter_of_column.push(filter_form);
  }

  deleteFilter(index: number) {
    this.filter_of_column.removeAt(index);
  }

  filterFormSubmit(filters_data: any, page: number, lead_type: string) {
    this.tableSkeletonLoader = true;
    this.service.getLeadsCustomFilteredData(filters_data, page ? page : 1, lead_type).subscribe({
      next: (resp: any) => {
        this.tableSkeletonLoader = false;
        this.filter = this.leadsdropDown[0].value;
        // this.selection.clear();
        this.leadsObj = resp;
        this.leadsListArray = resp.data;
        // if (this.isSelected.flag === true && this.getPageIndexValues() > -1) {
        this.masterToggle();
        // }
        if (this.leadsListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      },
      error: () => {
        this.selectedSortOptions = null;
        this.column_filters = [];
        this.search_global = '';
        this.selected_filter_value = '';
        this.selected_filter = '';
        this.getLeadsList('clear', 1, this.enquiryType);
      }
    });
  }

  getFilters() {
    this.showExtraFilters = true;
    this.getAllLeadsFields();
    this.initilizeFiltersForm();
    this.filtersJsonForm();
  }

  resetFilters() {
    this.columnFilters.reset();
    this.initilizeFiltersForm();
    this.filtersJsonForm();
    this.getLeadsList('clear', 1, this.enquiryType);
  }

  clearCustomFilter() {
    this.column_filters = [];
    this.selectedSortOptions = null;
    this.updateFilterState();
    this.getLeadsList('clear', 1, this.enquiryType);
  }

  searchTriggerConatct(change: any) {
    if (change.term.length >= 3) {
      this.service.getContactsBySearch(change.term).subscribe({
        next: (resp: any) => {
          this.contactListArray = resp.data;
        }
      });
    }
  }

  searchTriggerCompany(change: any) {
    if (change.term.length >= 3) {
      this.service.getCompaniesBySearch(change.term).subscribe({
        next: (resp: any) => {
          this.companyListArray = resp.data;
        }
      });
    }
  }

  getTagsOfEnquiry() {
    this.settingsService.getTags('lead').subscribe({
      next: (resp: any) => {
        this.listOfTags = resp.data;
      }
    });
  }

  getDepartmentsList() {
    this.settingsService.getTeams(false).subscribe((resp: any) => {
      this.departmentsList = resp.data;
    });
  }

  openFiltersModal(type: string) {

    if (type === 'sort') {
      this.filters_modal = this.modalService.open(CustomFiltersComponent, { size: 'md', centered: true });
    } else {
      this.filters_modal = this.modalService.open(CustomFiltersComponent, { size: 'lg', scrollable: true, centered: true });
    }
    this.filters_modal.componentInstance.filters_custm = this.column_filters;
    this.filters_modal.componentInstance.filterType = type;
    if (this.selectedSortOptions) {
      this.filters_modal.componentInstance.selectedSortOptions = this.selectedSortOptions;
    }
    this.filters_modal.componentInstance.sortByOptions = this.sortByDropDown;
    this.filters_modal.componentInstance.customFiltersArray = this.customFiltersArray;
    this.filters_modal.componentInstance.is_filter_for = 'lead';
    this.filters_modal.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.search_global = '';
        if (receivedEntry.from === 'sort') {
          if (receivedEntry.type === 'save') {
            this.selectedSortOptions = receivedEntry.data.sorting;
          } else {
            this.selectedSortOptions = null;
          }
        } else {
          if (receivedEntry.type === 'save') {
            this.column_filters = receivedEntry.data.filter;
          } else {
            this.column_filters = [];
          }
        }
        this.updateFilterState();
        const payload = {
          filter: receivedEntry.data.filter,
          sort_by: receivedEntry.data?.sorting?.sort_by,
          sort_order: receivedEntry.data?.sorting?.sort_order
        };
        this.pageIndex = 0;
        this.currentPage = 1;
        // this.clearSelection();
        this.filterFormSubmit(payload, 1, this.enquiryType);
      }
    });
  }
  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      console.log(this.featuresData);
    });
  }

  importModalDialog(importExportModel: any) {
    this.modalService.open(importExportModel, { size: 'md', backdrop: 'static', scrollable: true });
  }

  importLeads() {
    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    this.service.postCSVFile(formData).subscribe((resp: any) => {
      // this.stepper = 2;
      this.imported_id = resp.data.id;
      this.fieldsMapping();
    });
  }

  onFileChange(event: any) {
    this.enableImportBtn = true;
    this.selectedFile = event.target.files[0];
  }

  closeImportModal() {
    this.modalService.dismissAll('cross click');
    this.stepper = 1;
  }

  fieldsMapping() {
    this.service.updateCSVFile({ id: this.imported_id, operation: 'CREATE', type: 'enquiry', is_active: true }).subscribe(() => {
      this.modalService.dismissAll('cross click');
      this.router.navigate(['/crm/import'], { queryParams: { module: 'enquiry', id: this.imported_id } });
    });
  }

  downloadSampleFile() {
    this.service.downloadSample('leads');
  }
  // getFeatues() {
  //   this.authService.getFeaturesManagement().subscribe((data: any) => {
  //     this.featuresData = data?.data;
  //     console.log(this.featuresData);
  //   });
  // }

  // pickColor() {
  //   let picked_out_color:any = '';
  //   this.initial_color_code_index += 1;
  //   if (this.initial_color_code_index === 10) this.initial_color_code_index = 0;
  //   picked_out_color = this.channel_color_codes[this.initial_color_code_index]?.color;
  //   console.log(this.initial_color_code_index, picked_out_color);

  //   return picked_out_color;
  // }
  getColoumsList() {
    this.service.getTableColoums('enquiry').subscribe((resp: any) => {
      this.tableColumnResponse = resp.data;
      this.tableColumns = resp.data.customized_fields;
      this.originalTableColumnLists = JSON.parse(JSON.stringify(this.tableColumns));
    });
  }
  onUpdateTableColumns() {
    const payload = JSON.parse(JSON.stringify(this.tableColumnResponse));
    Object.assign(payload.customized_fields, this.originalTableColumnLists);
    this.service.updateTableColumn(payload).subscribe((data: any) => {
      console.log(data);
    });
  }

  selectFilterLable(event: any, item: any, index: number) {
    // if (!['enquiry_id', 'requirement_title', 'email', 'tags'].includes(item?.field_name)) {
    const obj = JSON.parse(JSON.stringify(item));
    obj.status = event.checked;
    this.tableColumns[index] = obj;
    this.originalTableColumnLists[index] = obj;
    // }
    // Object.assign(this.originalTableColumnLists, { [item.key]: event.checked });
    // Object.assign(this.tableColumns, { [item.key]: event.checked });
  }

  onColumnFilterSearch(event: any) {
    if (this.columnFilterSearch.length > 1) {
      const searchResult: any[] = [];
      this.tableColumns = this.originalTableColumnLists.filter((item: any) => (item.field_name.split('_').join(" ").toLocaleLowerCase()).includes(this.columnFilterSearch.toLocaleLowerCase()));
      if (this.tableColumns && this.tableColumns.length > 0) {
        this.tableColumnNoResFound = false;
        this.columnFieldSearch = [];

        searchResult.forEach((elem: any) => {
          Object.assign(this.columnFieldSearch, elem);
        });
      } else {
        this.tableColumnNoResFound = true;
        this.columnFieldSearch = [];
        this.tableColumns = [];
      }
      // Object.assign(this.tableColumns,this.columnFieldSearch);
      // this.tableColumns = JSON.parse(JSON.stringify(this.columnFieldSearch));
    } else {
      this.tableColumns = JSON.parse(JSON.stringify(this.originalTableColumnLists));
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    // if (event.currentIndex > 3) {
    moveItemInArray(this.tableColumns, event.previousIndex, event.currentIndex);
    moveItemInArray(this.originalTableColumnLists, event.previousIndex, event.currentIndex);
    // }

  }

  getRolePermissions(item: any,permission: any) {
    return this.authService.rolefeatureShow(item,permission);
  }
}

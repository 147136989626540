<div class="d-flex editableData" 
    [ngClass]="(inputType==='textarea' || inputType==='TEXT_AREA') ? 'align-items-start text-break limit-line-length-2' : 'align-items-center'">
    <div class="flex-grow-1" style="max-width: 80%" [ngClass]="inputType ==='textarea' || inputType==='TEXT_AREA' ? 'limit-line-length-2' : 'limit-line-length-1'">
        <span
            *ngIf="inputType==='text' || inputType==='TEXT' || inputType ==='textarea' || inputType==='TEXT_AREA' || inputType === 'NUMBER' || inputType === 'EMAIL' || inputType === 'WEBSITE'"
            [innerHTML]="inputValue ? inputValue : ''"></span>
        <span *ngIf="inputType==='date' || inputType==='DATE'">{{inputValue && inputValue!== '-' ?
            (inputValue | dateConversion) : ""}}</span>
        <span *ngIf="inputType ==='dateTime' || inputType === 'DATE_TIME'">{{inputValue && inputValue!== '-' ?
            (inputValue | dateConversion : 'dateTime') : ""}}</span>
        <!-- <span *ngIf="inputType==='dateTime'">{{inputValue ? (inputValue | dateConversion) : "-" }}</span> -->
        <span *ngIf="inputType==='dropdown' || inputType==='SELECT_DROPDOWN' || inputType==='MULTI_SELECT_DROPDOWN' || inputType==='DATE_RANGE'">{{inputValue ?
            inputValue : "" }}</span>
        <span *ngIf="inputType==='multiselect' && inputValue==='-'">
            <span> </span>
        </span>
        <span *ngIf="inputType==='multiselect' && inputValue!=='-'">
            <div *ngFor="let item of inputValue">
                <span class="me-1" *ngIf="labelName === 'Staff' || labelName === 'Assignee'">{{item.fname}}
                    {{item.lname}}</span>
                <span class="me-1" *ngIf="labelName === 'Products'">{{item.name}}</span>
            </div>
            <!-- <p *ngFor="let item of inputValue">
                <span class="me-1">{{item.name}}</span>
            </p> -->
        </span>
        <span *ngIf="inputType==='RADIO_BUTTON'">{{
            inputValue ? inputValue : "" }}</span>
        <span *ngIf="inputType==='FILE'">
            <img src="{{inputValue?.path}}" alt="" width="150px" class="mb-2">
            <a href="{{inputValue?.path}}" target="_blank" class="ms-2 text-dark fw-medium mt-3" download>{{inputValue ?
                inputValue?.file_name : ""}}</a>
        </span>
        <span *ngIf="inputType === 'PHONE_NUMBER'">{{cCode}} {{modal}}</span>
    </div>
    <div class="a ps-2" *ngIf="is_active" (click)="$event.stopPropagation()">
        <img src="assets/images/edit_pen.svg" width="20" height="20"
            style="width: 20px !important; height: 20px !important;"
            class="edit-icon rounded-top rounded-bottom font-size-12" alt="" *ngIf="editEnable"
            [matMenuTriggerFor]="popoverMenu">
        <!-- <mat-icon *ngIf="editEnable" class="edit-icon rounded-top rounded-bottom font-size-12" mat-button
            [matMenuTriggerFor]="popoverMenu">edit</mat-icon> -->

        <!-- <mat-menu #popoverMenu="matMenu" xPosition="after" yPosition="below"
        [overlapTrigger]="false" [backdropClass]="'custom-popover-backdrop'"> -->

        <mat-menu #popoverMenu="matMenu" [hasBackdrop]="true" [backdropClass]="'custom-popover-backdrop'"
            style="width: 300px;">
            <div class="d-flex flex-column">
                <div class="p-2 align-self-center">
                    {{labelName.replace("_", " ") | titlecase}}
                </div>
                <div class="p-3 bd-highlight border-top border-bottom" style="width: 280px;"
                    (click)="$event.stopPropagation()">
                    <ng-container *ngIf="!cCode">
                        <input *ngIf="inputType==='text' || inputType==='TEXT' || inputType === 'EMAIL'" class="w-100"
                            type="text" [(ngModel)]="modal" (ngModelChange)="onValidateWebsite($event)"
                            (keydown)="keyDownTextEvent($event,12)" />
                    </ng-container>

                    <ng-container *ngIf="cCode || inputType==='PHONE_NUMBER'">
                        <div class="d-flex align-items-center phone-number-class">
                            <mat-form-field appearance="outline" class="me-1">
                                <mat-select placeholder="C code" [value]="cCode"
                                    (selectionChange)="onSelectCCode($event)">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search" (keyup)="onSearch($event)"
                                            noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let country of filteredBanks | async"
                                        [ngClass]="{'d-none': country.IAC === ''}"
                                        value={{country.IAC}}>{{country.IAC}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <input *ngIf="inputType==='text' || inputType==='TEXT' || inputType==='PHONE_NUMBER'"
                                class="w-100" type="text" [(ngModel)]="modal"
                                (ngModelChange)="onValidateWebsite($event)" (keydown)="keyDownTextEvent($event,12)" />
                        </div>
                    </ng-container>
                    <input *ngIf="inputType==='NUMBER'" class="w-100" type="number" (keydown)="keyDownEvent($event)"
                        (wheel)="$event.preventDefault()" [(ngModel)]="modal" />
                    <input *ngIf="inputType==='WEBSITE'" class="w-100" type="url" [(ngModel)]="modal" />

                    <!--pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"-->

                    <div appearance="outline" class="w-100" *ngIf="inputType==='textarea' || inputType==='TEXT_AREA'">
                        <ckeditor [editor]="Editor" placeholder="Description"
                            [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'ck-error':''"
                            [(ngModel)]="modal" [config]="ckconfigQue" class="ckeditor-height" #editorComponent
                            (ready)="onReady($event,editorComponent)" (keyup)="editorkeyEvent(editorComponent)">
                        </ckeditor>
                        <small [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                            class="font-size-10">Maximum
                            1000 characters are accepted.</small><small
                            [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                            class="font-size-10 ps-1">(Character limit:
                            {{ckeditorLimit.actualLimit}}/{{ckeditorLimit.maxLimit}})</small>
                        <!-- <textarea style="outline: none" matInput placeholder="Description" [(ngModel)]="modal"
                            style="height:100px" maxlength="1000"></textarea> -->
                    </div>

                    <mat-form-field *ngIf="inputType==='dateTime' || inputType==='DATE_TIME'" appearance="outline"
                        class="w-100">
                        <input matInput [ngxMatDatetimePicker]="picker" [min]="minDate"
                            placeholder="Choose a date & time" [(ngModel)]="modal">
                        <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100" *ngIf="inputType==='date' || inputType==='DATE'">
                        <input style="outline: none" matInput [matDatepicker]="picker" [min]="minDate"
                            placeholder="Closure date" [(ngModel)]="modal" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <!-- <mat-error class="error text-danger d-flex" *ngIf="submitted && f['due_date'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['due_date'].errors['required']">Due date
                                {{errorJson.required}}</span>
                        </mat-error> -->
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100" *ngIf="inputType === 'DATE_RANGE' ">
                        <mat-date-range-input [rangePicker]="dateRangepicker">
                            <input matStartDate [(ngModel)]="start" placeholder="Start date">
                            <input matEndDate [(ngModel)]="end" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="dateRangepicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #dateRangepicker></mat-date-range-picker>
                    </mat-form-field>

                    <mat-form-field *ngIf="inputType==='dropdown' || inputType==='SELECT_DROPDOWN'" appearance="outline"
                        class="w-100">
                        <mat-select placeholder="Select" [(ngModel)]="selectedItem">
                            <label *ngIf="fieldType !== 'customField'" class="w-100">
                                <span *ngIf="labelName==='Owner'">
                                    <mat-option *ngFor="let item of dropDownItems" (click)="onChange(item)"
                                        [value]="item.id"> {{item.fname}} {{item.lname}} </mat-option>
                                </span>

                                <span *ngIf="labelName==='Country' || labelName === 'country'">
                                    <mat-option *ngFor="let item of dropDownItems" (click)="onChange(item)"
                                        value={{item.Entity}}> {{item.Entity}} </mat-option>
                                </span>

                                <span *ngIf="labelName==='Currency' || labelName === 'currency'">
                                    <mat-option *ngFor="let currency of dropDownItems" (click)="onChange(currency)"
                                        value='{{currency.abbreviation +", "+currency.symbol}}'> {{currency.currency
                                        + " (" + currency.abbreviation + "," + currency.symbol +
                                        ")"}} </mat-option>
                                </span>
                                <span *ngIf="labelName==='time_zone' && formName === 'time_zone'">
                                    <mat-option>
                                        <ngx-mat-select-search ngModel (ngModelChange)="onSearching($event)"
                                            placeholderLabel="Search"
                                            noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                    </mat-option>
                                    <!-- <mat-option *ngFor="let item of timezoneLists" (click)="onChange(item)"
                                    [value]="item.utc[0]"> {{item.text}}
                                </mat-option> -->

                                    <mat-optgroup *ngFor="let time_zone of timezoneLists" [label]="time_zone.name">
                                        <mat-option class="mb-2 mat-hint-none"
                                            *ngFor="let countries of time_zone.countries" value="{{countries.timezone}}"
                                            (click)="onChange(countries)">{{countries.name}}</mat-option>
                                    </mat-optgroup>
                                </span>
                                <span
                                    *ngIf="labelName!=='Owner' && labelName!=='Country' && labelName!=='currency' && labelName!=='country' && labelName!=='time_zone'">

                                    <mat-option *ngFor="let item of dropDownItems" (click)="onChange(item)"
                                        [value]="item.id"> {{item.name}}
                                    </mat-option>

                                </span>
                            </label>
                            <label *ngIf="fieldType === 'customField'" class="w-100">
                                <mat-option *ngFor="let item of dropDownItems" (click)="onChange(item)" [value]="item">
                                    {{item}}
                                </mat-option>
                            </label>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="inputType==='MULTI_SELECT_DROPDOWN'" appearance="outline"
                        class="w-100">
                        <mat-select placeholder="Select" [(ngModel)]="multiSelectCustomField" multiple>
                            <label *ngIf="fieldType === 'customField'" class="w-100">
                                <mat-option *ngFor="let item of dropDownItems" (click)="onChange(item,'cusMulti')" [value]="item">
                                    {{item}}
                                </mat-option>
                            </label>
                        </mat-select>
                    </mat-form-field>
                    <ng-select class="form-control"
                        *ngIf="inputType==='multiselect' && (labelName ==='Assignee' || labelName === 'Staff') && dropDownItems"
                        [items]="dropDownItems" bindValue="id" bindLabel="fname" [addTag]="false" [multiple]="true"
                        placeholder="Select staff" [(ngModel)]="selectedItem">
                    </ng-select>

                    <ng-select class="form-control"
                        *ngIf="inputType==='multiselect' && labelName==='Products' && dropDownItems "
                        [items]="dropDownItems" bindValue="id" bindLabel="name" [multiple]="true" placeholder="Product"
                        [(ngModel)]="selectedItem">
                    </ng-select>

                    <mat-radio-group *ngIf="inputType==='RADIO_BUTTON' && fieldType !== 'customField'"
                        [(ngModel)]="modal">
                        <mat-radio-button [value]="true">
                            Required
                        </mat-radio-button>
                        <mat-radio-button [value]="false">
                            Not Required
                        </mat-radio-button>
                    </mat-radio-group>
                    <mat-radio-group *ngIf="inputType==='RADIO_BUTTON' && fieldType === 'customField'"
                        [(ngModel)]="modal">
                        <mat-radio-button [value]="item" *ngFor="let item of dropDownItems">
                            {{item}}
                        </mat-radio-button>
                    </mat-radio-group>

                    <ng-container *ngIf="inputType==='FILE'">
                        <input type="file" style="outline: none;width: 250px; text-overflow: ellipsis;" (change)="onFileUpload($event)" />
                    </ng-container>
                </div>
                <div class="p-2 d-flex justify-content-evenly">
                    <button class="btn btn-light" (click)="onCancel()">Cancel</button>
                    <button id="button-disabled" class="btn btn-primary"
                        [disabled]="(inputType==='textarea' || inputType==='TEXT_AREA') && ckeditorLimit.actualLimit > ckeditorLimit.maxLimit"
                        (click)="(ckeditorLimit.actualLimit <= ckeditorLimit.maxLimit) && onSave()">Save</button>
                </div>
            </div>
        </mat-menu>
    </div>
</div>

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/service/auth.service';
import { ChatService } from 'src/app/core/service/chat.services';

@Component({
  selector: 'app-bot-chat',
  templateUrl: './bot-chat.component.html',
  styleUrls: ['./bot-chat.component.scss']
})
export class BotChatComponent implements OnInit, OnDestroy, AfterViewInit {
  hidden = false;
  chatNavObj: any;
  iframeLoaded = false;
  @ViewChild('iframeRef', { static: true }) iframeRef!: ElementRef;
  private receiveMessage: ((event: any) => void);
  iframe: any;
  src: any;
  featuresData: any;
  loggedInUser: any;

  constructor(private router: Router, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer, private route: ActivatedRoute, private authService: AuthService, private chatServices: ChatService) {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getFeatues();
    this.spinner.show();
    if (this.router.getCurrentNavigation()?.extras?.state?.['routerState']) {
      this.chatNavObj = this.router.getCurrentNavigation()?.extras?.state?.['routerState'];
    }
    this.receiveMessage = (event: any) => {
      const sessionValues = {
        ...JSON.parse(sessionStorage.getItem('currentUser') ?? "{}")
      };
      if (event.data.message === "auth") {
        this.iframe?.contentWindow.postMessage({ type: "auth", data: sessionValues }, '*');
      } else if (event.data.message === "updateUrl") {
        sessionStorage.setItem('botchatredirectUrl', event.data.url);
        this.chatServices.updateCountsSub$.next('');
      } else if (event.data.message === "joinChat") {
        const routerState: any = {
          redirectUrl: event?.data?.url
        };
        sessionStorage.setItem('redirectUrl', event.data.url);
        this.router.navigate(['/live_chat'], { state: { routerState: routerState } });
      }
    };
  }
  ngOnInit(): void {
    // Parent page
    this.iframe = document.getElementById('bot-widget-value');

    window.addEventListener('message', this.receiveMessage, false);

    setTimeout(async () => {
      const iframeReload = this.iframeRef.nativeElement;
      iframeReload.src = this.iframe.src;
      await this.delay(500);
      this.iframeLoaded = true;
      this.spinner.hide();
    }, 3000);
  }
  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      this.route.queryParams.subscribe((params: any) => {
        if (params.folder === 'inbox' && this.featuresData?.email_inbox) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.location.hostname.includes('app.brexa.ai')
              ? 'https://automations.brexa.ai/emails/inbox'
              : window.location.hostname.includes('dev.brexa.ai')
                ? 'https://devautomation.brexa.ai/emails/inbox'
                : 'https://stageautomation.brexa.ai/emails/inbox'
          );
        } else if (
          params.folder === 'smartpopups' &&
          this.featuresData?.smart_pop &&
          (this.loggedInUser.data.role === 'ADMIN' ||
            this.loggedInUser.data.role === 'MANAGER')
        ) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.location.hostname.includes('app.brexa.ai')
              ? 'https://automations.brexa.ai/smartpopups'
              : window.location.hostname.includes('dev.brexa.ai')
                ? 'https://devautomation.brexa.ai/smartpopups'
                : 'https://stageautomation.brexa.ai/smartpopups'
          );
        } else if (
          params.folder === 'smartforms' &&
          this.featuresData?.smart_form
        ) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.location.hostname.includes('app.brexa.ai')
              ? 'https://automations.brexa.ai/smartforms'
              : window.location.hostname.includes('dev.brexa.ai')
                ? 'https://devautomation.brexa.ai/smartforms'
                : 'https://stageautomation.brexa.ai/smartforms/'
          );
        } else if (
          params.folder === 'apps' &&
          this.featuresData?.apps &&
          this.loggedInUser.data.role === 'ADMIN'
        ) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.location.hostname.includes('app.brexa.ai')
              ? 'https://automations.brexa.ai/v2/apps'
              : window.location.hostname.includes('dev.brexa.ai')
                ? 'https://devautomation.brexa.ai/v2/apps'
                : 'https://stageautomation.brexa.ai/v2/apps'
          );
        } else if (
          params.folder === 'chat_bot' &&
          this.featuresData?.bot_setup &&
          this.loggedInUser.data.role === 'ADMIN'
        ) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.location.hostname.includes('app.brexa.ai')
              ? 'https://automations.brexa.ai/apps'
              : window.location.hostname.includes('dev.brexa.ai')
                ? 'https://devautomation.brexa.ai/apps'
                : 'https://stageautomation.brexa.ai/apps'
          );
        } else if (
          params.folder === 'push' &&
          this.featuresData?.push_notification &&
          this.loggedInUser.data.role === 'ADMIN'
        ) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.location.hostname.includes('app.brexa.ai')
              ? 'https://automations.brexa.ai/push'
              : window.location.hostname.includes('dev.brexa.ai')
                ? 'https://devautomation.brexa.ai/push'
                : 'https://stageautomation.brexa.ai/push'
          );
        } else if (
          params.folder === 'marketing' &&
          this.featuresData?.marketing_automation &&
          this.featuresData?.contacts_list &&
          (this.loggedInUser.data.role === 'ADMIN' ||
            this.loggedInUser.data.role === 'MANAGER')
        ) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.location.hostname.includes('app.brexa.ai')
              ? 'https://automations.brexa.ai/marketing'
              : window.location.hostname.includes('dev.brexa.ai')
                ? 'https://devautomation.brexa.ai/marketing'
                : 'https://stageautomation.brexa.ai/marketing'
          );
        } else if (
          params.folder === 'workflows' &&
          this.featuresData?.workflow_automation
        ) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.location.hostname.includes('app.brexa.ai')
              ? 'https://automations.brexa.ai/workflows'
              : window.location.hostname.includes('dev.brexa.ai')
                ? 'https://devautomation.brexa.ai/workflows'
                : 'https://stageautomation.brexa.ai/workflows'
          );
        } else if (params.folder === "gallery" && this.featuresData?.gallery) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/gallery/folders' : window.location.hostname.includes('dev.brexa.ai') ? 'https://devautomation.brexa.ai/gallery/folders' : 'https://stageautomation.brexa.ai/gallery/folders');
        } else if (params.folder === "feeds" && this.featuresData?.feeds) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/feeds' : window.location.hostname.includes('dev.brexa.ai') ? 'https://devautomation.brexa.ai/feeds' : 'https://stageautomation.brexa.ai/feeds');
        } else if (params.folder === "forms" && this.featuresData?.forms) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/forms' : window.location.hostname.includes('dev.brexa.ai') ? 'https://devautomation.brexa.ai/forms' : 'https://stageautomation.brexa.ai/forms');
        } else if (params.folder === "people") {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/people/people_data' : window.location.hostname.includes('dev.brexa.ai') ? 'https://devautomation.brexa.ai/people/people_data' : 'https://stageautomation.brexa.ai/people/people_data');
        } else if (params.folder === "templates" && this.featuresData?.templates) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/templates/wa-templates' : window.location.hostname.includes('dev.brexa.ai') ? 'https://devautomation.brexa.ai/templates/wa-templates' : 'https://stageautomation.brexa.ai/templates/wa-templates');
        } else if (params.folder === "agents" && this.featuresData?.agents) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/agents' : window.location.hostname.includes('dev.brexa.ai') ? 'https://devautomation.brexa.ai/agents' : 'https://stageautomation.brexa.ai/agents');
        } else if (params.folder === "coupons" && this.featuresData?.chatbot_coupons) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/coupons' : window.location.hostname.includes('dev.brexa.ai') ? 'https://devautomation.brexa.ai/coupons' : 'https://stageautomation.brexa.ai/coupons');
        } else if (params.folder === "qrs" && this.featuresData?.qrs) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/qr-codes' : window.location.hostname.includes('dev.brexa.ai') ? 'https://devautomation.brexa.ai/qr-codes' : 'https://stageautomation.brexa.ai/qr-codes');
        } else if (params.folder === "stores" && this.featuresData?.chatbot_stores) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/setup/stores' : window.location.hostname.includes('dev.brexa.ai') ? 'https://devautomation.brexa.ai/setup/stores' : 'https://stageautomation.brexa.ai/setup/stores');
        } else if (
          !params.folder &&
          this.featuresData?.bot_chat &&
          this.loggedInUser.data.role !== 'MANAGER' &&
          this.loggedInUser.data.role !== 'STAFF'
        ) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.location.hostname.includes('app.brexa.ai')
              ? 'https://automations.brexa.ai/chats/botchats'
              : window.location.hostname.includes('dev.brexa.ai')
                ? 'https://devautomation.brexa.ai/chats/botchats'
                : 'https://stageautomation.brexa.ai/chats/botchats'
          );
        } else {
          this.router.navigate(['/unauthorised']);
        }
      });
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

  ngAfterViewInit() {
    let url = sessionStorage.getItem('botchatredirectUrl');
    this.iframe.onload = () => {

      if (this.chatNavObj?.botchatredirectUrl && this.chatNavObj?.botchatredirectUrl !== "") {
        url = this.chatNavObj?.botchatredirectUrl;
      }
      setTimeout(() => {
        this.iframe?.contentWindow.postMessage({ type: "redirect", data: { redirectUrl: url } }, '*');
      }, 500);
    };
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.receiveMessage, false);
    this.chatServices.updateCountsSub$.next('');
    sessionStorage.removeItem('botchatredirectUrl');
  }
}

<div class="container-fluid mt-3">
    <div class="row">
        <div class="col-12 col-lg-3">
            <mat-card class="matcard" style="height: 80vh;">
                <h5 class="heading font-size-16 fw-700 ms-3 mt-1" style="width: fit-content;">{{createformJson.heading}}
                </h5>
                <div class="row">
                    <div class="col-12 text-center">
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" [(ngModel)]="searchInput" (input)="onSearchInputChange($event)"
                                matInput placeholder="{{createformJson.palcehold_search}}" />
                            <mat-icon matSuffix>{{createformJson.icon_search}}</mat-icon>
                        </mat-form-field>

                    </div>
                </div>
                <div cdkDropList #itemsList="cdkDropList" [cdkDropListData]="items"
                    [cdkDropListConnectedTo]="[doneList]" class="example-list scroll_droplist"
                    (cdkDropListDropped)="drop($event)">
                    <ng-container *ngFor="let item of items">
                        <div class="example-box drag_box cursor-move"
                            *ngIf="(item?.launchDlKeys && featuresData[item.launchDlKeys]) || !item.launchDlKeys"
                            style="padding: 12px;" cdkDrag>
                            {{item.label}}
                            <label class="mb-0">
                                <mat-icon>{{createformJson.icon_indicator}}</mat-icon>
                            </label>
                        </div>
                    </ng-container>
                </div>
            </mat-card>
        </div>
        <div class="col-12 col-lg-9">
            <div class="">
                <form [formGroup]="custom_form">
                    <div class="d-flex align-items-center">
                        <mat-form-field appearance="outline" class="font-size-15">
                            <input style="outline: none;" matInput type="text" required placeholder="Enter Form Name"
                                formControlName="name">
                        </mat-form-field>
                        <div class="ms-3" *ngIf="originalFormName !== custom_form.value.name">

                            <button class="btn btn-primary btn-sm mb-4"
                                (click)="onFormNameSubmit()">{{createformJson.submit_btn}}</button>
                        </div>
                    </div>

                    <div style="width: 77%;min-height: 50px;">
                        <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="dropItemData"
                            style="border: dashed 1px #d6d8d88f;" [cdkDropListConnectedTo]="[itemsList]"
                            class="example-list" (cdkDropListDropped)="drop($event)">
                            <div class="example-box drop_box cursor-move"
                                style="position: relative;align-items: baseline;"
                                *ngFor="let doneitem of dropItemData;let i= index" cdkDrag>
                                <label class="item" style="min-width: 35%;">{{doneitem.label}} <span class="error"
                                        *ngIf="doneitem?.is_mandatory">*</span></label>

                                <!--Text Box-->
                                <input class="form-control" style="max-width: 50%;" type="text"
                                    *ngIf="doneitem.type==='TEXT' || doneitem.type==='EMAIL' || doneitem.type==='PHONE_NUMBER' || doneitem.type==='WEBSITE'">

                                <!--Number-->
                                <input class="form-control" style="max-width: 50%;" type="number"
                                    *ngIf="doneitem.type==='NUMBER'">

                                <!--Text area-->
                                <textarea class="form-control" type="text" *ngIf="doneitem.type==='TEXT_AREA'"
                                    style="min-height: 50px; max-width: 50%;"></textarea>

                                <!-- Select -->
                                <select class="form-select" style="max-width: 50%;"
                                    *ngIf="doneitem.type==='SELECT_DROPDOWN'">
                                    <ng-container
                                        *ngIf="!(doneitem.original_label === 'company' || doneitem.original_label === 'product_category' || doneitem.original_label === 'product' || doneitem.original_label === 'scheduler_category' || doneitem.original_label === 'scheduler' || doneitem.original_label === 'service' || doneitem.original_label === 'service_category')">
                                        <option *ngFor="let options of doneitem.options">
                                            {{options}}
                                        </option>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="doneitem.original_label === 'company' || doneitem.original_label === 'product_category' || doneitem.original_label === 'product' || doneitem.original_label === 'scheduler_category' || doneitem.original_label === 'scheduler'|| doneitem.original_label === 'service'|| doneitem.original_label === 'service_category'">
                                        <option *ngFor="let options of doneitem.options">
                                            {{getItemName(options)}}</option>
                                    </ng-container>
                                </select>
                                <mat-select class="form-select" style="max-width: 50%;" multiple
                                    *ngIf="doneitem.type==='MULTI_SELECT_DROPDOWN'">
                                    <mat-option *ngFor="let options of doneitem.options">
                                        {{getItemName(options)}}
                                    </mat-option>
                                </mat-select>


                                <!-- Check Box --> <!-- Radio Button -->
                                <div style="max-width: 50%; min-width: fit-content;"
                                    *ngIf="doneitem.type==='CHECK_BOX' || doneitem.type==='RADIO_BUTTON'">
                                    <div class="d-flex justify-content-start"
                                        *ngFor="let options of doneitem.options;let i= index">
                                        <input class="form-check-input" type="checkbox"
                                            *ngIf="doneitem.type==='CHECK_BOX'">
                                        <input class="form-check-input" type="radio"
                                            *ngIf="doneitem.type==='RADIO_BUTTON'">
                                        <label class="item labelname mx-1" style="width: 100%;bottom: 0px;"
                                            *ngIf="doneitem.type==='CHECK_BOX' || doneitem.type==='RADIO_BUTTON'">
                                            {{options}}
                                        </label>
                                    </div>
                                </div>

                                <!-- Date Time -->
                                <input class="form-control" style="max-width: 50%;"
                                    *ngIf="doneitem.type==='DATETIME' || doneitem.type==='DATE' || doneitem.type==='DATE_TIME' || doneitem.type==='DATE_RANGE'"
                                    type="date" />
                                <!--Time-->
                                <input class="form-control" style="outline: none;max-width: 50%"
                                    placeholder="Enter Time" type="time" *ngIf="doneitem.type==='TIME'">

                                <!--File upload-->
                                <input class=" form-control" style="max-width: 50%;" type="file"
                                    *ngIf="doneitem.type==='FILE'">

                                <!-- Select -->
                                <ng-container *ngIf="doneitem.key === 'APPOINTMENT_CATEGORIES'">
                                    <div style="min-width: 50%;">
                                        <select class="form-select" style="min-width: 100%;">
                                            <option *ngFor="let options of doneitem.options">
                                                {{getItemName(options)}}

                                            </option>
                                        </select>
                                        <!-- <div class="mt-2">
                                            <input type="text" disabled class="form-control" placeholder="{{createformJson.appointment_text_field}}">
                                        </div> -->
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="doneitem.key === 'PRODUCT_CATEGORIES' || doneitem.key === 'SERVICE_CATEGORIES'">
                                    <div style="min-width: 50%;">
                                        <select class="form-select" style="min-width: 100%;">
                                            <option *ngFor="let options of doneitem.options">
                                                {{getItemName(options)}}


                                            </option>
                                        </select>
                                        <!-- <div class="mt-2">
                                            <input type="text" disabled class="form-control" placeholder="{{createformJson.product_text_field}}">
                                        </div> -->
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="doneitem.key === 'PRODUCT' || doneitem.key === 'SERVICE'">
                                    <div style="min-width: 50%;">
                                        <select class="form-select" style="min-width: 100%;">
                                            <option *ngFor="let options of doneitem.options">
                                                {{getItemName(options)}}
                                            </option>
                                        </select>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="doneitem.key === 'APPOINTMENT'">
                                    <div style="min-width: 50%;">
                                        <select class="form-select" style="min-width: 100%;">
                                            <option *ngFor="let options of doneitem.options">
                                                {{getItemName(options)}}
                                            </option>
                                        </select>
                                    </div>
                                </ng-container>



                                <!-- </div> -->
                                <label class="icons d-flex align-items-center" style="transform: scale(0.6);">
                                    <span class="text-danger del cursor"
                                        (click)="deleteFormItem(i,defaultValueModal,doneitem)"
                                        *ngIf="!doneitem.is_default">
                                        <mat-icon>{{createformJson.icon_delete}}</mat-icon>
                                    </span>

                                    <span class="dialog_btn px-3 cursor" (click)="openSettingsModel(doneitem, i)">
                                        <mat-icon>{{createformJson.icon_settings}}</mat-icon>
                                    </span>
                                </label>
                            </div>
                            <div class="card p-5 text-center" *ngIf="dropItemData.length===0">
                                <label>{{createformJson.drop_text}}</label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="row nxt_step d-none">
                <div class="col-12">
                    <button class="btn-light btn">{{createformJson.cancel_btn}}</button>&nbsp;&nbsp;&nbsp;
                    <button class="btn-primary btn" (click)="onFormSubmit()">{{createformJson.submit_btn}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #defaultValueModal let-modal>
    <div class="modal-header pb-0 border-0">
        <h6 class="modal-title text-primary fw-600" id="modal-basic-title">Default Value</h6>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
    </div>
    <div class="modal-body">
        <mat-form-field appearance="outline" class="w-100"
            *ngIf="deletedItemData.type!=='DATETIME' && deletedItemData.type!=='DATE' && deletedItemData.type!=='DATE_TIME' && deletedItemData.type!=='FILE' && deletedItemData.type !== 'DATE_RANGE' && deletedItemData.type !== 'TIME'">
            <mat-select *ngIf="default_value_option.length !== 0" [(ngModel)]="default_value">
                <mat-option [value]="getItemId(item)"
                    *ngFor="let item of default_value_option">{{getItemName(item)}}</mat-option>
            </mat-select>
            <mat-select *ngIf="default_value_option.length === 0 && deletedItemData.type === 'SELECT_DROPDOWN'"
                [(ngModel)]="default_value">
                <span
                    *ngIf="deletedItemData.original_label==='Country' || deletedItemData.original_label === 'country'">
                    <mat-option *ngFor="let item of countryjson" value={{item.Entity}}> {{item.Entity}} </mat-option>
                </span>

                <span
                    *ngIf="deletedItemData.original_label==='Currency' || deletedItemData.original_label === 'currency'">
                    <mat-option *ngFor="let currency of currencyjson"
                        value='{{currency.abbreviation +", "+currency.symbol}}'> {{currency.currency
                        + " (" + currency.abbreviation + "," + currency.symbol +
                        ")"}} </mat-option>
                </span>
            </mat-select>
            <input
                *ngIf="default_value_option.length === 0 && (deletedItemData.type === 'TEXT_AREA' || deletedItemData.type === 'TEXT' || deletedItemData.type === 'WEBSITE' || deletedItemData.type === 'PHONE_NUMBER' || deletedItemData.type === 'EMAIL')"
                style="outline: none" type="text" matInput placeholder="Default Value" [(ngModel)]="default_value" />
            <input *ngIf="default_value_option.length === 0 && deletedItemData.type === 'NUMBER'" style="outline: none"
                type="number" matInput placeholder="Default Value" [(ngModel)]="default_value" />
        </mat-form-field>
        <!--DATE-->
        <input class="form-control" *ngIf="deletedItemData.type==='DATE'" type="date" [(ngModel)]="default_value" />
        <input class="form-control" *ngIf="deletedItemData.type==='TIME'" type="time" [(ngModel)]="default_value" />
        <!--DATETIME-->
        <mat-form-field *ngIf="deletedItemData.type==='DATETIME' || deletedItemData.type==='DATE_TIME'"
            appearance="outline" class="w-100">
            <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date & time"
                [(ngModel)]="default_value">
            <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
        </mat-form-field>
        <!--DATE RANGE-->
        <mat-form-field appearance="outline" class="w-100" *ngIf="deletedItemData.type === 'DATE_RANGE'">
            <mat-date-range-input [rangePicker]="dateRangepicker">
                <input matStartDate placeholder="Start date" [(ngModel)]="start">
                <input matEndDate placeholder="End date" [(ngModel)]="end" (dateChange)="onDateChange()">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="dateRangepicker"></mat-datepicker-toggle>
            <mat-date-range-picker #dateRangepicker></mat-date-range-picker>
        </mat-form-field>
        <!--File Upload-->
        <input class="form-control" type="file" *ngIf="deletedItemData.type==='FILE'" (change)="onFileUpload($event)">
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end mb-5">
        <button type="button" class="d-flex align-items-center btn btn-primary small" [disabled]="!default_value"
            (click)="deleteItemInForm(deleteIndex)">
            Save & Delete
        </button>
    </div>
</ng-template>
<div class="container-fluid">
    <div class="row p-2">
        <div class="col-md-12 h-35rem">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0 me-3">
                <li [ngbNavItem]="1" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">SMS history</span>
                    </a>
                    <ng-template ngbNavContent>

                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">SMS notifications</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="row py-3 ">
                            <div class="col-sm-6">
                                <span class="fw-600 font-size-16 text-primary mt-2 d-inline-flex align-items-center">SMS
                                    Notifications</span>
                            </div>
                            <div class="col-sm-6 text-end">
                                <label
                                    class="fw-600 bg-tag d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2">SMS
                                    Credits Left 0.00</label>
                                <button class="btn btn-outline-primary  align-items-center px-2 py-1 "> Add Credits
                                </button>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="mt-1">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="tocustomertab">
                                        <mat-tab-group class="tocustomertab">
                                            <mat-tab label="To Customer">
                                                <div class="appointments-list">
                                                    <mat-list>
                                                        <mat-list-item>
                                                            <div class="row">
                                                                <div class="col-sm-10 font-size-13">
                                                                    Appointment Approved
                                                                </div>
                                                                <div class="col-sm-2 text-end">
                                                                    <mat-icon
                                                                        class="d-inline-flex align-items-center p-0 font-size-9 dot-approved"
                                                                        matend>circle</mat-icon>
                                                                </div>
                                                            </div>
                                                        </mat-list-item>
                                                        <mat-divider></mat-divider>
                                                        <mat-list-item>
                                                            <div class="row">
                                                                <div class="col-sm-10 font-size-13">
                                                                    Appointment Approved
                                                                </div>
                                                                <div class="col-sm-2 text-end">
                                                                    <mat-icon
                                                                        class="d-inline-flex align-items-center p-0 font-size-9 dot-approved"
                                                                        matend>circle</mat-icon>
                                                                </div>
                                                            </div>
                                                        </mat-list-item>
                                                        <mat-divider></mat-divider>
                                                        <mat-list-item>
                                                            <div class="row">
                                                                <div class="col-sm-10 font-size-13">
                                                                    Appointment Approved
                                                                </div>
                                                                <div class="col-sm-2 text-end">
                                                                    <mat-icon
                                                                        class="d-inline-flex align-items-center p-0 font-size-9 dot-approved"
                                                                        matend>circle</mat-icon>
                                                                </div>
                                                            </div>
                                                        </mat-list-item>
                                                        <mat-divider></mat-divider>
                                                    </mat-list>

                                                </div>
                                            </mat-tab>
                                            <mat-tab label="To Employee">
                                                <div>
                                                    <h5>To Employee</h5>
                                                </div>
                                            </mat-tab>
                                        </mat-tab-group>
                                    </div>
                                </div>
                                <div class="col-sm-8">
                                    <div class="pt-3 d-flex align-items-center">
                                        <label class="switch ms-2">
                                            <input type="checkbox" checked>
                                            <span class="slider round"></span>
                                        </label>
                                        <span class="px-3 fw-600 font-size-13">Appointment Approved</span>
                                    </div>
                                    <div class="py-4">
                                        <label
                                            class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Appointment</label>
                                        <label
                                            class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Customer</label>
                                        <label
                                            class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Employee</label>
                                        <label
                                            class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Service</label>
                                        <label
                                            class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Location</label>
                                        <label
                                            class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13 mt-2">Company</label>

                                    </div>
                                    <div class="ms-2">
                                        <span class="d-sm-block font-size-13 text-primary fw-500">Message</span>
                                        <textarea name="" id="" cols="50" rows="5"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">SMS settings</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <h6 class="text-primary fw-600 mt-3">SMS Settings</h6>
                            <div class="card border-radius-8 dotted-border mt-3">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-1 d-flex align-items-center justify-content-center">
                                            <mat-icon>sms</mat-icon>
                                        </div>
                                        <div class="col-8">
                                            <p class="text-primary mb-0">SMS Credits Left <span
                                                    class="text-danger fw-600 font-size-16 ms-1">0.00</span></p>
                                            <p class="text-danger mb-0">You are running out of SMS credits.</p>
                                        </div>
                                        <div class="col-3 d-flex align-items-center justify-content-end">
                                            <button class="btn btn-primary">Add Credits</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card bg-dark-yellow border-radius-8 mt-3">
                                <div class="card-body">
                                    <p class="fw-600 mb-1">Alphanumeric Sender ID</p>
                                    <p class="mb-0">Support for sending messages from an alphanumeric sender ID depends
                                        on your destination (‘To’) phone number and is not available everywhere.</p>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label class="mb-2 fw-600 font-size-13 text-primary">Alphanumeric Sender ID</label>
                                    <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                        <input style="outline: none" matInput
                                            placeholder="Enter Alphanumeric Sender ID" />
                                    </mat-form-field>
                                    <span class="text-light font-size-12">Allows you to set your company name or brand as the Sender ID when sending one-way SMS messages.</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
</div>
<div class="container-fluid p-0" [ngClass]="{'p-3' : crm_type ? crm_type : ''}" style="height: calc(100vh - 200px);">
  <ng-container *ngIf="listOfStages.length === 0">
    <div *ngIf="selected_type==='project'"
      class="d-flex align-items-center justify-content-center my-1 cursor w-100 p-2 border-radius-6 my-3"
      style="background:rgba(219, 225, 228, 0.39);" (click)="openAddStageModal(stageAdd)">
      <span class="p-2 rounded d-flex align-items-center justify-content-center"
        style="background:rgba(219, 225, 228, 0.39);">
        <img src="../../../assets/images/icons_add.svg" width="16">
      </span>
      <p class="text-primary mb-0 ms-2 fw-600">Add Stage</p>
    </div>
    <div class="d-flex align-items-center justify-content-center" *ngIf="!crm_type">
      <img src="../../../../../assets/images/Empty Order.svg">
    </div>
  </ng-container>
  <div *ngIf="listOfStages.length !== 0 && !crm_type">
    <div class="d-flex align-items-center justify-content-end new_design_2024 pb-2">
      <mat-form-field appearance="outline" class="mat-hint-none position-relative searching-box">
        <input style="outline: none" matInput [(ngModel)]="search_global"
          (ngModelChange)="searchSubject$.next(search_global)" placeholder="Search" />
        <mat-icon matPrefix style="color: #827e7e;" class="ps-2 pe-1 py-0">search</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-hint-none ms-2">
        <mat-select placeholder="View Type" class="pt-1 font-size-12" [(ngModel)]="show_value" (ngModelChange)="viewChange($event)">
          <mat-option class="font-size-12" value="campaign_view">
            <mat-icon style="margin-right: 4px;">view_module</mat-icon>Campaign View</mat-option>
          <mat-option class="font-size-12" value="list_view">
            <mat-icon style="margin-right: 4px;">list</mat-icon>List View</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div cdkDropListGroup class="d-flex dropListGroup ps-3 auto-hide-scroll-bar flex-grow-1" *ngIf="show_value === 'campaign_view'">
      <div *ngFor="let card of listOfStages" class="main-container mb-0 mx-0 me-3 mt-0 global-taskboard">
        <mat-card class="example-card" style="height: 100%;">
          <mat-card-header>
            <div class="container px-2 d-flex align-items-center justify-content-center stage-heading">
              <!-- <div class="row w-100">
                <div class="col-md-8 p-0"> -->
              <!-- <mat-card-title
                    class="card-title bg-stage-title d-flex align-items-center justify-content-center px-2"> -->
              <div class="w-100 d-flex align-items-center justify-content-between p-0">
                <div class="col-md-11 d-flex align-items-center label-text-clr">
                  <p class="mb-0 truncate-description limit-line-length-1 fw-500 font-size-14 ">
                    {{card?.name | titlecase}}</p>
                  <span class="mx-2 fw-500 font-size-14">(<span
                      *ngIf="card?.tasks?.length < 10">0</span>{{card?.tasks?.length ?
                    card?.tasks?.length : 0}})
                  </span>
                </div>
                <div class="col-md-1" *ngIf="!readOnly">
                  <img src="assets/images/edit_pen.svg" class="p-1 cursor" alt=""
                    (click)="openAddStageModal(stageAdd,card)">
                  <!-- <div>
                          <svg (click)="openAddStageModal(stageAdd,card)" xmlns="http://www.w3.org/2000/svg" width="16"
                            height="16" viewBox="0 0 16 16" fill="none">
                            <path
                              d="M9.18961 3.54114C9.18961 3.54114 9.18961 4.63089 10.2794 5.72064C11.3691 6.81039 12.4589 6.81039 12.4589 6.81039M4.87975 11.992L7.16823 11.6651C7.49833 11.618 7.80424 11.465 8.04003 11.2292L13.5486 5.72064C14.1505 5.11879 14.1505 4.14299 13.5486 3.54114L12.4589 2.45139C11.857 1.84954 10.8812 1.84954 10.2794 2.45139L4.77078 7.95997C4.53499 8.19576 4.38203 8.50167 4.33488 8.83177L4.00795 11.1202C3.9353 11.6288 4.3712 12.0647 4.87975 11.992Z"
                              stroke="black" stroke-opacity="0.81" stroke-width="1.5" stroke-linecap="round" />
                          </svg>
                        </div> -->
                </div>
              </div>
              <!-- </mat-card-title> -->

            </div>
            <!-- <div class="col-md-4 d-flex align-items-center justify-content-end pe-0">
                  <button class="add-task-btn font-size-14">
                    <span *ngIf="card?.tasks?.length < 10">0</span>{{card?.tasks?.length ?
                    card?.tasks?.length : 0}}
                  </button>
                  <button *ngIf="!crm_type && !readOnly" mat-raised-button matTooltip="Add task"
                    aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                    class="add-task-btn font-size-14 d-flex align-items-center ms-2"
                    >
                    <mat-icon>add</mat-icon>
                  </button>
                </div> -->
            <!-- </div> -->
            <!-- <div class="d-flex align-items-center">
                                <mat-card-title class="card-title">
                                    <span *ngIf="card.is_closed" style="width: 20px;height: 0px;border: 2px solid #219653;transform: rotate(90deg);
                            margin-right:5px"></span><span *ngIf="!card.is_closed" style="width: 20px;height: 0px;border: 2px solid blueviolet;transform: rotate(90deg);
                            margin-right:5px"></span> {{card?.name | titlecase}}</mat-card-title>
                                <button class="round-btn font-size-14 ms-3">
                                    <span>{{card?.tasks?.length}}</span>
                                </button>
                            </div>
                            <div class="on-hover" *ngIf="selected_type==='project'">
                                <mat-icon class="font-size-18 d-flex align-items-center cursor" style="color:#0000004F;"
                                    (click)="openAddStageModal(stageAdd,card)">edit</mat-icon>
                            </div> -->
            <!-- </div> -->
          </mat-card-header>
          <!-- <button *ngIf="!crm_type"
                        class="btn_add font-size-12 d-flex align-items-center justify-content-center"
                        (click)="openAddTaskModal(card.id)">
                        <mat-icon>add</mat-icon>
                        Add Task
                    </button> -->
          <mat-card-content class="h-100 px-2 pb-0">
            <div (click)="!crm_type && !readOnly && openAddTaskModal(card.id)"
              [ngStyle]="{'opacity':!crm_type && !readOnly ? '1' : '0.5','cursor':!crm_type && !readOnly ? 'pointer' : 'auto' }"
              class="add-task-sec d-flex align-items-center justify-content-center">
              <mat-icon class="me-2">add</mat-icon>
              <p class="m-0 fw-500 font-size-14">Add Task</p>
            </div>
            <div cdkDropList [cdkDropListData]="card.tasks" class="example-list dropList auto-hide-scroll-bar"
              (cdkDropListDropped)="drop($event,card)">
              <div class="card-body p-0 example-box container cursor" *ngFor="let item of card.tasks" cdkDrag
                [cdkDragData]="item">
                <div class="sub-card card mb-1" (click)="openTaskDetailsModal(item)">
                  <div class="card-body p-0">
                    <div class="d-flex align-items-center justify-content-between mb-1">
                      <div class="d-flex">
                        <div *ngIf="item?.display_id" class="me-2">
                          <span class="label-text-clr fw-500 px-2 border-radius-16 font-size-12 bg-gray-light" style="padding: 6px 8px;">
                            {{item?.display_id}}
                          </span>
                        </div>
                        <div class="d-flex justify-content-center">
                          <div
                            class="priority-sec border-radius-16 fw-500 font-size-12 px-2 py-1 d-flex align-items-center"
                            [ngStyle]="{'color': item?.priority === 'High' ? '#752522' : item?.priority === 'Medium' ? '#73321B' : '#302E7C',
                              'background': item?.priority === 'High' ? '#F9E3E2' : item?.priority === 'Medium' ? '#FCEED8' : '#E1E7FD'
                             }">
                            <div class="dot" [ngStyle]="{'background': item?.priority === 'High' ? '#752522' : item?.priority === 'Medium' ? '#73321B' : '#302E7C'
                             }"></div>
                            <!-- <svg *ngIf="item?.priority === 'High'" xmlns="http://www.w3.org/2000/svg" width="20"
                              height="20" viewBox="0 0 20 20" fill="none">
                              <path d="M5.83301 14.167L9.99967 10.8337L14.1663 14.167" stroke="#EB3C54"
                                stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M5.83301 9.16699L9.99967 5.83366L14.1663 9.16699" stroke="#EB3C54"
                                stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <svg *ngIf="item?.priority === 'Medium'" xmlns="http://www.w3.org/2000/svg" width="20"
                              height="20" viewBox="0 0 20 20" fill="none">
                              <path d="M5.83301 14.167L9.99967 10.8337L14.1663 14.167" stroke="#CD7C33"
                                stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M5.83301 9.16699L9.99967 5.83366L14.1663 9.16699" stroke="#CD7C33"
                                stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <svg *ngIf="item?.priority === 'Low'" xmlns="http://www.w3.org/2000/svg" width="20"
                              height="20" viewBox="0 0 20 20" fill="none">
                              <path d="M5.83301 14.167L9.99967 10.8337L14.1663 14.167" stroke="#4074CE"
                                stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M5.83301 9.16699L9.99967 5.83366L14.1663 9.16699" stroke="#4074CE"
                                stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> -->
                            {{item?.priority | uppercase}}
                            <!-- <span class="ms-1" *ngIf="item?.priority === 'High'">🔥</span></button> -->
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center font-size-14" (click)="$event.stopPropagation()"
                        *ngIf="!readOnly">
                        <img src="assets/images/edit_pen.svg" class="p-1 cursor"
                          (click)="openAddTaskModal(card.id,item)">
                          <img src="assets/images/delete-board-icon.svg" class="p-1 cursor"
                            (click)="confirmDelete(item)">
                      </div>
                    </div>
                    <div
                      class="text-capitalize fw-600 font-size-14 mt-1 cardcontent truncate-description d-flex justify-content-between"
                      style="color: #232323;">
                      {{item?.name}}
                    </div>
                    <div style="height:56px;">
                      <div class="mb-0 truncate-text-lines text-capitalize font-size-13 mt-1" style="color: #8F8F8F"
                        [innerHTML]="item?.description">
                      </div>
                    </div>

                    <!-- <mat-divider class=""></mat-divider> -->
                    <div class="d-flex align-items-center justify-content-between pt-1"
                      style="border-top: 0.8px dashed #C0C0C0;">
                      <div class="d-flex align-items-center ">
                        <div *ngIf="item.due_date" class="d-flex align-items-center me-2">
                          <img src="assets/images/newUI/CRM/calender_icons.svg" class="me-1" alt="">
                          <p class="font-size-13 mb-0 duedate-background" *ngIf="item?.due_date"
                            [ngClass]="{'text-danger':todayDate < item.due_date}">
                            {{item.due_date | dateConversion}}</p>
                        </div>
                        <div class="">
                          <label class="switch"
                            *ngIf="loggedInUser.data.role === 'ADMIN' && selected_type !== 'company' && selected_type !== 'contact' && !readOnly"
                            (click)="$event.stopPropagation()" matTooltip="Client visibility"
                            matTooltipPosition="above">
                            <input type="checkbox" [id]="item?.id" [checked]="item?.client_visibility"
                              (change)="onToggleChange($event,item)">
                            <span class="slider round"
                              [ngStyle]="{'cursor' :item?.is_editable ? 'pointer':'auto'}"></span>
                          </label>
                        </div>
                      </div>
                      <div class="d-flex align-items-center" *ngIf="item.owner_info?.fname">
                        <div class="avatar-group d-flex align-items-center owner-background">
                          <!-- <div
                                                        class="text-capitalize avatar-xs avatar-group-item bg-primary text-white fw-600 border-radius-50 d-flex align-items-center justify-content-center  font-size-12">
                                                        {{item.owner_info?.fname[0]}}{{item.owner_info?.lname[0]}}
                                                    </div>
                                                    <p class="mb-0 font-size-12 ms-1 me-2">
                                                        {{item.owner_info?.fname + " " + item.owner_info?.lname }}
                                                    </p> -->
                          <button mat-raised-button
                            matTooltip="{{item.owner_info?.fname | titlecase}} {{item.owner_info?.lname | titlecase}}"
                            aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                            class="btn header-item border-0 p-0">
                            <div *ngIf="item.owner_info?.fname"
                              class="text-capitalize avatar-xs avatar-group-item bg-success text-white fw-600 border-radius-50 d-flex align-items-center justify-content-center  font-size-12">
                              {{item.owner_info?.fname[0]
                              }}{{item.owner_info?.lname[0]}}
                            </div>
                          </button>
                          <button mat-raised-button
                            matTooltip="{{item.info.staff_info_array[0]?.fname | titlecase}} {{item.info.staff_info_array[0]?.lname | titlecase}}"
                            aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                            class="btn header-item border-0 p-0" *ngIf="item.info.hasOwnProperty('staff_info_array')">
                            <div *ngIf="item.info.staff_info_array[0]"
                              class="text-capitalize avatar-xs avatar-group-item bg-success text-white fw-600 border-radius-50 d-flex align-items-center justify-content-center  font-size-12">
                              {{item.info.staff_info_array[0]?.fname[0]
                              }}{{item.info.staff_info_array[0]?.lname[0]}}
                            </div>
                          </button>
                          <button mat-raised-button
                            matTooltip="{{item.info.staff_info_array[1]?.fname | titlecase}} {{item.info.staff_info_array[1]?.lname | titlecase}}"
                            aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                            class="btn header-item border-0 p-0" *ngIf="item.info.hasOwnProperty('staff_info_array')">
                            <div *ngIf="item.info.staff_info_array[1]"
                              class="text-capitalize avatar-xs avatar-group-item bg-danger text-white fw-600 border-radius-50 d-flex align-items-center justify-content-center  font-size-12">
                              {{item.info.staff_info_array[1]?.fname[0]}}{{item.info.staff_info_array[1]?.lname[0]}}
                            </div>
                          </button>
                          <div class="" *ngIf="item.info.hasOwnProperty('staff_info_array')">
                            <div *ngIf="item.info?.staff_info_array?.length > 2 "
                              class="avatar-xs avatar-group-item border-radius-50 d-flex align-items-center justify-content-center font-size-12"
                              style="background-color: lightgrey;">

                              +{{item.info.staff_info_array.length - 2}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- <div class="" *ngIf="selected_type==='project'">
                <div class="d-flex align-items-center justify-content-center mt-1">
                    <span class="p-2 rounded d-flex align-items-center justify-content-center"
                        style="background:rgba(219, 225, 228, 0.39);" (click)="openAddStageModal(stageAdd)">
                        <img src="../../../assets/images/icons_add.svg" class="cursor" width="16">
                    </span>
                </div>
            </div> -->
    </div>
    <div class="list_view p-2" *ngIf="show_value === 'list_view'">
      <div class="custom-table-div-height-home custom-table-div-height-task">
        <div class="table-responsive table-height mt-2 h-90">
          <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
            <thead>
              <tr>
                <th scope="col" *ngFor="let col of table_cols" class="text-primary pb-3 text-capitalize">{{col}}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="cursor" *ngFor="let item of listOfStages; let i = index">
                <td>{{item.display_id}}</td>
                <td>{{item.name}}</td>
                <td>{{item?.due_date ? (item.due_date | dateConversion) : ''}}</td>
                <td>{{item.stage_name}}</td>
                <td>{{item?.priority || "NA"}}</td>
                <td>{{item?.owner_info?.fname}}</td>
                <!-- <td (click)="$event.stopPropagation()">
                  <div class="d-flex" *ngIf="item.stage_settings">
                    <button class="btn btn-light me-2" *ngFor="let btn of item.stage_settings" [disabled]="!getRolePermissions('flow-cards','can_update')"
                      (click)="updateStageCard(item,btn.nextStageId)">{{btn.buttonLabel}}</button>
                  </div>
                  <mat-form-field appearance="outline" class="w-100 mat-hint-none" *ngIf="!item.stage_settings">
                    <mat-select placeholder="Stage" class="pt-1" [(ngModel)]="item.stage" [disabled]="!getRolePermissions('flow-cards','can_update')"
                      (ngModelChange)="updateStageCard(item)">
                      <mat-option class="font-size-12" [value]="opt.id"
                        *ngFor="let opt of stageList">{{opt.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td> -->
                <td>{{item?.created_at | dateConversion}}</td>
                <td (click)="$event.stopPropagation()">
                  <div class="dropdown" ngbDropdown>
                    <mat-icon [matMenuTriggerFor]="menuaction" class="more-icon cursor"
                      (click)="$event.stopPropagation()">more_vert</mat-icon>
                    <mat-menu #menuaction="matMenu" class="rounded header-mat-menu">
                      <button mat-menu-item class="py-2" (click)="openAddTaskModal(item.current_stage,item)">Edit</button>
                      <button mat-menu-item class="py-2" (click)="confirmDelete(item)">
                        Delete
                      </button>
                    </mat-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <mat-paginator [length]="total_task" [pageSize]="10" (page)="page($event)" aria-label="Select page"
            hidePageSize="true" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="crm_type === 'lead' || crm_type === 'contact' || crm_type === 'company' || crm_type==='deal' || crm_type === 'project'">
    <div class="d-flex justify-content-between">
      <h5>Stages Info</h5>
      <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button> -->
    </div>
    <mat-divider></mat-divider>
    <div class="scrollable-container">
      <div class="d-flex w-100 align-items-center my-3" *ngFor="let card of listOfStages; let i = index">
        <div class="card w-100 p-2 mb-0" *ngIf="selectedStage !== card">
          <p class="mb-0">
            <span *ngIf="card.is_closed"
              style="width: 20px;height: 0px;border: 2px solid #219653;transform: rotate(90deg);margin-right:15px"></span>
            <span *ngIf="!card.is_closed"
              style="width: 20px;height: 0px;border: 2px solid blueviolet;transform: rotate(90deg);margin-right:15px"></span>
            {{card?.name | titlecase}}
          </p>
        </div>
        <div *ngIf="selectedStage === card" class="w-100">
          <form [formGroup]="stageForm" class="d-flex align-items-center">
            <input type="text" class="form-control w-75" formControlName="name">
            <label class="fw-600 text-primary font-size-14 ms-4">Closed Stage</label>
            <mat-checkbox formControlName="is_closed" (change)="onCheckboxChecked($event,i)"></mat-checkbox>
          </form>
        </div>
        <div class="mx-5 cursor" (click)="openAddStageModal(stageAdd, card)">
          <mat-icon class="font-size-24 d-flex align-items-center justify-content-end me-1 cursor"
            *ngIf="selectedStage !== card">edit</mat-icon>
          <mat-icon class="font-size-24 d-flex align-items-center justify-content-end me-1 cursor"
            *ngIf="selectedStage === card">done</mat-icon>
        </div>
        <div (click)="selectedStage = null" class="cursor">
          <mat-icon class="font-size-24 d-flex align-items-center justify-content-end me-1 cursor"
            *ngIf="selectedStage === card">cancel</mat-icon>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center my-1 cursor w-100 p-2 border-radius-6 my-3"
        style="background:rgba(219, 225, 228, 0.39);" (click)="addNew = true; selectedStage = null; initlizeForm()"
        *ngIf="!addNew">
        <span class="p-2 rounded d-flex align-items-center justify-content-center"
          style="background:rgba(219, 225, 228, 0.39);">
          <img src="../../../assets/images/icons_add.svg" width="16">
        </span>
        <p class="text-primary mb-0 ms-2 fw-600">Add Stage</p>
      </div>

      <div *ngIf="addNew" class="d-flex w-100 align-items-center my-3">
        <div class="w-100">
          <form [formGroup]="stageForm" class="d-flex align-items-center">
            <input type="text" class="form-control w-75" formControlName="name">
            <label class="fw-600 text-primary font-size-14 ms-4">Closed Stage</label>
            <mat-checkbox formControlName="is_closed"></mat-checkbox>
          </form>
        </div>
        <div class="mx-5 cursor" (click)="openAddStageModal('new')">
          <mat-icon class="font-size-24 d-flex align-items-center justify-content-end me-1 cursor">done</mat-icon>

        </div>
        <div (click)="addNew = false">
          <mat-icon class="font-size-24 d-flex align-items-center justify-content-end me-1 cursor">cancel</mat-icon>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #stageAdd let-modal>
  <form [formGroup]="stageForm" (ngSubmit)="onSubmitStageForm()">
    <div class="modal-header">
      <h5 class="modal-title mt-0" *ngIf="!stageForm.value.id">
        Add Stage
      </h5>
      <h5 class="modal-title mt-0" *ngIf="stageForm.value.id">
        Edit Stage
      </h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Close click')"></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <label class="mb-2 fw-600 text-primary font-size-14">Name<small class="text-danger">*</small></label>
          <mat-form-field appearance="outline" class="w-100 text-primary">
            <input style="outline: none" matInput placeholder="Stage name" formControlName="name" />
            <mat-error class="error d-flex" *ngIf="submitStageForm && f['name'].errors">
              <mat-icon class="me-1 my-auto">info</mat-icon>
              <span *ngIf="f['name'].errors['required']"> Name is required</span>
              <span *ngIf="f['name'].errors['pattern']">Invalid name</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <label class="mb-2 fw-600 text-primary font-size-14">Closed Stage</label>
          <mat-checkbox formControlName="is_closed"></mat-checkbox>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button class="btn btn-light me-2" type="button" (click)="modal.dismiss('Cross click')">
        Close
      </button>
      <button class="btn btn-primary" type="submit">Save</button>
    </div>
  </form>
</ng-template>
<div class="modal-header">
    <h5 class="modal-title fw-600">{{appointmentId ? appointmentJson.reschedule :
        appointmentJson.schedule}}
        {{appointmentJson.heading_label}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancelAptModal()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="aptForm" (ngSubmit)="onAptSubmit()" class="aptForm">
        <div class="row">

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="!aptForm.value.id">
                <label class="fw-600 mb-2 ">{{appointmentJson.name_label}} <small class="text-danger">*</small></label>
                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="contact_id" placeholder={{appointmentJson.name_placeholder}}
                        #myFirstInput>
                        <mat-option>
                            <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option [value]="data.id" (onSelectionChange)="onContactChange($event,data)"
                            class="filteropt" *ngFor="let data of contactListArray">{{data.first_name}}
                            {{data.last_name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['contact_id'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['contact_id'].errors['required']">{{appointmentJson.name_label}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field> -->
                <ng-select class="form-control" [items]="contactListArray" bindLabel="first_name" [multiple]="false"
                    (change)="onSelectContact($event)" placeholder="Contact name" (search)="searchContact($event)"
                    formControlName="contact_name" (clear)="clearContactFields($event)">
                </ng-select>
                <div class="error d-flex mt-2" *ngIf="submitted && f['contact_name'].errors">
                    <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                    <span *ngIf="f['contact_name'].errors['required']"> Contact Name
                        {{errorJson.required}}</span>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="!aptForm.value.id">
                <label class="fw-600 mb-2 ">{{appointmentJson.email_label}} <small class="text-danger" *ngIf="hasRequiredValidator('email')">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder={{appointmentJson.email_placeholder}}
                        formControlName="email" />
                    <mat-error *ngIf="submitted && f['email'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['email'].errors['required']">{{appointmentJson.email_label}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="!aptForm.value.id">
                <label class="fw-600 mb-2 ">{{appointmentJson.mobile_label}} <small class="text-danger" *ngIf="hasRequiredValidator('phone_number')">*</small></label>
                <div class="d-flex align-items-center">
                    <mat-form-field appearance="outline" class="me-1">
                        <mat-select placeholder="Country code" formControlName="ccode">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredBanks | async"
                                [ngClass]="{'d-none': country.IAC === ''}"
                                value={{country.IAC}}>{{country.IAC}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                            placeholder={{appointmentJson.mobile_placeholder}} formControlName="phone_number">
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phone_number'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['phone_number'].errors['required']">{{appointmentJson.mobile_label}}
                                {{errorJson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <label class="fw-600 mb-2 ">{{appointmentJson.category_label}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="category" placeholder={{appointmentJson.category_placeholder}}
                        (selectionChange)="onCategoryChange($event.value)">
                        <mat-option [value]="item.id" class="filteropt"
                            *ngFor="let item of categoriesArray">{{item.name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['category'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['category'].errors['required']">{{appointmentJson.category_label}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <label class="fw-600 mb-2 ">{{appointmentJson.service_label}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="consultation_id" placeholder={{appointmentJson.service_placeholder}}>
                        <mat-option [value]="item.id" class="filteropt" (onSelectionChange)="onAptSelected(item,$event)"
                            *ngFor="let item of appointments_list">{{item.name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['consultation_id'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['consultation_id'].errors['required']">{{appointmentJson.service_label}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <label class="fw-600 mb-2 ">{{appointmentJson.appointment_type_label}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="meeting_type" (selectionChange)="onAptTypeChange($event.value)"
                        placeholder={{appointmentJson.appointment_type_placeholder}}>
                        <mat-option [value]="item.mode" class="filteropt"
                            *ngFor="let item of selectedconsultation_data">{{item.mode === "ON_LINE" ? 'Online' :
                            'Offline'}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['meeting_type'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['meeting_type'].errors['required']">{{appointmentJson.appointment_type_label}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <label class="fw-600 mb-2 ">{{appointmentJson.assigned_staff_label}} </label>
                <ng-select class="form-control" [ngStyle]="{'opacity' : aptForm.get('staff_id')?.disabled ? '0.5' : '1' }" [items]="staff_info" bindLabel="fname" bindValue="id" [multiple]="false"
                    [clearable]="true" placeholder={{appointmentJson.assigned_staff_placeholder}}
                    formControlName="staff_id" (change)="getSlots()"
                    [ngClass]="{ 'is-invalid border-red': submitted && f['staff_id'].errors }">
                </ng-select>

                <div *ngIf="submitted && f['staff_id'].errors"
                    class="invalid-feedback text-danger d-flex align-items-center">
                    <mat-icon class="font-size-12 d-flex align-items-center me-0 info">info</mat-icon>
                    <div class="font-size-12" *ngIf="f['staff_id'].errors['required']">
                        {{appointmentJson.staff}}
                        {{errorJson.required}}</div>
                </div>
                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="staff_id" placeholder={{appointmentJson.assigned_staff_placeholder}}
                        (selectionChange)="getSlots()">
                        <mat-option [value]="staff.id" class="filteropt" *ngFor="let staff of staff_info">{{staff.fname
                            + '' + staff.lname}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['staff_id'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['staff_id'].errors['required']">{{appointmentJson.staff}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field> -->
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <label class="fw-600 mb-2 ">{{appointmentJson.appointment_details_label}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="apt_details" placeholder={{appointmentJson.details_placeholder}}
                        (selectionChange)="locationChange($event)">
                        <mat-option [value]="item.id" class="filteropt"
                            *ngFor="let item of locationsDetails">{{item.name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['apt_details'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['apt_details'].errors['required']">{{appointmentJson.appointment_details_label}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 meeting-link" >
                <label class="mb-2 fw-600 font-size-14">Meeting Link <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="Meeting link" formControlName="medium_url" />
                    <mat-error class="error d-flex" *ngIf="submitted && f['medium_url'].errors">
                        <mat-icon class="me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['medium_url'].errors['required']"> Meeting link {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div> -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 meeting-link">
                <label class="mb-2 fw-600 font-size-14">Meeting Link <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="Meeting link" formControlName="custom_meeting_url" />
                    <mat-error class="error d-flex" *ngIf="submitted && f['custom_meeting_url'].errors">
                        <mat-icon class="me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['custom_meeting_url'].errors['required']"> Meeting link {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <label class="fw-600 mb-2 ">{{appointmentJson.date_label}} <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <!-- <input ngbDatepicker style="outline: none" placeholder="yyyy-mm-dd" #d="ngbDatepicker"
                        (click)="d.toggle()" matInput placeholder={{appointmentJson.date_placeholder}} type="date"
                        formControlName="date" /> -->
                    <input matInput type="text" placeholder={{appointmentJson.date_placeholder}} matInput
                        [matDatepicker]="picker" formControlName="date" [min]="minDate" [max]="maxDate"
                        (dateChange)="getSlots()">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['date'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['date'].errors['required']">{{appointmentJson.date_label}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>

                <!-- <label class="fw-600 mb-2 ">{{appointmentJson.payment_label}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="payment_mode" placeholder={{appointmentJson.payment_placeholder}}>
                        <mat-option value="ON_LINE" class="filteropt">Online</mat-option>
                        <mat-option value="COD" class="filteropt">COD</mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="slotsData.length !== 0 || noslots">
                <label class="fw-600 mb-2 ">{{appointmentJson.time_label}} <small
                        class="text-danger">*</small></label><br>
                <div class="d-inline-block mb-2" *ngFor="let item of slotsData; let i = index">
                    <input style="outline: none" class="btn-check" type="radio" value="{{item.startTime}}"
                        placeholder={{appointmentJson.time_placeholder}} id="{{ i+1 }}" formControlName="slot" />
                    <label for="{{i+1}}" [ngClass]="{'disable': currentTime >= item.startTime}"
                        class="btn btn-light border-1 mx-1 rounded btn-sm btn-md">{{item.startTime}}</label>
                </div>
                <div class="error d-flex mt-2" *ngIf="noslots">No Slots Available</div>
                <div class="error d-flex mt-2" *ngIf="submitted && f['slot'].errors">
                    <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                    <span *ngIf="f['slot'].errors['required']"> {{appointmentJson.time_label}}
                        {{errorJson.required}}</span>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <button class="btn btn-light me-2" type="button" (click)="cancelAptModal()">
                {{appointmentJson.close_btn}}
            </button>
            <button class="btn btn-primary" type="submit" [disabled]="loading">{{appointmentId ?
                appointmentJson.reschedule :
                appointmentJson.schedule}}</button>
        </div>
    </form>
</div>
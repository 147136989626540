<div style="height: calc(100vh - 200px); overflow:auto" class="crm-timeline mt-2">
	<div *ngIf="orderedTimelineList && orderedTimelineList.length > 0" (scroll)="logScrolling($event)">
		<div class="overflow-auto ps-3 py-3" *ngFor="let timeLine of orderedTimelineList">
			<div class="d-flex align-items-center date-sec w-100 ">
				<mat-icon class="d-flex align-items-center justify-content-center font-size-19" style="width: 20px;">query_builder</mat-icon>
				<p class="m-0 ms-1 font-size-13 fw-600">{{timeLine?.convertedDate}}</p>
			</div>
			<div class="w-100 crm-time-line-body d-flex align-items-center justify-content-between px-4 py-2"
				*ngFor="let timeline of timeLine.timeline">
				<div class="d-flex align-items-center" style="width: 90%;">
					<div
						class="circle-name text-capitalize line-height-1 d-flex align-items-center justify-content-center line-height-1 fw-600 font-size-13">
						{{timeline?.activity?.charAt(0) || 'U'}}
					</div>
					<p class="m-0 line-height-2 ms-1 font-size-13">{{timeline.activity}}</p>
				</div>
				<div class="font-size-12 fw-500" style="color:#8B8B8B">
					{{timeline?.convertedTime}}
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="orderedTimelineList && orderedTimelineList.length < 1" class="d-flex flex-column align-items-center justify-content-center h-100">
		<img src="assets/images/newUI/CRM/empty_timeline.svg" alt="">
		<p class="m-0 font-size-13" style="width: 250px;color: #52525A;">If any changes are done in the task the timeline will reflect here!</p>
	</div>
</div>



<!-- <div style="height: calc(100vh - 248px); overflow:hidden">
    <div class="d-flex justify-content-between align-items-center">
        <h5 class="card-title fw-700 font-size-16">{{timelineJSON.heading}}</h5>
        // <div class="d-flex align-items-center justify-content-end">
        <h5 class="card-title text-light me-3">{{timelineJSON.filter_label}}:</h5>
        <div class="timeline-select">
            <mat-form-field appearance="outline">
                <mat-select [(ngModel)]="timeline_filter" class="pt-1">
                    <mat-option value="All">All</mat-option>
                    <mat-option value="Calls">Calls</mat-option>
                    <mat-option value="Notes">Notes</mat-option>
                    <mat-option value="Task">Task</mat-option>
                    <mat-option value="Events">Events</mat-option>
                    <mat-option value="Files">Files</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div> //
    </div>
    <div class="time-line-flow custom-details-div-height mt-1" infinite-scroll [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="1000" (scrolled)="onScroll()">
        <div class="list-block w-100 pt-2" *ngFor="let item of currentPageTimelineList">
            <div class="row">
                <p class="font-size-10 text-white col-md-2 text-center"></p>
                <div class="col-md-1 pe-0">
                    <div class="border-1 border-radius-50 bg-black m-0 border-circle"></div>
                    <div class="vertical-line"></div>
                </div>
                <div class="col-md-9 ps-0">
                    <h6 class="font-size-13 fw-500 mb-0"><span class="bg-medium p-2 border-radius-8">{{item.on_date__date |
                            dateConversion}}</span></h6>
                </div>
            </div>
            <div class="row" *ngFor="let subitem of item.timeline">
                <p class="font-size-10 color-l-gray col-md-2 text-center">{{subitem.time_stamp | date: 'h:mm a'}}</p>
                <div class="col-md-1 pe-0">
                    <div class="border-light-1 border-radius-50 m-0 border-circle"></div>
                    <div class="vertical-line"></div>
                </div>
                <div class="col-md-9 ps-0">
                    <div class="d-flex">
                        <img src=".\assets\images\Timeline icon 12.svg">
                        <div class="ms-2">
                            <h6 class="font-size-12 fw-500 mb-0">{{subitem.activity}}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            //<div class="d-flex">
            <p class="font-size-10 text-white w-8"></p>
            <div class="px-2 d-flex align-items-center flex-column">
                <div class="border-1 border-radius-50 bg-black m-0 border-circle"></div>
                <div class="vertical-line"></div>
            </div>
            <div class="d-flex">
                <h6 class="font-size-15 fw-500">{{item.on_date__date}}</h6>
            </div>
        </div>
        <div class="d-flex" *ngFor="let subitem of item.timeline">
            <p class="font-size-10 w-8 color-l-gray">{{subitem.time_stamp | date: 'h:mm a'}}</p>
            <div class="px-2 d-flex align-items-center flex-column">
                <div class="border-light-1 border-radius-50 m-0 border-circle"></div>
                <div class="vertical-line"></div>
            </div>
            <div class="d-flex">
                <mat-icon>sticky_note_2</mat-icon>
                <div class="ms-2">
                    <h6 class="font-size-13 fw-500 mb-0">{{subitem.activity}} 
                    </h6>
                </div>
            </div>
        </div>
        </div>//
    </div>
</div> -->
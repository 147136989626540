import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ADDCONTACTJSON } from 'src/app/core/data/contacts_json';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ReplaySubject, Subject, count, debounceTime, finalize, takeUntil } from 'rxjs';
import { countryData } from 'src/app/core/data/countryData';
import { AuthService } from 'src/app/core/service/auth.service';
import { CustomFieldsService } from 'src/app/core/service/custom-fields.service';
import { ErrorLoadImage, maximumNumberAllowed, onOnlyNumbers, onParseDomValue, onlyNumberDecimal, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { time_zone_json } from 'src/app/core/data/mobile_json';
import { CHANNELS_LIST } from 'src/app/core/data/channels_json';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { environment } from 'src/environments/environment';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-contacts-modal',
  templateUrl: './contacts-modal.component.html',
  styleUrls: ['./contacts-modal.component.scss']
})
export class ContactsModalComponent implements OnInit, OnDestroy, AfterViewInit {
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  public bankFilterTimezoneCtrl: FormControl = new FormControl();
  public filteredTimezoneBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onTimezoneDestroy = new Subject<void>();
  @Input() contact: any = {};
  @ViewChildren('editorComponents') editorComponents!: QueryList<CKEditorComponent>;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  countryjson = countryData;
  channels = CHANNELS_LIST;
  timezonejson = time_zone_json;
  title = 'FormArray Example in Angular Reactive forms';
  submitted = false;
  contactForm!: FormGroup;
  addcontact = ADDCONTACTJSON[0];
  errorJson = ERRORJSON[0];
  contact_type : any;
  staffMembers: any = [];
  companiesListArray: any = [];
  info: any = {};
  loading = false;
  @ViewChild('myInput') myInput!: ElementRef;
  loggedInUser: any;
  tagsListArray: any[] = [];
  customFieldsArray: any[] = [];
  systemFieldsArray: any[] = [];
  @Input() chatId!: string;
  ckeditorLimitLists: any = {};
  public Editor: any = ClassicEditor;
  ckconfigQue = {
    height: 200,
    toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
    removeButtons: 'Subscript,Superscript'
  };
  searchSubject: Subject<any> = new Subject<any>();
  presignedurl = environment.preSendUrl;
  editorComponentLists: any[] = [];
  // companyList = false;
  featuresData: any;

  constructor(
    public ngbActiveModal: NgbActiveModal,
    private service: CrmService,
    private settingsService: SettingsService,
    private toaster: ToastrService,
    private authService: AuthService,
    private _customFields: CustomFieldsService,
    private awsUtilService: AwsUtilsService
  ) {
    this.searchSubject.pipe(
      debounceTime(300)
    ).subscribe((searchTerm: any) => {
      if (searchTerm.term.length >= 3) {
        this.service.getCompaniesBySearch(searchTerm.term).subscribe({
          next: (resp: any) => {
            this.companiesListArray = resp.data;
          }
        });
      } else if (searchTerm.term.length === 0) {
        this.getCompaniesList();
      }
    });
    this.getFeatues();
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }

  ngOnInit() {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();

      });
    this.filteredTimezoneBanks.next(this.timezonejson.slice());
    this.bankFilterTimezoneCtrl.valueChanges
      .pipe(takeUntil(this._onTimezoneDestroy))
      .subscribe(() => {
        this.filterTimezoneBanks();
      });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.initlizeContactForm();
    this.getContactTypes();
    this.getCompaniesList();
    this.organisationMembersList();
    this.getTags();

    if (this.contact?.id) {
      // this.contactForm.controls['email'].disable();
      this.contactForm.controls['channel'].disable();
      if (this.contact.owner !== this.loggedInUser.data.id && this.loggedInUser.data.role !== 'ADMIN') {
        this.contactForm.controls["assignee"].disable();
        this.contactForm.controls["owner"].disable();
      }
    } else {
      this.info['owner_info'] = {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      };
    }
    this.getCustomFieldsData();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.myInput.nativeElement.focus();
    }, 0);
    this.editorComponentLists = [];
    this.editorComponents.changes.subscribe((components: QueryList<CKEditorComponent>) => {
      components.forEach((editorComponent: any) => {
        this.editorComponentLists.push(editorComponent);
      });
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onTimezoneDestroy.next();
    this._onTimezoneDestroy.complete();
  }

  keyDownCustomField(event: any, decimalPoint: number, type: string) {
    return onlyNumberDecimal(event, decimalPoint, type);
  }

  keyDownEvent(event: any, maxLength: number) {
    const maxNum = maximumNumberAllowed(event, maxLength);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }

  addFieldValues() {
    const copyCustomFiledArray = this.customFieldsArray.filter((elem: any) => elem.on_form);
    copyCustomFiledArray.forEach(async (field: any, i) => {
      const index = this.contact?.custom_contact_fields?.findIndex(function (item: any) {
        return (item.custom_contact_template === field.id);
        //   return true;
        // }
        // return false;
      });
      if (index >= 0) {
        if (this.contact.custom_contact_fields[index].data_type === 'DATE_RANGE') {
          this.custom_contact_fields.controls[i].patchValue({
            start: this.contact.custom_contact_fields[index].data !== "NA" ? this.contact.custom_contact_fields[index].data.split('&')[0] : "",
            end: this.contact.custom_contact_fields[index].data !== "NA" ? this.contact.custom_contact_fields[index].data.split('&')[1] : "",
            data: this.contact.custom_contact_fields[index].data !== "NA" ? this.contact.custom_contact_fields[index].data : "",
            id: this.contact.custom_contact_fields[index].id
          });
        } else if (this.contact.custom_contact_fields[index].data_type === 'PHONE_NUMBER') {
          this.custom_contact_fields.controls[i].patchValue({
            start: this.contact.custom_contact_fields[index].data !== "NA" ? this.contact.custom_contact_fields[index].data?.custom_ccode : "",
            end: this.contact.custom_contact_fields[index].data !== "NA" ? this.contact.custom_contact_fields[index].data.custom_phone_number : "",
            data: this.contact.custom_contact_fields[index].data !== "NA" ? this.contact.custom_contact_fields[index].data : "",
            id: this.contact.custom_contact_fields[index].id
          });
        } else {
          this.custom_contact_fields.controls[i].patchValue({
            data: this.contact.custom_contact_fields[index].data !== "NA" ? this.contact.custom_contact_fields[index].data : "",
            id: this.contact.custom_contact_fields[index].id
          });
        }
      }
    });

    // this.systemFieldsArray.forEach(async (field: any, i) => {
    //   let count = 0;
    //   if (!field[1].is_default && field[1].on_form) {


    //   }

    // })
  }

  preventDefault() {
    return false;
  }

  async onReady(editor: any, index: any) {
    const object = {
      [index]: {
        maxLimit: 1000,
        actualLimit: 0,
        type: false
      }
    };
    Object.assign(this.ckeditorLimitLists, object);
    const dataWithoutTags = await onParseDomValue(editor.data.get());
    console.log(dataWithoutTags.length);
    this.ckeditorLimitLists[index]['actualLimit'] = dataWithoutTags?.length || 0;
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const edittedItem = this.editorComponentLists.find((elem: any) => elem.editorInstance.id === editor.id);
      const contentLength = pasteEventInCkeditor(edittedItem, pasteContent);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { ...contentLength });
    });
  }

  editorkeyEvent(editor: any, editorComponents: any, index: any) {
    const edittedItem = this.editorComponentLists.find((elem: any) => elem.id === editorComponents.id);
    if (edittedItem) {
      const inputData = edittedItem.editorInstance.getData();
      const dataWithoutTags = onParseDomValue(inputData);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { actualLimit: dataWithoutTags.length || 0 });
    }
  }

  initlizeContactForm() {
    this.contactForm = new FormGroup({
      id: new FormControl(this.contact ? this.contact.id : ''),
      first_name: new FormControl(this.contact?.first_name ? this.contact.first_name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9-'\s]{1,}$/)]),
      last_name: new FormControl(this.contact?.last_name ? this.contact.last_name : '', [Validators.pattern(/^[A-Za-z][A-Za-z-'\s]*$/)]),
      email: new FormControl(this.contact?.email ? this.contact.email : '', Validators.compose([Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])),//eslint-disable-line 
      // company_id: new FormControl(this.contact?.company ? this.contact.company : ''),
      company_name: new FormControl(this.contact?.company_name ? this.contact.company_name : ''),
      phone_number: new FormControl(this.contact?.phone_number ? this.contact.phone_number : '',Validators.compose([Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])),//Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])
      tags: new FormControl(this.contact ? this.contact.tags : ''),
      owner: new FormControl(this.contact?.owner ? this.contact.owner : this.loggedInUser.data.id, [Validators.required]),
      assignee: new FormControl(this.contact?.assignee ? this.contact.assignee : []),
      channel: new FormControl(this.contact?.channel ? this.contact.channel : 'manual'),
      contact_type: new FormControl(this.contact?.contact_type ? this.contact.contact_type : ''),
      custom_contact_fields: new FormArray([]),
      system_contact_fields: new FormArray([]),
      ccode: new FormControl(this.contact?.ccode ? (this.contact.ccode.includes('+') ? this.contact?.ccode : '+' + this.contact.ccode) : this.authService.getCompanyData().ccode),
      display_picture: new FormControl(this.contact?.display_picture ? this.contact.display_picture : '')
    });
    if (this.loggedInUser.customer_primary_login_type === 'email') {
      this.contactForm.controls['email'].setValidators(Validators.required);
      this.contactForm.controls['email'].updateValueAndValidity();
      if (this.contact?.id) this.contactForm.controls['email'].disable();
    } else {
      this.contactForm.controls['phone_number'].setValidators(Validators.required);
      this.contactForm.controls['phone_number'].updateValueAndValidity();
      if (this.contact?.id) {
        this.contactForm.controls['phone_number'].disable(); this.contactForm.controls['ccode'].disable();
      }
    }
  }

  get custom_contact_fields(): FormArray {
    return this.contactForm.get("custom_contact_fields") as FormArray;
  }

  get system_contact_fields(): FormArray {
    return this.contactForm.get("system_contact_fields") as FormArray;
  }

  custom_fields(data: any): FormGroup {
    const tempData = data?.data ?
      (data.data !== "NA" ?
        (data.data_type === 'CHECK_BOX' ?
          [] : data.data_type === 'RADIO_BUTTON' ?
            data.data ? true : false : data.data) : "") : data?.data === false ? false : "";
    const returnData = new FormGroup({
      id: new FormControl(""),
      label: new FormControl(data?.label ? data.label : ''),
      data_type: new FormControl(data?.data_type ? data.data_type : ''),
      options: new FormControl(data?.options ? data.options : ''),
      on_form: new FormControl(data?.on_form ? data.on_form : false),
      is_inner_screen: new FormControl(data?.is_inner_screen ? data.is_inner_screen : false),
      data: new FormControl(tempData),
      is_mandatory: new FormControl(data?.is_mandatory ? data.is_mandatory : false),
      is_active: new FormControl(data?.is_active ? data.is_active : true),
      custom_contact_template: new FormControl(data?.id ? data.id : ''),
      contact: new FormControl(this.contact?.id),
      start: new FormControl(''),
      end: new FormControl('')
    });
    return returnData;
  }

  addfieldsData(data: any) {
    this.custom_contact_fields.push(this.custom_fields(data));
  }

  get f() {
    return this.contactForm.controls;
  }

  addNewCompanyName = (name: any) => ({ name: name, id: '' });

  onSelectCompany(e: any) {
    this.contactForm.patchValue({ company_id: e.id, company_name: e.name });
  }

  onSubmit() {
    const inValidCkEditor = Object.entries(this.ckeditorLimitLists).find((ckeditorLimit: any) => ckeditorLimit[1]?.actualLimit > ckeditorLimit[1]?.maxLimit);
    if (!inValidCkEditor) {
      const submit_value = this.contactForm.value;
      submit_value.custom_contact_fields.forEach((obj: any, i: number) => {
        if (obj.data_type === "DATE_RANGE") {
          if (obj.end) {
            obj.data = this.convert(obj.start) + '&' + this.convert(obj.end);
          } else if (obj.start) {
            obj.data = this.convert(obj.start);
          }
          this.custom_contact_fields.controls[i].patchValue({ data: obj.data });
        } else if (obj.data_type === "PHONE_NUMBER") {
          obj.data = { custom_ccode: obj.start, custom_phone_number: obj.end };
          this.custom_contact_fields.controls[i].patchValue({ data: obj.data });
        }
        // if (obj.data === "" || (!obj.data && typeof obj.data !== 'boolean')) {
        //   obj.data = "NA";
        // }
      });

      // submit_value?.system_contact_fields?.forEach((obj: any) => {
      //   //   if (obj.data === "" || (!obj.data && typeof obj.data !== 'boolean')) {
      //   //     obj.data = "NA";
      //   //   }
      //   if (obj.data_type === 'RADIO_BUTTON' && obj.options.length === 0 && !obj.data) {
      //     obj.data = false;
      //   }
      // });


      this.contactForm.value?.system_contact_fields?.forEach((fields: any, index: any) => {
        submit_value[fields.label] = fields.data;
        if (fields.data_type === 'RADIO_BUTTON' && fields.options.length === 0 && !fields.data) {
          // fields.data = false;
          submit_value[fields.label] = false;
        } else if (fields.data_type === "SELECT_DROPDOWN" && fields.label === "company") {
          fields.data = this.contactForm.value.company_name;
          this.system_contact_fields.controls[index].patchValue({ data: this.contactForm.value.company_name });
        }
      });
      delete submit_value.system_contact_fields;

      this.contactForm.markAllAsTouched();
      this.submitted = true;
      if (this.contactForm.valid) {
        this.loading = true;
        const assignee_departments: any = [];
        let valid_assignee: any;
        let valid_owner: any;

        if (this.contactForm.controls['assignee'].status === 'DISABLED') {
          valid_assignee = this.contactForm.controls['assignee'].value;
        } else {
          valid_assignee = this.contactForm.value.assignee;
        }

        if (this.contactForm.controls['owner'].status === 'DISABLED') {
          valid_owner = this.contactForm.controls['owner'].value;
        } else {
          valid_owner = this.contactForm.value.owner;
        }

        if (valid_assignee.length !== 0) {
          this.staffMembers.forEach((item: any) => {
            valid_assignee.filter((assigne: any) => {
              if (assigne === item.id) {
                assignee_departments.push(item.department_info.id ? item.department_info.id : item.utype === 'ADMIN' ? this.loggedInUser.data.organisation : '');
              }
            });
          });
        }

        const owner_department = this.staffMembers.filter((member: any) => member.id === valid_owner);

        // eslint-disable-next-line no-shadow
        // const submit_value = this.contactForm.value;


        // submit_value.custom_contact_fields.forEach((obj: any) => {
        //   if (obj.data === "") {
        //     obj.data = "NA";
        //   }
        // });

        this.contactForm.controls['email'].setValue(this.contactForm.value.email?.toLowerCase());

        if (this.contactForm.value.id) {
          let info_body;
          if (Object.keys(this.info).length === 0) {
            info_body = this.contact.info;
          } else {
            info_body = this.info;
          }

          this.service.updateContact({ ...submit_value, assignee: valid_assignee, owner: valid_owner, info: info_body, email: this.contactForm.controls["email"].value, channel: this.contactForm.controls["channel"].value, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0]?.department_info?.id ? owner_department[0]?.department_info?.id : owner_department[0]?.utype === 'ADMIN' ? this.loggedInUser.data.organisation : '' }).pipe(finalize(() => this.loading = false)).subscribe({
            next: () => {
              this.info = {};
              this.passBack();
              this.ngbActiveModal.dismiss();
            }
          });
        } else {
          this.service.createContact({ ...submit_value, info: this.info, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0]?.department_info.id ? owner_department[0].department_info.id : owner_department[0]?.utype === 'ADMIN' ? this.loggedInUser.data.organisation : '' }).pipe(finalize(() => this.loading = false)).subscribe((resp: any) => {
            if (resp.status === 200) {
              this.toaster.error(resp.message);
            } else {
              this.toaster.success(resp.message);
              const iframe: any = document.getElementById('chat-widget-value');
              resp.data.chatId = this.chatId;
              iframe?.contentWindow.postMessage({ ...resp, type: "contactCreated" }, '*');
              this.passBack();
              this.ngbActiveModal.dismiss();
            }
          }, (err: any) => {
            this.toaster.error(err.message);
          });
        }
      }
    }
  }

  hasRequiredValidator(controlName: string): boolean {
    const control = this.contactForm.get(controlName);
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      return !!(validator && validator['required']);
    }
    return false;
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  getCompaniesList() {
    this.service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.companiesListArray = resp.data;
        if (this.contact?.id && this.contact?.company) {
          if (resp.data.filter((ele: any) => ele.id === this.contact?.company).length === 0) this.getCompanyById(this.contact.company);
        }

      }
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  // eslint-disable-next-line no-shadow
  custom_array(count: number): any {
    return this.custom_contact_fields.controls[count] as FormArray;
  }

  // eslint-disable-next-line no-shadow
  system_array(count: number): any {
    return this.system_contact_fields.controls[count] as FormArray;
  }

  getCustomFieldsData() {
    this._customFields.getContactModelFields().subscribe({
      next: (resp: any) => {
        this.customFieldsArray = resp.data.custom_contact_templates;
        this.systemFieldsArray = Object.entries(resp.data);
        // eslint-disable-next-line no-shadow
        let count = 0;
        this.systemFieldsArray.map((field: any) => {
          if (!field[1].is_default && field[1].on_form) {
            this.system_contact_fields.push(this.custom_fields({ ...field[1], label: field[0], data: this.contact ? this.contact[field[0]] : "" }));
            if (field[1].is_mandatory) {
              this.system_array(count).controls['data'].setValidators(Validators.required);
              this.system_array(count).controls['data'].updateValueAndValidity();
            }
            count++;
          }
        });

        if (this.customFieldsArray) {
          // eslint-disable-next-line no-shadow
          let count = 0;
          for (const data of this.customFieldsArray) {
            if (data.on_form) {
              this.addfieldsData(data);
              if (data.data_type === 'WEBSITE') {
                this.custom_array(count).controls['data'].setValidators(Validators.pattern(/^(https?:\/\/)?(www\.)?((([a-zA-Z0-9]+\.)+[a-zA-Z]{2,})|localhost)(:[0-9]{1,5})?(\/.*)?$/));
                this.custom_array(count).controls['data'].updateValueAndValidity();
              }
              if (data.is_mandatory) {
                this.custom_array(count).controls['data'].setValidators(Validators.required);
                this.custom_array(count).controls['data'].updateValueAndValidity();
              }
              count++;
            }
          }
        }
        if (this.contact) {
          this.addFieldValues();
        }
      }
    });
  }

  //custom & system type File upload function
  onFileUpload(ev: any, index: number, field: any) {
    if (!ev.target.files[0]) return;
    if (ev.target.files[0].size > 15 * 1048576) {
      this.toaster.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    /************************ For ngx-dropzone ********************/
    // if (!ev.addedFiles[0]) return;
    // if (ev.addedFiles[0].size > 15 * 1048576) {
    //   this.toaster.error("File size is too large, please upload a file size less than 15MB");
    //   return;
    // }

    this.awsUtilService.getUploadFileLink(ev.target.files[0].type, ev.target.files[0].name.split('.')[1]).subscribe({
      next: (resp) => {
        if (field.value?.custom_contact_template) {
          //Custom Field
          this.custom_contact_fields.controls[index].patchValue({ data: { path: this.presignedurl + resp.data.file_name, file_name: ev.target.files[0].name } });
        } else {
          this.system_contact_fields.controls[index].patchValue({ data: this.presignedurl + resp.data.file_name });
        }
        this.awsUtilService.uploadFile(ev.target.files[0], resp.data.url).subscribe({
          next: () => {//@typescript-eslint/no-empty-function
          }
        });
      }
    });
  }

  getAcceptAttribute(field: any) {
    if (field.value?.custom_contact_template) {
      return '';
    } else {
      return '.pdf';
    }
  }

  toggleCheckbox(value: string, i = 0) {
    const selectedOptionsControl = this.custom_array(i).get('data');
    const currentValue = selectedOptionsControl.value;


    if (currentValue.includes(value)) {
      selectedOptionsControl.setValue(currentValue.filter((item: any) => item !== value));
    } else {
      currentValue.push(value);
      selectedOptionsControl.setValue(currentValue);
    }
  }

  // onCompanySelect(company: any) {
  //   this.info['company_info'] = company;
  // }

  onOwnerSelect(owner: any) {
    this.info['owner_info'] = {
      fname: owner.fname,
      lname: owner.lname
    };
    if (this.contactForm.value?.owner !== this.loggedInUser.data.id && this.loggedInUser.data.role !== 'ADMIN') {
      this.contactForm.controls['assignee'].disable();
    } else {
      this.contactForm.controls['assignee'].enable();
    }
  }

  passBack() {
    this.passEntry.emit(true);
  }

  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterTimezoneBanks() {
    if (!this.timezonejson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterTimezoneCtrl.value;
    if (!search) {
      this.filteredTimezoneBanks.next(this.timezonejson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    const filteredTimezones = this.timezonejson.map((element: any) => {
      const filteredCountries = element.countries.filter((bank: any) => bank.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
      return { ...element, countries: filteredCountries };
    }).filter((element: any) => element.countries.length > 0);

    this.filteredTimezoneBanks.next(filteredTimezones);
  }

  getTags() {
    this.settingsService.getTags('contact').subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }

  getFieldValidity(arrayName: any, i: number) {
    return (<FormArray>this.contactForm.get(arrayName)).controls[i].invalid;
  }

  // searchCompany(term: any) {
  //   if (term.term.length >= 3) {
  //     this.service.getCompaniesBySearch(term.term).subscribe({
  //       next: (resp: any) => {
  //         this.companiesListArray = resp.data;
  //       }
  //     });
  //   } else if (term.term.length === 0) {
  //     this.getCompaniesList();
  //   }
  // }

  getCompanyById(id: string) {
    this.service.getCompanyById(id).subscribe({
      next: (company_id_info: any) => {
        if (company_id_info.data.length > 0) {
          this.companiesListArray.push(company_id_info.data[0]);
          this.companiesListArray = [...this.companiesListArray];
        }
      }
    });
  }

  clearCompany(e: any) {
    this.contactForm.patchValue({ company_name: '' });
  }

  onErrorLoadImage(event: any, target: any, file: any) {
    if (file) event.target.src = ErrorLoadImage(target, file);
  }

  onProfilePicUpload(ev: any) {
    this.awsUtilService.getUploadFileLink(ev.target.files[0].type, ev.target.files[0].name.split('.')[1]).subscribe((resp) => {
      this.awsUtilService.uploadFile(ev.target.files[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
        this.contactForm.controls['display_picture'].setValue(this.presignedurl + resp.data.file_name);
      });
    });
  }

  onRemoveImage(){
    this.contactForm.controls['display_picture'].setValue('');
  }

  getContactTypes(){
    this.service.getContactTypes().subscribe((resp: any)=>{
      this.contact_type = resp.data;
      this.contact_type.filter((ele: any)=>{
        if(ele.name === 'Partial') {
          if(ele.id === this.contactForm.value.contact_type && this.contact?.id){
            this.contactForm.controls['email'].enable();
            this.contactForm.controls['phone_number'].enable();
            this.contactForm.controls['ccode'].enable();
          }
        }
      });
    });
  }
}

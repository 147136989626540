import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CONTACTSJSON } from 'src/app/core/data/contacts_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { ExportItemSelectionComponent } from '../../shared/export-item-selection/export-item-selection.component';
import { CustomFiltersComponent } from '../../shared/custom-filters/custom-filters.component';

@Component({
  selector: 'app-contact-groups',
  templateUrl: './contact-groups.component.html',
  styleUrls: ['./contact-groups.component.scss']
})
export class ContactGroupsComponent implements OnInit,OnChanges {
  contactsJson = CONTACTSJSON[0];
  groupList: any[] = [];
  selectedItem: any;
  groupName: any;
  duplicateName = '';
  originalList: any[] = [];
  @Input() refresh!: boolean;
  @Input() isInnerScreen!: boolean;
  @Input() searchSubject!: Subject<any>;
  filters_modal: any;
  column_filters: any;
  customFiltersArray: any;

  constructor(private service: CrmService, private modalService: NgbModal, private confirmationMsz: ConfirmationMszService) {

  }

  ngOnInit() {
    this.getList();
    this.searchSubject && this.searchSubject.subscribe((text: any) => {
      if (text.length >= 2) {
        this.groupList = this.originalList.filter((elem: any) => elem.name.toLowerCase().includes(text.toLowerCase()));
      } else {
        this.groupList = JSON.parse(JSON.stringify(this.originalList));
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refresh']?.currentValue) this.getList();
  }

  getList() {
    this.service.getGroupList().subscribe((resp: any) => {
      this.groupList = resp.data;
      this.originalList = JSON.parse(JSON.stringify(resp.data));
    });
  }

  onDuplicateList(container: any, item: any) {
    this.selectedItem = item;
    this.modalService.open(container, { size: 'md', centered: true });
  }

  duplicatingList() {
    const payload = {
      new_group_name: this.duplicateName,
      group_id: this.selectedItem.id
    };
    this.service.duplicateContactList(payload).subscribe({
      next: () => {
        this.modalService.dismissAll();
        this.getList();
        this.duplicateName = '';
        this.selectedItem = null;
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  onGroupEdit(addtolistModel: any, item: any) {
    this.getAllContactFields();
    this.selectedItem = item;
    this.modalService.open(addtolistModel, { size: 'md' });
  }

  updateToList() {
    this.service.updateGroup(this.selectedItem).subscribe((result: any) => {
      console.log(result, "result");
      this.getList();
      this.modalService.dismissAll();
    });
  }

  confirmDelete(group_id: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteGroup(group_id);
      }
    });
  }

  deleteGroup(id: string) {
    this.service.deleteGroup(id).subscribe(() => {
      this.getList();
    });
  }
  downloadContacts(group_id: string) {
    this.service.getTableColoums('contact').subscribe((resp: any) => {
      // let count = this.contactsObj.total;
      // const selectedCheckbox = Object.keys(this.checkBoxStorageKeeper).length;
      // if (this.checkBoxStorageKeeper && selectedCheckbox > 0) {
      //   count = selectedCheckbox;
      // }
      const modalRef = this.modalService.open(ExportItemSelectionComponent, { size: 'md', centered: true });
      modalRef.componentInstance.payload = {
        type: 'contact',
        group_id: group_id,
        data: resp.data.customized_fields,
        disabledItems: ['display_id', 'first_name', 'email', 'contact_type', 'tags'],
        checkBoxStorageKeeper: [],
        message: `Contacts will be exported`
      };
    });

  }

  getAllContactFields() {
    this.customFiltersArray = [];
    this.service.getContactsFileds().subscribe({
      next: (resp: any) => {
        for (const [key, value] of Object.entries(resp.data)) {
          let formatted_obj: any = {};
          let replaced_text: any;
          if (key === 'custom_contact_templates') {
            const custom_fields_list: any = value;
            custom_fields_list.forEach((element: any) => {
              formatted_obj = {};
              formatted_obj['id'] = element.id;
              formatted_obj['display_label'] = element.label;
              formatted_obj['custom_input'] = true;
              formatted_obj['data_type'] = element.data_type;
              formatted_obj['is_mandatory'] = element.is_mandatory;
              formatted_obj['options'] = element.options;
              if (element?.on_form) {
                this.customFiltersArray.push(formatted_obj);
              }
            });
          } else if (key === 'id' || key === 'organisation' || key === 'contact_fields_order' || key === 'display_picture') {
            console.log("");

          } else {
            const system_fields_value: any = value;
            if (key.includes('_')) {
              replaced_text = key.replace('_', " ");
            } else {
              replaced_text = key;
            }
            formatted_obj['id'] = key;
            formatted_obj['display_label'] = replaced_text;
            formatted_obj['custom_input'] = false;
            formatted_obj['data_type'] = system_fields_value.data_type;
            formatted_obj['is_mandatory'] = system_fields_value.is_mandatory;
            if (system_fields_value?.on_form) {
              this.customFiltersArray.push(formatted_obj);
            }
          }
        }
        const created_at = {
          id: 'created_at',
          is_mandatory: true,
          display_label: 'Created at',
          custom_input: false,
          data_type: 'DATE_TIME'
        };
        const updated_at = {
          id: 'updated_at',
          is_mandatory: true,
          display_label: 'Updated at',
          custom_input: false,
          data_type: 'DATE_TIME'
        };
        this.customFiltersArray.push(created_at);
        this.customFiltersArray.push(updated_at);
      }
    });
  }

  openFiltersModal(type: string) {
    this.column_filters = this.selectedItem.list_filter;
    this.filters_modal = this.modalService.open(CustomFiltersComponent, { size: 'lg', scrollable: true, centered: true });
    this.filters_modal.componentInstance.filters_custm = this.column_filters;
    this.filters_modal.componentInstance.filterType = type;
    this.filters_modal.componentInstance.customFiltersArray = this.customFiltersArray;
    this.filters_modal.componentInstance.is_filter_for = 'contact';
    this.filters_modal.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        if (receivedEntry.type === 'save') {
          this.column_filters = receivedEntry.data.filter;
        } else {
          this.column_filters = [];
        }
        this.service.updateGroup({ id: this.selectedItem.id, list_filter: receivedEntry.data.filter }).subscribe(() => {
          this.getList();
          this.modalService.dismissAll();
        });
      }
    });
  }
}

<div class="mt-3 role-permission">
    <div *ngIf="templateRolesListType">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-9 d-flex justify-content-end align-items-center">
                <button class="btn btn-primary d-flex align-items-center me-3" (click)="openModal(createNewRole)"
                    *ngIf="loggedInUser.data.role === 'ADMIN'">
                    <mat-icon>add</mat-icon>
                    Add New role
                </button>
            </div>

        </div>
        <div class="table-responsive table-height mt-4 roles-table">
            <table class="table align-middle table-nowrap table-hover mb-0 users-table w-100">
                <thead>
                    <tr>
                        <ng-container *ngFor="let col of table_cols">
                            <th scope="col" class="text-primary pb-3"
                                *ngIf="!(col === 'Actions' && loggedInUser.data.role !== 'ADMIN')">
                                {{col}}</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of rolesList; let i = index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.staffs_active_count }}</td>
                        <td>{{ item.staffs_inactive_count }}</td>
                        <td *ngIf="loggedInUser.data.role === 'ADMIN'">
                            <div class="d-flex align-items-center" *ngIf="!item.default_role">
                                <mat-icon class="ms-1 font-size-16 cursor"
                                    (click)="openModal(createNewRole,item)">edit</mat-icon>
                                <mat-icon class="font-size-16 cursor"
                                    (click)="deleteSelectedType(item.id)">delete</mat-icon>
                                <!-- <mat-icon class="font-size-18 cursor" matTooltip="Permissions"
                                    (click)="addRole(false,item.id)">accessible</mat-icon> -->
                                <img src="../../../assets/images/permissions.svg" width="16" class="cursor mb-2"
                                    matTooltip="Permissions" (click)="addRole(false,item.id)">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="!templateRolesListType">
        <div class="addRoles">
            <div class="my-3 w-80 m-auto">
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex text-light">
                            <label class="cursor"
                                (click)="addRole(true)">Roles</label>&nbsp;>&nbsp;<label>Permissions</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="me-3 mat-hint-none">
                            <input style="outline: none" matInput placeholder="Search" [(ngModel)]="permission_search"
                                (ngModelChange)="searchName($event)" />
                            <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img
                                    src="assets/images/search_log.svg" alt=""></mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <form [formGroup]="permissionForm" (ngSubmit)="addnewRoleSubmit()" class="mt-3">
                    <div class="table-responsive table-height mt-2 roles-table mb-3">
                        <table class="table align-middle table-nowrap mb-0 users-table w-100">
                            <thead style="top: -11px;">
                                <tr>
                                    <th scope="col" *ngFor="let col of add_role_table_cols" class="text-primary pb-3">
                                        {{col}}</th>
                                </tr>
                            </thead>
                            <tbody formArrayName="permissions">
                                <tr class="cursor" *ngFor="let item of formPermissions().controls; let i = index"
                                    [formGroupName]="i">
                                    <td>{{ formPermissions().value[i].name }}</td>
                                    <td>
                                        <section class="example-section">
                                            <!-- <p class="mb-0"><mat-checkbox (change)="onCheckboxChecked($event,i)"
                                                    formControlName="admin_access">Admin</mat-checkbox>
                                            </p> -->
                                            <div class="d-flex">
                                                <p class="mb-0"><mat-checkbox
                                                        formControlName="can_read">View</mat-checkbox>
                                                </p>
                                                <mat-form-field appearance="outline"
                                                    class="mat-hint-none button-type ms-3"
                                                    *ngIf="!['build-your-app-set-up','build-your-bot-set-up','smart-forms-set-up','smart-pop-ups-set-up','flow-automation-set-up','marketing-automation-set-up','push-notification-set-up','bot-chats','live-chats','messages','flows'].includes(formPermissions().value[i].key)">
                                                    <mat-select placeholder="Select" formControlName="can_access"
                                                        [disabled]="!formPermissions().controls[i].get('can_read')?.value">
                                                        <mat-option value="view_self">
                                                            Own
                                                        </mat-option>
                                                        <mat-option value="view_all">
                                                            Global
                                                        </mat-option>
                                                        <mat-option value="view_team">
                                                            Team
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <p class="mb-0"><mat-checkbox
                                                    formControlName="can_create">Create</mat-checkbox>
                                            </p>
                                            <p class="mb-0"><mat-checkbox
                                                    formControlName="can_update">Edit</mat-checkbox></p>
                                            <p class="mb-0"><mat-checkbox
                                                    formControlName="can_delete">Delete</mat-checkbox>
                                            </p>
                                        </section>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-light me-3" (click)="addRole(true)">Cancel</button>
                        <button type="submit" class="btn btn-primary">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-template let-modal #createNewRole>
    <div class="modal-header">
        <h5 class="modal-title text-primary"><span *ngIf="addRoleForm.value.id">Update</span>
            <span *ngIf="!addRoleForm.value.id">Create</span> New Role
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="addRoleForm" class="mt-3">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <label class="fw-600 mb-2">Role Name <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder='Enter Role Name' formControlName="name" />
                        <mat-error class="error text-danger d-flex" *ngIf="submited && f['name'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['name'].errors['required']">Role Name
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end mb-3">
        <button type="button" class="d-flex align-items-center btn btn-primary small" (click)="createRole()">
            <span *ngIf="addRoleForm.value.id">Update</span>
            <span *ngIf="!addRoleForm.value.id">Create</span>
        </button>
    </div>
</ng-template>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@twilio/voice-sdk';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';
import { ChatService } from './chat.services';

@Injectable({
  providedIn: 'root'
})
export class TwilioVoiceService {
  device!: Device;
  twilioToken = new BehaviorSubject<any>(null);
  activeConnection: any;
  voicecallStatus = new BehaviorSubject<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  userDetails: any = JSON.parse(sessionStorage.getItem('currentUser')!);
  callStartTime!: number;
  private callTimer: any;
  ongoingCallDuration = new BehaviorSubject<any>('00:00:00');
  incomingConnection: any;
  incomingConnectionAvailable = new BehaviorSubject<any>(null);
  twilioConnection = new BehaviorSubject<any>(false);
  callerConnectionId = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private chatservice: ChatService) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.userDetails = JSON.parse(sessionStorage.getItem('currentUser')!);
  }

  initializeDevice(token: string) {
    this.device = new Device(token);
    this.device.register();
    this.device.on(Device.EventName.TokenWillExpire, async () => {
      console.log("token expires");
    });
    this.device.on('ready', () => console.log('Twilio Device Ready'));
    this.device.on('error', (error) => {
      console.error('Twilio Device Error:', error);
      if (error.code === 31005) { // Network error
        console.error('Network connectivity issue:', error.message);
      }
    });
    this.device.on('tokenWillExpire', () => {
      this.getTwilioToken();
    });
    this.device.on('incoming', async (call: any) => {
      this.incomingConnection = call;
      this.incomingConnectionAvailable.next(call);
      this.voicecallStatus.next((await (this.incomingConnection)).status());
    });
  }

  async makeCall(data: any) {
    if (this.device) {
      try {
        this.activeConnection = await this.device.connect(
          {
            params: { To: data.to, From: data.from, organisation: this.userDetails?.data?.organisation, staffId: this.userDetails?.data?.id, contactId: data.contact_id, location: "India" },
            rtcConstraints: {
              audio: true
            }
          });
        this.callerConnectionId.next({ contact_id: data.contact_id, number: data.to, userName: data?.contact_name });
        this.voicecallStatus.next((await (this.activeConnection)).status());
        console.log(this.activeConnection, "connection");
        // (await  this.activeConnection).accept();
        console.log((await this.activeConnection).status());

        (await this.activeConnection).on('ringing', async () => {
          console.log((await (this.activeConnection)).status(), "call ringing");

        });
        (await this.activeConnection).on('accept', async () => {
          this.voicecallStatus.next((await (this.activeConnection)).status());
          sessionStorage.setItem('CallSid', this.activeConnection.parameters.CallSid);
          this.callStartTime = Date.now();
          this.startCallTimer();
          console.log((await (this.activeConnection)).status(), "call accepted");
        });
        (await this.activeConnection).on('disconnect', async () => {
          this.voicecallStatus.next((await (this.activeConnection)).status());
          this.stopCallTimer();
        });
        this.activeConnection.on('reject', () => {
          console.log('Call rejected');
        });
        (await this.activeConnection).on('error', async (error: any) => {
          console.error('Connection Error:', error);
          this.voicecallStatus.next((await (this.activeConnection)).status());
        });
        // this.activeConnection.mute(true).catch((error: any) => {
        //   console.error('Failed to mute the connection:', error);
        // });
        this.device.on('incoming', async (call: any) => {
          this.incomingConnection = call;
          this.incomingConnectionAvailable.next(call);
          this.voicecallStatus.next((await (this.incomingConnection)).status());
          console.log(call, " incoming call");
        });
      } catch (error) {
        console.error('Error making call:', error);
      }
    }
  }

  startCallTimer() {
    this.callTimer = setInterval(async () => {
      if (this.callStartTime) {
        const elapsedTime = Date.now() - this.callStartTime;
        this.ongoingCallDuration.next(this.formatDuration(elapsedTime));
      }
    }, 1000); // Update every second
  }

  stopCallTimer() {
    if (this.callTimer) {
      clearInterval(this.callTimer);
      this.callTimer = null;
    }
  }

  formatDuration(ms: number): string {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }
  pad(value: number): string {
    return String(value).padStart(2, '0');
  }
  getTwilioToken() {
    this.http.get(`${environment.messageUrl}${constant.twilio_token}`).subscribe((resp: any) => {
      this.twilioToken.next(resp.data?.token);
      this.initializeDevice(resp.data?.token);
    });
  }

  getTwilioFromNumbers() {
    return this.http.get(`${environment.messageUrl}${constant.twilio_from_numbers}`);
  }

  async hangUpCall() {
    if (this.activeConnection) {
      this.activeConnection.disconnect();
      this.activeConnection = null;
      // this.device.disconnectAll();//working
    } else if (this.incomingConnection) {
      this.incomingConnection.disconnect();
      this.incomingConnection = null;
    }
  }

  muteCall() {
    if (this.activeConnection) {
      this.activeConnection.mute(!this.activeConnection.isMuted());
      return this.activeConnection.isMuted();
    } else if (this.incomingConnection) {
      this.incomingConnection.mute(!this.incomingConnection.isMuted());
      return this.incomingConnection.isMuted();
    }
  }

  sendDigits(digits: string) {
    if (this.activeConnection) {
      this.activeConnection.sendDigits(digits);
    } else if (this.incomingConnection) {
      this.incomingConnection.sendDigits(digits);
    }
  }

  postCallFeedback(body: any) {
    return this.http.patch(`${environment.messageUrl}${constant.twilio_callhistory}`, body);
  }

  getContactCallHistory(contactId?: string, current_page?: number) {
    if (contactId) {
      return this.http.get(`${environment.messageUrl}${constant.twilio_callhistory}?contact=${contactId}`);
    } else {
      return this.http.get(`${environment.messageUrl}${constant.twilio_callhistory}?page=${current_page || 1}&limit=20`);
    }
  }

  async acceptCall() {
    console.log(this.incomingConnection);
    if (this.incomingConnection) {
      this.incomingConnection.accept();
      // this.voicecallStatus.next((await (this.incomingConnection)).status());
      // this.callStartTime = Date.now();
      // this.startCallTimer();
      // this.voicecallStatus.next((await (this.incomingConnection)).status());
      (await this.incomingConnection).on('accept', async () => {
        sessionStorage.setItem('CallSid', this.incomingConnection.parameters.CallSid);
        this.voicecallStatus.next((await (this.incomingConnection)).status());
        this.callStartTime = Date.now();
        this.startCallTimer();
        this.chatservice.twilio_incomingvoice_call.subscribe((resp: any) => {
          this.postCallFeedback({ CallSid: this.incomingConnection.parameters.CallSid, parentCallSid: resp, callStatus: this.incomingConnection.status(), staffId: this.userDetails?.data?.id }).subscribe();
        });

        console.log((await (this.incomingConnection)).status(), "call accepted");
      });
      (await this.incomingConnection).on('disconnect', async () => {
        this.voicecallStatus.next((await (this.incomingConnection)).status());
        this.stopCallTimer();
        this.incomingConnectionAvailable.next(null);
        console.log((await (this.incomingConnection)).status(), "call disconnected");
      });
      (await this.incomingConnection).on('reject', async () => {
        this.voicecallStatus.next((await (this.incomingConnection)).status());
        this.incomingConnectionAvailable.next(null);
        console.log((await (this.incomingConnection)).status(), "call disconnected");
      });
    }
  }
  async rejectCall() {
    if (this.incomingConnection) {
      this.incomingConnection.reject();
      this.voicecallStatus.next((await (this.incomingConnection)).status());
    }
  }

  showIncomingCallDialog(trigger: string) {
    if (trigger === 'Accept') {
      this.acceptCall();
    } else {
      this.rejectCall();
    }
  }

  getTwilioConnection() {
    this.http.get(`${environment.urlPrefix}${constant.twilio_connection_check}`).subscribe((resp: any) => {
      this.twilioConnection.next(resp.data.status);
    });
  }
}

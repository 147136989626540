import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ADDPIPELINEMODAL } from 'src/app/core/data/pipeline_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { CreateTeamPipelineComponent } from '../../pipeline/models/create-team-pipeline/create-team-pipeline.component';
import { ReplaySubject, Subject, debounceTime, finalize, takeUntil } from 'rxjs';
import { SettingsService } from 'src/app/core/service/settings.service';
import { Router } from '@angular/router';
import { currencyJson } from 'src/app/core/data/currencyData';
import { ErrorLoadImage, maximumNumberAllowed, onCheckRequiredFieldStatus, onOnlyNumbers, onParseDomValue, onlyNumberDecimal, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { CHANNELS_LIST } from 'src/app/core/data/channels_json';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AuthService } from 'src/app/core/service/auth.service';
import { countryData } from 'src/app/core/data/countryData';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-pipeline-modal',
  templateUrl: './pipeline-modal.component.html',
  styleUrls: ['./pipeline-modal.component.scss']
})
export class PipelineModalComponent implements OnInit, AfterViewInit {
  user_details = JSON.parse(sessionStorage.getItem('currentUser')!);
  @Input() contact: any; //From Chat 
  @Input() type: any; //From Enquiry 
  @Input() enquiryData: any; //From Enquiry 
  @ViewChildren('customEditorComponents') customEditorComponents!: QueryList<CKEditorComponent>;
  submitted = false;
  pipelineForm!: FormGroup;
  FormFields!: FormGroup;
  pipelineJson = ADDPIPELINEMODAL[0];
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  errorJson = ERRORJSON[0];
  channels = CHANNELS_LIST;
  pipeline_list: any[] = [];
  selected_pipeline_stages: any[] = [];
  selected_pipeline: any;
  customFields: any;
  aptCategoriesArray: any[] = [];
  appointmentsArray: any;
  storeId: any;
  productCategory: any;
  productsList: any;
  @Input() pipelineData: any;
  checked: any[] = [];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  contactsList: any = [];
  editCard = false;
  selectedPipelineData!: any[];
  price_on!: boolean;
  loading = false;
  minDate: Date = new Date();
  newEmail = false;
  staffMembers: any;
  loggedInUser: any;
  assignee_departments: any = [];
  tagsListArray: any[] = [];
  companiesList: any;
  systemFields: any;
  onFormSystemfieldsData: any[] = [];
  systemFormFields!: FormGroup;
  currencyjson = currencyJson;
  @Input() chatId!: string;
  public bankFilterCurrencyCtrl: FormControl = new FormControl();
  public filteredCurrencyBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onCurrencyDestroy = new Subject<void>();
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  countryjson = countryData;
  is_client_visible = false;
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };
  searchSubject: Subject<any> = new Subject<any>();
  presignedurl = environment.preSendUrl;
  editorComponentLists: any[] = [];
  ckeditorLimitLists: any = {};
  revokedSysFieldsList: { [key: string]: { is_visible: any } } = {};
  showErrorMessage = false;
  featuresData: any;
  serviceCategory: any;
  servicesList: any;

  constructor(private fb: FormBuilder, public ngbActiveModal: NgbActiveModal, private crmService: CrmService,
    private setupService: SetUpService, private settingService: SettingsService,
    private router: Router, private authService: AuthService, private awsUtilService: AwsUtilsService, private toaster: ToastrService) {
    this.searchSubject.pipe(
      debounceTime(300)
    ).subscribe((searchTerm: any) => {
      if (searchTerm.text.length >= 3) {
        this.crmService.getContactsBySearchDropDown(searchTerm.type, searchTerm.text).subscribe((result: any) => {
          this.contactsList = result.data;
        });
        // } else if (searchTerm.term.length === 0) {
        //   console.log("contact");

        //   this.getContacts();
      }
    });
  }

  ngOnInit() {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.intilizeForm();
    this.getFeatues();
    this.getPipelines();
    // this.getContacts();
    this.getStaffList();
    this.getTags();
    if (this.type) { // For enquiry to pipline card creation
      this.pipelineForm.patchValue(this.enquiryData);
      this.pipelineForm.patchValue({ title: this.enquiryData.requirement_title, contact_first_name: this.enquiryData?.name });
      this.pipelineForm.controls["phone_number"].disable();
      this.pipelineForm.controls["ccode"].disable();
      this.pipelineForm.controls["email"].disable();
      this.pipelineForm.controls["contact_first_name"].disable();
      this.pipelineForm.controls["channel"].disable();
    }

    if (this.pipelineData) {
      this.selected_pipeline = this.pipelineData.pipelineId;
      this.getStagesBasedonPipeline();
      if (this.pipelineData.cardId) {
        this.editCard = true;
        this.getCardById();
      } else {
        this.getTemplateCard();
      }
    }
    this.filteredCurrencyBanks.next(this.currencyjson.slice());
    this.bankFilterCurrencyCtrl.valueChanges
      .pipe(takeUntil(this._onCurrencyDestroy))
      .subscribe(() => {
        this.filterCurrencyBanks();
      });
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
  }

  ngOnDestroy() {
    this._onCurrencyDestroy.next();
    this._onCurrencyDestroy.complete();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  keyDownEvent(event: any, maxLength: number) {
    const maxNum = maximumNumberAllowed(event, maxLength);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }

  ngAfterViewInit() {
    this.editorComponentLists = [];
    this.customEditorComponents.changes.subscribe((components: QueryList<CKEditorComponent>) => {
      components.forEach((editorComponent: any) => {
        this.editorComponentLists.push(editorComponent);
      });
    });
  }

  protected filterCurrencyBanks() {
    if (!this.currencyjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCurrencyCtrl.value;
    if (!search) {
      this.filteredCurrencyBanks.next(this.currencyjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCurrencyBanks.next(
      this.currencyjson.filter(bank => (bank.currency.toLowerCase().indexOf(search) > -1) ||
        bank.abbreviation.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditorLimit = Object.assign(this.ckeditorLimit, { ...contentLength });
    });
  }

  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();
    const dataWithoutTags = onParseDomValue(inputData);
    this.ckeditorLimit = Object.assign(this.ckeditorLimit, { actualLimit: dataWithoutTags.length || 0 });
  }


  custEditorkeyEvent(editor: any, editorComponents: any, index: any) {
    const edittedItem = this.editorComponentLists.find((elem: any) => elem.id === editorComponents.id);
    if (edittedItem) {
      const inputData = edittedItem.editorInstance.getData();
      const dataWithoutTags = onParseDomValue(inputData);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { actualLimit: dataWithoutTags.length || 0 });
    }
  }

  onCustReady(editor: any, index: any) {
    const object = {
      [index]: {
        maxLimit: 1000,
        actualLimit: 0,
        type: false
      }
    };
    Object.assign(this.ckeditorLimitLists, object);
    const dataWithoutTags = onParseDomValue(editor.data.get());
    this.ckeditorLimitLists[index]['actualLimit'] = dataWithoutTags?.length || 0;
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const edittedItem = this.editorComponentLists.find((elem: any) => elem.editorInstance.id === editor.id);
      const contentLength = pasteEventInCkeditor(edittedItem, pasteContent);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { ...contentLength });
    });
  }

  intilizeForm(data?: any) {
    this.pipelineForm = this.fb.group({
      pipeline: [this.pipelineData ? this.pipelineData.pipelineId : '', [Validators.required]],
      stage: [this.pipelineData ? this.pipelineData.selectedstageId : '', [Validators.required]],
      title: [data ? data[0]?.title : '', [Validators.required]],
      contact: [data ? data[0]?.contact : '', [Validators.required]],
      email: [data ? data[0]?.contact_email : ''],
      phone_number: [data ? data[0]?.contact_phone_number : ''],
      ccode: [data?.contact_ccode ? (data[0]?.contact_ccode.includes('+') ? data[0]?.contact_ccode : '+' + data[0]?.contact_ccode) : this.authService.getCompanyData().ccode],
      contact_first_name: [{ value: data ? data[0]?.contact_first_name : '', disabled: this.pipelineData?.cardId ? true : false }, [Validators.required]],
      price: [data ? data[0]?.price : '', [Validators.pattern("[0-9]+"), Validators.min(0)]],
      price_on: [this.price_on],
      due_date: [data ? data[0]?.due_date : ''],
      channel: [data ? data[0]?.channel : 'manual'],
      owner: [data ? data[0]?.owner : this.loggedInUser.data.id, [Validators.required]],
      owner_department: [data ? data[0]?.owner_department : ''],
      assignee: new FormControl(data ? data[0].assignee : []),
      tags: new FormControl(data ? data[0].tags : ''),
      fields: new FormArray([]),
      system_fields: new FormArray([])
    });
    if (data) {
      this.pipelineForm.controls["phone_number"].disable();
      this.pipelineForm.controls["ccode"].disable();
      this.pipelineForm.controls["email"].disable();
      this.pipelineForm.controls['channel'].disable();
    }
    if (this.contact) this.pipelineForm.controls["contact"].setValue(this.contact); this.getContacts();
    if(this.loggedInUser.customer_primary_login_type === 'email'){
      this.pipelineForm.controls['email'].setValidators(Validators.required);
      this.pipelineForm.controls['email'].updateValueAndValidity();
    } else {
      this.pipelineForm.controls['phone_number'].setValidators(Validators.required);
      this.pipelineForm.controls['phone_number'].updateValueAndValidity();
    }
  }

  get f() {
    return this.pipelineForm.controls;
  }

  systemfieldsForm(): FormArray {
    return this.pipelineForm.get('system_fields') as FormArray;
  }

  system_array(count: number): any {
    return this.systemfieldsForm().controls[count] as FormArray;
  }

  get systemfieldsFormArray(): FormArray {
    return this.pipelineForm.get('system_fields') as FormArray;
  }

  addSystemFormFileds(element: any, data?: any) {
    return this.systemFormFields = new FormGroup({
      template_field: new FormControl(element ? element.id : ''),
      data: new FormControl(data ? data : ''),
      data_name: new FormControl(element ? element[0] : ''),
      options: new FormArray([]),
      data_type: new FormControl(element ? element[1].data_type : ''),
      is_mandatory: new FormControl(element ? element[1].is_mandatory : '')
    });
  }

  getCustomFieldValidity(i: number) {
    return (<FormArray>this.pipelineForm.get('fields')).controls[i].invalid;
  }

  getSystemFieldValidity(i: number) {
    return (<FormArray>this.pipelineForm.get('system_fields')).controls[i]?.invalid;
  }

  sysoptions(index: number) {
    return this.systemfieldsForm().at(index).get('options') as FormArray;
  }

  fieldsForm(): FormArray {
    return this.pipelineForm.get('fields') as FormArray;
  }
  get fieldsFormArray(): FormArray {
    return this.pipelineForm.get('fields') as FormArray;
  }
  custom_array(count: number): any {
    return this.fieldsForm().controls[count] as FormArray;
  }

  addFormFileds(element: any, data?: any) {
    return this.FormFields = new FormGroup({
      template_field: new FormControl(element ? element.id : ''),
      data: new FormControl(data ? data.data : ''),
      data_name: new FormControl(element ? element.label : ''),
      url: new FormControl(element ? element.url : ''),
      options: new FormArray([]),
      data_type: new FormControl(element ? element.data_type : ''),
      is_mandatory: new FormControl(element ? element.is_mandatory : ''),
      // check_box: new FormGroup({})
      start: new FormControl(''),
      end: new FormControl('')
    });
  }
  updateFormFields(element: any) {
    return this.FormFields = new FormGroup({
      template_field: new FormControl(element ? element.template_field : ''),
      data: new FormControl(element ? (element.data !== 'NA' ? element.data : "") : ''),
      data_name: new FormControl(element ? element.label : ''),
      url: new FormControl(element ? element.url : ''),
      options: new FormArray([]),
      data_type: new FormControl(element ? element.data_type : ''),
      id: new FormControl(element ? element.id : ''),
      is_mandatory: new FormControl(element ? element.is_mandatory : ''),
      start: new FormControl(element.data_type === 'DATE_RANGE' ? (element.data !== 'NA' ? element.data.split('&')[0] : '') : element.data_type === 'PHONE_NUMBER' ? (element.data ? element.data?.custom_ccode : '') : ''),
      end: new FormControl(element.data_type === 'DATE_RANGE' ? (element.data !== 'NA' ? element.data.split('&')[1] : '') : element.data_type === 'PHONE_NUMBER' ? (element.data ? element.data?.custom_phone_number : '') : '')
    });
  }

  options(index: number) {
    return this.fieldsForm().at(index).get('options') as FormArray;
  }

  getPipelines() {
    this.crmService.getPipelines().subscribe({
      next: (resp: any) => {
        this.pipeline_list = resp.data;
        if (this.selected_pipeline) this.selectedPipelineData = this.pipeline_list.filter((ele: any) => ele.id === this.selected_pipeline);
      }
    });
  }

  keyDownCustomField(event: any, decimalPoint: number, type: string) {
    return onlyNumberDecimal(event, decimalPoint, type);
  }

  onChangePipeline(ev: any) {
    this.selected_pipeline = ev.value;
    this.selectedPipelineData = this.pipeline_list.filter((ele: any) => ele.id === this.selected_pipeline);
    console.log(this.selectedPipelineData, "pipeline date");

    this.fieldsForm().clear();
    this.systemfieldsForm().clear();
    this.getStagesBasedonPipeline();
    this.showErrorMessage = false;
    this.revokedSysFieldsList = {};
    this.getTemplateCard();
  }
  addNewName = (name: any) => ({ first_name: name, id: '' });
  addNewEmail = (email: any) => ({ email: email, id: '' });
  addNewphonenumber = (number: any) => ({ phone_number: number, id: '' });

  hasRequiredValidator(controlName: string): boolean {
    const control = this.pipelineForm.get(controlName);
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      return !!(validator && validator['required']);
    }
    return false;
  }

  async onContactChange(value: any, type: string) {
    console.log(value);
    this.pipelineForm.controls["phone_number"].enable();
    this.pipelineForm.controls["ccode"].enable();
    this.pipelineForm.controls["email"].enable();
    // const selected_contact = await this.contactsList.filter((e: any) => e.id === value);
    if (value?.id) {
      this.newEmail = false;
      this.pipelineForm.patchValue({ email: value.email, contact: value.id, phone_number: value.phone_number, ccode: value.ccode, contact_first_name: value.first_name });
      this.pipelineForm.controls["phone_number"].disable();
      this.pipelineForm.controls["ccode"].disable();
      this.pipelineForm.controls["email"].disable();
      this.pipelineForm.controls['contact'].setValidators(Validators.compose([Validators.required]));
      this.pipelineForm.controls['contact'].updateValueAndValidity();
    } else if (type === 'name') {
      this.newEmail = true;
      this.pipelineForm.patchValue({ contact: value.id, contact_first_name: value.first_name });
      this.pipelineForm.controls['contact'].clearValidators();
      this.pipelineForm.controls['contact'].updateValueAndValidity();
    } else if (type === 'email') {
      this.newEmail = true;
      this.pipelineForm.patchValue({ contact: value.id, email: value.email });
      this.pipelineForm.controls['contact'].clearValidators();
      this.pipelineForm.controls['contact'].updateValueAndValidity();
    } else if (type === 'phone_number') {
      this.newEmail = true;
      this.pipelineForm.patchValue({ contact: value.id, phone_number: value.phone_number, ccode: value.ccode || this.authService.getCompanyData().ccode });
      this.pipelineForm.controls['contact'].clearValidators();
      this.pipelineForm.controls['contact'].updateValueAndValidity();
    }
    // if (type === 'name') {
    //   this.leadForm.patchValue({ contact: contact.id, name: contact.first_name });
    //   this.contactActive = true;
    // } else if (type === 'email') {
    //   this.leadForm.patchValue({ contact: contact.id, email: contact.email });
    //   this.contactActive = true;
    // } else if (type === 'phone_number') {
    //   this.leadForm.patchValue({ contact: contact.id, phone_number: contact.phone_number, ccode: contact.ccode || this.authService.getCompanyData().ccode });
    //   this.contactActive = true;
    // }
    if (this.editCard) {
      this.pipelineForm.patchValue({ email: value.email, phone_number: value.phone_number, ccode: value.ccode });
      this.updatePipelineCard();
    }
  }

  getStagesBasedonPipeline() {
    this.crmService.getStageByPipelineId(this.selected_pipeline).subscribe({
      next: (resp: any) => {
        this.selected_pipeline_stages = resp.data;
      }
    });
  }

  getTemplateCard() {
    this.crmService.getTemplateCard(this.selected_pipeline).subscribe({
      next: (resp: any) => {
        this.showErrorMessage = false;
        this.revokedSysFieldsList = {};
        this.onCheckRequiredFieldStatus(resp.data[0]);
        this.price_on = resp.data[0].price_on;
        this.pipelineForm.patchValue({ price_on: this.price_on });
        // this.getFields(resp.data[0].id); //Commented because we are getting a data in new field itself
        //Custom fileds started
        this.customFields = resp.data[0].pipeline_template_field;
        let customCount = 0;
        this.customFields.forEach((element: any, i: number) => {
          if (element.data_type === 'CHECK_BOX') {
            this.fieldsForm().push(this.addFormFileds(element));
            element.options.forEach((e: any) => {
              const serv: any = {
                checked: new FormControl(false),
                binder: new FormControl(e)
              };
              this.options(i).push(new FormGroup(serv));
            });
          } else {
            this.fieldsForm().push(this.addFormFileds(element));
            if (element.options) {
              element?.options.forEach((opt: string) => {
                this.options(i).push(new FormControl(opt));
              });
            }
          }
          if (element.data_type === 'WEBSITE') {
            this.custom_array(customCount).controls['data'].setValidators(Validators.pattern(/^(https?:\/\/)?(www\.)?((([a-zA-Z0-9]+\.)+[a-zA-Z]{2,})|localhost)(:[0-9]{1,5})?(\/.*)?$/));
            this.custom_array(customCount).controls['data'].updateValueAndValidity();
          }
          if (element.is_mandatory) {
            this.custom_array(customCount).controls['data'].setValidators(Validators.required);
            this.custom_array(customCount).controls['data'].updateValueAndValidity();
          }
          customCount++;
        });
        //system Fields Form Started
        this.systemFields = Object.entries(resp.data[0]);
        let count = 0;
        this.systemFields.forEach((field: any, i: number) => {
          if (field[0] !== 'id' && field[0] !== 'pipeline_template_field' && field[0] !== 'organisation') {
            if (field[0] === 'scheduler_category' && field[1]?.on_form) {
              this.getAPTCategoriesList();
            } else if (field[0] === "scheduler" && field[1]?.on_form) {
              this.getAppointments();
            } else if ((field[0] === "product_category" && field[1]?.on_form) || (field[0] === "service_category" && field[1]?.on_form)) {
              this.getStoreId('category', field[0]);
            } else if ((field[0] === "product" && field[1]?.on_form) || (field[0] === "service" && field[1]?.on_form)) {
              this.getStoreId('products', field[0]);
              // } else if (field[0] === "contact" && field[1]?.on_form) {
              //   this.getContacts();
            } else if (field[0] === "company" && field[1]?.on_form) {
              this.getCompanys();
            }
            if (!field[1]?.is_default && field[1]?.on_form) {
              if (field[1]?.data_type === 'CHECK_BOX') {
                this.systemfieldsForm().push(this.addSystemFormFileds(field));
                field[1].options.forEach((e: any) => {
                  const serv: any = {
                    checked: new FormControl(false),
                    binder: new FormControl(e)
                  };
                  this.sysoptions(this.onFormSystemfieldsData.length).push(new FormGroup(serv));
                });
              } else {
                this.onFormSystemfieldsData.push(field);
                this.systemfieldsForm().push(this.addSystemFormFileds(field));
              }
              if (field[1].is_mandatory) {
                this.system_array(count).controls['data'].setValidators(Validators.required);
                this.system_array(count).controls['data'].updateValueAndValidity();
              }
              count++;
            }
          }
        });
        console.log(this.onFormSystemfieldsData, this.pipelineForm, "formfields");
        if (this.type) { // For enquiry to pipline card creation
          if (this.enquiryData.description) {
            this.pipelineForm.value.system_fields.forEach((element: any, i: number) => {
              if (element.data_name === "description") this.systemfieldsForm().at(i).patchValue({ data: this.enquiryData.description });
            });
          }
        }
      }
    });
  }

  onCheckRequiredFieldStatus(allFieldData: any) {
    const returnedObj = onCheckRequiredFieldStatus(allFieldData, this.featuresData, this.showErrorMessage);
    this.revokedSysFieldsList = returnedObj.revokedSysFieldsList;
    this.showErrorMessage = returnedObj.showErrorMessage;
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      console.log(this.featuresData);
    });
  }

  getStaffList() {
    this.settingService.getStaff_info().subscribe((data: any) => {
      this.staffMembers = data?.data;
    });
  }

  getTags() {
    this.settingService.getTags('pipeline').subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }

  /***************************OnCard creation getting custom fields*******************/
  getFields(templateId: string) { // No longer using this function
    this.crmService.getFieldsByTemaplateCardId(templateId).subscribe({
      next: (resp: any) => {
        this.customFields = resp.data;
        this.customFields.forEach((element: any, i: number) => {
          if (element.data_type === 'CHECK_BOX') {
            this.fieldsForm().push(this.addFormFileds(element));
            element.options.forEach((e: any) => {
              const serv: any = {
                checked: new FormControl(false),
                binder: new FormControl(e)
              };
              this.options(i).push(new FormGroup(serv));
            });
          } else {
            this.fieldsForm().push(this.addFormFileds(element));
            if (element.options) {
              element?.options.forEach((opt: string) => {
                this.options(i).push(new FormControl(opt));
              });
            }
          }
          // if (element.url === '/consultations/category/') {
          //   this.getAPTCategoriesList();
          // } else if (element.url === 'consultations/consultations/') {
          //   this.getAppointments();
          // } else if (element.url === 'stores/?/categories/') {
          //   this.getStoreId('category');
          // } else if (element.url === 'product/stores/?/products/') {
          //   this.getStoreId('products');
          // } else if (element.url === 'crm_service/Contacts') {
          //   this.getContacts();
          // } else if (element.url === 'crm_service/Companys') {
          //   this.getCompanys();
          // }
        });
      }
    });
  }

  setAll(event: any, selectedvalue: any, index: number, type: string) {
    this.checked = [];
    this.fieldsForm().controls[index].value.options.forEach((element: any) => {
      if (element.checked) {
        this.checked.push(element.binder);
      }
    });
    if (event.checked) {
      this.checked.push(selectedvalue);
      // this.fieldsForm().controls[index].value.data ="[" + this.checked + "]";
      if (type === "customfield") {
        this.fieldsForm().controls[index].value.options.forEach((element: any) => {
          //   // element.data.push(selectedvalue);
          //   console.log(this.checked,element);
          if (element.binder.includes(selectedvalue)) this.fieldsForm().controls[index].value.data = "[" + (this.fieldsForm().controls[index].value.data.replace('[', '').replace(']', '')) + this.checked + "]";
        });
      }
    } else {
      this.checked = this.checked.filter(item => !selectedvalue.includes(item));
      if (type === "customfield") {
        this.fieldsForm().controls[index].value.options.forEach((element: any) => {
          if (element.binder.includes(selectedvalue)) {
            this.checked.indexOf(selectedvalue);

            this.fieldsForm().controls[index].value.data = "[" + this.checked + "]";
          }
          //console.log(this.checked,element);
        });
      }
    }
    // this.fieldsForm().controls[index].value.data = "[" + this.checked + "]";
    // console.log(this.checked, index, this.fieldsForm().controls[index].value, this.pipelineForm);

    // this.fieldsForm().controls[index].get('data')?.patchValue(this.checked);
    if (this.editCard) { // Checkbox Update
      const body = {
        data: "[" + this.checked + "]",
        id: this.fieldsForm().controls[index].value.id,
        template_field: this.fieldsForm().controls[index].value.template_field
      };
      this.crmService.UpdateField(body).subscribe({
        next: (resp: any) => {
          this.options(index).clear();
          resp.data.options.forEach((e: any) => {
            const serv: any = {
              checked: new FormControl(resp.data.data.includes(e) ? true : false),
              binder: new FormControl(e)
            };
            this.options(index).push(new FormGroup(serv));
          });
        }
      });
    }
  }

  cancelModal() {
    this.ngbActiveModal.dismiss();
    if (this.editCard) {
      const emitData = {
        type: 'onPiplinePage',
        cardId: this.pipelineData.cardId
      };
      this.passEntry.emit(emitData);
    }
  }

  onSubmit() {
    this.submitted = true;
    const inValidCkEditor = Object.entries(this.ckeditorLimitLists).find((ckeditorLimit: any) => ckeditorLimit[1]?.actualLimit > ckeditorLimit[1]?.maxLimit);
    if (!inValidCkEditor) {
      console.log(this.pipelineForm, "pipelineForms");
      this.pipelineForm.value.fields.forEach((customfield: any, index: number) => {
        if (customfield.data_type === "CHECK_BOX") {
          this.fieldsForm().controls[index].value.options.forEach((element: any) => {
            customfield.data = this.checked;
          });
        }
        if (customfield.data_type === "DATE_RANGE") {
          if (customfield.end) {
            customfield.data = this.convert(customfield.start) + '&' + this.convert(customfield.end);
            this.fieldsForm().controls[index].patchValue({ data: customfield.data });
          } else if (customfield.start) {
            customfield.data = this.convert(customfield.start);
            this.fieldsForm()?.controls[index].patchValue({ data: customfield.data });
          }
        } else if (customfield.data && (customfield.data_type === 'DATE')) {
          customfield.data = this.convert(customfield.data);
          this.fieldsForm().controls[index].patchValue({ data: customfield.data });
        } else if (customfield.data_type === "PHONE_NUMBER") {
          customfield.data = { custom_ccode: customfield.start, custom_phone_number: customfield.end };
        }
        // if (customfield.data === "") {
        //   customfield.data = "NA";
        // }
      });
      if (this.pipelineForm.valid) {
        this.loading = true;
        this.assignee_departments = [];

        // if (this.pipelineForm.value.assignee.length !== 0) {
        this.staffMembers.forEach((item: any) => {
          this.pipelineForm.value.assignee.filter((assigne: any) => {
            if (assigne === item.id) {
              this.assignee_departments.push(item.department_info.id ? item.department_info.id : item.utype === 'ADMIN' ? this.loggedInUser.data.organisation : '');
            }
          });
          if (item.id === this.pipelineForm.value.owner) {
            this.pipelineForm.controls['owner_department'].setValue(item.department_info.id ? item.department_info.id : item.utype === 'ADMIN' ? this.loggedInUser.data.organisation : null);
          }
        });
        // }
        if (this.newEmail) {
          this.createContact();
        } else {
          this.bulkUpload();
        }
        // console.log(this.pipelineForm.value, "submitform");
      } else {
        this.pipelineForm.markAllAsTouched();
      }
    }
  }

  bulkUpload() {
    if (this.pipelineForm.value.due_date) this.pipelineForm.patchValue({ due_date: this.convert(this.pipelineForm.value.due_date) });
    const owner_data: any = this.staffMembers.filter((member: any) => member.id === this.pipelineForm.value.owner);
    if (this.type) this.pipelineForm.controls["channel"].enable();// For enquiry to pipline card creation
    const body = {
      assignee_department: this.assignee_departments ? this.assignee_departments : [],
      info: {
        owner_info: {
          fname: owner_data[0].fname,
          lname: owner_data[0].lname
        }
      },
      is_client_visible: this.is_client_visible,
      enquiry_id: this.enquiryData?.id,
      ...this.pipelineForm.value
    };
    this.pipelineForm.value.system_fields.forEach((sysfield: any) => {
      // if (sysfield.data === "" && !sysfield.is_mandatory) {
      //   sysfield.data = "NA";
      // }
      body[sysfield.data_name] = sysfield.data;
    });

    this.crmService.createBulkCard(body).pipe(finalize(() => this.loading = false)).subscribe((resp: any) => {
      resp.data.chatId = this.chatId;
      const iframe: any = document.getElementById('chat-widget-value');
      iframe?.contentWindow.postMessage({ ...resp, type: "pipelineCreated" }, '*');
      this.ngbActiveModal.dismiss();
      if (this.pipelineData?.pipelineId) {
        const emitData = {
          type: 'onPiplinePage',
          stageId: resp.data.stage
        };
        this.passEntry.emit(emitData);
      } else if (this.type) {
        this.passEntry.emit("success");
      }
    });
  }

  /**************************************Update Start**************************************** */

  getCardById() {
    this.crmService.getCardsById(this.pipelineData.cardId).subscribe((data: any) => {
      this.crmService.getTemplateCard(this.selected_pipeline).subscribe((resp: any) => {
        this.onCheckRequiredFieldStatus(resp.data[0]);
        this.price_on = resp.data[0].price_on;
        this.is_client_visible = data.data[0].is_client_visible;
        this.intilizeForm(data.data);
        const dataWithoutTags = onParseDomValue(data.data[0]?.description);
        this.ckeditorLimit['actualLimit'] = dataWithoutTags?.length || 0;
        this.customFields = resp.data[0].pipeline_template_field;
        this.getFieldsBasedonCardId(this.pipelineData.cardId);// Custom Fields
        //system Fields Form Started
        this.systemFields = Object.entries(resp.data[0]);
        let count = 0;
        this.systemFields.forEach((field: any) => {
          if (field[0] !== 'id' && field[0] !== 'pipeline_template_field' && field[0] !== 'organisation') {
            if (field[0] === 'scheduler_category' && field[1]?.on_form) {
              this.getAPTCategoriesList();
            } else if (field[0] === "scheduler" && field[1]?.on_form) {
              this.getAppointments();
            } else if ((field[0] === "product_category" && field[1]?.on_form) || (field[0] === "service_category" && field[1]?.on_form)) {
              this.getStoreId('category', field[0]);
            } else if ((field[0] === "product" && field[1]?.on_form) || (field[0] === "service" && field[1]?.on_form)) {
              this.getStoreId('products', field[0]);
              // } else if (field[0] === "contact" && field[1]?.on_form) {
              //   this.getContacts();
            } else if (field[0] === "company" && field[1]?.on_form) {
              this.getCompanys();
            }
            if (!field[1]?.is_default && field[1]?.on_form) {
              this.onFormSystemfieldsData.push(field);
              // this.systemfieldsForm().push(this.addSystemFormFileds(field, data.data[0][field[0].replace("", '')]));
              if (field[1]?.data_type === 'CHECK_BOX') {
                this.onFormSystemfieldsData.push(field);
                this.systemfieldsForm().push(this.addSystemFormFileds(field, data.data[0][field[0].replace("", '')]));
                field[1].options.forEach((e: any) => {
                  const serv: any = {
                    checked: new FormControl(false),
                    binder: new FormControl(e)
                  };
                  this.sysoptions(this.onFormSystemfieldsData.length - 1).push(new FormGroup(serv));
                });
              } else {
                this.onFormSystemfieldsData.push(field);
                this.systemfieldsForm().push(this.addSystemFormFileds(field, data.data[0][field[0].replace("", '')]));
              }
              if (field[1].is_mandatory) {
                this.system_array(count).controls['data'].setValidators(Validators.required);
                this.system_array(count).controls['data'].updateValueAndValidity();
              }
              count++;
            }
          }
        });
      });
    });
  }

  getFieldsBasedonCardId(cardId: string) {
    this.crmService.getFieldsbyCardId(cardId).subscribe((resp: any) => {
      this.customFields.forEach(async (field: any) => {
        resp.data.findIndex(function (item: any) {
          if (item.template_field === field.id) {
            item.is_mandatory = field.is_mandatory;
          }
        });
      });
      resp.data.forEach((element: any, i: number) => {
        if (element.data_type === 'CHECK_BOX') {
          this.fieldsForm().push(this.updateFormFields(element));
          element.options.forEach((e: any) => {
            const serv: any = {
              checked: new FormControl(element.data.includes(e) ? true : false),
              binder: new FormControl(e)
            };
            this.options(i).push(new FormGroup(serv));
          });
        } else {
          this.fieldsForm().push(this.updateFormFields(element));
          if (element.options.length > 0) {
            element?.options.forEach((opt: string) => {
              this.options(i).push(new FormControl(opt));
            });
          }
        }
      });
    });
  }

  //*************Radio **************/
  radioChange(ev: any, item: any) {
    if (this.editCard) {
      item.value.data = ev.value;
      this.updateSigleField(item);
    }
  }

  //*****************Matselect, TextBox, text area, Date picker Change***************** */
  selectChange(ev: any, item: any) {
    console.log("event");

    if (this.editCard) this.updateSigleField(item);
  }

  updateSigleField(item: any) { //For Custom fields
    console.log(item.value);
    if (item.value.data_type === "DATE_RANGE") {
      if (item.value.end) {
        item.value.data = this.convert(item.value.start) + '&' + this.convert(item.value.end);
      } else if (item.value.start) {
        item.value.data = this.convert(item.start);
      }
    } else if (item.value.data_type === "PHONE_NUMBER") {
      item.value.data = { custom_ccode: item.value.start, custom_phone_number: item.value.end };
    }
    const body = {
      id: item.value.id,
      data: item.value.data,
      template_field: item.value.template_field
    };
    this.crmService.UpdateField(body).subscribe({
      next: () => { //@typescript-eslint/no-empty-function
      }
    });
  }

  updatePipelineCard() { // For pipeline or stage or title change
    this.submitted = true;
    // console.log(this.pipelineForm);
    if (this.ckeditorLimit.actualLimit > this.ckeditorLimit.maxLimit) {
      return false;
    }
    if (this.editCard && this.pipelineForm.valid) {
      this.assignee_departments = [];
      let info: any;
      // if (this.pipelineForm.value.assignee.length !== 0) {
      this.staffMembers.forEach((item: any) => {
        this.pipelineForm.value.assignee.filter((assigne: any) => {
          if (assigne === item.id) {
            this.assignee_departments.push(item.department_info.id);
          }
        });
        if (item.id === this.pipelineForm.value.owner) {
          this.pipelineForm.controls['owner_department'].setValue(item.department_info.id || null);
          info = {
            owner_info: {
              fname: item.fname,
              lname: item.lname
            }
          };
        }
      });
      // }
      if (this.pipelineForm.value.due_date) this.pipelineForm.patchValue({ due_date: this.convert(this.pipelineForm.value.due_date) });
      const body = {
        assignee_department: this.assignee_departments ? this.assignee_departments : [],
        pipeline: this.pipelineForm.value.pipeline,
        stage: this.pipelineForm.value.stage,
        title: this.pipelineForm.value.title,
        id: this.pipelineData.cardId,
        info: info,
        is_client_visible: this.is_client_visible,
        ...this.pipelineForm.value
      };
      this.pipelineForm.value.system_fields.forEach((sysfield: any) => {
        body[sysfield.data_name] = sysfield.data;
      });
      this.crmService.updateCard(body).subscribe({
        next: () => {//@typescript-eslint/no-empty-function
        }
      });
    } else if (this.editCard && this.pipelineForm.invalid) {
      this.pipelineForm.markAllAsTouched();
    }
    return true;
  }

  /**************************************Update END**************************************** */
  /**********************************Fields API Starts*************************************/
  getAPTCategoriesList() {
    this.setupService.getAllCategories().subscribe({
      next: (resp: any) => {
        this.aptCategoriesArray = resp.data;
      }
    });
  }

  getAppointments() {
    this.setupService.getAllConsulations().subscribe({
      next: (resp: any) => {
        this.appointmentsArray = resp.data;
      }
    });
  }

  getStoreId(item: string, type: string) {
    this.setupService.getStore().subscribe((resp: any) => {
      this.storeId = resp.data.id;
      if (item === 'category') {
        this.getProductCategory(type);
      } else {
        this.getProduct(type);
      }
    });
  }

  getProductCategory(type: string) {
    this.setupService.getProductCategories(this.storeId, type).subscribe((resp: any) => {
      if (type === 'product_category') this.productCategory = resp.data;
      else this.serviceCategory = resp.data;
    });
  }

  getProduct(type: string) {
    this.setupService.getDropDownProducts(this.storeId, type).subscribe((resp: any) => {
      if (type === 'product') this.productsList = resp.data;
      else this.servicesList = resp.data;
    });
  }

  getContacts() {
    this.crmService.getContactsDropdownList().subscribe((resp: any) => {
      this.contactsList = resp.data;
      const exist = this.contactsList.filter((item: any) => item.id.includes(this.contact));
      if (exist.length !== 0) {
        this.pipelineForm.patchValue({ email: exist[0].email, phone_number: exist[0].phone_number, ccode: exist[0].ccode, contact_first_name: exist[0].first_name });
      } else if (this.contact) {
        this.crmService.getContactById(this.contact).subscribe((item: any) => {
          this.pipelineForm.patchValue({ email: item.data[0].email, phone_number: item.data[0].phone_number, ccode: exist[0].ccode, contact_first_name: item.data[0].first_name });
        });
      }
      if (this.contact) {
        this.pipelineForm.controls['phone_number'].disable();
        this.pipelineForm.controls['ccode'].disable();
        this.pipelineForm.controls['email'].disable();
        this.pipelineForm.controls['contact_first_name'].disable();
      }
    });
  }

  // searchContact(term: any) {
  //   if (term.term.length >= 3) {
  //     this.crmService.getContactsBySearch(term.term).subscribe((result: any) => {
  //       this.contactsList = result.data;
  //     });
  //   } else if (term.term.length === 0) {
  //     this.getContacts();
  //   }
  // }

  getCompanys() {
    this.crmService.getCompaniesDropdownList().subscribe((resp: any) => {
      this.companiesList = resp.data;
    });
  }

  /**********************************Fields API Ends**************************************/

  openCustomPipelineDialog() {
    this.ngbActiveModal.dismiss();
    this.router.navigate(['settings/custom-fields'], { queryParams: { activeLink: 'Pipeline', pipelineId: this.pipelineForm.value.pipeline || null } });
    // const modalRef = this.modalService.open(CreateTeamPipelineComponent, { size: 'lg', modalDialogClass: 'custom-pipeline-modal' });
    // modalRef.componentInstance.pipelineId = this.pipelineForm.value.pipeline;
    // modalRef.componentInstance.stepperCount = 2;
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  createContact() {
    this.settingService.getStaff_info().subscribe((data: any) => {
      const loggedMember = data.data.filter((staff: any) => staff.id === this.user_details.data.id);
      const owner_department = this.staffMembers.filter((member: any) => member.id === this.pipelineForm.value.owner);
      const body = {
        custom_contact_fields: [],
        phone_number: this.pipelineForm.value.phone_number,
        ccode: this.pipelineForm.value.ccode,
        email: this.pipelineForm.value.email.toLowerCase(),
        first_name: this.pipelineForm.value.contact_first_name,
        info: {
          owner_info: {
            fname: loggedMember.fname,
            lname: loggedMember.lname
          }
        },
        company_id: '',
        company_name: '',
        owner: this.user_details.data.id,
        owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : owner_department[0].utype === 'ADMIN' ? this.loggedInUser.data.organisation : '',
        channel: 'manual'
      };
      this.crmService.createContact(body).pipe(finalize(() => this.loading = false)).subscribe({
        next: (resp: any) => {
          this.newEmail = false;
          this.pipelineForm.controls['contact'].setValue(resp.data?.id);
          this.pipelineForm.patchValue({ contact: resp.data?.id, contact_first_name: resp.data.first_name });
          this.bulkUpload();
        }
      });
    });
  }

  clearContactFields(e: any) {
    this.pipelineForm.patchValue({ email: '', contact: '', phone_number: '', ccode: '', contact_first_name: '' });
    this.pipelineForm.controls["phone_number"].enable();
    this.pipelineForm.controls["ccode"].enable();
    this.pipelineForm.controls["email"].enable();
  }

  toggleVisibility(ev: any) {
    this.is_client_visible = ev.checked;
    if (this.editCard) {
      this.updatePipelineCard();
    }
  }

  //custom type File upload function
  onFileUpload(ev: any, index: number, item: any) {
    if (!ev.target.files[0]) return;
    if (ev.target.files[0].size > 15 * 1048576) {
      this.toaster.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    this.awsUtilService.getUploadFileLink(ev.target.files[0].type, ev.target.files[0].name.split('.')[1]).subscribe({
      next: (resp) => {
        this.fieldsForm().controls[index].patchValue({ data: { path: this.presignedurl + resp.data.file_name, file_name: ev.target.files[0].name } });
        this.awsUtilService.uploadFile(ev.target.files[0], resp.data.url).subscribe({
          next: () => {
            if (this.editCard) this.updateSigleField(item);//On File Change in edit card
          }
        });
      }
    });
  }

  onErrorLoadImage(event: any, target: any, file: any) {
    event.target.src = ErrorLoadImage(target, file);
  }
}

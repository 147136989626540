<div class="activity-div-height" [ngClass]="isModal ? 'activity-modal-body' : ''">
  <div class="d-flex align-items-center activityfilter table-responsive px-3 pb-2 pt-1">
    <!-- <mat-select [(ngModel)]="activity_filter" *ngIf="isModal !== 'modal'">
            <mat-option value="all" class="filteropt font-size-12">All Activities</mat-option>
            <mat-option value="open" class="filteropt font-size-12">Open Activities</mat-option>
            <mat-option value="closed" class="filteropt font-size-12">Closed Activities</mat-option>
            <mat-option value="" class="filteropt font-size-12">My Activites</mat-option>
            <mat-option value="" class="filteropt font-size-12">Next 7 Days + Overdue Acivites</mat-option>
            <mat-option value="" class="filteropt font-size-12">Overdue Acivites</mat-option>
            <mat-option value="" class="filteropt font-size-12">Today + Overdue Acivites</mat-option>
            <mat-option value="" class="filteropt font-size-12">Todays Acivites</mat-option>
            <mat-option value="" class="filteropt font-size-12">Tommorows Activites</mat-option>
        </mat-select> -->
    <ng-container *ngFor="let activity of allActivityLists;let index = index">
      <button (click)="currentActivity(activity,formDirective,index)"
        [ngClass]="(activity_type === (activity.name).split(' ').join('').toLowerCase() && activity_show) && !readOnly ? 'bg-gradient-active': 'bg-gradient'"
        style="min-width: fit-content;"
        class="btn d-flex align-items-center justify-content-center font-size-13 fw-500 my-1 me-2 activity-btn"
        *ngIf="activity.status && !readOnly">
        <mat-icon *ngIf="index !== currentActivityIndex" class="me-2">add</mat-icon>{{activity.name}}
      </button>
    </ng-container>
  </div>
  <div class="card overflow-auto auto-hide-scroll-bar"
    [ngClass]="activity_show === true ? 'shrink-to-max' : 'shrink-to-0'"
    [ngStyle]="{'border':currentActiveActivity === 'no-res' ? '0px' : 'auto'}">
    <div class="card-body" [hidden]="!currentActiveActivity">
      <ng-container>
        <form [formGroup]="activity_form" #formDirective="ngForm"
          (ngSubmit)="ckeditorLimit.actualLimit <= ckeditorLimit.maxLimit && submitActivityForm(formDirective)">
          <div class="row new_design_2024" *ngIf="currentActiveActivity?.name">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <label class="mb-2 fw-500 label-text-clr font-size-13">{{currentActiveActivity?.name}}
                Name <small class="text-danger">*</small></label>
              <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" matInput placeholder="Name" formControlName="name" />
                <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['name'].errors">
                  <mat-icon class="me-1 my-auto">info</mat-icon>
                  <span *ngIf="f['name'].errors['required']">
                    <span *ngIf="activity_type === 'email'">{{currentActiveActivity?.name}}</span>
                    Name
                    {{errorjson.required}}</span>
                  <span *ngIf="f['name'].errors['pattern']">
                    Invalid {{currentActiveActivity?.name}} name.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <label class="mb-2 fw-500 label-text-clr font-size-13">Due Date & Time</label>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput [ngxMatDatetimePicker]="picker" [min]="minDate" placeholder="Choose a date & time"
                  formControlName="due_date">
                <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                <!-- <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['due_date'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['due_date'].errors['required']"> Due date
                                        {{errorjson.required}}</span>
                                </mat-error> -->
              </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <label class="mb-2 fw-500 label-text-clr font-size-13">Owner <small class="text-danger">*</small></label>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select style="outline: none" placeholder="Select" formControlName="owner" (selectionChange)="onOwnerchange()">
                  <mat-option *ngFor="let member of staffMembers" [value]="member.id">{{member.fname}}
                    {{member.lname}}</mat-option>
                </mat-select>
                <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['owner'].errors">
                  <mat-icon class="me-1 my-auto">info</mat-icon>
                  <span *ngIf="f['owner'].errors['required']"> Owner
                    {{errorjson.required}}</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="isModal">
              <label class="fw-500 label-text-clr mb-2 w-100">Related to <small class="text-danger">*</small></label>
              <mat-radio-group aria-label="Select an option" formControlName="related_to_type"
                (change)="onRelatedTochange($event)">
                <mat-radio-button [value]=item.value
                  *ngFor="let item of activity_related_types">{{item.label}}</mat-radio-button>
                <!-- <mat-radio-button value="lead">Enquiry</mat-radio-button>
                                <mat-radio-button value="company">Company</mat-radio-button>
                                <mat-radio-button value="project">Client Project</mat-radio-button> -->
              </mat-radio-group>
              <mat-error class="error d-flex align-items-center"
                *ngIf="submitedActivityForm && f['related_to_type'].errors">
                <mat-icon class="font-size-12 w-auto h-auto me-1">info</mat-icon>
                <span *ngIf="f['related_to_type'].errors['required']">Related to
                  {{errorjson.required}}</span>
              </mat-error>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="activity_type ==='meeting'">
              <label class="mb-2 fw-600 font-size-13">Guest Mail</label>
              <ng-select class="form-control no-arrow" [items]="guestsMeetingList" bindLabel="mail" bindValue="mail"
                [addTag]="addnew" [multiple]="true" (change)="onSelectEmail($event)" placeholder="Guest mail">
              </ng-select>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="activity_type ==='meeting'">
              <label class="mb-2 fw-600 font-size-13">Mode <small class="text-danger">*</small></label>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select style="outline: none" placeholder="Select" (selectionChange)="onSelectMode($event)"
                  formControlName="location">
                  <mat-option value="zoom">Zoom</mat-option>
                  <mat-option value="google">Google Meet</mat-option>
                  <mat-option value="custom">Custom Link</mat-option>
                </mat-select>
                <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['location'].errors">
                  <mat-icon class="me-1 my-auto">info</mat-icon>
                  <span *ngIf="f['location'].errors['required']"> Mode is required
                    {{errorjson.required}}</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 meeting-link" *ngIf="activity_type ==='meeting'">
              <label class="mb-2 fw-600 font-size-13">Meeting Link <small class="text-danger">*</small></label>
              <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" matInput placeholder="Meeting link" formControlName="meeting_link" />
                <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['meeting_link'].errors">
                  <mat-icon class="me-1 my-auto">info</mat-icon>
                  <span *ngIf="f['meeting_link'].errors['required']"> Meeting link
                    {{errorjson.required}}</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="isModal && (activity_form.value.related_to_type === 'contact')">
              <label class="fw-500 label-text-clr mb-2 w-100">Record Type <small class="text-danger">*</small></label>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select style="outline: none" placeholder="Select"
                  formControlName="object_type" (selectionChange)="getContactsList()">
                  <mat-option [value]=item.id *ngFor="let item of object_types">{{item.name}}</mat-option>
                </mat-select>
                <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['object_type'].errors">
                  <mat-icon class="me-1 my-auto">info</mat-icon>
                  <span *ngIf="f['object_type'].errors['required']"> Record Type is required
                    {{errorjson.required}}</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="activity_form.value.related_to_type">
              <label class="fw-600 mb-2 " *ngIf="activity_form.value.related_to_type === 'contact' && activity_form.value.object_type">Select
                <span *ngIf="selectedobject">{{selectedobject[0]?.name}} </span> <small class="text-danger">*</small></label>
              <label class="fw-600 mb-2 " *ngIf="activity_form.value.related_to_type === 'lead'">Select
                Lead
                <small class="text-danger">*</small></label>
              <label class="fw-600 mb-2 " *ngIf="activity_form.value.related_to_type === 'company'">Select
                Company <small class="text-danger">*</small></label>
              <label class="fw-600 mb-2 " *ngIf="activity_form.value.related_to_type === 'project'">Select
                Client Project <small class="text-danger">*</small></label>

              <div>
                <mat-form-field appearance="outline" class="w-100"
                  *ngIf="selected_type === 'project' || activity_form.value.related_to_type === 'project'">
                  <mat-select placeholder='Related to' formControlName="source_id">
                    <mat-option>
                      <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                        noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of related_to" value={{item.id}}
                      (click)="selectedSource()">{{item.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100"
                  *ngIf="selected_type === 'company' || activity_form.value.related_to_type === 'company'">
                  <mat-select placeholder='Related to' formControlName="source_id">
                    <mat-option>
                      <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                        noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of related_to" value={{item.id}}
                      (click)="selectedSource()">{{item.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100"
                  *ngIf="(selected_type === 'contact' || activity_form.value.related_to_type === 'contact') && activity_form.value.object_type">
                  <mat-select placeholder='Related to' formControlName="source_id">
                    <mat-option>
                      <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                        noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of related_to" value={{item.id}}
                      (click)="selectedSource(item)">{{item.display_name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100"
                  *ngIf="selected_type === 'lead' || activity_form.value.related_to_type === 'lead'">
                  <mat-select placeholder='Related to' formControlName="source_id">
                    <mat-option>
                      <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                        noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of related_to" value={{item.id}}
                      (click)="selectedSource()">{{item.requirement_title}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['source_id'].errors">
                  <mat-icon class="font-size-12">info</mat-icon>
                  <span *ngIf="f['source_id'].errors['required']">Related to
                    {{errorjson.required}}</span>
                </mat-error>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6"
              *ngIf="(selected_type === 'contact' || activity_form.value.related_to_type === 'contact') && selectedContactDetails?.email">
              <label class="mb-2 fw-500 label-text-clr font-size-13">Contact Email</label>
              <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" disabled="true" readonly="true" matInput placeholder="Contact Email"
                  [value]="selectedContactDetails?.email" />
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="!isModal && selected_type !== 'contact'">
              <label class="mb-2 fw-600 font-size-13">Related To<small class="text-danger">*</small></label>
              <mat-form-field appearance="outline" class="w-100 ">
                <input style="outline: none"  readonly="true" matInput placeholder="Related" formControlName="source_type" />
                <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['source_type'].errors">
                  <mat-icon class="me-1 my-auto">info</mat-icon>
                  <span *ngIf="f['source_type'].errors['required']"> Related To
                    {{errorjson.required}}</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label class="m-0 mb-2 fw-600 font-size-13">Description</label>
              <ckeditor [editor]="Editor" formControlName="description" #editorComponent
                [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'ck-error':''"
                (keyup)="editorkeyEvent(editorComponent)" (ready)="onReady($event,editorComponent)"
                class="ckeditor-height" [config]="ckconfigQue">
              </ckeditor>
              <small [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                class="font-size-10">Maximum
                1000 characters are accepted.</small><small
                [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                class="font-size-10 ps-1">(Character limit:
                {{ckeditorLimit.actualLimit}}/{{ckeditorLimit.maxLimit}})</small>
            </div>
          </div>
          <button *ngIf="currentActiveActivity?.name" type="submit" class="btn btn-primary mt-3 px-3"
            [disabled]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit">Create Activity</button>
          <div *ngIf="currentActiveActivity === 'no-res'">
            <h6 class="text-center ">Please enable atleast one Activity</h6>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="!isModal">
    <div class="overflow-auto h-100"
      [ngClass]="(activity_type === 'followup' || activity_type === 'call' || activity_type === 'email' || activity_type === 'meeting') ? 'shrink_div' : 'expand_div'">
      <!-- <div class="d-flex justify-content-between align-items-center text-center">
        <mat-divider class="w-100 m-0"></mat-divider>
        <p class="m-0 w-50 text-light">Activities</p>
        <mat-divider class="w-100 m-0"></mat-divider>
      </div> -->
      <div class="d-flex align-items-center mb-2 px-3 pt-3">
        <img src="assets/images/newUI/CRM/add_notes_icons.svg" alt="">
        <p class="m-0 ps-2 title-text-clr font-size-16 fw-600">Activities</p>
      </div>
      <div *ngIf="activityId"
        class="dummy-search-box d-flex border-radius-12 width-fit-content px-2 py-2 my-3 mx-3 align-items-center">
        <p class="m-0 me-2">{{'Filtered By: Selected from Notifications'}}</p>
        <button type="button" class="btn-close" aria-label="Close" (click)="clearFilter()"></button>
      </div>
      <div class="" *ngIf="activitiesArray && activitiesArray.length > 0">
        <div class="card mb-0 activity-card" *ngFor="let activity of activitiesArray">
          <div class="card-body pb-2">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div class="d-flex align-items-center">
                <div class="name-circle text-capitalize">
                  <span>{{activity?.created_by_name [0]}}</span>
                </div>
                <p class="m-0 ps-2 font-size-13 fw-500 user-name">
                  {{activity?.created_by_name }}
                </p>
              </div>
              <p class="m-0 font-size-12 fw-500" style="color: #8B8B8B;">{{activity.customTime}}</p>
            </div>
            <div class="activity-body p-3">
              <p class="m-0 font-size-16 fw-600 mb-2">{{activity.name}}</p>
              <div class="d-flex">
                <label class="text-black font-size-13" [innerHTML]="activity.description"></label>
              </div>
              <div class="mt-2 font-size-13" *ngIf="activity?.guest?.length !== 0">
                <b>Guest Mails </b>: <span *ngFor="let item of activity.guest; let i = index">
                  {{item}} <span *ngIf="activity.guest.length !== i + 1">,</span></span>
              </div>
              <div class="d-flex align-items-center justify-content-between mt-2 ">
                <div class="d-flex align-items-center activity-name">
                  <div class="dot"></div>
                  <p class="m-0 font-size-13 fw-500">{{activity.activity_type_name |
                    titlecase}}</p>
                </div>
                <img src="assets/images/newUI/CRM/delete_icons.svg" *ngIf="!readOnly" class="cursor p-1"
                  (click)="confirmDelete(activity.id)" />
              </div>
              <!-- <div>
                <h6 class="fw-600">{{activity.name | titlecase}}</h6>

                <div class="mt-2 font-size-13" *ngIf="activity?.guest?.length !== 0">
                  <b>Guest Mails </b>: <span *ngFor="let item of activity.guest; let i = index">
                    {{item}} <span *ngIf="activity.guest.length !== i + 1">,</span></span>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <mat-icon class="cursor d-none me-2" *ngIf="!readOnly"
                    (click)="confirmDelete(activity.id)">delete</mat-icon>
                </div>
                <button class="btn bg-white border-1 font-size-12" (click)="updateActivity(activity.id,true)"
                  *ngIf="!activity.mark_as_done && !readOnly">Mark As
                  Completed</button>
                <button class="btn bg-white border-1 font-size-12" *ngIf="activity.mark_as_done"
                  [ngStyle]="{'cursor':activity.mark_as_done ? 'auto' : ''}">
                  Completed</button>
              </div> -->
            </div>
            <div class="activity-status d-flex align-items-center mt-2 pt-1 justify-content-between">
              <div class="d-flex align-items-center activity-name px-2" *ngIf="activity.mark_as_done">
                <div class="dot"></div>
                <p class="m-0 ps-2 font-size-13 fw-500">Completed</p>
              </div>
              <div class="d-flex align-items-center activity-due px-2" *ngIf="!activity.mark_as_done">
                <div class="dot"></div>
                <p class="m-0 ps-2 font-size-13 fw-500">Due on : {{activity?.due_date ? ((activity?.due_date | dateConversion) + ' ' + (activity?.due_date | date: 'h:mm a')) : 'NA'}}</p>
              </div>
              <div (click)="updateActivity(activity.id,true)"
                class="mark_completed p-2 py-1 line-height-1 d-flex align-items-center"
                *ngIf="!activity.mark_as_done && !readOnly">
                <input type="checkbox" class="cursor" id="mark_as_read">&nbsp;<label
                  class="m-auto my-0 cursor ms-1 label-text-clr font-size-12 fw-500 limit-line-length-1">Mark as
                  Completed</label>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2" *ngIf="activities_total_count > 20">
          <mat-paginator [length]="activities_total_count" [pageSize]="20" (page)="page($event)"
            aria-label="Select page" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
      <div *ngIf="activitiesArray && activitiesArray.length === 0">
        <app-nodata-found></app-nodata-found>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-header">
    <h5 *ngIf="!contact?.id" class="modal-title fw-600">Add {{object_data ? object_data?.name : 'Contact'}}</h5>
    <h5 *ngIf="contact?.id" class="modal-title fw-600">Update {{object_data ? object_data?.name : 'Contact'}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>

<div class="modal-body">
    <div class="d-flex flex-column" *ngIf="select_object_show">
        <label class="mb-2 fw-600">Record Type</label>
        <mat-form-field appearance="outline" class="w-50">
            <mat-select (selectionChange)="onObjectTypeChange($event.value)" placeholder="Select record">
                <mat-option *ngFor="let options of object_types" [value]="options.id">
                    {{options.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <form [formGroup]="objectForm">
        <div class="row">
            <ng-container *ngFor="let field of system_contact_fields.controls; let i=index">
                <ng-container [ngTemplateOutlet]="fields"
                    [ngTemplateOutletContext]="{field:field, i:i, formArrayName: 'system_contact_fields',fieldType:'sys'}">
                </ng-container>
            </ng-container>
        </div>
    </form>
</div>

<div class="modal-footer mt-3 d-flex justify-content-between">
    <div class="d-flex cursor" [routerLink]="['settings/custom-fields']"
        [queryParams]="{ activeLink: 'Record',object_type: object_type}" (click)="ngbActiveModal.dismiss()">
        <span class="material-icons text-primary">settings_applications</span>
        {{addcontact.customize_text.replace('contact', object_data?.name || 'contact')}}
    </div>
    <div class="d-flex">
        <button class="btn btn-light me-2" type="button" (click)="ngbActiveModal.dismiss()">
            {{addcontact.cancel_btn}}
        </button>
        <button class="btn btn-primary" type="submit" (click)="onSubmit()"
            [disabled]="loading">{{addcontact.save_btn}}</button>
    </div>
</div>


<ng-template #fields let-field='field' let-i="i" let-formArrayName="formArrayName" let-fieldType="fieldType">
    <form [formGroup]="objectForm" class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
        <div [formArrayName]="formArrayName">
            <div *ngIf="field.value.label !== 'ccode'"><!--field.value.on_form && -->
                <div class="row" [formGroupName]="i">
                    <div>
                        <label class="fw-600 mb-2 ">{{field.value.label.replace("_", " ") | titlecase}}</label>&nbsp;
                        <small class="text-danger" *ngIf="field.value.is_mandatory">*</small>

                        <ng-container *ngIf="field.value.data_type === 'TEXT'  ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                        <!-- [ngClass]="ckeditorLimit_2.actualLimit > ckeditorLimit_2.maxLimit ? 'ck-error':''" -->
                        <ng-container *ngIf="field.value.data_type === 'TEXT_AREA'">
                            <ckeditor [editor]="Editor" placeholder="write a message" formControlName="data"
                                [config]="ckconfigQue" class="ckeditor-height" #editorComponents
                                (ready)="onReady($event,fieldType+i)"
                                [ngClass]="ckeditorLimitLists[fieldType+i]?.actualLimit > ckeditorLimitLists[fieldType+i]?.maxLimit ? 'ck-error':''"
                                (keyup)="editorkeyEvent($event,editorComponents,fieldType+i)">
                            </ckeditor>
                            <small
                                [ngClass]="ckeditorLimitLists[fieldType+i]?.actualLimit > ckeditorLimitLists[fieldType+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10">Maximum
                                1000 characters are accepted.</small><small
                                [ngClass]="ckeditorLimitLists[fieldType+i]?.actualLimit > ckeditorLimitLists[fieldType+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10 ps-1">(Character
                                limit:
                                {{ckeditorLimitLists[fieldType+i]?.actualLimit}}/{{ckeditorLimitLists[fieldType+i]?.maxLimit}})</small>
                            <!-- <textarea class="form-control background-color" rows="2" cols="30" formControlName="data"
                  placeholder="write a message"></textarea> -->
                            <div class="error d-flex align-items-center"
                                *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", "
                                    ")}}</span>&nbsp;{{errorJson.required}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'CHECK_BOX'">
                            <div *ngFor="let options of field.value.options">
                                <mat-checkbox [value]="options" (change)="toggleCheckbox(options, i)">
                                    {{options}}
                                </mat-checkbox>
                            </div>
                        </ng-container>

                        <ng-container
                            *ngIf="field.value.data_type === 'SELECT_DROPDOWN' || field.value.data_type === 'TAB'">
                            <div>
                                <ng-container *ngIf="field.value.options.length > 0">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select formControlName="data">
                                            <mat-option [value]="null" (click)="onNextFlow(field.value,'',i)">
                                                None
                                            </mat-option>
                                            <mat-option *ngFor="let options of field.value.options"
                                                [value]="options.label" (click)="onNextFlow(field.value,options,i)">
                                                {{options.label}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span class="text-capitalize">{{field.value.label.replace("_", "
                                                ")}}</span>&nbsp;{{errorJson.required}}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="field.value.options.length === 0">
                                    <mat-form-field appearance="outline" class="w-100"
                                        *ngIf="field.value.label === 'country'">
                                        <mat-select formControlName="data">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                    placeholderLabel="Search"
                                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of filteredBanks | async"
                                                value={{country.Entity}}>{{country.Entity}}</mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span class="text-capitalize">{{field.value.label.replace("_", "
                                                ")}}</span>&nbsp;{{errorJson.required}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-100"
                                        *ngIf="field.value.label === 'time_zone'">
                                        <mat-select formControlName="data">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="bankFilterTimezoneCtrl"
                                                    placeholderLabel="Search"
                                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-optgroup *ngFor="let time_zone of filteredTimezoneBanks | async"
                                                [label]="time_zone.name">
                                                <mat-option class="mb-2 mat-hint-none"
                                                    *ngFor="let countries of time_zone.countries"
                                                    value="{{countries.timezone}}">{{countries.name}}</mat-option>
                                            </mat-optgroup>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span class="text-capitalize">{{field.value.label.replace("_", "
                                                ")}}</span>&nbsp;{{errorJson.required}}
                                        </mat-error>
                                    </mat-form-field>
                                    <ng-select class="form-control" [items]="companiesListArray" bindLabel="name"
                                        bindValue="id" [addTag]="addNewCompanyName" [multiple]="false"
                                        (search)="searchSubject.next($event)"
                                        *ngIf="field.value.label === 'company' && featuresData['create_company'] && featuresData['company']"
                                        (change)="onSelectCompany($event)" (clear)="clearCompany($event)"
                                        placeholder="Company name" formControlName="data">
                                    </ng-select>
                                    <mat-form-field appearance="outline" class="w-100"
                                        *ngIf="field.value.label === 'owner'">
                                        <mat-select formControlName="data" placeholder={{addcontact.owner}}>
                                            <mat-option *ngFor="let staff of staffMembers"
                                                [value]="staff.id">{{staff.fname}}
                                                {{staff.lname}}</mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span class="text-capitalize">
                                                {{addcontact.owner}} {{errorJson.required}}
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-100"
                                        *ngIf="field.value.label === 'tags'">
                                        <mat-select formControlName="data" placeholder="{{addcontact.label_tag}}">
                                            <mat-option [value]="item.id"
                                                *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-100"
                                        *ngIf="field.value.label === 'channel'">
                                        <mat-select formControlName="data" placeholder={{addcontact.Source}}>
                                            <mat-option *ngFor="let item of channels"
                                                [value]="item.value">{{item.display_name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-100"
                                        *ngIf="field.value.label === 'contact_type'">
                                        <mat-select formControlName="data" placeholder={{addcontact.contact_type}}>
                                            <mat-option [value]="type.id"
                                                *ngFor="let type of contact_type">{{type.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="field.value.data_type === 'MULTI_SELECT_DROPDOWN'">
                            <div *ngIf="field.value.options.length > 0">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-select formControlName="data" multiple
                                        (openedChange)="onSelectOpenedChange($event,field.value,i)">
                                        <mat-option *ngFor="let options of field.value.options" [value]="options.label">
                                            {{options.label}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error class="error text-danger d-flex"
                                        *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", "
                                            ")}}</span>&nbsp;{{errorJson.required}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div *ngIf="field.value.options.length === 0">
                                <ng-select class="form-control" *ngIf="field.value.label === 'assignee'"
                                    [ngClass]="{'disabled-field': contact?.owner && (contact.owner !== loggedInUser.data.id && loggedInUser.data.role !== 'ADMIN')}"
                                    [items]="staffMembers" bindValue="id" bindLabel="fname" [addTag]="false"
                                    [multiple]="true" placeholder="Select staff" formControlName="data">
                                </ng-select>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'SINGLE_USER'">
                            <ng-select class="form-control" [items]="staffMembers" bindValue="id" bindLabel="fname"
                                [addTag]="false" [multiple]="false" placeholder="{{field.value.label}}"
                                formControlName="data">
                            </ng-select>
                            <div class="error d-flex text-danger"
                                *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", " ")}}
                                    {{errorJson.required}}</span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="field.value.data_type === 'MULTI_USER'">
                            <ng-select class="form-control" [items]="staffMembers" bindValue="id" bindLabel="fname"
                                [addTag]="false" [multiple]="true" placeholder="{{field.value.label}}"
                                formControlName="data">
                            </ng-select>
                            <div class="error d-flex text-danger"
                                *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", " ")}}
                                    {{errorJson.required}}</span>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'RADIO_BUTTON'">
                            <div>
                                <ng-container *ngIf="field.value.options.length > 0">
                                    <mat-radio-group formControlName="data">
                                        <mat-radio-button value="" (change)="onNextFlow(field.value,'',i)">None</mat-radio-button>
                                        <mat-radio-button *ngFor="let options of field.value.options"
                                            [value]="options.label" (change)="onNextFlow(field.value,options,i)">
                                            {{options.label}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <div class="error d-flex align-items-center"
                                        *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                        <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", "
                                            ")}}</span>&nbsp;{{errorJson.required}}
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="field.value.options.length === 0">
                                    <mat-radio-group formControlName="data">
                                        <mat-radio-button [value]="true">
                                            Required
                                        </mat-radio-button>
                                        <mat-radio-button [value]="false">
                                            Not Required
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <div class="error d-flex align-items-center"
                                        *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                        <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", "
                                            ")}}</span>&nbsp;{{errorJson.required}}
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'TOGGLE_BUTTON' ">
                            <label class="switch d-none">
                                <input type="checkbox" formControlName="data">
                                <span class="slider round"></span>
                            </label>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'NUMBER' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" type="number"
                                    (keydown)="keyDownCustomField($event,0,'custom_field')"
                                    (wheel)="$event.preventDefault()" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'WEBSITE'">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" type="url" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" (focusout)="getCompanyDetailsBylinkedIn(field.value)"/>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}} or Invalid
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'TIME'">
                            <input matInput type="time" class="w-100 border-radius-5 ps-3 pe-2"
                                style="border: 1px solid #9a9797;height: 35px;" placeholder="{{field.value.label}}"
                                formControlName="data">
                            <mat-error class="error text-danger d-flex"
                                *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                    class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                {{errorJson.required}}
                            </mat-error>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="text" matInput [matDatepicker]="picker" formControlName="data"
                                    placeholder="{{field.value.label}}">
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE_TIME' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput [ngxMatDatetimePicker]="dateTimePicker" placeholder="Choose a date"
                                    formControlName="data">
                                <mat-datepicker-toggle matSuffix [for]="$any(dateTimePicker)"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #dateTimePicker>
                                </ngx-mat-datetime-picker>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE_RANGE' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-date-range-input [rangePicker]="dateRangepicker">
                                    <input matStartDate formControlName="start" placeholder="Start date">
                                    <input matEndDate formControlName="end" placeholder="End date">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matIconSuffix [for]="dateRangepicker"></mat-datepicker-toggle>
                                <mat-date-range-picker #dateRangepicker></mat-date-range-picker>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'FILE'">
                            <div class="" *ngIf="contact?.id">
                                <img src="{{field.value?.data?.path}}" alt="" width="150px" class="mb-2"
                                    (error)="onErrorLoadImage($event,presignedurl,field.value?.data?.file_name)">
                                <a href="{{field.value?.data?.path}}" target="_blank"
                                    class="ms-2 text-dark fw-medium mt-3" download>{{field.value?.data?.file_name}}</a>
                            </div>
                            <input type="file" style="outline: none" placeholder="{{field.value.label}}"
                                (change)="onFileUpload($event,i,field)" [accept]="getAcceptAttribute(field)" />
                            <div class="error d-flex align-items-center"
                                *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", "
                                    ")}}</span>&nbsp;{{errorJson.required}}
                            </div>
                            <!-- <ngx-dropzone (change)="onFileUpload($event,i)" [multiple]="false" class="bg-gray-light">
                  <ngx-dropzone-label>Drag a <span class="text-primary fw-500">{{field.value.label}}</span>
                    to upload
                  </ngx-dropzone-label>
                  <ngx-dropzone-image-preview [removable]="true" 
                  ngProjectAs="ngx-dropzone-preview" *ngFor="let f of company_logo" [file]="f">
                </ngx-dropzone-image-preview>
                </ngx-dropzone> -->
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'PHONE_NUMBER'">
                            <div class="d-flex">
                                <mat-form-field appearance="outline" class="me-1">
                                    <mat-select placeholder="Country code" formControlName="start">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let country of filteredBanks | async"
                                            [ngClass]="{'d-none': country.IAC === ''}"
                                            value={{country.IAC}}>{{country.IAC}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                                        placeholder="{{field.value.label}}" formControlName="end" />
                                    <mat-error class="error text-danger d-flex"
                                        *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label}}
                                            {{errorJson.required}}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="error d-flex align-items-center"
                                *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", "
                                    ")}}</span>&nbsp;{{errorJson.required}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'EMAIL'">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                        <ng-container
                            *ngIf="field.value.data_type === 'SINGLE_RECORD' || field.value.data_type === 'MULTI_RECORD' ">
                            <ng-container>
                                <ng-select class="form-control" [items]="field.value.start" bindLabel="display_name"
                                    bindValue="id" [addTag]="createAddTagHandler(field.value,i)" [multiple]="field.value.data_type === 'SINGLE_RECORD' ? false : true"
                                    (search)="onSearch($event,field.value,i)" formControlName="data">
                                </ng-select>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
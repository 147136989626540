import { SelectionModel } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { CrmService } from 'src/app/core/service/crm.service';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ContactsListComponent {
  public selection = new SelectionModel<any>(true, []);

  filtersLables = [
    {
      position: 1,
      lableName: 'Name',
      checked: true
    },
    {
      position: 2,
      lableName: 'Email',
      checked: true
    },
    {
      position: 3,
      lableName: 'Phone Number',
      checked: true
    },
    {
      position: 4,
      lableName: 'Company Name',
      checked: true
    },
    {
      position: 5,
      lableName: 'Owner',
      checked: true
    },
    {
      position: 6,
      lableName: 'Source',
      checked: true
    },
    {
      position: 7,
      lableName: 'Created On',
      checked: true
    }
    // {
    //   position: 8,
    //   lableName: 'Delete',
    //   checked: true
    // }
  ];
  currentPage = 1;
  page_size = 20;
  contactsObj: any = {};
  contactListArray: any[] = [];
  group_id: any;
  group_user:any;
  display_no_data = false;
  search_global = '';
  searchSubject: Subject<string> = new Subject<string>();

  constructor(private service: CrmService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      this.group_id = params['groupid'];
      this.group_user = params['user'];
      this.getContactsBasedonGroupId();
    });
    this.searchSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      this.getContactsBasedonGroupId(searchTerm);
    });
  }

  getContactsBasedonGroupId(searchTerm?: any) {
    this.service.getContactsBasedonGroupId(this.group_id, this.currentPage, this.page_size,searchTerm).subscribe((result: any) => {
      this.contactsObj = result;
      this.contactListArray = result.data;
      if (this.contactListArray.length === 0) {
        this.display_no_data = true;
      } else {
        this.display_no_data = false;
      }
    });
  }

  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.currentPage = e.pageIndex + 1;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.currentPage -= 1;
    } else {
      this.currentPage = 1;
    }
    this.getContactsBasedonGroupId();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.contactListArray.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.contactListArray.forEach((row: any) => {
      this.selection.select(row);
    });
  }

  deleteFromList() {
    const selectedContacts: any[] = [];
    this.selection.selected.filter((item: any) => {
      selectedContacts.push(item.id);
    });
    this.deleteContact(selectedContacts);
  }

  deleteContact(selectedContacts?: any) {
    const body = {
      group_id: this.group_id,
      contacts: selectedContacts
    };
    this.service.deleteContactFromGroup(body).subscribe(() => {
      this.getContactsBasedonGroupId();
    });
  }
}

<div class="modal-header">
    <h5 class="modal-title text-primary fw-600">Manage <span
            *ngIf="badge_type === 'APPOINTMENT'">Appointment</span><span *ngIf="badge_type === 'STAFF'">Employee </span>
        <span *ngIf="badge_type === 'PRODUCTS'">Products</span>
        <span class="text-capitalize">{{badges_text}}</span>
    </h5>

    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>
<div class="px-3 pt-3">
    <p class="text-primary">Define new or edit existing {{badges_text}} for <span
            *ngIf="badge_type === 'APPOINTMENT'">Appointment</span><span *ngIf="badge_type === 'STAFF'">Employee</span>
        <span *ngIf="badge_type === 'PRODUCTS'">Products</span> <span *ngIf="badge_type === 'DYNAMICAPP'">Form</span>.
    </p>
</div>

<div class="modal-body mx-1 my-1 py-1 manage-badge">
    <form [formGroup]="badgeForm" #formDirective="ngForm" (ngSubmit)="onSubmit(formDirective)">

        <div class="row border-radius-8 py-2 d-flex align-items-center w-100 m-0" style="background-color: #F2F6FE;">
            <!-- <p class="text-primary my-2">Create New Badge</p> -->
            <div class="col-md-4">
                <label class="fw-600 mb-2 text-primary font-size-13">{{field_text}} Name<small class="text-danger">
                        *</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="{{field_text}} name" formControlName="name" />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['name'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['name'].errors['required']"> {{field_text}} name required</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <label class="fw-600 mb-2 text-primary font-size-13">{{field_text}} Color<small class="text-danger">
                        *</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="Color" formControlName="colour" type="color"
                        style="outline: none;" />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['colour'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['colour'].errors['required']"> {{field_text}} color required</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <label class="fw-600 mb-2 text-primary font-size-13">Text color<small class="text-danger">
                        *</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="Color" formControlName="text_colour" type="color"
                        style="outline: none;" />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['text_colour'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['text_colour'].errors['required']"> {{field_text}} text color required</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-2 d-flex align-items-center">
                <button class="btn bg-white border-1 px-4 mt-2" (click)="resetTagForm(formDirective)"
                    type="button">Clear</button>
            </div>
            <div class="col-md-2 d-flex align-items-center">
                <button class="btn btn-primary px-4 mt-2" *ngIf="!this.badgeForm?.value?.id" type="submit">Add</button>
                <button class="btn btn-primary px-4 mt-2" *ngIf="this.badgeForm?.value?.id"
                    type="submit">Update</button>
            </div>
        </div>
    </form>
    <div class="my-3" style="max-height:300px; overflow: auto;">
        <ul class="list-group list-group-flush">
            <li *ngFor="let item of badgesArray"
                class="list-group-item d-flex align-items-center justify-content-between">
                <span class="border-radius-8 px-2 font-size-13 text-capitalize"
                    [ngStyle]="{'background-color': item?.colour, 'color' : item?.text_colour}">{{item?.name}}</span>
                <div class="d-flex align-items-center justify-content-end">
                    <mat-icon class="cursor" style="font-size: 20px;" (click)="editBadge(item)">edit</mat-icon>
                    <mat-icon class="ms-2 cursor" style="font-size: 20px;"
                        (click)="deleteBadge(item.id)">delete</mat-icon>
                </div>
            </li>

        </ul>
    </div>
</div>
<div class="container-fluid d-flex align-items-center justify-content-center h-100">
    <div class="w-100 p-4 bg-white invoice-custom-height">
        <form [formGroup]="invoice_creation_form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-md-4">
                    <div class="invoice-address">
                        <div class="d-flex align-items-center p-3">
                            <h6 class="text-primary fw-600 mb-0">BILLED BY</h6>
                            <p class="mb-0 ms-2 text-light">( Location Details )</p>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="p-3">
                            <p class="text-primary">LOCATION DETAILS</p>
                            <mat-form-field appearance="outline" class="w-100 mat-hint-none mb-4 bg-white">
                                <mat-select placeholder="Select branch" formControlName="org_address">
                                    <mat-option (click)="selectedBranchAddress(item)" *ngFor="let item of branchs"
                                        value={{item.id}}>{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="card-body" *ngIf="quotation_from?.id">

                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Location</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p>{{quotation_from.location}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Email</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p>{{quotation_from.email}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Phone</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p><span
                                                *ngIf="quotation_from?.phone_number">{{quotation_from.ccode}}&nbsp;</span>
                                            {{quotation_from.phone_number}}</p>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center">
                                    <div class="col-md-4">
                                        <p>Due Date</p>
                                    </div>
                                    <div class="col-md-8">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <input style="outline: none" matInput [matDatepicker]="picker"
                                                placeholder="Select Date" formControlName="issue_date" />
                                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                        <!-- <p>{{min_date | dateConversion}}</p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="invoice-address">
                        <div class="d-flex align-items-center p-3">
                            <h6 class="text-primary fw-600 mb-0">BILLED TO</h6>
                            <p class="mb-0 ms-2 text-light">( Customer Details )</p>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="p-3">
                            <p class="text-primary">EXISTING CUSTOMER NAME</p>
                            <ng-select class="form-control mb-4" [items]="contactsArray" bindLabel="first_name"
                                [multiple]="false" placeholder="Search Contact" (change)="selectedCustomer($event)"
                                (clear)="clearContact()" (search)="searchTriggerContact($event)"
                                formControlName="billing_address">
                            </ng-select>
                            <div *ngIf="quotation_to_billing?.id">
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Name</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p>{{quotation_to_billing.first_name}} {{quotation_to_billing.last_name}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Email</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p>{{quotation_to_billing.email}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Phone</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p><span
                                                *ngIf="quotation_to_billing?.phone_number">{{quotation_to_billing.ccode}}</span>
                                            &nbsp;{{quotation_to_billing.phone_number}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Address<span><img class="ms-2 cursor"
                                                    src="../../../assets/images/edit-board-icon.svg"
                                                    (click)="openEditAddress(add_new_billing_address, 'billing_address')"></span>
                                        </p>
                                    </div>
                                    <div class="col-md-8">
                                        <p>{{ quotation_to_billing?.billing_address ?
                                            quotation_to_billing.billing_address :
                                            (new_billing_address ? new_billing_address : 'NA') }}</p>
                                    </div>
                                </div>
                            </div>
                            <button (click)="openContactDialog()" *ngIf="!quotation_to_billing.hasOwnProperty('id') && (featuresData?.contact_add && featuresData?.contact)"
                                class="btn btn-primary d-flex align-items-center font-size-13 mx-auto">
                                <mat-icon>add</mat-icon>
                                Add New Contact
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="invoice-address">
                        <div class="d-flex align-items-center p-3">
                            <h6 class="text-primary fw-600 mb-0">SHIPPING TO</h6>
                            <p class="mb-0 ms-2 text-light">( Shipping Address )</p>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="px-2 py-3 d-flex align-items-center justify-content-between">
                            <mat-checkbox class="text-primary" formControlName="is_clients_address"
                                (change)="toggleVisibility($event)">Same as Billing
                                Address</mat-checkbox>
                            <!-- <button [disabled]="invoice_creation_form.value.is_clients_address"
                                (click)="openEditAddress(add_new_shipping_address,'shipping_address')"
                                class="btn btn-primary d-flex align-items-center font-size-13 mx-2">
                                <mat-icon>add</mat-icon>
                                Add New
                            </button> -->
                        </div>
                        <div class="p-3" *ngIf="quotation_to_shipping.hasOwnProperty('id')">
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Name</p>
                                </div>
                                <div class="col-md-8">
                                    <p>{{quotation_to_shipping.first_name}} {{quotation_to_shipping.last_name}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Email</p>
                                </div>
                                <div class="col-md-8">
                                    <p>{{quotation_to_shipping.email}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Phone</p>
                                </div>
                                <div class="col-md-8">
                                    <p><span
                                            *ngIf="quotation_to_shipping?.phone_number">{{quotation_to_shipping.ccode}}</span>
                                        &nbsp;{{quotation_to_shipping.phone_number}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Address<span
                                            *ngIf="!(invoice_creation_form.get('is_clients_address')?.value)"><img
                                                class="ms-2 cursor" src="../../../assets/images/edit-board-icon.svg"
                                                (click)="openEditAddress(add_new_shipping_address,'shipping_address')"></span>
                                    </p>
                                </div>
                                <div class="col-md-8">
                                    <p>{{ invoice_creation_form.get('is_clients_address')?.value ?
                                        quotation_to_shipping?.billing_address : quotation_to_shipping.shipping_address
                                        }}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="cart-table mt-4" *ngIf="cartArray.length > 0">
                <h6 class="text-primary fw-600">ITEMS ON THE INVOICE</h6>
                <table class="table table-borderless mt-4">
                    <thead class="table-success">
                        <tr>
                            <th class="text-center font-size-13 fw-600" style="border-radius: 8px 0px 0px 0px;"
                                scope="col">S.No</th>
                            <th class="fw-600 font-size-13" scope="col">PRODUCT/SERVICE</th>
                            <th class="text-center font-size-13 fw-600" scope="col">NET PRICE</th>
                            <th class="text-center font-size-13 fw-600" scope="col">QUANTITY</th>
                            <th class="text-center font-size-13 fw-600" scope="col">DISCOUNT</th>
                            <th class="text-center font-size-13 fw-600" scope="col">DISCOUNT TOTAL</th>
                            <th class="text-center font-size-13 fw-600" scope="col">TAX</th>
                            <th class="text-center font-size-13 fw-600" scope="col">TAX TOTAL</th>
                            <th class="text-center font-size-13 fw-600" style="border-radius: 0px 8px 0px 0px;"
                                scope="col">TOTAL AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody style="background: #FAFCFD;">
                        <tr *ngFor="let cart_item of cartArray; let i=index;">
                            <td class="text-center">{{i+1}}</td>
                            <td style="max-width: 400px;" class="text-wrap">{{cart_item.name}}</td>
                            <td class="text-center">
                                <div class="">
                                    <input type="number" [(ngModel)]="cart_item.price" (focus)="onFocus($event)"
                                        [ngModelOptions]="{standalone: true}" (blur)="calculateCart($event)">
                                </div>
                            </td>
                            <td class="text-center">
                                <!-- {{cart_item.item_quantity}} -->
                                <div class="d-flex align-items-center">
                                    <mat-icon (click)="cartProductQuantity('minus',i,cart_item.item_quantity,'ongo')"
                                        class="font-size-13 d-flex align-items-center justify-content-center cursor"
                                        matPrefix>remove</mat-icon>
                                    {{cart_item.item_quantity}}
                                    <mat-icon (click)="cartProductQuantity('plus',i, cart_item.item_quantity,'ongo')"
                                        class="font-size-13 d-flex align-items-center justify-content-center cursor"
                                        matSuffix>add</mat-icon>
                                </div>
                            </td>
                            <td class="text-center">
                                <span class="d-flex align-items-start justify-content-between">
                                    <input type="number" [(ngModel)]="cart_item.discount_value" class="me-2 w-75"
                                        [ngModelOptions]="{standalone: true}" (ngModelChange)="calculateCart()" onkeydown="javascript: return event.keyCode === 8 ||
                                    event.keyCode === 46 ? true : !isNaN(Number(event.key))">
                                    <mat-select placeholder="Type" [(ngModel)]="cart_item.discount_type"
                                        [ngModelOptions]="{standalone: true}" class="w-25 bg-white mx-1"
                                        (ngModelChange)="calculateCart()">
                                        <mat-option value="PERCENTAGE">%</mat-option>
                                        <mat-option value="AMOUNT">{{ cart_item.currency.split(',')[1] || company_currency}}</mat-option>
                                    </mat-select>
                                </span>
                                <!-- <span *ngIf="cart_item?.discount_type === 'AMOUNT'">
                                    <input type="text" [(ngModel)]="cart_item.discount_value"
                                        [ngModelOptions]="{standalone: true}" (blur)="calculateCart()">
                                    {{cart_item.discount_value |
                                    currencyConversion}} Off
                                </span> -->
                            </td>
                            <!-- <td class="text-center" *ngIf="!cart_item?.discount_value">
                                    -
                                </td> -->
                            <td class="text-center">
                                {{cart_item.product_discount_price ? (cart_item.product_discount_price |
                                currencyConversion : cart_item.currency.split(',')[1]) : '-'}}
                            </td>
                            <td style="width:200px;" class="text-center text-wrap"
                                *ngIf="cart_item?.tax_info?.length > 0"><label *ngFor="let tax of cart_item?.tax_info">
                                    <span *ngIf="tax.status">{{tax.name}}({{tax.percentage}}%)</span>
                                </label>
                            </td>
                            <td class="text-center" *ngIf="cart_item?.tax_info?.length === 0">-</td>
                            <td class="text-center">
                                {{cart_item.product_taxable_amount ?(cart_item.product_taxable_amount |
                                currencyConversion : cart_item.currency.split(',')[1]) : '-'}}</td>
                            <td class="text-center">{{(cart_item.product_discounted_price +
                                cart_item.product_taxable_amount) | currencyConversion : cart_item.currency.split(',')[1]}}</td>
                        </tr>
                        <tr style="background-color: rgba(5, 96, 69, 0.06);">
                            <td style="border-radius: 0px 0px 0px 8px;" colspan="6"></td>
                            <td class="text-end fw-600 font-size-13" colspan="2">SUB TOTAL :</td>
                            <td style="border-radius: 0px 0px 8px 0px;" class="text-center fw-600 font-size-13"
                                colspan="1">{{total_cart_amount | currencyConversion}}</td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="d-flex justify-content-between mt-4">
                <div>
                    <button class="btn btn-primary d-flex align-items-center font-size-13"
                        (click)="openProductsAndServicesModal(products_and_services_modal , 'product_items')">
                        <mat-icon class="mx-1">add</mat-icon>
                        Select Items From Inventory
                    </button>
                </div>
                <div class="additional-data" *ngIf="cartArray.length > 0">
                    <div class="font-size-13 d-none">
                        <ng-container *ngIf="!addAdditionalCharges">
                            <div class="d-flex align-items-center justify-content-end">
                                <img class="me-2 cursor" (click)="addAdditionalCharges = true"
                                    src="../../../assets/images/edit-board-icon.svg">Add addtional
                                charges
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addAdditionalCharges">
                            <div class="d-flex align-items-center justify-content-end">
                                <mat-form-field class="mx-1">
                                    <input style="outline: none" matInput placeholder='Additional charges label'
                                        formControlName="additional_charges_label" />
                                </mat-form-field>
                                <mat-form-field class="mx-1 w-25">
                                    <input style="outline: none" matInput type="number" placeholder='Value'
                                        formControlName="additional_charges_value" />
                                </mat-form-field>
                                <mat-form-field class="w-25 bg-white mx-1">
                                    <mat-select placeholder="Type" formControlName="additional_charges_type"
                                        (selectionChange)="onAdditionCharges('additional_charges')">
                                        <mat-option value="PERCENTAGE">%</mat-option>
                                        <mat-option value="FLAT">{{company_currency}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-icon
                                    class="text-primary font-size-16 d-flex align-items-center justify-content-center mx-1 cursor"
                                    (click)="clearAdditionalCharges()">close</mat-icon>
                            </div>
                        </ng-container>
                    </div>
                    <div class="mt-2 font-size-13 d-none">
                        <ng-container *ngIf="!addAdditionalDiscount">
                            <div class="d-flex align-items-center justify-content-end">
                                <img class="me-2 cursor" (click)="addAdditionalDiscount = true"
                                    src="../../../assets/images/edit-board-icon.svg">Give discount on
                                total
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addAdditionalDiscount">
                            <div class="d-flex align-items-center justify-content-end">
                                <mat-form-field class="mx-1">
                                    <input style="outline: none" matInput placeholder='Discount label'
                                        formControlName="additional_discount_label" />
                                </mat-form-field>
                                <mat-form-field class="mx-1 w-25">
                                    <input style="outline: none" matInput type="number" placeholder='Value'
                                        formControlName="additional_discount_value" />
                                </mat-form-field>
                                <mat-form-field class="w-25 bg-white mx-1">
                                    <mat-select placeholder="Type" formControlName="additional_discount_type"
                                        (selectionChange)="onAdditionCharges('discount')">
                                        <mat-option value="PERCENTAGE">%</mat-option>
                                        <mat-option value="FLAT">{{company_currency}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-icon
                                    class="text-primary font-size-16 d-flex align-items-center justify-content-center mx-1 cursor"
                                    (click)="clearExtraDiscount()">close</mat-icon>
                            </div>
                        </ng-container>
                    </div>
                    <h5 class="mt-3 text-primary fw-600">Total:
                        <!-- <span
                            *ngIf="!this.invoice_creation_form.value.additional_discount_value && !this.invoice_creation_form.value.additional_charges_value">{{total_cart_amount
                            | currencyConversion}}</span>
                        <span
                            *ngIf="this.invoice_creation_form.value.additional_discount_label && this.invoice_creation_form.value.additional_discount_value && this.invoice_creation_form.value.additional_discount_type === 'FLAT' && !this.invoice_creation_form.value.additional_charges_value">{{total_cart_amount
                            - this.invoice_creation_form.value.additional_discount_value | currencyConversion}}</span>
                        <span
                            *ngIf="this.invoice_creation_form.value.additional_discount_label && this.invoice_creation_form.value.additional_discount_value && this.invoice_creation_form.value.additional_discount_type === 'PERCENTAGE'  && !this.invoice_creation_form.value.additional_charges_value">{{(total_cart_amount
                            - ((total_cart_amount
                            * this.invoice_creation_form.value.additional_discount_value)/100)) |
                            currencyConversion}}</span>
                        <span
                            *ngIf="this.invoice_creation_form.value.additional_charges_label && this.invoice_creation_form.value.additional_charges_value && this.invoice_creation_form.value.additional_charges_type === 'FLAT' && !this.invoice_creation_form.value.additional_discount_value">{{total_cart_amount
                            + this.invoice_creation_form.value.additional_charges_value | currencyConversion}}</span>
                        <span
                            *ngIf="this.invoice_creation_form.value.additional_charges_label && this.invoice_creation_form.value.additional_charges_value && this.invoice_creation_form.value.additional_charges_type === 'PERCENTAGE' && !this.invoice_creation_form.value.additional_discount_value">{{(total_cart_amount
                            + ((total_cart_amount
                            * this.invoice_creation_form.value.additional_discount_value)/100)) |
                            currencyConversion}}</span> -->
                        <span *ngIf="final_amount !== 0">{{final_amount | currencyConversion}}</span>
                        <span *ngIf="final_amount === 0">{{total_cart_amount | currencyConversion}}</span>
                    </h5>
                </div>
            </div>


            <div class="mt-4">
                <mat-checkbox class="text-primary mb-2" formControlName="is_terms_and_condition"
                    (change)="toggleVisibilityForTermsCondition($event,'terms&conditions')"><u>Add
                        Terms And Conditions</u></mat-checkbox>
                <ckeditor *ngIf="invoice_creation_form.value.is_terms_and_condition" [editor]="Editor"
                    placeholder="Description" formControlName="terms_conditions" [config]="ckconfigQue"
                    #editorComponent>
                </ckeditor>
            </div>
            <div class="mt-3" *ngIf="invoice_type === 'PROFORMA'">
                <mat-checkbox class="text-primary mb-2" formControlName="is_bank_details"
                    (change)="toggleVisibilityForTermsCondition($event,'bank_details')"><u>Add
                        Bank Details</u></mat-checkbox>
                <ckeditor *ngIf="invoice_creation_form.value.is_bank_details" [editor]="Editor"
                    placeholder="Bank Details" formControlName="bank_details" [config]="ckconfigQue" #editorBankDetails>
                </ckeditor>
                <div class="">
                    <label>Payment Mode</label>
                    <mat-radio-group formControlName="payment_online" (change)="onPaymentModeChange($event)">
                        <mat-radio-button [value]="true">Online</mat-radio-button>
                        <mat-radio-button [value]="false">Offline</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="" *ngIf="invoice_creation_form.value.payment_online">
                    <label>Select Payment Type</label>
                    <mat-radio-group formControlName="payment_name">
                        <mat-radio-button [value]="'razorpay'">razorpay</mat-radio-button>
                        <mat-radio-button [value]="'stripe'">stripe</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="mt-3">
                <mat-checkbox class="text-primary" formControlName="is_contact_us"
                    (change)="toggleVisibilityForContactUs($event)"><u>Contact
                        Us</u></mat-checkbox>
                <ckeditor *ngIf="invoice_creation_form.value.is_contact_us" [editor]="Editor" [config]="ckconfigQue"
                    formControlName="footer_contact_us" #editorComponentFooter>
                </ckeditor>
            </div>
            <div class="d-flex align-items-center">
                <button class="btn btn-primary d-flex align-items-center font-size-13 px-4 mt-3"
                    (click)="openProductsAndServicesModal(products_and_services_modal , 'billing_cart')">
                    Complete Payment
                    <mat-icon
                        class="d-flex align-items-center justify-content-center font-size-16 ms-2">arrow_forward</mat-icon>
                </button>
                <button class="btn btn-light d-flex align-items-center font-size-13 px-4 mt-3 ms-3"
                    (click)="triggerRoleBack(false)">
                    Close
                </button>
            </div>
        </form>
    </div>
</div>
<ng-template #products_and_services_modal let-modal>
    <div class="org-products px-4 py-3">
        <ng-container *ngIf="on_popup === 'product_items'">
            <div class="d-flex align-items-center justify-content-between">
                <h6 class="text-primary fw-600 mb-0">Add Products</h6>
                <button class="btn btn-primary d-flex align-items-center font-size-13 mx-2"
                    (click)="createNewProduct()" *ngIf="featuresData?.products_service">
                    <mat-icon>add</mat-icon>
                    Add New
                </button>
            </div>
            <div class="list-of-products my-3">
                <div class="products-header p-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <mat-form-field appearance="outline" class="mat-hint-none w-75 bg-white me-2">
                            <input style="outline: none;" matInput placeholder="Search" [(ngModel)]="search_global"
                                (ngModelChange)="search($event)" />
                            <svg class="mx-3" xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                viewBox="0 0 13 13" fill="none" matPrefix>
                                <path
                                    d="M11.9026 12.0056L9.26388 9.53505M10.7262 6.23332C10.7262 7.47262 10.1994 8.66116 9.26176 9.53747C8.32412 10.4138 7.0524 10.9061 5.72637 10.9061C4.40034 10.9061 3.12862 10.4138 2.19097 9.53747C1.25333 8.66116 0.726563 7.47262 0.726562 6.23332C0.726563 4.99402 1.25333 3.80549 2.19097 2.92917C3.12862 2.05286 4.40034 1.56055 5.72637 1.56055C7.0524 1.56055 8.32412 2.05286 9.26176 2.92917C10.1994 3.80549 10.7262 4.99402 10.7262 6.23332V6.23332Z"
                                    stroke="black" stroke-opacity="0.6" stroke-width="1.46597" stroke-linecap="round" />
                            </svg>
                            <svg class="mx-3" xmlns="http://www.w3.org/2000/svg" width="18" height="15"
                                viewBox="0 0 18 15" fill="none" matSuffix>
                                <path
                                    d="M8.93319 3.59209C13.5033 3.59209 17.2082 2.93576 17.2082 2.12612C17.2082 1.31649 13.5033 0.660156 8.93319 0.660156C4.36304 0.660156 0.658203 1.31649 0.658203 2.12612C0.658203 2.93576 4.36304 3.59209 8.93319 3.59209Z"
                                    stroke="black" stroke-opacity="0.4" stroke-width="0.732984" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path
                                    d="M0.658203 2.12598C0.658203 3.76053 4.21737 7.01791 6.04246 8.5799C6.71196 9.14567 7.08779 9.8926 7.0943 10.6704V11.8741C7.0943 13.1741 8.40648 14.0637 9.61408 13.5823V13.5823C10.3133 13.3036 10.7721 12.6269 10.7721 11.8741V10.6704C10.7721 9.89268 11.1592 9.14943 11.8239 8.5799C13.6499 7.01791 17.2082 3.76126 17.2082 2.12598"
                                    stroke="black" stroke-opacity="0.4" stroke-width="0.732984" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="mat-hint-none bg-white ms-2">
                            <mat-select placeholder="Select" [(ngModel)]="selected_type"
                                (ngModelChange)="selectedType($event)">
                                <mat-option value="PRODUCT">Products</mat-option>
                                <mat-option value="SERVICE">Services</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="d-flex align-items-center my-2 py-1" style="overflow-x: auto; width:677px;">
                        <ng-container *ngFor="let item of categories_list; let i=index;">
                            <div class="d-flex align-items-center cursor" style="min-width: fit-content;" [id]="item.id"
                                (click)="onSelectFilterCategory(item.id)"
                                [ngClass]="{ 'mx-1' : i > 0 , 'me-1' : i === 0, 'category-buttons-active': onSelectedCategory === item.id, 'category-buttons' : onSelectedCategory !== item.id   }">
                                {{item.name}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="products-body p-3 d-flex flex-row flex-wrap">
                    <ng-container *ngFor="let item of productsArray">
                        <div class="product-cards m-1">
                            <div class="product-upper-data text-center p-1">
                                <img [src]="item.image[0]" style="width: 60px;
                        height: 60px;border-radius: 46.8px;"
                                    onerror="this.src='../../../../assets/images/default_image.png'">
                                <div style="width: 120px;" class="text-ellipsis font-size-13 text-primary mt-3 fw-600">
                                    {{item.name}}
                                </div>
                                <p class="fw-600 mt-1 mb-0">
                                    <span *ngIf="item?.discount_type === 'AMOUNT' || !item?.discount_type">
                                        {{item.discount_value ? (item.price - item.discount_value |
                                        currencyConversion : item.currency.split(',')[1])
                                        : item.price | currencyConversion : item.currency.split(',')[1]}}
                                    </span>
                                    <span *ngIf="item?.discount_type === 'PERCENTAGE'">
                                        {{item.price - ((item.price*item.discount_value)/100) |
                                        currencyConversion : item.currency.split(',')[1]}}
                                    </span>
                                    <s class="text-light ms-1" *ngIf="item.discount_value">{{item.price |
                                        currencyConversion : item.currency.split(',')[1]}}</s>
                                </p>
                            </div>
                            <div class="product-bottom-button d-flex align-items-center"
                                [ngClass]="item.discount_value !== 0   ? 'justify-content-between' : 'justify-content-end'">
                                <button
                                    class="btn d-flex align-items-center justify-content-center font-size-13 text-danger"
                                    *ngIf="(item?.discount_type === 'PERCENTAGE' && item.discount_value !== 0) || (item?.discount_type === 'AMOUNT' && item?.discount_value !== 0)"
                                    style="padding: 2px 4px;background: rgba(255, 0, 0, 0.10);">
                                    <span *ngIf="item?.discount_type === 'PERCENTAGE'">
                                        {{item.discount_value}}% Off
                                    </span>
                                    <span *ngIf="item?.discount_type === 'AMOUNT'">
                                        {{item.discount_value |
                                        currencyConversion : item.currency.split(',')[1]}} Off
                                    </span>
                                </button>
                                <button class="btn btn-primary d-flex align-items-center font-size-12"
                                    (click)="addCartItems(item)" style="padding: 2px 6px;">
                                    <mat-icon *ngIf="!isItemInCart(item)"
                                        class="font-size-13 d-flex align-items-center justify-content-center">add</mat-icon>
                                        {{isItemInCart(item) ? 'ADDED' : 'ADD'}}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="productsArray.length === 0" class="w-100">
                        <app-nodata-found class="w-100"></app-nodata-found>
                    </ng-container>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between py-2" *ngIf="cartArray.length>0">
                <h6 class="text-primary fw-600 mb-0">Items Added</h6>
                <p class="text-primary mb-0 cursor" (click)="cartArray = []">Clear All</p>
            </div>
            <div class="invoice-cart d-flex align-items-center flex-row mt-2">
                <ng-container *ngFor="let item of cartArray; let i=index;">
                    <div class="product-cards-cart m-1 position-relative">
                        <div class="position-absolute bg-danger border-radius-50 d-flex align-items-center justify-content-center cursor"
                            style="top:6px;right:6px;width: 20px;height: 20px;" (click)="removeCartItem(i)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                <path d="M6.64418 0.840856L0.839844 6.64519M6.64418 6.64515L0.839844 0.84082"
                                    stroke="white" stroke-width="1.02607" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div class="product-upper-data text-center p-1">
                            <img [src]="item.image[0]" style="width: 60px;
                    height: 60px;border-radius: 46.8px;">
                            <div style="width: 120px;" class="text-ellipsis font-size-13 text-primary mt-3 fw-600">
                                {{item.name}}
                            </div>
                            <p class="fw-600 mt-1 mb-0">
                                <span *ngIf="item?.discount_type === 'AMOUNT' || !item?.discount_type">
                                    {{item.discount_value ? (item.price - item.discount_value | currencyConversion : item.currency.split(',')[1])
                                    : item.price | currencyConversion : item.currency.split(',')[1]}}
                                </span>
                                <span *ngIf="item?.discount_type === 'PERCENTAGE'">
                                    {{item.price - ((item.price*item.discount_value)/100) | currencyConversion : item.currency.split(',')[1]}}
                                </span>
                                <s class="text-light ms-1" *ngIf="item.discount_value">{{item.price |
                                    currencyConversion : item.currency.split(',')[1]}}</s>
                            </p>
                        </div>
                        <div class="product-bottom-button d-flex align-items-center justify-content-end">
                            <button
                                class="btn btn-primary d-flex align-items-center justify-content-between font-size-12"
                                style="padding: 2px 6px;width: 60%;">
                                <mat-icon (click)="cartProductQuantity('minus',i,item.item_quantity)"
                                    class="font-size-13 d-flex align-items-center justify-content-center cursor"
                                    matPrefix>remove</mat-icon>
                                {{item.item_quantity}}
                                <mat-icon (click)="cartProductQuantity('plus',i, item.item_quantity)"
                                    class="font-size-13 d-flex align-items-center justify-content-center cursor"
                                    matSuffix>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="d-flex align-items-center justify-content-end mt-3">
                <button class="btn btn-primary d-flex align-items-center font-size-13 px-4"
                    (click)="modal.dismiss('Cross click');calculateCart()" *ngIf="cartArray.length > 0">
                    Next <mat-icon
                        class="d-flex align-items-center justify-content-center font-size-13">arrow_forward</mat-icon>
                </button>
                <button class="btn btn-primary d-flex align-items-center font-size-13 px-4"
                    (click)="modal.dismiss('Cross click')" *ngIf="cartArray.length === 0">
                    Close
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="on_popup === 'new_product'">
            <form [formGroup]="product_form" (ngSubmit)="onProductSubmit()">

                <div class="row mt-3">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="mb-2 ">Product Name <small class="text-danger">*</small></label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder="Name" formControlName="name"
                                #myFirstInput />
                            <mat-error class="error d-flex" *ngIf="product_submiteed && f['name'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span *ngIf="f['name'].errors['required']">
                                    Product name is required</span>
                                <span *ngIf="f['name'].errors['pattern']">Invalid
                                    produc name</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="mb-2 ">Type<small class="text-danger">*</small></label>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-select placeholder="Select Type" formControlName="type">
                                <mat-option value="PRODUCT">Product</mat-option>
                                <mat-option value="SERVICE">Service</mat-option>
                            </mat-select>
                            <mat-error class="error d-flex" *ngIf="product_submiteed && f['type'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span *ngIf="f['type'].errors['required']">Type is required</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="mb-2 ">Price <small class="text-danger">*</small></label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder="Price" formControlName="price"
                                type="number" (wheel)="$event.preventDefault()"
                                (keydown)="keyDownEvent($event,'price')" />
                            <mat-error class="error d-flex" *ngIf="product_submiteed && f['price'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span *ngIf="f['price'].errors['required']"> Price is required</span>
                                <span *ngIf="f['price'].errors['min']"> Price needs to be greater then 0</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="mb-2 font-size-13 ">Discount Type</label>
                        <ng-select class="form-control" [items]="discount_type_list" bindLabel="name" bindValue="id"
                            [multiple]="false" placeholder="Select type" formControlName="discount_type"
                            (change)="onChangeDiscountType('discount_value')">
                        </ng-select>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="mb-2 ">Discount</label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput type="number"
                                (keydown)="keyDownEvent($event,'discount_type')" (wheel)="$event.preventDefault()"
                                placeholder="Discount" formControlName="discount_value" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="mb-2 ">Unit <small class="text-danger">*</small></label>
                        <mat-form-field appearance="outline" class="w-100 mb-0">
                            <input style="outline: none;" matInput placeholder="Units" formControlName="unit"
                                type="number" />
                            <mat-error class="error d-flex" *ngIf="product_submiteed && f['unit'].errors">
                                <mat-icon class="font-size-12 d-flex align-items-center m-0">info</mat-icon>
                                <span *ngIf="f['unit'].errors['required']">Unit is required</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="mb-2 ">Unit Type <small class="text-danger">*</small></label>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-select formControlName="measure" placeholder="Unit type">
                                <mat-option [value]="item" *ngFor="let item of unit_type_list">{{item}}</mat-option>
                            </mat-select>
                            <mat-error class="error d-flex" *ngIf="product_submiteed && f['measure'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span *ngIf="f['measure'].errors['required']">Unit type is required</span>

                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="font-size-13 mb-2">Tax</label>
                        <ng-select class="form-control" [items]="listOfTaxTypes" bindValue="id" bindLabel="name"
                            [addTag]="false" [multiple]="true" placeholder="Select Tax" formControlName="tax">
                        </ng-select>
                    </div>
                </div>

                <div class="mt-4 d-flex align-items-center justify-content-end">
                    <button class="btn btn-light px-3 me-2" type="button" (click)="on_popup = 'product_items'">
                        Close
                    </button>
                    <button class="btn btn-primary px-4" type="submit">
                        Create
                    </button>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="on_popup === 'billing_cart'">
            <div class="invoice-payment-screen" *ngIf="cartArray.length > 0">
                <div class="row">
                    <div class="col-md-5">
                        <h5 class="text-primary fw-600 my-3">Select Payment</h5>
                        <div class="d-flex flex-wrap">
                            <!-- condition commented for future use when multiple mode of payments added -->
                            <!-- [ngClass]="mode_selected ? 'invoice-payment-modes-active' : 'invoice-payment-modes'"
                                (click)="mode_selected = !mode_selected" -->
                            <div
                                class="d-flex flex-column align-items-center justify-content-center invoice-payment-modes-active cursor">
                                <img width="24px" height="24px" src="../../../../assets/images/cash-icon.svg">
                                <p class="fw-600 mb-0">Cash</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <mat-divider style="height: 90vh;" [vertical]="true"></mat-divider>
                    </div>
                    <div class="col-md-6">
                        <h5 class="text-primary fw-600 my-3">Walk-In</h5>
                        <mat-divider class="mb-5"></mat-divider>
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            <h6 class="fw-600 text-light">Invoice Type</h6>
                            <h6 class="fw-600 text-light font-size-12">{{invoice_type}}</h6>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            <h6 class="fw-600 text-light">Item</h6>
                            <h6 class="fw-600 text-light">Amount</h6>
                        </div>
                        <ng-container *ngFor="let cart_item of cartArray">
                            <div class="d-flex align-items-center justify-content-between my-4">
                                <h6 class="fw-600 text-primary">{{cart_item.name}}</h6>
                                <h6 class="text-primary">{{cart_item.item_quantity}} <span class="font-size-13">x</span>
                                    <span *ngIf="cart_item?.discount_type === 'AMOUNT' || !cart_item?.discount_type">
                                        {{cart_item.discount_value ? (cart_item.price - cart_item.discount_value |
                                        currencyConversion : cart_item.currency.split(',')[1])
                                        : cart_item.price | currencyConversion : cart_item.currency.split(',')[1]}}
                                    </span>
                                    <span *ngIf="cart_item?.discount_type === 'PERCENTAGE'">
                                        {{cart_item.price - ((cart_item.price*cart_item.discount_value)/100) |
                                        currencyConversion : cart_item.currency.split(',')[1]}}
                                    </span>
                                </h6>
                            </div>
                            <mat-divider></mat-divider>
                        </ng-container>
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            <h6 class="fw-600 text-primary">Total Discount</h6>
                            <h6 class="text=primary">- {{total_cart_discount ? (total_cart_discount |
                                currencyConversion) : '-'}}</h6>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <h6 class="fw-600 text-primary">Total Tax</h6>
                            <h6 class="text-primary"><span *ngIf="total_taxable_amount || total_taxable_amount !==0">+
                                    {{total_taxable_amount | currencyConversion}}</span><span
                                    *ngIf="!total_taxable_amount || total_taxable_amount ===0">-</span></h6>
                        </div>
                        <div class="d-none align-items-center justify-content-between">
                            <h6 class="fw-600 text-primary">{{this.invoice_creation_form.value.additional_charges_label
                                ? this.invoice_creation_form.value.additional_charges_label : 'Additional Charges'}}
                            </h6>
                            <h6 class="text-primary"><span *ngIf="totalCharges || totalCharges !==0">+
                                    {{totalCharges | currencyConversion}}</span><span
                                    *ngIf="!totalCharges || totalCharges ===0">-</span></h6>
                        </div>
                        <div class="d-none align-items-center justify-content-between">
                            <h6 class="fw-600 text-primary">{{this.invoice_creation_form.value.additional_discount_label
                                ? this.invoice_creation_form.value.additional_discount_label : 'Total Discount'}}</h6>
                            <h6 class="text-primary"><span *ngIf="totalDiscount || totalDiscount !==0">-
                                    {{totalDiscount | currencyConversion}}</span><span
                                    *ngIf="!totalDiscount || totalDiscount ===0">-</span></h6>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <h5 class="fw-600 text-primary">TOTAL</h5>
                            <h5 class="text-primary fw-600">
                                <span *ngIf="final_amount !== 0">{{final_amount | currencyConversion}}</span>
                                <span *ngIf="final_amount === 0">{{total_cart_amount | currencyConversion}}</span>
                            </h5>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            <div class="">
                                <button type="button" class="btn btn-light me-2" (click)="closeModal()">Close</button>
                            </div>
                            <div class="d-flex align-items-center">
                                <button type="button" class="btn btn-light me-2" (click)="on_popup = 'product_items'">Go
                                    To Cart</button>
                                <button type="button"
                                    class="btn btn-primary d-flex align-items-center justify-content-between"
                                    (click)="CreateManualInvoice()">Next
                                    <mat-icon
                                        class="d-flex align-items-center justify-content-center font-size-16 ms-2">arrow_forward</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center" style="height: 90vh;"
                *ngIf="cartArray.length === 0">
                <div class="text-center">
                    <h4 class="text-primary fw-600"> Oops! your cart is empty</h4>
                    <button type="button" class="btn btn-primary mt-3" (click)="on_popup = 'product_items'">Add To
                        Cart</button>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #add_new_billing_address let-modal>
    <div class="modal-header">
        <h6 class="textprimary fw-600">Address</h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <textarea [(ngModel)]="new_billing_address" type="text" class="w-100" style="height: 100px;"></textarea>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close(new_billing_address);">Save</button>
    </div>
</ng-template>

<ng-template #add_new_shipping_address let-modal>
    <div class="modal-header">
        <h6 class="textprimary fw-600">Address</h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <textarea [(ngModel)]="new_shipping_address" type="text" class="w-100" style="height: 100px;"></textarea>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close(new_shipping_address)">Next</button>
    </div>
</ng-template>
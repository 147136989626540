import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-crm-sidebar',
  templateUrl: './crm-sidebar.component.html',
  styleUrls: ['./crm-sidebar.component.scss']
})
export class CrmSidebarComponent implements OnInit {
  menuList: any = [
    {
      id: 1,
      label: 'Leads',
      link: '/crm/leads',
      slug: 'leads',
      image: './assets/images/add-enquiry.svg',
      white_image: './assets/images/add-enquiry.svg',
      launchDlKeys: ['enquiry'],
      role_key: 'leads',
      isEnable: false,
      queryParams: {}
    },
    {
      id: 2,
      label: 'Companies',
      link: '/crm/companies',
      slug: 'company',
      image: './assets/images/add-company.svg',
      white_image: './assets/images/add-company.svg',
      launchDlKeys: ['company'],
      role_key: 'companies',
      isEnable: false,
      queryParams: {}
    }
  ];
  selectedIndex = 0;
  featuresData: any;
  loggedInUser: any;
  searchInput = "";
  originalMenuList: any;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getFeatues();
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
      this.menuList.forEach((elem: any) => {
        if (elem.launchDlKeys) {
          const searchedItem = elem.launchDlKeys.find((ele: any) => !this.featuresData[ele]);
          if (searchedItem || !this.authService.rolefeatureShow(elem.role_key, 'can_read')) {
            elem.isEnable = false;
          } else {
            elem.isEnable = true;
          }
        }
      });
      this.getContactList();
    });
  }
  getRolePermissions(item: any, permission: any) {
    return this.authService?.rolefeatureShow(item + '-' + this.loggedInUser.data.organisation, permission);
  }

  getContactList() {
    // this.authService.getGroupList().subscribe((resp: any) => {
    //   resp.data.forEach((element: any, i: number) => {
    //     this.menuList.push({
    //       id: i + 2,
    //       label: element.name,
    //       link: '/campaigns/contact-list',
    //       slug: 'Contacts',
    //       image: './assets/images/new_list.svg',
    //       white_image: './assets/images/newUI/HOME/contact_icon.svg',
    //       launchDlKeys: ['contact'],
    //       role_key: 'contacts',
    //       isEnable: true,
    //       queryParams: { user: element.name, groupid: element.id }
    //     });
    //   });
    // });

    // Fetch Object Types
    this.authService.getObjectTypes().subscribe((resp: any) => {
      resp.data.forEach((element: any, i: number) => {
        if (this.getRolePermissions(element?.name, 'can_read')) {
          this.menuList.push({
            id: i + 2,//10
            label: element.name,
            link: '/crm/records',
            slug: element.name,
            image: './assets/images/collection.svg',
            white_image: './assets/images/collection.svg',
            launchDlKeys: ['contact'],
            role_key: 'contacts',
            isEnable: true,
            queryParams: { object_type: element.id, show_type: element.name }
          });
        }
      });

      if (this.menuList.length > 0 && this.router.url === '/crm') {
        const list: any = this.menuList.find((ele: any) => ele.isEnable);
        if (list) this.routerURL(list, 0);
      } else {
        const currentUrl: any = decodeURIComponent(this.router.url);
        this.selectedIndex = this.menuList.findIndex((ele: any) => {
          let fullUrl = ele.link;
          if (ele?.queryParams) {
            const params = new URLSearchParams(ele.queryParams);
            fullUrl = `${ele.link}?${params.toString()}`;
          }
          return decodeURIComponent(fullUrl.replace(/\+/g, ' ')) === decodeURIComponent(currentUrl);
        });
      }
      this.route.queryParams.subscribe((params: any) => {
        if (params.object_type) this.selectedIndex = this.menuList.findIndex((ele: any) => ele.queryParams?.object_type === params.object_type);
      });
      this.originalMenuList = this.menuList;
    });
  }

  onSearchInputChange(event: any) {
    if (this.searchInput !== "") {
      this.menuList = this.originalMenuList.filter((item: any) => item.label.toLowerCase().includes(this.searchInput.toLowerCase()));
    } else {
      this.menuList = this.originalMenuList;
    }
  }

  routerURL(item: any, index: number) {
    if (item.queryParams) {
      this.router.navigate([item?.link], { queryParams: item.queryParams });
    } else {
      this.router.navigateByUrl(item?.link);
    }
    this.selectedIndex = index;
  }
}

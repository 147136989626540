<div class="d-flex no-gutters details-modal" [ngStyle]="{'padding-top':headerHeight - 14 +'px'}">
    <div class="col-md-3 col-lg-3 col-xl-3 pe-0 h-100">
        <app-crm-info></app-crm-info>
    </div>
    <div class="col-md-9 col-lg-9 col-xl-9 h-100 ps-0 py-3 ps-1 bg-white" style="padding-right: 54px;">
        <div class="crm-client-content">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom px-3 mt-0 tab-sec-height"
                (navChange)="tabChange($event)">
                <li [ngbNavItem]="17" *ngIf="pipeline_order_id" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Order Details</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-0">
                            <app-order-detail [order_id]="pipeline_order_id"></app-order-detail>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="1" *ngIf="timeline" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Timeline</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-0">
                            <app-time-line></app-time-line>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="18" *ngIf="selected_type === 'contact'" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Relations</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem">
                            <div class="p-3">
                                <div class="table-responsive table-height fixed-table">
                                    <table class="table align-middle table-nowrap table-hover mb-0 w-100 call-table">
                                        <thead>
                                            <tr class="border-top-table">
                                                <ng-container *ngFor="let col of tableHeader">
                                                    <th scope="col"
                                                        class="text-primary font-size-13 pb-3 table-sticky-header bg-white"
                                                        style="z-index: 999;">{{col}}</th>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="cursor" *ngFor="let item of contact_relations; let i = index"  [routerLink]="['/crm/crm-details']"
                                            [queryParams]="{id:item?.contact,showtype: 'contact',object_type: item?.object_type}">
                                                <td>
                                                    {{ item.display_id }}
                                                </td>
                                                <td>
                                                    {{ item.label }}
                                                </td>
                                                <td>
                                                    {{ item.email }}
                                                </td>
                                                <td>{{ item.phone_number }}</td>
                                                <td>{{ item.object_name }}</td>
                                                <td>
                                                    {{item.data_type }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" *ngIf="task" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Task Board</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="pt-2 pb-0">
                            <app-task-board></app-task-board>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" *ngIf="notes" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Notes</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-notes-crm></app-notes-crm>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" *ngIf="selected_type !== 'deal' && activity" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Activity</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="py-2">
                            <app-activity-crm></app-activity-crm>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="5" *ngIf="files" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Files</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="py-3">
                            <app-files-modal></app-files-modal>
                        </div>
                    </ng-template>
                </li>
                <!-- <li [ngbNavItem]="6" *ngIf="selected_type !== 'lead' && selected_type !== 'deal'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Pipelines</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-deals-modal></app-deals-modal>
                    </ng-template>
                </li> -->
                <li [ngbNavItem]="7"
                    *ngIf="selected_type !== 'lead' && selected_type !== 'deal' && projects && getRolePermissions('client-projects','can_read') && featuresData?.projects"
                    class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Projects</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-3">
                            <app-project-modal></app-project-modal>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="11"
                    *ngIf="selected_type !== 'lead' && selected_type !== 'deal' && enquiry && getRolePermissions('leads','can_read') && featuresData?.enquiry"
                    class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Leads</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-3">
                            <div class="custom-table-div-crm-height d-flex flex-column justify-content-between"
                                style="height: calc(100vh - 224px);">
                                <div class="table-responsive table-height fixed-table">
                                    <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="7"
                                        [fromLocation]="'CRM'"></app-table-skeleton>
                                    <table *ngIf="!tableSkeletonLoader"
                                        class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                                        <thead>
                                            <tr class="border-top-table bg-white" style="z-index: 999;">
                                                <ng-container *ngFor="let col of originalTableColumnLists">
                                                    <th *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                                                        scope="col"
                                                        class="text-primary font-size-13 pb-3 table-sticky-header bg-white text-capitalize"
                                                        style="z-index: 999;min-width: 100px;max-width: 150px;">
                                                        {{col.field_name!.split("_").join(" ")}}</th>
                                                </ng-container>
                                                <th class="text-primary font-size-13 pb-3 table-sticky-header bg-white"
                                                    style="min-width: 60px;">Move To</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="!display_no_data">
                                            <tr class="cursor" *ngFor="let item of leadsListArray; let i = index"
                                                [routerLink]="['../crm-details']"
                                                [ngClass]="{'inactiveClass': !item?.is_contact_active }"
                                                [queryParams]="{ id: item.id, showtype: 'lead',contact_id: item.contact,contact_active: item?.is_contact_active }">
                                                <ng-container *ngFor="let col of originalTableColumnLists">
                                                    <td *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                                                        style="max-width: 200px;"
                                                        [ngStyle]="{'min-width': item[col.field_name] && item[col.field_name].length < 10 ? '110px' : !item[col.field_name] ? '80px' : '150px'}">
                                                        <div *ngIf="col.system_field">
                                                            <span
                                                                *ngIf="col.field_name === 'phone_number' && item[col.field_name]">{{item[col.field_name]
                                                                ? item.ccode : ''}}&nbsp;{{item[col.field_name] ||
                                                                'NA'}}</span>
                                                            <span class="limit-line-length-1"
                                                                *ngIf="col.field_name === 'owner'">{{(item?.owner_info?.fname
                                                                || '-') + ' ' + (item?.owner_info?.lname || '')}}</span>
                                                            <span class="limit-line-length-1"
                                                                *ngIf="col.field_name === 'company'">{{item.company_name
                                                                || 'NA'}}</span>
                                                            <span class="limit-line-length-1"
                                                                *ngIf="col.data_type === 'DATE_TIME'">
                                                                {{item[col.field_name] | dateConversion }} {{
                                                                item[col.field_name] | date: 'h:mm a' || 'NA'}}
                                                            </span>
                                                            <span class="limit-line-length-1"
                                                                *ngIf="col.field_name === 'contact'">
                                                                {{ item.name || 'NA'}}
                                                            </span>
                                                            <div *ngIf="col.field_name === 'tags'" class="d-flex">
                                                                <span class="text-name m-0" *ngIf="!item['tag_name']"
                                                                    style="width: -webkit-fill-available;">NA</span>
                                                                <p *ngIf="item['tag_name']"
                                                                    class="text-name m-0 d-inline-flex align-items-center border-radius-50">
                                                                    <span class="limit-line-length-1 word-break">
                                                                        {{item?.tag_name}}</span>
                                                                </p>
                                                            </div>
                                                            <span class="limit-line-length-1"
                                                                *ngIf="col.field_name !== 'contact' && col.field_name !== 'phone_number' && col.field_name !== 'tags' && col.data_type !== 'DATE_TIME' && col.field_name !== 'ccode' && col.field_name !== 'owner' && col.field_name !== 'company'">{{item[col.field_name]
                                                                || 'NA'}}</span>
                                                        </div>
                                                        <div *ngIf="!col.system_field">
                                                            <ng-container
                                                                *ngFor="let customField of item?.custom_contact_fields">
                                                                <ng-container
                                                                    *ngIf="customField.label === col.field_name">
                                                                    <span class="limit-line-length-1"
                                                                        *ngIf="col.data_type === 'DATE'">
                                                                        {{customField.data ? (customField.data |
                                                                        dateConversion) : 'NA'}}
                                                                    </span>
                                                                    <span class="limit-line-length-1"
                                                                        *ngIf="col.data_type === 'DATE_TIME'">
                                                                        {{customField.data
                                                                        | dateConversion }} {{ customField.data | date:
                                                                        'h:mm a' || 'NA'}}
                                                                    </span>
                                                                    <span *ngIf="col.data_type === 'DATE_RANGE'">
                                                                        <span class="limit-line-length-1"
                                                                            *ngIf="customField.data && customField.data.split('&').length > 1">
                                                                            {{customField?.data.split("&")[0].split("-").reverse().join("/")
                                                                            + ' - ' +
                                                                            customField?.data.split("&")[1].split("-").reverse().join("/")}}
                                                                        </span>
                                                                        <span *ngIf="!customField.data">
                                                                            NA
                                                                        </span>
                                                                    </span>
                                                                    <span class="limit-line-length-1"
                                                                        *ngIf="col.data_type === 'PHONE_NUMBER'">
                                                                        {{customField?.data?.custom_ccode ?
                                                                        customField?.data?.custom_ccode :
                                                                        ''}}&nbsp;{{customField?.data?.custom_phone_number
                                                                        || 'NA'}}
                                                                    </span>
                                                                    <span class="limit-line-length-1"
                                                                        *ngIf="col.data_type === 'FILE'">
                                                                        {{customField?.data?.file_name || 'NA'}}
                                                                    </span>
                                                                    <span class="limit-line-length-1"
                                                                        *ngIf="col.data_type !== 'PHONE_NUMBER' && col.data_type !== 'DATE' && col.data_type !== 'DATE_TIME' && col.data_type !== 'DATE_RANGE' && col.data_type !== 'FILE'">
                                                                        {{customField.data || 'NA'}}
                                                                    </span>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                    </td>
                                                </ng-container>
                                                <td (click)="$event.stopPropagation()"
                                                    class="mat-hint-none mat-customised-sec">
                                                    <mat-form-field appearance="outline" class="w-75">
                                                        <mat-select placeholder='Select' [(ngModel)]="item.type"
                                                            [disabled]="!item?.is_contact_active">
                                                            <mat-option (click)="moveLeadTo(item,'ACTIVE')"
                                                                value="ACTIVE" *ngIf="item.type !== 'ACTIVE'">Move to
                                                                Open</mat-option>
                                                            <mat-option (click)="moveLeadTo(item,'LOST')" value="LOST"
                                                                *ngIf="item.type !== 'LOST'">Move to Lost</mat-option>
                                                            <mat-option (click)="moveLeadTo(item,'WON')" value="WON"
                                                                *ngIf="item.type !== 'WON'">Move to Won</mat-option>
                                                            <mat-option (click)="moveLeadTo(item,'JUNK')" value="JUNK"
                                                                *ngIf="item.type !== 'JUNK'">Move to Junk</mat-option>
                                                            <mat-option (click)="moveLeadTo(item,'PIPELINE')"
                                                                value="PIPELINE" *ngIf="item.type === 'ACTIVE'">Move to
                                                                Flow</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- later appointments tab should be there for enquires only -->
                <!-- <li [ngbNavItem]="8" *ngIf="selected_type === 'lead'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Appointments</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem overflow-auto">
                            <app-appointment></app-appointment>
                        </div>
                    </ng-template>
                </li> -->
                <li [ngbNavItem]="8"
                    *ngIf="selected_type === 'company' && contact && getRolePermissions('contacts','can_read') && featuresData?.contact"
                    class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Contacts</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div>
                            <app-contacts [companyId]="selected_id" [isInnerScreen]="true"></app-contacts>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="9" *ngIf="conversations && (selected_type === 'lead' || selected_type === 'deal')"
                    class="me-2 conv">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Conversations</span>
                        <label class="avatar-xsss d-flex justify-content-between chat-count z-index-max"
                            *ngIf="unread_count !== 0">
                            <span
                                class="avatar-title rounded-circle text-uppercase font-size-9 bg-count">{{unread_count}}</span>
                        </label>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem">
                            <!-- <div class="p-3"> -->
                            <app-client-conversations></app-client-conversations>
                            <!-- </div> -->
                            <!-- <app-conversations></app-conversations> -->
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="10" *ngIf="emails && (selected_type === 'lead' || selected_type === 'deal')"
                    class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Emails</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem">
                            <div class="p-3">
                                <app-conversations></app-conversations>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="12"
                    *ngIf="selected_type === 'contact' && getRolePermissions('contact-voice-calls','can_read') && selectedObjectTypeData?.calls"
                    class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Calls</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem">
                            <div class="p-3">
                                <div class="table-responsive table-height fixed-table">
                                    <table class="table align-middle table-nowrap table-hover mb-0 w-100 call-table">
                                        <thead>
                                            <tr class="border-top-table">
                                                <ng-container *ngFor="let col of tableHeader">
                                                    <th scope="col"
                                                        class="text-primary font-size-13 pb-3 table-sticky-header bg-white"
                                                        style="z-index: 999;">{{col}}</th>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="cursor" *ngFor="let item of callDetails; let i = index">
                                                <td>
                                                    {{ item.callType }}
                                                </td>
                                                <td>
                                                    {{ item.callDuration }}
                                                </td>
                                                <td>
                                                    {{ item.purpose }}
                                                </td>
                                                <td>{{ item.disposition }}</td>
                                                <td>
                                                    <span class="text-ellipsis">
                                                        {{ item.note }}
                                                    </span>
                                                </td>
                                                <td>
                                                    {{item.experience }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="13" *ngIf="selected_type === 'contact' && selectedObjectTypeData?.orders" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Orders</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem">
                            <div class="p-3">
                                <div class="table-responsive table-height fixed-table">
                                    <table class="table align-middle table-nowrap table-hover mb-0 w-100 call-table">
                                        <thead>
                                            <tr class="border-top-table">
                                                <ng-container *ngFor="let col of tableHeader">
                                                    <th scope="col"
                                                        class="text-primary font-size-13 pb-3 table-sticky-header bg-white"
                                                        style="z-index: 999;">{{col}}</th>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="cursor" *ngFor="let item of orderList; let i = index"
                                                [routerLink]="['/home/orders/home-management']"
                                                [queryParams]="{show_type: 'order',id: item.id }">
                                                <td>{{ item.order_id }}</td>
                                                <td>{{ item.total_quantity }}</td>
                                                <td>
                                                    <label
                                                        class="px-2 py-1 bg-high border-radius-16  fw-600 font-size-12 width-fit-content text-capitalize limit-line-length-1"
                                                        style="letter-spacing: 0px;"
                                                        [ngClass]="item.order_status === 'REJECTED' ? 'bg-high': item.order_status === 'ACCEPTED' ? 'bg-medium' : item.order_status === 'PENDING' ? 'bg-low' : 'bg-refunded'">
                                                        <span class="limit-line-length-1">
                                                            {{item.order_status}}
                                                        </span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <span class="limit-line-length-1">
                                                        {{item?.source_type?.split('_')?.join(' ')}}
                                                    </span>
                                                </td>
                                                <td>{{ item?.created_at | dateConversion}} {{item?.created_at | date:
                                                    'h:mm a'}}</td>
                                                <td>{{ item?.final_price ? (item?.final_price | currencyConversion :
                                                    item.currency_symbol) : '-' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="14" *ngIf="selected_type === 'contact' && selectedObjectTypeData?.schedulers" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Schedulers</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem">
                            <div class="p-3">
                                <div class="table-responsive table-height fixed-table">
                                    <table class="table align-middle table-nowrap table-hover mb-0 w-100 call-table">
                                        <thead>
                                            <tr class="border-top-table">
                                                <ng-container *ngFor="let col of tableHeader">
                                                    <th scope="col"
                                                        class="text-primary font-size-13 pb-3 table-sticky-header bg-white"
                                                        style="z-index: 999;">{{col}}</th>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="cursor" *ngFor="let item of aptList; let i = index"
                                                routerLink="/home/orders/home-management"
                                                [queryParams]="{ show_type: 'appointment',id:item.id }">
                                                <td style="min-width: 100px;max-width: 200px;">
                                                    <span class="limit-line-length-1">
                                                        {{item.display_booking_id}}
                                                    </span>
                                                </td>
                                                <td style="min-width: 100px;max-width: 200px;">
                                                    <span class="limit-line-length-1">{{ item.consultation_id.name
                                                        }}</span>
                                                </td>
                                                <td style="min-width: 150px;max-width: 200px;">
                                                    <div class="d-flex align-items-center limit-line-length-1">
                                                        <mat-icon
                                                            class="d-inline-flex align-items-center w-20 font-size-9 me-1"
                                                            [ngClass]="item.meeting_type === 'ON_LINE' || item.meeting_type === 'DOOR_STEP' ? 'dot-approved' : item.meeting_type === 'OFF_LINE' ? 'dot-pending' : 'dot-rejected'"
                                                            matPrefix>circle</mat-icon>
                                                        <label class="">{{ item.meeting_type === 'ON_LINE' ? 'Online' :
                                                            item.meeting_type ===
                                                            'OFF_LINE' ? 'Offline' : 'DoorStep' }}</label>
                                                    </div>
                                                </td>
                                                <td> <span class="limit-line-length-1">{{ item.source_type ?
                                                        item.source_type.replace("_", " "):
                                                        "NA" }}</span></td>
                                                <td style="min-width: 150px;max-width: 200px;"> <span
                                                        class="limit-line-length-1">{{
                                                        item.info?.staff?.staffName ?
                                                        item.info?.staff?.staffName: "NA" }}</span></td>
                                                <td> <span class="limit-line-length-1">{{ item.created_at |
                                                        dateConversion}}
                                                        {{item?.created_at | date: 'h:mm a'}}</span></td>
                                                <td
                                                    [ngClass]="{'text-danger':item?.due_expired,'text-green':item?.today_due}">
                                                    <span class="limit-line-length-1">{{
                                                        item?.staff_timezone_date ? (item?.staff_timezone_date |
                                                        dateConversion) : '' }}</span>
                                                </td>
                                                <td style="min-width: 200px;max-width: 250px;"> <span
                                                        class="limit-line-length-1">{{
                                                        item.staff_timezone_time }}&nbsp; ({{ item?.time_zone}})</span>
                                                </td>
                                                <td> <span class="limit-line-length-1">{{item.payment_mode}}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="15" *ngIf="selected_type === 'contact' && selectedObjectTypeData?.flow_cards" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Flow Cards</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="" style="overflow: scroll;height: 35rem;">
                            <div class="p-3" *ngFor="let pipeline of pipelineCardsList | keyvalue; let index=index">
                                <mat-accordion class="contact-accord" [multi]="false">
                                    <mat-expansion-panel [expanded]="step === index"
                                        (opened)="setStep(index);getColoumsList(pipeline.value[0]?.pipeline)">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div class="w-100 d-flex align-items-center justify-content-between">
                                                    <p class="fw-600 text-primary mb-1">
                                                        {{pipeline.key}}</p>
                                                </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <ng-container>
                                            <div class="table-responsive table-height fixed-table">
                                                <table
                                                    class="table align-middle table-nowrap table-hover mb-0 w-100 call-table">
                                                    <thead>
                                                        <tr class="border-top-table">
                                                            <ng-container *ngFor="let col of originalTableColumnLists">
                                                                <th scope="col"
                                                                    *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                                                                    class="text-primary font-size-13 pb-3 table-sticky-header bg-white text-capitalize"
                                                                    style="z-index: 999;">
                                                                    {{col.field_name!.split("_").join(" ")}}</th>
                                                            </ng-container>
                                                        </tr>
                                                    </thead>
                                                    <tbody
                                                        *ngIf="pipeline.value && pipeline.value?.length > 0; else noDataTemplate">
                                                        <tr class="cursor" [routerLink]="['../crm-details']" (click)="activeId = 1"
                                                          [queryParams]="{ id: item.id, showtype: 'deal', pipeline_id: item.pipeline,contact_id: item.contact,contact_active: item?.is_contact_active,is_new: item?.is_new }"
                                                            *ngFor="let item of pipeline.value; let i = index">
                                                            <ng-container *ngFor="let col of originalTableColumnLists">
                                                                <td *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                                                                    style="max-width: 200px;"
                                                                    [ngStyle]="{'min-width': item[col.field_name] && item[col.field_name].length < 10 ? '110px' : !item[col.field_name] ? '100px' : '150px'}">
                                                                    <div *ngIf="col.system_field">
                                                                        <span
                                                                            *ngIf="col.field_name === 'contact_phone_number'"
                                                                            class="d-flex">{{item[col.field_name]
                                                                            ?
                                                                            item.contact_ccode :
                                                                            ''}}&nbsp;{{item[col.field_name] || 'NA'}}
                                                                        </span>
                                                                        <span class="limit-line-length-1"
                                                                            *ngIf="col.field_name === 'display_id'">{{(item?.card_id
                                                                            ||
                                                                            '-')}}</span>
                                                                        <span class="limit-line-length-1"
                                                                            *ngIf="col.field_name === 'owner'">{{(item?.owner_info?.fname
                                                                            || '-') + ' ' +
                                                                            (item?.owner_info?.lname || '')}}</span>
                                                                        <span class="limit-line-length-1"
                                                                            *ngIf="col.field_name === 'stage'">{{(item?.stage_name
                                                                            || '-')}}</span>
                                                                        <span class="limit-line-length-1"
                                                                            *ngIf="col.data_type === 'DATE'">
                                                                            {{item.created_at ? (item.created_at |
                                                                            dateConversion : 'dateTime') : 'NA'}}
                                                                        </span>
                                                                        <span class="limit-line-length-1"
                                                                            *ngIf="col.data_type === 'DATE_TIME'">
                                                                            {{item.created_at
                                                                            | dateConversion }} {{ item.created_at |
                                                                            date:
                                                                            'h:mm a' || 'NA'}}
                                                                        </span>
                                                                        <a class="limit-line-length-1" target="_blank"
                                                                            *ngIf="col.data_type === 'WEBSITE' && item[col.field_name]"
                                                                            [href]="item[col.field_name]"
                                                                            (click)="$event.stopPropagation()">
                                                                            {{item[col.field_name]}}
                                                                        </a>
                                                                        <span class="limit-line-length-1"
                                                                            *ngIf="col.data_type === 'WEBSITE' && !item[col.field_name]">NA</span>
                                                                        <div *ngIf="col.field_name === 'tags'"
                                                                            class="d-flex">
                                                                            <p class="text-name m-0"
                                                                                *ngIf="!item['tag_name']"
                                                                                style="width: -webkit-fill-available;">
                                                                                NA
                                                                            </p>
                                                                            <p class="text-name text-wrap w-100 cursor"
                                                                                style="margin-bottom: 0px !important;">
                                                                                {{item?.tag_name}}
                                                                            </p>
                                                                        </div>
                                                                        <div *ngIf="col.field_name === 'channel'">
                                                                            <p *ngIf="item['channel']"
                                                                                style="background-color: #FCEED8;color: #73321B;"
                                                                                class="text-name m-0 d-inline-flex align-items-center px-2 border-radius-50">
                                                                                <span
                                                                                    class="border-radius-50 m-0 tag-color me-1"
                                                                                    style="height: 2px; padding: 3px;"
                                                                                    [style]="{'background-color': '#73321B'}"></span>
                                                                                <span
                                                                                    class="limit-line-length-1 word-break">
                                                                                    {{item['channel']}}</span>
                                                                            </p>
                                                                            <p class="text-name m-0"
                                                                                *ngIf="!item['channel']">NA</p>
                                                                        </div>
                                                                        <span class="limit-line-length-1"
                                                                            *ngIf="col.field_name !== 'contact_phone_number' && col.field_name !== 'ccode'&& col.field_name !== 'display_id' && col.field_name !== 'tags' && col.field_name !== 'channel' && col.data_type !== 'DATE_TIME' && col.data_type !== 'DATE' && col.field_name !== 'owner' && col.field_name !== 'stage' && col.data_type !== 'WEBSITE'"
                                                                            [innerHTML]="item[col.field_name] || 'NA'"></span>

                                                                    </div>
                                                                    <div *ngIf="!col.system_field">
                                                                        <ng-container
                                                                            *ngFor="let customField of item?.custom_card_fields">
                                                                            <ng-container
                                                                                *ngIf="customField.label === col.field_name">
                                                                                <span class="limit-line-length-1"
                                                                                    *ngIf="col.data_type === 'DATE'">
                                                                                    {{customField.data ?
                                                                                    (customField.data |
                                                                                    dateConversion) : 'NA'}}
                                                                                </span>
                                                                                <span class="limit-line-length-1"
                                                                                    *ngIf="col.data_type === 'DATE_TIME'">
                                                                                    {{customField.data
                                                                                    | dateConversion }} {{
                                                                                    customField.data
                                                                                    | date: 'h:mm a' || 'NA'}}
                                                                                </span>
                                                                                <span
                                                                                    *ngIf="col.data_type === 'DATE_RANGE'">
                                                                                    <span class="limit-line-length-1"
                                                                                        *ngIf="customField.data && customField.data.split('&').length > 1">
                                                                                        {{customField?.data.split("&")[0].split("-").reverse().join("/")
                                                                                        + '
                                                                                        - ' +
                                                                                        customField?.data.split("&")[1].split("-").reverse().join("/")}}
                                                                                    </span>
                                                                                    <span *ngIf="!customField.data">
                                                                                        NA
                                                                                    </span>
                                                                                </span>
                                                                                <span class="limit-line-length-1"
                                                                                    *ngIf="col.data_type === 'PHONE_NUMBER'">
                                                                                    {{customField?.data?.custom_ccode ?
                                                                                    customField?.data?.custom_ccode :
                                                                                    ''}}&nbsp;{{customField?.data?.custom_phone_number
                                                                                    || 'NA'}}
                                                                                </span>
                                                                                <span class="limit-line-length-1"
                                                                                    *ngIf="col.data_type === 'FILE'">
                                                                                    {{customField?.data?.file_name ||
                                                                                    'NA'}}
                                                                                </span>
                                                                                <a class="limit-line-length-1"
                                                                                    target="_blank"
                                                                                    *ngIf="col.data_type === 'WEBSITE' && customField.data"
                                                                                    [href]="customField.data"
                                                                                    (click)="$event.stopPropagation()">
                                                                                    {{customField.data}}
                                                                                </a>
                                                                                <span class="limit-line-length-1"
                                                                                    *ngIf="col.data_type === 'WEBSITE' && !customField.data">NA</span>
                                                                                <span class="limit-line-length-1"
                                                                                    *ngIf="col.data_type !== 'PHONE_NUMBER' && col.data_type !== 'DATE' && col.data_type !== 'DATE_TIME' && col.data_type !== 'WEBSITE' && col.data_type !== 'DATE_RANGE' && col.data_type !== 'FILE'"
                                                                                    [innerHTML]="customField.data || 'NA'">
                                                                                </span>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                        </tr>
                                                    </tbody>
                                                    <ng-template #noDataTemplate>
                                                        <tr>
                                                            <td colspan="6" class="text-center">No data available</td>
                                                        </tr>
                                                    </ng-template>
                                                </table>

                                            </div>
                                        </ng-container>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="16" *ngIf="selected_type === 'contact' && selectedObjectTypeData?.meetings" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Meetings</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem">
                            <div class="p-3">
                                <div class="table-responsive table-height fixed-table">
                                    <table class="table align-middle table-nowrap table-hover mb-0 w-100 call-table">
                                        <thead>
                                            <tr class="border-top-table">
                                                <ng-container *ngFor="let col of tableHeader">
                                                    <th scope="col"
                                                        class="text-primary font-size-13 pb-3 table-sticky-header bg-white"
                                                        style="z-index: 999;">{{col}}</th>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="cursor" *ngFor="let item of calendarBookingsList; let i = index"
                                                [routerLink]="['/home/calendar-bookings/home-management']"
                                                [queryParams]="{show_type: 'calendar',id: item.id }">
                                                <td>{{item.booking_id || 'NA'}}</td>
                                                <td class="home-text-color" style="min-width: 100px;max-width: 200px;">
                                                    <span class="limit-line-length-1">
                                                        {{ item?.booking_name }}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="limit-line-length-1"
                                                        style="min-width: 100px;max-width: 200px;">
                                                        {{item?.staff_name ? item?.staff_name : 'NA'}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="limit-line-length-1"
                                                        [ngClass]="{'text-danger':item?.due_expired,'text-green':!item?.due_expired}">
                                                        {{ item?.startStr | dateConversion }}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="limit-line-length-1">
                                                        {{ item?.start_time}} &nbsp; ({{ item?.staff_timezone}})
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="limit-line-length-1">
                                                        {{item?.created_at | dateConversion }} {{item?.created_at |
                                                        date: 'h:mm a'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- <li [ngbNavItem]="10">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Tickets</span>
                    </a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li> -->
                <!-- <li [ngbNavItem]="11" *ngIf="selected_type === 'lead' || selected_type === 'deal'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-13">Email Drafts</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem">
                            <app-email-draft></app-email-draft>
                        </div>
                    </ng-template>
                </li> -->
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
</div>
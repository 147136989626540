<div class="container-fluid ps-3 ms-2 flex-shrink home-management newUI">
  <div class="d-flex w-100 pt-3">
    <div class="col-md-4 p-0"
      *ngIf="!(show_type === 'appointment' || show_type === 'order' || show_type === 'calendar')">
      <div class="bg-crm-info h-100">
        <div class="container-fluid p-0 pe-3 h-100">
          <div class="overflow-auto">
            <mat-accordion>
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div>
                        <p style="color: #232323;" class="font-size-18 fw-600 m-0 mb-1">Associated With</p>
                        <h6 class="fw-500 font-size-13 mb-0 text-grey" *ngIf="taskDetails?.source_type === 'lead'">
                          Lead</h6>
                        <h6 class="fw-500 font-size-13 mb-0 text-grey" *ngIf="taskDetails?.source_type === 'contact'">
                          Contact
                        </h6>
                        <h6 class="fw-500 font-size-13 mb-0 text-grey" *ngIf="taskDetails?.source_type === 'company'">
                          Company
                        </h6>
                        <h6 class="fw-500 font-size-13 mb-0 text-grey" *ngIf="taskDetails?.source_type === 'project'">
                          Client
                          Project</h6>
                        <h6 class="fw-500 font-size-13 mb-0 text-grey" *ngIf="taskDetails?.source_type === 'deal'">
                          Flow
                        </h6>
                        <h6 class="fw-500 font-size-13 mb-0 text-grey" *ngIf="taskDetails?.source_type === 'board'">
                          Board</h6>
                      </div>
                      <div class="d-flex align-items-center me-3" *ngIf="validateUserAccess">
                        <ng-container *ngIf="taskDetails?.source_type !== 'board'">
                          <img src="assets/images/edit_pen.svg"
                            (click)="openModalDialog($event,taskDetails?.source_type)" alt="">
                          <!-- <mat-icon class="mb-1 font-size-18 ms-3">expand_more</mat-icon> -->
                        </ng-container>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row no-gutters mt-2 auto-hide-scroll-bar" *ngIf="taskDetails?.source_type === 'lead'">
                  <div class="no-gutters bottom-border p-3 d-flex">
                    <div class="info-accord col-4">
                      <p class="text-inner-info m-0">Title </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-8">
                      <div class="text-name d-flex text-wrap w-100">
                        <span class="pe-1 fw-600">:</span>
                        <p class="text-name text-ellipsis cursor m-0"
                          (click)="onNavigateToInnerScreen('lead',relatedSourceDetails.id)">
                          {{relatedSourceDetails?.requirement_title ?
                          relatedSourceDetails?.requirement_title : "NA"}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3 cursor d-flex">
                    <div class="info-accord col-4">
                      <p class="text-inner-info m-0">Name </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-8" [ngClass]="{'cursor':validateUserAccess}">
                      <div class="text-name d-flex text-wrap w-100">
                        <span class="pe-1 fw-600">:</span>
                        <p class="text-name text-ellipsis cursor m-0"
                          (click)="validateUserAccess && onNavigateToInnerScreen('contact',relatedSourceDetails.id)">
                          {{relatedSourceDetails?.name ?
                          relatedSourceDetails?.name : "NA"}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Email',titleValue:relatedSourceDetails?.email}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Phone',titleValue:relatedSourceDetails?.phone_number ? relatedSourceDetails?.ccode + ' ' + relatedSourceDetails?.phone_number :'' }"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Tag',titleValue:relatedSourceDetails?.tags,color:relatedSourceDetails?.colour}"></ng-container>
                  <ng-container *ngTemplateOutlet="list_view;context:{title:'Channel',titleValue:relatedSourceDetails?.channel ?
                      (relatedSourceDetails?.channel.replace('_', ' ') | titlecase) : ''}"></ng-container>
                  <ng-container *ngTemplateOutlet="list_view;context:{title:'Owner',titleValue:relatedSourceDetails?.owner_info
                      ?
                      relatedSourceDetails?.owner_info?.fname + ' ' +
                      relatedSourceDetails?.owner_info?.lname : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Assignee',titleValue:assigneeMembers}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Due Date',titleValue:relatedSourceDetails?.due_date ? (relatedSourceDetails?.due_date | dateConversion) : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Created By',titleValue:relatedSourceDetails?.created_by_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Created on',titleValue:relatedSourceDetails?.created_at ? (relatedSourceDetails?.created_at | dateConversion)+ ' ' +(relatedSourceDetails?.created_at | date: 'h:mm a') : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Updated By',titleValue:relatedSourceDetails?.updated_by_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Updated on',titleValue:relatedSourceDetails?.updated_at ? (relatedSourceDetails?.updated_at | dateConversion)+ ' ' +(relatedSourceDetails?.updated_at | date: 'h:mm a') : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Description',titleValue:relatedSourceDetails?.description}"></ng-container>
                </div>

                <div class="row no-gutters mt-2 auto-hide-scroll-bar" *ngIf="taskDetails?.source_type === 'contact'">
                  <div class="no-gutters bottom-border p-3 d-flex">
                    <div class="info-accord col-4">
                      <p class="text-inner-info m-0">First name </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-8" [ngClass]="{'cursor':validateUserAccess}">
                      <div class="text-name d-flex text-wrap w-100">
                        <span class="pe-1 fw-600">:</span>
                        <p class="text-name text-ellipsis cursor m-0"
                          (click)="validateUserAccess && onNavigateToInnerScreen('contact',relatedSourceDetails.id)">
                          {{relatedSourceDetails?.first_name ?
                          relatedSourceDetails.first_name : "NA"}}</p>
                      </div>
                    </div>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Last name',titleValue:relatedSourceDetails?.last_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Email',titleValue:relatedSourceDetails?.email}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Phone',titleValue:relatedSourceDetails?.phone_number ? relatedSourceDetails?.ccode + ' ' + relatedSourceDetails?.phone_number :'' }"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Tag',titleValue:relatedSourceDetails?.tags,color:relatedSourceDetails?.colour}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Channel',titleValue:relatedSourceDetails?.channel ? (relatedSourceDetails?.channel.replace('_', ' ') | titlecase) : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Owner',titleValue:relatedSourceDetails?.owner_info ? relatedSourceDetails?.owner_info?.fname + ' ' + relatedSourceDetails?.owner_info?.lname : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Assignee',titleValue:assigneeMembers}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Created By',titleValue:relatedSourceDetails?.created_by_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Created on',titleValue:relatedSourceDetails?.created_at ? (relatedSourceDetails?.created_at | dateConversion)+ ' ' +(relatedSourceDetails?.created_at | date: 'h:mm a') : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Updated By',titleValue:relatedSourceDetails?.updated_by_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Updated on',titleValue:relatedSourceDetails?.updated_at ? (relatedSourceDetails?.updated_at | dateConversion)+ ' ' +(relatedSourceDetails?.updated_at | date: 'h:mm a') : ''}"></ng-container>
                </div>

                <div class="row no-gutters mt-2 auto-hide-scroll-bar" *ngIf="taskDetails?.source_type === 'company'">
                  <div class="no-gutters bottom-border p-3 d-flex">
                    <div class="info-accord col-4">
                      <p class="text-inner-info m-0">Name </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-8" [ngClass]="{'cursor':validateUserAccess}">
                      <div class="text-name d-flex text-wrap w-100">
                        <span class="pe-1 fw-600">:</span>
                        <p class="text-name text-ellipsis cursor m-0"
                          (click)="validateUserAccess && onNavigateToInnerScreen('company',relatedSourceDetails.id)">
                          {{relatedSourceDetails?.name ?
                          relatedSourceDetails?.name : "NA"}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Email',titleValue:relatedSourceDetails?.email}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Phone',titleValue:relatedSourceDetails?.phone_number ? relatedSourceDetails?.ccode + ' ' + relatedSourceDetails?.phone_number :'' }"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Tag',titleValue:relatedSourceDetails?.tags,color:relatedSourceDetails?.colour}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Channel',titleValue:relatedSourceDetails?.channel ? (relatedSourceDetails?.channel.replace('_', ' ') | titlecase) : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Owner',titleValue:relatedSourceDetails?.owner_info ? relatedSourceDetails?.owner_info?.fname + ' ' + relatedSourceDetails?.owner_info?.lname : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Assignee',titleValue:assigneeMembers}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Website',titleValue:relatedSourceDetails?.website}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Address',titleValue:relatedSourceDetails?.address}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'City',titleValue:relatedSourceDetails?.city}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Zipcode',titleValue:relatedSourceDetails?.zipcode}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Country',titleValue:relatedSourceDetails?.country}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Created By',titleValue:relatedSourceDetails?.created_by_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Created on',titleValue:relatedSourceDetails?.created_at ? (relatedSourceDetails?.created_at | dateConversion)+ ' ' +(relatedSourceDetails?.created_at | date: 'h:mm a') : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Updated By',titleValue:relatedSourceDetails?.updated_by_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Updated on',titleValue:relatedSourceDetails?.updated_at ? (relatedSourceDetails?.updated_at | dateConversion)+ ' ' +(relatedSourceDetails?.updated_at | date: 'h:mm a') : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Description',titleValue:relatedSourceDetails?.description}"></ng-container>
                </div>

                <div class="row no-gutters mt-2 auto-hide-scroll-bar" *ngIf="taskDetails?.source_type === 'project'">
                  <div class="no-gutters bottom-border p-3 d-flex">
                    <div class="info-accord col-4">
                      <p class="text-inner-info m-0">Project Name </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-8" [ngClass]="{'cursor':validateUserAccess}">
                      <div class="text-name d-flex text-wrap w-100">
                        <span class="pe-1 fw-600">:</span>
                        <p class="text-name text-ellipsis cursor m-0"
                          (click)="validateUserAccess && onNavigateToInnerScreen('project',relatedSourceDetails.id)">
                          {{relatedSourceDetails?.name ?
                          relatedSourceDetails?.name : "NA"}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Name',titleValue:relatedSourceDetails?.client_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Email',titleValue:relatedSourceDetails?.email}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Phone',titleValue:relatedSourceDetails?.phone_number ? relatedSourceDetails?.ccode + ' ' + relatedSourceDetails?.phone_number :'' }"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Status',titleValue:relatedSourceDetails?.project_status}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Budget',titleValue:relatedSourceDetails?.cost}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Tag',titleValue:relatedSourceDetails?.tags,color:relatedSourceDetails?.colour}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Channel',titleValue:relatedSourceDetails?.channel ? (relatedSourceDetails?.channel.replace('_', ' ') | titlecase) : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Owner',titleValue:relatedSourceDetails?.owner_info ? relatedSourceDetails?.owner_info?.fname + ' ' + relatedSourceDetails?.owner_info?.lname : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Assignee',titleValue:assigneeMembers}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Due Date',titleValue:relatedSourceDetails?.due_date ? (relatedSourceDetails?.due_date | dateConversion) : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Created By',titleValue:relatedSourceDetails?.created_by_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Created on',titleValue:relatedSourceDetails?.created_at ? (relatedSourceDetails?.created_at | dateConversion)+ ' ' +(relatedSourceDetails?.created_at | date: 'h:mm a') : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Updated By',titleValue:relatedSourceDetails?.updated_by_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Updated on',titleValue:relatedSourceDetails?.updated_at ? (relatedSourceDetails?.updated_at | dateConversion)+ ' ' +(relatedSourceDetails?.updated_at | date: 'h:mm a') : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Description',titleValue:relatedSourceDetails?.description}"></ng-container>
                </div>

                <div class="row no-gutters mt-2 cursor auto-hide-scroll-bar" *ngIf="taskDetails?.source_type === 'deal'">
                  <div class="no-gutters bottom-border p-3 cursor d-flex">
                    <div class="info-accord col-4">
                      <p class="text-inner-info m-0">Flow</p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-8">
                      <div class="text-name d-flex text-wrap w-100">
                        <span class="pe-1 fw-600">:</span>
                        <p class="text-name text-ellipsis cursor m-0"
                          (click)="onNavigateToInnerScreen('deal',cardFields?.id,cardFields?.pipeline,cardFields?.contact)">
                          {{cardFields?.pipeline_name ?
                          cardFields?.pipeline_name : "NA"}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Flow Title',titleValue:cardFields?.title}"></ng-container>
                  <ng-container *ngTemplateOutlet="list_view;context:{title:'Name',titleValue:cardFields?.contact_first_name + ' ' +
                        cardFields?.contact_last_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Email',titleValue:cardFields?.contact_email}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Phone',titleValue:cardFields?.phone_number ? cardFields?.contact_ccode + ' ' + cardFields?.phone_number :'' }"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Tag',titleValue:cardFields?.tag_name,color:cardFields?.tag_colour}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Channel',titleValue:cardFields?.channel ? (cardFields?.channel.replace('_', ' ') | titlecase) : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Owner',titleValue:cardFields?.owner_info ? cardFields?.owner_info?.fname + ' ' + relatedSourceDetails?.owner_info?.lname : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Assignee',titleValue:assigneeMembers}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Due Date',titleValue:cardFields?.due_date ? (cardFields?.due_date | dateConversion) : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Created By',titleValue:cardFields?.created_by_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Created on',titleValue:cardFields?.created_at ? (cardFields?.created_at | dateConversion)+ ' ' +(cardFields?.created_at | date: 'h:mm a') : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Updated By',titleValue:cardFields?.updated_by_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Updated on',titleValue:cardFields?.updated_at ? (cardFields?.updated_at | dateConversion)+ ' ' +(cardFields?.updated_at | date: 'h:mm a') : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Description',titleValue:cardFields?.description}"></ng-container>
                </div>

                <div class="row no-gutters mt-2 auto-hide-scroll-bar" style="height: fit-content;" *ngIf="taskDetails?.source_type === 'board'">
                  <div class="no-gutters bottom-border p-3 cursor d-flex">
                    <div class="info-accord col-4">
                      <p class="text-inner-info m-0">Name</p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-8">
                      <div class="text-name d-flex text-wrap w-100">
                        <span class="pe-1 fw-600">:</span>
                        <p class="text-name text-ellipsis cursor m-0"
                          (click)="onNavigateToInnerScreen('board',taskDetails?.source_id)">{{boardDetails?.name ?
                          boardDetails?.name : "NA"}}</p>
                      </div>
                    </div>
                  </div>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Created By',titleValue:boardDetails?.created_by_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Created on',titleValue:boardDetails?.created_at ? (boardDetails?.created_at | dateConversion)+ ' ' +(boardDetails?.created_at | date: 'h:mm a') : ''}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Updated By',titleValue:boardDetails?.updated_by_name}"></ng-container>
                  <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Updated on',titleValue:boardDetails?.updated_at ? (boardDetails?.updated_at | dateConversion)+ ' ' +(boardDetails?.updated_at | date: 'h:mm a') : ''}"></ng-container>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
    <div class="auto-hide-scroll-bar ms-1"
      [ngClass]="{'task-details-screen':show_type !== 'appointment' && show_type !== 'order' && show_type !== 'calendar','col-12' : (show_type === 'appointment' || show_type === 'order' || show_type === 'calendar'),'col-md-8' : !(show_type === 'appointment' || show_type === 'order' || show_type === 'calendar')} ">
      <app-task-detail [taskId]="id" [showType]="show_type"
        *ngIf="show_type !== 'appointment' && show_type !== 'order' && show_type !== 'calendar'"></app-task-detail>
      <app-bookedappointment-detail [appointmentId]="id"
        *ngIf="show_type === 'appointment'"></app-bookedappointment-detail>
      <app-order-detail *ngIf="show_type === 'order'"></app-order-detail>
      <app-booked-calendar-details *ngIf="show_type === 'calendar'"></app-booked-calendar-details>

      <!-- <app-followup-detail [taskId]="id" *ngIf="show_type === 'followup'"></app-followup-detail> -->
      <!-- <app-call-detail *ngIf="show_type === 'calls'"></app-call-detail>
            <app-bookedappointment-detail *ngIf="show_type === 'appointment'"></app-bookedappointment-detail>
            <app-meeting-detail  *ngIf="show_type === 'meeting'"></app-meeting-detail>
            <app-enquire-details *ngIf="show_type === 'enquire'"></app-enquire-details> -->
    </div>
  </div>
</div>
<ng-template #list_view let-title="title" let-value="titleValue" let-color="color">
  <div class="no-gutters bottom-border list-view-sec p-3 d-flex">
    <div class="info-accord col-4">
      <p class="text-inner-info m-0 limit-line-length-1">{{title}} </p>
    </div>
    <!-- <div class="col-md-1"></div> -->
    <div class="info-accord d-flex col-8">
      <div class="text-name d-flex text-wrap w-100">
        <span class="pe-1 fw-600">:</span>
        <div *ngIf="title !== 'Tag' && title !== 'Assignee' && title !=='Description'">
          <p class="text-name text-ellipsis m-0">
            {{value || "NA"}}
          </p>
        </div>
        <div *ngIf="title === 'Tag'">
          <p *ngIf="value" class="text-name m-0 bg-tag d-inline-flex align-items-center px-1 border-radius-50">
            <span class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;"
              [style]="{'background-color': color}"></span>
            {{value}}
          </p>
          <p class="text-name m-0" *ngIf="!value">NA</p>
        </div>
        <div *ngIf="title === 'Assignee'">
          <p class="text-name m-0 truncate-text-lines" *ngIf="value.length > 0">
            <span *ngFor="let assignee of value">{{assignee.fname}}
              {{assignee.lname}}, </span>
          </p>
          <p class="text-name m-0" *ngIf="value.length === 0">NA</p>
        </div>
        <div *ngIf="title === 'Description'" style="word-wrap: break-word">
          <p class="text-name truncate-text-lines" [innerHTML]="value ? value : 'NA'"></p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent {
  transaction_details: any;
  table_cols = ["Display Id", "Payed throw", "Amount", "Created At", "Action"];
  page_size = 20;
  currentPage = 1;
  totalRecords = 0;

  constructor(private service: SettingsService) {
    this.getTransactionDetails();
  }

  getTransactionDetails() {
    this.service.getTransactionHistory(this.currentPage).subscribe((resp: any) => {
      this.totalRecords = resp.total;
      this.transaction_details = resp.data;
    });
  }

  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.currentPage = e.pageIndex + 1;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getTransactionDetails();
  }
}

import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { homeJson, MYBOARD } from 'src/app/core/data/homeModule_json';
import { colorCodes, source_color_code } from 'src/app/core/data/colorcode';
import { HomeService } from 'src/app/core/service/home.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { ProfileService } from 'src/app/core/service/profile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeCustomFiltersComponent } from '../shared/home-custom-filters/home-custom-filters.component';
import { filterConstant } from 'src/app/core/data/filter-storing-data';
import { AuthService } from 'src/app/core/service/auth.service';
import { customDateTimeConversion } from 'src/app/core/common/common-functions';
import { ChatService } from 'src/app/core/service/chat.services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  activeId = 1;
  myboardJson = MYBOARD[0];
  activity_logs = [];
  notification_list: any[] = [];
  showType = ['lead', 'project', 'deal', 'company', 'contact'];
  homeTextjson = homeJson[0];
  tabs = [
    {
      id: 1,
      title: 'Tasks',
      route: '/home/task',
      launch_dkl_key: 'home_task',
      role_permission: '',
      queryParams: ''
    },
    // {
    //   id: 2,
    //   title: 'Follow ups',
    //   route: 'follow-up'
    // },
    // {
    //   id: 3,
    //   title: 'Calls',
    //   route: 'calls'
    // },
    // {
    //   id: 4,
    //   title: 'Email',
    //   route: 'email'
    // },
    // {
    //   id: 5,
    //   title: 'Meetings',
    //   route: 'meetings'
    // },
    {
      id: 2,
      title: 'Schedulers',
      route: '/home/appointments',
      launch_dkl_key: 'home_appointments',
      role_permission: 'schedulers-home',
      queryParams: ''
    },
    {
      id: 3,
      title: 'Orders',
      route: '/home/orders',
      launch_dkl_key: 'home_orders',
      role_permission: 'orders-home',
      queryParams: ''
    },
    {
      id: 4,
      title: 'WhatsApp orders',
      route: '/home/whatsapp-orders',
      launch_dkl_key: 'home_orders',
      role_permission: 'orders-home',
      queryParams: ''
    },
    {
      id: 5,
      title: 'WhatsApp tranasctions',
      route: '/home/whatsapp-transcations',
      launch_dkl_key: 'home_orders',
      role_permission: 'orders-home',
      queryParams: ''
    },
    // {
    //   id: 8,
    //   title: 'Enquiries',
    //   route: 'enquire'
    // }
    {
      id: 6,
      title: 'Calendar meetings',
      route: '/home/calendar-bookings',
      launch_dkl_key: 'home_calendar_bookings',
      role_permission: '',
      queryParams: ''
    },
    {
      id: 7,
      title: 'Voice Calls',
      route: '/home/voice-call',
      launch_dkl_key: 'contact',//if contact is exist then voice call is also exit no spl key for this
      queryParams: '',
      role_permission: 'contact-voice-calls'
    }
  ];
  taskCount!: number;
  colorCodes = colorCodes;
  enquireCount!: number;
  activityCount!: number;
  aptCount: any;
  allActivityLists: Array<any> = [];
  paramsData: any;
  search_global: any;
  sourceColorCode: any = source_color_code;
  // headerNotificationsSubscription!: Subscription;
  copy_select_type = [
    {
      label: 'All',
      field_name: 'all',
      field_value: ''
    },
    {
      label: 'Todays',
      field_name: 'today',
      field_value: true
    },
    {
      label: 'Yesterday',
      field_name: 'yesterday',
      field_value: true
    },
    {
      label: 'Last 7 days',
      field_name: 'last_7_days',
      field_value: true
    }
  ];
  select_type: any[] = [];
  filter: any;
  // filter: any = this.select_type[0].value;
  selected_filter!: string;
  selected_filter_value: any;
  notificationsLastPage = 1;
  notificationsCurrentpage = 1;
  isExpanded = true;
  navigationSubscription$!: Subscription;
  showSearch = false;
  filters_modal: any;
  column_filters: any[] = [];
  currentPage = 1;
  selectedSortOptions: any;
  customFiltersArray: any;
  currentActiveTab = 'task';
  featuresData: any;
  homeTabs = 'view_all';
  searchSubject: Subject<string> = new Subject<string>();
  notitficationsCount = 0;
  autoHideNotifications: any;
  constructor(private router: Router, private homeService: HomeService, private chatServices: ChatService, private settingServices: SettingsService, private activatedRoute: ActivatedRoute, private renderer: Renderer2, private cdr: ChangeDetectorRef, private modalService: NgbModal, private profile_service: ProfileService, private sanitizer: DomSanitizer, private authServices: AuthService) {
    this.tabs.forEach((item: any) => {
      this.search_global = '';
      if (this.router.url.includes(item.route)) this.activeId = item.id;
    });
    this.select_type = JSON.parse(JSON.stringify(this.copy_select_type));
    this.getFilterBasesOnUrl(window.location.pathname);
    // this.headerNotificationsSubscription = this.homeService.headerNotificationsSubscription$.subscribe((data:any) => {

    //   // this.featuresData?.notifications && this.getNotificationsCount();
    // });

    this.handlePopState = this.handlePopState.bind(this);
    window.addEventListener('popstate', this.handlePopState);

    this.searchSubject.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      this.filter = this.select_type[0].label;
      this.selected_filter_value = '';
      this.selected_filter = '';
      // this.updateQueryParams();
      if (this.selectedSortOptions || this.column_filters.length > 0) {
        this.selectedSortOptions = null;
        this.column_filters = [];
        const payload = {
          filter: [],
          sort_by: '',
          sort_order: 'desc'
        };
        this.filterFormSubmit(payload);
      }
      this.updateUrlParams(searchTerm);
      this.updateFilterState();
    });
  }

  updateUrlParams(searchTerm: string) {
    let queryParams: any = {};
    const title = this.activatedRoute.snapshot.queryParamMap.get('title');
    const id = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (title && id) {
      queryParams = {
        title: title,
        id: id
      };
    }
    if (searchTerm.length >= 3) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { search: this.search_global, page: 1 }
      });
    }
    else if (searchTerm.length < 3 && searchTerm.length > 0) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { search: this.search_global, page: 1 }
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams
      });
    }
  }

  ngOnInit() {
    if (filterConstant.filterInfoData && filterConstant.filterInfoData.page_name === this.currentActiveTab) {
      this.filter = filterConstant.filterInfoData.dropdown_filter ? filterConstant.filterInfoData.dropdown_filter : this.select_type[0].label;
      if (filterConstant.filterInfoData.searchKey && filterConstant.filterInfoData.searchKey.length >= 3) {
        this.updateUrlParams(filterConstant.filterInfoData.searchKey);
      } else if (filterConstant.filterInfoData.dropdown_filter) {
        setTimeout(() => {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
              f_type: filterConstant.filterInfoData.dropdown_filter,
              f_value: filterConstant.filterInfoData.dropdown_filter_value,
              page: filterConstant.filterInfoData.currentPageIndex
            },
            queryParamsHandling: 'merge' // Preserve other query parameters
          });
        }, 100);
      }
      this.updateCurrentPageFiltrData();
    } else {
      // this.getContactsList('clear', 1);
      this.updateFilterState();
    }
    this.activatedRoute.queryParams.pipe(debounceTime(700)).subscribe((data: any) => {
      if (data && data.id) {
        this.paramsData = data;
      }
      if (data && data?.search) {
        this.search_global = data?.search;
      }
      if (data && data?.f_type) {
        const filteredValue = this.select_type.find((elem: any) => {
          const f_value = data?.f_value === 'true' ? data?.f_value === 'true' : data?.f_value;
          return (elem?.field_name === data?.f_type && elem?.field_value === f_value);
        });
        if (filteredValue) {
          this.filter = filteredValue.label;
        }
      }
    });
    if (!this.filter) {
      this.filter = this.select_type[0].label;
    }
    this.profile_service.firstTabSubject.subscribe({
      next: (resp: any) => {
        this.activeId = 1;
      }
    });

    this.authServices.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      this.featuresData?.notifications && this.getNotification();
      this.getNotificationsCount();
      // this.featuresData?.home_task && this.getTaskCount();
      // this.featuresData?.home_appointments && this.getAppointmentCount();
      // this.featuresData?.home_activity && this.getActivityCount();
      // this.featuresData?.enquiry && this.getEnquireCount();
      this.featuresData?.home_activity && this.getAllActivityLists();
    });
    this.homeService.filterAddedSubscriptions$.subscribe((data: any) => {
      const sorting = {
        sort_by: data.sort_by,
        sort_by_label: data.sort_by_label,
        sort_order: data.sort_order
      };
      const filter = data.filter;
      this.selectedSortOptions = sorting;
      this.column_filters = filter;
    });

    this.chatServices.notificationCount$.subscribe(() => {
      const location = window.location.pathname;
      if (location.split("/")[1] === 'home') {
        this.getNotificationsCount();
      }
    });
  }

  getNotificationsCount() {
    if (this.featuresData?.notifications) {
      this.homeService.getNotificationsCount().subscribe((response: any) => {
        this.notitficationsCount = response.data.unread_count;
        this.homeService.headerNotificationsSubscription$.next({ type: 'nCount', unread_count: this.notitficationsCount });
        this.notitficationsCount > 0 && this.addRemoveNotifications('ADD');
        this.notitficationsCount === 0 && this.addRemoveNotifications('REMOVE');
      });
    }
  }

  async onClearFilters(i: number) {
    if (this.column_filters.length > 0) {
      this.column_filters = this.column_filters.filter((elem: any, index: number) => index !== i);
      this.homeService.filterAddedSubscriptions$.next(await this.updatePayloadOnClearFilters(this.selectedSortOptions, this.column_filters));
    }

  }
  async onClearSortBy() {
    this.selectedSortOptions.sort_by = '';
    this.selectedSortOptions.sort_by_label = '';
    this.homeService.filterAddedSubscriptions$.next(await this.updatePayloadOnClearFilters(this.selectedSortOptions, this.column_filters));
  }

  updatePayloadOnClearFilters(sorting: any, filter: any) {
    return {
      filter: filter,
      sort_by: sorting?.sort_by,
      sort_order: sorting?.sort_order,
      sort_by_label: sorting?.sort_by_label
    };
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.updateWidth();
    this.autoScrollTabs(500);
  }
  onTriggerExpandSearchBox() {
    document.getElementById('home-search-box')?.focus();
    this.showSearch = true;
    this.updateWidth();
  }

  onExpandNotifications(event: any) {
    if (event.target.id === 'mark_as_read_notification') {
      return;
    }
    const notifications_section: any = document.querySelector('.notifications-sections');
    const arrowIcon = document.querySelector(".arrow-icons");
    const markAsSec = document.querySelector(".mark-as-button");
    clearTimeout(this.autoHideNotifications);
    if (notifications_section.classList.contains('expnd')) {
      this.renderer.setStyle(markAsSec, 'display', 'none');
      this.renderer.removeClass(notifications_section, 'expnd');
      this.renderer.setStyle(arrowIcon, 'transform', 'rotate(0deg)');
      this.renderer.setStyle(notifications_section, 'height', '50px');
      this.autoHideNotificationsMethod();
    } else {
      this.renderer.setStyle(markAsSec, 'display', 'flex');
      this.renderer.addClass(notifications_section, 'expnd');
      this.renderer.setStyle(arrowIcon, 'transform', 'rotate(180deg)');
      this.renderer.setStyle(notifications_section, 'height', '69vh');

    }
  }

  addRemoveNotifications(type: string) {
    const notifications_section: any = document.querySelector('.notifications-sections');
    const dummyDiv = document.querySelector('.dummy-hider-div');
    if (type === 'ADD') {
      this.renderer.setStyle(notifications_section, 'display', 'block');
      this.renderer.setStyle(dummyDiv, 'display', 'block');
      clearTimeout(this.autoHideNotifications);
      this.autoHideNotificationsMethod();
      setTimeout(() => {
        this.renderer.setStyle(notifications_section, 'transform', 'translateY(14px)');
      }, 100);
    } else {
      this.renderer.setStyle(notifications_section, 'transform', 'translateY(54px)');
      setTimeout(() => {
        this.renderer.setStyle(notifications_section, 'display', 'none');
        this.renderer.setStyle(dummyDiv, 'display', 'none');
      }, 300);
    }
  }

  autoHideNotificationsMethod() {
    const notifications_section: any = document.querySelector('.notifications-sections');
    if (!notifications_section.classList.contains('expnd')) {
      this.autoHideNotifications = setTimeout(() => {
        this.addRemoveNotifications('REMOVE');
      }, 1000 * 30);
    }
  }

  onTriggerMinimisedSearchBox() {
    this.showSearch = false;
    this.updateWidth();
  }

  updateFilterState() {
    filterConstant.filterInfoData = {
      mainFilter: this.column_filters,
      sortValue: this.selectedSortOptions,
      dropdown_filter: this.selected_filter,
      dropdown_filter_value: this.selected_filter_value,
      searchKey: this.search_global,
      page_name: this.currentActiveTab,
      currentPageIndex: 1,
      tab: ''
    };
  }


  updateCurrentPageFiltrData() {
    this.column_filters = filterConstant.filterInfoData.mainFilter;
    this.selectedSortOptions = filterConstant.filterInfoData.sortValue;
    this.selected_filter = filterConstant.filterInfoData.dropdown_filter;
    this.selected_filter_value = filterConstant.filterInfoData.dropdown_filter_value;
    this.search_global = filterConstant.filterInfoData.searchKey;
    // this.currentPage = filterConstant.filterInfoData.currentPageIndex;
    // this.pageIndex = filterConstant.filterInfoData.currentPageIndex - 1;
    this.filter = this.selected_filter_value || 'All';
  }

  updateWidth() {
    const filterSec = document.querySelector('.search-customised-sec');
    if (filterSec) {
      setTimeout(() => {
        const homeTabSec = document.querySelector('.home-tab-sec');
        this.renderer.setStyle(homeTabSec, 'width', `calc(100% - ${filterSec!.clientWidth + 12}px)`);
      }, 300);
    } else {
      setTimeout(() => {
        this.updateWidth();
      }, 500);
    }
  }

  tabChange(event: any, isScroll = true) {
    this.activeId = event.nextId;
    if ([1, 2, 3, 4, 5].includes(this.tabs[this.activeId - 1].id)) {
      this.router.navigate([this.tabs[this.activeId - 1].route]);
    } else {
      const title = (this.tabs[this.activeId - 1].title as string).toLowerCase().split(' ').join('_');
      this.router.navigate([this.tabs[this.activeId - 1].route], { queryParams: { title: title, id: this.tabs[this.activeId - 1].queryParams } });
    }
    setTimeout(() => {
      this.filter = this.select_type[0].label;
      this.search_global = '';
      this.selectedSortOptions = null;
      this.column_filters = [];
      this.getFilterBasesOnUrl(window.location.pathname);
      this.updateFilterState();
    });
    isScroll && this.autoScrollTabs(0);
    console.log(this.tabs, "tabs");

  }

  autoScrollTabs(timer: number) {
    setTimeout(() => {
      const mainUlSection = document.querySelector('.tab-sec-height');
      const currentTabSection = document.getElementById('li' + this.activeId);
      if (currentTabSection && mainUlSection) {
        if (currentTabSection.offsetLeft + 150 > mainUlSection?.clientWidth && (currentTabSection.offsetLeft + 150 > (mainUlSection?.clientWidth + mainUlSection?.scrollLeft))) {
          if (timer === 0) {
            mainUlSection.scrollBy(mainUlSection?.clientWidth - 200, 0);
          } else {
            mainUlSection.scrollBy(currentTabSection.offsetLeft - 200, 0);
          }
        } else if (currentTabSection.offsetLeft < mainUlSection?.scrollLeft + 150) {
          mainUlSection.scrollBy(-(mainUlSection?.clientWidth - 200), 0);
        }
      }
    }, timer);

  }

  getFilterBasesOnUrl(urlPathname: string) {
    switch (urlPathname) {
      case '/home/task': {
        this.currentActiveTab = 'task';
        const taskKeys: any = [
          {
            label: 'Contact',
            field_name: 'source_type',
            field_value: 'contact'
          },
          {
            label: 'Lead',
            field_name: 'source_type',
            field_value: 'lead'
          },
          {
            label: 'Company',
            field_name: 'source_type',
            field_value: 'company'
          },
          {
            label: 'Flow',
            field_name: 'source_type',
            field_value: 'deal'
          },
          {
            label: 'Project',
            field_name: 'source_type',
            field_value: 'project'
          },
          {
            label: 'Board',
            field_name: 'source_type',
            field_value: 'board'
          },
          {
            label: 'Passed Due Dates',
            field_name: 'passed_due_dates',
            field_value: true
          }];
        const filterKeys: any = [
          {
            label: 'Owner',
            key: 'owner',
            showType: false
          },
          {
            label: 'Assignee',
            key: 'assignee',
            showType: false
          },
          {
            label: 'Task Name',
            key: 'name',
            showType: true
          },
          {
            label: 'Contact',
            key: 'source_id',
            showType: false
          },
          {
            label: 'Created at',
            key: 'created_at',
            showType: true
          },
          {
            label: 'Updated at',
            key: 'updated_at',
            showType: true
          },
          // {
          //   label: 'Created by',
          //   key: 'created_by',
          //   showType: false
          // },
          {
            label: 'Due Date',
            key: 'due_date',
            showType: true
          },
          {
            label: 'Priority',
            key: 'priority',
            showType: false
          },
          {
            label: 'Related to',
            key: 'source_type',
            showType: false
          }
        ];
        this.updateFilterLists(taskKeys, filterKeys);
        break;
      }

      case '/home/appointments': {
        this.currentActiveTab = 'appointments';
        const appointmentKeys: any = [
          {
            label: 'Online',
            field_name: 'meeting_type',
            field_value: 'on_line'
          },
          {
            label: 'Offline',
            field_name: 'meeting_type',
            field_value: 'off_line'
          },
          {
            label: 'Doorstep',
            field_name: 'meeting_type',
            field_value: 'door_step'
          },
          {
            label: 'COD',
            field_name: 'payment_mode',
            field_value: 'cod'
          },
          // {
          //   label: 'Online',
          //   field_name: 'payment_mode',
          //   field_value: 'online'
          // },
          {
            label: 'Admin pannel',
            field_name: 'source_type',
            field_value: 'admin_panel'
          },
          {
            label: 'iFrame',
            field_name: 'source_type',
            field_value: 'iframe'
          },
          // {
          //   label: 'Bot',
          //   field_name: 'source_type',
          //   field_value: 'bot'
          // },
          {
            label: 'Accepted',
            field_name: 'appointment_status',
            field_value: 'accepted'
          },
          {
            label: 'Rejected',
            field_name: 'appointment_status',
            field_value: 'rejected'
          },
          {
            label: 'Completed',
            field_name: 'appointment_status',
            field_value: 'completed'
          },
          {
            label: ' No show',
            field_name: 'appointment_status',
            field_value: 'no_show'
          }
        ];
        const filterKeys: any = [
          {
            label: 'Appointment Name',
            key: 'appointment_name',
            showType: true
          },
          {
            label: 'Assigned staff',
            key: 'staff_id',
            showType: false
          },
          {
            label: 'Contact',
            key: 'contact_id',
            showType: false
          },
          {
            label: 'Booked at',
            key: 'created_at',
            showType: true
          },
          {
            label: 'Updated at',
            key: 'updated_at',
            showType: true
          },
          {
            label: 'Channel',
            key: 'source_type',
            showType: false
          },
          {
            label: 'Appointment Status',
            key: 'appointment_status',
            showType: false
          },
          {
            label: 'Appointment Type',
            key: 'meeting_type',
            showType: false
          },
          // {
          //   label: 'Created by',
          //   key: 'created_by',
          //   showType: false
          // },
          {
            label: 'Scheduled Date',
            key: 'staff_timezone_date',
            showType: true
          }
        ];
        this.updateFilterLists(appointmentKeys, filterKeys);
        break;
      }

      case '/home/orders': {
        this.currentActiveTab = 'orders';
        const orderKeys: any = [
          // {
          //   label: 'Online',
          //   field_name: 'order_status',
          //   field_value: 'on_line'
          // },
          {
            label: 'Pending',
            field_name: 'order_status',
            field_value: 'pending'
          },
          {
            label: 'Accepted',
            field_name: 'order_status',
            field_value: 'accepted'
          },
          {
            label: 'Rejected',
            field_name: 'order_status',
            field_value: 'rejected'
          },
          {
            label: 'Refunded',
            field_name: 'order_status',
            field_value: 'refunded'
          },
          {
            label: 'iFrame',
            field_name: 'source_type',
            field_value: 'iframe'
          },
          {
            label: 'Admin pannel',
            field_name: 'source_type',
            field_value: 'admin_pannel'
          },
          {
            label: 'COD',
            field_name: 'cod',
            field_value: 'payment_type'
          }];
        const filterKeys: any = [
          {
            label: 'Order ID',
            key: 'order_id',
            showType: true
          },
          {
            label: 'Contact',
            key: 'contact',
            showType: false
          },
          {
            label: 'Ordered at',
            key: 'created_at',
            showType: true
          },
          {
            label: 'Updated at',
            key: 'updated_at',
            showType: false
          }
        ];
        this.updateFilterLists(orderKeys, filterKeys);
        break;
      }
      case '/home/calendar-bookings': {
        this.currentActiveTab = 'meetings';
        const meetingKeys: any = [
          // {
          //   label: 'Created at',
          //   field_name: 'created_at',
          //   field_value: true
          // },
          // {
          //   label: 'Meeting Date',
          //   field_name: 'booking_start_time',
          //   field_value: true
          // }
        ];
        const filterKeys: any = [
          {
            label: 'Contact',
            key: 'source_id',
            type: 'source_id',
            showType: false
          },
          {
            label: 'Booking status',
            key: 'booking_status',
            showType: true
          },
          {
            label: 'Assigned Staff',
            key: 'staff_name',
            showType: true
          },
          {
            label: 'Scheduled Date',
            key: 'date',
            showType: true
          },
          {
            label: 'Booked at',
            key: 'created_at',
            showType: true
          },
          {
            label: 'Meeting Name',
            key: 'booking_name',
            showType: true
          }
        ];
        this.updateFilterLists(meetingKeys, filterKeys);
        break;
      }
      case '/home/activity': {
        this.currentActiveTab = 'activity';
        const activityKeys: any = [
          {
            label: 'Contact',
            field_name: 'source_type',
            field_value: 'contact'
          },
          {
            label: 'Lead',
            field_name: 'source_type',
            field_value: 'lead'
          },
          {
            label: 'Company',
            field_name: 'source_type',
            field_value: 'company'
          },
          {
            label: 'Flow',
            field_name: 'source_type',
            field_value: 'pipeline'
          },
          {
            label: 'Project',
            field_name: 'source_type',
            field_value: 'project'
          },
          {
            label: 'Board',
            field_name: 'source_type',
            field_value: 'board'
          },
          {
            label: 'Passed Due Dates',
            field_name: 'passed_due_dates',
            field_value: true
          },
          {
            label: 'Pending',
            field_name: 'mark_as_done',
            field_value: false
          },
          {
            label: 'Completed',
            field_name: 'mark_as_done',
            field_value: true
          }];
        const filterKeys: any = [
          {
            label: 'Contact',
            key: 'source_id',
            showType: false
          },
          {
            label: 'Owner',
            key: 'owner',
            showType: true
          },
          {
            label: 'Due Date',
            key: 'due_date',
            showType: true
          },
          // {
          //   label: 'Created by',
          //   key: 'created_by',
          //   showType: false
          // },
          {
            label: 'Created at',
            key: 'created_at',
            showType: true
          }
        ];

        this.updateFilterLists(activityKeys, filterKeys);
        break;
      }
      default:
        break;
    }

  }

  updateFilterLists(dropdownKeys: any, filterKeys: any, type?: string) {
    const select_type = JSON.parse(JSON.stringify(this.copy_select_type));
    if (this.currentActiveTab === 'appointments' || this.currentActiveTab === 'meetings') {
      select_type.map((elem: any) => {
        if (elem.label === 'Yesterday') {
          elem.label = 'Tomorrow';
          elem.field_name = 'tomorrow';
        } else if (elem.label === 'Last 7 days') {
          elem.label = 'Next 7 days';
          elem.field_name = 'next_7_days';
        }
      });
      this.select_type = JSON.parse(JSON.stringify(select_type.concat(dropdownKeys)));
    } else {
      this.select_type = JSON.parse(JSON.stringify(this.copy_select_type.concat(dropdownKeys)));
    }
    this.filter = this.select_type[0].label;
    this.customFiltersArray = filterKeys;

  }

  getAllActivityLists() {
    this.settingServices.getAllActivities().subscribe((response: any) => {
      this.allActivityLists = response['data'];
      if (this.allActivityLists && this.allActivityLists.length > 0) {
        this.allActivityLists.forEach((data: any) => {
          if (this.paramsData) {
            this.paramsData.id === data.id ? this.activeId = this.tabs.length + 1 : "";
          }
          if (data.status) {
            this.tabs.push({
              id: this.tabs.length + 1,
              title: data.name,
              route: `/home/activity`,
              queryParams: data.id,
              role_permission: '',
              launch_dkl_key: 'home_activity'
            });
          }
        });
        this.handlePopState();

      }
    });
  }

  getNotification(type?: string) {
    this.homeService.getNotifications(this.notificationsCurrentpage).subscribe((resp: any) => {
      resp.data.forEach((element: any) => {
        const timeString = element?.created_at;
        element['customTime'] = customDateTimeConversion(new Date(timeString).getTime());
        element.message = this.sanitizer.bypassSecurityTrustHtml(element.message);
      });
      type && (this.notification_list = []);
      this.notification_list.push(...resp.data);
      this.notificationsLastPage = resp?.last_page;
      this.notification_list.forEach((element: any) => {
        const inputString = element.description;
        const regex = /{[^{}]*}/g;
        const modifiedString = element.description && inputString.replace(regex, (match: string) => {
          try {
            const jsonObject: any = JSON.parse(match);
            if (Object.keys(jsonObject).length !== 0 && jsonObject.fname && jsonObject.id) {
              const searchString = jsonObject.fname + ' ' + jsonObject.lname;
              element['mentioned'] = true;
              return '<span " class="pointer custom_events" style="color: #0072f0;">' +
                searchString + `</span>`;
            } else {
              return match;
            }
          } catch (error) {
            console.error('');
            return match;
          }
        });
        element.description = modifiedString;
      });
    });
  }
  onScroll(): void {
    if (this.notificationsCurrentpage !== this.notificationsLastPage) {
      ++this.notificationsCurrentpage;
      this.getNotification();
    }
  }

  onClickSeemore(pRef: any, index: number) {
    const notifications = document.querySelectorAll(".notification-text");
    const seeMore = document.querySelectorAll('.see-more');
    this.renderer.addClass(notifications[index], 'notification-see-more-expand');
    this.renderer.removeClass(notifications[index], 'limit-line-length-1');
    if (pRef.scrollHeight > 38) {
      setTimeout(() => {
        const height = notifications[index]?.getElementsByTagName('p')[0]?.clientHeight;
        this.renderer.setStyle(notifications[index], 'height', height ? height + 'px' : notifications[index]?.scrollHeight + 'px');
        this.renderer.setStyle(notifications[index], 'transition', 'height 0.3s ease');
      });
    } else {
      this.renderer.setStyle(notifications[index], 'height', 'fit-content');
    }
    this.renderer.addClass(seeMore[index], 'd-none');
  }
  onEventDeligation(item: any) {
    this.onNavigateFromNotifications(item);
  }

  onNavigateFromNotifications(item: any) {
    this.router.navigateByUrl(item.redirection);
  }

  markasRead(item: any, type: string) {
    if (type === 'lbl') {
      item.is_read = !item.is_read;
    }
    this.homeService.putNotification(item).subscribe(() => {
      this.getNotification('Mark');
    });
  }

  markAllasRead(event: any) {
    const inputBox: any = document.getElementById('mark_as_read')!;
    inputBox.checked = true;
    this.homeService.notificationsRead().subscribe(() => {
      inputBox.checked = false;
      this.notification_list = [];
      this.getNotification('Mark');
      this.addRemoveNotifications('REMOVE');
    });
  }

  // getTaskCount() {
  //   this.homeService.getTasksCount().subscribe((resp: any) => {
  //     this.taskCount = resp.count;
  //   });
  // }

  // getEnquireCount() {
  //   this.homeService.getEnquiresCount().subscribe((resp: any) => {
  //     this.enquireCount = resp.total;
  //   });
  // }

  // getActivityCount() {
  //   this.homeService.getActivityCount().subscribe((resp: any) => {
  //     this.activityCount = resp.count;
  //   });
  // }

  // getAppointmentCount() {
  //   this.homeService.getAppointmentCount().subscribe((resp: any) => {
  //     this.aptCount = resp.data.appointments_count;
  //   });
  // }

  handlePopState() {
    let title: any;
    if (window.location.pathname === "/home/activity") {
      const urlSearchParams = new URLSearchParams(window.location.search);
      if (urlSearchParams.has("title")) {
        title = (urlSearchParams.get('title') as string).split("_").join(' ').toUpperCase();
      }
    } else {
      title = window.location.pathname;
    }
    console.log(title, this.tabs);
    this.tabs.forEach((data) => {
      if ((title === data.route) || (title === data.title.toUpperCase())) this.activeId = data.id;
    });
  }
  ngOnDestroy() {
    window.removeEventListener('popstate', this.handlePopState);
    clearTimeout(this.autoHideNotifications);
    // this.headerNotificationsSubscription.unsubscribe();
    this.navigationSubscription$ && this.navigationSubscription$.unsubscribe();
  }

  updateQueryParams() {
    const title = this.activatedRoute.snapshot.queryParamMap.get('title');
    const id = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (title && id) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { title: title, id: id }
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.activatedRoute
      });
    }

  }

  onFilterChange(e: any) {
    // this.updateWidth();
    this.search_global = '';
    this.updateQueryParams();
    if (this.selectedSortOptions || this.column_filters.length > 0) {
      this.selectedSortOptions = null;
      this.column_filters = [];
      const payload = {
        filter: [],
        sort_by: '',
        sort_order: 'desc'
      };
      this.filterFormSubmit(payload);
    }
    const selectedObject = this.select_type.find(opt => opt.label === e);
    this.filter = selectedObject.label;
    this.selected_filter_value = selectedObject.field_value;
    this.selected_filter = selectedObject.field_name;
    // if (this.filter === 'todays') {
    //   this.selected_filter = 'date_filter';
    //   this.selected_filter_value = 'todays';
    // } else if (this.filter === 'this_week') {
    //   this.selected_filter = 'date_filter';
    //   this.selected_filter_value = 'this_week';
    // } else if (this.filter === 'over_due') {
    //   this.selected_filter = 'over_due';
    //   this.selected_filter_value = 'over_due';
    // } else {
    //   this.selected_filter = '';
    //   this.selected_filter_value = '';
    // }
    this.updateFilterState();
    setTimeout(() => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          f_type: this.selected_filter,
          f_value: this.selected_filter_value,
          page: 1
        },
        queryParamsHandling: 'merge' // Preserve other query parameters
      });
    }, 100);

  }

  openFiltersModal(type: string) {
    this.updateQueryParams();
    if (type === 'sort') {
      this.filters_modal = this.modalService.open(HomeCustomFiltersComponent, { size: 'md', centered: true });
    } else {
      this.filters_modal = this.modalService.open(HomeCustomFiltersComponent, { size: 'lg', centered: true, scrollable: true });
    }
    this.search_global = '';
    this.filter = this.select_type[0].label;
    this.filters_modal.componentInstance.filters_custm = this.column_filters;
    this.filters_modal.componentInstance.filterType = type;
    this.filters_modal.componentInstance.is_filter_for = this.currentActiveTab;
    if (this.selectedSortOptions) {
      this.filters_modal.componentInstance.selectedSortOptions = this.selectedSortOptions;
    }
    this.filters_modal.componentInstance.customFiltersArray = this.customFiltersArray;
    // this.filters_modal.componentInstance.is_filter_for = 'lead';
    this.filters_modal.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) {

        if (receivedEntry.from === 'sort') {
          if (receivedEntry.type === 'save') {
            this.selectedSortOptions = receivedEntry.data.sorting;
          } else {
            this.selectedSortOptions = null;
          }
        } else {
          if (receivedEntry.type === 'save') {
            this.column_filters = receivedEntry.data.filter;
          } else {
            this.column_filters = [];
          }
        }
        this.updateFilterState();
        const payload = {
          filter: receivedEntry.data.filter,
          sort_by: receivedEntry.data?.sorting?.sort_by,
          sort_order: receivedEntry.data?.sorting?.sort_order,
          sort_by_label: receivedEntry.data?.sorting?.sort_by_label
        };
        this.filterFormSubmit(payload);
      }
    });
  }

  filterFormSubmit(payload: any) {
    this.homeService.filterAddedSubscriptions$.next(payload);
  }

  onSelectHomeTabs(event: any) {
    console.log(event);
    if (event === 'view_all') {
      this.tabChange({ nextId: 1, isScroll: false });
    } else {
      this.tabChange({ nextId: event, isScroll: false });
    }
    setTimeout(() => {
      const mainUlSection = document.querySelector('.tab-sec-height');
      const currentTabSection = document.getElementById('li' + this.activeId);
      if (currentTabSection && mainUlSection) {
        mainUlSection.scrollTo(currentTabSection?.offsetLeft - 30, 0);
      }
    }, 300);
  }

  getRolePermissions(item: any, permission: any) {
    if (!item) {
      return true;
    } else {
      return this.authServices.rolefeatureShow(item, permission);
    }
  }
}

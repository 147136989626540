import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CrmService } from 'src/app/core/service/crm.service';
import { Location } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-imported-files',
  templateUrl: './imported-files.component.html',
  styleUrls: ['./imported-files.component.scss']
})
export class ImportedFilesComponent {
  field_data: any[] = [];
  params: any;
  showType = '';
  total_resp: any;
  page_size = 20;
  pageIndex = 0;
  pageEvent!: PageEvent;
  currentPage = 1;
  search_global!: string;
  searchSubject: Subject<string> = new Subject<string>();
  object_type: any;


  constructor(private service: CrmService, private activatedRoute: ActivatedRoute, private _location: Location) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.params = params?.type;
      this.showType = params.showType;
      this.object_type = params?.object_type || '';
      if (this.showType === 'upload') {
        this.getFiles();
      } else {
        this.getDownloadedFiles();
      }
    });
    this.searchSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      if (searchTerm.length >= 2) {
        if (this.showType === 'upload') {
          this.getFiles(searchTerm);
        } else {
          this.getDownloadedFiles();
        }
      } else if (searchTerm.length < 1) {
        if (this.showType === 'upload') {
          this.getFiles();
        } else {
          this.getDownloadedFiles();
        }
      }
    });
  }

  getFiles(searchTerm?: string) {
    this.service.getCSVFiles(this.params, this.currentPage,searchTerm).subscribe((resp: any) => {
      this.field_data = resp.data;
      this.total_resp = resp;
    });
  }
  getDownloadedFiles() {
    const serviceMethod = (this.params === 'client' || this.params === 'project')
      ? this.service.getCLIENTCSV(this.params, this.currentPage)
      : this.service.getCRMCSV(this.params, this.currentPage,this.object_type);
    serviceMethod.subscribe({
      next: (resp: any) => {
        if (resp.data && resp.data.length > 0) {
          this.field_data = resp.data;
          this.total_resp = resp;
        }
      }
    });
  }

  onBack() {
    this._location.back();
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
      this.pageIndex = e.pageIndex++;
    } else if (this.pageEvent.pageIndex === 0) {
      this.currentPage = 1;
      this.pageIndex = 0;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
      this.pageIndex = e.pageIndex--;
    }
    console.log(this.currentPage, this.pageIndex);
    if (this.showType === 'upload') {
      this.getFiles();
    } else {
      this.getDownloadedFiles();
    }
  }

  refreshFile(fileId: string) {
    if (this.params === 'client' || this.params === 'project') {
      this.service.getCLIENTCSVRefresh(fileId).subscribe(() => {
        setTimeout(() => {
          this.getFiles();
        }, 1000);
      });
    } else {
      this.service.getCRMCSVRefresh(fileId).subscribe(() => {
        setTimeout(() => {
          this.getFiles();
        }, 1000);
      });
    }
  }
}

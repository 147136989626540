import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';
import { BehaviorSubject, Observable, Subject, map, of } from 'rxjs';
import { SetUpService } from './set-up.service';
import { SettingsService } from './settings.service';
import { AuthGuardService } from './auth-guard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
export interface customLaunchDarklyKeys {
  createEnquiry: boolean,
  createPipeline: boolean
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private addnewChanel = new Subject<boolean>();
  private activeTabStatus = new Subject<number>();

  private current_user = new BehaviorSubject<object>({});
  current_user_data = this.current_user.asObservable();
  generalSetting: any;
  customLaunchDarklyKeys: customLaunchDarklyKeys = { createEnquiry: true, createPipeline: true };
  customLaunchDarklyKeysEvents$ = new Subject();
  priceSetting: any;
  authUserSubscription$ = new Subject();
  featureManagement: any;
  apiCalling = false;
  private current_user_role_features = new BehaviorSubject<any>({});

  constructor(private http: HttpClient, private router: Router, private setUpService: SetUpService,
    private settingsService: SettingsService, private authGuardServices: AuthGuardService, private modalService: NgbModal) {
    /* tslint:disable:no-empty */
    this.authGuardServices.featureManagement.subscribe((data: any) => {
      if (data && data.data) {
        this.featureManagement = data;
      } else if (data === null) {
        this.featureManagement = null;
        this.apiCalling = false;
      }
    });
  }

  register(body: any) {
    return this.http.post<any>(`${environment.urlPrefix}${constant.register}`, body);
  }

  login(body: any) {
    this.authGuardServices.featureManagement.next(null);
    return this.http.post<any>(`${environment.urlPrefix}${constant.login}`, body);
  }

  forgotPsw(body: JSON) {
    return this.http.post(`${environment.urlPrefix}${constant.forgot_psw}`, body);
  }

  resetPsw(body: JSON) {
    return this.http.post(`${environment.urlPrefix}${constant.reset_psw}`, body);
  }

  verifyLinkforPsw(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.verify_psw_link}`, body);
  }

  activateUser(id: string, otp: string) {
    return this.http.get(`${environment.urlPrefix}${constant.verification_code}${id}/?code=${otp}`);
  }

  resendactivation(userEmail: string) {
    return this.http.get(`${environment.urlPrefix}${constant.resend_verification_code}?email=${userEmail}`);
  }

  logout() {
    const authUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.putActiveStatus(authUser.data.id, { active_status: false }).subscribe();
    this.modalService.dismissAll();
    sessionStorage.removeItem('currentUser');
    localStorage.removeItem('currentUser');
    this.clearSubjectVariables();
    this.router.navigate(['']);
    this.authUserSubscription$.next('');
    // this.apiCalling = false;
  }

  public setAddChanelStatus(status: boolean): void {
    this.addnewChanel.next(status);
  }
  public getAddChanelStatus(): Observable<boolean> {
    return this.addnewChanel.asObservable();
  }
  public setActiveTabStatus(status: number): void {
    this.activeTabStatus.next(status);
  }
  public getActiveTabStatus(): Observable<number> {
    return this.activeTabStatus.asObservable();
  }

  getData() {
    this.http.get(`${environment.urlPrefix}${constant.general_settings}`).subscribe((resp: any) => {
      this.generalSetting = resp.data;
    });
    this.http.get(`${environment.urlPrefix}${constant.price_settings}`).subscribe((resp: any) => {
      this.priceSetting = resp.data;
    });
    this.getSuscriptionPlanUpdate();
  }

  getCompanyData() {
    return this.generalSetting;
  }

  getPriceData() {
    return this.priceSetting;
  }

  clearSubjectVariables() {
    this.setUpService.meetingSettings.next(null);
    this.setUpService.calendarMeetings.next(null);
    this.setUpService.workingHours.next(null);
    this.setUpService.setupSetting.next(null);
    this.settingsService.getStaffInfoList.next(null);
  }

  //header Calendar
  getAllSlotsData(type_calendar: any, date: any) {
    return this.http.get(`${environment.urlPrefix}${constant.header_calendar}date/?${type_calendar}=${date}&time_zone=${this.generalSetting?.timezone || 'Asia/Calcutta'}`);
  }

  getAptSlots(type_calendar: any, date: any, end_date: any) {
    return this.http.get(`${environment.urlPrefix}${constant.calendar_filter}?${type_calendar}=${date}&end_date=${end_date}&time_zone=${this.generalSetting?.timezone || 'Asia/Calcutta'}`);
  }

  getSlotDetailsByid(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.header_calendar}${id}/?time_zone=${this.generalSetting?.timezone || 'Asia/Calcutta'}`);
  }

  putActiveStatus(staff_id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.active_status}${staff_id}/`, body);
  }

  getChatbotCalenderSync(filter: string, date: any) {
    return this.http.get(`${environment.messageUrl}${constant.header_calendar_sync}?filter=${filter}&start=${date}&time_zone=${this.generalSetting?.timezone || 'Asia/Calcutta'}`);
  }

  getFeaturesManagement() {
    if (this.featureManagement && Object.keys(this.featureManagement).length > 0) {
      return of(this.featureManagement);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.feature_management}`).pipe(map((resp: any) => {
        this.authGuardServices.featureManagement.next(resp);
        this.featureManagement = resp;
        return this.featureManagement;
      }));
    }
  }

  getRolesPermissions() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const current_User = JSON.parse(sessionStorage.getItem('currentUser')!).data;
    if (current_User.role === 'MANAGER' || current_User.role === 'ADMIN' || current_User.role === 'STAFF' || current_User.role === 'AGENT') {
      console.log('');

    } else if (!(current_User.role === 'MANAGER' || current_User.role === 'ADMIN' || current_User.role === 'STAFF' || current_User.role === 'AGENT')) {
      this.http.get(`${environment.urlPrefix}${constant.roles_modules_permissions_fetch}${current_User.role_id}/`).subscribe((resp: any) => {
        this.current_user_role_features.next(resp);
      });
    }
  }

  rolefeatureShow(item: any,permission: any) {
    const current_User = JSON.parse(sessionStorage.getItem('currentUser')!).data;
    if (current_User.role === 'MANAGER' || current_User.role === 'ADMIN' || current_User.role === 'STAFF' || current_User.role === 'AGENT') {
      return true;
    } else {
      return this.current_user_role_features.getValue().data[item]?.[permission];
    }
  }

  getCurrentPlanDays() {
    return this.http.get(`${environment.urlPrefix}${constant.billing}${constant.current_plan_days}`);
  }

  getCurrentPlan() {
    return this.http.get(`${environment.urlPrefix}${constant.billing}${constant.current_plan}`);
  }

  getSuscriptionPlanUpdate() {// check every time the subscription plan is ended or not
    this.http.get(`${environment.urlPrefix}${constant.billing}${constant.subscription_plans_update}`).subscribe();
  }

  getContacts() {
    return this.http.get(`${environment.urlPrefix}${constant.contacts_crm}`);
  }

  getContactsBySearch(name: string) {
    return this.http.get(`${environment.urlPrefix}${constant.contacts_crm}?search=${name}&phone_number=true`);
  }

  getDisplayId() {
    return this.http.get(`${environment.urlPrefix}${constant.Crm_displayId}`);
  }
}
//   this.http.get(`${environment.urlPrefix}${constant.feature_management}`).subscribe((resp: any) => {
//     console.log('called if not valid');
//     this.authGuardServices.featureManagement.next(resp);
//     return of(resp.data);
//   });
// 

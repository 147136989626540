<div class="w-100 overflow-hidden" [ngStyle]="{'min-height': filterType === 'filters' ? 'calc(100vh - 500px)' : '0vh'}">
    <!-- <div class="p-2"> -->
    <div class="bg-white">
        <div class="d-flex align-items-center justify-content-between modal-header mb-3">
            <h6 class="fw-600 text-primary m-0">{{filterType === 'filters' ? 'Filter' : 'Sort'}}</h6>
            <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
        </div>
        <form [formGroup]="columnFilters" (ngSubmit)="filterFormSubmit()"
            class="d-flex flex-column justify-content-between">
            <div [ngStyle]="{'min-height': filterType === 'filters' ? 'calc(100vh - 610px)' : '0vh'}">
                <div class="d-flex justify-content-center px-3 mb-3" formGroupName="sorting"
                    *ngIf="filterType === 'sort'">
                    <div class="col-md-6 pe-3" style="display: grid;">
                        <label class="mb-2 text-primary">Sort by</label>
                        <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none">
                            <mat-select placeholder="Select" formControlName="sort_by">
                                <ng-container *ngFor="let option of sortByOptions">
                                    <mat-option class="font-size-12" [value]="option.key">{{option.label}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 p-0" style="display: grid;">
                        <label class="mb-2 text-primary ms-3">Sort order</label>
                        <mat-form-field appearance="outline" class="contactsfilter main-filter ms-3 mat-hint-none">
                            <mat-select placeholder="Select" formControlName="sort_order">
                                <mat-option class="font-size-12" value="ascend">Asc</mat-option>
                                <mat-option class="font-size-12" value="desc">Desc</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <ng-container formArrayName="filter" *ngIf="filterType === 'filters'">
                    <ng-container *ngFor="let item of filter_of_column.controls; let i = index">
                        <div class="d-flex px-3" [formGroupName]="i" [ngClass]="i >= 1 ? 'mt-2' : ''">
                            <div class="" style="width:30%">
                                <label class="mb-2 text-primary">Field Name</label>
                                <mat-form-field appearance="outline"
                                    class="contactsfilter main-filter w-100 mat-hint-none">
                                    <mat-select placeholder="Select Field" (selectionChange)="onChangeDropDown(i)"
                                        formControlName="column">
                                        <mat-option (click)="forCustomFieldStatus(option, i)"
                                            *ngFor="let option of customFiltersArray" class="font-size-12"
                                            [value]="option.id">{{option.display_label}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="mx-3" style="width:30%">
                                <label class="mb-2 text-primary">Condition</label>
                                <mat-form-field appearance="outline"
                                    class="contactsfilter w-100 main-filter mat-hint-none">
                                    <mat-select placeholder="Select Condition"
                                        (selectionChange)="(columnFilters.value.filter[i].operation === 'is_null' || columnFilters.value.filter[i].operation === 'is_empty')  ? updateFormValidations(i,'disable') : updateFormValidations(i,'enable')"
                                        formControlName="operation">
                                        <mat-option class="font-size-12" value="contains" *ngIf="(columnFilters.value.filter[i].column !== 'channel' && 
                                        columnFilters.value.filter[i].column !== 'tags' && 
                                        columnFilters.value.filter[i].column !== 'email_subscription' &&
                                        columnFilters.value.filter[i].column !== 'sms_subscription' && 
                                        columnFilters.value.filter[i].column !== 'whatsapp_subscription' && 
                                        columnFilters.value.filter[i].column !== 'time_zone' &&
                                        columnFilters.value.filter[i].column !== 'ccode' &&
                                        columnFilters.value.filter[i].column !== 'contact' &&
                                        columnFilters.value.filter[i].column !== 'owner' &&
                                        columnFilters.value.filter[i].column !== 'product' &&
                                        columnFilters.value.filter[i].column !== 'created_at' &&
                                        columnFilters.value.filter[i].column !== 'updated_at' &&
                                        columnFilters.value.filter[i].column !== 'assignee' &&
                                        columnFilters.value.filter[i].column !== 'due_date') &&
                                        columnFilters.value.filter[i].column !== 'country' &&
                                        columnFilters.value.filter[i].is_data_type !== 'MULTI_SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE_TIME' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE_RANGE' &&
                                        columnFilters.value.filter[i].is_data_type !== 'SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i].is_data_type !== 'RADIO_BUTTON'
                                        ">Contains</mat-option>

                                        <mat-option class="font-size-12" value="is" *ngIf="columnFilters.value.filter[i].column !== 'channel' && 
                                        columnFilters.value.filter[i].column !== 'tags' && 
                                        columnFilters.value.filter[i].column !== 'time_zone' &&
                                        columnFilters.value.filter[i].column !== 'ccode' &&
                                        columnFilters.value.filter[i].column !== 'owner' &&
                                        columnFilters.value.filter[i].column !== 'assignee' &&
                                        columnFilters.value.filter[i].column !== 'product' &&
                                        columnFilters.value.filter[i].column !== 'created_at' &&
                                        columnFilters.value.filter[i].column !== 'updated_at' &&
                                        columnFilters.value.filter[i].column !== 'contact' &&
                                        columnFilters.value.filter[i].column !== 'country' &&
                                        columnFilters.value.filter[i].is_data_type !== 'TEXT_AREA' &&
                                        columnFilters.value.filter[i].is_data_type !== 'MULTI_SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE_TIME' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE_RANGE' &&
                                        columnFilters.value.filter[i].is_data_type !== 'SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i].is_data_type !== 'RADIO_BUTTON' && 
                                        columnFilters.value.filter[i].is_data_type !== 'FILE'">Is
                                            equal
                                            to</mat-option>

                                        <mat-option class="font-size-12" value="greater_than" *ngIf="( 
                                        (columnFilters.value.filter[i].is_data_type && (columnFilters.value.filter[i].is_data_type === 'DATE' || columnFilters.value.filter[i].is_data_type === 'DATE_TIME') && columnFilters.value.filter[i].is_data_type !== 'DATE_RANGE') ||
                                        columnFilters.value.filter[i].column === 'created_at' ||
                                        columnFilters.value.filter[i].column === 'updated_at' ||
                                        columnFilters.value.filter[i].column === 'due_date' || 
                                        columnFilters.value.filter[i].column === 'assignee_department' || 
                                        columnFilters.value.filter[i].column === 'staff_ids' || 
                                        columnFilters.value.filter[i].column === 'service_ids' || 
                                        columnFilters.value.filter[i].column === 'client_id') && 
                                        columnFilters.value.filter[i].column !== 'country' &&
                                        columnFilters.value.filter[i].column !== 'email_subscription' && 
                                        columnFilters.value.filter[i].column !== 'sms_subscription' && 
                                        columnFilters.value.filter[i].column !== 'whatsapp_subscription' && 
                                        columnFilters.value.filter[i].column !== 'time_zone' &&
                                        columnFilters.value.filter[i].is_data_type !== 'MULTI_SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i].is_data_type !== 'TEXT_AREA' &&
                                        columnFilters.value.filter[i].column !== 'ccode' &&
                                        columnFilters.value.filter[i].column !== 'owner' &&
                                        columnFilters.value.filter[i].column !== 'assignee' &&
                                        columnFilters.value.filter[i].column !== 'contact' &&
                                        columnFilters.value.filter[i].is_data_type !== 'FILE'">Greater
                                            than</mat-option>

                                        <mat-option class="font-size-12" value="less_than" *ngIf="( 
                                        (columnFilters.value.filter[i].is_data_type && (columnFilters.value.filter[i].is_data_type === 'DATE' || columnFilters.value.filter[i].is_data_type === 'DATE_TIME') && columnFilters.value.filter[i].is_data_type !== 'DATE_RANGE') ||
                                        columnFilters.value.filter[i].column === 'created_at' ||
                                        columnFilters.value.filter[i].column === 'updated_at' ||
                                        columnFilters.value.filter[i].column === 'due_date' || 
                                        columnFilters.value.filter[i].column === 'assignee_department' || 
                                        columnFilters.value.filter[i].column === 'staff_ids' || 
                                        columnFilters.value.filter[i].column === 'service_ids' || 
                                        columnFilters.value.filter[i].column === 'client_id') && 
                                        columnFilters.value.filter[i].column !== 'email_subscription' && 
                                        columnFilters.value.filter[i].column !== 'sms_subscription' && 
                                        columnFilters.value.filter[i].column !== 'whatsapp_subscription' && 
                                        columnFilters.value.filter[i].column !== 'time_zone' &&
                                        columnFilters.value.filter[i].column !== 'ccode' &&
                                        columnFilters.value.filter[i].column !== 'country' &&
                                        columnFilters.value.filter[i].column !== 'owner' &&
                                        columnFilters.value.filter[i].column !== 'assignee' &&
                                        columnFilters.value.filter[i].is_data_type !== 'MULTI_SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i].is_data_type !== 'TEXT_AREA' &&
                                        columnFilters.value.filter[i].column !== 'contact' && 
                                        columnFilters.value.filter[i].is_data_type !== 'FILE'">Less
                                            than</mat-option>

                                        <mat-option class="font-size-12" value="starts_with" *ngIf="columnFilters.value.filter[i].column !== 'channel' && 
                                        columnFilters.value.filter[i].column !== 'tags' && 
                                        columnFilters.value.filter[i].column !== 'email_subscription' && 
                                        columnFilters.value.filter[i].column !== 'sms_subscription' && 
                                        columnFilters.value.filter[i].column !== 'whatsapp_subscription' && 
                                        columnFilters.value.filter[i].column !== 'time_zone' &&
                                        columnFilters.value.filter[i].column !== 'ccode' &&
                                        columnFilters.value.filter[i].column !== 'owner' &&
                                        columnFilters.value.filter[i].column !== 'created_at' &&
                                        columnFilters.value.filter[i].column !== 'updated_at' &&
                                        columnFilters.value.filter[i].column !== 'assignee' &&
                                        columnFilters.value.filter[i].column !== 'contact' &&
                                        columnFilters.value.filter[i].column !== 'country' &&
                                        columnFilters.value.filter[i].is_data_type !== 'MULTI_SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i].is_data_type !== 'TEXT_AREA' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE_TIME' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE_RANGE' &&
                                        columnFilters.value.filter[i].is_data_type !== 'SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i].is_data_type !== 'RADIO_BUTTON' && 
                                        columnFilters.value.filter[i].is_data_type !== 'FILE'">Starts
                                            with</mat-option>

                                        <mat-option class="font-size-12" value="ends_with" *ngIf="columnFilters.value.filter[i].column !== 'channel' && 
                                        columnFilters.value.filter[i].column !== 'tags' && 
                                        columnFilters.value.filter[i].column !== 'email_subscription' && 
                                        columnFilters.value.filter[i].column !== 'sms_subscription' && 
                                        columnFilters.value.filter[i].column !== 'whatsapp_subscription' && 
                                        columnFilters.value.filter[i].column !== 'time_zone' &&
                                        columnFilters.value.filter[i].column !== 'ccode' &&
                                        columnFilters.value.filter[i].column !== 'owner' &&
                                        columnFilters.value.filter[i].column !== 'created_at' &&
                                        columnFilters.value.filter[i].column !== 'updated_at' && 
                                        columnFilters.value.filter[i].column !== 'assignee' &&
                                        columnFilters.value.filter[i].column !== 'contact' &&
                                        columnFilters.value.filter[i].column !== 'country' &&
                                        columnFilters.value.filter[i].is_data_type !== 'MULTI_SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i].is_data_type !== 'TEXT_AREA' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE_TIME' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE_RANGE' &&
                                        columnFilters.value.filter[i].is_data_type !== 'SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i].is_data_type !== 'RADIO_BUTTON' && 
                                        columnFilters.value.filter[i].is_data_type !== 'FILE'">Ends
                                            with</mat-option>
                                        <mat-option class="font-size-12" value="between"
                                            *ngIf="(columnFilters.value.filter[i].is_data_type && (columnFilters.value.filter[i].is_data_type === 'DATE') || columnFilters.value.filter[i].is_data_type === 'DATE_TIME') || columnFilters.value.filter[i].column === 'created_at'  ||
                                                   columnFilters.value.filter[i].column === 'updated_at'">Between</mat-option>

                                        <mat-option class="font-size-12" value="is_empty"
                                            *ngIf="columnFilters.value.filter[i].column === 'country'">Is
                                            Empty</mat-option>

                                        <mat-option class="font-size-12" value="is_null" *ngIf="columnFilters.value.filter[i].is_data_type !== 'MULTI_SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE_TIME' &&
                                        columnFilters.value.filter[i].is_data_type !== 'DATE_RANGE' &&
                                        columnFilters.value.filter[i].is_data_type !== 'SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i].is_data_type !== 'RADIO_BUTTON' && 
                                        columnFilters.value.filter[i].is_data_type !== 'FILE' && columnFilters.value.filter[i].column !== 'channel' && 
                                         ((is_filter_for === 'project' && columnFilters.value.filter[i].column !== 'name') || 
                                         (is_filter_for === 'client' && (columnFilters.value.filter[i].column !== 'name' && 
                                         columnFilters.value.filter[i].column !== 'phone_number' && 
                                         columnFilters.value.filter[i].column !== 'email')) ||
                                         (is_filter_for === 'company' && columnFilters.value.filter[i].column !== 'name') ||
                                         (is_filter_for === 'lead' && (columnFilters.value.filter[i].column !== 'name' &&
                                          columnFilters.value.filter[i].column  !== 'contact')) ||
                                         (is_filter_for === 'contact' && columnFilters.value.filter[i].column !== 'first_name'  && 
                                         columnFilters.value.filter[i].column !== 'last_name' && 
                                         columnFilters.value.filter[i].column !== 'email' && 
                                         columnFilters.value.filter[i].column !== 'phone_number')) && 
                                         (columnFilters.value.filter[i].column !== 'email_subscription' && 
                                         columnFilters.value.filter[i].column !== 'sms_subscription' && 
                                         columnFilters.value.filter[i].column !== 'whatsapp_subscription') &&
                                        columnFilters.value.filter[i].column !== 'ccode' &&
                                        columnFilters.value.filter[i].column !== 'country' &&
                                        columnFilters.value.filter[i].column !== 'owner' &&
                                        columnFilters.value.filter[i].column !== 'created_at' &&
                                        columnFilters.value.filter[i].column !== 'updated_at' &&
                                        columnFilters.value.filter[i].is_data_type !== 'MULTI_SELECT_DROPDOWN' &&
                                        columnFilters.value.filter[i]?.is_mandatory !== true &&
                                        columnFilters.value.filter[i].column !== 'contact' && 
                                        columnFilters.value.filter[i].is_data_type !== 'FILE'
                                        ">Is null</mat-option>

                                        <mat-option class="font-size-12" value="is" *ngIf="(columnFilters.value.filter[i].is_data_type && 
                                        columnFilters.value.filter[i].is_data_type !== 'FILE' &&
                                        (columnFilters.value.filter[i].is_data_type === 'MULTI_SELECT_DROPDOWN' ||
                                        columnFilters.value.filter[i].is_data_type === 'DATE' ||
                                        columnFilters.value.filter[i].is_data_type === 'DATE_TIME' ||
                                        columnFilters.value.filter[i].is_data_type === 'DATE_RANGE' ||
                                        columnFilters.value.filter[i].is_data_type === 'SELECT_DROPDOWN' ||
                                        columnFilters.value.filter[i].is_data_type === 'RADIO_BUTTON')) || 
                                        columnFilters.value.filter[i].column === 'channel' || 
                                        columnFilters.value.filter[i].column === 'country' ||
                                        columnFilters.value.filter[i].column === 'tags' || 
                                        columnFilters.value.filter[i].column === 'created_at' ||
                                        columnFilters.value.filter[i].column === 'updated_at' ||
                                        columnFilters.value.filter[i].column === 'time_zone' ||
                                        columnFilters.value.filter[i].column === 'ccode' ||
                                        columnFilters.value.filter[i].column === 'owner' ||
                                        columnFilters.value.filter[i].column === 'assignee' ||
                                        columnFilters.value.filter[i].column === 'contact' ||
                                        columnFilters.value.filter[i].is_data_type === 'MULTI_SELECT_DROPDOWN' ||
                                        columnFilters.value.filter[i].column === 'project_status' && 
                                        columnFilters.value.filter[i].column !== 'email_subscription' && 
                                        columnFilters.value.filter[i].column !== 'sms_subscription' && 
                                        columnFilters.value.filter[i].column !== 'whatsapp_subscription' &&
                                        columnFilters.value.filter[i].is_data_type !== 'TEXT_AREA' &&
                                        columnFilters.value.filter[i].is_data_type !== 'FILE'
                                        ">Is</mat-option>


                                        <mat-option class="font-size-12" value="not" *ngIf="(columnFilters.value.filter[i].is_data_type && 
                                        columnFilters.value.filter[i].is_data_type !== 'FILE' &&
                                        (columnFilters.value.filter[i].is_data_type === 'MULTI_SELECT_DROPDOWN' ||
                                        columnFilters.value.filter[i].is_data_type === 'DATE' ||
                                        columnFilters.value.filter[i].is_data_type === 'DATE_TIME' ||
                                        columnFilters.value.filter[i].is_data_type === 'DATE_RANGE' ||
                                        columnFilters.value.filter[i].is_data_type === 'SELECT_DROPDOWN' ||
                                        columnFilters.value.filter[i].is_data_type === 'RADIO_BUTTON')) || 
                                        columnFilters.value.filter[i].column === 'channel' || 
                                        columnFilters.value.filter[i].column === 'country' ||
                                        columnFilters.value.filter[i].column === 'tags' || 
                                        columnFilters.value.filter[i].column === 'created_at' ||
                                        columnFilters.value.filter[i].column === 'updated_at' ||
                                        columnFilters.value.filter[i].column === 'time_zone' ||
                                        columnFilters.value.filter[i].column === 'ccode' ||
                                        columnFilters.value.filter[i].column === 'owner' ||
                                        columnFilters.value.filter[i].column === 'assignee' || 
                                        columnFilters.value.filter[i].column === 'contact' ||
                                        columnFilters.value.filter[i].column === 'project_status' && 
                                        columnFilters.value.filter[i].column !== 'email_subscription' && 
                                        columnFilters.value.filter[i].column !== 'sms_subscription' && 
                                        columnFilters.value.filter[i].column !== 'whatsapp_subscription' &&
                                        columnFilters.value.filter[i].is_data_type !== 'TEXT_AREA'
                                        "> Is
                                            not</mat-option>

                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="" style="width:30%"
                                *ngIf="columnFilters.value.filter[i].operation !== 'is_null' && columnFilters.value.filter[i].operation !== 'is_empty'">
                                <label class="mb-2 text-primary">Value</label>
                                <mat-form-field appearance="outline" *ngIf=" !(
                                (columnFilters.value.filter[i].column === 'contact') || 
                                columnFilters.value.filter[i].column === 'country' ||
                                columnFilters.value.filter[i].is_data_type === 'DATE' ||
                                columnFilters.value.filter[i].is_data_type === 'DATE_TIME' ||
                                columnFilters.value.filter[i].is_data_type === 'DATE_RANGE' ||
                                columnFilters.value.filter[i].is_data_type === 'SELECT_DROPDOWN' ||
                                columnFilters.value.filter[i].is_data_type === 'MULTI_SELECT_DROPDOWN' ||
                                columnFilters.value.filter[i].is_data_type === 'RADIO_BUTTON' ||
                                columnFilters.value.filter[i].column === 'company_id' ||  
                                columnFilters.value.filter[i].column === 'owner' || 
                                columnFilters.value.filter[i].column === 'assignee' ||
                                columnFilters.value.filter[i].column === 'company'  || 
                                columnFilters.value.filter[i].column === 'products_category' || 
                                columnFilters.value.filter[i].column === 'product_name' || 
                                columnFilters.value.filter[i].column === 'product' || 
                                columnFilters.value.filter[i].column === 'due_date' || 
                                columnFilters.value.filter[i].column === 'tags' || 
                                columnFilters.value.filter[i].column === 'assignee_department' || 
                                columnFilters.value.filter[i].column === 'channel'  || 
                                columnFilters.value.filter[i].column === 'created_at' ||
                                columnFilters.value.filter[i].column === 'updated_at'||
                                columnFilters.value.filter[i].column === 'staff_ids' || 
                                columnFilters.value.filter[i].column === 'service_ids' || 
                                columnFilters.value.filter[i].column === 'client_id' || 
                                columnFilters.value.filter[i].column === 'email_subscription' || 
                                columnFilters.value.filter[i].column === 'sms_subscription' || 
                                columnFilters.value.filter[i].column === 'whatsapp_subscription')"
                                    class="contactsfilter main-filter w-100 mat-hint-none">
                                    <input style="outline: none" [id]="'customInput'+i" matInput placeholder="Enter Value"
                                        formControlName="data" />
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'owner' || columnFilters.value.filter[i].column ===
                                'assignee' ">
                                    <mat-select placeholder="Value" formControlName="data">
                                        <mat-option *ngFor="let item of staffMembers" class="font-size-12"
                                            [value]="item.id">{{item?.fname}} {{item?.lname}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <ng-select *ngIf="columnFilters.value.filter[i].column === 'contact'"
                                    class="search-ng-select ms-0" [items]="contactListArray" bindLabel="first_name"
                                    bindValue="id" [multiple]="false" placeholder="Search name"
                                    (search)="searchTriggerConatct($event)" formControlName="data">
                                </ng-select>
                                <ng-select *ngIf="(columnFilters.value.filter[i].column === 'company_id' || 
                                 columnFilters.value.filter[i].column === 'company')" class="search-ng-select ms-0"
                                    [items]="companyListArray" bindLabel="name" bindValue="id" [multiple]="false"
                                    placeholder="Search name" (search)="searchTriggerCompany($event)"
                                    formControlName="data">
                                </ng-select>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'product_category' || 
                                        columnFilters.value.filter[i].column === 'product_name'">
                                    <mat-select placeholder="Value" formControlName="data">
                                        <mat-option *ngFor="let item of product_categories_array" class="font-size-12"
                                            [value]="item.id">{{item?.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'contact_type'">
                                    <mat-select formControlName="data" placeholder='Select contact type'>
                                        <mat-option [value]="type.id" *ngFor="let type of contact_type">{{type.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'product_name' || 
                                columnFilters.value.filter[i].column === 'product' ||
                                columnFilters.value.filter[i].column === 'service_ids'">
                                    <mat-select placeholder="Value" formControlName="data">
                                        <mat-option *ngFor="let item of products_array" class="font-size-12"
                                            [value]="item.id">{{item?.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'tags'">
                                    <mat-select placeholder="Value" formControlName="data">
                                        <mat-option *ngFor="let item of listOfTags" class="font-size-12"
                                            [value]="item.id">{{item?.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline"
                                    class="contactsfilter main-filter mat-hint-none w-100"
                                    *ngIf="columnFilters.value.filter[i].column === 'priority'">
                                    <mat-select formControlName="data" placeholder="Priority">
                                        <mat-option value="High" class="font-size-12">High</mat-option>
                                        <mat-option value="Low" class="font-size-12">Low</mat-option>
                                        <mat-option value="Medium" class="font-size-12">Medium</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'scheduler_category'">
                                    <mat-select placeholder="Value" formControlName="data">
                                        <mat-option *ngFor="let item of categoriesArray" class="font-size-12"
                                            [value]="item.id">{{item?.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'scheduler'">
                                    <mat-select placeholder="Value" formControlName="data">
                                        <mat-option *ngFor="let item of appointmentsArray" class="font-size-12"
                                            [value]="item.id">{{item?.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline"
                                    class="contactsfilter main-filter mat-hint-none w-100" *ngIf="((columnFilters.value.filter[i].custom_field &&  columnFilters.value.filter[i].is_data_type &&
                                    (columnFilters.value.filter[i].is_data_type === 'DATE' ||
                                    columnFilters.value.filter[i].is_data_type === 'DATE_TIME') &&
                                     columnFilters.value.filter[i].is_data_type !== 'DATE_RANGE' && 
                                     columnFilters.value.filter[i].operation !== 'between')) || 
                                     (columnFilters.value.filter[i].operation !== 'between' && 
                                     (columnFilters.value.filter[i].column === 'due_date'|| 
                                     columnFilters.value.filter[i].column === 'created_at' ||
                                     columnFilters.value.filter[i].column === 'updated_at'))">
                                    <input style="outline: none" matInput [matDatepicker]="picker"
                                        (dateChange)="updateDateFormat($event,i,'date')" placeholder="Date"
                                        formControlName="data" />
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'assignee_department'">
                                    <mat-select placeholder="Value" formControlName="data">
                                        <mat-option *ngFor="let item of departmentsList" class="font-size-12"
                                            [value]="item.id">{{item?.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].custom_field && columnFilters.value.filter[i].is_data_type && (columnFilters.value.filter[i].is_data_type === 'SELECT_DROPDOWN' || columnFilters.value.filter[i].is_data_type === 'RADIO_BUTTON')">
                                    <mat-select placeholder="Value" formControlName="data">
                                        <mat-option *ngFor="let item of customFieldsOptions[i]" class="font-size-12"
                                            [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'channel'">
                                    <mat-select placeholder="Value" formControlName="data">
                                        <mat-option *ngFor="let item of channels" class="font-size-12"
                                            [value]="item.value">{{item?.display_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'email_subscription' || columnFilters.value.filter[i].column === 'sms_subscription' || columnFilters.value.filter[i].column === 'whatsapp_subscription'">
                                    <mat-select placeholder="Value" formControlName="data">
                                        <mat-option class="font-size-12" value="true">True</mat-option>
                                        <mat-option class="font-size-12" value="false">False</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'project_status'">
                                    <mat-select placeholder="Value" formControlName="data">
                                        <mat-option class="font-size-12" value="PENDING">Pending</mat-option>
                                        <mat-option class="font-size-12" value="COMPLETED">Completed</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'country'">
                                    <mat-select placeholder='Select country' formControlName="data">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let country of filteredBanks | async"
                                            value={{country.Entity}}>{{country.Entity}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none" *ngIf="columnFilters.value.filter[i].column === 'time_zone'">
                                    <mat-select placeholder='Select Timezone'
                                        formControlName="data">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterTimezoneCtrl"
                                                placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-optgroup *ngFor="let time_zone of filteredTimezoneBanks | async"
                                            [label]="time_zone.name">
                                            <mat-option class="mb-2 mat-hint-none"
                                                *ngFor="let countries of time_zone.countries"
                                                value="{{countries.timezone}}">{{countries.name}}</mat-option>
                                        </mat-optgroup>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none"
                                    *ngIf="columnFilters.value.filter[i].column === 'currency'">
                                    <mat-select placeholder='Select currency' formControlName="data">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterCurrencyCtrl"
                                                placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let currency of filteredCurrencyBanks | async"
                                            value='{{currency.abbreviation +", "+currency.symbol}}'>{{currency.currency
                                            + " (" + currency.abbreviation + "," + currency.symbol +
                                            ")"}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline"
                                    class="contactsfilter main-filter mat-hint-none w-100"
                                    *ngIf="( columnFilters.value.filter[i].custom_field && columnFilters.value.filter[i].is_data_type && columnFilters.value.filter[i].is_data_type === 'DATE_RANGE') || (columnFilters.value.filter[i].operation === 'between' &&
                                                (columnFilters.value.filter[i].column === 'created_at' ||
                                                    columnFilters.value.filter[i].column === 'updated_at' || columnFilters.value.filter[i].is_data_type))">
                                    <mat-date-range-input [rangePicker]="picker">
                                        <input #startDateInput matStartDate
                                            (dateChange)="onDateChange($event,'start',i)"
                                            [value]="betweenFilterDates[i]?.startDate" placeholder="Start date">
                                        <input matEndDate #endDateInput (dateChange)="onDateChange($event,'end',i)"
                                            placeholder="End date"
                                            [value]="betweenFilterDates[i]?.endDate">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                </mat-form-field>
                                <ng-select *ngIf="columnFilters.value.filter[i].column === 'client_id'"
                                    class="search-ng-select ms-0" [items]="clientArray" bindLabel="name" bindValue="id"
                                    [multiple]="false" placeholder="Search name" (search)="searchTriggerClient($event)"
                                    formControlName="data">
                                </ng-select>
                            </div>
                            <div style="width: 8%;">
                                <div class="col-md-1 d-flex align-items-center w-100 justify-content-center h-100"
                                    *ngIf="filter_of_column.length > 1">
                                    <mat-icon class="cursor" style="font-size:20px;"
                                        (click)="deleteFilter(i)">delete</mat-icon>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="d-flex align-items-center justify-content-end px-3 ">
                <button class="btn btn-primary d-flex align-items-center font-size-13 my-3 ms-3 me-1"
                    *ngIf="filterType === 'filters'" [disabled]="filter_of_column.length > 2" type="button"
                    (click)="filtersJsonForm()">
                    Add additional filter
                </button>
                <button class="btn btn-primary d-flex align-items-center font-size-13 my-3 ms-1" type="submit">
                    Apply
                </button>
                <button *ngIf="filterType === 'sort'" [disabled]="!columnFilters.value?.sorting?.sort_by"
                    class="btn btn-primary d-flex align-items-center font-size-13 my-3 mx-1" type="button"
                    (click)="resetFilters()">
                    Reset
                </button>
                <button *ngIf="filterType !== 'sort'" [disabled]="!columnFilters.value?.filter[0]?.column"
                    class="btn btn-primary d-flex align-items-center font-size-13 my-3 mx-1" type="button"
                    (click)="resetFilters()">
                    Reset
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</div>
<div class="container-fluid" *ngIf="!create_quotation">
    <div class="row my-2 py-1 customised-sec">
        <div class="col-md-4 d-flex align-items-center height-max-content">
            <!-- <mat-icon class="text-primary">arrow_back_ios </mat-icon>
            <p class="m-0 text-primary fw-600 font-size-16 text-primary">{{ invoiceJson.back_label }}</p> -->
        </div>
        <div class="col-md-8 d-flex justify-content-end align-items-center ">
            <mat-form-field appearance="outline" class="me-2 mat-hint-none position-relative searching-box">
                <input style="outline: none" matInput placeholder={{invoiceJson.search_placeholder}}
                    [(ngModel)]="search_global" (ngModelChange)="searchSubject.next(search_global)" />
                <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
                        alt=""></mat-icon>
            </mat-form-field>
            <!-- <button (click)="create_quotation = true"
                class="btn btn-primary d-flex align-items-center py-2 font-size-14"><mat-icon>add</mat-icon>Create
                New</button> -->
            <div ngbDropdown *ngIf="featuresData?.invoice_add">
                <button class="btn btn-primary d-flex align-items-center me-3" id="addinvoice" ngbDropdownToggle *ngIf="getRolePermissions('finances','can_create')">
                    <mat-icon>add</mat-icon>
                    {{invoiceJson.label_add_invoice}}
                </button>
                <div ngbDropdownMenu="addinvoice">
                    <button ngbDropdownItem (click)="openCreateInvoice('ESTIMATE')">Estimate Invoice</button>
                    <button ngbDropdownItem (click)="openCreateInvoice('PROFORMA')">Proforma Invoice</button>
                    <button ngbDropdownItem (click)="openCreateInvoice('INVOICE')">Invoice</button>
                </div>
            </div>
            <!-- <mat-icon class="me-3 text-primary">local_offer</mat-icon>
            <mat-icon class="me-3 text-primary">cloud_upload</mat-icon>
            <mat-icon> reorder</mat-icon> -->
        </div>
    </div>
    <div class="flex-column justify-content-between d-flex custom-table-div-crm-height">
        <div class="table-responsive table-height fixed-table">
            <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="table_cols.length"
                [fromLocation]="'INVOICE'"></app-table-skeleton>
            <table *ngIf="!tableSkeletonLoader"
                class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                <thead>
                    <tr class="table-header-color">
                        <!-- <th scope="col">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                    </th> -->
                        <th scope="col" *ngFor="let col of table_cols">{{col}}</th>
                    </tr>
                </thead>
                <tbody *ngIf="!display_no_data">
                    <tr *ngFor="let item of invoiceArray; let i = index">
                        <!-- <td>
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(item) : null"
                            [checked]="selection.isSelected(item)"></mat-checkbox>
                    </td> -->
                        <td>
                            {{ item.display_id }}
                        </td>
                        <!-- <td>
                        {{ item.issue_date | date: 'M/d/yy'}}
                    </td> -->
                        <td>{{ item.billed_to[0]?.value }}</td>
                        <td>
                            {{ item.billed_to[1]?.value }}
                        </td>
                        <td>{{ item.billed_to[2]?.value }}</td>
                        <td>{{ item?.invoice_type }}</td>
                        <td>{{ item.source_type | titlecase}}</td>
                        <td>
                            <span *ngIf="price_symbol_postion === 'before'">{{ item.currency_symbol + item.total
                                }}</span>
                            <span *ngIf="price_symbol_postion !== 'before'">{{ item.total + item.currency_symbol
                                }}</span>
                        </td>
                        <td>
                            <span *ngIf="item.due_amount">
                                <span *ngIf="price_symbol_postion === 'before'">{{ item.currency_symbol +
                                    item.due_amount }}</span>
                                <span *ngIf="price_symbol_postion !== 'before'">{{ item.due_amount +
                                    item.currency_symbol}}</span>
                            </span>
                            <span *ngIf="!item.due_amount">-</span>
                        </td>
                        <td>{{ item.created_at | dateConversion : 'dateTime' }}</td>
                        <td>{{ item.creation_status }}</td>
                        <td class="cursor">
                            <div class="dropdown" ngbDropdown>
                                <mat-icon [matMenuTriggerFor]="menuadd" style="z-index: 99;">more_vert</mat-icon>
                                <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                                    <button mat-menu-item class="py-2" (click)="editInvoice(item)"
                                        *ngIf="item.invoice_type!=='INVOICE' && !item.proforma_to_invoice && getRolePermissions('finances','can_update')">
                                        Edit
                                    </button>
                                    <button mat-menu-item class="py-2" (click)="invoiceConvertion(item.id)"
                                        *ngIf="item.invoice_type==='PROFORMA' && !item.payment_online && !item.proforma_to_invoice && getRolePermissions('finances','can_update')">
                                        Convert to Invoice
                                    </button>
                                    <button mat-menu-item class="py-2"
                                        (click)="partialInvoice(partialInvoiceModal,item)"
                                        *ngIf="item.invoice_type==='PROFORMA' && item.proforma_to_invoice">
                                        Partial Invoice
                                    </button>
                                    <a class="d-flex align-items-center text-primary w-100 h-100"
                                        style="text-decoration: none;" [href]="item.invoice_pdf_link" target="_blank"
                                        download *ngIf="item.creation_status === 'COMPLETED'">
                                        <button mat-menu-item class="py-2">
                                            Download
                                        </button>
                                    </a>
                                    <button mat-menu-item class="py-2" *ngIf="item.creation_status === 'INITIATED'"
                                        (click)="getInvoices(1)">
                                        Refresh
                                    </button>
                                    <button mat-menu-item class="py-2" *ngIf="item.creation_status === 'FAILED'"
                                        (click)="retryInvoice(item.id)">
                                        Retry
                                    </button>
                                </mat-menu>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
        </div>
        <div>
            <mat-paginator [length]="total_count" [pageSize]="20" [pageIndex]="pageIndex" (page)="page($event)"
                aria-label="Select page" hidePageSize="true" showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>
</div>
<ng-container *ngIf="create_quotation">
    <app-create-invoice [invoice_type]="type_quotation" [invoice_id]="selectedInvoiceId"
        (roleback)="rolebackFunction()"></app-create-invoice>
</ng-container>

<ng-template #partialInvoiceModal let-modal>
    <div class="modal-header">
        <h6 class="textprimary fw-600">Partial Invoice</h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="mb-2">
            <mat-label>Due Amount:</mat-label>
            <span>{{invoice_due_amount}}</span>
        </div>
        <mat-label>Amount:</mat-label>
        <mat-form-field appearance="outline" class="me-2 mat-hint-none w-100">
            <input style="outline: none" matInput placeholder="Amount" [(ngModel)]="partial_amount" type="number"
                (keydown)="keyDownEvent($event)" />
        </mat-form-field>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="partialInvoiceCreation()">Save</button>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private http: HttpClient) { }

  getAllClients(page: number, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `${filter_param}=` : '';
    const params = new HttpParams()
      .set('page', page)
      // .set(filter_param ? filter_param : '', filter ? filter : '')
      .set('offset', 20);
    return this.http.get(`${environment.urlPrefix}${constant.clients}?${filter_param ? filter_param : ''}${filter ? filter : ''}`, { params });
  }
  getAllClientsForDropdown() {
    return this.http.get(`${environment.urlPrefix}${constant.clients}?dropdown=true`);
  }

  getAllProjectsByContact(contact_id: string, page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.client_projects}?contact_id=${contact_id}&page=${page}&limit=20`);
  }

  getAllProjectsByCompanyId(company_id: string, page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.client_projects}?source_id=${company_id}&page=${page}&limit=20`);
  }

  getAllTagsWithName(payload:any){
    return this.http.post(`${environment.urlPrefix}${constant.tags_with_name}`,payload);
  }

  getClientById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.clients}${id}/`);
  }

  createClient(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.clients}`, body);
  }

  updateClient(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.clients}${id}/`, body);
  }

  deleteClient(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.clients}${id}/`);
  }

  getProjectDetailsById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.client_projects}${id}/`);
  }
  getAllProjects(page?: number, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    if (page) {
      const params = new HttpParams()
        .set('page', page)
        // .set(filter_param ? filter_param : '', filter ? filter : '')
        .set('offset', 20);
      return this.http.get(`${environment.urlPrefix}${constant.client_projects}?${filter_param ? filter_param : ''}${filter ? filter : ''}`, { params });
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.client_projects}`);
    }
  }
  getClientProjects(id: string, page: number, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    const params = new HttpParams()
      .set('client_id', id)
      .set('page', page)
      // .set(filter_param ? filter_param : '', filter ? filter : '')
      .set('offset', 20);
    return this.http.get(`${environment.urlPrefix}${constant.client_projects}?${filter_param ? filter_param : ''}${filter ? filter : ''}`, { params });
  }
  createClientProject(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.client_projects}?client_id=${body.client_id}`, body);
  }
  getStoreId() {
    return this.http.get(`${environment.urlPrefix}${constant.store}`);
  }
  getStoreProducts(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.store}${id}` + '/products/');
  }
  deleteClientProject(client_id: string, project_id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.client_projects}${project_id}/`);
  }
  updateClientProject(project_id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.client_projects}${project_id}/`, body);
  }
  getAllProjectsBySearch(name: string,page?:number) {
    if(page){
      return this.http.get(`${environment.urlPrefix}${constant.client_projects}?name=${name}&page=${page}&offset=20`);
    }else{
      return this.http.get(`${environment.urlPrefix}${constant.client_projects}?name=${name}`);
    }
  }
  getClientsBySearch(name: string,page?:number) {
    if(page){
      return this.http.get(`${environment.urlPrefix}${constant.clients}?name=${name}&page=${page}&offset=20`);
    }else{
      return this.http.get(`${environment.urlPrefix}${constant.clients}?name=${name}&dropdown=true`);
    }
  }

  getAllClientFields() {
    return this.http.get(`${environment.urlPrefix}${constant.custom_client_model}`);
  }

  getAllClientProjectFields() {
    return this.http.get(`${environment.urlPrefix}${constant.custom_project_model}`);
  }

  getClientsCustomFilteredData(body: any, page: number) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_clients_filter}?page=${page}&offset=20`, body);
  }

  getProjectsCustomFilteredData(body: any, page: number) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_projects_filter}?page=${page}&offset=20`, body);
  }

  getTableColoums(type: string){
    return this.http.get(`${environment.urlPrefix}${constant.clients_table_coloums}?type=${type}`);
  }

  updateTableColumn(paylaod:any){
    return this.http.put(`${environment.urlPrefix}${constant.clients_table_coloums}`, paylaod);
  }
  // getAllProjects(project_id:any) {
  //   return this.http.get(`${environment.urlPrefix}`)
  // }

  //conversations

  getConversation(id: any) {
    return this.http.get(`${environment.urlPrefix}${constant.conversation}?source_id=${id}`);
  }

  postConversation(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.conversation}`, body);
  }

  worke_ai(body: any) {
    return this.http.post(`${environment.worke_ai_url}${constant.worke_ai}`, body);
  }

  getChatbotConversation(id: string, show_type: string,pipeline_id?: string) {
    if (show_type === 'lead') {
      return this.http.get(`${environment.messageUrl}${constant.chatbot_conversation}/leads/${id}`);
    } else if(show_type === 'project'){
      return this.http.get(`${environment.messageUrl}${constant.chatbot_conversation}/projects/${id}`);
    } else {
      return this.http.get(`${environment.messageUrl}${constant.chatbot_conversation}/pipelinecards/${id}?pipelineId=${pipeline_id}`);
    }
  }

  getChatbotConversationMessages(conversation_id: string, page_size: number) {
    return this.http.get(`${environment.messageUrl}${constant.chatbot_conversation}/${conversation_id}/messages?page=${page_size}&limit=10`);
  }

  chatbotSendMessage(body: any) { // API got changed(No more using)
    return this.http.post(`${environment.messageUrl}${constant.chatbot_conversation}/sendMessage/`, body);
  }
}

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/service/auth.service';
import { ChatService } from 'src/app/core/service/chat.services';

@Component({
  selector: 'app-bot-chat',
  templateUrl: './bot-chat.component.html',
  styleUrls: ['./bot-chat.component.scss']
})
export class BotChatComponent implements OnInit, OnDestroy, AfterViewInit {
  hidden = false;
  chatNavObj: any;
  iframeLoaded = false;
  @ViewChild('iframeRef', { static: true }) iframeRef!: ElementRef;
  private receiveMessage: ((event: any) => void);
  iframe: any;
  src: any;
  featuresData: any;
  loggedInUser: any;

  constructor(private router: Router, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer, private route: ActivatedRoute, private authService: AuthService, private chatServices: ChatService) {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getFeatues();
    this.spinner.show();
    if (this.router.getCurrentNavigation()?.extras?.state?.['routerState']) {
      this.chatNavObj = this.router.getCurrentNavigation()?.extras?.state?.['routerState'];
    }
    this.receiveMessage = (event: any) => {
      const sessionValues = {
        ...JSON.parse(sessionStorage.getItem('currentUser') ?? "{}")
      };
      if (event.data.message === "auth") {
        this.iframe?.contentWindow.postMessage({ type: "auth", data: sessionValues }, '*');
      } else if (event.data.message === "updateUrl") {
        sessionStorage.setItem('botchatredirectUrl', event.data.url);
        this.chatServices.updateCountsSub$.next('');
      } else if (event.data.message === "joinChat") {
        const routerState: any = {
          redirectUrl: event?.data?.url
        };
        sessionStorage.setItem('redirectUrl', event.data.url);
        this.router.navigate(['/live_chat'], { state: { routerState: routerState } });
      }
    };
  }
  ngOnInit(): void {
    // Parent page
    this.iframe = document.getElementById('bot-widget-value');

    window.addEventListener('message', this.receiveMessage, false);

    setTimeout(async () => {
      const iframeReload = this.iframeRef.nativeElement;
      iframeReload.src = this.iframe.src;
      await this.delay(500);
      this.iframeLoaded = true;
      this.spinner.hide();
    }, 3000);
  }
  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      this.route.queryParams.subscribe((params: any) => {
        if (params.folder === "inbox" && this.featuresData?.email_inbox) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/emails/inbox' : window.location.hostname.includes('demo.brexa.ai') ? 'https://demoautomation.brexa.ai/emails/inbox' : 'https://stageautomation.brexa.ai/emails/inbox');
        } else if (params.folder === "smartpopups" && this.featuresData?.smart_pop && (this.loggedInUser.data.role === 'ADMIN' || this.loggedInUser.data.role === 'MANAGER')) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/smartpopups' : window.location.hostname.includes('demo.brexa.ai') ? 'https://demoautomation.brexa.ai/smartpopups' : 'https://stageautomation.brexa.ai/smartpopups');
        } else if (params.folder === "smartforms" && this.featuresData?.smart_form) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/smartforms' : window.location.hostname.includes('demo.brexa.ai') ? 'https://demoautomation.brexa.ai/smartforms' : 'https://stageautomation.brexa.ai/smartforms/');
        } else if (params.folder === "apps" && this.featuresData?.apps && this.loggedInUser.data.role === 'ADMIN') {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/apps' : window.location.hostname.includes('demo.brexa.ai') ? 'https://demoautomation.brexa.ai/apps' : 'https://stageautomation.brexa.ai/apps');
        } else if (params.folder === "chat_bot" && this.featuresData?.bot_setup && this.loggedInUser.data.role === 'ADMIN') {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/chatbots' : window.location.hostname.includes('demo.brexa.ai') ? 'https://demoautomation.brexa.ai/chatbots' : 'https://stageautomation.brexa.ai/chatbots');
        } else if (params.folder === "push" && this.featuresData?.push_notification && this.loggedInUser.data.role === 'ADMIN') {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/push' : window.location.hostname.includes('demo.brexa.ai') ? 'https://demoautomation.brexa.ai/push' : 'https://stageautomation.brexa.ai/push');
        } else if (params.folder === "marketing" && this.featuresData?.marketing_automation && this.featuresData?.contacts_list && (this.loggedInUser.data.role === 'ADMIN' || this.loggedInUser.data.role === 'MANAGER')) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/marketing' : window.location.hostname.includes('demo.brexa.ai') ? 'https://demoautomation.brexa.ai/marketing' : 'https://stageautomation.brexa.ai/marketing');
        } else if (params.folder === "workflows" && this.featuresData?.workflow_automation) {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/workflows' : window.location.hostname.includes('demo.brexa.ai') ? 'https://demoautomation.brexa.ai/workflows' : 'https://stageautomation.brexa.ai/workflows');
        } else if (!params.folder && this.featuresData?.bot_chat && this.loggedInUser.data.role !== 'MANAGER' && this.loggedInUser.data.role !== 'STAFF') {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/chats/botchats' : window.location.hostname.includes('demo.brexa.ai') ? 'https://demoautomation.brexa.ai/chats/botchats' : 'https://stageautomation.brexa.ai/chats/botchats');
        } else {
          this.router.navigate(['/unauthorised']);
        }
      });
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

  ngAfterViewInit() {
    let url = sessionStorage.getItem('botchatredirectUrl');
    this.iframe.onload = () => {

      if (this.chatNavObj?.botchatredirectUrl && this.chatNavObj?.botchatredirectUrl !== "") {
        url = this.chatNavObj?.botchatredirectUrl;
      }
      setTimeout(() => {
        this.iframe?.contentWindow.postMessage({ type: "redirect", data: { redirectUrl: url } }, '*');
      }, 500);
    };
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.receiveMessage, false);
    this.chatServices.updateCountsSub$.next('');
    sessionStorage.removeItem('botchatredirectUrl');
  }
}

<div class="appointment-inner-screen newUI mt-1 col-12 d-flex ">
  <div class="appointment-info border-radius-16 col-3 me-3 height-webkit-fill auto-hide-scroll-bar overflow-auto"
    style="height: calc(100vh - 132px);">
    <div class="main-info border-radius-16 d-flex align-items-center flex-column">
      <div class="d-flex align-items-center flex-column px-3 pt-3 w-100"
        style="position: sticky; top: 0px;background:#f4f7f5;">
        <div style="width: 100px; height: 100px;"
          class="border-radius-50 mb-2 overflow-hidden d-flex align-items-center justify-content-center">
          <img src="{{appointmentdata?.consultation_id?.images[0]}}"
            onerror="this.src='../../../../assets/images/default_image.png'" alt="" width="100" height="100">
        </div>
        <div class="main-info-details d-flex align-items-center flex-column py-1" *ngIf="appointmentdata">
          <p class="label-text-clr font-size-16 fw-600 m-0 text-center">{{appointmentdata?.consultation_id.name}}</p>
          <div class="px-2 bg-medium fw-600 d-flex align-items-center justify-content-center width-fit-content mt-2">
            <mat-icon class="d-flex align-items-center">query_builder</mat-icon>
            <p class="m-0">
              {{appointmentdata?.consultation_id.duration}} min</p>
          </div>
        </div>
      </div>
      <p class="m-0 font-size-13 fw-400 mt-2 pt-1 text-center px-3" style="color: #5A5A5A;"
        [innerHTML]="appointmentdata?.consultation_id.description"></p>
    </div>
    <div class="appointment-type-info" *ngIf="appointmentdata">
      <ng-container
        *ngTemplateOutlet="list_view;context:{title:'Scheduler Type',titleValue:appointmentdata?.meeting_type,col:true}"></ng-container>
      <div *ngIf="appointmentdata?.meeting_type ==='DOOR_STEP'">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Amount',titleValue:appointmentdata?.billed_to[3]?.value + ' ' + appointmentdata?.billed_to[4]?.value,col:true}"></ng-container>
      </div>
      <div *ngIf="appointmentdata?.meeting_type ==='OFF_LINE'">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Location',titleValue:locationsDetails?.name + ' ' + locationsDetails?.location,col:true}"></ng-container>
      </div>
      <ng-container
        *ngTemplateOutlet="list_view;context:{title:'Date',titleValue:appointmentdata?.staff_timezone_date ? (appointmentdata?.staff_timezone_date | dateConversion) : '',col:true}"></ng-container>
      <ng-container
        *ngTemplateOutlet="list_view;context:{title:'Starts At',titleValue:appointmentdata?.staff_timezone_time,col:true}"></ng-container>
      <ng-container
        *ngTemplateOutlet="list_view;context:{title:'Time Zone',titleValue:appointmentdata?.time_zone,col:true}"></ng-container>
      <ng-container *ngIf="appointmentdata?.meeting_type ==='ON_LINE'">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Customer\'s Meeting URL',titleValue:appointmentdata?.online_meeting_medium === 'zoom' ? appointmentdata?.customer_twilio_meeting_url : appointmentdata?.online_meeting_medium !== 'zoom' ? appointmentdata?.video_meeting_url :
                                'Meeting Link not available, Please regenerate a meeting link',col:true}"></ng-container>
      </ng-container>
    </div>
    <div class="appointment-action-button p-3 d-flex align-items-center justify-content-between"
      *ngIf="!disabledMetting && appointmentdata?.meeting_type ==='ON_LINE' ">
      <button (mouseenter)="onMouseEnter()" (cdkCopyToClipboardCopied)="onClipboardCopy($event)"
        matTooltip="Copy to clipboard" matTooltipPosition="above" #tp="matTooltip" [cdkCopyToClipboard]="appointmentdata?.online_meeting_medium === 'zoom' ? appointmentdata?.staff_twilio_meeting_url :
          appointmentdata?.online_meeting_medium !== 'zoom' ? appointmentdata?.video_meeting_url :''"
        class="copy-link font-size-12 label-text-clr fw-500 py-2 d-flex align-items-center justify-content-center">
        <mat-icon style="width: 12px; height: 12px;" class="me-1 font-size-12 fw-600">content_copy</mat-icon>
        <span class="limit-line-length-1">Copy Link</span>
      </button>
      <div style="width: 47%;"
        [matTooltip]="isMeetingEnabled ? '' : 'Meeting link will active 10 minutes prior to the scheduled time.'">
        <button
          class="join-link btn font-size-12 w-100  label-text-clr fw-500 py-2 d-flex align-items-center justify-content-center"
          [disabled]="!isMeetingEnabled">
          <mat-icon style="color: white;width: 16px; height: 16px;" class="me-1 font-size-16 fw-600">videocam</mat-icon>
          <a [href]="appointmentdata?.online_meeting_medium === 'zoom' ? appointmentdata?.staff_twilio_meeting_url :
                                appointmentdata?.online_meeting_medium !== 'zoom' ? appointmentdata?.video_meeting_url :''"
            class="text-decoration-none" style="color: white;" target="_blank"> <span class="limit-line-length-1">Join
              Now</span></a>
        </button>
      </div>
    </div>
  </div>
  <div class="appointment-details col-9 border-radius-16 ms-1 flex-shrink">
    <div class="appointment-details-header py-2 px-3 d-flex align-items-center justify-content-between">
      <p class="m-0 font-size-18 fw-600 limit-line-length-1" style="color: #232323;">Scheduler Details</p>
      <div class="d-flex align-items-center">
        <div class="border-radius-8 appointment-source px-2 me-3"
          *ngIf="appointmentdata && appointmentdata?.source_type">
          <div class="text-center limit-line-length-1 font-size-14 d-flex align-items-center">
            <img src="assets/images/newUI/HOME/information_icon.svg" class="me-1" alt="">
            You got this source
            from
            <span class="fw-600 ms-1">{{appointmentdata?.source_type}}</span>
          </div>
        </div>
        <button (click)="reschedule()"
          *ngIf="appointmentdata?.appointment_status !== 'REJECTED' && appointmentdata?.appointment_status !== 'COMPLETED' && appointmentdata?.appointment_status !== 'NO_SHOW'"
          [disabled]="rescheduleBtnDisable"
          class="btn border-radius-8 bg-white limit-line-length-1 d-flex align-items-center me-3">
          <img src="assets/images/newUI/HOME/reload_icon.svg" class="me-2" alt="">
          Reschedule Now
        </button>
        <button class="btn border-radius-8 bg-white">
          <a [href]="appointmentdata?.invoice_media_url" target="_blank" download
            class="limit-line-length-1 d-flex align-items-center text-decoration-none" style="color: #09090B;">
            <img src="assets/images/newUI/HOME/download_icons.svg" class="me-2" alt="">
            Download Invoice
          </a>
        </button>
      </div>
    </div>
    <div class="appointment-details-info auto-hide-scroll-bar overflow-auto" style="height: calc(100vh - 186px);">
      <div>
        <div class="d-flex align-items-center p-3 mt-1">
          <img src="assets/images/newUI/HOME/contact_icon.svg" alt="">
          <p class="m-0 ps-2 font-size-16 fw-600" style="color: #232323;">Contact Details</p>
        </div>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Booked For',titleValue:appointmentdata?.contact_details?.first_name + ' ' +appointmentdata?.contact_details?.last_name}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Email Address',titleValue:appointmentdata?.contact_details?.email}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Phone Number',titleValue:appointmentdata?.contact_details?.phone_number ? appointmentdata?.contact_details?.ccode + ' ' + appointmentdata?.contact_details?.phone_number :''}"></ng-container>
      </div>
      <div>
        <div class="d-flex align-items-center p-3 mt-1">
          <img src="assets/images/newUI/HOME/contact_icon.svg" alt="">
          <p class="m-0 ps-2 font-size-16 fw-600" style="color: #232323;">Booked By</p>
        </div>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Booked By',titleValue:appointmentdata?.booked_by_details?.first_name + ' ' +appointmentdata?.booked_by_details?.last_name}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Email Address',titleValue:appointmentdata?.booked_by_details?.email}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Phone Number',titleValue:appointmentdata?.booked_by_details?.phone_number ? appointmentdata?.booked_by_details?.ccode + ' ' + appointmentdata?.booked_by_details?.phone_number :''}"></ng-container>
      </div>
      <div>
        <div>
          <div class="d-flex align-items-center p-3 mt-1">
            <img src="assets/images/newUI/HOME/calender_icon.svg" alt="">
            <p class="m-0 ps-2 font-size-16 fw-600" style="color: #232323;">Booking Details</p>
          </div>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Booked On',titleValue:(appointmentdata?.created_at | dateConversion)+ ' ' +(appointmentdata?.created_at | date: 'h:mm a')}"></ng-container>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Status',titleValue:appointmentdata?.appointment_status}"></ng-container>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Staff Assigned',titleValue:appointmentdata?.info?.staff?.staffName ? (appointmentdata?.info?.staff?.staffName | titlecase) : ''}"></ng-container>
        </div>
      </div>
      <div>
        <div class="d-flex align-items-center p-3 mt-1">
          <img src="assets/images/newUI/HOME/bill_icons.svg" alt="">
          <p class="m-0 ps-2 font-size-16 fw-600" style="color: #232323;">Billing Details</p>
        </div>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Booking ID',titleValue:appointmentdata?.display_booking_id ? appointmentdata.display_booking_id : ''}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Total Price',titleValue:appointmentdata?.amount_info?.price ? (appointmentdata.amount_info.price | decimal | currencyConversion : appointmentdata.currency.split(',')[1]) : ''}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Staff Price',titleValue:appointmentdata?.amount_info.staff_price ? (appointmentdata.amount_info.staff_price | decimal | currencyConversion : appointmentdata.currency.split(',')[1]) : ''}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Discount',titleValue:appointmentdata?.amount_info?.discount ? (appointmentdata?.amount_info?.discount | decimal | currencyConversion : appointmentdata.currency.split(',')[1]) : ''}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Tax',titleValue:appointmentdata?.amount_info?.tax ? (appointmentdata?.amount_info?.tax | decimal | currencyConversion : appointmentdata.currency.split(',')[1]) : ''}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Final Price',titleValue:appointmentdata?.amount_in_decimal ? (appointmentdata?.amount_in_decimal | decimal | currencyConversion : appointmentdata.currency.split(',')[1]) : ''}"></ng-container>
      </div>
      <div>
        <div class="reschedule" *ngIf="appointmentdata?.reschedule_history?.length > 0">
          <div class="d-flex align-items-center p-3 mt-1">
            <img src="assets/images/newUI/CRM/calender_icons.svg" class="me-1" alt="">
            <p class="m-0 ps-2 font-size-16 fw-600" style="color: #232323;">Scheduled History</p>
          </div>
        </div>
        <div class="d-flex align-items-center px-3 py-3"
          *ngFor="let history of appointmentdata?.reschedule_history;let i = index"
          [ngClass]="{'border-bottom': (i+1) < appointmentdata?.reschedule_history.length}">
          <p class="fw-700 d-flex align-items-center">
            {{history?.date | dateConversion}}
          </p>
          <span>:</span>
          <p class="fw-700 mb-0 d-flex align-items-center justify-content-end">
            {{history?.slot}}
          </p>
        </div>
      </div>
      <div class="questionnaire-sec p-3 mb-3" *ngIf="appointmentdata">
        <div class="tabs">
          <ul ngbNav #customNav="ngbNav" class="nav-pills mt-0" (navChange)="tabChange($event)"
            style="border: 1px solid #D8D8D8; width: fit-content;border-radius: 7px;" [(activeId)]="activeId">
            <li [ngbNavItem]="1" class="">
              <a ngbNavLink class="d-flex">
                <img class="me-2" src="assets/images/newUI/HOME/chat_icon.svg" alt="">
                <span class="d-sm-block font-size-12">Questionnaire</span>
              </a>
              <ng-template ngbNavContent>
                <p class="font-size-14 fw-500 label-text-clr m-0 pb-2 pt-3 mt-1 pe-3">Questions & Answer’s</p>
                <div class="questionnaire bg-white border-radius-8 p-3"
                  *ngIf="questionnaire_list && questionnaire_list.length > 0">
                  <div *ngFor="let item of questionnaire_list;let i = index"
                    class="bg-white border-radius-8 questionnaire mb-2" style="padding: 12px;">
                    <div class="mb-2 d-flex align-items-center">
                      <div class="bg-tag d-flex align-items-center width-fit-content px-2 py-1 font-size-14 me-2"
                        style="color: #18181B;border-radius: 99px;">
                        <img src="assets/images/newUI/HOME/mentioned_@_icon.svg" alt="">
                        <span class="ps-1 ">Q{{i+1}}.</span>
                      </div>
                      <p class="m-0font-size-14 fw-400" style="color: #52525A;">{{item?.question}}</p>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="bg-low d-flex align-items-center width-fit-content px-2 py-1 font-size-14 me-2"
                        style="color: #18181B;border-radius: 99px;">
                        <img src="assets/images/newUI/HOME/align_left_icon.svg" alt="">
                        <span class="ps-1">Ans</span>
                      </div>
                      <p class="font-size-14 fw-500 m-0"
                        *ngIf="item.question_type !== 'ATTACHMENT' && item.question_type !== 'FILE'">{{item.answer}}</p>
                      <button *ngIf="item.question_type === 'ATTACHMENT' || item.question_type === 'FILE'"
                        class="btn border-radius-8 p-1 bg-white">
                        <a [href]="item.answer" target="_blank" download
                          class="limit-line-length-1 d-flex align-items-center text-decoration-none font-size-12"
                          style="color: #09090B;">
                          <img src="assets/images/newUI/HOME/download_icons.svg" class="me-2" alt="">
                          Download
                        </a>
                      </button>
                      <!-- <h6 *ngIf="item.question_type === 'ATTACHMENT' || item.question_type === 'FILE'" class="d-flex"><span class="fw-600">Download
                          File: &nbsp;</span>
                        <a href="{{item.answer}}" target="_blank" class="text-dark fw-medium" download>
                          <mat-icon style="color: rgb(0, 0, 0);">file_download_outlined</mat-icon>
                        </a>
                      </h6> -->
                    </div>
                    <!-- <p class="font-size-15 fw-600">{{i+1}}. &nbsp;{{item?.question}}</p>
                    <h6 *ngIf="item.question_type !== 'ATTACHMENT'"><span class="fw-600">Answer:</span>
                      {{item.answer}}</h6>
                    <h6 *ngIf="item.question_type === 'ATTACHMENT'" class="d-flex"><span class="fw-600">Download
                        File: &nbsp;</span>
                      <a href="{{item.answer}}" target="_blank" class="text-dark fw-medium" download>
                        <mat-icon style="color: rgb(0, 0, 0);">file_download_outlined</mat-icon>
                      </a>
                    </h6> -->
                  </div>
                </div>
                <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" class="">
              <a ngbNavLink class="d-flex">
                <img class="me-2" src="assets/images/newUI/HOME/zap_icon.svg" alt="">
                <span class="d-sm-block font-size-12">Snooze</span>
              </a>
              <ng-template ngbNavContent>
                <p class="p-5 text-center">Coming Soon...</p>
              </ng-template>
            </li>
            <li [ngbNavItem]="3" class="">
              <a ngbNavLink class="d-flex">
                <img class="me-2" src="assets/images/newUI/HOME/small_file_icon.svg" alt="">
                <span class="d-sm-block font-size-12">Files</span>
              </a>
              <ng-template ngbNavContent>
                <div class="row mt-3 pt-4" *ngIf="contactActive">
                  <div class="px-3" style="border-bottom: 1px dashed #E1E0E0;">
                    <div class="col-12">
                      <ngx-dropzone (change)="onSelect($event)" [multiple]="false" [draggable]="false"
                        id="media-dropzone" class="file-upload-sec border-radius-8"
                        style="height: 80px; min-height: 80px;"
                        accept="image/jpeg,image/jpg,image/png,image/gif,video/mp4,audio/mp3,audio/mpeg,video/mov,video/quicktime,text/plain,.doc,.docx,.txt,.pdf,.csv,.xlsx,.xls">
                        <ngx-dropzone-label class="m-0 mx-3 font-size-14"> {{filesJSON.dropfile_placeholder_text1}}
                          <span class="text-primary fw-500">{{filesJSON.dropfile_placeholder_text2}}</span>
                          {{filesJSON.dropfile_placeholder_text3}}
                        </ngx-dropzone-label>
                        <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)"
                          ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                        </ngx-dropzone-image-preview>
                      </ngx-dropzone>
                    </div>
                    <p class="fw-400 mb-0 font-size-12 mt-2 pb-2" style="color: #717179;">Files of JPG, JPEG, PNG,
                      GIF,
                      PDF, DOC, DOCX, TXT, CSV, XLSX, XLS, MP3 MP4, or
                      MOV
                      formats with max of
                      15MB
                      are allowed.</p>
                    <div class="d-flex my-3">
                      <div class="" style="width:40px;height:40px" *ngIf="show_loader">
                        <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                          enable-background="new 0 0 0 0" xml:space="preserve">
                          <path fill="black"
                            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"
                              from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                          </path>
                        </svg>
                      </div>
                      <button *ngIf="!show_loader"
                        class="btn border-radius-8 bg-primary fw-500 font-size-14 text-white action-btn" type="button"
                        [disabled]="!this.files[0]" (click)="onUpload()">Upload File</button>
                    </div>
                  </div>
                </div>
                  <div *ngIf="fileId"
                    class="dummy-search-box d-flex border-radius-12 width-fit-content px-2 py-2 mt-2 align-items-center">
                    <p class="m-0 me-2">{{'Filtered By: Selected from Notifications'}}</p>
                    <button type="button" class="btn-close" aria-label="Close" (click)="clearFilter()"></button>
                  </div>
                <div class="files-div-height d-flex flex-column justify-content-between new_design_2024 pt-3">
                  <div class="table-responsive table-height h-100">
                    <table class="table align-middle table-nowrap table-hover mb-0 file-table ">
                      <thead>
                        <tr class="table-header-color">
                          <th scope="col">{{filesJSON.col_one_label}}</th>
                          <th scope="col">{{filesJSON.col_seven_label}}</th>
                          <th scope="col">{{filesJSON.col_two_label}}</th>
                          <th scope="col">
                            {{filesJSON.col_five_label}}</th>
                          <th scope="col">{{filesJSON.col_four_label}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of filesListArray; let i= index;">
                          <td>
                            <div class="d-flex align-items-center list-name-sec">
                              <img src="{{item.media_url}}" (error)="onErrorLoadImage($event,presignedurl,item.name)"
                                height="20px" width="20px">
                              <a href="{{item.media_url}}" target="_blank"
                                class="position-relative ms-2 text-dark fw-medium limit-line-length-1" download>
                                {{item.name}}
                              </a>
                            </div>
                          </td>
                          <td>
                            <span class="limit-line-length-1">
                              {{item?.created_by_name || "NA" }}
                            </span>
                          </td>
                          <td>
                            <span class="limit-line-length-1">
                              {{item.updated_at | dateConversion }} {{item?.updated_at | date: 'h:mm a'}}
                            </span>
                          </td>
                          <td
                            [ngStyle]="{'opacity':item?.is_editable ? '1' : '0.5','cursor' :item?.is_editable ? 'pointer':'auto'}">
                            <label class="switch" (click)="!(item?.is_editable) ? $event.preventDefault() : ''">
                              <input type="checkbox" [id]="item.id" [(ngModel)]="item.is_visible"
                                (change)="toggleVisibility($event,item)">
                              <span class="slider round"
                                [ngStyle]="{'cursor' :item?.is_editable ? 'pointer':'auto'}"></span>
                            </label>
                          </td>
                          <td>
                            <mat-icon class="cursor d-flex align-items-center"
                              [ngStyle]="{'opacity':item.is_editable ? '1' : '0.5','cursor' :item.is_editable ? 'pointer':'auto'}"
                              (click)="item.is_editable && confirmDeleteFile(item.id)">delete</mat-icon>
                          </td>
                        </tr>
                        <tr *ngIf="filesListArray && filesListArray.length === 0">
                          <div style="transform: translateX(100%); background: transparent;">
                            <app-nodata-found></app-nodata-found>
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <mat-paginator [length]="filesTotal" [pageSize]="20" (page)="page($event)" aria-label="Select page"
                      showFirstLastButtons>
                    </mat-paginator>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="customNav"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #list_view let-title="title" let-value="titleValue" let-color="color" let-colValue="col">
  <div class="no-gutters bottom-border p-3 cursor d-flex">
    <div class="info-accord" [ngClass]="colValue ? 'col-6' : 'col-2'">
      <p class="text-inner-info m-0 limit-line-length-1" [matTooltip]="title">{{title}}</p>
    </div>
    <!-- <div class="col-md-1"></div> -->
    <div class="info-accord d-flex" [ngClass]="colValue ? 'col-6' : 'col-10'">
      <div class="text-name d-flex text-wrap w-100">
        <span class="pe-1 fw-600">:</span>
        <p class="text-name text-ellipsis cursor m-0" [matTooltip]="value"
          [ngClass]="title === 'Final Price' ? 'fw-900' : 'fw-500'"
          *ngIf="title !== 'Scheduler Type' && title !== 'Customer\'s Meeting URL' && title !== 'Status' && title !== 'Staff Assigned'">
          {{value}}
        </p>
        <div *ngIf="title === 'Scheduler Type'">
          <p *ngIf="value"
            [ngStyle]="{'color': value === 'OFF_LINE' ? '#752522' : value === 'DOOR_STEP' ? '#73321B' : '#302E7C', 'background': value === 'OFF_LINE' ? '#F9E3E2' : value === 'DOOR_STEP' ? '#FCEED8' : '#E1E7FD' }"
            class="text-name text text-capitalize m-0 d-inline-flex align-items-center px-1 border-radius-50">
            {{value.split("_").join("")}}
          </p>
        </div>
        <div *ngIf="title === 'Status'">
          <mat-form-field appearance="outline" class="status-select mat-hint-none"
            *ngIf="value==='WAITING_FOR_ACCEPTANCE'">
            <mat-icon class="d-inline-flex align-items-center p-0 ps-3" *ngIf="value !=='WAITING_FOR_ACCEPTANCE'"
              [ngClass]="{'dot-approved': value === 'ACCEPTED','dot-pending': value === 'NO_SHOW','dot-rejected': value === 'REJECTED'}"
              style="font-size: 9px;" matPrefix>circle</mat-icon>
            <mat-select [(ngModel)]="appointmentdata.appointment_status" placeholder="Select"
              (selectionChange)="onStatusChange(lostreason)" [disabled]="!contactActive || rescheduleBtnDisable">
              <mat-option value="ACCEPTED">Accept</mat-option>
              <mat-option value="REJECTED">Reject</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="status-select mat-hint-none" *ngIf="value === 'ACCEPTED'">
            <mat-icon class="d-inline-flex align-items-center p-0 px-2"
              [ngClass]="{'dot-approved': value === 'ACCEPTED','dot-pending': value === 'NO_SHOW','dot-rejected': value === 'REJECTED'}"
              style="font-size: 9px; width: auto; height: auto;" matPrefix>circle</mat-icon>
            <mat-select [(ngModel)]="appointmentdata.appointment_status" (selectionChange)="onStatusChange()"
              [disabled]="!contactActive">
              <mat-option value="ACCEPTED">Accepted</mat-option>
              <mat-option value="COMPLETED">Completed</mat-option>
              <mat-option value="NO_SHOW">No Show</mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="value!=='ACCEPTED' && value!=='WAITING_FOR_ACCEPTANCE'"
            class="badge-soft-info  fw-500 text-center font-size-14 px-3 text-capitalize text-ellipsis"
            [ngClass]="{'bg-green':value === 'COMPLETED','bg-high':value === 'REJECTED','bg-medium':value === 'NO_SHOW'}">
            {{value | titlecase}}
          </div>
        </div>
        <div *ngIf="title === 'Staff Assigned'" class="d-flex align-items-center"
          [ngClass]="value ? 'assigned-staff' :''">
          <img src="assets/images/newUI/HOME/contact_icon.svg" alt="" *ngIf="value">
          <p class="m-0 ps-2 font-size-14 fw-600" [ngStyle]="{'color':value ? '#73321B' : ''}">{{value || 'NA'}}</p>
        </div>
        <div *ngIf="title === 'Customer\'s Meeting URL'" class="d-flex text-name w-100 cursor m-0 align-items-center">
          <p class="m-0 ps-2 font-size-14 fw-600 limit-line-length-1" style="width: 80%;">{{value || 'NA'}}</p>
          <mat-icon style="width: 12px; height: 12px;" class="ms-1 font-size-12 fw-400"
            (mouseenter)="onCustomerMouseEnter()" (cdkCopyToClipboardCopied)="onCustomerClipboardCopy($event)"
            matTooltip="Copy to clipboard" matTooltipPosition="above" #customer="matTooltip"
            *ngIf="appointmentdata?.online_meeting_medium"
            [cdkCopyToClipboard]="appointmentdata?.online_meeting_medium === 'zoom' ? appointmentdata?.customer_twilio_meeting_url : appointmentdata?.online_meeting_medium !== 'zoom' ? appointmentdata?.video_meeting_url : ''">content_copy</mat-icon>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #lostreason let-modal>
  <div class="modal-header">
      <h5 class="modal-title text-primary">
          Lost Reason
      </h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modalService.dismissAll()"></button>
  </div>
  <div class="modal-body">
      <div class="mb-3">
          <label>Reason</label>
          <mat-form-field appearance="outline" class="w-100 mat-hint-none">
              <input style="outline: none" matInput placeholder="Enter your reason" [(ngModel)]="lostReason" />
          </mat-form-field>
      </div>
      <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-primary" (click)="lostReasonSave()">
              Save
          </button>
      </div>
  </div>
</ng-template>
export interface MenuItem {
  id?: number;
  label?: string;
  icon?: string;
  image?: string;
  link?: string;
  subItems?: any;
  isTitle?: boolean;
  badge?: any;
  slug?: any;
  parentId?: number;
  isLayout?: boolean;
  color_img?: string;
  launchDlKeys?:string[];
  isEnable?:boolean;
  white_image?:string;
}

export const MENU: MenuItem[] = [
  {
    id: 2,
    label: 'CRM',
    icon: 'folder_shared',
    link: '/crm/contacts',
    slug: 'crm',
    image: './assets/images/newUI/MENU/crm_icon.svg',
    color_img: './assets/images/crm_blue.svg',
    white_image: './assets/images/newUI/MENU/crm_icon_white.svg',
    isEnable: true,
    subItems: [
      {
        id: 3,
        label: 'Leads',
        link: '/crm/leads',
        slug: 'leads',
        name:['enquiry'],
        parentId: 2
      },
      {
        id: 5,
        label: 'Contacts',
        link: '/crm/contacts',
        slug: 'leads',
        name: ['contact'],
        parentId: 2
      },
      {
        id: 6,
        label: 'Companies',
        link: 'crm/companies',
        slug: 'leads',
        name: ['company'],
        parentId: 2
      }
    ]
  },
  {
    id: 1,
    label: 'Dashboard',
    icon: 'home',
    link: '/home',
    slug: 'dashboard',
    image: './assets/images/newUI/MENU/home_icon.svg',
    color_img: './assets/images/Color_Home_Icon.svg',
    white_image:'./assets/images/newUI/MENU/home_icon_white.svg',
    isEnable: true
  },
  {
    id: 4,
    label: 'Flows',
    icon: 'folder_shared',
    link: 'pipelines/deals',
    slug: 'deals',
    image: './assets/images/newUI/MENU/flow_icon.svg',
    color_img: './assets/images/newUI/MENU/flow_icon_color.svg',
    white_image: './assets/images/newUI/MENU/flow_icon_white.svg',
    launchDlKeys: ['pipeline'],
    isEnable: true
  },
  {
    id: 7,
    label: 'Clients',
    icon: 'people',
    link: '/clients/clients',
    slug: 'clients',
    image: './assets/images/newUI/MENU/client_icon.svg',
    color_img: './assets/images/newUI/MENU/client_icon_color.svg',
    white_image: './assets/images/newUI/MENU/client_icon_white.svg',
    isEnable: true,
    subItems: [
      {
        id: 8,
        label: 'Clients',
        link: '/clients/clients',
        slug: 'clients',
        name: ['client'],
        parentId: 7
      },
      {
        id: 9,
        label: 'Client Projects',
        link: '/clients/client-projects',
        slug: 'client projects',
        name: ['projects'],
        parentId: 7
      }
    ]
  },
  {
    id: 10,
    label: 'SetUp',
    icon: '',
    link: '/setup',
    slug: 'setup',
    image: './assets/images/newUI/MENU/setup_icon.svg',
    color_img: './assets/images/newUI/MENU/setup_icon_color.svg',
    white_image: './assets/images/newUI/MENU/setup_icon_white.svg',
    isEnable: true
  },
  // {
  //   id: 11,
  //   label: 'Inbox',
  //   icon: '',
  //   link: '/inbox/orders',
  //   slug: 'inbox',
  //   image: './assets/images/Inbox_Icon.svg',
  // subItems: [
  //   {
  //     id: 12,
  //     label: 'Appointments',
  //     link: '/inbox/appointments',
  //     slug: 'appointments',
  //     parentId: 9
  //   },
  //   {
  //     id: 13,
  //     label: 'Orders',
  //     link: '/inbox/orders',
  //     slug: 'orders',
  //     parentId: 9
  //   }
  // ]
  // },
  // {
  //   id: 17,
  //   label: 'Finance',
  //   icon: 'monetization_on',
  //   link: '/finance',
  //   slug: 'finance',
  //   image: './assets/images/Finance_Icon.svg',
  //   color_img: './assets/images/color_finance_Icon.svg'
  // },
  {
    id: 14,
    label: 'Live Chat',
    icon: 'chat',
    link: '/live_chat',
    slug: 'live_chat',
    image: './assets/images/newUI/MENU/live-chat_icon.svg',
    color_img: './assets/images/live-chat1-color.svg',
    white_image: './assets/images/newUI/MENU/live-chat_icon_white.svg',
    launchDlKeys: ['live_chat'],
    isEnable: true
  },
  {
    id: 14,
    label: 'UniBox',
    icon: 'unibox',
    link: '/unibox',
    slug: 'unibox',
    image: './assets/images/color_automation.svg',
    color_img: './assets/images/color_automation.svg',
    white_image: './assets/images/automation.svg',
    launchDlKeys: ['uni_box'],
    isEnable: true
  },
  {
    id: 28,
    label: 'Bot Chat',
    icon: 'chat',
    link: '/bot_chat',
    slug: 'bot_chat',
    image: './assets/images/newUI/MENU/bot-chat_icon.svg',
    color_img: './assets/images/color_chat_Icon.svg',
    white_image: './assets/images/newUI/MENU/bot-chat_icon_white.svg',
    launchDlKeys: ['bot_chat'],
    isEnable: true
  },
  {
    id: 25,
    label: 'Messages',
    icon: 'chat',
    link: '/conversation',
    slug: 'conversation',
    image: './assets/images/newUI/MENU/message_icon.svg',
    color_img: './assets/images/conversations-color.svg',
    white_image: './assets/images/newUI/MENU//message_icon_white.svg',
    launchDlKeys: ['conversation'],
    isEnable: true
  },
  {
    id: 21,
    label: 'Boards',
    icon: 'dashboard',
    link: '/taskboard',
    slug: 'taskboard',
    image: './assets/images/task-board.svg',
    color_img: './assets/images/task-board-color.svg',
    white_image: './assets/images/task-board-white.svg',
    launchDlKeys: ['taskboard']
  },
  // {
  //   id: 15,
  //   label: 'Campaign',
  //   icon: 'campaign',
  //   link: '/campaigns/campaign',
  //   slug: 'campaign',
  //   image: './assets/images/Campaign_Icon.svg',
  //   color_img: './assets/images/campaign_blue.svg',
  //   subItems: [
  //     {
  //       id: 23,
  //       label: 'Campaign',
  //       link: '/campaigns/campaign',
  //       slug: 'campaign',
  //       name: 'campaign',
  //       parentId: 15
  //     },
  //     {
  //       id: 24,
  //       label: 'Contacts List',
  //       link: '/campaigns/group',
  //       slug: 'list',
  //       name: 'contacts_list',
  //       parentId: 15
  //     }
  //   ]
  // },
  // {
  //   id: 15,
  //   label: 'Campaign',
  //   icon: 'campaign',
  //   link: '/campaign',
  //   slug: 'campaign',
  //   image: './assets/images/Campaign_Icon.svg',
  //   color_img: './assets/images/campaign_blue.svg',
  //   subItems: [
  //     {
  //       id: 23,
  //       label: 'Campaign',
  //       link: '/campaign',
  //       slug: 'campaign',
  //       parentId: 15
  //     },
  //     {
  //       id: 24,
  //       label: 'Contacts List',
  //       link: '/campaign/group',
  //       slug: 'list',
  //       parentId: 15
  //     }
  //   ]
  // },
  // {
  //   id: 16,
  //   label: 'Reports',
  //   icon: 'pie_chart',
  //   link: '/reports',
  //   slug: 'reports',
  //   image: './assets/images/Report_Icon.svg',
  //   color_img: './assets/images/color_reports.svg'
  // },
  // {
  //   id: 20,
  //   label: 'Automation',
  //   icon: 'monetization_on',
  //   link: '/automation',
  //   slug: 'automation',
  //   image: './assets/images/automation.svg',
  //   color_img: './assets/images/color_automation.svg'
  // },
  {
    id: 27,
    label: 'More',
    icon: 'more_horiz',
    link: '/finance',
    slug: 'more',
    image: './assets/images/more_hover.svg',
    color_img: './assets/images/more.png',
    white_image: './assets/images/more_hover.svg',
    isEnable: true,
    subItems: [
      {
        id: 17,
        label: 'Finance',
        link: '/finance',
        slug: 'finance',
        icon: 'monetization_on',
        image: './assets/images/Finance_Icon.svg',
        color_img: './assets/images/color_finance_Icon.svg',
        name: ['finance'],
        parentId: 27
      },
      {
        id: 16,
        label: 'Reports',
        link: '/reports',
        slug: 'reports',
        icon: 'pie_chart',
        image: './assets/images/Report_Icon.svg',
        color_img: './assets/images/color_reports.svg',
        name: ['reports'],
        parentId: 27
      },
      {
        id: 26,
        label: 'Teamchat',
        icon: 'chat',
        link: '/teamchat',
        slug: 'team chat',
        image: './assets/images/newUI/MENU/team-chat_icon.svg',
        color_img: './assets/images/color-team-chat.svg',
        white_image: './assets/images/newUI/MENU/team-chat_icon_white.svg',
        name: ['teamchat'],
        isEnable: true,
        parentId: 27
      },
      {
        id: 29,
        label: 'File Manager',
        icon: 'dashboard',
        link: '/filemanager',
        slug: 'filemanager',
        image: './assets/images/task-board.svg',
        color_img: './assets/images/task-board-color.svg',
        name: ['file_manager'],
        parentId: 27
      }
    ]
  }
  // {
  //   id: 21,
  //   label: 'Taskboard',
  //   icon: 'dashboard',
  //   link: '/taskboard',
  //   slug: 'taskboard',
  //   image: './assets/images/task-board.svg',
  //   color_img: './assets/images/task-board-color.svg'
  // }
];

export const header = [
  {
    text: "You are in Free plan.",
    text2: "Upgrade or Extend Trial",
    input_search: "Search here",
    profile_btn: 'Profile',
    logout_btn: 'Logout'
  }
];

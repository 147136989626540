import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-objects-form-builder',
  templateUrl: './objects-form-builder.component.html',
  styleUrls: ['./objects-form-builder.component.scss']
})
export class ObjectsFormBuilderComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('iframeRef', { static: true }) iframeRef!: ElementRef;
  private receiveMessage: ((event: any) => void);
  iframe: any;
  src: any;
  iframeLoaded = false;

  constructor(private sanitizer: DomSanitizer, private spinner: NgxSpinnerService, private activatedRoute: ActivatedRoute) {
    this.receiveMessage = (event: any) => {
      const sessionValues = {
        ...JSON.parse(sessionStorage.getItem('currentUser') ?? "{}")
      };
      if (event.data.message === "auth") {
        this.iframe?.contentWindow.postMessage({ type: "auth", data: sessionValues }, '*');
      } else if (event.data.message === "updateUrl") {
        sessionStorage.setItem('redirectUrl', event.data.url);
      }
    };
  }

  ngOnInit(): void {
    this.iframe = document.getElementById('object-widget-value');
    window.addEventListener('message', this.receiveMessage, false);
    this.activatedRoute.queryParams.subscribe((data: any) => {
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? `https://automations.brexa.ai/dynamicforms/${data?.object_id}` : window.location.hostname.includes('demo.brexa.ai') ? `https://demoautomation.brexa.ai/dynamicforms/${data?.object_id}` : `https://stageautomation.brexa.ai/dynamicforms/${data?.object_id}`);
    });
    this.spinner.show();
    setTimeout(async () => {
      const iframeReload = this.iframeRef.nativeElement;
      iframeReload.src = this.iframe.src;
      await this.delay(500);
      this.iframeLoaded = true;
      this.spinner.hide();
    }, 3000);
  }

  ngAfterViewInit() {
    const url = sessionStorage.getItem('redirectUrl');
    this.iframe.onload = () => {
      setTimeout(() => {
        this.iframe?.contentWindow.postMessage({ type: "redirect", data: { redirectUrl: url } }, '*');
      }, 500);
    };
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.receiveMessage, false);
    sessionStorage.removeItem('redirectUrl');
  }
}

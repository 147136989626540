<div class="modal-header">
    <h5 class="modal-title fw-600 text-capitalize">{{ editCard ? pipelineJson.update_label :
        pipelineJson.add_label}}
        {{selectedPipelineData ? (selectedPipelineData[0].card_name ? selectedPipelineData[0].card_name :
        pipelineJson.heading_label): pipelineJson.heading_label }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancelModal()"></button>
</div>

<div *ngIf="showErrorMessage" class="px-3 pb-0 pt-1" style="box-shadow: 0 4px 14px #e1e1e1;">
    <div>
        <p class="m-0 font-size-13"> <span class="text-danger font-size-13 fw-500">NOTE : </span> You are not
            Authorised to view or use
            <ng-container *ngFor="let fields of revokedSysFieldsList | keyvalue; let i = index">
                <span class="text-danger  fw-600" *ngIf="fields.value.is_visible">{{ fields.key.replace("_", " ") |
                    titlecase }}{{ i < (revokedSysFieldsList | keyvalue).length - 1 ? ', ' : '' }}</span>
            </ng-container>
            feature. Please remove them as required field from <span class="font-size-13 fw-600">Settings > Custom
                Fields > Flow > System field table</span>.
        </p>
    </div>
</div>

<div class="modal-body">
    <form [formGroup]="pipelineForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{pipelineJson.label_name}} <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select  style="outline: none" [disabled]="editCard ? true : false"
                        placeholder="{{pipelineJson.label_name}}" formControlName="pipeline"
                        (selectionChange)="onChangePipeline($event)">
                        <mat-option *ngFor="let item of pipeline_list"
                            [disabled]="editCard ? true : false || pipelineData ? true : false"
                            [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['pipeline'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['pipeline'].errors['required']"> {{pipelineJson.label_name}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{pipelineJson.label_stage}} <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select style="outline: none" placeholder="{{pipelineJson.label_stage}}" formControlName="stage"
                        (selectionChange)="updatePipelineCard();getStaffList()">
                        <mat-option *ngFor="let item of selected_pipeline_stages"
                            [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['stage'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['stage'].errors['required']"> {{pipelineJson.label_stage}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{selectedPipelineData ? (selectedPipelineData[0].card_name ?
                    selectedPipelineData[0].card_name :
                    pipelineJson.heading_label): pipelineJson.heading_label }} Name <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="text" placeholder="{{selectedPipelineData ? (selectedPipelineData[0].card_name ? selectedPipelineData[0].card_name :
                        pipelineJson.heading_label): pipelineJson.heading_label }} Name" formControlName="title"
                        (blur)="updatePipelineCard()">
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['title'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['title'].errors['required']"> {{selectedPipelineData ?
                            (selectedPipelineData[0].card_name ? selectedPipelineData[0].card_name :
                            pipelineJson.heading_label): pipelineJson.heading_label }} Name
                            {{errorJson.required}}</span>
                        <span *ngIf="f['title'].errors['pattern']"> {{selectedPipelineData ?
                            (selectedPipelineData[0].card_name ? selectedPipelineData[0].card_name :
                            pipelineJson.heading_label): pipelineJson.heading_label }}
                            {{errorJson.invalid}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{pipelineJson.contact_name}} <small class="text-danger">*</small></label>
                <ng-select class="form-control" [items]="contactsList" bindLabel="first_name" [addTag]="addNewName"
                    [multiple]="false" (change)="onContactChange($event,'name')" placeholder={{pipelineJson.contact_name}}
                    formControlName="contact_first_name" (search)="searchSubject.next({text: $event.term, type: 'name'})"
                    (clear)="clearContactFields($event)">
                </ng-select>
                <div class="error d-flex mt-2" *ngIf="submitted && f['contact_first_name'].errors">
                    <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                    <span *ngIf="f['contact_first_name'].errors['required']"> {{pipelineJson.contact_name}}
                        {{errorJson.required}}</span>
                </div>
                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-select placeholder="{{pipelineJson.contact_name}}" formControlName="contact"
                        (selectionChange)="onContactChange($event.value)">
                        <mat-option *ngFor="let item of contactsList" [value]="item.id">
                            {{item.first_name}}
                        </mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['contact'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['contact'].errors['required']"> {{pipelineJson.contact_name}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field> -->
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{pipelineJson.email}} <small class="text-danger" *ngIf="hasRequiredValidator('email')">*</small></label>
                <ng-select class="form-control" [items]="contactsList"
                    bindLabel="email" [addTag]="addNewEmail" [multiple]="false" (change)="onContactChange($event,'email')"
                    placeholder="Email" (search)="searchSubject.next({text: $event.term, type: 'email'})" formControlName="email"
                    (clear)="clearContactFields($event)">
                </ng-select>
                <div *ngIf="submitted && f['email'].errors"
                    class="text-danger error font-size-12 d-flex align-items-center">
                    <mat-icon class="font-size-12 d-flex align-items-center w-3 me-1">info</mat-icon>
                    <span *ngIf="f['email'].errors['required']">{{pipelineJson.email}}
                        {{errorJson.required}}</span>
                    <span *ngIf="f['email'].errors['pattern']">{{errorJson.invalid}} {{pipelineJson.email}}</span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 ">{{pipelineJson.phone_number}} <small class="text-danger" *ngIf="hasRequiredValidator('phone_number')">*</small> </label>
                <div class="d-flex">
                    <mat-form-field appearance="outline" class="me-1 mat-hint-none">
                        <mat-select placeholder="Country code" formControlName="ccode">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredBanks | async"
                                [ngClass]="{'d-none': country.IAC === ''}"
                                value={{country.IAC}}>{{country.IAC}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-select class="form-control" [items]="contactsList" style="height: fit-content;"
                        bindLabel="phone_number" [addTag]="addNewphonenumber" [multiple]="false" (change)="onContactChange($event,'phone_number')"
                        placeholder="Phone Number" (search)="searchSubject.next({text: $event.term, type: 'phone_number'})" formControlName="phone_number"
                        (clear)="clearContactFields($event)">
                    </ng-select>
                </div>
                <div *ngIf="submitted && f['phone_number'].errors"
                    class="text-danger error font-size-12 d-flex align-items-center">
                    <mat-icon class="font-size-12 d-flex align-items-center w-3 me-1">info</mat-icon>
                    <span *ngIf="f['phone_number'].errors['required']">Phone number
                        {{errorJson.required}}</span>
                    <span *ngIf="f['phone_number'].errors['pattern'] || f['phone_number'].errors['minlength'] || f['phone_number'].errors['maxlength']">{{errorJson.invalid}} phone number</span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{pipelineJson.due_date}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="text" placeholder="{{pipelineJson.due_date}}" matInput
                        [matDatepicker]="picker" formControlName="due_date" [min]="minDate"
                        (dateChange)="updatePipelineCard()">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <!-- <mat-error class="error text-danger d-flex" *ngIf="submitted && f['due_date'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['due_date'].errors['required']"> {{pipelineJson.due_date}}
                            {{errorJson.required}}</span>
                    </mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="pipelineForm.value.price_on">
                <label class="fw-600 mb-2 ">{{pipelineJson.budget}} </label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="text" placeholder="{{pipelineJson.budget}}" formControlName="price"
                        (blur)="updatePipelineCard()">
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['price'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['price'].errors['pattern']"> {{pipelineJson.budget}}
                            {{errorJson.invalid}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{pipelineJson.label_source}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="channel" placeholder={{pipelineJson.label_source}}>
                        <mat-option *ngFor="let item of channels"
                            [value]="item.value">{{item.display_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 ">{{pipelineJson.label_owner}} <small class="text-danger" *ngIf="hasRequiredValidator('owner')">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="owner" placeholder={{pipelineJson.label_owner}}
                        (selectionChange)="updatePipelineCard()">
                        <mat-option *ngFor="let member of staffMembers" [value]="member.id">{{member.fname}}
                            {{member.lname}}</mat-option>
                    </mat-select>
                    <mat-error class="error d-flex" *ngIf="submitted && f['owner'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['owner'].errors['required']"> {{pipelineJson.label_owner}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{pipelineJson.label_assignee}}</label>
                <ng-select class="form-control" [items]="staffMembers" bindValue="id" bindLabel="fname" [addTag]="false"
                    [multiple]="true" placeholder="Select staff" formControlName="assignee"
                    (change)="updatePipelineCard()">
                </ng-select>
                <!-- <div class="error d-flex mt-2" *ngIf="submitted && f['assignee'].errors">
                    <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                    <span *ngIf="f['assignee'].errors['required']"> Staff
                        {{errorJson.required}}</span>
                </div> -->
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{pipelineJson.label_tag}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="tags" placeholder="Select {{pipelineJson.label_tag}}"
                        (selectionChange)="updatePipelineCard()">
                        <mat-option [value]="item.id" *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- System Fields -->
            <hr class="mt-2" *ngIf="systemfieldsForm().controls.length !== 0">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" formArrayName="system_fields"
                *ngFor="let system_field of systemfieldsForm().controls;let i=index;"
                [ngSwitch]="system_field.value.data_type">
                <div class="" [formGroupName]="i">
                    <label class="fw-600 mb-2 text-capitalize" *ngIf="system_field.value.data_name ==='company' && featuresData['create_company'] && featuresData['company'] || system_field.value.data_name !=='company'" >{{system_field.value.data_name}}</label>
                    <small class="text-danger" *ngIf="system_field.value.is_mandatory">&nbsp; *</small>
                    <!-- Text Box -->
                    <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'TEXT'">
                        <input matInput type="text" formControlName="data" (blur)="updatePipelineCard()"
                            placeholder="{{system_field.value.data_name}}">
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && getSystemFieldValidity(i)">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                class="text-capitalize">{{system_field.value.data_name.replace("_", " ")}}</span>&nbsp;
                            {{errorJson.required}}
                        </mat-error>
                    </mat-form-field>
                    <!--Text Area-->
                    <ng-container *ngIf="system_field.value.data_name !== 'description'">
                        <!-- <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'TEXT_AREA'"> -->
                        <div *ngSwitchCase="'TEXT_AREA'">
                            <ckeditor [editor]="Editor" placeholder="write a message" 
                                formControlName="data" [config]="ckconfigQue" class="ckeditor-height-2"
                                #customEditorComponents (ready)="onCustReady($event,'sys')"
                                [ngClass]="ckeditorLimitLists['sys'+i]?.actualLimit > ckeditorLimitLists['sys'+i]?.maxLimit ? 'ck-error':''"
                                (keyup)="custEditorkeyEvent($event,customEditorComponents,'sys'+i)"
                                (blur)="updatePipelineCard()">
                            </ckeditor>
                            <small
                                [ngClass]="ckeditorLimitLists['sys'+i]?.actualLimit > ckeditorLimitLists['sys'+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10">Maximum
                                1000 characters are accepted.</small><small
                                [ngClass]="ckeditorLimitLists['sys'+i]?.actualLimit > ckeditorLimitLists['sys'+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10 ps-1">(Character
                                limit:
                                {{ckeditorLimitLists['sys'+i]?.actualLimit}}/{{ckeditorLimitLists['sys'+i]?.maxLimit}})</small>
                            <!-- <textarea matInput type="text" class="textareamat" formControlName="data"
                                (blur)="updatePipelineCard()"></textarea> -->
                            <div class="error text-danger d-flex" *ngIf="submitted && getSystemFieldValidity(i)">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span class="text-capitalize">{{system_field.value.data_name.replace("_", "
                                    ")}}</span>&nbsp; {{errorJson.required}}
                            </div>
                        </div>
                        <!-- </mat-form-field> -->
                    </ng-container>
                    <ng-container *ngIf="system_field.value.data_name === 'description'">
                        <ckeditor [editor]="Editor" *ngSwitchCase="'TEXT_AREA'" class="ckeditor-height textareamat"
                            (blur)="updatePipelineCard()" formControlName="data" 
                            [config]="ckconfigQue" #editorComponen
                            [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'ck-error':''"
                            (ready)="onReady($event,editorComponen)" (keyup)="editorkeyEvent(editorComponen)">
                        </ckeditor>
                        <small [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                            class="font-size-10">Maximum
                            1000 characters are accepted.</small><small
                            [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                            class="font-size-10 ps-1">(Character limit:
                            {{ckeditorLimit.actualLimit}}/{{ckeditorLimit.maxLimit}})</small>
                        <div class="error d-flex align-items-center" *ngIf="submitted && getSystemFieldValidity(i)">
                            <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                            <span class="text-capitalize">{{system_field.value.data_name.replace("_", "
                                ")}}</span>&nbsp; {{errorJson.required}}
                        </div>
                    </ng-container>
                    <!--Date & Time-->
                    <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'DATE_TIME'">
                        <input style="outline: none" matInput [matDatepicker]="picker"
                            (dateChange)="updatePipelineCard()" placeholder="{{system_field.value.data_name}}"
                            formControlName="data" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && getSystemFieldValidity(i)">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                class="text-capitalize">{{system_field.value.data_name.replace("_", " ")}}</span>&nbsp;
                            {{errorJson.required}}
                        </mat-error>
                    </mat-form-field>
                    <!-- Check Box -->
                    <ul *ngSwitchCase="'CHECK_BOX'" class="mb-2 ps-0">
                        <li *ngFor="let field_option of system_field.value.options;let l=index">
                            <mat-checkbox class="mb-2" [checked]="field_option.checked"
                                (change)="setAll($event,field_option.binder,i,'systemForm')">
                                {{field_option.binder}}
                            </mat-checkbox>
                        </li>
                    </ul>
                    <!-- Radio button -->
                    <div *ngSwitchCase="'RADIO_BUTTON'" class="mb-4">
                        <mat-radio-group aria-labelledby="example-radio-group-label" formControlName="data"
                            class="example-radio-group">
                            <mat-radio-button class="example-radio-button" (change)="updatePipelineCard()"
                                *ngFor="let field_option of system_field.value.options" [value]="field_option">
                                {{field_option}} &nbsp;
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="error d-flex align-items-center" *ngIf="submitted && getSystemFieldValidity(i)">
                            <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                            <span class="text-capitalize">{{system_field.value.data_name.replace("_", "
                                ")}}</span>&nbsp; {{errorJson.required}}
                        </div>
                    </div>
                    <!-- Drop down-->
                     <ng-container *ngIf="system_field.value.data_name ==='company' && featuresData['create_company'] && featuresData['company'] || system_field.value.data_name !=='company'">
                         <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'SELECT_DROPDOWN'" >
                             <mat-select formControlName="data" placeholder="{{system_field.value.data_name}}"
                                 (selectionChange)="updatePipelineCard()">
                                 <ng-container
                                     *ngIf="system_field.value.data_name!=='scheduler_category' || system_field.value.data_name!=='scheduler' ||
                                 system_field.value.data_name!=='product_category' || system_field.value.data_name!=='product' || system_field.value.data_name!=='contact' ||
                                 system_field.value.data_name!=='company' || system_field.value.data_name!=='status' || system_field.value.data_name!=='channel' || system_field.value.data_name!=='currency'">
                                     <mat-option *ngFor="let field_option of system_field.value.options"
                                         [value]="field_option">
                                         {{field_option}}
                                     </mat-option>
                                 </ng-container>
                                 <ng-container *ngIf="system_field.value.data_name==='scheduler_category'">
                                     <mat-option *ngFor="let item of aptCategoriesArray" [value]="item.id">
                                         {{item.name}}
                                     </mat-option>
                                 </ng-container>
                                 <ng-container *ngIf="system_field.value.data_name==='scheduler'">
                                     <mat-option *ngFor="let item of appointmentsArray" [value]="item.id">
                                         {{item.name}}
                                     </mat-option>
                                 </ng-container>
                                 <ng-container *ngIf="system_field.value.data_name==='product_category'">
                                     <mat-option *ngFor="let item of productCategory" [value]="item.id">
                                         {{item.name}}
                                     </mat-option>
                                 </ng-container>
                                 <ng-container *ngIf="system_field.value.data_name==='product'">
                                     <mat-option *ngFor="let item of productsList" [value]="item.id">
                                         {{item.name}}
                                     </mat-option>
                                 </ng-container>
                                 <ng-container *ngIf="system_field.value.data_name==='service_category'">
                                     <mat-option *ngFor="let item of serviceCategory" [value]="item.id">
                                         {{item.name}}
                                     </mat-option>
                                 </ng-container>
                                 <ng-container *ngIf="system_field.value.data_name==='service'">
                                     <mat-option *ngFor="let item of servicesList" [value]="item.id">
                                         {{item.name}}
                                     </mat-option>
                                 </ng-container>
                                 <ng-container *ngIf="system_field.value.data_name==='contact'">
                                     <mat-option *ngFor="let item of contactsList" [value]="item.id">
                                         {{item.first_name}}
                                     </mat-option>
                                 </ng-container>
                                 <ng-container *ngIf="system_field.value.data_name==='company'">
                                     <mat-option *ngFor="let item of companiesList" [value]="item.id">
                                         {{item.name}}
                                     </mat-option>
                                 </ng-container>
                                 <ng-container *ngIf="system_field.value.data_name==='currency'">
                                     <mat-option>
                                         <ngx-mat-select-search [formControl]="bankFilterCurrencyCtrl"
                                             placeholderLabel="Search"
                                             noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                     </mat-option>
                                     <mat-option *ngFor="let currency of filteredCurrencyBanks | async"
                                         value='{{currency.abbreviation +", "+currency.symbol}}'>{{currency.currency
                                         + " (" + currency.abbreviation + "," + currency.symbol +
                                         ")"}}</mat-option>
                                 </ng-container>
                                 <ng-container *ngIf="system_field.value.data_name==='status'">
                                     <mat-option value="pending">Pending</mat-option>
                                     <mat-option value="approved">Approved</mat-option>
                                     <mat-option value="rejected">Rejected</mat-option>
                                     <mat-option value="completed">Completed</mat-option>
                                 </ng-container>
                                 <!-- <ng-container *ngIf="system_field.value.data_name==='channel'">
                                     <mat-option *ngFor="let item of channels"
                                         [value]="item.value">{{item.display_name}}</mat-option>
                                 </ng-container> -->
                                 <ng-container *ngIf="system_field.value.data_name === 'priority'">
                                     <mat-option value="High">High</mat-option>
                                     <mat-option value="Low">Low</mat-option>
                                     <mat-option value="Medium">Medium</mat-option>
                                 </ng-container>
                             </mat-select>
                             <mat-error class="error text-danger d-flex" *ngIf="submitted && getSystemFieldValidity(i)">
                                 <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                     class="text-capitalize">{{system_field.value.data_name.replace("_", " ")}}</span>&nbsp;
                                 {{errorJson.required}}
                             </mat-error>
                         </mat-form-field>
                     </ng-container>
                    <!--Number-->
                    <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'NUMBER'">
                        <input style="outline: none" matInput placeholder="{{system_field.value.label}}"
                            formControlName="data" type="number" (keydown)="keyDownCustomField($event,0,'custom_field')"
                            (blur)="updatePipelineCard()" (wheel)="$event.preventDefault()" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && getSystemFieldValidity(i)">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                class="text-capitalize">{{system_field.value.data_name.replace("_", " ")}}</span>&nbsp;
                            {{errorJson.required}}
                        </mat-error>
                    </mat-form-field>
                    <!--Date Range-->
                    <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'DATE_RANGE'">
                        <mat-date-range-input [rangePicker]="dateRangepicker">
                            <input matStartDate formControlName="start" placeholder="Start date">
                            <input matEndDate formControlName="end" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="dateRangepicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #dateRangepicker></mat-date-range-picker>
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && getSystemFieldValidity(i)">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                class="text-capitalize">{{system_field.value.data_name.replace("_", " ")}}</span>&nbsp;
                            {{errorJson.required}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <hr class="mt-2">
            <h5>
                Additional Fields
            </h5>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" formArrayName="fields"
                *ngFor="let field of fieldsForm().controls;let i=index;" [ngSwitch]="field.value.data_type">
                <div class="" [formGroupName]="i">
                    <div class=""><!-- *ngIf="field.value.on_form"-->
                        <label class="fw-600 mb-2 text-capitalize">{{field.value.data_name}}</label>
                        <small class="text-danger" *ngIf="field.value.is_mandatory">&nbsp; *</small>
                        <!-- Text Box -->
                        <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'TEXT'">
                            <input matInput type="text" formControlName="data" placeholder="{{field.value.data_name}}"
                                (blur)="selectChange($event,field)" (keydown.enter)="selectChange($event,field)">
                            <mat-error class="error text-danger d-flex" *ngIf="submitted && getCustomFieldValidity(i)">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                    class="text-capitalize">{{field.value.data_name.replace("_", " ")}}</span>&nbsp;
                                {{errorJson.required}}
                            </mat-error>
                        </mat-form-field>
                        <!--Text Area-->
                        <!-- <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'TEXT_AREA'"> -->
                        <div *ngSwitchCase="'TEXT_AREA'">
                            <ckeditor [editor]="Editor" placeholder="write a message" formControlName="data"
                                [config]="ckconfigQue" class="ckeditor-height-2" #customEditorComponents
                                (ready)="onCustReady($event,'cust'+i)"
                                [ngClass]="ckeditorLimitLists['cust'+i]?.actualLimit > ckeditorLimitLists['cust'+i]?.maxLimit ? 'ck-error':''"
                                (keyup)="custEditorkeyEvent($event,customEditorComponents,'cust'+i)"
                                (blur)="selectChange($event,field)" (keydown.enter)="selectChange($event,field)">
                            </ckeditor>
                            <small
                                [ngClass]="ckeditorLimitLists['cust'+i]?.actualLimit > ckeditorLimitLists['cust'+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10">Maximum
                                1000 characters are accepted.</small><small
                                [ngClass]="ckeditorLimitLists['cust'+i]?.actualLimit > ckeditorLimitLists['cust'+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10 ps-1">(Character
                                limit:
                                {{ckeditorLimitLists['cust'+i]?.actualLimit}}/{{ckeditorLimitLists['cust'+i]?.maxLimit}})</small>
                            <!-- <textarea matInput type="text" class="textareamat" formControlName="data"
                                placeholder="{{field.value.data_name}}" (blur)="selectChange($event,field)"
                                (keydown.enter)="selectChange($event,field)"></textarea> -->
                            <div class="error text-danger d-flex" *ngIf="submitted && getCustomFieldValidity(i)">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                    class="text-capitalize">{{field.value.data_name.replace("_", " ")}}</span>&nbsp;
                                {{errorJson.required}}
                            </div>
                        </div>
                        <!-- </mat-form-field> -->
                        <!--Date & Time-->
                        <ng-container *ngSwitchCase="'DATE_TIME'">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput [ngxMatDatetimePicker]="dateTimePicker" placeholder="Choose a date"
                                    formControlName="data" (dateChange)="selectChange($event,field)">
                                <mat-datepicker-toggle matSuffix [for]="$any(dateTimePicker)"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #dateTimePicker></ngx-mat-datetime-picker>
                                <mat-error class="error text-danger d-flex" *ngIf="submitted && getCustomFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon><span
                                        class="text-capitalize">{{field.value.data_name.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngSwitchCase="'DATE'">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="text" matInput [matDatepicker]="picker" formControlName="data"
                                    placeholder="{{field.value.label}}">
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error class="error text-danger d-flex" *ngIf="submitted && getCustomFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.data_name.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                        <!-- Check Box -->
                        <!-- <ul *ngSwitchCase="'CHECK_BOX'" class="mb-2 ps-0" [formGroupName]="field.label">
                    <li *ngFor="let field_option of field.options">
                        <mat-checkbox class="mb-2" [formControlName]="field_option">
                            {{field_option}}
                        </mat-checkbox>
                    </li>{hair wash:false}
                </ul> -->
                        <ul *ngSwitchCase="'CHECK_BOX'" class="mb-2 ps-0">
                            <li *ngFor="let field_option of field.value.options;let l=index">
                                <mat-checkbox class="mb-2" [checked]="field_option.checked"
                                    (change)="setAll($event,field_option.binder,i,'customfield')">
                                    {{field_option.binder}}
                                </mat-checkbox>
                            </li>
                        </ul>
                        <!-- Radio button -->
                        <div *ngSwitchCase="'RADIO_BUTTON'" class="mb-4">
                            <mat-radio-group aria-labelledby="example-radio-group-label" formControlName="data"
                                class="example-radio-group">
                                <mat-radio-button class="example-radio-button" (change)="radioChange($event,field)"
                                    *ngFor="let field_option of field.value.options" [value]="field_option">
                                    {{field_option}} &nbsp;
                                </mat-radio-button>
                            </mat-radio-group>
                            <div class="error d-flex align-items-center" *ngIf="submitted && getCustomFieldValidity(i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.data_name.replace("_", " ")}}</span>&nbsp;
                                {{errorJson.required}}
                            </div>
                        </div>
                        <!-- Drop down-->
                        <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'SELECT_DROPDOWN'">
                            <mat-select formControlName="data" placeholder="{{field.value.data_name}}"
                                (selectionChange)="selectChange($event.value,field)">
                                <ng-container *ngIf="field.value.url===''">
                                    <mat-option *ngFor="let field_option of field.value.options" [value]="field_option">
                                        {{field_option}}
                                    </mat-option>
                                </ng-container>
                                <ng-container *ngIf="field.value.url==='/consultations/category/'">
                                    <mat-option *ngFor="let item of aptCategoriesArray" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </ng-container>
                                <ng-container *ngIf="field.value.url==='consultations/consultations/'">
                                    <mat-option *ngFor="let item of appointmentsArray" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </ng-container>
                                <ng-container *ngIf="field.value.url==='stores/?/categories/'">
                                    <mat-option *ngFor="let item of productCategory" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </ng-container>
                                <ng-container *ngIf="field.value.url==='product/stores/?/products/'">
                                    <mat-option *ngFor="let item of productsList" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </ng-container>
                                <ng-container *ngIf="field.value.url==='crm_service/Contacts'">
                                    <mat-option *ngFor="let item of contactsList" [value]="item.id">
                                        {{item.first_name}}
                                    </mat-option>
                                </ng-container>
                                <ng-container *ngIf="field.value.url==='crm_service/Companys'">
                                    <mat-option *ngFor="let item of companiesList" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </ng-container>
                                <ng-container *ngIf="field.value.url==='status'">
                                    <mat-option value="pending">Pending</mat-option>
                                    <mat-option value="approved">Approved</mat-option>
                                    <mat-option value="rejected">Rejected</mat-option>
                                    <mat-option value="completed">Completed</mat-option>
                                </ng-container>
                                <ng-container *ngIf="field.value.url==='source'">
                                    <mat-option value="whatsapp">Whatsapp</mat-option>
                                    <mat-option value="manual">Manual entry</mat-option>
                                </ng-container>
                                <!-- <ng-container *ngIf="field.label!=='Service'">
                            <mat-option *ngFor="let field_options of field.field_options"
                                [value]="field_options">
                                {{field.label==='Service' ? returnServiceName(field_options) :
                                field_options}}
                            </mat-option>
                        </ng-container> -->
                            </mat-select>
                            <mat-error class="error text-danger d-flex" *ngIf="submitted && getCustomFieldValidity(i)">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                    class="text-capitalize">{{field.value.data_name.replace("_", " ")}}</span>&nbsp;
                                {{errorJson.required}}
                            </mat-error>
                        </mat-form-field>
                        <!-- Multi Drop down-->
                        <ng-container *ngSwitchCase="'MULTI_SELECT_DROPDOWN'">
                            <div *ngIf="field.value.options.length > 0">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-select formControlName="data" multiple (selectionChange)="selectChange($event.value,field)">
                                        <mat-option *ngFor="let options of field.value.options" [value]="options">
                                            {{options}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error class="error text-danger d-flex"
                                        *ngIf="submitted && getCustomFieldValidity(i)">
                                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.data_name.replace("_", "
                                            ")}}</span>&nbsp;{{errorJson.required}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>
                        <!--Number-->
                        <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'NUMBER'">
                            <input style="outline: none" matInput placeholder="{{field.value.data_name}}"
                                formControlName="data" type="number" (keydown)="keyDownEvent($event,12)"
                                (blur)="selectChange($event,field)" (wheel)="$event.preventDefault()"
                                (keydown.enter)="selectChange($event,field)" />
                            <mat-error class="error text-danger d-flex" *ngIf="submitted && getCustomFieldValidity(i)">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                    class="text-capitalize">{{field.value.data_name.replace("_", " ")}}</span>&nbsp;
                                {{errorJson.required}}
                            </mat-error>
                        </mat-form-field>
                        <!--Website-->
                        <ng-container *ngIf="field.value.data_type === 'WEBSITE'">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" type="url" matInput placeholder="{{field.value.data_name}}"
                                    formControlName="data" (blur)="selectChange($event,field)" (keydown.enter)="selectChange($event,field)"/>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getCustomFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.data_name.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}} or Invalid
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                        <!--Date Range-->
                        <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'DATE_RANGE'">
                            <mat-date-range-input [rangePicker]="dateRangepicker" (blur)="selectChange($event,field)">
                                <input matStartDate formControlName="start" placeholder="Start date">
                                <input matEndDate formControlName="end" placeholder="End date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matIconSuffix [for]="dateRangepicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #dateRangepicker
                                (closed)="selectChange($event,field)"></mat-date-range-picker>
                            <mat-error class="error text-danger d-flex" *ngIf="submitted && getCustomFieldValidity(i)">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                    class="text-capitalize">{{field.value.data_name.replace("_", " ")}}</span>&nbsp;
                                {{errorJson.required}}
                            </mat-error>
                        </mat-form-field>
                        <!--File Upload-->
                        <ng-container *ngSwitchCase="'FILE'">
                            <div class="" *ngIf="editCard">
                                <img src="{{field.value?.data?.path}}" alt="" width="150px" class="mb-2"
                                    (error)="onErrorLoadImage($event,presignedurl,field.value?.data?.file_name)">
                                <a href="{{field.value?.data?.path}}" target="_blank"
                                    class="ms-2 text-dark fw-medium mt-3" download>{{field.value?.data?.file_name}}</a>
                            </div>
                            <input type="file" style="outline: none" placeholder="{{field.value.data_name}}"
                                (change)="onFileUpload($event,i,field)" />
                            <div class="error d-flex align-items-center" *ngIf="submitted && getCustomFieldValidity(i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.data_name.replace("_", "
                                    ")}}</span>&nbsp;{{errorJson.required}}
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'PHONE_NUMBER'">
                            <div class="d-flex">
                                <mat-form-field appearance="outline" class="me-1">
                                    <mat-select placeholder="Country code" formControlName="start" (selectionChange)="selectChange($event.value,field)">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let country of filteredBanks | async"
                                            [ngClass]="{'d-none': country.IAC === ''}"
                                            value={{country.IAC}}>{{country.IAC}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                                        placeholder="{{field.value.data_name}}" formControlName="end" (blur)="selectChange($event,field)"/>
                                    <mat-error class="error text-danger d-flex"
                                        *ngIf="submitted && getCustomFieldValidity(i)">
                                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.data_name.replace("_", " ") }}
                                            {{errorJson.required}}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'EMAIL'">
                            <mat-form-field appearance="outline" class="w-100">
                              <input style="outline: none" matInput placeholder="{{field.value.data_name}}" formControlName="data" 
                                (blur)="selectChange($event,field)"/>
                              <mat-error class="error text-danger d-flex" *ngIf="submitted && getCustomFieldValidity(i)">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                  class="text-capitalize">{{field.value.data_name.replace("_", " ")}}</span>&nbsp;
                                {{errorJson.required}}
                              </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer border-0 d-flex justify-content-between mt-3">
    <div class="d-flex cursor" *ngIf="loggedInUser.data.role === 'ADMIN'" (click)="openCustomPipelineDialog()">
        <span class="material-icons text-primary me-2">settings_applications</span>
        {{pipelineJson.customize_text}}
    </div>

    <div class=" d-flex align-items-center justify-content-between">
        <mat-checkbox (change)="toggleVisibility($event)" [(ngModel)]="is_client_visible">Client
            Visiblity</mat-checkbox>
    </div>
    <div class="d-flex">
        <button class="btn btn-light me-2" type="submit" *ngIf="!editCard" (click)="cancelModal()">
            {{pipelineJson.cancel_btn}}
        </button>
        <button class="btn btn-primary" type="submit"
            (click)="(ckeditorLimit.actualLimit <= ckeditorLimit.maxLimit) && !showErrorMessage && onSubmit()" *ngIf="!editCard"
            [disabled]="loading || (ckeditorLimit.actualLimit > ckeditorLimit.maxLimit) || showErrorMessage">{{pipelineJson.save_btn}}</button>
    </div>
</div>
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { MENU } from '../data/menu_list';
import { TwilioVoiceService } from '../service/twilio-voice.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CallFeedbackComponent } from './call-feedback/call-feedback.component';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss']
})
export class LayoutsComponent {

  menuList: any[];
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map((result: any) => result.matches)
    );
  selectedMenu: any;
  callStatus: any;
  muteStatus = false;
  call_duration = '00:00';
  incomingCallStatus = false;
  caller_details: any;
  modalRef: any;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private cdr: ChangeDetectorRef,
    private twilioVoiceService: TwilioVoiceService, private modalService: NgbModal) {
    this.menuList = MENU;
    this.twilioVoiceService.voicecallStatus.subscribe((resp: any) => {
      this.callStatus = resp;
      if (this.callStatus === 'closed') {
        this.modalRef = this.modalService.open(CallFeedbackComponent, { size: 'md' });
        this.modalRef.componentInstance.contact = this.caller_details;
        this.modalRef.componentInstance.call_duration = this.call_duration;
      }
      this.twilioVoiceService.callerConnectionId.subscribe((contact_details: any) => {
        this.caller_details = contact_details;
      });
    });
    this.twilioVoiceService.ongoingCallDuration.subscribe((resp: any) => {
      console.log(resp);
      this.call_duration = resp;
    });
    this.twilioVoiceService.incomingConnectionAvailable.subscribe((resp: any) => {
      if (resp) {
        this.incomingCallStatus = true;
      } else {
        this.incomingCallStatus = false;
      }
    });
  }

  changeOfRoutes() {
    this.menuList.forEach((ele) => {
      if (ele.label.toLowerCase() === this.router.url.replace('_', " ").split('/')[1] || (ele.label.toLowerCase() === 'flows' && this.router.url.replace('_', " ").split('/')[1] === 'pipelines')) {
        this.selectedMenu = ele;
        this.cdr.detectChanges();
      } else if ('user-profile' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 21,
          "label": "Profile",
          "icon": "",
          "link": "/user-profile",
          "slug": "",
          "image": "",
          "color_img": ""
        };
      } else if ('settings' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 21,
          "label": "Settings",
          "icon": "settings",
          "link": "/settings",
          "slug": "settings",
          "image": "./assets/images/settings.png",
          "color_img": "./assets/images/color_settings_Icon.svg"
        };
      } else if ('header-calendar' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 22,
          "label": "Calendar",
          "icon": "calendar",
          "link": "/header-calendar",
          "slug": "calendar",
          "image": "./assets/images/calendar.png",
          "color_img": "./assets/images/color_personal_calendar_Icon.svg"
        };
      } else if ('taskboard' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 23,
          "label": "Boards",
          "icon": "dashboard",
          "link": "/taskboard",
          "slug": "taskboard",
          "image": './assets/images/task-board.svg',
          "color_img": './assets/images/task-board-color.svg',
          "white_image": './assets/images/task-board-color.svg'
        };
      } else if ('filemanager' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 29,
          "label": 'File Manager',
          "icon": 'dashboard',
          "link": '/filemanager',
          "slug": 'filemanager',
          "image": './assets/images/task-board.svg',
          "color_img": './assets/images/newUI/MENU/file_manager_icon_color.svg'
        };
      } else if ('finance' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 17,
          "label": 'Finance',
          "link": '/finance',
          "slug": 'finance',
          "icon": 'monetization_on',
          "image": './assets/images/Finance_Icon.svg',
          "color_img": './assets/images/newUI/MENU/finance_icon_color.svg'
        };
      } else if ('reports' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 16,
          "label": 'Reports',
          "link": '/reports',
          "slug": 'reports',
          "icon": 'pie_chart',
          "image": './assets/images/Report_Icon.svg',
          "color_img": './assets/images/newUI/MENU/reports_icon_color.svg'
        };
      } else if ('conversation' === this.router.url.split('/')[1]) {//Route Link and label name are different so we are using this to match the heding 
        this.selectedMenu = {
          id: 25,
          label: 'Messages',
          icon: 'chat',
          link: '/conversation',
          slug: 'conversation',
          image: './assets/images/conversations (1).svg',
          color_img: './assets/images/conversations-color.svg'
        };
      } else if ('folder=inbox' === this.router.url.split('?')[1]) {
        this.selectedMenu = {
          id: 30,
          label: 'Inbox',
          icon: 'chat',
          link: '/bot_chat?folder=inbox',
          slug: 'inbox',
          image: './assets/images/conversations (1).svg',
          color_img: './assets/images/conversations-color.svg'
        };
      } else if ('home' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          id: 1,
          label: 'Dashboard',
          icon: 'home',
          link: '/home',
          slug: 'dashboard',
          image: './assets/images/newUI/MENU/home_icon.svg',
          color_img: './assets/images/Color_Home_Icon.svg',
          white_image: './assets/images/newUI/MENU/home_icon_white.svg',
          isEnable: true
        };
      }
    });
  }

  hangCall() {
    this.twilioVoiceService.hangUpCall();
  }

  muteCall() {
    this.muteStatus = this.twilioVoiceService.muteCall();
  }

  incomingCall(btnType: string) {
    this.twilioVoiceService.showIncomingCallDialog(btnType);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SetUpService {

  meetingSettings = new BehaviorSubject<any>(null);
  calendarMeetings = new BehaviorSubject<any>(null);
  workingHours = new BehaviorSubject<any>(null);
  setupSetting = new BehaviorSubject<any>(null);
  current_user = JSON.parse(sessionStorage.getItem('currentUser')!);
  constructor(private http: HttpClient) { }
  //Setup the Organization Services

  getSetupProjectInfo() {
    return this.http.get(`${environment.urlPrefix}${constant.setup}?query_param=client_project`);
  }

  getSetupInfo() {
    this.http.get(`${environment.urlPrefix}${constant.setup}`).subscribe((resp: any) => {
      this.setupSetting.next(resp.data);
    });
  }

  updateSetupStatus(id: string, status: { status: boolean; }) {
    return this.http.put(`${environment.urlPrefix}${constant.setup}${id}/`, status);
  }

  getSetupInformation(type?: string) {
    if (type) {
      this.http.get(`${environment.urlPrefix}${constant.setup}`).subscribe((resp: any) => {
        this.setupSetting.next(resp.data);
      });
      return this.setupSetting.asObservable();
    } else {
      return this.setupSetting.asObservable();
    }
  }

  getGlobalSetupInfo(){
    return this.http.get(`${environment.urlPrefix}${constant.global_setup}`);
  }

  checkFeature(type: string) {
    let status;
    this.setupSetting.getValue()?.forEach((value: any) => {
      if (value.name === type) {
        status = value.status;
      }
    });
    return status;
  }

  //Products
  getStore() {
    return this.http.get(`${environment.urlPrefix}${constant.store}`);
  }

  getStoreById(store_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.store}${store_id}/`);
  }

  duplicateProductStore(store_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.store}duplicate/${store_id}/`);
  }

  postProductStore(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.store}`,body);
  }

  putProductStore(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.store}${body.id}/`,body);
  }

  deleteProductStore(store_id: any){
    return this.http.delete(`${environment.urlPrefix}${constant.store}${store_id}/`);
  }

  getStoreProductCategories(storeId: string, type: string, searchTerm?: string) {
    if (type.includes('category')) type = type.split('_')[0];
    if (searchTerm) {
      return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.product_categories}?type=${type}&name=${searchTerm}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.product_categories}?type=${type.toUpperCase()}`);
    }
  }

  getActiveCategories(storeId: string, type: string) {
    return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.product_categories}?status=True&type=${type.toUpperCase()}`);
  }

  getProductCategories(type: string, searchTerm?: string) {
    if (type.includes('category')) type = type.split('_')[0];
    if (searchTerm) {
      return this.http.get(`${environment.urlPrefix}${constant.store}categories/?type=${type}&name=${searchTerm}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.store}categories/?type=${type.toUpperCase()}`);
    }
  }

  postProductCategories(storeId: string, body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.store}${storeId}${constant.product_categories}`, body);
  }

  putProductCategories(storeId: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.store}${storeId}${constant.product_categories}${body.id}/`, body);
  }

  deleteProductCategories(storeId: string, id: any) {
    return this.http.delete(`${environment.urlPrefix}${constant.store}${storeId}${constant.product_categories}${id}/`);
  }

  getProductData(storeId: string, type: string, searchTerm?: string) {
    if (searchTerm) {
      return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}?type=${type}&name=${searchTerm}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}?type=${type.toUpperCase()}`);
    }
  }
  getProduct(type: string, searchTerm?: string) {
    if (searchTerm) {
      return this.http.get(`${environment.urlPrefix}${constant.store}products/?type=${type}&name=${searchTerm}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.store}products/?type=${type.toUpperCase()}`);
    }
  }

  getActiveProducts(storeId: string, type: string) {
    return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}?status=True&type=${type}`);
  }

  getDropDownProducts(type: string) {
    return this.http.get(`${environment.urlPrefix}${constant.store}${constant.products}?status=True&dropdown=true&type=${type}`);
  }

  getProductById(storeId: string, id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}${id}/`);
  }

  getProductByIdwithoutstore(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.store}${constant.products}${id}/`);
  }

  postProduct(storeId: string, body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}`, body);
  }

  putProduct(storeId: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}${body.id}/`, body);
  }

  deleteProduct(storeId: string, id: any) {
    return this.http.delete(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}${id}/`);
  }

  updateVariant(productId: string, body: any) {
    return this.http.put(`${environment.urlPrefix}/product${constant.products}${productId}${constant.variants}${body.id}/`, body);
  }

  deleteVariant(variantId: string, productId: string) {
    return this.http.delete(`${environment.urlPrefix}/product${constant.products}${productId}${constant.variants}${variantId}/`);
  }

  getVariantsCombinations(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.variants_combinations}`, body);
  }

  getDefaultTaskStage() {
    this.current_user = JSON.parse(sessionStorage.getItem('currentUser')!);
    return this.http.get(`${environment.urlPrefix}${constant.stage}?source_id=${this.current_user.data?.organisation}`);
  }

  createAppStore(body: any){
    return this.http.post(`${environment.messageUrl}${constant.app_store}`,body);
  }

  deleteAppStore(storeId: string) {
    return this.http.delete(`${environment.messageUrl}${constant.app_store}${storeId}`);
  }

  //Categories
  getAllCategories() {
    return this.http.get(`${environment.urlPrefix}${constant.categories}`);
  }
  getAllActiveCategories() {
    return this.http.get(`${environment.urlPrefix}${constant.categories}?active=true`);
  }
  deleteCategory(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.categories}${id}/`);
  }
  putCategory(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.categories}${id}/`, body);
  }
  postCategory(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.categories}`, body);
  }

  //Appointments
  getAllAppointments(page: any) {
    return this.http.get(`${environment.urlPrefix}${constant.appointments}?page=${page}&offset=20`);
  }
  postAppointment(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.appointments}`, body);
  }
  putAppointmentStatus(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.appointments}${id}/`, body);
  }
  putAppointmentReschedule(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.appointmentReschedule}${id}/`, body);
  }
  deleteAppointment(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.appointments}${id}/`);
  }

  getAppointmentsBySearch(page: any, search_text: string) {
    return this.http.get(`${environment.urlPrefix}${constant.appointments}?page=${page}&search=${search_text}&limit=20`);
  }

  postTransaction(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.transaction}`, body);
  }

  getSlotsBasedOnStaffId(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.slots}`, body);
  }

  getWidget() {
    return this.http.get(`${environment.urlPrefix}${constant.appointment_widget}`);
  }

  postWidget(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.appointment_widget}`, body);
  }
  //Questionnaries
  getQuestionnaire(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.appointmentQuestionnaire}?consultation_id=${id}`);
  }

  postQuestionnaire(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.appointmentQuestionnaire}`, body);
  }

  putQuestionnaire(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.appointmentQuestionnaire}${body.id}/`, body);
  }

  deleteQuestionnaire(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.appointmentQuestionnaire}${id}/`);
  }

  //consultations Settings
  getConsulationSettings() {
    return this.http.get(`${environment.urlPrefix}${constant.consultations_settings}`);
  }

  postConsulationSettings(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.consultations_settings}`, body);
  }

  //consultations

  getAllConsulations(page?: any) {
    return this.http.get(`${environment.urlPrefix}${constant.consultations}`);//?page=${page}&offset=20
  }

  getConsultationByID(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.consultations}${id}/`);
  }

  postConsultation(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.consultations}`, body);
  }

  putConsultation(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.consultations}${id}/`, body);
  }

  deleteConsultations(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.consultations}${id}/`);
  }

  getConsulationsBycategoryId(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.consultations}?category_id=${id}`);
  }

  getConsulationsBycategoryIdActive(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.consultations}?category_id=${id}&active=true`);
  }

  //Calender-Meetings

  postProfileMeeting(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.profileMeetings}`, body);
  }

  putProfileMeeting(id: any, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.profileMeetings}${id}/`, body);
  }

  // getProfileMeetings() {
  //   return this.http.get(`${environment.urlPrefix}${constant.profileMeetings}`);
  // }

  getProfileMeetings(refreshType: string): Observable<any> {
    if (this.meetingSettings.getValue() !== null && refreshType !== "force") {
      return this.meetingSettings.asObservable();
    } else {
      this.http.get(`${environment.urlPrefix}${constant.profileMeetings}`).subscribe((data) => {
        this.meetingSettings.next(data);
      });
      return this.meetingSettings.asObservable();
    }
  }

  // getWorkingHours() {
  //   return this.http.get(`${environment.urlPrefix}${constant.profileWorkingHours}`);
  // }

  getWorkingHours(refreshType: string) {
    this.current_user = JSON.parse(sessionStorage.getItem('currentUser')!);
    if (this.workingHours.getValue() !== null && refreshType !== "force") {
      return this.workingHours.asObservable();
    } else {
      this.http.get(`${environment.urlPrefix}${constant.profileWorkingHours}?staff_id=${this.current_user?.data?.id}&type=meeting`).subscribe((data) => {
        this.workingHours.next(data);
      }, err => {
        this.workingHours.next(err);
      });
      return this.workingHours.asObservable();
    }
  }

  getOrgWorkHours(type: string, staff_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.profileWorkingHours}?staff_id=${staff_id}&type=${type}`);
  }

  postWorkingHours(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.profileWorkingHours}`, body);
  }

  putWorkingHours(id: any, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.profileWorkingHours}${id}/`, body);
  }


  postCalendarMeetings(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.profileCalMeetings}`, body);
  }

  putCalendarMeetings(id: any, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.profileCalMeetings}${id}/`, body);
  }

  mettingsSortOrder(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.profilemeetingsorder}`, body);
  }

  // getCalendarMeetings() {
  //   return this.http.get(`${environment.urlPrefix}${constant.profileCalMeetings}`);
  // }

  getCalendarMeetings(refreshType: string) {
    if (this.calendarMeetings.getValue() !== null && refreshType !== "force") {
      return this.calendarMeetings.asObservable();
    } else {
      this.http.get(`${environment.urlPrefix}${constant.profileCalMeetings}`).subscribe((data) => {
        this.calendarMeetings.next(data);
      });
      return this.calendarMeetings.asObservable();
    }
  }

  getCalendarMeetingById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.profileCalMeetings}${id}/`);
  }

  deleteCalendarMeetings(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.profileCalMeetings}${id}/`);
  }

  getCalenderWidget() {
    return this.http.get(`${environment.urlPrefix}${constant.calender_widget}`);
  }

  postCalenderWidget(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.calender_widget}`, body);
  }

  //Knowledge Base
  getArticles(page: number, offset: number,group_id?: string) {
    let query_param: any;
    if(group_id) query_param = `&topic=${group_id}`;
    return this.http.get(`${environment.urlPrefix}${constant.article}?page=${page}&offset=${offset || 20}${query_param || ''}`);
  }
  createArticle(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.article}`, body);
  }
  updateArticle(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.article}`, body);
  }
  deleteArticle(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.article}?id=${id}`);
  }

  getGroups(page: number, offset: number) {
    return this.http.get(`${environment.urlPrefix}${constant.group}?page=${page}&offset=${offset || 20}`);
  }

  getGroupsWithoutPagintion() {
    return this.http.get(`${environment.urlPrefix}${constant.group_dropdown}`);
  }

  searchGroup(searchTerm: string, type: string, page?: number) {
    if (type === 'articles') {
      return this.http.get(`${environment.urlPrefix}${constant.article}?search=${searchTerm}&page=${page}&limit=1`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.group}?search=${searchTerm}&page=${page}&limit=1`);
    }
  }

  createGroup(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.group}`, body);
  }
  updateGroup(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.group}`, body);
  }
  deleteGroup(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.group}?id=${id}`);
  }

  //Announcements
  getAnnouncements(page?: number) {
    return this.http.get(`${environment.urlPrefix}${constant.announcements}?page=${page}&limit=20`);
  }

  getSearchAnnouncements(searchTerm: string, page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.announcements}?search=${searchTerm}&page=${page}&limit=20`);
  }

  getInternalAnnouncements() {
    return this.http.get(`${environment.urlPrefix}${constant.announcements}?internal=True`);
  }

  getAnnouncementsById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.announcements}?id=${id}`);
  }

  postAnnouncements(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.announcements}`, body);
  }

  updateAnnouncements(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.announcements}`, body);
  }

  deleteAnnouncement(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.announcements}?id=${id}`);
  }

  //Dynamic Application // not using any more

  postCategoryForApp(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.dynamic_app_category}`, body);
  }
  updateCategoryForApp(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.dynamic_app_category}${body.id}/`, body);
  }
  deleteCategoryForApp(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.dynamic_app_category}${id}/`);
  }
  getCategoryForApp() {
    return this.http.get(`${environment.urlPrefix}${constant.dynamic_app_category}`);
  }
  getCategoryById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.dynamic_app_category}${id}/`);
  }

  getDynamicForms(category_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.dynamic_app_form}?category_id=${category_id}`);
  }
  getDynamicFormsById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.dynamic_app_form}${id}/`);
  }
  postDynamicForms(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.dynamic_app_form}`, body);
  }
  updateDynamicForms(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.dynamic_app_form}${body.id}/`, body);
  }
  deleteDynamicForms(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.dynamic_app_form}${id}/`);
  }
  duplicateDynamicForms(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.dynamic_app_form_duplication}`, body);
  }

  getFormDescriptionsFields(form_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.dynamic_app_show_user}${form_id}/`);
  }
  postFormDescriptionsFields(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.dynamic_app_show_user}`, body);
  }
  updateFormDescriptionsFields(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.dynamic_app_show_user}${body.form_fields_payload.id}/`, body);
  }
  deleteFormDescriptionsFields(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.dynamic_app_show_user}${id}/`);
  }

  getDynamicAppFilters(category_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.dynamic_app_filters}?category_id=${category_id}`);
  }
  postFilters(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.dynamic_app_filters}`, body);
  }
  putFilters(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.dynamic_app_filters}${body.id}/`, body);
  }
  deleteFilters(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.dynamic_app_filters}${id}/`);
  }

  //build your store or dynamic apps
  getStores() {
    return this.http.get(`${environment.urlPrefix}${constant.build_your_store}`);
  }
  postStore(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.build_your_store}`, body);
  }
  putStore(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.build_your_store}${body.id}/`, body);
  }
  deleteStore(storeId: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.build_your_store}${storeId}/`);
  }
  duplicateStore(store_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.build_your_store}${store_id}/duplicate/`);
  }
  importPosatalCodeforStore(store_id: string, body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.import_postalcodes}${store_id}/`, body);
  }
  exportPostcodesBystoreId(store_id: string) {
    return `${environment.urlPrefix}${constant.export_postalcodes}${store_id}/`;
  }

  getCategoryByStoreId(store_id: string, searchTerm?: any) {
    if (searchTerm) {
      return this.http.get(`${environment.urlPrefix}${constant.category_bys}${store_id}/?name=${searchTerm}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.category_bys}${store_id}/`);
    }
  }

  getCategoryByMultipleStoreId(type: string, store_id: string, searchTerm?: any) {
    if (searchTerm) {
      return this.http.get(`${environment.urlPrefix}${constant.bys_coupons_dropdowns}?type=${type}&ids=${store_id}/?name=${searchTerm}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.bys_coupons_dropdowns}?type=${type}&ids=${store_id}`);
    }
  }
  postCategoryByStoreId(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.category_bys}${body.store_id}/`, body);
  }
  putCategoryForBYS(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.category_bys}${body.store_id}/${body.id}/`, body);
  }
  deleteCategoryForBYS(body: any) {
    return this.http.delete(`${environment.urlPrefix}${constant.category_bys}${body.store_id}/${body.id}/`);
  }
  getCategoryDropDownList(store_id: any) {
    return this.http.get(`${environment.urlPrefix}${constant.category_bys_dropdown}${store_id}/`);
  }

  categorySettings(category_id: string, type?: string) {//type is for Store API calling
    return this.http.get(`${environment.urlPrefix}${constant.category_settings_bys}${type ? type + '/' : ''}${category_id}/`);
  }
  updateCategorySettings(body: any, type?: string) {
    return this.http.put(`${environment.urlPrefix}${constant.category_settings_bys}${type ? type + '/' : ''}${body.category_id}/`, body);
  }
  categoryBooleanSettingsBys(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.category_boolean_settings_bys}${body.id}/`, body);
  }
  getAskShowUserFields(type?: string) {
    let filter = '';
    if (type) filter = `?show_type=${type}`;
    return this.http.get(`${environment.urlPrefix}${constant.ask_show_user_bys}${filter}`);
  }
  getAskOrShowUserFields(category_id: string, api_differ?: string, type?: string, page_num?: string) {
    let filter = '';
    if (type) filter = `?show_type=${type}`;
    if (page_num) filter = `?page_num=${page_num}`;
    return this.http.get(`${environment.urlPrefix}${constant.ask_show_users_submission}${api_differ ? api_differ + '/' : ''}${category_id}/${filter}`);
  }
  createAskOrShowUserFields(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.ask_show_user_bys}${body.category_id}/`, body);
  }
  getProductsByBys(store_id: string, searchTerm?: any) {
    if (searchTerm) {
      return this.http.get(`${environment.urlPrefix}${constant.products_all_bys}${store_id}/?name=${searchTerm}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.products_all_bys}${store_id}/`);
    }
  }
  createProductByBys(store_id: string, body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.products_bys}${store_id}/`, body);
  }
  getProductByBysById(category_id: string, product_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.products_bys}${product_id}/category/${category_id}/`);
  }
  createProductbyCategoryId(body: any, type?: string) {
    return this.http.post(`${environment.urlPrefix}${constant.ask_show_users_submission}${type ? type + '/' : ''}${type ? body.store_id : body.category_id}/`, body);
  }
  updatefieldByCategoryId(body: any, type?: string) {
    return this.http.put(`${environment.urlPrefix}${constant.ask_show_users_submission}${type ? type + '/' : ''}${type ? body.store_id : body.category_id}/${body.id}/`, body);
  }
  deletefieldByCategoryId(body: any, type?: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.ask_show_users_submission}${type ? type + '/' : ''}${body.category_id}/${body.id}/`);
  }
  updateProductbyBys(store_id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.products_bys}${body.id}/category/${body.categories}/`, body);
  }
  deleteProductbyBys(body: any) {
    return this.http.delete(`${environment.urlPrefix}${constant.products_bys}${body.id}/category/${body.category_id}/`);
  }
  getVariantsCombinationsbyBys(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.variants_combo_bys}`, body);
  }
  updateVariantsbyBys(productId: string, body: any){
    return this.http.put(`${environment.urlPrefix}${constant.products_bys}${productId}/variants/${body.id}/`, body);
  }
  deleteVariantsbyBys(variantId: string, productId: any){
    return this.http.delete(`${environment.urlPrefix}${constant.products_bys}${productId}/variants/${variantId}/`);
  }
  getTaxesbyBys() {
    return this.http.get(`${environment.urlPrefix}${constant.bys_tax}`);
  }
  createTaxesbyBys(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.bys_tax}`, body);
  }
  updateTaxesbyBys(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.bys_tax}${body.id}/`, body);
  }

  getTagsbyBys(storeId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.bys_tags}${storeId}/`);
  }
  createTag(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.bys_tags}${body.store_id}/`, body);
  }
  updateTagbyBys(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.bys_tags}${body.store_id}/${body.id}/`, body);
  }

  getBrandsbyBys(storeId: string){
    return this.http.get(`${environment.urlPrefix}${constant.bys_brands}${storeId}/`);
  }
  createBrands(body: any){
    return this.http.post(`${environment.urlPrefix}${constant.bys_brands}${body.store_id}/`, body);
  }
  getManufacturersbyBys(storeId: string){
    return this.http.get(`${environment.urlPrefix}${constant.bys_manufacturers}${storeId}/`);
  }
  createManufacturers(body: any){
    return this.http.post(`${environment.urlPrefix}${constant.bys_manufacturers}${body.store_id}/`, body);
  }

  getAddons(category_id: string, type?: string) {//type is for Store API calling
    return this.http.get(`${environment.urlPrefix}${constant.addons_bys}${type ? type + '/' : ''}${category_id}/`);
  }
  getAddonsByCategory_id(main_category_id: string, category_id: string,type?: any) {
    return this.http.get(`${environment.urlPrefix}${constant.addons_bys}${type ? type + '/' : ''}${main_category_id}/${category_id}/`);
  }
  createAddonForcategoryId(body: any, type?: string) {
    return this.http.post(`${environment.urlPrefix}${constant.addons_bys}${type ? type + '/' : ''}${body.category_id}/`, body);
  }
  updateAddonForcategoryId(body: any, type?: string) {
    return this.http.put(`${environment.urlPrefix}${constant.addons_bys}${type ? type + '/' : ''}${body.category_id}/${body.id}/`, body);
  }
  deleteAddon(body: any, type?: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.addons_bys}${type ? type + '/' : ''}${body.category_id}/${body.id}/`);
  }

  getCategoryAddon(type: string, type_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.bys_addon_category}?${type}=${type_id}`);
  }
  getAllAddon(payload: any) {
    return this.http.get(`${environment.urlPrefix}${constant.bys_addon_category}?category=${payload.category}&store=${payload.store}`);
  }
  createCategoryAddon(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.bys_addon_category}`, body);
  }
  updateCategoryAddon(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.bys_addon_category}`, body);
  }
  deleteCategoryAddon(category_id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.bys_addon_category}${category_id}/`);
  }

  getCoupons(type: string, id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.bys_coupons}?${type}=${id}`);
  }
  getOrgCoupons(page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.bys_coupons}?page=${page}&offset=20`);
  }
  createCoupon(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.bys_coupons}`, body);
  }
  updateCoupon(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.bys_coupons}${body.id}/`, body);
  }
  deleteCoupon(coupon_id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.bys_coupons}${coupon_id}/`);
  }

  getSeoMetaData(type: string,id: string){
    return this.http.get(`${environment.urlPrefix}${constant.seo_meta_bys}?${type}=${id}`);
  }

  postSeoMetadata(body: any){
    return this.http.post(`${environment.urlPrefix}${constant.seo_meta_bys}`,body);
  }

  putSeoMetadata(body: any){
    return this.http.put(`${environment.urlPrefix}${constant.seo_meta_bys}`,body);
  }

  getInventoryProducts(store_id: any){
    return this.http.get(`${environment.urlPrefix}${constant.inventory_products}${store_id}/`);
  }

  getQRCodes(page: number,filters?: any){
    let filter: any = '';
    if(filters) filter = `&${filters}`;
    return this.http.get(`${environment.urlPrefix}${constant.QRcode}?page=${page}&offset=20${filter}`);
  }

  getQRCodeById(body: any){
    return this.http.get(`${environment.urlPrefix}${constant.QRcode}${body.store}/${body.id}/`);
  }

  postQRCode(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.QRcode}`,body);
  }

  putQRCode(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.QRcode}${body.id}/`,body);
  }

  deleteQRCode(id: string){
    return this.http.delete(`${environment.urlPrefix}${constant.QRcode}${id}/`);
  }

  getChatbotApps(){
    return this.http.get(`${environment.messageUrl}/apps?type=app`);
  }

  getAllQrCodes(page: number){
    return this.http.get(`${environment.urlPrefix}${constant.all_qrcodes}?page=${page}&offset=20`);
  }

  getAllQrCodesById(qr_id: string){
    return this.http.get(`${environment.urlPrefix}${constant.all_qrcodes}${qr_id}/`);
  }

  postAllTypeQrCodes(body: any){
    return this.http.post(`${environment.urlPrefix}${constant.all_qrcodes}`,body);
  }

  putAllTypeQrCodes(body: any){
    return this.http.put(`${environment.urlPrefix}${constant.all_qrcodes}${body.id}/`,body);
  }

  deleteAllTypQRCode(id: string){
    return this.http.delete(`${environment.urlPrefix}${constant.all_qrcodes}${id}/`);
  }

  getAuthobjects(){
    return this.http.get(`${environment.urlPrefix}${constant.object_types}?auth=True`);
  }
}


import { Injectable } from '@angular/core';
import { BehaviorSubject, map, of } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { constant } from '../constants';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  featuresData: any;
  featureManagement = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient, private router: Router) {
  }

  userAuthenticationPermission() {
    if (sessionStorage.getItem('currentUser')) {
      return of(true);
    }
    return of(false);
  }
  userAuthentication() {
    if (sessionStorage.getItem('currentUser')) {
      this.router.navigate(['/crm']);
      return of(true);
    }
    return of(false);
  }

  moduleFeatureAccessPermission(type: string, iFrameRouters?: string) {
    if (!this.featuresData) {
      return this.http.get(`${environment.urlPrefix}${constant.feature_management}`).pipe(map((resp: any) => {
        this.featuresData = resp?.data;
        this.featureManagement.next(resp);
        let conditions = true;
        if (iFrameRouters) {
          conditions = this.onReturnModuleBasedOnUrl(iFrameRouters);
        }
        return this.moduleWiseSegregate(type) && conditions;
      }));
    } else {
      let conditions = true;
      if (iFrameRouters) {
        conditions = this.onReturnModuleBasedOnUrl(iFrameRouters);
      }
      return of(this.moduleWiseSegregate(type) && conditions);
    }
  }

  onReturnModuleBasedOnUrl(url: string) {
    const urlParams = new URLSearchParams(url);
    const folder = urlParams.get('folder');
    let module = '';
    switch (folder) {
      case 'workflows':
        module = 'workflow_automation';
        break;
      case 'marketing':
        module = 'marketing_automation';
        break;
      case 'push':
        module = 'push_notification';
        break;
      case 'apps':
        module = 'apps';
        break;
      case 'smartforms':
        module = 'smart_form';
        break;
      case 'smartpopups':
        module = 'smart_pop';
        break;
      case 'inbox':
        module = 'email_inbox';
        break;
      default:
        break;
    }
    return this.featuresData[module];
  }

  moduleWiseSegregate(type: string) {
    let modules = false;
    switch (type) {
      case 'crm':
        modules = this.featuresData.contact || this.featuresData.enquiry || this.featuresData.company;
        break;
      case 'client':
        modules = this.featuresData.client || this.featuresData.projects;
        break;
      case 'campaign':
        modules = this.featuresData.campaign || this.featuresData.contacts_list;
        break;
      case 'products':
        modules = this.featuresData.products_service ||
          this.featuresData.service_setup;
        break;
      case 'home':
        modules = this.featuresData.home_task ||
          this.featuresData.home_activity ||
          this.featuresData.home_appointments ||
          this.featuresData.home_orders ||
          this.featuresData.home_calendar_bookings;
        break;
      case 'setup':
        modules = this.featuresData.appointment ||
          this.featuresData.meetings ||
          this.featuresData.products_service ||
          this.featuresData.service_setup ||
          this.featuresData.form_builder ||
          this.featuresData.knowledge_base ||
          this.featuresData.announcements ||
          this.featuresData.automation_app ||
          this.featuresData.smart_pop ||
          this.featuresData.smart_form ||
          this.featuresData.apps ||
          this.featuresData.push_notification ||
          this.featuresData.marketing_automation ||
          this.featuresData.workflow_automation ||
          this.featuresData.dynamic_app;
        break;
      case 'automations':
        modules = this.featuresData.smart_pop ||
          this.featuresData.smart_form ||
          this.featuresData.apps ||
          this.featuresData.push_notification ||
          this.featuresData.marketing_automation ||
          this.featuresData.email_inbox ||
          this.featuresData.workflow_automation;
        break;
      default:
        break;
    }
    return modules;
  }

  featureAccessPermission(keyName: string, nestedCondition?: string) {
    if (!this.featuresData) {
      return this.http.get(`${environment.urlPrefix}${constant.feature_management}`).pipe(map((resp: any) => {
        this.featuresData = resp?.data;
        this.featureManagement.next(resp);
        if (nestedCondition) {
          return this.featuresData[keyName] && this.featuresData[nestedCondition] ? this.featuresData[keyName] : false;
        } else {
          return this.featuresData[keyName] ? this.featuresData[keyName] : false;
        }
      }));
    } else {
      if (nestedCondition) {
        return this.featuresData[keyName] && this.featuresData[nestedCondition] ? of(this.featuresData[keyName]) : of(false);
      } else {
        return this.featuresData[keyName] ? of(this.featuresData[keyName]) : of(false);
      }

    }
  }
  homeTabsAccessPermissions() {
    let redirectTo = '';
    if (this.featuresData && this.featuresData.home_task) {
      redirectTo = '/home/task';
    } else if (this.featuresData && this.featuresData.home_appointments) {
      redirectTo = '/home/appointments';
    } else if (this.featuresData && this.featuresData.home_activity) {
      redirectTo = '/home/activity';
    } else if (this.featuresData && this.featuresData.home_orders) {
      redirectTo = '/home/orders';
    } else if (this.featuresData && this.featuresData.home_calendar_bookings) {
      redirectTo = '/home/calendar-bookings';
    } else {
      redirectTo = '/user-profile';
    }
    return redirectTo;
  }
}

import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutsComponent } from './core/layouts/layouts.component';
import { Page404Component } from './pages/page404/page404.component';
import { AuthGuardService } from './core/service/auth-guard.service';
import { map } from 'rxjs';
import { UnauthorisedComponent } from './pages/unauthorised/unauthorised.component';
import { LoginComponent } from './pages/auth/login/login.component';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), canMatch: [() => {
      return inject(AuthGuardService).userAuthenticationPermission().pipe(map(auth => !auth));
    }]
  },
  {
    path: 'login', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), canMatch: [() => {
      return inject(AuthGuardService).userAuthentication().pipe(map(auth => !auth));
    }]
  },
  {
    path: 'signup', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), canMatch: [() => {
      return inject(AuthGuardService).userAuthentication().pipe(map(auth => !auth));
    }]
  },
  {
    path: 'verify_account', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), canMatch: [() => {
      return inject(AuthGuardService).userAuthentication().pipe(map(auth => !auth));
    }]
  },
  {
    path: 'forgot-password', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), canMatch: [() => {
      return inject(AuthGuardService).userAuthentication().pipe(map(auth => !auth));
    }]
  },
  {
    path: '',  component: LayoutsComponent,
    loadChildren: () => import('./core/layouts/layouts.module').then(m => m.LayoutsModule), 
    canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).userAuthenticationPermission().pipe(map(auth => auth || router.createUrlTree(['/login'])));
    }]
  },
  {
    path: 'unauthorised', 
    component: UnauthorisedComponent
  },
  { path: '**', pathMatch: 'full', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }

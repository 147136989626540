import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { filter, map, Observable, Subscription } from 'rxjs';
import { MENU } from '../../data/menu_list';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { ChatService } from '../../service/chat.services';
import { ProfileService } from '../../service/profile.service';
import { AuthService } from '../../service/auth.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'menu-overlay-pane'
      }
    }
  ]
})
export class SidebarComponent implements OnDestroy {

  menuList: any[];
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map((result: any) => result.matches)
    );
  menu_opend = false;
  // @ViewChild('levelOneTrigger') trigger!: MatMenuTrigger;
  prevButtonTrigger: any;
  enteredButton = false;
  trigger: any;
  liveChatCount = 0;
  teamChatCount = 0;
  conversationCount = 0;
  loggedInUser: any;
  chatCuntSubs!: Subscription;
  featuresData: any;
  botChatCount: any;
  currentTab = 'CRM';

  constructor(private breakpointObserver: BreakpointObserver, private chatServices: ChatService, private profile_service: ProfileService, private authService: AuthService, private router: Router) {
    this.menuList = MENU;
    this.getChatCounts();
    this.getFeatues();
    this.chatCuntSubs = this.chatServices.updateCountsSub$.subscribe(() => {
      this.getChatCounts();
    });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    const currentUrl = window.location.pathname;
    this.onGettingCurrentTab(currentUrl);
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((events: any) => {
        this.onGettingCurrentTab(events.url);
      });
  }

  menuOpened(event: any) {
    this.menu_opend = true;
    this.trigger = event;
    event.openMenu();
  }

  onGettingCurrentTab(pathname:string){
    if(pathname.includes('/home/')){
      this.currentTab = 'Dashboard';
    }else if(pathname.includes('/crm/')){
      this.currentTab = 'CRM';
    } else if (pathname.includes('/pipelines/')){
      this.currentTab = 'Flows';
    }else if(pathname.includes('/clients/')){
      this.currentTab = 'Clients';
    }else if(pathname.includes('/setup')){
      this.currentTab = 'SetUp';
    }else if(pathname.includes('/live_chat')){
      this.currentTab = 'Live Chat';
    }else if(pathname.includes('bot_chat')){
      this.currentTab = 'Bot Chat';
    } else if (pathname.includes('/conversation')){
      this.currentTab = 'Messages';
    } else if (pathname.includes('/taskboard')){
      this.currentTab = 'Boards';
    } else if (pathname.includes('/finance') || pathname.includes('/teamchat') || pathname.includes('/filemanager') || pathname.includes('/reports')){
      this.currentTab = 'More';
    } else {
      this.currentTab = '';
    }
  }

  onSubMENUClicked(label:string){
    this.currentTab = label;
  }

  menuClosed(trigger: any) {
    this.menu_opend = false;
    this.trigger = trigger;
    trigger.closeMenu();
  }

  buttonEnter(trigger?: any) {
    this.enteredButton = true;
    if (!trigger && this.trigger) {
      this.trigger.closeMenu();
    } else if (trigger) {
      if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.menu_opend = false;
        trigger.openMenu();
      }
      else if (!this.menu_opend) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    }
  }

  buttonLeave(trigger: any, button: any) {
    this.trigger = trigger;
    if (this.enteredButton && !this.menu_opend) {
      trigger.closeMenu();
    }
  }

  getChatCounts() {
    this.chatServices.getChatCount().subscribe((chatCount: any) => {
      console.log(chatCount, "chat Count");
      const count_data = chatCount.data;
      this.liveChatCount = count_data.unreadAllLiveChatsCount;
      this.botChatCount = count_data.allBotChatsUnreadCount;
      this.teamChatCount = count_data.unreadAllTeamChatsCount;
      this.conversationCount = count_data.unreadAllConversationsCount;
    });
  }

  resetToFirstTab(menuGroup?: any,event?:any) {
    if (menuGroup) {
      this.currentTab = menuGroup?.label;
      if(menuGroup?.subItems){
        event.preventDefault();
      }else{
        this.router.navigate([menuGroup.link]);
      }
      if (menuGroup.id === 1) {
        const tabIndex = this.homeTabsAccessPermissions();
        this.profile_service.passResetToTask(tabIndex);
      } else {
        this.profile_service.passResetToTask(menuGroup.id);
      }
    } else {
      this.currentTab = 'CRM';
      const tabIndex = this.homeTabsAccessPermissions();
      this.profile_service.passResetToTask(tabIndex);
    }
  }
  homeTabsAccessPermissions() {
    let tabIndex = 1;
    if (this.featuresData && this.featuresData.home_task) {
      tabIndex = 1;
    } else if (this.featuresData && this.featuresData.home_appointments) {
      tabIndex = 2;
    } else if (this.featuresData && this.featuresData.home_orders) {
      tabIndex = 3;
    } else if (this.featuresData && this.featuresData.home_calendar_bookings) {
      tabIndex = 4;
    } else if (this.featuresData && this.featuresData.home_activity) {
      tabIndex = 5;
    } else {
      tabIndex = 1;
    }
    return tabIndex;
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      this.menuList.forEach((elem: any) => {
        if (elem.subItems && elem.subItems.length > 0) {
          const searchedItem = elem.subItems.find((ele: any) => {
            if (ele.name[0] === 'client') {
              return (this.featuresData[ele.name[0]]);
            } else if (ele.name[0] === 'projects') {
              return (this.featuresData[ele.name[0]] && this.featuresData['client']);
            } else {
              return this.featuresData[ele.name[0]];
            }
          });
          if (!searchedItem) {
            elem.isEnable = false;
          } else {
            elem.isEnable = true;
          }
        } else if (elem.launchDlKeys) {
          const searchedItem = elem.launchDlKeys.find((ele: any) => !this.featuresData[ele]);
          if (searchedItem) {
            elem.isEnable = false;
          } else {
            elem.isEnable = true;
          }
        }
      });
    });
  }

  ngOnDestroy() {
    this.chatCuntSubs.unsubscribe();
  }
}

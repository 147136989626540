<div class="row pt-3 ">
    <h6 class="text-primary fw-600">Whatsapp Templates</h6>
</div>
<div class="mt-1">
    <div class="row">
        <div class="col-sm-4">
            <div class="tocustomertab">
                <mat-tab-group class="tocustomertab">
                    <mat-tab label="To Customer">
                        <div class="appointments-list">
                            <mat-list>
                                <mat-list-item>
                                    <div class="row">
                                        <div class="col-sm-10 font-size-13">
                                            Appointment Approved
                                        </div>
                                        <div class="col-sm-2 text-end">
                                            <mat-icon
                                                class="d-inline-flex align-items-center p-0 font-size-9 dot-approved"
                                                matend>circle</mat-icon>
                                        </div>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item>
                                    <div class="row">
                                        <div class="col-sm-10 font-size-13">
                                            Appointment Approved
                                        </div>
                                        <div class="col-sm-2 text-end">
                                            <mat-icon
                                                class="d-inline-flex align-items-center p-0 font-size-9 dot-approved"
                                                matend>circle</mat-icon>
                                        </div>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item>
                                    <div class="row">
                                        <div class="col-sm-10 font-size-13">
                                            Appointment Approved
                                        </div>
                                        <div class="col-sm-2 text-end">
                                            <mat-icon
                                                class="d-inline-flex align-items-center p-0 font-size-9 dot-approved"
                                                matend>circle</mat-icon>
                                        </div>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                            </mat-list>

                        </div>
                    </mat-tab>
                    <mat-tab label="To Employee">
                        <div>
                            <h5>To Employee</h5>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div class="col-sm-8">
            <div class="pt-3 d-flex align-items-center">
                <label class="switch ms-2">
                    <input type="checkbox" checked>
                    <span class="slider round"></span>
                </label>
                <span class="px-3 fw-600 font-size-13">Appointment Approved</span>
            </div>
            <div class="py-3">
                <label
                    class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Appointment</label>
                <label
                    class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Customer</label>
                <label
                    class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Employee</label>
                <label
                    class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Service</label>
                <label
                    class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Location</label>
                <label
                    class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13 mt-2">Company</label>

            </div>
            <div class="ms-2">
                <div class="row mt-3">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="mb-2 fw-600 font-size-13 text-primary">Subject</label>
                        <mat-form-field appearance="outline" class="w-100 mat-hint-none mb-2">
                            <input style="outline: none" matInput placeholder="Appointment Approved" />
                        </mat-form-field>
                        <p class="d-flex align-items-center">
                            <span class="me-3 ms-2">Add Cc</span>
                            <span>Add Bcc</span>
                        </p>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
            <div class="py-3">
                <label
                    class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Appointment</label>
                <label
                    class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Customer</label>
                <label
                    class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Employee</label>
                <label
                    class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Service</label>
                <label
                    class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13">Location</label>
                <label
                    class=" bg-list d-inline-flex align-items-center p-2 border-radius-8 font-size-12 mx-2 font-size-13 mt-2">Company</label>

            </div>
            <div class="mt-4 ms-2">
                <label class="mb-2 fw-600 font-size-13 text-primary">Message</label>
                <ckeditor [editor]="Editor" [(ngModel)]="Message" [config]="ckconfigQue"></ckeditor>
            </div>
        </div>
    </div>
</div>
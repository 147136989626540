<div class="modal-header">
    <h5 *ngIf="!lead?.id" class="modal-title fw-600">Add Lead</h5>
    <h5 *ngIf="lead?.id" class="modal-title fw-600">Update Lead</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>
<div *ngIf="showErrorMessage" class="px-3 pb-0 pt-1" style="box-shadow: 0 4px 14px #e1e1e1;">
    <div>
        <p class="m-0 font-size-13"> <span class="text-danger font-size-13 fw-500">NOTE : </span> You are not
            Authorised to view or use
            <ng-container *ngFor="let fields of revokedSysFieldsList | keyvalue; let i = index">
                <span class="text-danger  fw-600" *ngIf="fields.value.is_visible">{{ fields.key.replace("_", " ") |
                    titlecase }}{{ i < (revokedSysFieldsList | keyvalue).length - 1 ? ', ' : '' }}</span>
            </ng-container>
            feature. Please remove them as required field from <span class="font-size-13 fw-600">Settings > Custom Fields > Lead > System field table</span>.
        </p>
    </div>
</div>
<div class="modal-body leadform">
    <form [formGroup]="leadForm">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{leadJson.label_name}} <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="{{leadJson.label_name}}"
                        formControlName="requirement_title" #myFirstInput />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['requirement_title'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['requirement_title'].errors['required']"> {{leadJson.label_name}}
                            {{errorJson.required}}</span>
                        <span *ngIf="f['requirement_title'].errors['pattern']">Only alphanumeric are accepted.</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 ">{{leadJson.label_company}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="company_id" placeholder="{{leadJson.label_company}}">
                        <mat-option *ngFor="let company of companiesListArray"
                            [value]="company.id">{{company.name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['company_id'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['company_id'].errors['required']"> {{leadJson.label_company}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div> -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">Contact Name <small class="text-danger">*</small></label>
                <ng-select class="form-control" [items]="contactListArray"
                    bindLabel="first_name" [addTag]="addNewName" [multiple]="false" (change)="onSelectContact($event,'name')"
                    placeholder="Contact name" (search)="searchSubject.next({text: $event.term, type: 'name'})" formControlName="name"
                    (clear)="clearContactFields($event)">
                </ng-select>
                <!-- <mat-form-field appearance="outline" class="w-100" *ngIf="leadForm.value.id">
                    <mat-select formControlName="name" placeholder="contact name">
                        <mat-option *ngFor="let member of contactListArray"
                            [value]="member.first_name">{{member.first_name}}</mat-option>
                    </mat-select>
                    <mat-error class="error d-flex" *ngIf="submitted && f['name'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['name'].errors['required']"> Name
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field> -->
                <div *ngIf="submitted && f['name'].errors"
                    class="text-danger error font-size-12 d-flex align-items-center">
                    <mat-icon class="font-size-12 d-flex align-items-center w-3 me-1">info</mat-icon>
                    <span *ngIf="f['name'].errors['required']">Contact name
                        {{errorJson.required}}</span>
                    <span *ngIf="f['name'].errors['pattern']">{{errorJson.invalid}} contact name</span>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 ">{{leadJson.label_email}} <small class="text-danger" *ngIf="hasRequiredValidator('email')">*</small></label>
                <ng-select class="form-control" [items]="contactListArray"
                    bindLabel="email" [addTag]="addNewEmail" [multiple]="false" (change)="onSelectContact($event,'email')"
                    placeholder="Email" (search)="searchSubject.next({text: $event.term, type: 'email'})" formControlName="email"
                    (clear)="clearContactFields($event)">
                </ng-select>
                <div *ngIf="submitted && f['email'].errors"
                    class="text-danger error font-size-12 d-flex align-items-center">
                    <mat-icon class="font-size-12 d-flex align-items-center w-3 me-1">info</mat-icon>
                    <span *ngIf="f['email'].errors['required']">{{leadJson.label_email}}
                        {{errorJson.required}}</span>
                    <span *ngIf="f['email'].errors['pattern']">{{errorJson.invalid}} {{leadJson.label_email}}</span>
                </div>
                <mat-form-field appearance="outline" class="w-100 d-none">
                    <input style="outline: none" matInput placeholder={{leadJson.label_email}}
                        formControlName="email" />
                    <mat-error class="error text-danger d-flex align-items-center"
                        *ngIf="submitted && f['email'].errors">
                        <mat-icon class="font-size-12 d-flex align-items-center me-1">info</mat-icon>
                        <span *ngIf="f['email'].errors['required']">{{leadJson.label_email}}
                            {{errorJson.required}}</span>
                        <span *ngIf="f['email'].errors['pattern']">{{errorJson.invalid}} {{leadJson.label_email}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{leadJson.label_phone}} <small class="text-danger" *ngIf="hasRequiredValidator('phone_number')">*</small></label>
                <div class="d-flex">
                    <mat-form-field appearance="outline" class="me-1 mat-hint-none">
                        <mat-select placeholder="Country code" formControlName="ccode">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredBanks | async"
                                [ngClass]="{'d-none': country.IAC === ''}"
                                value={{country.IAC}}>{{country.IAC}}</mat-option>
                        </mat-select>
                        <!-- <mat-error class="error text-danger d-flex" *ngIf="submitted && f['ccode'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['ccode'].errors['required']">Country code
                                {{errorJson.required}}</span>
                        </mat-error> -->
                    </mat-form-field>
                    <ng-select class="form-control" [items]="contactListArray" style="height: fit-content;"
                        bindLabel="phone_number" [addTag]="addNewphonenumber" [multiple]="false" (change)="onSelectContact($event,'phone_number')"
                        placeholder="Phone Number" (search)="searchSubject.next({text: $event.term, type: 'phone_number'})" formControlName="phone_number"
                        (clear)="clearContactFields($event)">
                    </ng-select>
                    <mat-form-field appearance="outline" class="w-100 d-none">
                        <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                            placeholder="{{leadJson.label_phone}}" formControlName="phone_number" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phone_number'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['phone_number'].errors['required']">Phone number
                                {{errorJson.required}}</span>
                            <span
                                *ngIf="f['phone_number'].errors['pattern'] || f['phone_number'].errors['minlength'] || f['phone_number'].errors['maxlength']">{{errorJson.invalid}}
                                phone number</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="submitted && f['phone_number'].errors"
                    class="text-danger error font-size-12 d-flex align-items-center">
                    <mat-icon class="font-size-12 d-flex align-items-center w-3 me-1">info</mat-icon>
                    <span *ngIf="f['phone_number'].errors['required']">Phone number
                        {{errorJson.required}}</span>
                    <span *ngIf="f['phone_number'].errors['pattern']">{{errorJson.invalid}} phone number</span>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 ">{{leadJson.label_tag}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="tags" placeholder="Select Tag">
                        <mat-option [value]="item.id" *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{leadJson.Source}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="channel" placeholder={{leadJson.Source}}>
                        <mat-option *ngFor="let item of channels"
                            [value]="item.value">{{item.display_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 ">{{leadJson.label_owner}} <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="owner" placeholder={{leadJson.label_owner}}>
                        <mat-option *ngFor="let member of staffMembers" (click)="onSelectOwner(member)"
                            [value]="member.id">{{member.fname}}
                            {{member.lname}}</mat-option>
                    </mat-select>
                    <mat-error class="error d-flex" *ngIf="submitted && f['owner'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['owner'].errors['required']"> {{leadJson.label_owner}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">Assignee</label>
                <ng-select class="form-control mb-4" [items]="staffMembers" bindValue="id" bindLabel="fname"
                    [addTag]="false" [multiple]="true" [ngClass]="{'disabled-field':lead?.owner && ( lead.owner !== loggedInUser.data.id && loggedInUser.data.role !== 'ADMIN')
                }" placeholder="Select staff" formControlName="assignee">
                </ng-select>
                <!-- <div class="error d-flex mt-2" *ngIf="submitted && f['assignee'].errors">
                    <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                    <span *ngIf="f['assignee'].errors['required']"> Staff
                        {{errorJson.required}}</span>
                </div> -->
            </div>

            <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{leadJson.product}}</label>
                <ng-select class="form-control" [items]="products_list" bindLabel="name" bindValue="id"
                    [multiple]="true" placeholder="product" formControlName="product">
                </ng-select> -->
            <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="product" placeholder="Select Product">
                        <mat-option *ngFor="let product of products_list"
                            [value]="product.id">{{product.name}}</mat-option>
                    </mat-select> -->
            <!-- <mat-error class="error d-flex" *ngIf="submitted && f['product'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['product'].errors['required']"> {{leadJson.product}}
                            {{errorJson.required}}</span>
                    </mat-error> -->
            <!-- </mat-form-field> -->
            <!-- </div> -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="mb-2 fw-600 font-size-13">Due Date & Time</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput [ngxMatDatetimePicker]="picker" [min]="minDate" placeholder="Choose a date & time"
                        formControlName="due_date">
                    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
            <!-- <div formArrayName="custom_lead_fields">
                <div *ngFor="let field of custom_lead_fields.controls; let i=index">
                  <div class="row" [formGroupName]="i">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label class="fw-600 mb-2 ">{{field.value.label}}</label>
                      <mat-form-field appearance="outline" class="w-100" *ngIf="field.value.data_type === 'text'">
                        <input style="outline: none" matInput placeholder="{{field.value.label}}" formControlName="data" />
                      </mat-form-field> -->

            <!-- <mat-form-field appearance="outline" class="w-100" *ngIf="field.value.data_type === 'dropdown'">
                        <mat-select formControlName="data" placeholder="{{field.value.label}}">
                          <mat-option *ngFor="let option of options" [value]="option.id">{{option.name}}</mat-option>
                        </mat-select>
                      </mat-form-field> -->
            <!-- </div>
                  </div>
                </div>
              </div> -->

            <ng-container *ngFor="let field of system_lead_fields.controls; let i=index">
                <ng-container [ngTemplateOutlet]="fields"
                    [ngTemplateOutletContext]="{field:field, i:i, formArrayName: 'system_lead_fields',fieldType:'sys'}">
                </ng-container>
            </ng-container>
            <div class="col-md-12 mb-3 mt-3 col-sm-12">
                <label class="fw-600 mb-2 ">Description</label>
                <!-- <mat-form-field appearance="outline" class="w-100"> -->
                <ckeditor [editor]="Editor" placeholder="Description" formControlName="description"
                    [config]="ckconfigQue" class="ckeditor-height" #editorComponent
                    [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'ck-error':''"
                    (ready)="onReady($event,editorComponent)" (keyup)="editorkeyEvent(editorComponent)">
                </ckeditor>
                <small [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                    class="font-size-10">Maximum
                    1000 characters are accepted.</small><small
                    [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                    class="font-size-10 ps-1">(Character limit:
                    {{ckeditorLimit.actualLimit}}/{{ckeditorLimit.maxLimit}})</small>
                <!-- <textarea style="outline: none" matInput placeholder="Description" formControlName="description" style="height:100px" maxlength="1000"></textarea> -->
                <!-- </mat-form-field> -->
            </div>

            <hr class="mt-2">
            <h5>
                Additional Fields
            </h5>
            <ng-container *ngFor="let field of custom_lead_fields.controls; let i=index">
                <ng-container [ngTemplateOutlet]="fields" *ngIf="field.value.on_form"
                    [ngTemplateOutletContext]="{field:field, i:i, formArrayName: 'custom_lead_fields',fieldType:'custm'}">
                </ng-container>
            </ng-container>



        </div>



    </form>
</div>

<div class="mt-3 d-flex justify-content-between modal-footer">
    <div class="d-flex cursor" routerLink="settings/custom-fields" [queryParams]="{ activeLink: 'Leads'}"
        (click)="ngbActiveModal.dismiss()">
        <span class="material-icons text-primary">settings_applications</span>
        <span class="cursor"></span> {{leadJson.customize_text}}
    </div>
    <div class="d-flex">
        <button class="btn btn-light me-2" type="button" (click)="ngbActiveModal.dismiss()">
            {{leadJson.cancel_btn}}
        </button>
        <button class="btn btn-primary" [disabled]="loading || (ckeditorLimit.actualLimit > ckeditorLimit.maxLimit) || showErrorMessage"
            type="submit"
            (click)="ckeditorLimit.actualLimit <= ckeditorLimit.maxLimit && !showErrorMessage && onSubmit()">{{leadJson.save_btn}}</button>
    </div>
</div>


<ng-template #fields let-field='field' let-i="i" let-formArrayName="formArrayName" let-fieldType="fieldType">
    <form [formGroup]="leadForm" class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
        <div [formArrayName]="formArrayName">
            <div *ngIf="field.value.on_form">
                <div class="row" [formGroupName]="i">
                    <div [ngStyle]="{'opacity':fieldType === 'sys' && revokedSysFieldsList[field.value.label] ? '0.5' : '1'}" >
                        <label class="fw-600 mb-2 ">{{field.value.label.replace("_", " ") |
                            titlecase}}</label>&nbsp;
                        <small class="text-danger" *ngIf="field.value.is_mandatory">*</small>

                        <ng-container *ngIf="field.value.data_type === 'TEXT'  ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'TEXT_AREA'">
                            <ckeditor [editor]="Editor" placeholder="write a message" formControlName="data"
                                [config]="ckconfigQue" class="ckeditor-height" #customEditorComponents
                                (ready)="onCustReady($event,fieldType+i)"
                                [ngClass]="ckeditorLimitLists[fieldType+i]?.actualLimit > ckeditorLimitLists[fieldType+i]?.maxLimit ? 'ck-error':''"
                                (keyup)="custEditorkeyEvent($event,customEditorComponents,fieldType+i)">
                            </ckeditor>
                            <small
                                [ngClass]="ckeditorLimitLists[fieldType+i]?.actualLimit > ckeditorLimitLists[fieldType+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10">Maximum
                                1000 characters are accepted.</small><small
                                [ngClass]="ckeditorLimitLists[fieldType+i]?.actualLimit > ckeditorLimitLists[fieldType+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10 ps-1">(Character
                                limit:
                                {{ckeditorLimitLists[fieldType+i]?.actualLimit}}/{{ckeditorLimitLists[fieldType+i]?.maxLimit}})</small>
                            <!-- <textarea class="form-control background-color" rows="2" cols="30" formControlName="data"
                                placeholder="write a message"></textarea> -->
                            <div class="error d-flex align-items-center"
                                *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", "
                                    ")}}</span>&nbsp;{{errorJson.required}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'CHECK_BOX'">
                            <div *ngFor="let options of field.value.options">
                                <mat-checkbox [value]="options" (change)="toggleCheckbox(options, i)">
                                    {{options}}
                                </mat-checkbox>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'SELECT_DROPDOWN' ">
                            <div >
                                <ng-container *ngIf="field.value.options.length > 0">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select formControlName="data">
                                            <mat-option [value]="null">
                                              None
                                            </mat-option>
                                            <mat-option *ngFor="let options of field.value.options" [value]="options">
                                                {{options}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span class="text-capitalize">{{field.value.label.replace("_", "
                                                ")}}</span>&nbsp;{{errorJson.required}}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-container>

                                <ng-container *ngIf="field.value.options.length === 0">
                                    <mat-form-field appearance="outline" class="w-100"
                                        *ngIf="field.value.label !== 'company'">
                                        <mat-select formControlName="data"
                                            placeholder="{{field.value.label | titlecase}}">
                                            <ng-container *ngIf="field.value.label === 'country'">
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                        placeholderLabel="Search"
                                                        noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let country of filteredBanks | async"
                                                    value={{country.Entity}}>{{country.Entity}}</mat-option>
                                            </ng-container>
                                            <ng-container *ngIf="field.value.label === 'currency'">
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="bankFilterCurrencyCtrl"
                                                        placeholderLabel="Search"
                                                        noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let currency of filteredCurrencyBanks | async"
                                                    value='{{currency.abbreviation +", "+currency.symbol}}'>{{currency.currency
                                                    + " (" + currency.abbreviation + "," + currency.symbol +
                                                    ")"}}</mat-option>
                                            </ng-container>
                                            <!-- <ng-container *ngIf="field.value.label === 'company'">
                                                <mat-option *ngFor="let company of companiesListArray"
                                                    [value]="company.id">{{company.name}}</mat-option>
                                            </ng-container> -->
                                            <ng-container *ngIf="field.value.label === 'priority'">
                                                <mat-option value="High">High</mat-option>
                                                <mat-option value="Low">Low</mat-option>
                                                <mat-option value="Medium">Medium</mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span class="text-capitalize">{{field.value.label.replace("_", "
                                                ")}}</span>&nbsp;{{errorJson.required}}
                                        </mat-error>
                                    </mat-form-field>
                                    <ng-select class="form-control" *ngIf="field.value.label === 'company' && featuresData['create_company'] && featuresData['company']"
                                        [items]="companiesListArray" bindLabel="name" bindValue="id" [multiple]="false"
                                        placeholder="Company name" (search)="searchCompanySubject.next($event)"
                                        formControlName="data">
                                    </ng-select>
                                </ng-container>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'MULTI_SELECT_DROPDOWN'">
                            <ng-select class="form-control" [items]="products_list" [isOpen]="revokedSysFieldsList[field.value.label] && false" bindLabel="name" bindValue="id"
                                [multiple]="true" placeholder="Product" formControlName="data"
                                *ngIf="field.value.label === 'product'">
                            </ng-select>
                            <ng-select class="form-control" [items]="services_list" [isOpen]="revokedSysFieldsList[field.value.label] && false" bindLabel="name" bindValue="id"
                                [multiple]="true" placeholder="Services" formControlName="data"
                                *ngIf="field.value.label === 'service'">
                            </ng-select>
                            <ng-select class="form-control" [isOpen]="revokedSysFieldsList[field.value.label] && false" [items]="productCategory" bindLabel="name" bindValue="id"
                                [multiple]="true" placeholder="Product Category" formControlName="data"
                                *ngIf="field.value.label === 'product_category'">
                            </ng-select>
                            <ng-select class="form-control" [isOpen]="revokedSysFieldsList[field.value.label] && false" [items]="serviceCategory" bindLabel="name" bindValue="id"
                                [multiple]="true" placeholder="Service Category" formControlName="data"
                                *ngIf="field.value.label === 'service_category'">
                            </ng-select>
                            <ng-select  [isOpen]="revokedSysFieldsList[field.value.label] && false" class="form-control" [items]="aptCategoriesArray" bindLabel="name" bindValue="id"
                                [multiple]="true" placeholder="Scheduler Category" formControlName="data"
                                *ngIf="field.value.label === 'scheduler_category'">
                            </ng-select>
                            <ng-select  [isOpen]="revokedSysFieldsList[field.value.label] && false" class="form-control" [items]="appointmentsArray" bindLabel="name" bindValue="id"
                                [multiple]="true" placeholder="Scheduler" formControlName="data"
                                *ngIf="field.value.label === 'scheduler'">
                            </ng-select>
                            <mat-form-field appearance="outline" class="w-100 mat-hint-none" *ngIf="field.value.label !== 'product' && field.value.label !== 'service' && field.value.label !== 'product_category' && field.value.label !== 'service_category' && field.value.label !== 'scheduler_category' && field.value.label !== 'scheduler'">
                                <mat-select formControlName="data" multiple>
                                    <mat-option *ngFor="let options of field.value.options" [value]="options">
                                        {{options}}
                                    </mat-option>
                                </mat-select>
                                </mat-form-field>
                            <div class="error d-flex align-items-center"
                                *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", "
                                    ")}}</span>&nbsp;{{errorJson.required}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'RADIO_BUTTON'">
                            <div>
                                <ng-container *ngIf="field.value.options.length > 0">
                                    <mat-radio-group formControlName="data">
                                        <mat-radio-button value="">None</mat-radio-button>
                                        <mat-radio-button *ngFor="let options of field.value.options" [value]="options">
                                            {{options}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <div class="error d-flex align-items-center"
                                        *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                        <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", "
                                            ")}}</span>&nbsp;{{errorJson.required}}
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="field.value.options.length === 0">
                                    <mat-radio-group formControlName="data">
                                        <mat-radio-button [value]="true">
                                            Required
                                        </mat-radio-button>
                                        <mat-radio-button [value]="false">
                                            Not Required
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <div class="error d-flex align-items-center"
                                        *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                        <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", "
                                            ")}}</span>&nbsp;{{errorJson.required}}
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'TOGGLE_BUTTON' ">
                            <label class="switch d-none">
                                <input type="checkbox" formControlName="data">
                                <span class="slider round"></span>
                            </label>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'NUMBER' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" (keydown)="keyDownCustomField($event,0,'custom_field')"
                                    type="number" (wheel)="$event.preventDefault()" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'WEBSITE'">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" type="url" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}} or Invalid
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                        
                        <ng-container *ngIf="field.value.data_type === 'DATE' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="text" matInput [matDatepicker]="picker" formControlName="data"
                                    placeholder="{{field.value.label}}">
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE_TIME' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput [ngxMatDatetimePicker]="dateTimePicker" placeholder="Choose a date"
                                    formControlName="data">
                                <mat-datepicker-toggle matSuffix [for]="$any(dateTimePicker)"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #dateTimePicker>
                                </ngx-mat-datetime-picker>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE_RANGE' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-date-range-input [rangePicker]="dateRangepicker">
                                    <input matStartDate formControlName="start" placeholder="Start date">
                                    <input matEndDate formControlName="end" placeholder="End date">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matIconSuffix [for]="dateRangepicker"></mat-datepicker-toggle>
                                <mat-date-range-picker #dateRangepicker></mat-date-range-picker>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'FILE'">
                            <div class="" *ngIf="lead?.id">
                                <img src="{{field.value?.data?.path}}" alt="" width="150px" class="mb-2"
                                    (error)="onErrorLoadImage($event,presignedurl,field.value?.data?.file_name)">
                                <a href="{{field.value?.data?.path}}" target="_blank"
                                    class="ms-2 text-dark fw-medium mt-3" download>{{field.value?.data?.file_name}}</a>
                            </div>
                            <input type="file" style="outline: none" placeholder="{{field.value.label}}"
                                (change)="onFileUpload($event,i)" />
                            <div class="error d-flex align-items-center"
                                *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", "
                                    ")}}</span>&nbsp;{{errorJson.required}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'PHONE_NUMBER'">
                            <div class="d-flex">
                                <mat-form-field appearance="outline" class="me-1">
                                    <mat-select placeholder="Country code" formControlName="start">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let country of filteredBanks | async"
                                            [ngClass]="{'d-none': country.IAC === ''}"
                                            value={{country.IAC}}>{{country.IAC}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                                        placeholder="{{field.value.label}}" formControlName="end" />
                                    <mat-error class="error text-danger d-flex"
                                        *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", " ") }}
                                            {{errorJson.required}}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'EMAIL'">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(formArrayName,i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
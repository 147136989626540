import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss']
})
export class UnauthorisedComponent {
  featuresData:any;

  constructor(private router: Router, private authservice: AuthService){
    this.getFeatues();
  }
  
  OnNavigatingBack(){
    // if (this.featuresData && this.featuresData.home_task){
    //   this.router.navigate(['/home/task']);
    // } else if (this.featuresData && this.featuresData.home_appointments){
    //   this.router.navigate(['/home/appointments']);
    // } else if (this.featuresData && this.featuresData.home_activity) {
    //   this.router.navigate(['/home/activity']);
    // } else if (this.featuresData && this.featuresData.home_orders) {
    //   this.router.navigate(['/home/orders']);
    // } else if (this.featuresData && this.featuresData.home_calendar_bookings) {
    //   this.router.navigate(['/home/calendar-bookings']);
    // } 
    if (this.featuresData && this.featuresData.uni_box){
      this.router.navigate(['/unibox']);
    } else {
      this.router.navigate(['/user-profile']);
    }
  }

  getFeatues() {
    this.authservice.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }

}

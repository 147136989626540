<div *ngIf="loggedInUser.data.role === 'ADMIN'" class="ms-1 ps-2">
  <div class="row pt-3 ">
    <h6 class="text-primary fw-600">Email Notifications</h6>
  </div>
  <div class="mt-1">
    <div class="row">
      <div class="col-sm-4">
        <div class="tocustomertab">
          <mat-tab-group class="tocustomertab" #tabRef (selectedTabChange)="tabChanged($event)">
            <mat-tab label="To Customer">
              <ng-container *ngTemplateOutlet="appointment"></ng-container>
            </mat-tab>
            <mat-tab label="To Employee">
              <ng-container *ngTemplateOutlet="appointment"></ng-container>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="col-sm-8 customised-sec-email">
        <div class="pt-3 d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <label class="switch ms-2">
              <input type="checkbox" [checked]="checkboxRefAppoinment" (change)="onStatusChange($event)">
              <span class="slider round"></span>
            </label>
            <span class="px-3 fw-600 font-size-13">Appointment Approved</span>
          </div>
          <div class="menu-dot-sec">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onOpenTestMail(test_mail_modal)">
                <mat-icon class="me-1">email</mat-icon>
                <span class="font-size-13">Send Test Email</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="pt-3 custom-fields">
          <ng-container *ngFor="let customisedMsg of customisedMsgSelection"
            class="d-flex align-items-center flex-wrap justify-content-between">
            <mat-form-field appearance="outline" class="m-2 width-10rem">
              <mat-select #selector style="outline: none;" placeholder={{customisedMsg.type}}>
                <mat-option *ngFor="let option of customisedMsg.subtype"
                  (click)="selector.open();onOptionSelected(option.value,'subject')"
                  class="width-fit-content my-1 ps-2 border-radius-4 mx-2 custom-mat-opt">{{
                  option.type
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
        <form [formGroup]="email_templateForm" (ngSubmit)="onEmailNotificationSubmit()" #formDirective="ngForm">
          <div class="ms-2">
            <div class="row mt-3">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <label class="mb-2 fw-600 font-size-13 text-primary">Subject</label>
                <mat-form-field appearance="outline" class="w-100">
                  <input style="outline: none" matInput placeholder="Appointment Approved"
                    (keyup)="getCurrentCursorPosition($event,'subject')" (click)="onClickInputField($event,'subject')"
                    formControlName="subject" class="pb-1" />
                  <mat-error class=" mt-1 error d-flex align-item-center"
                    *ngIf="f['subject'].errors && f['subject'].touched">
                    <mat-icon class="font-size-12 me-1 w-auto h-auto my-auto">info</mat-icon>
                    <span *ngIf="f['subject'].errors['required']">Subject field {{errorjson.required}}</span>
                  </mat-error>
                </mat-form-field>
                <p class="d-flex align-items-center" *ngIf="!showCc || !showBcc">
                  <span (click)="onAddRemoveCcBcc('cc')" *ngIf="!showCc"
                    class="d-flex align-items-center custom-hover-bg py-2">
                    <mat-icon class="ms-1">add</mat-icon> <span class="me-2 ms-1 cursor">Add Cc</span>
                  </span>
                  <span (click)="onAddRemoveCcBcc('bcc')" *ngIf="!showBcc"
                    class="d-flex align-items-center custom-hover-bg py-2">
                    <mat-icon class="ms-1">add</mat-icon><span class="cursor ms-1 me-2">Add Bcc</span>
                  </span>
                </p>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3" *ngIf="showCc">
                  <label class="fw-600 mb-2 text-primary">Cc <small class="text-danger">*</small></label>
                  <div class="d-flex align-items-center">
                    <ng-select class="form-control" [items]="ccEmailLists" [addTag]="allowToAddItem" [multiple]="true"
                      placeholder="example@brexa.com" formControlName="cc_recipients"
                      (input)="onEmailInputChange($event)">
                    </ng-select>
                    <mat-icon class="ms-3 pointer" (click)="onAddRemoveCcBcc('cc')">delete</mat-icon>
                  </div>
                  <mat-error class="mt-1 error d-flex align-item-center"
                    *ngIf="f['cc_recipients'].errors && f['cc_recipients'].touched">
                    <mat-icon class="font-size-12 me-1 w-auto h-auto my-auto">info</mat-icon>
                    <span *ngIf="f['cc_recipients'].errors['required']">Cc field {{errorjson.required}}</span>
                  </mat-error>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3" *ngIf="showBcc">
                  <label class="fw-600 mb-2 text-primary">Bcc <small class="text-danger">*</small></label>
                  <div class="d-flex align-items-center">
                    <ng-select class="form-control" [items]="bccEmailLists" [addTag]="allowToAddItem" [multiple]="true"
                      placeholder="example@brexa.com" formControlName="bcc_recipients"
                      (input)="onEmailInputChange($event)">
                    </ng-select>
                    <mat-icon class="ms-3 pointer" (click)="onAddRemoveCcBcc('bcc')">delete</mat-icon>
                  </div>
                  <mat-error class="mt-1 error d-flex align-item-center"
                    *ngIf="f['bcc_recipients'].errors && f['bcc_recipients'].touched">
                    <mat-icon class="font-size-12 me-1 w-auto h-auto my-auto">info</mat-icon>
                    <span *ngIf="f['bcc_recipients'].errors['required']">Bcc field {{errorjson.required}}</span>
                  </mat-error>
                </div>
              </div>
            </div>
            <mat-divider></mat-divider>
          </div>
          <div class="pt-3 custom-fields">
            <ng-container *ngFor="let customisedMsg of customisedMsgSelection"
              class="d-flex align-items-center flex-wrap justify-content-between">
              <mat-form-field appearance="outline" class="m-2 width-10rem">
                <mat-select #selector style="outline: none;" placeholder={{customisedMsg.type}}>
                  <mat-option *ngFor="let option of customisedMsg.subtype"
                    (click)="selector.open();onOptionSelected(option.value,'email_body')"
                    class="width-fit-content my-1 ps-2 border-radius-4 mx-2 text-primary custom-mat-opt">{{
                    option.type }}</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>
          <div class="mt-4 ms-2">
            <label class="mb-2 fw-600 font-size-13 text-primary">Message</label>
            <ckeditor [editor]="Editor" formControlName="email_body" (ready)="onEditorReady($event)"
              [config]="ckconfigQue"></ckeditor>
            <mat-error class="mt-1 error d-flex align-item-center"
              *ngIf="f['email_body'].errors && f['email_body'].touched">
              <mat-icon class="font-size-12 me-1 w-auto h-auto my-auto">info</mat-icon>
              <span *ngIf="f['email_body'].errors['required']">Message field {{errorjson.required}}</span>
            </mat-error>
          </div>
          <div class="text-end mb-4 mt-2">
            <button [disabled]="email_templateForm.invalid" type="submit"
              class="btn btn-primary  mt-3 px-3">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="loggedInUser.data.role !== 'ADMIN'">
  <div class="d-flex align-items-center justify-content-center">
    <h6 class="text-secondary">You are not authorized to view this.</h6>
  </div>
</ng-container>
<ng-template #appointment>
  <div class="appointments-list">
    <mat-list>
      <ng-container *ngFor="let appointment of appointmentLists;let i =  index">
        <mat-list-item class="pointer" (click)="onSelectAppoinments(i)"
          [ngClass]="{ 'selected-item': i === selectedAppointmentIndex }">
          <div class="row">
            <div class="col-sm-10 font-size-13" [ngClass]="{'dot-inactive':!appointment.status} ">
              {{appointment.name}}
            </div>
            <div class="col-sm-2 text-end">
              <mat-icon [ngClass]="appointment.status?'dot-approved' : 'dot-inactive'"
                class="d-inline-flex align-items-center p-0 font-size-9 " matend>circle</mat-icon>
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-list>
  </div>
</ng-template>
<ng-template #test_mail_modal let-modal>
  <div class="modal-header pb-0 border-0">
    <h6 class="modal-title text-primary fw-600" id="label">Send Test Email
    </h6>
    <mat-icon class="cursor" (click)="modalRef.close()">close</mat-icon>
  </div>
  <div class="modal-body">
    <p class="m-0 pb-3"> Send Test Email to make sure everything is fine with the content for your notification
      including Subject and Message.</p>
    <form [formGroup]="email_templateForm" (ngSubmit)="onTestMailSubmit()">
      <p *ngIf="email_templateForm.invalid && f['to_mail'].valid " class="text-danger">Subject/Cc/Bcc/Message field is Invalid</p>
      <label class="mb-2 fw-500 text-primary">Test Email <small class="text-danger">*</small></label>
      <mat-form-field appearance="outline" class="w-100">
        <input style="outline: none" matInput placeholder="example@brexa.com" formControlName="to_mail" class="pb-1" />
        <mat-error class="mt-1 error d-flex align-item-center" *ngIf="f['to_mail'].errors && f['to_mail'].touched">
          <mat-icon class="font-size-12 me-1 w-auto h-auto my-auto">info</mat-icon>
          <span *ngIf="f['to_mail'].errors['required']">Email id {{errorjson.required}}</span>
          <span *ngIf="f['to_mail'].errors['pattern']">{{errorjson.invalid}} email id</span>
        </mat-error>
      </mat-form-field>
      <div class="d-flex justify-content-end align-items-center">
        <button [disabled]="email_templateForm.invalid" type="submit" class="btn btn-primary">Send Test Email</button>
      </div>
    </form>
  </div>
</ng-template>
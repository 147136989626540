export const environment = {

  production: false,
  urlPrefix: 'https://stage.backend.brexa.ai',
  preSendUrl: 'https://brexaa-stage.s3.amazonaws.com/',
  socketUrl: 'https://stagemessagingsocket.brexa.ai',

  chatBotUrl: 'https://nodebot.worke.io',
  messageUrl: 'https://stagemessagingapi.brexa.ai',
  baseUrl: 'https://stage.brexa.ai',
  worke_ai_url: 'https://chatservices.brexa.ai',
  calender_url: 'stage.brexa.me',
  aiassit: 'https://aiassiststage.brexa.ai'

};
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, finalize, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { ErrorLoadImage, maximumNumberAllowed, onlyNumberDecimal, onOnlyNumbers, onParseDomValue, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { CHANNELS_LIST } from 'src/app/core/data/channels_json';
import { ADDCONTACTJSON } from 'src/app/core/data/contacts_json';
import { countryData } from 'src/app/core/data/countryData';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { time_zone_json } from 'src/app/core/data/mobile_json';
import { AuthService } from 'src/app/core/service/auth.service';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-object-modal',
  templateUrl: './object-modal.component.html',
  styleUrls: ['./object-modal.component.scss']
})
export class ObjectModalComponent implements OnInit, AfterViewInit {
  @Input() contact: any;
  @Input() object_type: any;
  @Input() duplicate_object_modal_open: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChildren('editorComponents') editorComponents!: QueryList<CKEditorComponent>;
  object_data: any;
  addcontact = ADDCONTACTJSON[0];
  errorJson = ERRORJSON[0];
  objectForm!: FormGroup<any>;
  submitted = false;
  companiesListArray: any;
  staffMembers: any;
  tagsListArray: any;
  ckeditorLimitLists: any = {};
  public Editor: any = ClassicEditor;
  ckconfigQue = {
    height: 200,
    toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
    removeButtons: 'Subscript,Superscript'
  };
  editorComponentLists: any[] = [];
  countryjson = countryData;
  channels = CHANNELS_LIST;
  timezonejson = time_zone_json;
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  public bankFilterTimezoneCtrl: FormControl = new FormControl();
  public filteredTimezoneBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onTimezoneDestroy = new Subject<void>();
  featuresData: any;
  searchSubject: Subject<any> = new Subject<any>();
  contact_type: any;
  loggedInUser: any;
  presignedurl = environment.preSendUrl;
  loading = false;
  info: any = {};
  @Input() chatId!: string;
  @Input() iframe_type!: string;
  object_types: any;
  select_object_show = false;
  modalRef: any;


  constructor(public ngbActiveModal: NgbActiveModal, private service: CrmService, private authService: AuthService,
    private settingsService: SettingsService, private toaster: ToastrService, private awsUtilService: AwsUtilsService,
    private modalService: NgbModal) {
    this.searchSubject.pipe(
      debounceTime(300)
    ).subscribe((searchTerm: any) => {
      if (searchTerm.term.length >= 3) {
        this.service.getCompaniesBySearch(searchTerm.term).subscribe({
          next: (resp: any) => {
            this.companiesListArray = resp.data;
          }
        });
      } else if (searchTerm.term.length === 0) {
        this.getCompaniesList();
      }
    });
    this.getFeatues();
  }

  ngOnInit() {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();

      });
    this.filteredTimezoneBanks.next(this.timezonejson.slice());
    this.bankFilterTimezoneCtrl.valueChanges
      .pipe(takeUntil(this._onTimezoneDestroy))
      .subscribe(() => {
        this.filterTimezoneBanks();
      });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getFeatues();
    this.initlizeObjectForm();
    this.getContactTypes();
    this.getCompaniesList();
    this.organisationMembersList();
    this.getTags();
    if (this.object_type) {
      this.select_object_show = false;
      this.getObjectbyId();
      this.getContactFormBuilder();
    } else {
      this.select_object_show = true;
      this.getObjectTypes();
    }
  }

  ngAfterViewInit() {
    this.editorComponentLists = [];
    this.editorComponents.changes.subscribe((components: QueryList<CKEditorComponent>) => {
      components.forEach((editorComponent: any) => {
        this.editorComponentLists.push(editorComponent);
      });
    });
  }

  getObjectbyId() {
    this.authService.getObjectTypeById(this.object_type).subscribe((resp: any) => {
      this.object_data = resp.data;
    });
  }

  getObjectTypes() {
    this.authService.getObjectTypes().subscribe((resp: any) => {
      this.object_types = resp.data.filter((ele: any)=> this.getRolePermissions(ele?.name,'can_read'));
    });
  }

  getRolePermissions(item: any, permission: any) {
    return this.authService?.rolefeatureShow(item + '-' + this.loggedInUser.data.organisation, permission);
  }

  onObjectTypeChange(object_type: any) {
    this.object_type = object_type;
    this.objectForm.reset();
    this.initlizeObjectForm();
    this.getObjectbyId();
    this.getContactFormBuilder();
  }

  initlizeObjectForm() {
    this.objectForm = new FormGroup({
      id: new FormControl(this.contact ? this.contact.id : ''),
      custom_contact_fields: new FormArray([]),
      system_contact_fields: new FormArray([])
    });
  }

  getFieldValidity(arrayName: any, i: number) {
    return (<FormArray>this.objectForm.get(arrayName)).controls[i].invalid;
  }

  get custom_contact_fields(): FormArray {
    return this.objectForm.get("custom_contact_fields") as FormArray;
  }

  get system_contact_fields(): FormArray {
    return this.objectForm.get("system_contact_fields") as FormArray;
  }
  custom_fields(data: any): FormGroup {
    let tempData = '';
    if (!data.custom_contact_template) {
      tempData = data?.data ?
        (data.data !== "NA" ?
          (data.data_type === 'CHECK_BOX' ?
            [] : data.data_type === 'RADIO_BUTTON' ?
              data.data ? true : false : data.data) : "") : data.data_type === 'MULTI_SELECT_DROPDOWN' ?
          [] : data?.data === false ? false : "";
    } else {
      tempData = data?.data ?
        (data.data !== "NA" ?
          (data.data_type === 'CHECK_BOX' ?
            [] : data.data) : "") : data.data_type === 'MULTI_SELECT_DROPDOWN' ?
          [] : data?.data === false ? false : "";
    }
    const returnData = new FormGroup({
      id: new FormControl(""),
      label: new FormControl(data?.label ? data.label : ''),
      data_type: new FormControl(data?.data_type ? data.data_type : ''),
      options: new FormControl(data?.options ? data?.options : ''),
      // on_form: new FormControl(data?.on_form ? data.on_form : false),
      // is_inner_screen: new FormControl(data?.is_inner_screen ? data.is_inner_screen : false),
      data: new FormControl(tempData),
      is_mandatory: new FormControl(data?.is_mandatory ? data.is_mandatory : false),
      // is_active: new FormControl(data?.is_active ? data.is_active : true),
      custom_contact_template: new FormControl(data?.custom_contact_template ? data.custom_contact_template : ''),
      contact: new FormControl(this.contact?.id),
      start: new FormControl((data?.data_type === 'SINGLE_RECORD' || data?.data_type === 'MULTI_RECORD' || data?.data_type === 'DATE_RANGE' || data?.data_type === 'PHONE_NUMBER') ? data?.start : ''),
      end: new FormControl(data ? data?.end : '')
    });
    return returnData;
  }
  // eslint-disable-next-line no-shadow
  custom_array(count: number): any {
    return this.system_contact_fields.controls[count] as FormArray;
  }

  toggleCheckbox(value: string, i = 0) {
    const selectedOptionsControl = this.custom_array(i).get('data');
    const currentValue = selectedOptionsControl.value;
    if (currentValue.includes(value)) {
      selectedOptionsControl.setValue(currentValue.filter((item: any) => item !== value));
    } else {
      currentValue.push(value);
      selectedOptionsControl.setValue(currentValue);
    }
  }

  getContactFormBuilder() {
    let body: any = {};
    if (this.contact) { // For edit
      body = { ...this.contact };
      this.contact.custom_contact_fields.forEach((element: any) => {
        body[element.custom_contact_template] = element.data;
      });
    }
    this.service.postContactFormBuilder(this.object_type, body).subscribe((resp: any) => {
      const computedValues: { [key: number]: any } = {};
      resp.data.forEach(async (element: any, index: number) => {
        element.data_type = element.dataType;
        element.is_mandatory = element.isRequired;
        if (this.getFieldType(element.variable) === 'text') {
          element.label = element.variable;
          this.system_contact_fields.push(this.custom_fields(element));
          if (this.contact) {
            if (element.data_type === 'PHONE_NUMBER') {
              this.system_contact_fields.controls[index].patchValue({
                start: this.contact.ccode,
                end: this.contact.phone_number,
                data: this.contact.ccode + this.contact.phone_number,
                id: ''
              });
            }
          }
        } else {
          element.custom_contact_template = element.variable;
          if (element.label === 'Net Premium') {
            if (resp.data[index - 2].data && resp.data[index - 3].data) {//tax percent & Premium
              resp.data[index - 1].data = (resp.data[index - 2].data.split('%')[0] / resp.data[index - 3].data) * 100; //stax=(tax_perentage/premium)*100
              resp.data[index].data = resp.data[index - 3].data + resp.data[index - 1].data; //net_premium=premium+stax
              element.data = resp.data[index].data;
              computedValues[index - 1] = { custom_contact_template: resp.data[index - 1].custom_contact_template, data: resp.data[index - 1].data };// for storing the sTax value
            }
          }
          this.system_contact_fields.push(this.custom_fields(element));
          if (element.data_type === 'SINGLE_RECORD' || element.data_type === 'MULTI_RECORD') {
            const results: any = await this.service.getContactsBySearchDropDown('', '', element.objectType).toPromise();
            const isElementDataIncluded = results.data.some((item: any) => item.id === element.data);
            if (!isElementDataIncluded) {
              const results1: any = await this.service.getContactsBySearchDropDown('contact_id', element.data, element.objectType).toPromise();
              results.data.push(results1.data[0]);
            }
            this.custom_array(index).controls['start'].setValue(results.data);
            this.custom_array(index).controls['end'].setValue(element.objectType);
          } else if (element.data_type === 'PHONE_NUMBER') {
            const item: any = this.contact?.custom_contact_fields.find((ele: any) => ele.custom_contact_template === element.variable);
            this.system_contact_fields.controls[index].patchValue({
              start: item?.data !== "NA" ? item?.data?.custom_ccode : "",
              end: item?.data !== "NA" ? item?.data.custom_phone_number : "",
              data: item?.data !== "NA" ? item?.data : "",
              id: item?.id
            });
          } else if (element.data_type === 'DATE_RANGE') {
            const item: any = this.contact?.custom_contact_fields.find((ele: any) => ele.custom_contact_template === element.variable);
            this.system_contact_fields.controls[index].patchValue({
              start: item?.data !== "NA" ? item.data?.split('&')[0] : "",
              end: item?.data !== "NA" ? item.data.split('&')[1] : "",
              data: item?.data !== "NA" ? item.data : "",
              id: item?.id
            });
          }
        }
      });
      Object.keys(computedValues).forEach((keyStr) => {// for patching the sTax value for formcontrol
        const key = Number(keyStr);
        const controlIndex = this.system_contact_fields.controls.findIndex(
          (ctrl) => ctrl.value.custom_contact_template === computedValues[key].custom_contact_template
        );
        if (controlIndex !== -1) this.system_contact_fields.controls[controlIndex].patchValue({ data: computedValues[key].data });
      });
      console.log(this.objectForm.value);

    });
    // } else { // for creation
    //   this.service.getContactFormBuilder(this.object_type).subscribe((resp: any) => {
    //     resp.data.forEach(async (element: any, index: number) => {
    //       element.data_type = element.dataType;
    //       element.is_mandatory = element.isRequired;
    //       if (this.getFieldType(element.variable) === 'text') {
    //         element.label = element.variable;
    //         this.system_contact_fields.push(this.custom_fields(element));
    //       } else {
    //         element.custom_contact_template = element.variable;
    //         this.system_contact_fields.push(this.custom_fields(element));
    //         if (element.data_type === 'SINGLE_RECORD' || element.data_type === 'MULTI_RECORD') {
    //           const results: any = await this.service.getContactsBySearchDropDown('', '', element.objectType).toPromise();
    //           this.custom_array(index).controls['start'].setValue(results.data);
    //         }
    //         // this.custom_contact_fields.push(this.custom_fields(element));
    //       }
    //     });
    //   });
    // }
  }

  onSelectOpenedChange(opened: boolean, fieldValue: any, index: number): void {
    if (!opened) {
      this.onNextFlow(fieldValue, this.system_contact_fields.controls[index].value.data, index);
    }
  }

  getCompanyDetailsBylinkedIn(value: any) {
    if (value.label === "website" && !value.custom_contact_template) {
      if (!this.objectForm.value.id) {
        const name = value.data.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/$/, '');
        this.service.getLinkedInByCompanyName(name).subscribe((resp: any) => {
          this.system_contact_fields.value.forEach((element: any, index: number) => {
            if (!element.custom_contact_template && (element.label === 'title' || element.label === 'address'
              || element.label === 'city' || element.label === 'zipcode' || element.label === 'description'
              || element.label === 'logo' || element.label === 'country')) {
              if (!element.data) {
                if (element.label === 'country') {
                  this.countryjson.filter((ele: any) => {
                    if (ele.CC === resp.data?.geo?.countryCode) this.system_contact_fields.controls[index].patchValue({ data: ele.Entity });
                  });
                } else {
                  this.system_contact_fields.controls[index].patchValue({
                    data: element.label === 'title' ? resp.data?.name
                      : element.label === 'address' ? resp.data?.location : element.label === 'city' ? resp.data?.geo?.city
                        : element.label === 'zipcode' ? resp.data?.geo?.postalCode : element.label === 'description' ? resp.data?.description
                          : element.label === 'logo' ? resp.data?.logo : ''
                  });
                }
              }
            }
          });
        });
      }
    }
  }

  onNextFlow(nextField: any, nextValue: any, index: number) {
    let body: any = {};
    if (this.contact) { // For edit
      body = { ...this.contact };
      this.contact.custom_contact_fields.forEach((element: any) => {
        body[element.custom_contact_template] = element.data;
      });
      this.system_contact_fields.value.forEach((element: any) => {
        if (element.custom_contact_template) {
          if (element.data_type === 'DATE_RANGE') {
            if (element.end) {
              element.data = this.convert(element.start) + '&' + this.convert(element.end);
            } else if (element.start) {
              element.data = this.convert(element.start);
            }
          } else if (element.data_type === "PHONE_NUMBER") {
            element.data = { custom_ccode: element.start, custom_phone_number: element.end };
          }
          if (nextField.custom_contact_template === element.custom_contact_template) {
            if (nextField.data_type === "MULTI_SELECT_DROPDOWN") {
              body[element.custom_contact_template] = [...nextField.data];
            } else {
              body[element.custom_contact_template] = nextValue.label;
            }
          }
          else body[element.custom_contact_template] = element.data;
        }
        else body[element.label] = element.data;
      });
    } else {
      this.system_contact_fields.value?.forEach((fields: any) => {
        if (fields.custom_contact_template) {
          if (fields.data_type === 'DATE_RANGE') {
            if (fields.end) {
              fields.data = this.convert(fields.start) + '&' + this.convert(fields.end);
            } else if (fields.start) {
              fields.data = this.convert(fields.start);
            }
          } else if (fields.data_type === "PHONE_NUMBER") {
            fields.data = { custom_ccode: fields.start, custom_phone_number: fields.end };
          }
          if (nextField.custom_contact_template === fields.custom_contact_template) {
            if (nextField.data_type === "MULTI_SELECT_DROPDOWN") {
              body[nextField.custom_contact_template] = [...nextField.data];
            } else {
              body[nextField.custom_contact_template] = nextValue.label;
            }
          }
          else body[fields.custom_contact_template] = fields.data;
        }
        else {
          if (fields.data_type === 'PHONE_NUMBER') {
            fields.data = { custom_ccode: fields.start, custom_phone_number: fields.end };
          }
          body[fields.label] = fields.data;
        }
      });
    }
    this.service.postContactFormBuilder(this.object_type, body).subscribe((resp: any) => {
      for (let i = this.system_contact_fields.length - 1; i >= index; i--) {
        // this.system_contact_fields.removeAt(i + 1);
        this.system_contact_fields.clear();
      }
      const computedValues: { [key: number]: any } = {};
      resp.data.forEach(async (element: any, i: number) => {
        element.data_type = element.dataType;
        element.is_mandatory = element.isRequired;
        if (this.getFieldType(element.variable) === 'text') {
          element.label = element.variable;
          if (element.data_type === 'PHONE_NUMBER') {
            element.start = element.data ? element.data?.custom_phone_number?.custom_ccode : '';
            element.end = element.data ? element.data?.custom_phone_number?.custom_phone_number : '';
          }
          this.system_contact_fields.push(this.custom_fields(element));
        } else {
          element.custom_contact_template = element.variable;
          if (element.data_type === 'SINGLE_RECORD' || element.data_type === 'MULTI_RECORD') {
            const results: any = await this.service.getContactsBySearchDropDown('', '', element.objectType).toPromise();
            const isElementDataIncluded = results.data.some((item: any) => item.id === element.data);
            if (!isElementDataIncluded) {
              const results1: any = await this.service.getContactsBySearchDropDown('contact_id', element.data, element.objectType).toPromise();
              results.data.push(results1.data[0]);
            }
            element.start = results.data;
            element.end = element.objectType;
          } else if (element.data_type === 'DATE_RANGE') {
            element.start = element.data ? element.data.split('&')[0] : '';
            element.end = element.data ? element.data.split('&')[1] : '';
          } else if (element.data_type === 'PHONE_NUMBER') {
            element.start = element.data ? element.data?.custom_phone_number?.custom_ccode : '';
            element.end = element.data ? element.data?.custom_phone_number?.custom_phone_number : '';
          }
          if (element.label === 'Net Premium') {
            if (resp.data[i - 2].data && resp.data[i - 3].data) {//tax percent & Premium
              resp.data[i - 1].data = (resp.data[i - 2].data.split('%')[0] / resp.data[i - 3].data) * 100; //stax=(tax_perentage/premium)*100
              resp.data[i].data = resp.data[i - 3].data + resp.data[i - 1].data; //net_premium=premium+stax
              element.data = resp.data[i].data;
              computedValues[i - 1] = { custom_contact_template: resp.data[i - 1].custom_contact_template, data: resp.data[i - 1].data };// for storing the sTax value
            }
          }
          this.system_contact_fields.push(this.custom_fields(element));
        }
      });
      Object.keys(computedValues).forEach((keyStr) => {// for patching the sTax value for formcontrol
        const key = Number(keyStr);
        const controlIndex = this.system_contact_fields.controls.findIndex(
          (ctrl) => ctrl.value.custom_contact_template === computedValues[key].custom_contact_template
        );
        if (controlIndex !== -1) this.system_contact_fields.controls[controlIndex].patchValue({ data: computedValues[key].data });
      });
    });
  }

  getFieldType(fieldValue: any) {
    // Regular expression to match a standard UUID (version 4 or similar)
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    if (uuidRegex.test(fieldValue)) {
      return "uuid";
    }
    return "text";
  }

  getCompaniesList() {
    this.service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.companiesListArray = resp.data;
        if (this.contact?.id && this.contact?.company) {
          if (resp.data.filter((ele: any) => ele.id === this.contact?.company).length === 0) this.getCompanyById(this.contact.company);
        }

      }
    });
  }

  addNewCompanyName = (name: any) => ({ name: name, id: '' });

  onSelectCompany(e: any) {
    this.objectForm.patchValue({ company_id: e.id, company_name: e.name });
  }

  clearCompany(e: any) {
    this.objectForm.patchValue({ company_name: '' });
  }

  getCompanyById(id: string) {
    this.service.getCompanyById(id).subscribe({
      next: (company_id_info: any) => {
        if (company_id_info.data.length > 0) {
          this.companiesListArray.push(company_id_info.data[0]);
          this.companiesListArray = [...this.companiesListArray];
        }
      }
    });
  }

  getContactTypes() {
    this.service.getContactTypes().subscribe((resp: any) => {
      this.contact_type = resp.data;
      // this.contact_type.filter((ele: any) => {
      //   if (ele.name === 'Partial') {
      //     if (ele.id === this.objectForm.value.contact_type && this.contact?.id) {
      //       this.objectForm.controls['email'].enable();
      //       this.objectForm.controls['phone_number'].enable();
      //       this.objectForm.controls['ccode'].enable();
      //     }
      //   }
      // });
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  getTags() {
    this.settingsService.getTags('contact').subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }

  onSearch(ev: any, opt: any, index: number) {
    if (ev.term.length >= 3) {
      console.log(opt, ev, "search");

      this.service.getContactsBySearchDropDown('display_name', ev.term, opt.end).subscribe({
        next: (resp: any) => {
          this.custom_array(index).controls['start'].setValue(resp.data);
        }
      });
    }
  }

  async onReady(editor: any, index: any) {
    const object = {
      [index]: {
        maxLimit: 1000,
        actualLimit: 0,
        type: false
      }
    };
    Object.assign(this.ckeditorLimitLists, object);
    const dataWithoutTags = await onParseDomValue(editor.data.get());
    console.log(dataWithoutTags.length);
    this.ckeditorLimitLists[index]['actualLimit'] = dataWithoutTags?.length || 0;
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const edittedItem = this.editorComponentLists.find((elem: any) => elem.editorInstance.id === editor.id);
      const contentLength = pasteEventInCkeditor(edittedItem, pasteContent);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { ...contentLength });
    });
  }

  editorkeyEvent(editor: any, editorComponents: any, index: any) {
    const edittedItem = this.editorComponentLists.find((elem: any) => elem.id === editorComponents.id);
    if (edittedItem) {
      const inputData = edittedItem.editorInstance.getData();
      const dataWithoutTags = onParseDomValue(inputData);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { actualLimit: dataWithoutTags.length || 0 });
    }
  }

  keyDownCustomField(event: any, decimalPoint: number, type: string) {
    return onlyNumberDecimal(event, decimalPoint, type);
  }

  keyDownEvent(event: any, maxLength: number) {
    const maxNum = maximumNumberAllowed(event, maxLength);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }

  getAcceptAttribute(field: any) {
    if (field.value?.custom_contact_template) {
      return '';
    } else {
      return '.pdf';
    }
  }

  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterTimezoneBanks() {
    if (!this.timezonejson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterTimezoneCtrl.value;
    if (!search) {
      this.filteredTimezoneBanks.next(this.timezonejson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    const filteredTimezones = this.timezonejson.map((element: any) => {
      const filteredCountries = element.countries.filter((bank: any) => bank.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
      return { ...element, countries: filteredCountries };
    }).filter((element: any) => element.countries.length > 0);

    this.filteredTimezoneBanks.next(filteredTimezones);
  }


  onErrorLoadImage(event: any, target: any, file: any) {
    if (file) event.target.src = ErrorLoadImage(target, file);
  }

  onFileUpload(ev: any, index: number, field: any) {
    if (!ev.target.files[0]) return;
    if (ev.target.files[0].size > 15 * 1048576) {
      this.toaster.error("File size is too large, please upload a file size less than 15MB");
      return;
    }

    this.awsUtilService.getUploadFileLink(ev.target.files[0].type, ev.target.files[0].name.split('.')[1]).subscribe({
      next: (resp) => {
        // if (field.value?.custom_contact_template) {
        //   //Custom Field
        //   this.custom_contact_fields.controls[index].patchValue({ data: { path: this.presignedurl + resp.data.file_name, file_name: ev.target.files[0].name } });
        // } else {
        this.system_contact_fields.controls[index].patchValue({ data: { path: this.presignedurl + resp.data.file_name, file_name: ev.target.files[0].name } });
        // }
        this.awsUtilService.uploadFile(ev.target.files[0], resp.data.url).subscribe({
          next: () => {//@typescript-eslint/no-empty-function
          }
        });
      }
    });
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  onSubmit() {
    const inValidCkEditor = Object.entries(this.ckeditorLimitLists).find((ckeditorLimit: any) => ckeditorLimit[1]?.actualLimit > ckeditorLimit[1]?.maxLimit);
    if (!inValidCkEditor) {
      const submit_value = this.objectForm.value;
      // submit_value.custom_contact_fields.forEach((obj: any, i: number) => {
      //   if (obj.data_type === "DATE_RANGE") {
      //     if (obj.end) {
      //       obj.data = this.convert(obj.start) + '&' + this.convert(obj.end);
      //     } else if (obj.start) {
      //       obj.data = this.convert(obj.start);
      //     }
      //     this.custom_contact_fields.controls[i].patchValue({ data: obj.data });
      //   } else if (obj.data_type === "PHONE_NUMBER") {
      //     obj.data = { custom_ccode: obj.start, custom_phone_number: obj.end };
      //     this.custom_contact_fields.controls[i].patchValue({ data: obj.data });
      //   }
      //   // if (obj.data === "" || (!obj.data && typeof obj.data !== 'boolean')) {
      //   //   obj.data = "NA";
      //   // }
      // });

      const assignee_departments: any = [];
      let valid_assignee: any = [];
      let valid_owner: any;
      console.log(this.objectForm.value?.system_contact_fields, "this.objectForm.value?.system_contact_fields");

      this.objectForm.value?.system_contact_fields?.forEach((fields: any, index: any) => {
        if (fields.custom_contact_template) {
          if (fields.data_type === "DATE_RANGE") {
            if (fields.end) {
              fields.data = this.convert(fields.start) + '&' + this.convert(fields.end);
            } else if (fields.start) {
              fields.data = this.convert(fields.start);
            }
            this.system_contact_fields.controls[index].patchValue({ data: fields.data });
          } else if (fields.data_type === "PHONE_NUMBER") {
            fields.data = { custom_ccode: fields.start, custom_phone_number: fields.end };
            this.system_contact_fields.controls[index].patchValue({ data: fields.data });
          }
          this.objectForm.value.custom_contact_fields.push(fields);
        } else {
          if (fields.data_type === 'RADIO_BUTTON' && fields.options.length === 0 && !fields.data) {
            // fields.data = false;
            submit_value[fields.label] = false;
          } else if (fields.data_type === "SELECT_DROPDOWN" && fields.label === "company") {
            fields.data = this.objectForm.value.company_name;
            this.system_contact_fields.controls[index].patchValue({ data: this.objectForm.value.company_name });
          } else if (fields.data_type === "PHONE_NUMBER") {
            fields.data = fields.start + fields.end || null;
            this.system_contact_fields.controls[index].patchValue({ data: fields.start + fields.end || null });
            // const ccodeField = this.system_contact_fields.controls.find((field: any) => field.value.label === 'ccode');// for form array
            // if (ccodeField) ccodeField.patchValue({ data: fields.start || this.authService.getCompanyData().ccode });
            // const ccodeField1 = this.objectForm.value?.system_contact_fields.find((field: any) => field.label === 'ccode'); // for submit value
            // if (ccodeField1) ccodeField1.data = fields.start || this.authService.getCompanyData().ccode;
          }
          if (fields.label === 'assignee') {
            valid_assignee = this.system_contact_fields.controls[index].value.data;
          } else if (fields.label === 'owner') {
            valid_owner = this.system_contact_fields.controls[index].value.data;
          }
          submit_value[fields.label] = fields.data;
        }
      });
      delete submit_value.system_contact_fields;
      console.log(submit_value, "submit_value");

      this.objectForm.markAllAsTouched();
      this.submitted = true;
      if (this.objectForm.valid) {
        this.loading = true;

        if (valid_assignee.length !== 0) {
          this.staffMembers.forEach((item: any) => {
            valid_assignee.filter((assigne: any) => {
              if (assigne === item.id) {
                assignee_departments.push(item.department_info.id ? item.department_info.id : item.utype === 'ADMIN' ? this.loggedInUser.data.organisation : '');
              }
            });
          });
        }

        const owner_department = this.staffMembers.filter((member: any) => member.id === valid_owner);

        if (this.objectForm.value.id) {
          let info_body;
          if (Object.keys(this.info).length === 0) {
            info_body = this.contact.info;
          } else {
            info_body = this.info;
          }

          this.service.updateContact({ ...submit_value, assignee: valid_assignee, owner: valid_owner, info: info_body, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0]?.department_info?.id ? owner_department[0]?.department_info?.id : owner_department[0]?.utype === 'ADMIN' ? this.loggedInUser.data.organisation : '', object_type: this.object_type }).pipe(finalize(() => this.loading = false)).subscribe({//email: this.objectForm.controls["email"].value, channel: this.objectForm.controls["channel"].value,
            next: () => {
              this.info = {};
              this.passBack();
              this.ngbActiveModal.dismiss();
            }, error: () => {
              this.custom_contact_fields.clear();
              this.custom_contact_fields.updateValueAndValidity();
            }
          });
        } else {
          console.log({ ...submit_value, info: this.info, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0]?.department_info.id ? owner_department[0].department_info.id : owner_department[0]?.utype === 'ADMIN' ? this.loggedInUser.data.organisation : '', object_type: this.object_type });

          this.service.createContact({ ...submit_value, info: this.info, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0]?.department_info.id ? owner_department[0].department_info.id : owner_department[0]?.utype === 'ADMIN' ? this.loggedInUser.data.organisation : '', object_type: this.object_type }).pipe(finalize(() => this.loading = false)).subscribe((resp: any) => {
            if (resp.status === 200) {
              this.toaster.error(resp.message);
            } else {
              this.toaster.success(resp.message);
              let iframe: any;
              if (this.iframe_type === 'unibox') {
                iframe = document.getElementById('chatbot-widget-value');
              } else {
                iframe = document.getElementById('chat-widget-value');
              }
              resp.data.chatId = this.chatId;
              iframe?.contentWindow.postMessage({ ...resp, type: "contactCreated" }, '*');
              this.passBack(resp.data.id);
              this.ngbActiveModal.dismiss();
            }
          }, (err: any) => {
            this.custom_contact_fields.clear();
            this.custom_contact_fields.updateValueAndValidity();
            this.toaster.error(err.message);
          });
        }
      }
    }
  }

  passBack(contact_id?: string) {
    this.passEntry.emit(this.duplicate_object_modal_open ? contact_id : true);
  }

  openObjectModal(newTag: string, fieldValue: any, index: number): any {
    this.modalRef = this.modalService.open(ObjectModalComponent, { size: 'lg', scrollable: true });
    this.modalRef.componentInstance.object_type = fieldValue.end;
    this.modalRef.componentInstance.duplicate_object_modal_open = true;
    this.modalRef.componentInstance.passEntry.subscribe(async (receivedEntry: any) => {
      if (receivedEntry) {
        fieldValue.data = receivedEntry;
        this.system_contact_fields.controls[index].patchValue({ data: receivedEntry });
        const results: any = await this.service.getContactsBySearchDropDown('', '', fieldValue.end).toPromise();
        this.custom_array(index).controls['start'].setValue(results.data);
      }
    });
    return undefined;
  }

  createAddTagHandler(fieldValue: any, index: number): (newTag: string) => any {
    return (newTag: string) => {
      return this.openObjectModal(newTag, fieldValue, index);
    };
  }
}


import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ADDCLIENTJSON } from 'src/app/core/data/client_json';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { mobile_json } from 'src/app/core/data/mobile_json';
import { ClientsService } from 'src/app/core/service/clients.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { countryData } from 'src/app/core/data/countryData';
import { ReplaySubject, Subject, debounceTime, finalize, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { CustomFieldsService } from 'src/app/core/service/custom-fields.service';
import { ErrorLoadImage, maximumNumberAllowed, onOnlyNumbers, onParseDomValue, onlyNumberDecimal, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { CHANNELS_LIST } from 'src/app/core/data/channels_json';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-client-modal',
  templateUrl: './client-modal.component.html',
  styleUrls: ['./client-modal.component.scss']
})
export class ClientModalComponent implements OnInit, AfterViewInit {
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  @ViewChildren('editorComponents') editorComponents!: QueryList<CKEditorComponent>;
  countryjson = countryData;
  submitted = false;
  clientForm!: FormGroup;
  addclient = ADDCLIENTJSON[0];
  errorJson = ERRORJSON[0];
  channels = CHANNELS_LIST;
  countryNamesJson = mobile_json;
  contactListArray: any = [];
  companiesListArray: any = [];
  staffMembers: any = [];
  customFieldsArray: any = [];
  newEmail = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() clientData: any;
  info: any = {};
  info_body: any;
  loading = false;
  loggedInUser: any;
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  tagsListArray: any;
  searchSubject = new Subject();
  searchCompanySubject = new Subject();
  presignedurl = environment.preSendUrl;
  contactActive = false;
  ckeditorLimitLists: any = {};
  public Editor: any = ClassicEditor;
  featuresData:any;
  ckconfigQue = {
    height: 200,
    toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
    removeButtons: 'Subscript,Superscript'
  };
  editorComponentLists: any[] = [];

  constructor(
    private fb: FormBuilder,
    public ngbActiveModal: NgbActiveModal,
    private service: ClientsService,
    private crm_service: CrmService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private _customFields: CustomFieldsService,
    private awsUtilService: AwsUtilsService,
    private toaster: ToastrService
  ) {
    this.searchSubject.pipe(
      debounceTime(300)
    ).subscribe((searchTerm: any) => {
      if (searchTerm.text.length >= 3) {
        this.crm_service.getContactsBySearchDropDown(searchTerm.type, searchTerm.text).subscribe((result: any) => {
          this.contactListArray = result.data;
        });
      // } else if (searchTerm.term.length === 0) {
      //   this.getContactsList();
      }
    });
    this.searchCompanySubject.pipe(
      debounceTime(300)
    ).subscribe((searchTerm: any) => {
      if (searchTerm.term.length >= 3) {
        this.crm_service.getCompaniesBySearch(searchTerm.term).subscribe({
          next: (resp: any) => {
            this.companiesListArray = resp.data;
          }
        });
      } else if (searchTerm.term.length === 0) {
        this.getCompaniesList();
      }
    });
    this.getFeatues();
  }

  ngOnInit() {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.initilizeForm();
    // this.getContactsList();
    this.getCompaniesList();
    this.organisationMembersList();
    this.getTags();
    this.getCustomFieldsData();

    if (this.clientData?.id) {
      this.clientForm.controls["phone_number"].disable();
      this.clientForm.controls["email"].disable();
      this.clientForm.controls["name"].disable();
      this.clientForm.controls["channel"].disable();
    } else {
      this.info['owner_info'] = {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      };
    }
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }


  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.myFirstInput.nativeElement.focus();
    // }, 0);
    this.editorComponentLists = [];
    this.editorComponents.changes.subscribe((components: QueryList<CKEditorComponent>) => {
      components.forEach((editorComponent: any) => {
        this.editorComponentLists.push(editorComponent);
      });
    });
  }

  keyDownCustomField(event: any, decimalPoint: number, type: string) {
    return onlyNumberDecimal(event, decimalPoint, type);
  }

  keyDownEvent(event: any, maxLength: number) {
    const maxNum = maximumNumberAllowed(event, maxLength);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }

  async onReady(editor: any, index: any) {
    const object = {
      [index]: {
        maxLimit: 1000,
        actualLimit: 0,
        type: false
      }
    };
    Object.assign(this.ckeditorLimitLists, object);
    const dataWithoutTags = await onParseDomValue(editor.data.get());
    this.ckeditorLimitLists[index]['actualLimit'] = dataWithoutTags?.length || 0;
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const edittedItem = this.editorComponentLists.find((elem: any) => elem.editorInstance.id === editor.id);
      const contentLength = pasteEventInCkeditor(edittedItem, pasteContent);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { ...contentLength });
    });
  }

  editorkeyEvent(editor: any, editorComponents: any, index: any) {
    const edittedItem = this.editorComponentLists.find((elem: any) => elem.id === editorComponents.id);
    if (edittedItem) {
      const inputData = edittedItem.editorInstance.getData();
      const dataWithoutTags = onParseDomValue(inputData);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { actualLimit: dataWithoutTags.length || 0 });
    }
  }


  initilizeForm() {
    this.clientForm = this.fb.group({
      id: [this.clientData ? this.clientData.id : ''],
      name: [this.clientData ? this.clientData.name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9-'\s]{1,}$/)]],
      contact_id: [this.clientData ? this.clientData.contact_id : ''],
      email: [this.clientData ? this.clientData.email : '', Validators.compose([Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])],//eslint-disable-line 
      phone_number: [this.clientData ? this.clientData.phone_number : '',Validators.compose([Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])],//, Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])
      source_id: [this.clientData ? this.clientData.source_id : ''],
      company_name: [this.clientData ? this.clientData.company_name : ''],
      tags: [this.clientData ? this.clientData.tags : ''],
      owner: [this.clientData ? this.clientData.owner : this.loggedInUser.data.id, [Validators.required]],
      source_type: [this.clientData ? this.clientData.source_type : 'client'],
      channel: [this.clientData ? this.clientData.channel : 'manual'],
      country: [this.clientData ? this.clientData.country : ''],
      ccode: [this.clientData?.ccode ? (this.clientData.ccode.includes('+') ? this.clientData?.ccode : '+' + this.clientData.ccode) : this.authService.getCompanyData().ccode],
      custom_client_fields: this.fb.array([]),
      user_id: [''],
      display_picture: [this.clientData ? this.clientData?.display_picture : '']
    });
    // console.log(this.clientForm.value, "kjdsfk", this.clientData);
    if (this.clientForm.value.id) this.clientForm.controls["ccode"].disable(); this.contactActive = this.clientData?.is_contact_active;
    if(this.loggedInUser.customer_primary_login_type === 'email'){
      this.clientForm.controls['email'].setValidators(Validators.required);
      this.clientForm.controls['email'].updateValueAndValidity();
    } else {
      this.clientForm.controls['phone_number'].setValidators(Validators.required);
      this.clientForm.controls['phone_number'].updateValueAndValidity();
    }
  }

  addNewName = (name: any) => ({ first_name: name, id: '' });
  addNewEmail = (email: any) => ({ email: email, id: '' });
  addNewphonenumber = (number: any) => ({ phone_number: number, id: '' });

  hasRequiredValidator(controlName: string): boolean {
    const control = this.clientForm.get(controlName);
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      return !!(validator && validator['required']);
    }
    return false;
  }

  onSelectContact(contact: any,type: string) {
    this.clientForm.patchValue({ user_id: contact?.user_id ? contact.user_id : null });
    // console.log(contact, "contact selected");
    this.clientForm.controls["phone_number"].enable();
    this.clientForm.controls["email"].enable();
    this.clientForm.controls["ccode"].enable();
    this.contactActive = contact.is_active;
    if (contact?.id) {
      this.newEmail = false;
      if (contact.company) {
        if (this.companiesListArray?.filter((ele: any) => ele.id === contact.company).length === 0) this.getCompanyById(contact.company);
        this.clientForm.controls["source_id"].disable();

      }
      this.clientForm.patchValue({ email: contact.email, contact_id: contact.id, phone_number: contact.phone_number, name: contact.first_name, ccode: contact.ccode, source_id: contact.company });
      this.clientForm.controls["phone_number"].disable();
      this.clientForm.controls["email"].disable();
      this.clientForm.controls["ccode"].disable();
    } else if (type === 'name'){
      this.contactActive = true;
      this.clientForm.patchValue({ contact: contact?.id, name: contact?.first_name });
      this.newEmail = true;
    } else if (type === 'email') {
      this.contactActive = true;
      this.clientForm.patchValue({ contact: contact.id, email: contact?.email });
      this.newEmail = true;
    } else if (type === 'phone_number') {
      this.contactActive = true;
      this.clientForm.patchValue({ contact: contact.id, phone_number: contact?.phone_number, ccode: contact?.ccode || this.authService.getCompanyData().ccode });
      this.newEmail = true;
    }
  }

  get f() {
    return this.clientForm.controls;
  }

  // createNewEmail = (option: any) => ({ email: option, phone_number: '', id: '' });

  // onSelectEmail(value: any) {
  //   if (value.email && value.id) {
  //     this.newEmail = false;
  //     this.clientForm.patchValue({ contact_id: value.id });
  //   } else {
  //     this.newEmail = true;
  //   }
  //   if (value) this.clientForm.patchValue({ email: value.email, contact: value.id, phone_number: value.phone_number });
  // }

  onSubmit() {
    this.submitted = true;
    const inValidCkEditor = Object.entries(this.ckeditorLimitLists).find((ckeditorLimit: any) => ckeditorLimit[1]?.actualLimit > ckeditorLimit[1]?.maxLimit);
    if (!inValidCkEditor) {
      this.clientForm.value.custom_client_fields.forEach((obj: any, i: number) => {
        if (obj.data_type === "DATE_RANGE") {
          if (obj.end) {
            obj.data = this.convert(obj.start) + '&' + this.convert(obj.end);
            this.custom_client_fields.controls[i].patchValue({ data: obj.data });
          } else if (obj.start) {
            obj.data = this.convert(obj.start);
            this.custom_client_fields.controls[i].patchValue({ data: obj.data });
          }
        } else if (obj.data_type === "PHONE_NUMBER") {
          obj.data = { custom_ccode: obj.start, custom_phone_number: obj.end };
        }
        // if (obj.data === "" || (!obj.data && typeof obj.data !== 'boolean')) {
        //   obj.data = "NA";
        // }
      });
      if (this.clientForm.valid) {
        if (!this.contactActive) { //This conditions is to check if the user is active or not 
          this.toaster.error("Contact is not active");
          return;
        }
        this.loading = true;
        let valid_name: any;
        let valid_mailId: any;
        let valid_phone_number: any;
        let valid_ccode: any;

        if (this.clientForm.controls["name"].status === 'DISABLED') {
          valid_name = this.clientForm.controls["name"].value;
        } else {
          valid_name = this.clientForm.value.name;
        }

        if (this.clientForm.controls["email"].status === 'DISABLED') {
          valid_mailId = this.clientForm.controls["email"].value?.toLowerCase();
        } else {
          valid_mailId = this.clientForm.value.email?.toLowerCase();
        }

        if (this.clientForm.controls["phone_number"].status === 'DISABLED') {
          valid_phone_number = this.clientForm.controls["phone_number"].value;
        } else {
          valid_phone_number = this.clientForm.value.phone_number;
        }

        if (this.clientForm.controls["ccode"].status === 'DISABLED') {
          valid_ccode = this.clientForm.controls["ccode"].value;
        } else {
          valid_ccode = this.clientForm.value.ccode;
        }

        if (Object.keys(this.info).length === 0) {
          this.info_body = this.clientData?.info;
        } else {
          this.info_body = this.info;
        }
        this.clientForm.markAllAsTouched();
        if (this.newEmail === true) {
          this.clientForm.controls['contact_id'].setValidators(Validators.required);
          this.clientForm.controls['contact_id'].updateValueAndValidity();
          this.createContact(valid_phone_number, valid_mailId, valid_name, valid_ccode);
        } else {
          this.createClient(valid_phone_number, valid_mailId, valid_name, valid_ccode);
        }
      }
    }
  }
  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  createClient(valid_phone_number: string, valid_mailId: string, valid_name: string, valid_ccode: any) {
    console.log(this.clientForm);

    const submit_value = this.clientForm.value;
    // submit_value.custom_client_fields.forEach((obj: any) => {
    //   if (obj.data === "") {
    //     obj.data = "NA";
    //   }
    // });

    if (this.clientForm.valid) {
      const owner_department = this.staffMembers.filter((member: any) => member.id === this.clientForm.value.owner);
      if (this.clientForm.value.id) {
        console.log(submit_value);

        this.service.updateClient(this.clientForm.value.id, { ...submit_value, ccode: valid_ccode, phone_number: valid_phone_number, email: valid_mailId, name: valid_name, info: this.info_body, channel: this.clientForm.controls["channel"].value, owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe(() => {
          this.passEntry.emit("success");
          this.ngbActiveModal.dismiss();
        });
      } else {
        this.service.createClient({ ...submit_value, ccode: valid_ccode, phone_number: valid_phone_number, email: valid_mailId, name: valid_name, info: this.info_body, owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe(() => {
          this.passEntry.emit("success");
          this.ngbActiveModal.dismiss();
        });
      }
    }
  }

  onOwnerSelect(owner: any) {
    this.info['owner_info'] = {
      fname: owner.fname,
      lname: owner.lname
    };
  }

  createContact(valid_phone_number: string, valid_mailId: string, valid_name: string, valid_ccode: any) {
    const owner_department = this.staffMembers.filter((member: any) => member.id === this.clientForm.value.owner);
    const body = {
      ...this.clientForm.value,
      custom_contact_fields: [],
      phone_number: valid_phone_number,
      email: valid_mailId.toLocaleLowerCase(),
      company_id: this.clientForm.controls["source_id"].value,
      first_name: valid_name,
      info: this.info_body,
      ccode: valid_ccode,
      owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : owner_department[0].utype === 'ADMIN' ? this.loggedInUser.data.organisation : '',
      channel: 'manual',
      tags: '',
      company_name: ""
    };
    this.crm_service.createContact(body).subscribe({
      next: (resp: any) => {
        this.clientForm.controls['contact_id'].setValue(resp.data?.id);
        this.createClient(valid_phone_number, valid_mailId, valid_name, valid_ccode);
      }
    });
  }

  // searchContact(term: any) {
  //   if (term.term.length >= 3) {
  //     this.crm_service.getContactsBySearch(term.term).subscribe((result: any) => {
  //       this.contactListArray = result.data;
  //     });
  //   } else if (term.term.length === 0) {
  //     this.getContactsList();
  //   }
  // }

  getContactsList() {
    this.crm_service.getContactsDropdownList().subscribe({
      next: (resp: any) => {
        this.contactListArray = resp.data;
        if (this.clientData?.id) {
          if (this.contactListArray.filter((item: any) => item.id === this.clientData.contact_id).length === 0) this.getContactById(this.clientData.contact_id);
        }
      }
    });
  }

  getContactById(id: string) {
    this.crm_service.getContactById(id).subscribe({
      next: (resp: any) => {
        if (resp.data[0]) {
          this.contactListArray.push(resp.data[0]);
        }
      }
    });
  }

  getCompaniesList() {
    this.crm_service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.companiesListArray = resp.data;
        // if (this.clientData?.id) { //source_id is not valid one
        //   if (resp.data.filter((ele: any) => ele.id === this.clientData?.source_id).length === 0) this.getCompanyById(this.clientData.source_id);
        // }
      }
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  clearContactFields(e: any) {
    console.log(e, "clear event");
    this.clientForm.patchValue({ email: '', contact_id: '', phone_number: '', name: '', ccode: this.authService.getCompanyData().ccode, source_id: '' });
  }

  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  getTags() {
    this.settingsService.getClientTags('client').subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }


  //******************************* Custom Fields START ******************************************** *//

  getCustomFieldsData() {
    this._customFields.getClientTemplateFields().subscribe({
      next: (resp: any) => {
        this.customFieldsArray = resp.data;
        if (resp.data) {
          let count = 0;
          for (const data of resp.data) {
            if (data.on_form) {
              this.addfieldsData(data);
              if(data.data_type === 'WEBSITE') {
                this.custom_array(count).controls['data'].setValidators(Validators.pattern(/^(https?:\/\/)?(www\.)?((([a-zA-Z0-9]+\.)+[a-zA-Z]{2,})|localhost)(:[0-9]{1,5})?(\/.*)?$/));
                this.custom_array(count).controls['data'].updateValueAndValidity();
              }
              if (data.is_mandatory) {
                this.custom_array(count).controls['data'].setValidators(Validators.required);
                this.custom_array(count).controls['data'].updateValueAndValidity();
              }
              count++;
            }
          }
        }
        if (this.clientData) {
          this.addFieldValues();
        }
      }
    });
  }

  get custom_client_fields(): FormArray {
    return this.clientForm.get("custom_client_fields") as FormArray;
  }

  addfieldsData(data: any) {
    this.custom_client_fields.push(this.custom_fields(data));
  }

  addFieldValues() {
    const copyCustomFiledArray = this.customFieldsArray.filter((elem: any) => elem.on_form);
    copyCustomFiledArray.forEach(async (field: any, i: any) => {
      const index = this.clientData.custom_client_fields.findIndex(function (item: any) {
        return item.custom_client_template === field.id;
      });
      if (index >= 0) {
        if (this.clientData.custom_client_fields[index].data_type === 'DATE_RANGE') {
          this.custom_client_fields.controls[i].patchValue({
            start: this.clientData.custom_client_fields[index].data !== "NA" ? this.clientData.custom_client_fields[index].data.split('&')[0] : "",
            end: this.clientData.custom_client_fields[index].data !== "NA" ? this.clientData.custom_client_fields[index].data.split('&')[1] : "",
            data: this.clientData.custom_client_fields[index].data !== "NA" ? this.clientData.custom_client_fields[index].data : "",
            id: this.clientData.custom_client_fields[index].id
          });
        } else if (this.clientData.custom_client_fields[index].data_type === 'PHONE_NUMBER') {
          this.custom_client_fields.controls[i].patchValue({
            start: this.clientData.custom_client_fields[index].data !== "NA" ? this.clientData.custom_client_fields[index].data?.custom_ccode : "",
            end: this.clientData.custom_client_fields[index].data !== "NA" ? this.clientData.custom_client_fields[index].data.custom_phone_number : "",
            data: this.clientData.custom_client_fields[index].data !== "NA" ? this.clientData.custom_client_fields[index].data : "",
            id: this.clientData.custom_client_fields[index].id
          });
        } else {
          this.custom_client_fields.controls[i].patchValue({
            data: this.clientData.custom_client_fields[index].data !== "NA" ? this.clientData.custom_client_fields[index].data : "",
            id: this.clientData.custom_client_fields[index].id
          });
        }
      }
    });
  }

  custom_fields(data: any): FormGroup {
    return new FormGroup({
      id: new FormControl(""),
      label: new FormControl(data?.label ? data.label : ''),
      data_type: new FormControl(data?.data_type ? data.data_type : ''),
      options: new FormControl(data?.options ? data.options : ''),
      on_form: new FormControl(data?.on_form ? data.on_form : true),
      data: new FormControl(data?.data ? (data.data !== "NA" ? (data.data_type === 'CHECKBOX' ? [] : data.data) : "") : ""),
      is_mandatory: new FormControl(data?.is_mandatory ? data.is_mandatory : false),
      is_active: new FormControl(data?.is_active ? data.is_active : true),
      custom_client_template: new FormControl(data?.id ? data.id : ''),
      client: new FormControl(this.clientData?.id),
      organisation: new FormControl(this.loggedInUser.data.organisation),
      start: new FormControl(''),
      end: new FormControl('')
    });
  }

  custom_array(count: number): any {
    return this.custom_client_fields.controls[count] as FormArray;
  }

  toggleCheckbox(value: string, i = 0) {
    const selectedOptionsControl = this.custom_array(i).get('data');
    const currentValue = selectedOptionsControl.value;
    if (currentValue.includes(value)) {
      selectedOptionsControl.setValue(currentValue.filter((item: any) => item !== value));
    } else {
      currentValue.push(value);
      selectedOptionsControl.setValue(currentValue);
    }
  }

  addNewCompanyName = (name: any) => ({ name: name, id: '' });

  onSelectCompany(e: any) {
    this.clientForm.patchValue({ source_id: e.id, company_name: e.name });
  }

  getFieldValidity(i: number) {
    return (<FormArray>this.clientForm.get('custom_client_fields')).controls[i].invalid;
  }

  // searchCompany(term: any) {
  //   if (term.term.length >= 3) {
  //     this.crm_service.getCompaniesBySearch(term.term).subscribe({
  //       next: (resp: any) => {
  //         this.companiesListArray = resp.data;
  //       }
  //     });
  //   } else if (term.term.length === 0) {
  //     this.getCompaniesList();
  //   }
  // }

  getCompanyById(id: string) {
    this.crm_service.getCompanyById(id).subscribe({
      next: (company_id_info: any) => {
        if (company_id_info.data.length > 0) {
          this.companiesListArray.push(company_id_info.data[0]);
          this.companiesListArray = [...this.companiesListArray];
        }
      }
    });
  }

  clearCompany(e: any) {
    this.clientForm.patchValue({ company_name: '' });
  }

  //custom type File upload function
  onFileUpload(ev: any, index: number) {
    if (!ev.target.files[0]) return;
    if (ev.target.files[0].size > 15 * 1048576) {
      this.toaster.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    this.awsUtilService.getUploadFileLink(ev.target.files[0].type, ev.target.files[0].name.split('.')[1]).subscribe({
      next: (resp) => {
        this.custom_client_fields.controls[index].patchValue({ data: { path: this.presignedurl + resp.data.file_name, file_name: ev.target.files[0].name } });
        this.awsUtilService.uploadFile(ev.target.files[0], resp.data.url).subscribe({
          next: () => {//@typescript-eslint/no-empty-function
          }
        });
      }
    });
  }

  onErrorLoadImage(event: any, target: any, file: any) {
    event.target.src = ErrorLoadImage(target, file);
  }

  onDisplayPicUpload(ev: any) {
    this.awsUtilService.getUploadFileLink(ev.target.files[0].type, ev.target.files[0].name.split('.')[1]).subscribe((resp) => {
      this.awsUtilService.uploadFile(ev.target.files[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
        this.clientForm.controls['display_picture'].setValue(this.presignedurl + resp.data.file_name);
      });
    });
  }
  onRemoveImage(){
    this.clientForm.controls['display_picture'].setValue('');
  }
}

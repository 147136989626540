<div class="bg-crm-info h-100 p-3">
  <div class="container-fluid info-screen-overflow auto-hide-scroll-bar p-0 bg-white border-radius-10"
    (scroll)="onScroll()">
    <!-- <div class="d-flex align-items-center pt-2 pb-2">
      <div class="background-on-hover me-2 d-flex align-items-center">
        <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
          aria-label="Button that displays a tooltip when focused or hovered over"
          class="text-primary cursor font-size-16 d-flex align-items-center justify-content-around"
          [routerLink]="['/clients/client-projects']" routerLinkActive="router-link-active">arrow_back_ios </mat-icon>
      </div>
      <div class="person-name">
        <h5 class="text-primary fw-500 font-size-16  mb-0" *ngIf="project">{{project.name | titlecase}}</h5> -->
    <!-- <div class="d-flex align-items-center">
          <mat-icon style="color: #9197B3;font-size: 22px">check_circle_outline</mat-icon>
          <p class="text-inner-info ps-1 m-0">{{project.project_status}}</p>
        </div> -->
    <!-- </div> -->
    <!-- <mat-icon>more_vert</mat-icon> -->
    <!-- </div> -->
    <div>
      <div class="mb-3">
        <mat-accordion class="client-accord">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="w-100">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="fw-600 text-primary mb-1">Related Project</p>
                    <!-- <mat-icon class="mb-1 font-size-18">expand_more</mat-icon> -->
                  </div>
                  <!-- <div class="d-flex align-items-center">
                    <div class="avatar-group d-flex align-items-center">
                      <div
                        class="avatar-xs avatar-group-item bg-primary text-white fw-400 border-radius-50 d-flex align-items-center justify-content-center">
                        {{(project?.name ?
                        project?.name[0] : "-" ) | titlecase}}
                      </div>
                    </div>
                    <p class="fw-400 mb-0 ms-2 text-inner-info">{{(project?.name ?
                      project?.name : "-") | titlecase}}</p>

                  </div> -->
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="project">
              <div class="no-gutters bottom-border p-3 cursor d-flex">
                <div class="info-accord col-5">
                  <p class="text-inner-info">Project Id </p>
                </div>
                <div class="info-accord d-flex col-7">
                  <span class="pe-1">:</span>
                  <p class="text-name text-capitalize limit-line-length-1">{{project?.project_id || "NA"}}
                  </p>
                </div>
              </div>
              <div class="no-gutters bottom-border p-3 cursor d-flex">
                <div class="info-accord col-5">
                  <p class="text-inner-info">Title</p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="info-accord d-flex col-7">
                  <p class="text-name text-wrap text-ellipsis w-100"
                    (click)="project?.name?.length > 12 ? onOpenDetailBox($event,{title:'Project Title',value:project?.name}) : ''">
                    <span class="pe-1">:</span>
                    <app-table-popover class="w-100" [inputType]="'text'" [inputValue]="project?.name"
                      [labelName]="'Name'" [formName]="'name'"
                      (update)="onUpdatePopOver($event, project)"></app-table-popover>
                  </p>
                </div>
              </div>
              <div class="no-gutters bottom-border p-3 cursor d-flex">
                <div class="info-accord col-5">
                  <p class="text-inner-info">Status</p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="info-accord d-flex col-7">
                  <p class="text-name text-wrap">
                    <span class="pe-1">:</span>
                    <!-- {{project.project_status ? project.project_status : "-"}} -->
                    <app-table-popover class="w-100" [inputType]="'dropdown'"
                      [inputValue]="(project?.project_status | titlecase)" [formName]="'project_status'"
                      [labelName]="'Status'" [dropDownItems]="statusList"
                      [selectedItem]="project?.project_status?.toLowerCase() === 'pending' ? 1 : 2"
                      (update)="onUpdatePopOver($event, project)"></app-table-popover>
                  </p>
                </div>
              </div>
              <div class="no-gutters bottom-border p-3 cursor d-flex">
                <div class="info-accord col-5">
                  <p class="text-inner-info">Due Date</p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="info-accord d-flex col-7">
                  <p class="text-name text-wrap"
                    (click)="project?.due_date?.length > 12 ? onOpenDetailBox($event,{title:'Due Date',value:project?.due_date}) : ''">
                    <span class="pe-1">:</span>
                    <app-table-popover class="w-100" [inputType]="'dateTime'" [inputValue]="project?.due_date"
                      [formName]="'due_date'" [labelName]="'Due Date'"
                      (update)="onUpdatePopOver($event, project)"></app-table-popover>
                  </p>
                </div>
              </div>
              <ng-container *ngIf="!clientproject_seemore">
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Owner</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7"
                    (click)="project_owner?.fname?.length > 8 ? onOpenDetailBox($event,{title:'Owner',value:project_owner?.fname + ' ' + project_owner?.lname}) : ''">
                    <p class="text-name text-wrap text-ellipsis w-100">
                      <span class="pe-1">:</span>
                      <app-table-popover class="w-100" [inputType]="'dropdown'"
                        [inputValue]="(project_owner?.fname || '') + ' ' + (project_owner?.lname || '')"
                        [formName]="'info.owner_info'" [labelName]="'Owner'" [dropDownItems]="allStaffMembers"
                        [selectedItem]="project?.owner" (update)="onUpdatePopOver($event, project)"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Staff</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <p class="text-name text-wrap">
                      <span class="pe-1">:</span>
                      <app-table-popover class="w-100" [inputType]="'multiselect'" [inputValue]="staffMembers"
                        [formName]="'assignee'" [labelName]="'Staff'" [dropDownItems]="allStaffMembers"
                        [selectedItem]="project?.assignee"
                        (update)="onUpdatePopOver($event, project)"></app-table-popover>

                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Budget</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <p class="text-name text-wrap"
                      (click)="project?.cost?.length > 12 ? onOpenDetailBox($event,{title:'Budget',value:project?.cost}) : ''">
                      <span class="pe-1">:</span>
                      <app-table-popover class="w-100" [inputType]="'text'" [inputValue]="project?.cost"
                        [labelName]="'Budget'" [formName]="'cost'"
                        (update)="onUpdatePopOver($event, project)"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Tag</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="pe-1">:</span>
                      <span class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;"
                        [style]="{'background-color': project?.colour}"></span>
                      <p class="text-name text-wrap text-ellipsis w-100" style="margin-bottom: 0px !important;"
                        (click)="project?.tag_name?.length > 12 ? onOpenDetailBox($event,{title:'Tag',value:project?.tag_name}) : ''">
                        <app-table-popover class="w-100" [inputType]="'dropdown'" [inputValue]="project?.tag_name"
                          [formName]="'tags'" [labelName]="'Tag'" [dropDownItems]="tagsListArray"
                          [selectedItem]="project?.tags"
                          (update)="onUpdatePopOver($event, project, 'project')"></app-table-popover>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Channel</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <span class="pe-1">:</span>
                    <p class="text-name text-wrap text-capitalize"
                      (click)="project?.channel?.length > 12 ? onOpenDetailBox($event,{title:'Channel',value:project?.channel.replace('_',' '),isTitleCase:true}) : ''">
                      {{project?.channel ? (project?.channel.replace("_", "
                      ")
                      |
                      titlecase) : ""}}</p>
                  </div>
                </div>

                <div class="no-gutters" *ngFor="let item of project | keyvalue">
                  <div class="" *ngIf="item.value">
                    <div class="bottom-border p-3 cursor d-flex"
                      *ngIf="item.key === 'scheduler_category' || item.key === 'scheduler' || item.key === 'product_category' || item.key === 'product' || item.key === 'service_category' || item.key === 'service'">
                      <div class="info-accord col-5">
                        <p [matTooltip]="item.key.replace('_', ' ') | titlecase"
                          class="text-inner-info limit-line-length-1">{{item.key.replace("_", " ") | titlecase}}</p>
                      </div>
                      <!-- <div class="col-md-1"></div> -->
                      <!-- <div class="info-accord" *ngIf="isObject(item.value)">
                        <ng-container>
                          <p class="text-name text-wrap text-ellipsis w-100" *ngFor="let ele of item.value | keyvalue">
                            {{ ele.value }}
                          </p>
                        </ng-container>
                      </div> -->
                      <div class="info-accord d-flex col-7"
                        (click)="project[item.key][0]?.name?.length > 12 ? onOpenDetailBox($event,{title:item.key.replace('_', ' '),value:project[item.key][0]?.name,isTitleCase:true}) : ''">
                        <span class="pe-1">:</span>
                        <p class="text-name text-wrap text-capitalize">
                          <app-table-popover class="w-100" [inputType]="'dropdown'"
                            [inputValue]="project[item.key][0]?.name" [selectedItem]="project[item.key][0]?.id"
                            *ngIf="item.key === 'scheduler_category'" [formName]="item.key" [labelName]="item.key"
                            [dropDownItems]="appointment_category"
                            (update)="onUpdatePopOver($event, project)"></app-table-popover>
                          <app-table-popover class="w-100" [inputType]="'dropdown'"
                            [inputValue]="project[item.key][0]?.name" [selectedItem]="project[item.key][0]?.id"
                            *ngIf="item.key === 'scheduler'" [formName]="item.key" [labelName]="item.key"
                            [dropDownItems]="appointment"
                            (update)="onUpdatePopOver($event, project)"></app-table-popover>
                          <app-table-popover class="w-100" [inputType]="'dropdown'"
                            [inputValue]="project[item.key][0]?.name" [selectedItem]="project[item.key][0]?.id"
                            *ngIf="item.key === 'product_category'" [formName]="item.key" [labelName]="item.key"
                            [dropDownItems]="product_category"
                            (update)="onUpdatePopOver($event, project)"></app-table-popover>
                          <app-table-popover class="w-100" [inputType]="'dropdown'"
                            [inputValue]="project[item.key][0]?.name" [selectedItem]="project[item.key][0]?.id"
                            *ngIf="item.key === 'product'" [formName]="item.key" [labelName]="item.key"
                            [dropDownItems]="product" (update)="onUpdatePopOver($event, project)"></app-table-popover>
                          <app-table-popover class="w-100" [inputType]="'dropdown'"
                            [inputValue]="project[item.key][0]?.name" [selectedItem]="project[item.key][0]?.id"
                            *ngIf="item.key === 'service_category'" [formName]="item.key" [labelName]="item.key"
                            [dropDownItems]="service_category"
                            (update)="onUpdatePopOver($event, project)"></app-table-popover>
                          <app-table-popover class="w-100" [inputType]="'dropdown'"
                            [inputValue]="project[item.key][0]?.name" [selectedItem]="project[item.key][0]?.id"
                            *ngIf="item.key === 'service'" [formName]="item.key" [labelName]="item.key"
                            [dropDownItems]="services_list"
                            (update)="onUpdatePopOver($event, project)"></app-table-popover>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Created By</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7"
                    (click)="project?.created_by_name?.length > 12 ? onOpenDetailBox($event,{title:'Created By',value:project?.created_by_name}) : ''">
                    <span class="pe-1">:</span>
                    <p class="text-name text-wrap">{{project?.created_by_name ? project.created_by_name : ""}}
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Created At</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <span class="pe-1">:</span>
                    <p class="text-name text-wrap">{{project?.created_at | dateConversion}} {{project?.created_at | date: 'h:mm a'}}
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Updated By</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7"
                    (click)="project?.updated_by_name?.length > 12 ? onOpenDetailBox($event,{title:'Updated By',value:project?.updated_by_name}) : ''">
                    <span class="pe-1">:</span>
                    <p class="text-name text-wrap">{{project?.updated_by_name ? project.updated_by_name : ""}}
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Updated At</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-5">
                    <span class="pe-1">:</span>
                    <p class="text-name text-wrap"
                      *ngIf="project?.created_at.split('.')[0] === project?.updated_at.split('.')[0]"></p>
                    <p class="text-name text-wrap"
                      *ngIf="project?.created_at.split('.')[0] !== project?.updated_at.split('.')[0]">
                      {{project?.updated_at | dateConversion}} {{project?.updated_at | date: 'h:mm a'}}</p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Description</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7"
                    (click)="project?.description?.length > 12 ? onOpenDetailBox($event,{title:'Description',value:project?.description}) : ''">
                    <p class="text-name text-wrap w-100" style="margin-bottom: 0px !important;">
                      <span class="pe-1">:</span>
                      <app-table-popover class="w-100" [inputType]="'textarea'" [inputValue]="project?.description"
                        [formName]="'description'" [labelName]="'Description'"
                        (update)="onUpdatePopOver($event, project)"></app-table-popover>
                    </p>
                  </div>
                </div>

              </ng-container>
              <p class="text-primary cursor fw-500 p-3 m-0" (click)="seeMore('project')"><span
                  *ngIf="clientproject_seemore">See
                  more</span><span *ngIf="!clientproject_seemore">See less</span></p>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mb-3">
        <mat-accordion class="client-accord">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="w-100">
                  <div class="d-flex align-items-center justify-content-between editableData">
                    <p class="fw-600 text-primary mb-1">Additional fields for project</p>
                    <div class="">
                      <!-- <mat-icon class="font-size-18">expand_more</mat-icon> -->
                      <img src="assets/images/edit_pen.svg" class="me-3 pe-1" alt=""
                        (click)="openProjectModalDialog(project)" (click)="$event.stopPropagation();">
                      <!-- <mat-icon class="fs-6 edit-icon rounded-top rounded-bottom text-end me-3 font-size-13" mat-button
                        (click)="openProjectModalDialog(project)">edit</mat-icon> -->
                    </div>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="project?.custom_project_fields?.length !== 0">
              <div class="no-gutters bottom-border p-3 cursor d-flex"
                *ngFor="let item of custom_field | slice:0: slice_additional_Item" [ngStyle]="{'opacity':item.is_active ? '1' : '0.3'}">
                <div class="info-accord col-5">
                  <p class="text-inner-info">{{item.label.replace("_", " ") | titlecase}}
                  </p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="info-accord col-7 d-flex">
                  <!-- <p class="text-name text-wrap text-ellipsis w-100"
                    *ngIf="item.data_type !== 'DATE' && item.data_type !== 'FILE'">
                    {{item?.data}}
                  </p>
                  <p class="text-name text-wrap text-ellipsis w-100" *ngIf="item.data_type === 'DATE'">
                    {{item?.data | dateConversion}}
                  </p>
                  <p class="text-name text-wrap text-wrap w-100" *ngIf="item.data_type === 'FILE'">
                    <img src="{{item?.data?.path}}" alt="" width="150px"
                      (error)="onErrorLoadImage($event,presignedurl,item?.data?.file_name)"><br>
                    <a href="{{item?.data?.path}}" target="_blank" class="ms-2 text-dark fw-medium mt-3"
                      download>{{item?.data?.file_name}}</a>
                  </p> -->
                  <p class="text-name text-wrap text-wrap w-100"
                    (click)="item?.data?.length > 12 ? onOpenDetailBox($event,{title:item.label.replace('_', ' '),isTitleCase:true,value:item?.data}) : ''">
                    <span class="pe-1">:</span>
                    <app-table-popover class="w-100" [is_active]="item?.is_active"  [inputType]="item.data_type" [fieldType]="'customField'"
                      [inputValue]="item.data" [formName]="item.id" [labelName]="item.label"
                      [dropDownItems]="item.options"
                      *ngIf="item.data_type !== 'DATE_TIME' && item.data_type !== 'DATE' && item.data_type !== 'DATE_RANGE'"
                      (update)="onUpdatePopOver($event, project, 'project','custom_field',item)"></app-table-popover>
                    <app-table-popover class="w-100" [is_active]="item?.is_active"  [inputType]="item.data_type" [inputValue]="item.data"
                      [formName]="item.id" [labelName]="item.label" [fieldType]="'customField'"
                      *ngIf="item.data_type === 'DATE_TIME' || item.data_type === 'DATE' || item.data_type === 'DATE_RANGE'"
                      (update)="onUpdatePopOver($event, project, 'project','custom_field',item)"></app-table-popover>
                  </p>
                </div>
              </div>
              <p class="text-primary cursor fw-500 p-3 m-0" (click)="seeMoreAdditionalFields('project')"
                *ngIf="!addmore && custom_field.length>5"><span>See more</span></p>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mb-3">
        <mat-accordion class="client-accord">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="w-100">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="fw-600 text-primary mb-1">Related Client</p>
                    <!-- <mat-icon class="mb-1 font-size-18">expand_more</mat-icon> -->
                  </div>
                  <!-- <div class="d-flex align-items-center">
                    <div class="avatar-group d-flex align-items-center">
                      <div
                        class="avatar-xs avatar-group-item bg-primary text-white fw-400 border-radius-50 d-flex align-items-center justify-content-center">
                        {{(client?.name ?
                        client?.name[0] : "-" ) | titlecase}}
                      </div>
                    </div>
                    <p class="fw-400 mb-0 ms-2 text-inner-info">{{(client?.name ?
                      client?.name : "-") | titlecase}}</p>

                  </div> -->
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="client">
              <div class="no-gutters bottom-border p-3 cursor d-flex">
                <div class="info-accord col-5">
                  <p class="text-inner-info">Client Id</p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="info-accord d-flex col-7">
                  <span class="pe-1">:</span>
                  <p class="text-name text-wrap">{{client?.client_id || "NA"}}</p>
                </div>
              </div>
              <div class="no-gutters bottom-border p-3 cursor d-flex">
                <div class="info-accord col-5">
                  <p class="text-inner-info">Name</p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="info-accord d-flex col-7">
                  <span class="pe-1">:</span>
                  <p class="text-name text-wrap"
                    (click)="client?.name?.length > 12 ? onOpenDetailBox($event,{title:'Client Name',value:client?.name}) : ''">
                    {{client?.name ? client?.name : "NA"}}</p>
                </div>
              </div>
              <div class="no-gutters bottom-border p-3 cursor d-flex">
                <div class="info-accord col-5">
                  <p class="text-inner-info">Email</p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="info-accord d-flex col-7">
                  <span class="pe-1">:</span>
                  <p class="text-name text-wrap text-ellipsis limit-line-length-1"
                    (click)="client?.email?.length > 12 ? onOpenDetailBox($event,{title:'Email',value:client?.email}) : ''">
                    {{client?.email ? client?.email : ""}}</p>
                </div>
              </div>
              <div class="no-gutters bottom-border p-3 cursor d-flex">
                <div class="info-accord col-5">
                  <p class="text-inner-info col-5">Phone</p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="info-accord d-flex col-7">
                  <span class="pe-1">:</span>
                  <p class="text-name d-flex cursor w-100">
                    <span>{{client?.ccode ? client?.ccode :
                      ""}}</span>&nbsp;<app-table-popover [inputType]="'text'" style="width: 85%;"
                      [cCode]="client.ccode ? client.ccode : ''" [inputValue]="client?.phone_number"
                      [labelName]="'Phone Number'" [formName]="'phone_number'"
                      (update)="onUpdatePopOver($event, client, 'client')"></app-table-popover>
                  </p>
                  <!-- <span *ngIf="client?.phone_number">{{client?.ccode ? client?.ccode :
                    ""}}</span>&nbsp;{{client?.phone_number ? client?.phone_number : "NA"}}</p> -->
                </div>
              </div>
              <div class="no-gutters bottom-border p-3 cursor d-flex">
                <div class="info-accord col-5">
                  <p class="text-inner-info">Projects</p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="info-accord d-flex col-7">
                  <span class="pe-1">:</span>
                  <p class="text-name text-wrap">{{client?.num_projects ? client?.num_projects : "NA"}}</p>
                </div>
              </div>
              <ng-container *ngIf="!client_seemore">
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Owner</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <p class="text-name text-wrap text-ellipsis w-100"
                      (click)="client_owner?.fname?.length > 8 ? onOpenDetailBox($event,{title:'Owner',value:client_owner?.fname + ' ' + client_owner?.lname}) : ''">
                      <span class="pe-1">:</span>
                      <app-table-popover class="w-100" [inputType]="'dropdown'"
                        [inputValue]="(client_owner?.fname || '') + ' ' + (client_owner?.lname || '')" [formName]=""
                        [labelName]="'Owner'" [dropDownItems]="staffMembers" [selectedItem]="client?.owner"
                        (update)="onUpdatePopOver($event, client, 'client')"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Tag</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord col-7">
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="pe-1">:</span>
                      <span class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;" *ngIf="client?.colour"
                        [style]="{'background-color': client?.colour}"></span>
                      <p class="text-name text-wrap text-ellipsis w-100" style="margin-bottom: 0px !important;"
                        (click)="client?.tag_name?.length > 12 ? onOpenDetailBox($event,{title:'Tag',value:client?.tag_name}) : ''">
                        <app-table-popover class="w-100" [inputType]="'dropdown'" [inputValue]="client?.tag_name"
                          [formName]="'tags'" [labelName]="'Tag'" [dropDownItems]="tagsListArray"
                          [selectedItem]="client?.tags"
                          (update)="onUpdatePopOver($event, client, 'client')"></app-table-popover>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">channel</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <span class="pe-1">:</span>
                    <p class="text-name text-wrap text-capitalize"
                      (click)="client?.channel?.length > 12 ? onOpenDetailBox($event,{title:'Channel',value:client.channel.replace('_',' ')}) : ''">
                      {{client?.channel ? (client?.channel.replace("_", "
                      ")
                      |
                      titlecase) : ""}}</p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Country</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <p class="text-name text-wrap">
                      <span class="pe-1">:</span>
                      <app-table-popover class="w-100" [inputType]="'dropdown'" [inputValue]="client.country"
                        [formName]="'country'" [labelName]="'Country'" [dropDownItems]="countryjson"
                        [selectedItem]="client?.country"
                        (update)="onUpdatePopOver($event, client, 'client')"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="col-5 info-accord">
                    <p class="text-inner-info">Created By</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <span class="pe-1">:</span>
                    <p class="text-name text-wrap"
                      (click)="client?.created_by_name?.length > 12 ? onOpenDetailBox($event,{title:'Created By',value:client?.created_by_name}) : ''">
                      {{client?.created_by_name ? client.created_by_name : ""}}
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Created At</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <span class="pe-1">:</span>
                    <p class="text-name text-wrap">{{client?.created_at | dateConversion}} {{client?.created_at | date: 'h:mm a'}}
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Updated By</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7"
                    (click)="client?.updated_by_name?.length > 12 ? onOpenDetailBox($event,{title:'Updated By',value:client?.updated_by_name}) : ''">
                    <span class="pe-1">:</span>
                    <p class="text-name text-wrap">{{client?.updated_by_name ? client.updated_by_name : "NA"}}
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Updated At</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <span class="pe-1">:</span>
                    <p class="text-name text-wrap"
                      *ngIf="client?.created_at.split('.')[0] === client?.updated_at.split('.')[0]">
                    </p>
                    <p class="text-name text-wrap"
                      *ngIf="client?.created_at.split('.')[0] !== client?.updated_at.split('.')[0]">
                      {{client?.updated_at | dateConversion}} {{client?.updated_at | date: 'h:mm a'}}</p>
                  </div>
                </div>
              </ng-container>
              <p class="text-primary cursor fw-500 p-3 m-0" (click)="seeMore('client')"><span *ngIf="client_seemore">See
                  more</span><span *ngIf="!client_seemore">See less</span></p>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mb-3">
        <mat-accordion class="client-accord">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class=" w-100">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="fw-600 text-primary mb-1">Additional fields for client</p>
                    <div class="d-flex align-items-center">
                      <!-- <mat-icon class="mb-1 font-size-18">expand_more</mat-icon> -->
                      <img src="assets/images/edit_pen.svg" class="me-3 pe-1" alt=""
                        (click)="openClientModalDialog(client)" (click)="$event.stopPropagation();">
                      <!-- <mat-icon class="fs-6 font-size-13 edit-icon rounded-top rounded-bottom text-end me-3" mat-button
                        (click)="openClientModalDialog(client)">edit</mat-icon> -->
                    </div>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="client_custom_field.length !== 0">
              <div class="no-gutters bottom-border p-3 cursor d-flex" [ngStyle]="{'opacity':item.is_active ? '1' : '0.3'}"
                *ngFor="let item of client_custom_field | slice:0: slice_additional_contact_Item">
                <div class="info-accord col-5">
                  <p class="text-inner-info">{{item.label.replace("_", " ") | titlecase}} </p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="info-accord d-flex col-7">
                  <!-- : &nbsp; -->
                  <!-- <p class="text-name text-wrap text-ellipsis w-100"
                    *ngIf="item.data_type !== 'DATE' && item.data_type !== 'FILE'">
                    {{item?.data}}
                  </p>
                  <p class="text-name text-wrap text-ellipsis w-100" *ngIf="item.data_type === 'DATE'">
                    {{item?.data | dateConversion}}
                  </p>
                  <p class="text-name text-wrap text-wrap w-100" *ngIf="item.data_type === 'FILE'">
                    <img src="{{item?.data?.path}}" alt="" width="150px"
                      (error)="onErrorLoadImage($event,presignedurl,item?.data?.file_name)"><br>
                    <a href="{{item?.data?.path}}" target="_blank" class="ms-2 text-dark fw-medium mt-3"
                      download>{{item?.data?.file_name}}</a>
                  </p> -->
                  <p class="text-name text-wrap w-100"
                    (click)="item?.data?.length > 12 ? onOpenDetailBox($event,{title:item.label.replace('_', ' '),isTitleCase:true,value:item?.data}) : ''">
                    <span class="pe-1">:</span>
                    <app-table-popover class="w-100" [is_active]="item?.is_active" [inputType]="item.data_type" [fieldType]="'customField'"
                      [inputValue]="item.data" [formName]="item.id" [labelName]="item.label"
                      [dropDownItems]="item.options"
                      *ngIf="item.data_type !== 'DATE_TIME' && item.data_type !== 'DATE' && item.data_type !== 'DATE_RANGE'"
                      (update)="onUpdatePopOver($event, client, 'client','custom_field',item)"></app-table-popover>
                    <app-table-popover class="w-100" [is_active]="item?.is_active" [inputType]="item.data_type" [inputValue]="item.data"
                      [formName]="item.id" [labelName]="item.label" [fieldType]="'customField'"
                      *ngIf="item.data_type === 'DATE_TIME' || item.data_type === 'DATE' || item.data_type === 'DATE_RANGE'"
                      (update)="onUpdatePopOver($event, client, 'client','custom_field',item)"></app-table-popover>
                  </p>
                </div>
              </div>
              <p class="text-primary cursor fw-500 p-3 m-0" (click)="seeMoreAdditionalFields('client')"
                *ngIf="!seemoreContacts && client_custom_field.length>5"><span>See more</span></p>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
<div class="position-absolute details-box bg-white border-radius-12 custom">
  <div
    class="title position-sticky limit-line-length-1 text-capitalize  border-bottom px-3 py-2 text-center fs-14 text-primary fw-500">
    {{detailsBox.detailsBoxTitle}}
  </div>
  <div class="p-3 pt-2 body-sec fs-14 text-primary" [ngClass]="detailsBox.isTitleCase ? 'text-capitalize' : '' "
    *ngIf="detailsBox.detailsBoxTitle !== 'DATE RANGE'" [innerHTML]="detailsBox.detailBoxBody">
  </div>
  <div class="p-3 pt-2 body-sec fs-14 text-primary" *ngIf="detailsBox.detailsBoxTitle === 'DATE RANGE'">
    {{((detailsBox.detailsBoxTitle.split('&')[0] | dateConversion) + '&' + (detailsBox.detailsBoxTitle.split('&')[1] |
    dateConversion))}}
  </div>
</div>
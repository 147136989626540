import { Component } from '@angular/core';
import { FINANCE } from 'src/app/core/data/finance_module_json';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})
export class FinanceComponent {
  financeJson = FINANCE[0];
  activeId: any = 3;
  featuresData: any;

  constructor(private authService: AuthService) { 
    this.getFeatues();
  }

  tabChange(event: any) {
    this.activeId = event.nextId;
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }

  getRolePermissions(item: any,permission:any) {
    return this.authService.rolefeatureShow(item,permission);
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService) {
    /* tslint:disable:no-empty */
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (sessionStorage.getItem('currentUser') && !request.url.includes('brexaa-stage') && !request.url.includes('brexa-app') && !request.url.includes('cdn.brexa.ai')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${sessionStorage.getItem('currentUser') ? JSON.parse(sessionStorage.getItem('currentUser')!)?.access_token : ''}`
        }
      });
    }
    if (request.method === 'POST') {
      this.spinner.show();
    }
  
    return new Observable(observer => {
      const subscription = next.handle(request)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              observer.next(event);
              setTimeout(() => {
                this.spinner.hide();
              }, 1500);
            }
          },
          err => {
            observer.error(err);
            setTimeout(() => {
              this.spinner.hide();
            }, 1500);
          },
          () => {
            observer.complete();
            setTimeout(() => {
              this.spinner.hide();
            }, 1500);
          });
      return () => {
        subscription.unsubscribe();
      };
    });
  }
}
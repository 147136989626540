import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { io } from "socket.io-client";
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { constant } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private url = environment.socketUrl;
  private socket: any;
  private loggedInUser;
  chatRedirectUrlSubs$ = new Subject();
  notificationCount$ = new Subject();
  clearAllMessagesSub$ = new Subject();
  updateCountsSub$ = new Subject();
  getMessageSubscription$ = new Subject();
  getTransferChatSubscription$ = new Subject();
  getExitChatSubscription$ = new Subject();
  getLiveChatSubscription$ = new Subject();
  getUserRequestSubscription$ = new Subject();
  getGroupChatSubscription$ = new Subject();
  time_keeper: any;
  getAudioVideoChatSubscription$ = new Subject();
  twilio_incomingvoice_call = new BehaviorSubject(null);


  constructor(private authService: AuthService, private http: HttpClient) {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    if (this.loggedInUser) {
      this.connectSockets();
    }
    this.authService.authUserSubscription$.subscribe((userInfo: any) => {
      if (userInfo) {
        this.loggedInUser = userInfo;
        this.connectSockets();
      } else {
        this.disconnectSocket();
      }
    });
  }

  emitAllEvents() {
    //Message
    this.socket.on('MESSAGE_RECEIVED', (message: any) => {
      console.log(message, "recived msz");

      this.updateCountsSub$.next('');
      if (message?.senderUser.user !== this.loggedInUser.data.id) {

        this.getCurrentPathName(message) && this.getMessageSubscription$.next(message);
        // if (message?.chat?.type === "teamchat") {
        //   if (message?.chat?.group) {
        //     message.redirectUrl = `/chats/teamchats/${message?.chat._id}?list=group`;
        //     this.getCurrentPathName(message) && this.getMessageSubscription$.next(message);
        //   } else {
        //     message.redirectUrl = `/chats/teamchats/${message?.chat?._id}?list=dms`;
        //     this.getCurrentPathName(message) && this.getMessageSubscription$.next(message);
        //   }
        // } else if (message?.chat?.type === "livechat") {
        //   message.redirectUrl = `/chats/livechats/${message?.chat?._id}?list=active`;
        //   this.getCurrentPathName(message) && this.getMessageSubscription$.next(message);
        // } else if (message?.chat?.type === "chatbot" || message?.chat?.type === "aiflow" || message?.chat?.type === "aiassist") {
        //   message.redirectUrl = `/chats/botchats/${message?.chat?._id}`;
        //   this.getCurrentPathName(message) && this.getMessageSubscription$.next(message);
        // } else {
        //   message.redirectUrl = `/chats/conversations/${message?.chat?._id}`;
        //   this.getCurrentPathName(message) && this.getMessageSubscription$.next(message);
        // }
      }
    });

    //Transfer Chat
    this.socket.on('TRANSFER_CHAT_REQUEST_INITIATED', (message: any) => {
      console.log("TRANSFER_CHAT_REQUEST_INITIATED");

      if (message?.fromUser !== this.loggedInUser.data.id) {
        message.redirectUrl = `/chats/livechats/${message?.chatId}?list=requests`;
        this.getCurrentPathName(message) && this.getTransferChatSubscription$.next(message);
      }
    });
    this.socket.on('TRANSFER_CHAT_REQUEST_ACCEPTED', (message: any) => {
      console.log("TRANSFER_CHAT_REQUEST_ACCEPTED");

      if (message?.fromUser !== this.loggedInUser.data.id) {
        message.redirectUrl = `/chats/livechats/${message?.chatId}?list=active`;
        this.getCurrentPathName(message) && this.getTransferChatSubscription$.next(message);
      }
    });
    this.socket.on('TRANSFER_CHAT_REQUEST_REJECTED', (message: any) => {
      console.log("TRANSFER_CHAT_REQUEST_REJECTED");
      if (message?.fromUser !== this.loggedInUser.data.id) {
        message.redirectUrl = `/chats/livechats/${message?.chatId}?list=active`;
        this.getCurrentPathName(message) && this.getTransferChatSubscription$.next(message);
      }
    });

    //Live Chat
    this.socket.on('NEW_LIVE_CHAT', (message: any) => {
      console.log("NEW_LIVE_CHAT");
      if (message?.redirectUrl && message?.fromUser !== this.loggedInUser.data.id) {
        this.getCurrentPathName(message) && this.getLiveChatSubscription$.next(message);
      }
    });
    this.socket.on('LIVECHAT_REQUEST_INITIATED', (message: any) => {
      console.log("LIVECHAT_REQUEST_INITIATED", message);
      if (message?.fromUser !== this.loggedInUser.data.id) {
        message.redirectUrl = `/chats/livechats/${message?.chatId}?list=requests`;
        this.getCurrentPathName(message) && this.getLiveChatSubscription$.next(message);
      }
    });
    // this.socket.on('LIVECHAT_REQUEST_ACCEPTED', (message: any) => {
    //   console.log("LIVECHAT_REQUEST_ACCEPTED");
    //   if (message?.fromUser !== this.loggedInUser.data.id) {
    //     message.redirectUrl = `/chats/livechats/${message?.chat._id}?list=active`;
    //     this.getCurrentPathName(message) && this.getLiveChatSubscription$.next(message);
    //   }
    // });
    // this.socket.on('LIVECHAT_REQUEST_REJECTED', (message: any) => {
    //   console.log("LIVECHAT_REQUEST_REJECTED");
    //   if (message?.fromUser !== this.loggedInUser.data.id) {
    //     message.redirectUrl = `/chats/livechats/${message?.chat._id}?list=active`;
    //     this.getCurrentPathName(message) && this.getLiveChatSubscription$.next(message);
    //   }
    // });
    this.socket.on('ADD_USER_REQUEST_INITIATED', (message: any) => {
      console.log("ADD_USER_REQUEST_INITIATED");
      if (message?.fromUser !== this.loggedInUser.data.id) {
        message.redirectUrl = `/chats/livechats/${message?.chatId}?list=requests`;
        this.getCurrentPathName(message) && this.getUserRequestSubscription$.next(message);
      }
    });
    // this.socket.on('ADD_USER_REQUEST_REJECTED', (message: any) => {
    //   console.log("ADD_USER_REQUEST_REJECTED");
    //   if (message?.fromUser !== this.loggedInUser.data.id) {
    //     message.redirectUrl = `/chats/livechats/${message?.chat._id}?list=active`;
    //     this.getCurrentPathName(message) && this.getLiveChatSubscription$.next(message);
    //   }
    // });
    // this.socket.on('ADD_USER_REQUEST_ACCEPTED', (message: any) => {
    //   console.log("ADD_USER_REQUEST_ACCEPTED");
    //   if (message?.fromUser !== this.loggedInUser.data.id) {
    //     message.redirectUrl = `/chats/livechats/${message?.chat._id}?list=active`;
    //     this.getCurrentPathName(message) && this.getLiveChatSubscription$.next(message);
    //   }
    // });
    //Chat Notifications
    // this.socket.on('CHAT_NOTIFICATION', (message: any) => {
    //   console.log("CHAT_NOTIFICATION");
    //   if (message?.redirectUrl && (message?.fromUser !== this.loggedInUser.data.id)) {
    //     this.getCurrentPathName(message) && this.getLiveChatSubscription$.next(message);
    //   }
    // });
    //Audio/Video
    this.socket.on('NEW_INCOMING_CALL', (message: any) => {
      console.log("NEW_INCOMING_CALL");
      if (message?.redirectUrl && message?.fromUser !== this.loggedInUser.data.id) {
        this.getAudioVideoChatSubscription$.next(message);
      }
    });
    //New Notification
    this.socket.on('NEW_NOTIFICATION', (message: any) => {
      console.log("NEW_NOTIFICATION");
      this.notificationCount$.next(message);
    });
    //Transfer Chat
    // this.socket.on('GROUP_CHAT_USER_EXITED', (message: any) => {
    //   console.log("GROUP_CHAT_USER_EXITED");
    //   if (message?.fromUser !== this.loggedInUser.data.id) {
    //     message.redirectUrl = `/chats/teamchats/${message?.chat._id}?list=group`;
    //     this.getCurrentPathName(message) && this.getGroupChatSubscription$.next(message);
    //   }
    // });
    this.socket.on('GROUP_CHAT_USER_ADDED', (message: any) => {
      console.log("GROUP_CHAT_USER_ADDED");
      if (message?.fromUser !== this.loggedInUser.data.id) {
        message.redirectUrl = `/chats/teamchats/${message?.chatId}?list=group`;
        this.getCurrentPathName(message) && this.getGroupChatSubscription$.next(message);
      }
    });
    this.socket.on('GROUP_CHAT_STARTED', (message: any) => {
      console.log("GROUP_CHAT_STARTED");
      if (message?.fromUser !== this.loggedInUser.data.id) {
        message.redirectUrl = `/chats/teamchats/${message?.chatId}?list=group`;
        this.getCurrentPathName(message) && this.getGroupChatSubscription$.next(message);
      }
    });

    //twilio voice call 
    this.socket.on('TWILIO_INCOMING_CALL', (message: any) => {
      this.twilio_incomingvoice_call.next(message.callSid);
    });

  }

  connectSockets() {
    setTimeout(() => {
      this.socket = io(this.url, { reconnection: true });
      this.socket.emit('join', { user: this.loggedInUser?.automationUser, role: this.loggedInUser?.data?.role, org: this.loggedInUser?.data?.organisation });
      this.socketPingTimeKeeper();
      this.emitAllEvents();
      this.socket.io.on("reconnect", () => {
        this.socket = io(this.url, { reconnection: true });
        this.socket.emit('join', { user: this.loggedInUser?.automationUser, role: this.loggedInUser?.data?.role, org: this.loggedInUser?.data?.organisation });
        this.emitAllEvents();
      });

    }, 4000);
  }

  socketPingTimeKeeper() {
    this.socket.on("connect", () => {
      this.time_keeper = setInterval(() => {
        this.socket.emit("ping", {
          user: JSON.parse(sessionStorage.getItem('currentUser')!)?.data?.id
        });
      }, 1000 * 60 * 5);
    });
  }

  disconnectSocket() {
    if (this.socket) {
      clearInterval(this.time_keeper);
      this.socket.disconnect();
      console.log('Socket disconnected');
    }
  }

  getCurrentPathName(message: any) {
    if (message.redirectUrl.includes('/teamchats/') && window.location.pathname === '/teamchat') {
      if (sessionStorage.getItem('redirectteamchatUrl')) {
        const url = sessionStorage.getItem('redirectteamchatUrl');
        if (url === '/teamchats/') {
          return true;
        }
        return !(url?.includes(message?.chatId));
        // if (url?.includes(message?.chatId)) {
        //   return false;
        // } else {
        //   return true;
        // }
      } else {
        return true;
      }
    } else if (message.redirectUrl.includes('/livechats/') && window.location.pathname === '/live_chat') {
      if (sessionStorage.getItem('redirectUrl')) {
        const url = sessionStorage.getItem('redirectUrl');
        if (url === '/livechats') {
          return true;
        }
        return !(url?.includes(message?.chatId));
      } else {
        return true;
      }
    } else if (message.redirectUrl.includes('/conversations/') && window.location.pathname === '/conversation') {
      if (sessionStorage.getItem('redirectConUrl')) {
        const url = sessionStorage.getItem('redirectConUrl');
        if (url === '/conversations') {
          return true;
        }
        return !(url?.includes(message?.chatId));
      } else {
        return true;
      }
    } else if (message.redirectUrl.includes('/conversations/') && (window.location.pathname === '/crm/crm-details' || window.location.pathname === '/clients/client-managment')) {
      if (message?.clientProjects?.length > 0 || message?.enquiries?.length > 0 || message?.pipelineCards?.length > 0) {
        const url = new URL(window.location.href);
        let access = false;
        const currentViewedScreen = url.searchParams.get('id');
        console.log(currentViewedScreen);
        if (!currentViewedScreen) {
          access = true;
        } else {
          if (message?.clientProjects[0]?.length > 0 && currentViewedScreen !== message?.clientProjects[0]) {
            access = true;
          } else if (message?.enquiries[0]?.length > 0 && currentViewedScreen !== message?.enquiries[0]) {
            access = true;
          } else if (message?.pipelineCards[0]?.length > 0 && currentViewedScreen !== message?.pipelineCards[0]) {
            access = true;
          } else {
            access = false;
          }
        }
        return access;
      } else {
        return true;
      }
    } else if (message.redirectUrl.includes('/botchats/')) {
      return false;
    } else {
      return !(message.redirectUrl.includes('/conversations/') && window.location.pathname === '/crm/crm-details');
    }
  }

  getChatCount() {
    return this.http.get(`${environment.messageUrl}${constant.chat_count}`);
  }
}

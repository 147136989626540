import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-roles-permissions',
  templateUrl: './roles-permissions.component.html',
  styleUrls: ['./roles-permissions.component.scss']
})
export class RolesPermissionsComponent implements OnInit {
  errorjson = ERRORJSON[0];
  table_cols = ['Role', 'Active', 'Inactive', 'Actions'];
  rolesList: any[] = [];
  addRoleForm: any;
  submited = false;
  add_role_table_cols = ['Feature', 'Capabilities'];
  rolesPermissionModulesData = [];
  capabilities_list: any;
  templateRolesListType = true;
  permissionForm!: FormGroup<any>;
  selected_role_id: string | undefined;
  permission_search = '';
  loggedInUser: any;

  constructor(private formBuilder: FormBuilder, private settingService: SettingsService,
    private modalService: NgbModal,private confirmationMsz: ConfirmationMszService) { }

  ngOnInit() {
    this.getRoles();
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  }

  InitilizeRoles_Form(data?: any) {
    this.addRoleForm = this.formBuilder.group({
      name: [data ? data.name : '', Validators.required],
      id: [data ? data.id : '']
      // permissions: this.formBuilder.array([])
    });
  }
  InitilizePermission_Form() {
    this.permissionForm = this.formBuilder.group({
      permissions: this.formBuilder.array([])
    });
  }

  get f() {
    return this.addRoleForm.controls;
  }

  get p() {
    return this.permissionForm.controls;
  }

  formPermissions() {
    return this.permissionForm.get("permissions") as FormArray;
  }

  formElementsEdit(element: any) {
    return new FormGroup({
      name: new FormControl(element.brexa_module?.name),
      key: new FormControl(element.brexa_module?.name_key),
      id: new FormControl(element?.id),
      can_read: new FormControl(element.can_read),
      can_create: new FormControl(element.can_create),
      can_update: new FormControl(element.can_update),
      can_delete: new FormControl(element.can_delete),
      can_access: new FormControl({ value: element.can_access, disabled: !element.can_read })
    });
  }

  initalizeForm() {
    // this.rolesPermissionModulesData.forEach((element, i) => {
    //   this.formPermissions().insert(i, this.formElementsEdit(element, true));
    // });
  }

  addRole(type: boolean, role_id?: string) {
    this.templateRolesListType = type;
    if (!this.templateRolesListType) {
      this.selected_role_id = role_id;
      this.permission_search = '';
      this.InitilizePermission_Form();
      this.getListOfModules();
    }
  }

  getListOfModules(search?: string) {
    this.settingService.getListOfModules(this.selected_role_id || '',search).subscribe((resp: any) => {
      this.rolesPermissionModulesData = resp.data;
      this.formPermissions().clear();
      this.rolesPermissionModulesData.forEach((element, i) => {
        this.formPermissions().insert(i, this.formElementsEdit(element));
      });
    });
  }

  addnewRoleSubmit() {
    this.enableAllFormControls();
    this.submited = true;
    const body = {
      role_id: this.selected_role_id,
      rbac_list: this.permissionForm.value.permissions
    };
    this.settingService.updatethePermissionForRole(body).subscribe(() => {
      this.addRole(true);
    });

  }

  getRoles() {
    this.settingService.getRolesPermissions(false).subscribe((resp: any) => {
      this.rolesList = resp.data;
    });
  }

  openModal(modal: any, data?: any) {
    this.modalService.open(modal, { size: 'md' });
    this.InitilizeRoles_Form(data);
  }

  createRole() {
    this.submited = true;
    this.addRoleForm.markAllAsTouched();
    if (this.addRoleForm.valid) {
      if (this.addRoleForm.value.id) {
        this.settingService.updateRolesPermissions(this.addRoleForm.value).subscribe(() => {
          this.getRoles();
          this.modalService.dismissAll();
        });
      } else {
        this.settingService.postRolesPermissions(this.addRoleForm.value).subscribe(() => {
          this.getRoles();
          this.modalService.dismissAll();
        });
      }
    }
  }

  onCheckboxChecked(event: any, index: any) {
    console.log(event);
    if (event.checked) {
      this.formPermissions().at(index).patchValue({
        can_read: true,
        can_create: true,
        can_update: true,
        can_delete: true
      });
      this.formPermissions().controls[index].get('can_read')?.disable();
      this.formPermissions().controls[index].get('can_create')?.disable();
      this.formPermissions().controls[index].get('can_update')?.disable();
      this.formPermissions().controls[index].get('can_delete')?.disable();
    } else {
      this.formPermissions().at(index).patchValue({
        can_read: false,
        can_create: false,
        can_update: false,
        can_delete: false
      });
      this.formPermissions().controls[index].get('can_read')?.enable();
      this.formPermissions().controls[index].get('can_create')?.enable();
      this.formPermissions().controls[index].get('can_update')?.enable();
      this.formPermissions().controls[index].get('can_delete')?.enable();
    }
  }
  enableAllFormControls() {
    this.formPermissions().controls.forEach(control => {
      control.get('can_read')?.enable();
      control.get('can_create')?.enable();
      control.get('can_update')?.enable();
      control.get('can_delete')?.enable();
    });
  }
  searchName(e: any) {
    if (e.length >= 3) {
      this.getListOfModules(e);
    } else if (e.length === 0) {
      this.getListOfModules();
    }
  }

  deleteSelectedType(id: any) {
    this.confirmationMsz.customDeleteView({ title: 'Are you sure?', text: 'You want to delete!', button: 'Yes Delete!' })
      .then((data) => {
        if (data.value) {
          this.settingService.deleteRoles(id).subscribe(() => {
            this.getRoles();
          });
        }
      });
  }
}

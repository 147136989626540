const $HEADING_TITLE = "The most efficient tool to manage your business";
const $HEADING_DSP = "Brexa is one suite for SMEs to enable them in building operational efficiency and accelerate sales. We love building your business! Try Brexa that works for your business";

export const LOGINJSON = [
  {
    info_heading: $HEADING_TITLE,
    info_dsp: $HEADING_DSP,
    heading: "Sign in to your account",
    input_emailid: "Email Id",
    emailid_placeholder: "Enter Email ID",
    input_psw: "Password",
    psw_placeholder: "Enter your password",
    input_forgotpsw: "Forgot Password ?",
    check_box: "Keep me logged in",
    btn: 'Sign In',
    text: "New to Brexa?",
    btn2: "Create new account"
  }
];

export const REGISTERJSON = [
  {
    info_heading: $HEADING_TITLE,
    info_dsp: $HEADING_DSP,
    heading: "Create an account",
    input_firstname: "First Name",
    input_lastname: "Last Name",
    input_emailId: "Email ID",
    input_phonenumber: "Phone Number",
    input_psw: "Password",
    input_confirmpsw: "Confirm Password",
    input_bussiness: "Business Name",
    input_bussiness_type: "Business Type",
    input_industry_type: "Industry Type",
    confirmpsw_placeholder: "Enter your password",
    check_box: "By creating an account you specify that you have read and agree with our Terms of use and Privacy policy. We may keep you inform about latest updates through our default notification settings",
    btn: 'Create Account',
    text: "Your password must be at least 5 characters long and should include a combination of uppercase letters, lowercase letters, numbers, and special characters.",
    bussinessValidationText: "Business Name can be a combination of alphabets, numbers and special characters. Only special characters and numbers are not allowed.",
    text2: "Already a member",
    btn2: "Sign In",
    ccode_placeholder: "Country Code",
    customer_primary_login: 'Customer Primary Login'
  }
];

export const VERIFICATIONJSON = [
  {
    info_heading: $HEADING_TITLE,
    info_dsp: $HEADING_DSP,
    heading: "We Need to verify your email",
    input_code: "Verification code",
    code_placeholder: "6 characters",
    btn: 'Verify Email',
    text: "Copy the verification code",//or follow the
    link: "Email Link",
    text2: "to verify your email",
    btn2: "Resend",
    error_message: "accepts only 6 digits"
  }
];

export const FORGOTPWDJSON = [
  {
    info_heading: $HEADING_TITLE,
    info_dsp: $HEADING_DSP,
    heading: "Reset your password",
    input_email: "Email ID",
    email_placeholder: "Enter Email ID",
    btn: 'Next',
    text: "No worries we will send you a recovery link to reset your password",
    text2: "Did not receive code?",
    btn3: "Send again"
  }
];

export const RESETPSWJSON = [
  {
    input_code: 'Verification Code',
    code_placeholder: "+6 characters",
    input_new_psw: 'New Password',
    input_confirm_psw: 'Confirm Password',
    submit_btn: 'Change Password'
  }
];

export const B_TYPES_B2B = ["Marketing Agencies", "Software Development Companies", "Staffing Companies", "Insurance", "Corporate Legal", "HR & Recruitment", "Commercial Real Estate", "Internet Services", "Start Ups", "Saas", "Accounting/Fintech", "HVAC", "Photography", "Facility Management", "Office & Business Services", "Repair & Technical Support", "Other"];
export const B_TYPES_B2C = ["Health, Wellness/Fitness/Saloon & Spa", "Legal", "Real Estate", "Education", "Travel", "Events", "Hospitality", "Automotive", "Internet Service", "Start ups", "Accounting", "Laundry", "Professional Services", "HVAC", "Plumbing/Lawn", "Doorstep Services", "Gardening & Landscaping", "Property Management", "Interior Designers", "Recreation", "Specialists", "Sports & Fitness", "Food Services", "Handyman Services", "Fashion & Beauty", "Print Services", "Wedding Services", "Pets", "Photography", "Repair & Technical Support", "Home/Building/Construction", "Other"];
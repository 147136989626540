import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PromptNotificationsModalComponent } from '../prompt-notifications-modal/prompt-notifications-modal.component';
import { Subject, Subscription, take, takeUntil, timer } from 'rxjs';
import { ChatService } from 'src/app/core/service/chat.services';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/service/common.service';
@Component({
  selector: 'app-alert-notification',
  templateUrl: './alert-notification.component.html',
  styleUrls: ['./alert-notification.component.scss'],
  animations: [
    trigger('slideOutAnimationNotifications', [
      state('visible', style({ transform: 'translateX(0)', opacity: 1 })),
      transition('* => void', [
        animate(
          '500ms ease-out',
          style({ transform: 'translateX(100%)', opacity: 0 })
        )
      ])
    ])
  ]
})
export class AlertNotificationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('audioElement') audioElement!: ElementRef;
  pushNotificationLists: any[] = [];
  pushAudioVideoNotifications: any[] = [];
  showCancel: boolean[] = [];
  showClearAll = false;
  hideSmallNotifications = false;
  showNotificationToolbar = 'void';
  modalRef: any;
  counter = 0;
  notificationsAudioVideo: any[] = [];
  time_keeper: any;
  showCallCancel = false;
  button: any;
  private timerSubscriptions: Subscription[] = [];
  private clearMessageSubscription: Subscription;
  private unSubscribeSubscriptionAllSubs$ = new Subject();

  constructor(private router: Router, private chatService: ChatService, private modalService: NgbModal, private renderer: Renderer2, private el: ElementRef, private commonService: CommonService) {
    this.clearMessageSubscription = this.chatService.clearAllMessagesSub$.subscribe((data: any) => {
      if (data?.id && data?.showtype) {
        let filteredLists = [];
        switch (data.showtype) {
          case 'lead':
            filteredLists = this.filterNotificationsBasedOnType('enquiries', data?.id);
            break;
          case 'project':
            filteredLists = this.filterNotificationsBasedOnType('clientProjects', data?.id);
            break;
          case 'deal':
            filteredLists = this.filterNotificationsBasedOnType('pipelineCards', data?.id);
            break;
          default:
            break;
        }
        filteredLists && (this.pushNotificationLists = filteredLists);
      } else {
        this.pushNotificationLists = [];
      }
    });
  }

  filterNotificationsBasedOnType(type: string, id: string) {
    return this.pushNotificationLists.filter((elem: any) => !elem[type]?.includes(id));
  }

  ngOnInit() {
    this.chatService.getMessageSubscription$.pipe(takeUntil(this.unSubscribeSubscriptionAllSubs$)).subscribe((message: any) => {
      const currentIndex = this.pushNotificationLists.length;
      this.newNotificationCardView(message, 'text_message');
      this.timerFunctionToUpdateTime(message, currentIndex);
    });
    this.chatService.getTransferChatSubscription$.pipe(takeUntil(this.unSubscribeSubscriptionAllSubs$)).subscribe((message: any) => {
      const currentIndex = this.pushNotificationLists.length;
      this.newNotificationCardView(message, 'transfer_chat');
      this.timerFunctionToUpdateTime(message, currentIndex);

    });

    this.chatService.getExitChatSubscription$.pipe(takeUntil(this.unSubscribeSubscriptionAllSubs$)).subscribe((message: any) => {
      const currentIndex = this.pushNotificationLists.length;
      this.newNotificationCardView(message, 'exit_chat');
      this.timerFunctionToUpdateTime(message, currentIndex);

    });

    this.chatService.getLiveChatSubscription$.pipe(takeUntil(this.unSubscribeSubscriptionAllSubs$)).subscribe((message: any) => {
      const currentIndex = this.pushNotificationLists.length;
      this.newNotificationCardView(message, 'live_chat');
      this.timerFunctionToUpdateTime(message, currentIndex);
    });
    this.chatService.getUserRequestSubscription$.pipe(takeUntil(this.unSubscribeSubscriptionAllSubs$)).subscribe((message: any) => {
      const currentIndex = this.pushNotificationLists.length;
      this.newNotificationCardView(message, 'request_chat');
      this.timerFunctionToUpdateTime(message, currentIndex);
    });
    this.chatService.getGroupChatSubscription$.pipe(takeUntil(this.unSubscribeSubscriptionAllSubs$)).subscribe((message: any) => {
      const currentIndex = this.pushNotificationLists.length;
      this.newNotificationCardView(message, 'group_chat');
      this.timerFunctionToUpdateTime(message, currentIndex);
    });
    this.chatService.getAudioVideoChatSubscription$.pipe(takeUntil(this.unSubscribeSubscriptionAllSubs$)).subscribe((message: any) => {
      this.newAudioVideoNotifications(message);
    });
  }

  timerFunctionToUpdateTime(message: any, currentIndex: number) {
    const timerSubscription = timer(10000)
      .pipe(take(1))
      .subscribe(() => {
        this.convertTimeStampInMins(message, currentIndex);
        this.timerSubscriptions = this.timerSubscriptions.filter(subscription => subscription !== timerSubscription);
        timerSubscription.unsubscribe();
      });
    this.timerSubscriptions.push(timerSubscription);
  }

  ngAfterViewInit() {
    this.button = document.createElement('button');
    this.renderer.setStyle(this.button, 'display', 'none');
    document.getElementsByTagName('body')[0].appendChild(this.button);
    this.button.addEventListener('click', () => {
      this.audioElement.nativeElement.play();
    });
  }


  newAudioVideoNotifications(message: any) {
    this.button.click();
    this.audioElement.nativeElement.muted = false;
    this.pushAudioVideoNotifications.push(message);
    const call_div = this.el.nativeElement.querySelector(`.call-notifications`);
    if (this.modalRef) {
      this.renderer.setStyle(call_div, 'display', 'none');
    } else if (this.pushAudioVideoNotifications.length > 1) {
      this.onOpenViewInModal();
      this.renderer.setStyle(call_div, 'display', 'none');
    } else {
      this.renderer.setStyle(call_div, 'display', 'block');
    }
  }

  newNotificationCardView(message: any, type: string) {
    this.showNotificationToolbar = 'void';
    this.hideSmallNotifications = false;
    this.setPriorityOnChatBasic(message, type);
    this.counter = this.pushNotificationLists.length;
    if (this.counter > 3) {
      const previousDiv = this.el.nativeElement.querySelectorAll(`.new-message-notifications`);
      if (this.el.nativeElement.querySelector('.remove-animation')) {
        previousDiv.forEach((element: any, index: number) => {
          this.renderer.removeClass(element, 'remove-animation');
          if (index !== previousDiv.length) {
            this.renderer.addClass(element, 'animate-bottom');
            this.renderer.addClass(element, 'animate-bottom' + index);
          }
        });
      } else {
        this.renderer.addClass(previousDiv[previousDiv.length - 1], `animate-bottom`);
      }
    } else if (this.counter > 2) {
      const previousDiv = this.el.nativeElement.querySelectorAll(`.new-message-notifications`);
      previousDiv.forEach((element: any, index: number) => {
        this.renderer.addClass(element, `animate-bottom${index}`);
      });
    } else if (this.counter > 1) {
      const previousDiv = this.el.nativeElement.querySelector(`.new-message-notifications`);
      this.renderer.addClass(previousDiv, 'animate-bottom');
    }

    this.timeKeeper(8000, 'visible');
  }

  async setPriorityOnChatBasic(message: any, type: string) {
    switch (type) {
      case 'transfer_chat':
        message['customText'] = 'Transfer Chat Initiated';
        break;
      case 'live_chat':
        message['customText'] = 'Live Chat Initiated';
        break;
      default:
    }
    if (message.requestType === "addUser") {
      message['customText'] = 'Group Chat Initiated';
    }
    if (message.requestType === "addedToConversation") {
      message['customText'] = `New conversation initiated for ${message?.name}`;
    }
    message['customText_heading'] = `A New message recieved ${message?.senderUser?.name ? 'from' + message?.senderUser?.name : ' '}`;
    // if (message.chat.type === "teamchat") {
    //   if (type === 'group_chat') {
    //     if (message?.addedByUser) {
    //       message['customText_heading'] = `A New Member Added To The Chat By ${message?.addedByUser?.name}`;
    //     } else if (message.exitedUser) {
    //       message['customText_heading'] = `${message?.exitedUser?.name} Exited From The Group Chat`;
    //     } else {
    //       message['customText_heading'] = `A New Group Chat Created`;
    //     }
    //   } else {
    //     message['customText_heading'] = `New team chat message recieved from ${message?.senderUser?.name}`;
    //   }
    // } else if (message.chat.type === 'live_chat') {
    //   if (message?.requestByUser) {
    //     message['customText_heading'] = `${message?.requestByUser?.name} has been requested to join the live chat`;
    //   } else if (message?.acceptedByUser) {
    //     message['customText_heading'] = `${message?.acceptedByUser?.name} has accepted the live chat request`;
    //   }
    // } else if (message.chat.type === 'livechat') {//transfer chat
    //   if (type === 'request_chat') {
    //     if (message?.requestByUser) {
    //       message['customText_heading'] = `${message?.requestByUser?.name} has been added you to the live chat`;
    //     } else if (message?.acceptedByUser) {
    //       message['customText_heading'] = `${message?.acceptedByUser?.name} has accepted the live chat request`;
    //     }
    //   } else {
    //     if (message?.requestByUser) {
    //       message['customText_heading'] = `${message?.requestByUser?.name} has initiated a transfer chat request to you`;
    //     } else if (message.acceptedByUser) {
    //       message['customText_heading'] = `${message?.acceptedByUser?.name} has accepted the transfer chat request`;
    //     } else if (message.exitedUser) { // need to change this I am not getting the socket event from the chatbot team
    //       message['customText_heading'] = `${message?.exitedUser?.name} has rejected the transfer chat`;
    //     }
    //   }
    // }
    const regex = /{{[^{}]*}}/g;
    const inputString = message?.payload?.text;
    const jsonObjects = inputString && inputString.match(regex);
    if (jsonObjects) {
      jsonObjects.forEach((element: any, index: number) => {
        const searchString = message?.payload?.mentions[index]?.name;
        const text = message?.payload?.text.replace(element, searchString);
        message.payload.text = text;
      });
    }
    this.pushNotificationLists.push(message);
    const notificationDiv = await this.el.nativeElement.querySelector(`.msg-notifications`);
    if (this.modalRef) {
      this.renderer.setStyle(notificationDiv, 'display', 'none');
    } else {
      this.renderer.setStyle(notificationDiv, 'display', 'block');
    }
    this.pushNotificationLists[this.pushNotificationLists.length - 1]['formatedTime'] = 'now';
  }

  convertTimeStampInMins(message: any, index: number) {
    if (this.pushNotificationLists.length > 0 && message?.message?.createdAt) {
      const date = new Date(message?.message?.createdAt);
      const options: any = { hour: 'numeric', minute: 'numeric', hour12: true };
      const formattedTime = date.toLocaleString('en-US', options);
      this.pushNotificationLists[index]['formatedTime'] = formattedTime;
    }
  }

  muteCall() {
    // Toggle the muted state
    this.audioElement.nativeElement.muted = true;
    const snoozeButton = document.querySelector('.snooz-icon');
    snoozeButton && this.renderer.removeClass(snoozeButton, 'snooz-icon');
  }

  removeNotifications(length: number) {
    setTimeout(() => {
      this.showClearAll = false;
      this.showCancel[0] = false;
    }, 100);
    this.timeKeeper(0, 'void');
    this.clearSubscriptions();
    event?.stopPropagation();
  }

  openAllNotificationsModal(length: any) {
    if (length < 2 && !this.hideSmallNotifications) {
      this.getNavigateToChatPage(this.pushNotificationLists[0]);
      this.pushNotificationLists = [];
    }
    else {
      this.timeKeeper(10, 'void');
      this.hideSmallNotifications = true;
      this.modalRef = null;
      this.onOpenViewInModal();
    }
  }

  onOpenViewInModal() {
    this.hideSmallNotifications = true;
    this.showNotificationToolbar = 'void';
    this.modalRef = this.modalService.open(PromptNotificationsModalComponent, { size: 'lg', scrollable: true, windowClass: 'from-bottom-right' });
    const modalData = {
      textNotification: this.pushNotificationLists,
      callNotifications: this.pushAudioVideoNotifications,
      audioElement: this.audioElement,
      commonService: this.commonService
    };
    const call_div = this.el.nativeElement.querySelector(`.call-notifications`);
    if (this.pushAudioVideoNotifications.length > 0) {
      this.renderer.setStyle(call_div, 'display', 'none');
    }
    this.modalRef.componentInstance.allNotificationLists = modalData;
    this.modalRef.result.then((receivedEntry: any) => {
      this.hideSmallNotifications = false;
      if (receivedEntry) {
        switch (receivedEntry.type) {
          case 'answer_call':
            this.onAcceptCall(receivedEntry.index);
            this.pushNotificationLists = [];
            break;
          case 'reject_call':
            this.onRejectCall(receivedEntry.index);
            this.pushNotificationLists = [];
            break;
          case 'chat':
          {
            const notifications = this.pushNotificationLists[receivedEntry.index];
            this.getNavigateToChatPage(notifications);
            if (!notifications.redirectUrl.includes('/conversations/')) {
              this.pushNotificationLists = [];
            }
            break;
          }
          case 'clear':
            this.pushNotificationLists = [];
            break;
          default:
        }
      }
      //for 2 call generated
      if (this.pushAudioVideoNotifications.length > 0 && receivedEntry.type !== 'answer_call' && receivedEntry.type !== 'reject_call') {
        this.renderer.setStyle(call_div, 'display', 'block');
      }
      this.clearSubscriptions();
      this.modalRef = null;
      this.pushAudioVideoNotifications.length > 0 ? this.showNotificationToolbar = 'visible' : !(this.pushNotificationLists.length > 0) ? (this.showNotificationToolbar = 'void') : this.showNotificationToolbar = 'visible';
    }).catch((err: any) => {
      console.log(err);
      this.hideSmallNotifications = false;
      if (this.pushAudioVideoNotifications.length > 0) {
        this.renderer.setStyle(call_div, 'display', 'block');
      }
      this.modalRef = null;
      (this.pushNotificationLists.length > 0 || this.pushAudioVideoNotifications.length > 0) ? this.showNotificationToolbar = 'visible' : this.showNotificationToolbar = 'void';
    });
  }

  timeKeeper(timing: number, showNotificationToolbar: string) {
    if (this.time_keeper) {
      clearTimeout(this.time_keeper);
    }
    this.time_keeper = setTimeout(() => {
      const divisionsSec = this.el.nativeElement.querySelectorAll(`.new-message-notifications`);
      divisionsSec.forEach((element: any, index: number) => {
        if (index !== divisionsSec.length) {
          this.renderer.removeClass(element, 'animate-bottom' + index);
          this.renderer.removeClass(element, 'animate-bottom');
          this.renderer.removeClass(element, 'animations');
        }
        this.renderer.removeClass(element, 'animations');
        this.renderer.addClass(element, 'remove-animation');
      });
      setTimeout(() => {
        const notificationDiv = this.el.nativeElement.querySelector(`.msg-notifications`);
        this.renderer.setStyle(notificationDiv, 'display', 'none');
        this.hideSmallNotifications = true;
        if (timing !== 0) {
          this.showNotificationToolbar = showNotificationToolbar;
        } else {
          this.pushNotificationLists = [];
        }
      }, 400);
    }, timing);
  }

  getNavigateToChatPage(notifications: any) {
    const routerState: any = {
      redirectUrl: notifications?.redirectUrl
    };
    // if (notifications.redirectUrl.includes('/teamchats/') && this.router.routerState.snapshot.url !== '/teamchat') {
    //   this.router.navigate(['/teamchat'], { state: { routerState: routerState } });
    // } else if (notifications.redirectUrl.includes('/livechats/') && this.router.routerState.snapshot.url !== '/live_chat') {
    //   this.router.navigate(['/live_chat'], { state: { routerState: routerState } });
    // } else if (notifications.redirectUrl.includes('/conversations/') && this.router.routerState.snapshot.url !== '/conversation') {
    // pipelineCards, enquiries, clientProjects;
    //   if (notifications.enquiries && notifications.enquiries.length > 0) {
    //     const queryParams = {
    //       id: notifications.enquiries[0],
    //       showtype: 'lead',
    //       activeId: 9
    //     };
    //     this.router.navigate(['/crm/crm-details'], { queryParams: queryParams });
    //   } else if (notifications.pipelineCards && notifications.pipelineCards.length > 0) {
    //     const queryParams = {
    //       id: notifications.pipelineCards[0],
    //       showtype: 'deal',
    //       activeId: 9
    //     };
    //     this.router.navigate(['/crm/crm-details'], { queryParams: queryParams });

    //   } else if (notifications.clientProjects && notifications.clientProjects.length > 0) {
    //     const queryParams = {
    //       id: notifications.clientProjects[0],
    //       showtype: 'project',
    //       activeId: 6
    //     };
    //     this.router.navigate(['/clients/client-managment'], { queryParams: queryParams });
    //   } else {
    //     this.router.navigate(['/conversation'], { state: { routerState: routerState } });
    //   }
    // } else {
    this.router.navigate(['/unibox'], { state: { routerState: routerState } });
    // this.chatService.chatRedirectUrlSubs$.next(routerState);
    // }

  }

  onAcceptCall(index: any) {
    this.getNavigateToChatPage(this.pushAudioVideoNotifications[index]);
    this.onRemmovingAudioVideo(index);
    this.audioElement.nativeElement.pause();
  }

  onRejectCall(index: any) {
    const callId = this.pushAudioVideoNotifications[index].chatId;
    this.pushNotificationLists.length > 0 && this.showNotificationToolbar === 'visible';
    // this.commonService.onRejectCallapis(callId).subscribe(() => {
    //   this.onRemmovingAudioVideo(index);
    //   this.audioElement.nativeElement.pause();
    // });
  }

  onRemmovingAudioVideo(index: number) {
    const divisionsSec = this.el.nativeElement.querySelectorAll(`.new-call-notifications`);
    this.renderer.removeClass(divisionsSec[index], 'animations');
    this.renderer.addClass(divisionsSec[index], 'remove-animation');
    setTimeout(() => {
      this.pushAudioVideoNotifications.splice(index, 1);
      const call_div = this.el.nativeElement.querySelector(`.call-notifications`);
      call_div && this.renderer.setStyle(call_div, 'display', 'block');
    }, 400);
  }

  clearSubscriptions() {
    this.timerSubscriptions.forEach(subscription => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    this.clearSubscriptions();
    this.clearMessageSubscription.unsubscribe();
    this.unSubscribeSubscriptionAllSubs$.next('');
    this.unSubscribeSubscriptionAllSubs$.complete();
  }

}

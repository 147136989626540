<div class="container-fluid">
    <ng-container *ngIf="showType === 'marketing-channels'">
        <div class="row" style="min-height: 83vh">
            <div class="col-md-12 pe-2 col-sm-12">
                <div class=" mb-0" style="min-height: 83vh">
                    <div class="banner-card bg-primary p-5 d-flex align-items-center justify-content-between mb-4 m-3">
                        <div class="">
                            <h3 class="text-white font-size-16">Add the contacts to marketing list from your contact
                                screen</h3>
                            <p class="font-size-13">Reach out to customers and promote your products and offers to
                                them.
                                Launch SMS & Whatsapp
                                marketing
                                campaigns and target your customers using customizable templates to grow your
                                business</p>
                            <button class="btn bg-white text-primary d-flex align-items-center font-size-13"><mat-icon
                                    class="me-2">campaign</mat-icon>How it works</button>
                        </div>
                        <div class="banner_image">
                            <img src="assets/images/campaign.svg">
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mx-4 mb-3">
                        <h5 class="fw-700 text-primary font-size-16">Please choose a Marketing list</h5>
                        <button class="btn btn-light text-primary d-flex align-items-center mb-3"
                            style="border: 1px solid;"><mat-icon class="me-2">equalizer</mat-icon>Overview
                            Analytics</button>
                    </div>
                    <div class="row mx-3">
                        <div class="col-3 mb-5" *ngFor="let item of channels_list">
                            <div class="channel-card py-3 cursor" (click)="viewList()">
                                <img [src]="item.icon" class="mb-3 mt-4">
                                <div class="d-flex align-items-center justify-content-center">
                                    <h5 class="fw-700 text-primary font-size-16 mt-2">{{item.name}}</h5>
                                    <p class="mb-0 border-radius-50 ms-2 fw-600 d-flex align-items-center justify-content-center" style="height:36px; width:36px;background: rgba(39, 174, 96, 0.5);">{{item.count}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="showType === 'marketing-list'">
            <div class="row mt-3 mb-4">
              <div class="col-md-4 d-flex align-items-center">
                <mat-icon class="text-primary">arrow_back_ios </mat-icon>
                <mat-form-field appearance="outline" class="contactsfilter ms-3 mat-hint-none">
                  <mat-select placeholder="Zepul" class="">
                    <mat-option [value]="'GB'">Zepul</mat-option>
                    <mat-option [value]="'US'">Worke</mat-option>
                    <mat-option [value]="'CA'">Easyrooms</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-8 d-flex justify-content-end align-items-center">
                <mat-form-field appearance="outline" class="me-3 mat-hint-none">
                  <input style="outline: none" matInput placeholder={{contactsJson.search_contact_placeholder}} />
                  <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg" alt=""></mat-icon>
                </mat-form-field>
          
                <!-- <button class="btn btn-primary d-flex align-items-center me-3" (click)="openContactsModalDialog()">
                  <mat-icon>add</mat-icon>
                  {{contactsJson.label_add_contact}}
                </button>
                <mat-icon class="me-3 text-primary"
                  (click)="importEexportModalDialog(importExportModel, 'export')">exit_to_app</mat-icon>
                <mat-icon class="me-3 text-primary"
                  (click)="importEexportModalDialog(importExportModel, 'import')">move_to_inbox</mat-icon>
               
                <div class="d-flex contacts-filter-lable">
                  <span class="me-3 text-primary" (click)="checkedContacts()">{{contactsJson.Filter_label}}</span>
                  <span mat-raised-button class="menuTrigger text-primary material-icons cursor"
                    [matMenuTriggerFor]="filter_search_menu">filter_list</span>
            
                  <mat-menu #filter_search_menu="matMenu">
                    <mat-form-field appearance="outline" class="m-3 mb-1">
                      <input style="outline: none" matInput placeholder={{contactsJson.search_placeholder}} />
                    </mat-form-field>
            
                    <div class="" *ngFor="let item of filtersLables; let i = index">
                      <button mat-menu-item (click)="selectFilterLable($event)" id="{{ i }}">
                        <mat-icon class="empty">check_box_outline_blank</mat-icon>
                        {{ item.lableName }}
                      </button>
                    </div>
                  </mat-menu>
                </div> -->
              </div>
            </div>
          
            <div class="table-responsive table-height mt-4">
              <table class="table align-middle table-nowrap table-hover mb-0 w-100">
                <thead>
                  <tr>
                    <th scope="col">
                      <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                    </th>
                    <th scope="col" class="text-primary pb-3">{{contactsJson.col_two_label}}</th>
                    <th scope="col" class="text-primary pb-3">{{contactsJson.col_three_label}}</th>
                    <th scope="col" class="text-primary pb-3">{{contactsJson.col_four_label}}</th>
                    <th scope="col" class="text-primary pb-3">{{contactsJson.col_five_label}}</th>
                    <th scope="col" class="text-primary pb-3">{{contactsJson.col_six_label}}</th>
                    <th scope="col" class="text-primary pb-3">{{contactsJson.col_seven_label}}</th>
                    <th scope="col" class="text-primary pb-3">{{contactsJson.col_eight_label}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="cursor" *ngFor="let item of contactListArray; let i = index" [routerLink]="['../crm-details']"
                    [queryParams]="{ id: item.id, showtype: 'contact'}">
                    <td>
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(item) : null"
                        [checked]="selection.isSelected(item)"></mat-checkbox>
                    </td>
                    <td>
                      {{ item.company_name }}
                    </td>
                    <td>
                      {{ item.contact_name }}
                    </td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.contact_owner }}</td>
                    <td>{{ item.last_updated }}</td>
                    <td (click)="$event.stopPropagation()">
                      <div class="dropdown" ngbDropdown>
                        <mat-icon>more_vert</mat-icon>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <mat-paginator [length]="contactListArray.length" [pageSize]="20" aria-label="Select page" showFirstLastButtons>
                </mat-paginator>
              </div>
            </div>
          
          <ng-template #importExportModel let-modal>
            <div class="modal-header">
              <h5 class="modal-title text-primary" *ngIf="importModalType === 'import'">
                {{contactsJson.label_import}}
              </h5>
              <h5 class="modal-title text-primary" *ngIf="importModalType === 'export'">
                {{contactsJson.label_export}}
              </h5>
              <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
            </div>
            <div class="modal-body">
              <div class="contact-dotted-border text-center mb-3">
                <input type="file" class="form-control" />
              </div>
          
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-primary" *ngIf="importModalType === 'import'">
                  {{contactsJson.import_btn}}
                </button>
                <button type="button" class="btn btn-primary" *ngIf="importModalType === 'export'">
                  {{contactsJson.export_btn}}
                </button>
              </div>
            </div>
          </ng-template>
    </ng-container>
</div>
<div class="container-fluid">
    <div class="row p-2">

        <div class="col-lg-12">
            <div class="heading font-size-18 fw-700 text-primary mb-3">Settings</div>
            <div class="row">
                <div class="col-md-3">
                    <ul ngbNav #verticalNav="ngbNav" [(activeId)]="activeId"  class="nav-pills flex-column">
                        <ng-container *ngFor="let item of settings_menu; index as i;">
                            <li [ngbNavItem]="item.id" class="item-style" [routerLinkActive]="['active']" *ngIf="featuresData && item.viewFeature">
                                <a *ngIf="!(item.slug === 'communications' || item.slug === 'sms_notifications' || item.slug === 'email_notifications'|| item.slug === 'whatsapp')"
                                    [routerLink]="[item.route]" ngbNavLink>
                                    <span class="d-block font-size-13">{{item.title}}</span>
                                </a>
                                <div class="accordion py-1" id="accordionExample" *ngIf="item.slug === 'communications'">
                                    <div class="accordion-item border-0">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button collapsed font-size-13 py-1 ps-1"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                aria-expanded="false" aria-controls="collapseOne">
                                                {{item.title}}
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body py-1">
                                                <ng-container *ngFor="let item of settings_menu">
                                                    <!-- <ul style="list-style: none;"> -->
                                                        <li [ngbNavItem]="item.id" class="item-style" *ngIf="item.slug === 'sms_notifications' || item.slug === 'email_notifications'|| item.slug === 'whatsapp'" 
                                                            [routerLinkActive]="['active']">
                                                            <a class="cursor" [routerLink]="[item.route]"
                                                                ngbNavLink>
                                                                <span
                                                                    class="font-size-13">{{item.title}}</span>
                                                            </a>
                                                        </li>
                                                    <!-- </ul> -->
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-template ngbNavContent>
                                    <router-outlet></router-outlet>
                                </ng-template>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <div class="col-md-9 border-start">
                    <div [ngbNavOutlet]="verticalNav"></div>
                </div>
            </div>
        </div>
    </div>
</div>
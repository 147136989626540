import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject } from 'rxjs';
import { maximumNumberAllowed, onOnlyNumbers, onParseDomValue, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { countryData } from 'src/app/core/data/countryData';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-table-popover',
  templateUrl: './table-popover.component.html',
  styleUrls: ['./table-popover.component.scss']
})
export class TablePopoverComponent {

  @Input() labelName: any;
  @Input() inputType: any;
  @Input() inputValue: any;
  @Input() formName: any;
  @Input() dropDownItems: any;
  @Input() selectedItem: any;
  @Input() cCode: any;
  @Input() editEnable: any;
  @Input() fieldType: any;
  @Input() is_active = true;
  @Output() update: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatMenuTrigger) popoverMenuTrigger!: MatMenuTrigger;
  @ViewChild(MatMenu) popoverMenu!: MatMenu;
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  inputData: any = {};
  minDate = new Date();
  value: any;
  selectedItemData: any;
  modal: any;
  initialItem: any;
  timezoneLists: any;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  saveButton: any;
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };
  countryjson = countryData;
  presignedurl = environment.preSendUrl;
  start: any;
  end: any;
  multiSelectCustomField: any[] = [];

  constructor(private awsUtilService: AwsUtilsService, private toaster: ToastrService) { }

  ngOnInit() {
    if (this.editEnable === undefined || this.editEnable === null) {
      this.editEnable = true;
    }
    this.filteredBanks.next(this.countryjson.slice());
    if (this.inputValue === 'NA') this.inputValue = '';
    this.inputData = {
      "labelName": this.labelName,
      "type": this.inputType,
      "value": this.inputValue,
      "formName": this.formName,
      "dropDownItems": this.dropDownItems,
      "selectedItem": this.selectedItem
    };
    // console.log(this.inputValue, this.selectedItem, this.labelName, this.dropDownItems); 
    if (this.inputType === 'textarea' || this.inputType === 'TEXT_AREA') {
      const dataWithoutTags = onParseDomValue(this.inputValue);
      this.ckeditorLimit['actualLimit'] = dataWithoutTags?.length || 0;
    } else if (this.inputType === 'DATE_RANGE') {
      this.start = this.inputValue.split('&')[0];
      this.end = this.inputValue.split('&')[1];
    }
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'underline', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
    if (this.formName === 'time_zone' && this.labelName === 'time_zone') {
      this.timezoneLists = this.dropDownItems;
    }
    if (this.inputType === 'PHONE_NUMBER') {
      this.modal = this.inputValue.custom_phone_number;
      this.cCode = this.inputValue.custom_ccode;
    } else {
      this.modal = JSON.parse(JSON.stringify(this.inputValue || ""));
    }
    this.inputValue = this.inputValue && this.inputValue !== "null" ? this.inputValue : '';
    this.initialItem = JSON.parse(JSON.stringify(this.selectedItem || ''));
    // this.selectedItem = this.selectedItem || ""
    // console.log(this.inputData);
  }

  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditorLimit = Object.assign(this.ckeditorLimit, { ...contentLength });
    });
  }

  onSearch(event: any) {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = event.target.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();

    const dataWithoutTags = this.onParseDomValue(inputData);
    this.ckeditorLimit = Object.assign(this.ckeditorLimit, { actualLimit: dataWithoutTags.length || 0 });
  }

  onParseDomValue(inputData: string) {
    const parser = new DOMParser();
    const parsedData = parser.parseFromString(inputData, 'text/html');
    const dataWithoutTags = parsedData.body.innerText;
    return dataWithoutTags;
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      // JSON object has changed, perform desired actions
      // console.log('JSON object has changed:', changes);
      this.modal = JSON.parse(JSON.stringify(changes['inputValue'].currentValue || ""));
      // this.initialItem = JSON.parse(JSON.stringify(changes['selectedItem'].currentValue || ''));
    }
  }

  keyDownEvent(event: any, maxLength?: number) {
    if (maxLength) {
      const maxNum = maximumNumberAllowed(event, maxLength);
      return maxNum;
    } else {
      const onlyNum = onOnlyNumbers(event);
      return onlyNum;
    }
  }
  keyDownTextEvent(event: any, maxLength: number) {
    if (this.formName === 'phonenumber' || this.formName === 'phone_number') {
      const maxNum = maximumNumberAllowed(event, maxLength);
      const onlyNum = onOnlyNumbers(event);
      return (maxNum && onlyNum);
    } else {
      return true;
    }
  }

  onValidateWebsite(event: any, value?: any) {
    if (this.formName === 'website') {
      if (value) {
        this.toogleSaveButton(value);
      } else {
        this.toogleSaveButton(event);
      }
    }
  }
  toogleSaveButton(value: any) {
    this.saveButton = document.getElementById('button-disabled');
    const websitePattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9.-]*)*$/;
    const result = websitePattern.test(value);
    result ? this.saveButton.disabled = false : this.saveButton.disabled = true;
  }
  // const 

  onChange(data: any, type?: string) {
    this.selectedItem = data?.id;
    this.selectedItemData = data;
    if (this.labelName === 'Owner')
      this.modal = `${data.fname} ${data.lname}`;
    else if (this.labelName === 'Company Name' || this.labelName === 'priority' || this.labelName === "scheduler" || this.labelName === 'scheduler_category' || this.labelName === 'product_category' || this.labelName === 'product')
      this.modal = data.name;
    else if (this.labelName === 'Country' || this.labelName === 'country') {
      this.selectedItem = data.Entity;
      this.modal = data.Entity;
    } else if (this.labelName === 'Currency' || this.labelName === 'currency') {
      this.selectedItem = data.abbreviation + ", " + data.symbol;
      this.modal = data.abbreviation + ", " + data.symbol;
    } else if (this.labelName === 'Tag') {
      this.modal = data.name;
    } else if (this.labelName === 'time_zone') {
      this.selectedItem = data.timezone;
      this.modal = data.timezone;
    } else if (this.fieldType === 'customField') {
      this.selectedItem = data;
      this.modal = data;
      if (type) (this.selectedItem = this.multiSelectCustomField);
      if (type) (this.modal = this.multiSelectCustomField);
    }
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");

  }

  onSearching(event: any) {
    if (event.length >= 3) {
      const searchKey = event.toLowerCase();
      this.timezoneLists = this.dropDownItems.map((element: any) => {
        const filteredCountries = element.countries.filter((bank: any) => bank.name.toLowerCase().indexOf(event.toLowerCase()) > -1);
        return { ...element, countries: filteredCountries };
      }).filter((element: any) => element.countries.length > 0);
      // this.timezoneLists = this.dropDownItems.filter((data: any) => (data.text.toLowerCase().indexOf(searchKey) > -1));
    } else {
      this.timezoneLists = this.dropDownItems;
    }
  }

  onSelectCCode(event: any) {
    this.cCode = event.value;
  }

  onSave() {
    // Perform save logic
    const data: any = {
      labelName: this.labelName,
      type: this.inputType,
      value: this.modal,
      formName: this.formName,
      cCode: this.cCode
    };

    if (this.inputType === "dropdown") {
      data.value = this.selectedItemData;
    } else if(this.inputType === 'TAB') {
      data.value = this.selectedItemData.label;
    }
    if (this.inputType === "DATE_RANGE") {
      if (this.end) {
        data.value = this.convert(this.start) + '&' + this.convert(this.end);
      } else if (this.start) {
        data.value = this.convert(this.start);
      }
    } else if (this.inputType === "PHONE_NUMBER") {
      data.value = { custom_ccode: this.cCode, custom_phone_number: this.modal };
    }
    if (/^(date)$/.test(this.inputType)) {
      data.value = this.convert(this.modal);
    }

    if (this.inputType === "multiselect" || this.inputType === "MULTI_USER" || this.inputType === "SINGLE_USER") {
      data.value = this.selectedItem;
    }
    // let inValidText = false;
    // if (this.inputType === "text") {
    //   inValidText = /^[A-Za-z\s]+$/.test(data.value)
    // }

    // if (!inValidText) {

    // } else {
    //   alert(inValidText)
    // }

    this.update.emit(data);
    if (this.inputType !== "PHONE_NUMBER") this.modal = this.inputValue;
    this.selectedItem = this.initialItem;
    this.closeMenu();
  }

  onCancel() {
    // Perform cancel logic
    if (this.inputType === "PHONE_NUMBER") {
      this.modal = this.inputValue.custom_phone_number;
    } else {
      this.modal = this.inputValue;
    }
    this.selectedItem = this.initialItem;
    this.closeMenu();
  }

  closeMenu() {
    // Close the menu
    this.popoverMenuTrigger.closeMenu();
  }

  onFileUpload(ev: any) {
    if (!ev.target.files[0]) return;
    if (ev.target.files[0].size > 15 * 1048576) {
      this.toaster.error("File size is too large, please upload a file size less than 15MB");
      return;
    }

    this.awsUtilService.getUploadFileLink(ev.target.files[0].type, ev.target.files[0].name.split('.')[1]).subscribe({
      next: (resp) => {
        this.modal = { path: this.presignedurl + resp.data.file_name, file_name: ev.target.files[0].name };
        this.awsUtilService.uploadFile(ev.target.files[0], resp.data.url).subscribe({
          next: () => {//@typescript-eslint/no-empty-function
          }
        });
      }
    });
  }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ADDLEADJSON } from 'src/app/core/data/leads_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ReplaySubject, Subject, debounceTime, finalize, takeUntil } from 'rxjs';
import { countryData } from 'src/app/core/data/countryData';
import { AuthService } from 'src/app/core/service/auth.service';
import { CustomFieldsService } from 'src/app/core/service/custom-fields.service';
import { ErrorLoadImage, maximumNumberAllowed, onCheckRequiredFieldStatus, onOnlyNumbers, onParseDomValue, onlyNumberDecimal, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { currencyJson } from 'src/app/core/data/currencyData';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CHANNELS_LIST } from 'src/app/core/data/channels_json';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-lead-modal',
  templateUrl: './lead-modal.component.html',
  styleUrls: ['./lead-modal.component.scss']
})
export class LeadModalComponent implements OnInit, OnDestroy, AfterViewInit {
  public bankFilterCtrl: FormControl = new FormControl();
  public productFilter: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  public filteredProducts: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroyProducts = new Subject<void>();
  @ViewChildren('customEditorComponents') customEditorComponents!: QueryList<CKEditorComponent>;
  countryjson = countryData;
  title = 'FormArray Example in Angular Reactive forms';
  submitted = false;
  leadForm!: FormGroup;
  leadJson = ADDLEADJSON[0];
  errorJson = ERRORJSON[0];
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  channels = CHANNELS_LIST;
  @Input() lead: any;
  revokedSysFieldsList: { [key: string]: { is_visible: any } } = {};
  showErrorMessage = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  contactListArray: any = [];
  companiesListArray: any = [];
  staffMembers: any = [];
  customFieldsArray: any = [];
  systemFieldsArray: any = [];
  info: any = {};
  products_list: any = [];
  loading = false;
  clear_all: any;
  minDate = new Date();
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  loggedInUser: any;
  tagsListArray: any;
  @Input() contact: any; //From Chat 
  @Input() chatId!: string;
  currencyjson = currencyJson;
  public bankFilterCurrencyCtrl: FormControl = new FormControl();
  public filteredCurrencyBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onCurrencyDestroy = new Subject<void>();
  productCategory: any;
  aptCategoriesArray: any;
  appointmentsArray: any;
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };
  editorComponentLists: any[] = [];
  ckeditorLimitLists: any = {};
  searchSubject: Subject<any> = new Subject<any>();
  searchCompanySubject: Subject<any> = new Subject<any>();
  presignedurl = environment.preSendUrl;
  contactActive = false;
  featuresData: any;
  serviceCategory: any;
  services_list: any;

  constructor(
    public ngbActiveModal: NgbActiveModal,
    private service: CrmService,
    private settingsService: SettingsService,
    private product_service: SetUpService,
    private authService: AuthService,
    private _customFields: CustomFieldsService,
    private awsUtilService: AwsUtilsService,
    private toaster: ToastrService
  ) {
    this.searchSubject.pipe(
      debounceTime(300)
    ).subscribe((searchTerm: any) => {
      if (searchTerm.text.length >= 3) {
        this.service.getContactsBySearchDropDown(searchTerm.type, searchTerm.text).subscribe((result: any) => {
          this.contactListArray = result.data;
        });
      } else if (searchTerm.text.length === 0) {
        // this.getContactsList();
      }
    });
    this.searchCompanySubject.pipe(
      debounceTime(300)
    ).subscribe((searchTerm: any) => {
      if (searchTerm.term.length >= 3) {
        this.service.getCompaniesBySearch(searchTerm.term).subscribe({
          next: (resp: any) => {
            this.companiesListArray = resp.data;
          }
        });
      } else if (searchTerm.term.length === 0) {
        this.getCompaniesList();
      }
    });
  }

  ngOnInit() {
    this.getFeatues();
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.filteredCurrencyBanks.next(this.currencyjson.slice());
    this.bankFilterCurrencyCtrl.valueChanges
      .pipe(takeUntil(this._onCurrencyDestroy))
      .subscribe(() => {
        this.filterCurrencyBanks();
      });

    this.filteredProducts.next(this.products_list.slice());
    this.productFilter.valueChanges.pipe(takeUntil(this._onDestroyProducts)).subscribe(() => {
      this.filterProducts();
    });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.intilizeForm();
    // this.getContactsList();
    // this.getCompaniesList();
    this.organisationMembersList();
    // this.getProducts();
    this.getTags();
    // console.log("lead - ", this.lead);

    if (this.lead?.id) {
      const dataWithoutTags = onParseDomValue(this.lead?.description);
      this.ckeditorLimit['actualLimit'] = dataWithoutTags?.length || 0;
      this.leadForm.controls["phone_number"].disable();
      this.leadForm.controls["ccode"].disable();
      this.leadForm.controls["email"].disable();
      this.leadForm.controls["name"].disable();
      this.leadForm.controls["channel"].disable();
      if ((this.lead.owner !== this.loggedInUser.data.id && this.loggedInUser.data.role !== 'ADMIN')) {
        this.leadForm.controls["assignee"].disable();
        this.leadForm.controls["owner"].disable();
      }
    } else {
      this.info['owner_info'] = {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      };
    }
    // this.getExtraFieldsData();
    // this.leadForm.get('product')?.valueChanges.subscribe((product: any) => {
    //   console.log(product, "product log's");
    //   this.products_list = this.products_list.filter((item: any) => {
    //     item.name === product
    //   })

    // })
    this.getCustomFieldsData();
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.myFirstInput.nativeElement.focus();
    }, 0);
    this.editorComponentLists = [];
    this.customEditorComponents.changes.subscribe((components: QueryList<CKEditorComponent>) => {
      components.forEach((editorComponent: any) => {
        this.editorComponentLists.push(editorComponent);
      });
    });
  }

  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditorLimit = Object.assign(this.ckeditorLimit, { ...contentLength });
    });
  }

  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();
    const dataWithoutTags = onParseDomValue(inputData);
    this.ckeditorLimit = Object.assign(this.ckeditorLimit, { actualLimit: dataWithoutTags.length || 0 });
  }

  custEditorkeyEvent(editor: any, editorComponents: any, index: any) {
    const edittedItem = this.editorComponentLists.find((elem: any) => elem.id === editorComponents.id);
    if (edittedItem) {
      const inputData = edittedItem.editorInstance.getData();
      const dataWithoutTags = onParseDomValue(inputData);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { actualLimit: dataWithoutTags.length || 0 });
    }
  }

  onCustReady(editor: any, index: any) {
    const object = {
      [index]: {
        maxLimit: 1000,
        actualLimit: 0,
        type: false
      }
    };
    Object.assign(this.ckeditorLimitLists, object);
    const dataWithoutTags = onParseDomValue(editor.data.get());
    this.ckeditorLimitLists[index]['actualLimit'] = dataWithoutTags?.length || 0;
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const edittedItem = this.editorComponentLists.find((elem: any) => elem.editorInstance.id === editor.id);
      const contentLength = pasteEventInCkeditor(edittedItem, pasteContent);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { ...contentLength });
    });
  }

  ngOnDestroy() {
    this._onCurrencyDestroy.next();
    this._onCurrencyDestroy.complete();
  }

  keyDownEvent(event: any, maxLength: number) {
    const maxNum = maximumNumberAllowed(event, maxLength);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }

  keyDownCustomField(event: any, decimalPoint: number, type: string) {
    return onlyNumberDecimal(event, decimalPoint, type);
  }

  getExtraFieldsData() {
    // if (!this.lead) {
    //   this.getCustomFieldsData();
    // } else {
    //   if (this.lead.custom_contact_fields) {
    //     for (const data of this.lead.custom_contact_fields) {
    //       console.log(data);
    //       this.addfieldsData(data);
    //     }
    //   }
    // }
  }

  preventDefault() {
    return false;
  }

  intilizeForm() {
    console.log(this.lead);

    this.leadForm = new FormGroup({
      id: new FormControl(this.lead ? this.lead.id : ''),
      requirement_title: new FormControl(this.lead ? this.lead.requirement_title : '', [Validators.required]),// Validators.pattern(/^[A-Za-z][A-Za-z0-9-'&.#@_\s]{1,}$/)
      requirement_description: new FormControl(''),
      requirement_budget: new FormControl(''),
      requirement_currency: new FormControl(''),
      name: new FormControl(this.lead?.name ? this.lead.name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)]),
      email: new FormControl(this.lead?.email ? this.lead.email : '', Validators.compose([Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])),//eslint-disable-line 
      company_id: new FormControl(this.lead?.company_id ? this.lead.company_id : ''),
      contact: new FormControl(this.lead?.contact ? this.lead.contact : ''),
      phone_number: new FormControl(this.lead?.phone_number ? this.lead.phone_number : ''),
      owner: new FormControl(this.lead?.owner ? this.lead.owner : this.loggedInUser.data.id, [Validators.required]),
      assignee: new FormControl(this.lead?.assignee ? this.lead.assignee : []),
      tags: new FormControl(this.lead?.tags ? this.lead.tags : ''),
      channel: new FormControl(this.lead?.channel ? this.lead.channel : 'manual'),
      product: new FormControl(this.lead?.product ? this.lead.product : []),
      due_date: new FormControl(this.lead?.due_date ? this.lead.due_date : ''),
      type: new FormControl(this.lead?.type ? this.lead.type : 'ACTIVE'),
      description: new FormControl(this.lead ? this.lead.description : ''),
      custom_lead_fields: new FormArray([]),
      system_lead_fields: new FormArray([]),
      ccode: new FormControl(this.lead?.ccode ? (this.lead.ccode.includes('+') ? this.lead?.ccode : '+' + this.lead.ccode) : this.authService.getCompanyData().ccode)
    });
    if (this.contact) {
      this.leadForm.controls["contact"].setValue(this.contact);
      this.getContactsList();
    }
    if(this.loggedInUser.customer_primary_login_type === 'email'){
      this.leadForm.controls['email'].setValidators(Validators.required);
      this.leadForm.controls['email'].updateValueAndValidity();
    } else {
      this.leadForm.controls['phone_number'].setValidators(Validators.required);
      this.leadForm.controls['phone_number'].updateValueAndValidity();
    }
  }

  // get custom_lead_fields(): FormArray {
  //   return this.leadForm.get("custom_lead_fields") as FormArray;
  // }

  // custom_fields(data: any): FormGroup {
  //   return new FormGroup({
  //     label: new FormControl(data ? data.label : ''),
  //     data_type: new FormControl(data ? data.data_type : ''),
  //     options: new FormControl(data ? data.options : ''),
  //     data: new FormControl(data ? data.data : ''),
  //     is_active: new FormControl(data ? data.is_active : true),
  //     id: new FormControl(data ? data.id : '')
  //   });
  // }

  // addfieldsData(data: any) {
  //   this.custom_lead_fields.push(this.custom_fields(data));
  // }

  addNewName = (name: any) => ({ first_name: name, id: '' });
  addNewEmail = (email: any) => ({ email: email, id: '' });
  addNewphonenumber = (number: any) => ({ phone_number: number, id: '' });

  hasRequiredValidator(controlName: string): boolean {
    const control = this.leadForm.get(controlName);
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      return !!(validator && validator['required']);
    }
    return false;
  }

  onSelectContact(contact: any, type: string) {
    // console.log(contact, "contact selected");
    this.contactActive = contact.is_active;
    this.leadForm.controls["phone_number"].enable();
    this.leadForm.controls["ccode"].enable();
    this.leadForm.controls["email"].enable();
    if (contact?.id) {
      this.leadForm.patchValue({ email: contact.email, contact: contact.id, phone_number: contact.phone_number, ccode: contact.ccode, name: contact.first_name });
      this.leadForm.controls["phone_number"].disable();
      this.leadForm.controls["ccode"].disable();
      this.leadForm.controls["email"].disable();
      this.contactActive = contact.is_active;
    } else if (type === 'name') {
      this.leadForm.patchValue({ contact: contact.id, name: contact.first_name });
      this.contactActive = true;
    } else if (type === 'email') {
      this.leadForm.patchValue({ contact: contact.id, email: contact.email });
      this.contactActive = true;
    } else if (type === 'phone_number') {
      this.leadForm.patchValue({ contact: contact.id, phone_number: contact.phone_number, ccode: contact.ccode || this.authService.getCompanyData().ccode });
      this.contactActive = true;
    }
  }

  // searchContact(term: any) {
  //   if (term.term.length >= 3) {
  //     this.service.getContactsBySearch(term.term).subscribe((result: any) => {
  //       this.contactListArray = result.data;
  //     });
  //   } else if (term.term.length === 0) {
  //     this.getContactsList();
  //   }
  // }

  // searchCompany(term: any) {
  //   if (term.term.length >= 3) {
  //     this.service.getCompaniesBySearch(term.term).subscribe({
  //       next: (resp: any) => {
  //         this.companiesListArray = resp.data;
  //       }
  //     });
  //   } else if (term.term.length === 0) {
  //     this.getCompaniesList();
  //   }
  // }

  get f() {
    return this.leadForm.controls;
  }

  onSubmit() {
    const inValidCkEditor = Object.entries(this.ckeditorLimitLists).find((ckeditorLimit: any) => ckeditorLimit[1]?.actualLimit > ckeditorLimit[1]?.maxLimit);
    this.submitted = true;
    if (!inValidCkEditor) {
      const submit_value = this.leadForm.value;
      submit_value.custom_lead_fields.forEach((obj: any, i: number) => {
        if (obj.data_type === "DATE_RANGE") {
          if (obj.end) {
            obj.data = this.convert(obj.start) + '&' + this.convert(obj.end);
            this.custom_lead_fields.controls[i].patchValue({ data: obj.data });
          } else if (obj.start) {
            obj.data = this.convert(obj.start);
            this.custom_lead_fields.controls[i].patchValue({ data: obj.data });
          }
        } else if (obj.data_type === "PHONE_NUMBER") {
          obj.data = { custom_ccode: obj.start, custom_phone_number: obj.end };
        }
        // if (obj.data === "" || (!obj.data && typeof obj.data !== 'boolean')) {
        //   obj.data = "NA";
        // }
      });
      this.leadForm.markAllAsTouched();
      if (this.leadForm.valid) {
        this.loading = true;
        let valid_mailId: any;
        let valid_phone_number, valid_ccode: any;
        let valid_name: any;
        let valid_channel: any;
        let valid_assignee: any;
        let valid_owner: any;
        const assignee_departments: any = [];
        // if (!this.contactActive) { //This conditions is to check if the user is active or not 
        //   this.toaster.error("Contact is not active");
        //   return;
        // }
        if (this.leadForm.controls['assignee'].status === 'DISABLED') {
          valid_assignee = this.leadForm.controls['assignee'].value;
        } else {
          valid_assignee = this.leadForm.value.assignee;
        }

        if (this.leadForm.controls['owner'].status === 'DISABLED') {
          valid_owner = this.leadForm.controls['owner'].value;
        } else {
          valid_owner = this.leadForm.value.owner;
        }

        if (valid_assignee.length !== 0) {
          this.staffMembers.forEach((item: any) => {
            valid_assignee.filter((assigne: any) => {
              if (assigne === item.id) {
                assignee_departments.push(item.department_info.id ? item.department_info.id : item.utype === 'ADMIN' ? this.loggedInUser.data.organisation : '');
              }
            });
          });
        }

        if (this.leadForm.controls["email"].status === 'DISABLED') {
          valid_mailId = this.leadForm.controls["email"].value?.toLowerCase();
        } else {
          valid_mailId = this.leadForm.value.email?.toLowerCase();
        }

        if (this.leadForm.controls["phone_number"].status === 'DISABLED') {
          valid_phone_number = this.leadForm.controls["phone_number"].value;
          valid_ccode = this.leadForm.controls["ccode"].value;
        } else {
          valid_phone_number = this.leadForm.value.phone_number;
          valid_ccode = this.leadForm.value.ccode;
        }

        if (this.leadForm.controls["name"].status === 'DISABLED') {
          valid_name = this.leadForm.controls["name"].value;
        } else {
          valid_name = this.leadForm.value.name;
        }

        if (this.leadForm.controls["channel"].status === 'DISABLED') {
          valid_channel = this.leadForm.controls["channel"].value;
        } else {
          valid_channel = this.leadForm.value.channel;
        }

        const owner_department = this.staffMembers.filter((member: any) => member.id === valid_owner);
        // console.log(owner_department);

        // submit_value.system_lead_fields.forEach((obj: any) => {
        //   if (obj.data === "" || (!obj.data && typeof obj.data !== 'boolean')) {
        //     obj.data = "NA";
        //   }

        // });
        this.leadForm.value.system_lead_fields.forEach((fields: any) => {
          if (fields.data_type === "MULTI_SELECT_DROPDOWN" && fields.data === "") fields.data = [];
          submit_value[fields.label] = fields.data;
        });
        delete submit_value.system_lead_fields;
        if (this.leadForm.value.id) {
          let info_body;
          if (Object.keys(this.info).length === 0) {
            info_body = this.lead.info;
          } else {
            info_body = this.info;
          }

          this.service.updateLead({ ...submit_value, assignee: valid_assignee, owner: valid_owner, email: valid_mailId, phone_number: valid_phone_number, ccode: valid_ccode, name: valid_name, channel: valid_channel, info: info_body, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0]?.department_info.id ? owner_department[0]?.department_info.id : owner_department[0].utype === 'ADMIN' ? this.loggedInUser.data.organisation : '' }).pipe(finalize(() => this.loading = false)).subscribe({
            next: () => {
              this.passBack();
              this.ngbActiveModal.dismiss();
            }
          });
        } else {
          this.service.createLead({ ...submit_value, email: valid_mailId, phone_number: valid_phone_number, ccode: valid_ccode, info: this.info, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0]?.department_info.id ? owner_department[0]?.department_info.id : owner_department[0].utype === 'ADMIN' ? this.loggedInUser.data.organisation : '' }
          ).pipe(finalize(() => this.loading = false)).subscribe({
            next: (resp: any) => {
              resp.data.chatId = this.chatId;
              const iframe: any = document.getElementById('chat-widget-value');
              iframe?.contentWindow.postMessage({ ...resp, type: "LeadCreated" }, '*');
              this.passBack();
              this.ngbActiveModal.dismiss();
            }
          });
        }
      }
    }
  }

  getContactsList() {
    this.service.getContactsBySearchDropDown().subscribe({
      next: (resp: any) => {
        this.contactListArray = resp.data;
        const exist = this.contactListArray.filter((item: any) => (item.id.includes(this.contact) || item.id === this.leadForm.value?.contact));

        if (exist.length !== 0) {
          this.contactActive = exist[0].is_active;
          this.leadForm.patchValue({ email: exist[0].email, phone_number: exist[0].phone_number, name: exist[0].first_name });
        } else if (this.contact || this.leadForm.value?.contact) {
          this.service.getContactById(this.contact ? this.contact : this.leadForm.value?.contact).subscribe((item: any) => {
            this.contactListArray.push(item.data[0]);
            this.contactActive = item.data[0].is_active;
            this.leadForm.patchValue({ email: item.data[0].email, phone_number: item.data[0].phone_number, name: item.data[0].first_name });
          });
        }
        if (this.contact) {
          this.leadForm.controls['phone_number'].disable();
          this.leadForm.controls['ccode'].disable();
          this.leadForm.controls['email'].disable();
          this.leadForm.controls['name'].disable();
        }
      }
    });
  }

  getCompaniesList() {
    this.service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.companiesListArray = resp.data;
      }
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  getProducts(type: string) {
    this.product_service.getStore().subscribe({
      next: (resp: any) => {
        this.product_service.getDropDownProducts(resp.data.id, type).subscribe({
          next: (products_resp: any) => {
            console.log(products_resp);
            this.products_list = products_resp.data;
          }
        });
      }
    });
  }
  getServices(type: string) {
    this.product_service.getStore().subscribe({
      next: (resp: any) => {
        this.product_service.getDropDownProducts(resp.data.id, type).subscribe({
          next: (products_resp: any) => {
            console.log(products_resp);
            this.services_list = products_resp.data;
          }
        });
      }
    });
  }

  getProductCategory(type: string) {
    this.product_service.getStore().subscribe((result: any) => {
      this.product_service.getProductCategories(result.data.id, type).subscribe((resp: any) => {
        this.productCategory = resp.data;
      });
    });
  }
  getServiceCategory(type: string) {
    this.product_service.getStore().subscribe((result: any) => {
      this.product_service.getProductCategories(result.data.id, type).subscribe((resp: any) => {
        this.serviceCategory = resp.data;
      });
    });
  }

  getAPTCategoriesList() {
    this.product_service.getAllCategories().subscribe({
      next: (resp: any) => {
        this.aptCategoriesArray = resp.data;
      }
    });
  }

  getAppointments() {
    this.product_service.getAllConsulations().subscribe({
      next: (resp: any) => {
        this.appointmentsArray = resp.data;
      }
    });
  }

  // getCustomFieldsData() {
  //   this.service.getCustomLeadFields().subscribe({
  //     next: (resp: any) => {
  //       console.log("custom fields - ", resp.Data);
  //       if (resp.Data) {
  //         for (const data of resp.Data) {
  //           console.log(data);
  //           this.addfieldsData(data);
  //         }
  //       }
  //     }
  //   });
  // }

  onSelectOwner(owner: any) {
    this.info['owner_info'] = {
      fname: owner.fname,
      lname: owner.lname
    };
    if (this.leadForm.value?.owner !== this.loggedInUser.data.id && this.loggedInUser.data.role !== 'ADMIN') {
      this.leadForm.controls['assignee'].disable();
    } else {
      this.leadForm.controls['assignee'].enable();
    }
  }

  passBack() {
    this.passEntry.emit(true);
  }

  clearContactFields(e: any) {
    this.leadForm.controls["phone_number"].enable();
    this.leadForm.controls["ccode"].enable();
    this.leadForm.controls["email"].enable();
    this.leadForm.patchValue({ email: '', contact: '', phone_number: '', name: '' });
  }


  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterProducts() {
    if (!this.products_list) {
      return;
    }
    // get the search keyword
    let search = this.productFilter.value;
    if (!search) {
      this.filteredProducts.next(this.products_list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredProducts.next(
      this.products_list.filter((name: any) => (name.name.toLowerCase().indexOf(search) > -1)
      ));
  }

  protected filterCurrencyBanks() {
    if (!this.currencyjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCurrencyCtrl.value;
    if (!search) {
      this.filteredCurrencyBanks.next(this.currencyjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCurrencyBanks.next(
      this.currencyjson.filter(bank => (bank.currency.toLowerCase().indexOf(search) > -1) ||
        bank.abbreviation.toLowerCase().indexOf(search) > -1)
    );
  }
  getTags() {
    this.settingsService.getTags('lead').subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }

  //*******************************Custom Fields START******************************************** *//

  getCustomFieldsData() {
    this._customFields.getEnquiryModelFields().subscribe({
      next: (resp: any) => {
        this.showErrorMessage = false;
        this.revokedSysFieldsList = {};
        this.customFieldsArray = resp.data.custom_lead_templates;
        this.onCheckRequiredFieldStatus(resp.data);
        this.systemFieldsArray = Object.entries(resp.data);
        let count = 0;
        this.systemFieldsArray.map((field: any) => {
          if (!field[1].is_default && field[1].on_form) {
            this.system_lead_fields.push(this.custom_fields({ ...field[1], label: field[0], data: this.lead ? this.lead[field[0]] : "" }));
            if (field[1].is_mandatory) {
              this.system_array(count).controls['data'].setValidators(Validators.required);
              this.system_array(count).controls['data'].updateValueAndValidity();
            }
            count++;
          }
          if (field[0] === 'scheduler_category') {
            this.getAPTCategoriesList();
          } else if (field[0] === 'scheduler') {
            this.getAppointments();
          } else if (field[0] === 'product_category') {
            this.getProductCategory(field[0]);
          } else if (field[0] === 'product') {
            this.getProducts(field[0]);
          } else if (field[0] === 'service_category') {
            this.getServiceCategory(field[0]);
          } else if (field[0] === 'service') {
            this.getServices(field[0]);
          } else if (field[0] === 'company') {
            this.getCompaniesList();
          }
        });

        this.system_lead_fields?.value?.forEach((ele: any) => {
          console.log(ele);

          if (ele.label === 'company' && ele.data) {
            console.log("intooooooooo", this.companiesListArray);

            this.companiesListArray.forEach((ele1: any) => {
              console.log(ele1);

              if (ele1.id === ele.data) {
                console.log("No change");
              } else {
                console.log("else");
                this.service.getCompanyById(ele.data).subscribe({
                  next: (company_id_info: any) => {
                    console.log(company_id_info, "COMPANY INFO");
                    if (company_id_info.data.length > 0) this.companiesListArray.push(company_id_info.data[0]);

                  }
                });
              }
            });

          }
        });

        if (this.customFieldsArray) {
          let customcount = 0;
          for (const data of this.customFieldsArray) {
            if (data.on_form) {
              this.addfieldsData(data);
              if (data.data_type === 'WEBSITE') {
                this.custom_array(customcount).controls['data'].setValidators(Validators.pattern(/^(https?:\/\/)?(www\.)?((([a-zA-Z0-9]+\.)+[a-zA-Z]{2,})|localhost)(:[0-9]{1,5})?(\/.*)?$/));
                this.custom_array(customcount).controls['data'].updateValueAndValidity();
              }
              if (data.is_mandatory) {
                this.custom_array(customcount).controls['data'].setValidators(Validators.required);
                this.custom_array(customcount).controls['data'].updateValueAndValidity();
              }
              customcount++;
            }
          }
        }
        if (this.lead) {
          this.addFieldValues();
        }
      }
    });
  }

  onCheckRequiredFieldStatus(allFieldData: any) {
    const returnedObj = onCheckRequiredFieldStatus(allFieldData, this.featuresData, this.showErrorMessage);
    this.revokedSysFieldsList = returnedObj.revokedSysFieldsList;
    this.showErrorMessage = returnedObj.showErrorMessage;
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      console.log(this.featuresData);
    });
  }

  get custom_lead_fields(): FormArray {
    return this.leadForm.get("custom_lead_fields") as FormArray;
  }

  get system_lead_fields(): FormArray {
    return this.leadForm.get("system_lead_fields") as FormArray;
  }

  addfieldsData(data: any) {
    this.custom_lead_fields.push(this.custom_fields(data));
  }

  getFieldValidity(arrayName: any, i: number) {
    this.leadForm.markAllAsTouched();
    return (<FormArray>this.leadForm.get(arrayName)).controls[i].invalid;
  }

  addFieldValues() {
    const copyCustomFiledArray = this.customFieldsArray.filter((elem: any) => elem.on_form);
    copyCustomFiledArray.forEach(async (field: any, i: any) => {
      const index = this.lead.custom_lead_fields.findIndex(function (item: any) {
        return item.custom_lead_template === field.id;
      });
      if (index >= 0) {
        if (this.lead.custom_lead_fields[index].data_type === 'DATE_RANGE') {
          this.custom_lead_fields.controls[i].patchValue({
            start: this.lead.custom_lead_fields[index].data !== "NA" ? this.lead.custom_lead_fields[index].data.split('&')[0] : "",
            end: this.lead.custom_lead_fields[index].data !== "NA" ? this.lead.custom_lead_fields[index].data.split('&')[1] : "",
            data: this.lead.custom_lead_fields[index].data !== "NA" ? this.lead.custom_lead_fields[index].data : "",
            id: this.lead.custom_lead_fields[index].id
          });
        } else if (this.lead.custom_lead_fields[index].data_type === 'PHONE_NUMBER') {
          this.custom_lead_fields.controls[i].patchValue({
            start: this.lead.custom_lead_fields[index].data !== "NA" ? this.lead.custom_lead_fields[index].data?.custom_ccode : "",
            end: this.lead.custom_lead_fields[index].data !== "NA" ? this.lead.custom_lead_fields[index].data.custom_phone_number : "",
            data: this.lead.custom_lead_fields[index].data !== "NA" ? this.lead.custom_lead_fields[index].data : "",
            id: this.lead.custom_lead_fields[index].id
          });
        } else {
          this.custom_lead_fields.controls[i].patchValue({
            data: this.lead.custom_lead_fields[index].data,
            id: this.lead.custom_lead_fields[index].id
          });
        }
      }
    });
  }

  custom_fields(data: any): FormGroup {

    const tempData = data.data_type !== 'MULTI_SELECT_DROPDOWN' ? data?.data ?
      (data.data !== "NA" ?
        (data.data_type === 'CHECK_BOX' ?
          [] : data.data_type === 'RADIO_BUTTON' ?
            data.data ? true : false : data.data) : "") : data?.data === false ? false : "" : (data ? data.data : []);

    return new FormGroup({
      id: new FormControl(""),
      label: new FormControl(data?.label ? data.label : ''),
      data_type: new FormControl(data?.data_type ? data.data_type : ''),
      options: new FormControl(data?.options ? data.options : ''),
      on_form: new FormControl(data?.on_form ? data.on_form : true),
      is_inner_screen: new FormControl(data?.is_inner_screen ? data.is_inner_screen : false),
      data: new FormControl(tempData),
      is_active: new FormControl(data?.is_active ? data.is_active : true),
      is_mandatory: new FormControl(data?.is_mandatory ? data.is_mandatory : false),
      custom_lead_template: new FormControl(data?.id ? data.id : ''),
      lead: new FormControl(this.lead?.id),
      organisation: new FormControl(this.loggedInUser.data.organisation),
      start: new FormControl(''),
      end: new FormControl('')
    });
  }

  custom_array(count: number): any {
    return this.custom_lead_fields.controls[count] as FormArray;
  }

  system_array(count: number): any {
    return this.system_lead_fields.controls[count] as FormArray;
  }

  toggleCheckbox(value: string, i = 0) {
    const selectedOptionsControl = this.custom_array(i).get('data');
    const currentValue = selectedOptionsControl.value;


    if (currentValue.includes(value)) {
      selectedOptionsControl.setValue(currentValue.filter((item: any) => item !== value));
    } else {
      currentValue.push(value);
      selectedOptionsControl.setValue(currentValue);
    }
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  //custom type File upload function
  onFileUpload(ev: any, index: number) {
    if (!ev.target.files[0]) return;
    if (ev.target.files[0].size > 15 * 1048576) {
      this.toaster.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    this.awsUtilService.getUploadFileLink(ev.target.files[0].type, ev.target.files[0].name.split('.')[1]).subscribe({
      next: (resp) => {
        this.custom_lead_fields.controls[index].patchValue({ data: { path: this.presignedurl + resp.data.file_name, file_name: ev.target.files[0].name } });
        this.awsUtilService.uploadFile(ev.target.files[0], resp.data.url).subscribe({
          next: () => {//@typescript-eslint/no-empty-function
          }
        });
      }
    });
  }

  onErrorLoadImage(event: any, target: any, file: any) {
    event.target.src = ErrorLoadImage(target, file);
  }
}

<div class="container-fluid custom-height tags">
    <ng-container *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="card pt-2">
            <div class="d-flex justify-content-between">
                <div class="d-inline-flex text-wrap">
                    <nav mat-tab-nav-bar [backgroundColor]="background" [tabPanel]="tabPanel" mat-stretch-tabs="false"
                        mat-align-tabs="start" animationDuration="0ms">
                        <a mat-tab-link *ngFor="let link of listOfModuleNames" (click)="onTabChange(link.value)"
                            [active]="activeLink === link.value" class="text-primary">
                            {{link.label}}
                        </a>
                    </nav>
                </div>
                <div class="mt-1">
                    <button class="btn btn-primary btn-sm px-4 d-flex align-items-center"
                        (click)="openAddTypeModal(addTypes)">
                        <mat-icon>add</mat-icon>Add Type
                    </button>
                </div>
            </div>
        </div>
        <mat-tab-nav-panel #tabPanel>
            <div class="d-flex flex-column justify-content-between" style="height: calc(100vh - 216px);">
                <div class="card p-2 h-100 mb-0 pb-0 " style="overflow-y: auto;">
                    <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="column_names.length"
                        [fromLocation]="'TYPES'"></app-table-skeleton>
                    <table *ngIf="!tableSkeletonLoader" class="table align-middle mb-0 file-table w-100">
                        <thead>
                            <tr>
                                <th scope="col" class="text-primary pb-3" *ngFor="let col of column_names">{{col}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="" *ngFor="let item of typeListArray; let i = index">
                                <td class="text-capitalize">
                                    {{ item.name }}
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <mat-icon class="me-2 font-size-18 cursor"
                                            (click)="openAddTypeModal(addTypes,item)">edit</mat-icon>
                                        <mat-icon class="font-size-18 cursor"
                                            (click)="deleteSelectedType(item.id)">delete</mat-icon>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <mat-paginator #paginator [length]="this.totalTypes" [pageSize]="page_size" (page)="page($event)"
                    aria-label="Select page" hidePageSize="true" showFirstLastButtons>
                </mat-paginator>
            </div>
        </mat-tab-nav-panel>
    </ng-container>
    <ng-container *ngIf="loggedInUser.data.role !== 'ADMIN'">
        <div class="d-flex align-items-center justify-content-center">
            <h6 class="text-secondary">You are not authorized to view this.</h6>
        </div>
    </ng-container>
</div>
<ng-template #addTypes let-modal>
    <div class="modal-header pb-0 border-0">
        <h6 class="modal-title fw-600" id="label">{{addTypeForm.value.id ? 'Update' : 'Add'}} Type</h6>
        <mat-icon class="cursor" (click)="modalRef.close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <form [formGroup]="addTypeForm" (ngSubmit)="onSubmit()">
            <div class="d-flex justify-content-between align-items-baseline ">
                <div class="col-sm-12">
                    <label class="mb-2 fw-500">Type Name <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="Enter type name" formControlName="name" />
                        <mat-error class="error d-flex align-item-center" *ngIf="submitted && f['name'].errors">
                            <mat-icon class="font-size-12 mt-1 me-2">info</mat-icon>
                            <span *ngIf="f['name'].errors['required']">Type name required</span>
                            <span *ngIf="f['name'].errors['pattern']">Invalid Type name</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex justify-content-end align-items-center mt-3">
                <button type="submit" class="btn btn-primary"><span *ngIf="addTypeForm.value?.id">Update</span><span *ngIf="!addTypeForm.value?.id">Save</span></button>
            </div>
        </form>
    </div>
</ng-template>
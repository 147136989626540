import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent {
  transaction_details: any;
  table_cols = ["Display Id", "Gateway", "Plan Name", "Amount", "Payment Status", "Plan Status", "Created", "Expired", "Action"];
  page_size = 20;
  currentPage = 1;
  totalRecords = 0;

  constructor(
    private service: SettingsService,
    private confirmationMsz: ConfirmationMszService
  ) {
    this.getTransactionDetails();
  }

  getTransactionDetails() {
    this.service.getTransactionHistory(this.currentPage).subscribe((resp: any) => {
      this.totalRecords = resp.total;
      this.transaction_details = resp.data;
    });
  }

  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.currentPage = e.pageIndex + 1;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getTransactionDetails();
  }

  confirmCancelSubscription(transactionId: string) {
    this.confirmationMsz.customDeleteView({ title: 'Are You Sure ?', text: 'Selected Subscription will be cancelled', button: 'Yes' }).then((result: any) => {
      if (result.value) {
        this.cancelSubscription(transactionId);
      }
    });
  }

  cancelSubscription(transactionId: string) {
    this.service.cancelSubscription(transactionId).subscribe({
      next: () => {
        this.getTransactionDetails();
      }
    });
  }
}
<div class="container-fluid p-0" [ngClass]="isInnerScreen ? 'innerScreenWidth' : 'normalScreenWidth'">
    <div class="overflow-auto" style="height: calc(100vh - 124px);">
        <!-- <h5 class="fw-500 font-size-22 text-primary my-2">Lists</h5> -->
        <div class="new-design-padding">
            <div class="recommended-cards d-flex flex-wrap align-items-center w-100 mb-4">
                <div class="card group-cards" *ngFor="let item of groupList">
                    <div class="card-body">
                        <h6 class="fw-500 font-size-16 text-primary mt-2 d-flex align-items-center cursor"
                            [routerLink]="['/campaigns/contact-list']"
                            [queryParams]="{user:item.name, groupid: item.id}">{{item.name}}</h6>
                        <p class="fw-400 font-size-14 text-light my-2 content limit-line-length-1">{{item.description}}
                        </p>
                        <mat-divider class="mt-4" style="border-style: dotted; opacity: 0.1;"></mat-divider>
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            <div class="d-flex align-items-center">
                                <img src="../../../assets/images/book-check.svg" class="me-1"
                                    style="margin-bottom: 1rem;">
                                <p style="color:#22332f;opacity: 0.8;">{{item.no_of_contacts}}</p>
                            </div>
                            <div class="">
                                <mat-icon class="cursor text-end font-size-16"
                                    (click)="onDuplicateList(duplicate,item)">
                                    control_point_duplicate</mat-icon>
                                <mat-icon class="cursor text-end font-size-16"
                                    (click)="onGroupEdit(addtolistModel,item)">edit</mat-icon>
                                <mat-icon class="cursor text-end font-size-16"
                                    (click)="confirmDelete(item.id)">delete</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #duplicate let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-primary">Duplicate List Name</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row px-2">
            <div class="col-12">
                <label class="mb-2 fw-600 text-primary">{{'New '+ contactsJson.list_name}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" class="w-100 h-100 border-0" matInput placeholder="List Name" [(ngModel)]="duplicateName" />
                </mat-form-field>
            </div>

        </div>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end pt-0">
        <button [disabled]="duplicateName.length <= 1" type="button" class="d-flex align-items-center btn btn-primary small" (click)="duplicatingList()">
            Create
        </button>
    </div>

</ng-template>

<ng-template #addtolistModel let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-primary">{{contactsJson.heading_update}}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row px-2">
            <div class="col-12">
                <label class="mb-2 ps-0 fw-600 text-primary">{{contactsJson.list_name}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput class="w-100 h-100 border-0" placeholder="List Name" [(ngModel)]="selectedItem.name" />
                </mat-form-field>
            </div>
            <div class="col-12">
                <label class="mb-2 ps-0 fw-600 text-primary d-block">{{contactsJson.lable_dsp}} </label>
                <!-- <mat-form-field appearance="outline" class="w-100"> -->
                    <textarea style="outline: none" rows="3" class="d-block w-100 border-radius-8 p-2" matInput placeholder="List Description"
                        [(ngModel)]="selectedItem.description" ></textarea>
                <!-- </mat-form-field> -->
            </div>
        </div>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end mb-3">
        <button type="button" class="d-flex align-items-center btn btn-primary small" (click)="updateToList()">
            <mat-icon class="me-1">playlist_add_check</mat-icon> {{contactsJson.heading_update}}
        </button>
    </div>
</ng-template>
<div class="container-fluid reports-sec">
  <div class="h-100 overflow-auto p-2">
    <div class="recommended-cards w-100 mt-4 mb-4 row">
      <div *ngFor="let report of reportLists;let i = index;" class="col-md-4 mb-3">
        <div class="card setup-cards h-100">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <h6 class="fw-500 font-size-16 text-primary mt-2 d-flex align-items-center">{{report.type + ' created'}}
              </h6>
              <span class="ms-2">-</span>
              <div class="d-flex align-items-center ms-1">
                <mat-form-field appearance="outline" class="contactsfilter ms-2 mat-hint-none main-filter w-100">
                  <mat-select [value]="report.selectedfilterType"
                    (valueChange)="onSelectFilteredBy($event,i)" >
                    <mat-option class="font-size-12" [value]="list" *ngFor="let list of filteredBy">{{ list
                      }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="pt-2">
              <div *ngIf="report.customFieldSeclection" class="pb-2">
                <form [formGroup]="formsArray[i]">
                  <div class="">
                    <label class="font-size-13">Start Date</label>
                    <span class="mx-2">-</span>
                    <mat-form-field appearance="outline" class="" style="margin-bottom: -1.35rem;">
                      <input matInput [matDatepicker]="picker" name="start" [max]="report.startMaxDate"
                        (dateChange)="onDateChange($event,i)" formControlName="start_date" placeholder="MM/DD/YYYY">
                      <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="mt-2">
                    <label class="font-size-13">End Date</label>
                    <span class="ms-3 me-2">-</span>
                    <mat-form-field appearance="outline" class="" style=" margin-bottom: -1.35rem;">
                      <input matInput [matDatepicker]="picker1" name="end" [max]="today" [min]="report?.maxDate"
                        (dateChange)="onDateChange($event,i)" formControlName="end_date" placeholder="MM/DD/YYYY">
                      <mat-datepicker-toggle matSuffix [for]="$any(picker1)"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                  </div>
                </form>
              </div>
              <div class="d-flex align-items-center">
                <h6 class="fw-500 font-size-20 text-primary m-0">{{report.totalReportsCreated}}
                </h6>
                <!-- <span class="ps-3 text-green">{{report.totalReportsCreated + '%'}}</span> -->
                <img src="assets/images/refresh.svg" width="18px" class="ms-2 reloader" style="cursor: pointer;"
                  (click)="reloadReport(report.selectedfilterType,i)" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recommended-cards d-flex flex-wrap align-items-start justify-content-between w-100 mt-4 mb-4">
      <div class="card setup-cards flex-grow-1">
        <div class="card-body">
          <div class="d-flex align-items-start flex-wrap">
            <h6 class="fw-500 font-size-16 text-primary mt-2 d-flex align-items-center">Compare
            </h6>
            <div class="d-flex align-items-center ms-1 me-3 mb-3">
              <mat-form-field appearance="outline" style="width: 12rem"
                class="contactsfilter ms-2 mat-hint-none main-filter">
                <mat-select [value]="reportLists[0].type" (valueChange)="onSelectCompareFilter($event)" >
                  <ng-container *ngFor="let list of reportLists;let i = index">
                    <mat-option class="font-size-12" [value]="list.type"
                      *ngIf="list.type !== 'Pipeline'">{{ list.type
                      }}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>

            <div>
              <form [formGroup]="compareCustomDateForm">
                <div class="d-flex align-items-start flex-wrap mb-2">
                  <div class="me-3 mb-3" formGroupName="compare_with">
                    <div class="d-flex align-items-center">
                      <mat-label class="text-center">Compare With</mat-label>
                      <span class="ms-2">-</span>
                      <div class="d-flex align-items-center ms-1">
                        <mat-form-field appearance="outline" style="width: 12rem"
                          class="contactsfilter ms-2 mat-hint-none main-filter">
                          <mat-select [value]="comapreDataValidations[0].compare_with.selectedfilterType"
                            (valueChange)="onSelectCompareFilteredBy($event,'compare_with')">
                            <mat-option class="font-size-12"
                              [disabled]="comapreDataValidations[0].compare_to.selectedfilterType === list  && list !== 'Custom Date'"
                              [value]="list" *ngFor="let list of filteredBy">{{ list
                              }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="d-block mt-2" *ngIf="comapreDataValidations[0].compare_with.customFieldSeclection">
                      <div class="me-2">
                        <label class="font-size-13">Start Date</label>
                        <span class="mx-2">-</span>
                        <mat-form-field appearance="outline" class="" style="margin-bottom: -1.35rem;">
                          <input matInput #input [matDatepicker]="picker" name="start"
                            [min]="comapreDataValidations[0].compare_with.minDate"
                            [max]="comapreDataValidations[0].compare_with.startMaxDate"
                            (dateChange)="onCompareDateChange($event,'compare_with')" formControlName="start_date"
                            placeholder="MM/DD/YYYY">
                          <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="mt-2">
                        <label class="font-size-13">End Date</label>
                        <span class="ms-3 me-2">-</span>
                        <mat-form-field appearance="outline" class="" style="margin-bottom: -1.35rem;">
                          <input matInput [matDatepicker]="picker1" name="end"
                            [max]="comapreDataValidations[0].compare_with.endMaxDate"
                            [min]="comapreDataValidations[0].compare_with.maxDate"
                            (dateChange)="onCompareDateChange($event,'compare_with')" formControlName="end_date"
                            placeholder="MM/DD/YYYY">
                          <mat-datepicker-toggle matSuffix [for]="$any(picker1)"></mat-datepicker-toggle>
                          <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div formGroupName="compare_to">
                    <div class="d-flex align-items-center">
                      <mat-label class="text-center">Compare To</mat-label>
                      <span class="ms-2">-</span>
                      <div class="d-flex align-items-center ms-1">
                        <mat-form-field appearance="outline" style="width: 12rem"
                          class="contactsfilter ms-2 mat-hint-none main-filter">
                          <mat-select [value]="comapreDataValidations[0].compare_to.selectedfilterType"
                            (valueChange)="onSelectCompareFilteredBy($event,'compare_to')">
                            <mat-option class="font-size-12" [value]="list"
                              [disabled]="comapreDataValidations[0].compare_with.selectedfilterType === list && list !== 'Custom Date'"
                              *ngFor="let list of filteredBy">{{ list
                              }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="d-block mt-2" *ngIf="comapreDataValidations[0].compare_to.customFieldSeclection">
                      <div class="me-2">
                        <label class="font-size-13">Start Date</label>
                        <span class="mx-2">-</span>
                        <mat-form-field appearance="outline" class="" style="margin-bottom: -1.35rem;">
                          <input matInput #input [matDatepicker]="picker2" name="start"
                            [min]="comapreDataValidations[0].compare_to.minDate"
                            [max]="comapreDataValidations[0].compare_to.startMaxDate"
                            (dateChange)="onCompareDateChange($event,'compare_to')" formControlName="start_date"
                            placeholder="MM/DD/YYYY">
                          <mat-datepicker-toggle matSuffix [for]="$any(picker2)"></mat-datepicker-toggle>
                          <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="mt-2">
                        <label class="font-size-13">End Date</label>
                        <span class="ms-3 me-2">-</span>
                        <mat-form-field appearance="outline" class="" style="margin-bottom: -1.35rem;">
                          <input matInput [matDatepicker]="picker3" name="end" [max]="today"
                            [min]="comapreDataValidations[0].compare_to.maxDate"
                            (dateChange)="onCompareDateChange($event,'compare_to')" formControlName="end_date"
                            placeholder="MM/DD/YYYY">
                          <mat-datepicker-toggle matSuffix [for]="$any(picker3)"></mat-datepicker-toggle>
                          <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="d-flex align-items-center mb-3 ms-2">
            <div class="d-flex align-items-center" *ngIf="compareToReportsCount-compareFromReportsCount >= 0">
              <h6 class="fw-500 font-size-20 text-primary m-0">
                {{compareToReportsCount-compareFromReportsCount}}</h6>
              <span *ngIf="compareToReportsCount-compareFromReportsCount > 0"><img class="mb-1 ms-1"
                  style="width: 16px;" src="assets/images/Green_Arrow_Up.png"></span>
            </div>
            <div class="d-flex align-items-center" *ngIf="compareToReportsCount-compareFromReportsCount < 0">
              <h6 class="fw-500 font-size-20 text-primary m-0">
                {{compareFromReportsCount-compareToReportsCount}}
              </h6>
              <span><img class="ms-1" style="width: 16px;" src="assets/images/Red_Arrow_Down.png"></span>
            </div>
            <span class="ps-3 compare-percentage">{{compareReportPercentage + '%'}}</span>
            <img (click)="onReload()" src="assets/images/refresh.svg" width="18px" class="ms-2 compare-reloader" style="cursor: pointer;"
              alt="">
          </div>
        </div>
        <div>
          <canvas #compareCanvas id="compareCanvas">{{compareChart}}</canvas>
        </div>
      </div>
    </div>
    <div class="recommended-cards d-flex flex-wrap align-items-start justify-content-between w-100 mt-4 mb-4"
      *ngIf="allPipelineLists.length > 0">
      <div class="card setup-cards flex-grow-1">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h6 class="fw-500 font-size-16 text-primary mt-2 d-flex align-items-center">Pipeline created
            </h6>
            <div class="d-flex align-items-center ms-1">
              <mat-form-field appearance="outline" class="contactsfilter ms-2 mat-hint-none main-filter">
                <mat-select [value]="allPipelineLists[0].name" (valueChange)="onSelectionPipeline($event)">
                  <mat-option class="font-size-12" [value]="pipeline.name" *ngFor="let pipeline of allPipelineLists">{{
                    pipeline.name
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div>
          <canvas #canvasPipeline id="canvasPip">{{pipChart}}</canvas>
        </div>
      </div>
    </div>

    <div class="recommended-cards d-flex flex-wrap align-items-start justify-content-between w-100 mt-4 mb-4">
      <div class="card setup-cards flex-grow-1">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h6 class="fw-500 font-size-16 text-primary mt-2 d-flex align-items-center">Tasks
            </h6>
            <div class="d-flex align-items-center ms-1">
              <mat-form-field appearance="outline" style="width: 12rem"
                class="contactsfilter ms-2 mat-hint-none main-filter">
                <mat-select [value]="reportLists[0].type" (valueChange)="onSelectTaskBoard($event)">
                  <ng-container *ngFor="let list of reportLists">
                    <mat-option class="font-size-12" [value]="list.type"
                      *ngIf="list.type !== 'Clients' && list.type !== 'Task' && !list.disabled">{{ list.type
                      }}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="align-items-center ms-1 source_id">
              <div *ngIf="allStages.length>0">
                <mat-form-field appearance="outline" class="contactsfilter ms-2 mat-hint-none main-filter">
                  <mat-select [value]="allStages[0].id" (valueChange)="onSelectionsourceId($event)">
                    <mat-option class="font-size-12" [value]="stages.id" *ngFor="let stages of allStages">{{
                      stages.name || stages.client_name
                      }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div>
          <canvas #canvasTask id="canvasTask">{{taskChart}}</canvas>
        </div>
      </div>
    </div>

    <!-- <div class="recommended-cards d-flex flex-wrap align-items-start justify-content-between w-100 mt-4 mb-4">
      <div class="card setup-cards flex-grow-1">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <h6 class="fw-500 font-size-16 text-primary mt-2 d-flex align-items-center">Task created
              </h6>
              <span class="ms-2">-</span>
              <div class="d-flex align-items-center ms-1">
                <mat-form-field appearance="outline" style="width: 12rem"
                  class="contactsfilter ms-2 mat-hint-none main-filter">
                  <mat-select [value]="defaultValue" (valueChange)="onSelectFilteredBy($event,5)" class="pt-1">
                    <mat-option class="font-size-12" [value]="list" *ngFor="let list of filteredBy">{{ list
                      }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="ms-2" *ngIf="reportLists[5].customFieldSeclection">
              <form [formGroup]="formsArray[5]">
                <div class="d-flex align-items-center">
                  <div class="me-2">
                    <mat-label class="font-size-13">Start Date</mat-label>
                    <span class="mx-2">-</span>
                    <mat-form-field appearance="outline" class="" style="margin-bottom: -1.35rem;">
                      <input matInput #input [matDatepicker]="picker" name="start" [min]="minDate" [max]="today"
                        (dateChange)="onDateChange($event,5)" formControlName="start_date" placeholder="Select date">
                      <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="">
                    <mat-label class="font-size-13">End Date</mat-label>
                    <span class="mx-2">-</span>
                    <mat-form-field appearance="outline" class="" style="margin-bottom: -1.35rem;">
                      <input matInput [matDatepicker]="picker1" name="end" [max]="today" [min]="maxDate"
                        (dateChange)="onDateChange($event,5)" formControlName="end_date" placeholder="Select date">
                      <mat-datepicker-toggle matSuffix [for]="$any(picker1)"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div> -->

  </div>
</div>
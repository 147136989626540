import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/service/auth.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-object-types',
  templateUrl: './object-types.component.html',
  styleUrls: ['./object-types.component.scss']
})
export class ObjectTypesComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  listOfModuleNames = [
    {
      label: 'Record Types',
      value: 'contact',
      launchDlKeys: 'contact'
    }
  ];
  featuresData: any;
  activeLink = this.listOfModuleNames[0].value;
  addObjectForm!: FormGroup<any>;
  background: ThemePalette = undefined;
  tableSkeletonLoader = true;
  column_names = ['Record Type Name', 'Actions'];
  objectsListArray: any;
  totalObjects: any;
  currentPage = 1;
  page_size = 20;
  submitted = false;
  modalRef: any;

  constructor(private settingService: SettingsService, private authService: AuthService, private modalService: NgbModal,
    private confirmationMsz: ConfirmationMszService) { }

  ngOnInit() {
    this.getFeatues();
    this.getObjects();
  }

  getObjects() {
    this.tableSkeletonLoader = true;
    this.settingService.getObjectTypes().subscribe((resp: any) => {
      this.objectsListArray = resp.data;
      this.totalObjects = resp.count;
      this.tableSkeletonLoader = false;
    });
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      this.listOfModuleNames = this.listOfModuleNames.filter((elem: any) => {
        return this.featuresData[elem.launchDlKeys];
      });
      this.activeLink = this.listOfModuleNames[0].value;

    });
  }

  onTabChange(item: string) {
    // this.paginator.pageIndex = 0;
    this.currentPage = 1;
    this.activeLink = item;
    this.getObjects();
  }

  initilizeObjectForm(data?: any) {
    this.addObjectForm = new FormGroup({
      name: new FormControl(data ? data.name : '', [Validators.required]),//Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      name_plural: new FormControl(data ? data.name_plural : ''),
      primary_login_type: new FormControl(data ? data.customer_primary_login_type : 'no_auth'),
      id: new FormControl(data ? data.id : ''),
      activities: new FormControl(data ? data.activities : false),
      notes: new FormControl(data ? data.notes : false),
      files: new FormControl(data ? data.files : false),
      calls: new FormControl(data ? data.calls : false),
      orders: new FormControl(data ? data.orders : false),
      schedulers: new FormControl(data ? data.schedulers : false),
      flow_cards: new FormControl(data ? data.flow_cards : false),
      meetings: new FormControl(data ? data.meetings : false),
      task_board: new FormControl(data ? data.task_board : false),
      is_delete_record: new FormControl(data ? data.is_delete_record : false),
      delete_record_ttl: new FormControl(data ? data.delete_record_ttl : 30)
    });
  }

  get f(){
    return this.addObjectForm.controls;
  }

  openAddObjectsModal(modal: any,item?: any) {
    this.initilizeObjectForm(item);
    this.modalRef = this.modalService.open(modal);
  }

  deleteSelectedObject(id: any) {
    this.confirmationMsz.customDeleteView({ title: 'Are you sure?', text: 'You want to delete!', button: 'Yes Delete!' })
      .then((data) => {
        if (data.value) {
          this.settingService.deleteObjectTypes(id).subscribe(() => {
            this.getObjects();
          });
        }
      });
  }

  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.currentPage = e.pageIndex + 1;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getObjects();
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.addObjectForm.value);
    if (this.addObjectForm.valid) {
      if (this.addObjectForm.value.id) {
        this.settingService.putObjectTypes(this.addObjectForm.value).subscribe(() => {
          this.getObjects();
          this.modalRef.dismiss();
        });
      } else {
        this.settingService.postObjectTypes(this.addObjectForm.value).subscribe(() => {
          this.getObjects();
          this.modalRef.dismiss();
        });
      }
    }
  }
}

import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { FORMBUILDER } from 'src/app/core/data/form_builder_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { FormBuilderService } from 'src/app/core/service/form-builder.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { onParseDomValue, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { ContactType } from 'src/app/core/data/crm_modal_json';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {

  tableHeads = [
    'Form Name',
    'Submission Values',
    'Actions'
  ];
  forms_list: any = [];
  form!: FormGroup;
  is_form_submitted = false;
  formbuilderJson = FORMBUILDER[0];
  errorJson = ERRORJSON[0];
  contact_type : any;
  display_no_data = false;
  submitted = false;
  LINK = '';
  @ViewChild('tp') _matTooltip!: MatTooltip;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  showTooltips = false;
  public Editor: any = ClassicEditor;
  ckeditor = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };
  ckconfigQue: any;

  constructor(
    public modelService: NgbModal,
    private router: Router,
    private _formBuilderService: FormBuilderService,
    private formBuilder: FormBuilder,
    private confirmationMsz: ConfirmationMszService,
    private setupService: SetUpService,
    private settingsService: SettingsService,
    private renderer: Renderer2,
    private authservice: AuthService
  ) {
    this.getstaffByid();
  }

  intilizeForm() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],// Validators.pattern(/^[A-Za-z][A-Za-z0-9-'&.#@_\s]{1,}$/)
      contact_type: [''],
      department: '',
      description: '',
      iframe_visibility: false
    });
  }

  getstaffByid() {
    this.settingsService.getStaff_infoById(this.loggedInUser.data.id).subscribe({
      next: (resp: any) => {
        this.form.controls['department'].setValue(resp.data.department);
      }
    });
  }
  ngOnInit(): void {
    this.settingsService.getCompanySettings().subscribe((resp: any) => {
      const organisationData = resp.data;
      if (window.location.hostname.includes('app.brexa.ai')) {
        this.LINK = `https://${organisationData?.slug}.brexa.ai/frame/form/`;
      } else if (window.location.hostname.includes('demo.brexa.ai')) {
        this.LINK = `https://${organisationData?.slug}.demo.brexa.ai/frame/form/`;
      } else {
        this.LINK = `https://${organisationData?.slug}.stage.brexa.ai/frame/form/`;
      }
    });
    this.getForms();
  }

  get f() {
    return this.form.controls;
  }

  getForms() {
    this._formBuilderService.getForms().subscribe((resp: any) => {
      this.forms_list = resp.data;
      this.display_no_data = false;
      if (this.forms_list.length === 0) this.display_no_data = true;
    });
  }

  openModel(modal: any) {
    this.getContactTypes();
    this.intilizeForm();
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
    this.modelService.open(modal);
  }

  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditor = Object.assign(this.ckeditor, { ...contentLength });
    });
  }

  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();
    const dataWithoutTags = onParseDomValue(inputData);
    this.ckeditor = Object.assign(this.ckeditor, { actualLimit: dataWithoutTags.length || 0 });
  }
  getContactTypes(){
    this.settingsService.getContactTypes().subscribe((resp: any)=>{
      this.contact_type = resp.data;
    });
  }

  createformbuilder() {
    this.submitted = true;
    if (!this.form.valid || this.is_form_submitted) {
      this.form.markAllAsTouched();
      return;
    }
    this.is_form_submitted = true;
    this._formBuilderService.createForm(this.form.value).subscribe((resp: any) => {

      // const payload = {
      //   "form_id": resp.data.id,
      //   "form_fields_payload": [
      //     {
      //       "label": "Full Name",
      //       "type": "TEXT",
      //       "options": [],
      //       "key": "TEXT",
      //       "status": true,
      //       "order": 2,
      //       "is_default": true,
      //       "is_mandatory": true,
      //       "on_dashboard": false,
      //       "on_form": false,
      //       "on_model": false,
      //       "field_type": "SYSTEM_FIELD",
      //       "original_label": "name",
      //       "is_form_field": false
      //     },
      //     {
      //       "label": "Email Id",
      //       "type": "TEXT",
      //       "options": [],
      //       "key": "TEXT",
      //       "status": true,
      //       "order": 3,
      //       "is_default": true,
      //       "is_mandatory": true,
      //       "on_dashboard": false,
      //       "on_form": false,
      //       "on_model": false,
      //       "field_type": "SYSTEM_FIELD",
      //       "original_label": "email",
      //       "is_form_field": false
      //     },
      //     {
      //       "label": "Phone Number",
      //       "type": "TEXT",
      //       "options": [],
      //       "key": "TEXT",
      //       "status": true,
      //       "order": 4,
      //       "is_default": true,
      //       "is_mandatory": false,
      //       "on_dashboard": false,
      //       "on_form": false,
      //       "on_model": false,
      //       "field_type": "SYSTEM_FIELD",
      //       "original_label": "phone_number",
      //       "is_form_field": false
      //     },
      //     {
      //       "label": "ccode",
      //       "type": "TEXT",
      //       "options": [],
      //       "key": "NUMBER",
      //       "status": true,
      //       "order": 1,
      //       "is_default": true,
      //       "is_mandatory": false,
      //       "on_dashboard": false,
      //       "on_form": false,
      //       "on_model": false,
      //       "field_type": "SYSTEM_FIELD",
      //       "original_label": "ccode",
      //       "is_form_field": false
      //     }
      //   ]
      // };
      // this._formBuilderService.postFormFields(payload).subscribe(() => {
      //   console.log("Resp", resp);
      // });
      this.router.navigate(["../form-settings"], { queryParams: { id: resp.data.id } });
      this.form.reset();
      this.modelService.dismissAll();
      this.getForms();
      this.is_form_submitted = false;
    }, () => {
      this.is_form_submitted = false;
    });
  }

  deleteForm(id: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this._formBuilderService.deleteForm(id).subscribe(() => {
          this.getForms();
        });
      }
    });
  }

  featureShow() {
    return this.setupService.checkFeature('Web Forms');
  }

  clickEvent(event: any) {
    setTimeout(() => {
      const tooltipItem = document.querySelectorAll('.cdk-overlay-pane');
      if (tooltipItem) {
        tooltipItem.forEach((activeId) => {
          this.renderer.setStyle(activeId, 'top', event.clientY - 50 + 'px');
        });
      }
    }, 10);
  }

  onClipboardCopy(e: any) {
    setTimeout(() => {
      this._matTooltip.show();
      this._matTooltip.message = e ? 'Copied!' : 'Copy to clipboard';
    });
  }

  toggleVisibility(item: any) {
    this._formBuilderService.putForm(item, item.id).subscribe(() => {
      this.getForms();
    });
  }

  getRolePermissions(item: any,permission:any) {
    return this.authservice.rolefeatureShow(item,permission);
  }
}

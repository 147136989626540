import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CREATEFORM } from 'src/app/core/data/form_builder_json';
import { SettingsModelComponent } from './settings-model/settings-model.component';
import { cloneDeep } from 'lodash';
import { FormBuilderService } from 'src/app/core/service/form-builder.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { countryData } from 'src/app/core/data/countryData';
import { currencyJson } from 'src/app/core/data/currencyData';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.scss']
})
export class CreateFormComponent implements OnInit, OnChanges {
  @Input() formId = '';
  @Input() type = '';

  custom_form!: FormGroup;
  items: any = [];
  OriginalItems = [
    // { label: "name", type: "TEXT", options: [], key: "TEXT", status: true, original_label: "name" },
    // { label: "email", type: "TEXT", options: [], key: "EMAIL", status: true, original_label: "email" },
    // { label: "phone_number", type: "TEXT", options: [], key: "NUMBER", status: true, original_label: "phone_number" },
    // { label: "ccode", type: "TEXT", options: [], key: "NUMBER", status: true, original_label: "ccode" },
    { label: "Company Name", type: "TEXT", options: [], key: "TEXT", status: true, original_label: "company" },
    { label: "Description", type: "TEXT_AREA", options: [], key: "TEXT_AREA", status: true, original_label: "description" },
    { label: "Follow Up Date", type: "DATE_TIME", options: [], key: "DATE_TIME", status: true, original_label: "follow_up_date" },
    { label: "Budget", type: "TEXT", options: [], key: "NUMBER", status: true, original_label: "budget" },
    { label: "Website", type: "TEXT", options: [], key: "TEXT", status: true, original_label: "website" },

    { label: "Scheduler", launchDlKeys: 'appointment', type: "API", options: [], key: "APPOINTMENT", status: true, original_label: "scheduler" },
    { label: "Products", type: "API", launchDlKeys: 'products_service', options: [], key: "PRODUCT", status: true, original_label: "product" },
    { label: "Service", type: "API", launchDlKeys: 'service_setup', options: [], key: "SERVICE", status: true, original_label: "service" },
    { label: "Scheduler Categories", launchDlKeys: 'appointment', type: "API", options: [], key: "APPOINTMENT_CATEGORIES", status: true, original_label: "scheduler_category" },
    { label: "Product Categories", launchDlKeys: 'products_service', type: "API", options: [], key: "PRODUCT_CATEGORIES", status: true, original_label: "product_category" },
    { label: "Service Categories", launchDlKeys: 'service_setup', type: "API", options: [], key: "SERVICE_CATEGORIES", status: true, original_label: "service_category" },

    { label: "Amount", type: "TEXT", options: [], key: "NUMBER", status: true, original_label: "amount" },
    { label: "Applying Position", type: "TEXT", options: [], key: "TEXT", status: true, original_label: "applying_position" },
    { label: "Card Name", type: "TEXT", options: [], key: "TEXT", status: true, original_label: "card_name" },
    { label: "Closing Date", type: "DATE_TIME", options: [], key: "DATE_TIME", status: true, original_label: "closing_date" },
    { label: "Cost", type: "TEXT", options: [], key: "NUMBER", status: true, original_label: "cost" },
    { label: "Current Salary", type: "TEXT", options: [], key: "NUMBER", status: true, original_label: "current_salary" },
    { label: "Current Job Status", type: "TEXT", options: [], key: "TEXT", status: true, original_label: "current_job_status" },
    { label: "Current Employment Status", type: "TEXT", options: [], key: "TEXT", status: true, original_label: "current_employment_status" },
    { label: "Date of Birth", type: "DATE_TIME", options: [], key: "DATE_TIME", status: true, original_label: "date_of_birth" },
    { label: "End Date", type: "DATE_TIME", options: [], key: "DATE_TIME", status: true, original_label: "end_date" },
    { label: "Expected Salary", type: "TEXT", options: [], key: "NUMBER", status: true, original_label: "expected_salary" },
    { label: "Expected Revenue", type: "TEXT", options: [], key: "NUMBER", status: true, original_label: "expected_revenue" },
    { label: "Primary Contact", type: "TEXT", options: [], key: "TEXT", status: true, original_label: "primary_contact" },
    { label: "Priority", type: "TEXT", options: [], key: "TEXT", status: true, original_label: "priority" },
    { label: "Reason", type: "TEXT", options: [], key: "TEXT", status: true, original_label: "reason" },
    { label: "Secondary Contact", type: "TEXT", options: [], key: "TEXT", status: true, original_label: "secondary_contact" },
    { label: "Start Date", type: "DATE_TIME", options: [], key: "DATE_TIME", status: true, original_label: "start_date" },


    { label: "Text Box", type: "TEXT", options: [], key: "TEXT", status: true, original_label: "text_box" },
    { label: "Text Area", type: "TEXT_AREA", options: [], key: "TEXT_AREA", status: true, original_label: "text_area" },
    { label: "Drop Down", type: "SELECT_DROPDOWN", options: ["Option One", "Option Two", "Option Three"], key: "SELECT_DROPDOWN", status: true, original_label: "drop_down" },
    { label: "Check Box", type: "CHECK_BOX", options: ["Option One", "Option Two", "Option Three"], key: "CHECK_BOX", status: true, original_label: "check_box" },
    { label: "Radio Button", type: "RADIO_BUTTON", options: ["Option One", "Option Two", "Option Three"], key: "RADIO_BUTTON", status: true, original_label: "radio_button" },
    { label: "Date", type: "DATE", options: [], key: "DATE", status: true, original_label: "date" },
    { label: "Time", type: "TIME", options: [], key: "TIME", status: true, original_label: "time" },
    { label: "File Upload", type: "FILE", options: [], key: "FILE", status: true, original_label: "file_upload" }
  ];

  APPOINTMENT_CATEGORY: any = [];
  APPOINTMENT: any = [];
  PRODUCT_CATEGORY: any = [];
  PRODUCT: any = [];
  SERVICE_CATEGORY: any = [];
  SERVICE: any = [];
  STORE: any = {};

  dropItemData: any = [];
  createformJson = CREATEFORM[0];
  searchInput = "";

  originalFormName = '';
  default_value: any = "";
  deleteIndex!: number;
  default_value_option: any[] = [];
  variable: any[] = [];
  deletedItemData: any;
  start: any;
  end: any;
  presignedurl = environment.preSendUrl;
  countryjson = countryData;
  currencyjson = currencyJson;
  featuresData: any;


  constructor(
    public modalService: NgbModal,
    private _formServices: FormBuilderService,
    private _setUpService: SetUpService,
    private confirmationMsz: ConfirmationMszService, private awsUtilService: AwsUtilsService,
    private toaster: ToastrService, private authService: AuthService
  ) { }

  ngOnInit() {
    this.getFeatues();
    this.initializeForm();
    this.initialCall();
    this.items = this.OriginalItems;
    this.initialGetDataCall();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formId'] && changes['formId'].currentValue) {
      this.initializeForm();
      this.initialCall();
      this.items = this.OriginalItems;
      this.initialGetDataCall();
    }
  }

  initialCall() {
    this.getFormInfo(this.formId);
    this.getFormFields(this.formId);

  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }

  initialGetDataCall() {  // [TODO]: should be improved, as we are making unwanted api calls over here.
    this.getAppointmentCategories();
    this.getAppointment();
    this.getStore();
  }

  getFormInfo(id: string) {
    if (this.type === 'dynamic_app') {
      this._setUpService.getDynamicFormsById(id).subscribe((resp: any) => {
        this.initializeForm(resp.data);
        this.originalFormName = resp.data.name;
      });
    } else {
      this._formServices.getFormData(id).subscribe((resp: any) => {
        this.initializeForm(resp.data);
        this.originalFormName = resp.data.name;
      });
    }
  }

  getFormFields(id: string) {

    this._formServices.getFormFields(id, this.type).subscribe((resp: any) => {
      this.dropItemData = [];
      resp.data.forEach((item: any) => {
        if (item.original_label !== 'ccode') {
          this.addFormItem(item);
          this.dropItemData.push(item);
        }
      });
      this.dropItemData.forEach((element: any) => {
        if (Array.isArray(element.variables)) {
          this.variable.push(...element.variables);
        }
      });
    });
  }

  initializeForm(data?: any) {
    this.custom_form = new FormGroup({
      form_id: new FormControl(this.formId),
      name: new FormControl(data?.name ? data.name : '', [Validators.required]),//Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      form_fields_payload: new FormArray([])
    });
  }

  FormItemsArray(data?: any) {

    return new FormGroup({
      id: new FormControl(data?.id ? data.id : '', Validators.required),
      label: new FormControl(data?.label ? data.label : '', Validators.required),
      type: new FormControl(data?.type ? data.type : '', Validators.required),
      options: new FormControl(data?.options ? this.convertJSONToArray(data.options) : []),
      key: new FormControl(data?.key ? data.key : 'FIELD', Validators.required),
      status: new FormControl(data?.status ? data.status : true, Validators.required),
      order: new FormControl(data?.order ? data.order : 999, Validators.required),
      is_default: new FormControl(false),
      is_mandatory: new FormControl(false),
      on_dashboard: new FormControl(false),
      on_form: new FormControl(false),
      on_model: new FormControl(false)
    });


  }

  convertJSONToArray(obj: any) {
    if (typeof obj === "object" && obj !== null) {
      try {
        JSON.parse(JSON.stringify(obj));
        return Array.isArray(obj) && obj.length > 0 ? obj : [];
      } catch (e) {
        return obj;
      }
    } else {
      return obj;
    }
  }

  FormOptionsArray() {
    return new FormGroup({
      option: new FormControl('')
    });
  }

  get FormItems() {
    return this.custom_form.get('form_fields_payload') as FormArray;
  }

  FormOptions(i?: number) {// @ts-ignore: Object is possibly 'null'.
    return this.custom_form.get('form_fields_payload')['controls'][i].get('options') as FormArray;
  }

  addFormItem(data?: any) {
    this.FormItems.push(this.FormItemsArray(data));
  }

  addFormOption(i?: number) {
    this.FormOptions(i).push(new FormControl('', Validators.required));
  }

  deleteFormItem(index: number, model?: any, itemData?: any) {
    this.default_value = '';
    this.confirmationMsz.customDeleteView({ title: 'Are you sure?', text: 'You want to delete!', button: 'Yes Delete!' })
      .then((data) => {
        if (data.value) {
          this.FormItems.removeAt(index);
          if (this.dropItemData[index].is_mandatory && index > -1) {
            this.deletedItemData = itemData;
            this.modalService.open(model);
            this.deleteIndex = index;
            this.default_value_option = this.dropItemData[index].options;
          } else if (index > -1) {
            this._formServices.deleteFormFields(this.dropItemData[index].id, this.type).subscribe((resp) => {
              this.dropItemData.splice(index, 1);
            });
          }
        }
      });
  }

  deleteItemInForm(index: number) {
    this._formServices.putFormFields({ form_field_default_value: this.default_value, is_active: false, id: this.dropItemData[index].id }, this.formId, this.type).subscribe((resp) => {
      this.dropItemData.splice(index, 1);
      this.modalService.dismissAll();
    });
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  deleteFormOption(index: number, index_option: number) {
    this.FormOptions(index).removeAt(index_option);
  }

  onFileUpload(ev: any) {
    if (!ev.target.files[0]) return;
    if (ev.target.files[0].size > 15 * 1048576) {
      this.toaster.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    this.awsUtilService.getUploadFileLink(ev.target.files[0].type, ev.target.files[0].name.split('.')[1]).subscribe({
      next: (resp) => {
        this.default_value = { path: this.presignedurl + resp.data.file_name, file_name: ev.target.files[0].name };
        this.awsUtilService.uploadFile(ev.target.files[0], resp.data.url).subscribe({
          next: () => {//@typescript-eslint/no-empty-function
          }
        });
      }
    });
  }

  onDateChange() {
    if (this.end) {
      this.default_value = this.convert(this.start) + '&' + this.convert(this.end);
    } else if (this.start) {
      this.default_value = this.convert(this.start);
      this.default_value = this.start;
    }
  }

  drop(event: CdkDragDrop<any>) {

    if (event.previousContainer.id !== event.container.id && Number(event.container.id.split("-")[3]) > Number(event.previousContainer.id.split("-")[3])) {
      // Clone the item that was dropped.
      const clone = cloneDeep(event.previousContainer.data[event.previousIndex]);

      // Add the clone to the new array.
      event.container.data.splice(event.currentIndex, 0, clone);
      const form_fields_payload = {
        ...event.previousContainer.data[event.previousIndex], order: event.currentIndex + 2,
        is_default: false,
        is_mandatory: false,
        on_dashboard: false,
        on_form: false,
        on_model: false,
        is_form_field: true
      };
      this.addFormItem(form_fields_payload);
      if (event.previousContainer.data[event.previousIndex].type === "API") {
        const apiType = event.previousContainer.data[event.previousIndex].key;
        let tempOptions = [];
        const options: any = [];
        switch (apiType) {
          case "APPOINTMENT_CATEGORIES": tempOptions = this.APPOINTMENT_CATEGORY; break;
          case "APPOINTMENT": tempOptions = this.APPOINTMENT; break;
          case "PRODUCT_CATEGORIES": tempOptions = this.PRODUCT_CATEGORY; break;
          case "PRODUCT": tempOptions = this.PRODUCT; break;
          case "SERVICE_CATEGORIES": tempOptions = this.SERVICE_CATEGORY; break;
          case "SERVICE": tempOptions = this.SERVICE; break;
        }

        tempOptions.forEach((opt: any) => {
          options.push(JSON.stringify({ id: opt.id, name: opt.name }));
        });
        form_fields_payload.options = options;
      }
      this._formServices.postFormFields({
        form_id: this.formId,
        form_fields_payload: [form_fields_payload]
      }, this.type).subscribe(() => {
        this.getFormFields(this.formId);
      }, (err: any) => {
        if (err.message === 'Field already exist') {
          this.dropItemData.splice(event.currentIndex, 1);
          this.FormItems.removeAt(this.FormItems.length - 1);
        }
      });
    }
    else if (Number(event.container.id.split("-")[3]) < Number(event.previousContainer.id.split("-")[3])) {
      this.deleteFormItem(event.previousIndex);
    } else if (event.previousContainer.id === event.container.id && Number(event.container.id.split("-")[3]) % 2 !== 0) {
      const form_fields_payload = { ...event.previousContainer.data[event.previousIndex], order: event.currentIndex + 2 }; // 2 is added 1 for ccode and drag event starts with 0
      this._formServices.putFormFields(form_fields_payload, this.formId, this.type).subscribe((resp: any) => {
        this.getFormFields(this.formId);
      });
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

  }

  openSettingsModel(item: any, index: number) {

    const modalRef = this.modalService.open(SettingsModelComponent, {
      modalDialogClass: 'settings-dialog',
      centered: false,
      size: 'xl'
    });
    modalRef.componentInstance.formItem = item;
    modalRef.componentInstance.variableformFields = this.variable;
    switch (item.key) {
      case "APPOINTMENT_CATEGORIES":
        modalRef.componentInstance.API_LIST = this.APPOINTMENT_CATEGORY;
        break;
      case "APPOINTMENT":
        modalRef.componentInstance.API_LIST = this.APPOINTMENT;

        break;
      case "PRODUCT_CATEGORIES":
        modalRef.componentInstance.API_LIST = this.PRODUCT_CATEGORY;

        break;
      case "PRODUCT":
        modalRef.componentInstance.API_LIST = this.PRODUCT;
        break;
      case "SERVICE_CATEGORIES":
        modalRef.componentInstance.API_LIST = this.SERVICE_CATEGORY;

        break;
      case "SERVICE":
        modalRef.componentInstance.API_LIST = this.SERVICE;
        break;
      default:
        break;
    }
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      const options = this.dropItemData[index].type === "API" ? receivedEntry.select_options : receivedEntry.options;
      this.dropItemData[index].options = [];

      options.forEach((opitem: any) => {
        this.dropItemData[index].options.push(opitem);
      });
      this.dropItemData[index].label = receivedEntry.label;
      this.dropItemData[index].type = receivedEntry.type;
      this.dropItemData[index].is_mandatory = receivedEntry.is_mandatory;
      this.dropItemData[index].variables = receivedEntry.variables;
      this.dropItemData[index].place_holder = receivedEntry.place_holder;

      this._formServices.putFormFields({ ...this.dropItemData[index], options: options }, this.formId, this.type).subscribe((resp) => {
        this.initialCall();
      });
    });
  }

  onFormSubmit() {
    this._formServices.postFormFields(this.custom_form.value, this.type).subscribe((resp: any) => {
      this.initialCall();
    });
  }

  onFormNameSubmit() {
    if (this.type === 'dynamic_app') {
      this._setUpService.updateDynamicForms({ id: this.custom_form.value.form_id, ...this.custom_form.value }).subscribe(() => {
        this.initialCall();
      });
    } else {
      this._formServices.putForm(this.custom_form.value, this.formId).subscribe(() => {
        this.initialCall();
      });
    }
  }

  onSearchInputChange(event: any) {
    if (this.searchInput !== "") {
      this.items = this.OriginalItems.filter(item => item.label.toLowerCase().includes(this.searchInput.toLowerCase()));
    } else {
      this.items = this.OriginalItems;
    }
  }


  getAppointmentCategories() {
    this._setUpService.getAllCategories().subscribe((resp: any) => {
      this.APPOINTMENT_CATEGORY = resp.data;
    });
  }

  getAppointment() {
    this._setUpService.getAllConsulations().subscribe((resp: any) => {
      this.APPOINTMENT = resp.data;
    });
  }

  getStore() {
    // this._setUpService.getStore().subscribe((resp: any) => {
    //   this.STORE = resp.data;
    this.getProductCategories();
    this.getProduct();
    // });F
  }

  getProductCategories() {
    this._setUpService.getProductCategories("product").subscribe((resp: any) => {
      this.PRODUCT_CATEGORY = resp.data;
    });
    this._setUpService.getProductCategories("service").subscribe((resp: any) => {
      this.SERVICE_CATEGORY = resp.data;
    });

  }

  getProduct() {
    this._setUpService.getProduct("product").subscribe((resp: any) => {
      this.PRODUCT = resp.data;
    });
    this._setUpService.getProduct("service").subscribe((resp: any) => {
      this.SERVICE = resp.data;
    });
  }

  getName(arr: any, id: string) {
    return arr.find((item: any) => item.id === id)?.name;
  }

  getItemName(option: string) {
    try {
      const parsedOption = JSON.parse(option);
      if (parsedOption && parsedOption?.name) {
        return parsedOption.name;
      } else {
        return parsedOption;
      }
    } catch (e) {
      return option;
    }
  }
  getItemId(option: string) {
    try {
      const parsedOption = JSON.parse(option);
      if (parsedOption && parsedOption?.name) {
        return parsedOption.name;
      } else {
        return parsedOption;
      }
    } catch (e) {
      return option;
    }
  }
}

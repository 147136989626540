<div class="container-fluid">
    <mat-sidenav-container class="example-container-pipe pt-2" style="height: 100vh;" autosize>
        <mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true">
            <mat-form-field appearance="outline" class="m-2 mat-hint-none position-relative">
                <input style="outline: none" class="font-size-13" id="search-box" matInput placeholder="Search Here..."
                    [(ngModel)]="searchInput" (input)="onSearchInputChange($event)" />
                <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
                        alt=""></mat-icon>
            </mat-form-field>
            <mat-nav-list class="p-1">
                <ng-container *ngFor="let menuGroup of menuList;let i = index">
                    <div *ngIf="menuGroup?.isEnable" class="text-black cursor position-relative">
                        <a class="list fw-500 font-size-13 mb-1" mat-list-item
                            [ngClass]="{'is_active': selectedIndex === i}" (click)="routerURL(menuGroup,i)">
                            <div class="d-flex justify-content-between">
                                <div class="inner-img-card me-2">
                                    <img src="{{ selectedIndex !== i ? menuGroup.image : menuGroup.white_image}}"
                                        width="18" height="18" alt="{{ menuGroup.label }}">
                                </div>
                                <span class="text-ellipsis w-100 font-size-13">{{menuGroup.label}}</span>
                            </div>
                        </a>
                    </div>
                </ng-container>
            </mat-nav-list>
        </mat-sidenav>

        <div class="example-sidenav-content">
            <!-- <button mat-icon-button (click)="sidenav.toggle()" class="border-0 bg-white position-absolute"
                style="top: 7px;">
                <img src="../../../assets/images/newUI/CRM/menu_collapse.svg" width="15"></button> -->
            <router-outlet></router-outlet>
        </div>

    </mat-sidenav-container>
</div>
<div class="container-fluid mt-3">
    <h5 class="heading font-size-16 fw-700">{{formleadJson.heading}}</h5>
    <form [formGroup]="CRMForm">
        <div class="row mt-3 mb-5">
            <div class="col-md-12" *ngIf="!CRMForm.value.id">
                <label>Do you wish to add object fields to form? (Object fields are used for Contact/Objects and
                    Lead creation but are not applicable for Flows)</label>
                <div class="mt-1">
                    <label class="fw-600 mb-2 ">Record types</label><br>
                    <mat-form-field appearance="outline" class="w-75mat-hint-none">
                        <mat-select placeholder="Select record type" formControlName="object_type">
                            <mat-option [value]="opt.id" *ngFor="let opt of objectTypes">{{opt.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mt-2 mb-5 ">
                    <button class="btn btn-primary" (click)="onCRMFormSubmit()">{{formleadJson.btn_save}}</button>
                </div>
            </div>
            <div class="col-md-12 mb-3 d-none" *ngIf="!CRMForm.value.is_card">
                <div class="d-flex align-items-center">
                    <label>{{formleadJson.show_checkbox}}</label>
                    <label class="switch ms-3" *ngIf="!CRMForm.value.enquiry_owner">
                        <input type="checkbox" (change)="onAssignChange('pipeline')" formControlName="is_enquiry">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-5" *ngIf="CRMForm.value.is_enquiry">
                <div>
                    <label class="fw-600 mb-2 ">{{formleadJson.feild_owner}} <small
                            class="text-danger">*</small></label><br>
                    <mat-form-field appearance="outline" class="w-75">
                        <mat-select placeholder="{{formleadJson.placeholder_owner}}" formControlName="enquiry_owner">
                            <mat-option [value]="opt.id" *ngFor="let opt of staffList">{{opt.fname +" "+
                                opt.lname}}</mat-option>
                        </mat-select>
                        <mat-error class="error text-danger d-flex" *ngIf="isSubmited && f['enquiry_owner'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['enquiry_owner'].errors['required']">{{formleadJson.feild_owner}}
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="mt-2 mb-5 ">
                    <button class="btn btn-primary" (click)="onCRMFormSubmit()">{{formleadJson.btn_save}}</button>
                </div>
            </div>
            <div class="col-md-12" *ngIf="!CRMForm.value.is_enquiry">
                <div class="d-flex align-items-center">
                    <label>{{formleadJson.show_pipeline}}</label>
                    <label class="switch ms-3" *ngIf="!CRMForm.value.card_owner">
                        <input type="checkbox" (change)="onAssignChange('enquire')" formControlName="is_card">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-5 mt-3" *ngIf="CRMForm.value.is_card">
                <div>
                    <label class="fw-600 mb-2 ">{{formleadJson.pipeline_select}} <small
                            class="text-danger">*</small></label><br>
                    <mat-form-field appearance="outline" class="w-75">
                        <!-- <input style="outline: none" matInput placeholder="{{formleadJson.pipeline_select}}" /> -->
                        <mat-select placeholder="{{formleadJson.pipeline_select}}" formControlName="pipeline"
                            (selectionChange)="getStages()">
                            <mat-option [value]="opt.id" *ngFor="let opt of pipelineList">{{opt.name}}</mat-option>
                        </mat-select>
                        <mat-error class="error text-danger d-flex" *ngIf="isSubmited && f['pipeline'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['pipeline'].errors['required']">Pipeline
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <label class="fw-600 mb-2 ">{{formleadJson.stage_select}} <small
                            class="text-danger">*</small></label><br>
                    <mat-form-field appearance="outline" class="w-75">
                        <mat-select placeholder="{{formleadJson.stage_select}}" formControlName="stage"
                            (selectionChange)="getStageOwners($event.value)">
                            <mat-option [value]="opt.id" *ngFor="let opt of stageList">{{opt.name}}</mat-option>
                        </mat-select>
                        <mat-error class="error text-danger d-flex" *ngIf="isSubmited && f['stage'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['stage'].errors['required']">Stage
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <label class="fw-600 mb-2 ">{{formleadJson.feild_owner}} <small
                            class="text-danger">*</small></label><br>
                    <mat-form-field appearance="outline" class="w-75">
                        <mat-select placeholder="{{formleadJson.placeholder_owner}}" formControlName="card_owner">
                            <mat-option [value]="opt.id" *ngFor="let opt of staffList">{{opt.fname +" "+
                                opt.lname}}</mat-option>
                        </mat-select>
                        <mat-error class="error text-danger d-flex" *ngIf="isSubmited && f['card_owner'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['card_owner'].errors['required']">{{formleadJson.feild_owner}}
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="mt-2 mb-5 ">
                    <button class="btn btn-primary" (click)="onCRMFormSubmit()">{{formleadJson.btn_save}}</button>
                </div>

            </div>
        </div>
        <ng-container *ngIf="CRMForm.value.is_card || CRMForm.value.is_enquiry" class="d-none">
            <div class="d-none">
                <hr class="my-2">
                <p class="my-2">
                    Please Select Form Fields
                </p>
                <div class="mt-3 row w-75">

                    <div class="col">
                        <label class="fw-600 mb-2 ">{{formleadJson.name}}</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-select placeholder="{{formleadJson.name}}" formControlName="name">
                                <ng-container *ngFor="let opt of form_fields_list">
                                    <mat-option [value]="opt.id" *ngIf="opt.key === 'TEXT'">{{opt.label}}</mat-option>
                                </ng-container>

                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <label class="fw-600 mb-2 ">{{formleadJson.email}}</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-select placeholder="{{formleadJson.email}}" formControlName="email">
                                <ng-container *ngFor="let opt of form_fields_list">
                                    <mat-option [value]="opt.id" *ngIf="opt.key === 'EMAIL'">{{opt.label}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="w-100"></div>

                    <div class="col">
                        <label class="fw-600 mb-2 ">{{formleadJson.phone_number}}</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-select placeholder="{{formleadJson.phone_number}}" formControlName="phone">
                                <ng-container *ngFor="let opt of form_fields_list">
                                    <mat-option [value]="opt.id" *ngIf="opt.key === 'NUMBER'">{{opt.label}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <label class="fw-600 mb-2 ">{{formleadJson.title}}</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-select placeholder="{{formleadJson.title}}" formControlName="title">
                                <ng-container *ngFor="let opt of form_fields_list">
                                    <mat-option [value]="opt.id" *ngIf="opt.key === 'TEXT'">{{opt.label}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="w-100"></div>
                    <ng-container *ngIf="CRMForm.value.is_card">



                        <div class="col">
                            <label class="fw-600 mb-2 ">{{formleadJson.budget}}</label><br>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select placeholder="{{formleadJson.budget}}" formControlName="budget">
                                    <ng-container *ngFor="let opt of form_fields_list">
                                        <mat-option [value]="opt.id"
                                            *ngIf="opt.key === 'NUMBER'">{{opt.label}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- <div class="col">
                        <label class="fw-600 mb-2 ">{{formleadJson.due_date}}</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-select placeholder="{{formleadJson.due_date}}" class="pt-1" formControlName="due_date">
                                <ng-container *ngFor="let opt of form_fields_list">
                                    <mat-option [value]="opt.id" *ngIf="opt.key === 'DATETIME'">{{opt.label}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div> -->
                    </ng-container>

                </div>
                <div class="mt-2 mb-5 ">
                    <button class="btn btn-primary" (click)="onCRMFormSubmit()">{{formleadJson.btn_save}}</button>
                </div>
            </div>
        </ng-container>
    </form>
</div>
<div class="container-fluid">
    <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-3 mb-4"
        (navChange)="tabChange($event)">
        <li [ngbNavItem]="1">
            <a ngbNavLink class="d-flex">
                <span class="d-sm-block font-size-13">Import</span>
            </a>
            <ng-template ngbNavContent>
                <h6 class="text-primary fw-600">Upload File</h6>
                <ngx-dropzone (change)="onSelect($event)" [multiple]="false" class="bg-gray-light"
                    style="height: 61px;">
                    <ngx-dropzone-label>Drag a file here or <span class="text-primary fw-500">Browse</span>
                        for a file to upload
                    </ngx-dropzone-label>
                    <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)"
                        ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                    </ngx-dropzone-image-preview>
                </ngx-dropzone>
                <!-- <div class="d-flex align-items-center me-2">
                    <div class="d-flex align-items-center bg-primary text-white p-2 border-radius-8 me-2 cursor"
                        id="upload-btn" (change)="notesFileUpload($event)">
                        <mat-icon style="transform: rotate(152deg);">attachment</mat-icon>
                        <label class="fw-400 cursor ms-2" for="attach-file">Upload File
                            <input type="file" id="attach-file" accept=".pdf,.doc,.docx,.xml,.csv" hidden />
                        </label>
                    </div>
                    <div class="d-flex align-items-center" *ngIf="fileChosen">
                        <p class="mb-0 me-2 ms-2 font-size-13">{{fileChosen}}</p>
                        <div class="progress-bar bg-primary border-radius-8 me-1" role="progressbar"
                            style="width: 50px; height:8px" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        </div>
                        <mat-icon (click)="removeFile()">close</mat-icon>
                    </div>
                </div> -->
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink class="d-flex">
                <span class="d-sm-block font-size-13">Export Data</span>
            </a>
            <ng-template ngbNavContent>
                <h6 class="text-primary fw-600">Export Data</h6>
                <p class="mb-0 font-size-13">You can export records from a specific module in your Worke account as a
                    CSV file.</p>
                <label class="mb-2 mt-4 text-primary">Select a Module</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select placeholder="Select">
                        <mat-option value="Deals">
                            Deals
                        </mat-option>
                        <mat-option value="Pipelines">
                            Flows
                        </mat-option>
                        <mat-option value="Products">
                            Products
                        </mat-option>
                        <mat-option value="Task">
                            Task
                        </mat-option>
                        <mat-option value="Calls">
                            Calls
                        </mat-option>
                        <mat-option value="Companies">
                            Companies
                        </mat-option>
                        <mat-option value="Notes">
                            Notes
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button class="btn btn-primary">Export</button>
            </ng-template>
        </li>
        <li [ngbNavItem]="3">
            <a ngbNavLink class="d-flex">
                <span class="d-sm-block font-size-13">Recycle Bin</span>
            </a>
            <ng-template ngbNavContent>

            </ng-template>
        </li>
        <li [ngbNavItem]="4">
            <a ngbNavLink class="d-flex">
                <span class="d-sm-block font-size-13">Audit Log</span>
            </a>
            <ng-template ngbNavContent>

            </ng-template>
        </li>
        <li [ngbNavItem]="5">
            <a ngbNavLink class="d-flex">
                <span class="d-sm-block font-size-13">Storage</span>
            </a>
            <ng-template ngbNavContent>
                <h6 class="text-primary fw-600">Organization File Storage</h6>
                <div class="row d-flex align-items-center mt-3">
                    <div class="col-3">
                        <svg viewBox="0 0 36 36" class="circular-chart blue">
                            <path class="circle-bg" d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831" />
                            <path class="circle" stroke-dasharray="50, 100" d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831" />
                            <text x="18" y="20.35" class="percentage">50%</text>
                        </svg>
                    </div>
                    <div class="col-6">
                        <div class="d-flex align-items-center m-2">
                            <mat-icon class="font-size-16 d-flex align-items-center"
                                style="color: #d6d4d4;">trip_origin</mat-icon>
                            <p class="mb-0 me-2">Allowed Storage</p>
                            <mat-icon class="font-size-10 d-flex align-items-center"
                                style="color: #d6d4d4;">fiber_manual_record</mat-icon>
                            <p class="mb-0">1.5 GB</p>
                        </div>
                        <div class="d-flex align-items-center m-2">
                            <mat-icon class="font-size-16 d-flex align-items-center"
                                style="color: green;">trip_origin</mat-icon>
                            <p class="mb-0 me-2">Used Storage</p>
                            <mat-icon class="font-size-10 d-flex align-items-center"
                                style="color: #d6d4d4;">fiber_manual_record</mat-icon>
                            <p class="mb-0">
                                17.03 MB</p>
                        </div>
                    </div>
                </div>
                <div class="mt-5">
                    <h6 class="text-primary fw-600">File Storage Consumption by Users</h6>
                    <ul class="list-group mt-3">
                        <li class="list-group-item py-3">
                            <div class="row">
                                <div class="col-3 d-flex align-items-center">
                                    <h6 class="bg-primary d-flex-inline text-white border-radius-50 p-2 me-2 mb-0 fw-400 font-size-11">SG</h6>
                                    <p class="text-primary mb-0 fw-600 font-size-13">Sahithy G</p>
                                </div>
                                <div class="col-9 d-flex align-items-center justify-content-around">
                                    <p class="text-primary mb-0 font-size-13">5.15 MB</p>
                                    <mat-progress-bar class="w-70" mode="determinate" value="10"></mat-progress-bar>
                                    <p class="text-primary mb-0 font-size-13">34%</p>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item py-3">
                            <div class="row">
                                <div class="col-3 d-flex align-items-center">
                                    <h6 class="bg-primary d-flex-inline text-white border-radius-50 p-2 me-2 mb-0 fw-400 font-size-11">PD</h6>
                                    <p class="text-primary mb-0 fw-600 font-size-13">Prasanthi D</p>
                                </div>
                                <div class="col-9 d-flex align-items-center justify-content-around">
                                    <p class="text-primary mb-0 font-size-13">0 MB</p>
                                    <mat-progress-bar class="w-70" mode="determinate" value="10"></mat-progress-bar>
                                    <p class="text-primary mb-0 font-size-13">0%</p>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item py-3">
                            <div class="row">
                                <div class="col-3 d-flex align-items-center">
                                    <h6 class="bg-primary d-flex-inline text-white border-radius-50 p-2 me-2 mb-0 fw-400 font-size-11">SG</h6>
                                    <p class="text-primary mb-0 fw-600 font-size-13">Sahithy G</p>
                                </div>
                                <div class="col-9 d-flex align-items-center justify-content-around">
                                    <p class="text-primary mb-0 font-size-13">50.15 MB</p>
                                    <mat-progress-bar class="w-70" mode="determinate" value="10"></mat-progress-bar>
                                    <p class="text-primary mb-0 font-size-13">44%</p>
                                </div>
                            </div>
                        </li>
                      </ul>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="customNav"></div>
</div>
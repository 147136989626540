<div class="d-flex flex-column justify-content-between" style="height: calc(100vh - 130px);">
    <div class="table-responsive table-height fixed-table max-h-full">
        <table class="table align-middle table-nowrap table-hover mb-0 users-table w-100 max-h-full">
            <thead>
                <tr>
                    <th scope="col" *ngFor="let col of table_cols" class="text-primary pb-3">
                        {{col}}</th>
                </tr>
            </thead>
            <tbody>
            <tr *ngFor="let details of transaction_details">
                <td class="text-capitalize">{{details?.display_id}}</td>
                <td>{{ details?.gateway | titlecase }}</td>
                <td>{{ details?.description }}</td>
                <td>{{ details?.currency_symbol }} {{ details?.amount }}</td>
                <td>
                    <div [ngSwitch]="details?.status" class="status-badge">
                        <div *ngSwitchCase="'Success'" class="alert alert-success">
                            {{ details?.status }}
                        </div>
                        <div *ngSwitchCase="'Failed'" class="alert alert-danger">
                            {{ details?.status }}
                        </div>
                        <div *ngSwitchCase="'Cancelled'" class="alert alert-danger">
                            {{ details?.status }}
                        </div>
                        <div *ngSwitchCase="'Processing'" class="alert alert-warning">
                            {{ details?.status }}
                        </div>
                        <div *ngSwitchDefault class="alert alert-secondary">
                            {{ details?.status }}
                        </div>
                    </div>
                </td>
                <td>
                    <div [ngSwitch]="details?.plan_status" class="status-badge">
                        <div *ngSwitchCase="'ON-GOING'" class="alert alert-info">
                            {{ details?.plan_status }}
                        </div>
                        <div *ngSwitchCase="'FAILED'" class="alert alert-danger">
                            {{ details?.plan_status }}
                        </div>
                        <div *ngSwitchCase="'EXPIRED'" class="alert alert-warning">
                            {{ details?.plan_status }}
                        </div>
                        <div *ngSwitchCase="'CANCELLED'" class="alert alert-warning">
                            {{ details?.plan_status }}
                        </div>
                        <div *ngSwitchDefault class="alert alert-secondary">
                            {{ details?.plan_status }}
                        </div>
                    </div>
                </td>
                <td>{{ details?.created_at | date:'short' }}</td>
                <td>{{ details?.expired_at | date:'short' }}</td>
                <td>
                    <div class="d-flex align-items-center">
                        <a href="{{ details?.invoice_pdf_url }}" target="_blank" *ngIf="details?.invoice_pdf_url">
                            <mat-icon class="text-primary" matTooltip="Invoice">description</mat-icon>
                        </a>
                        <a href="{{ details?.receipt_link_url }}" target="_blank" class="ms-2"
                           *ngIf="details?.receipt_link_url">
                            <mat-icon class="text-primary" matTooltip="Receipt">receipt</mat-icon>
                        </a>
                        <ng-container *ngIf="details?.status === 'Success' && details?.plan_status === 'ON-GOING' && details?.gateway !== 'free'">
                            <button type="button" class="btn btn-link ms-2 p-0 border-0 d-flex align-items-center" (click)="confirmCancelSubscription(details.id)">
                                <mat-icon class="text-danger" matTooltip="Cancel Subscription">cancel</mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="mat-page-custom">
        <mat-paginator #paginator [length]="this.totalRecords" [pageSize]="page_size" (page)="page($event)"
                       aria-label="Select page" hidePageSize="true" showFirstLastButtons>
        </mat-paginator>
    </div>
</div>
<div class="container-fluid ms-2">
    <div class="row mt-3 mb-4">
        <div class="col-6 d-flex align-items-center">
            <div class="background-on-hover me-2 d-flex align-items-center">
                <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
                    aria-label="Button that displays a tooltip when focused or hovered over" class="text-primary cursor"
                    [routerLink]="['/crm/contacts']" [queryParams]="{contact_activeId: 3}"
                    routerLinkActive="router-link-active">arrow_back_ios
                </mat-icon><!--[routerLink]="['/campaign/group']" -->
            </div>
            <p class="m-0 heading font-size-18 fw-700 text-primary">{{group_user || 'List of Contacts'}}</p>
        </div>
        <div class="col-6 text-end px-4" style="height: 36px;">
            <mat-form-field appearance="outline" class="margin-right-14 mat-hint-none position-relative"
                style="width: 212px;">
                <input style="outline: none" class="font-size-14" id="search-box" matInput placeholder="Search Here..."
                    [(ngModel)]="search_global" (ngModelChange)="searchSubject.next(search_global)" />
                <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
                        alt=""></mat-icon>
            </mat-form-field>
            <button *ngIf="selection.selected.length > 0" mat-raised-button class="btn btn-primary border-0"
                (click)="deleteFromList()">Remove From List</button>
        </div>
    </div>
    <div class="custom-table-div-crm-height d-flex flex-column justify-content-between">
        <div class="table-responsive table-height fixed-table">
            <table class="table align-middle table-nowrap table-hover mb-0 w-100">
                <thead>
                    <tr class="border-top-table">
                        <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                class="mat_checkbox"></mat-checkbox>
                        </th>
                        <ng-container *ngFor="let col of filtersLables">
                            <th *ngIf="col.checked" scope="col"
                                class="text-primary font-size-14 pb-3 table-sticky-header bg-white"
                                style="z-index: 999;">{{col.lableName}}</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody *ngIf="!display_no_data">
                    <tr class="cursor" *ngFor="let item of contactListArray; let i = index">
                        <td>
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(item) : null" [checked]="selection.isSelected(item)"
                                class="mat_checkbox"></mat-checkbox>
                        </td>
                        <td>
                            {{ item.first_name ? item.first_name : "NA" }} {{item.last_name}}
                        </td>
                        <td>{{ item.email ? item.email : "NA" }}</td>
                        <td>
                            {{ item.phone_number ? item.phone_number : "NA" }}
                        </td>
                        <td>
                            {{item.company?.name ? item.company?.name : "NA"}}
                        </td>
                        <td>
                            {{(item?.owner_info?.fname || 'NA') + ' ' + (item?.owner_info?.lname || '')}}
                        </td>
                        <td>{{item.channel ? item.channel : "NA"}}</td>
                        <td *ngIf="item.created_at">{{ item.created_at | dateConversion}}</td><!--|  -->
                        <td *ngIf="!item.created_at">NA</td>
                        <!-- <td (click)="$event.stopPropagation()">
                            <mat-icon icon="" class="font-size-16 cursor text-danger"
                                (click)="deleteContact([item.id])">delete</mat-icon>
                        </td> -->
                    </tr>
                </tbody>
            </table>
            <app-nodata-found *ngIf="display_no_data"></app-nodata-found>

        </div>
        <div class="mt-3">
            <mat-paginator [length]="contactsObj.total" [pageSize]="page_size" (page)="page($event)"
                aria-label="Select page" showFirstLastButtons
                [pageSizeOptions]="[5, 20, 35, 50, 100, 200, 300, 400, 500]">
            </mat-paginator>
        </div>
    </div>
</div>
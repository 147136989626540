export const COMPANYSETTINGS = [
  {
    heading: 'Company',
    company_name: 'Company Name',
    org_name:'Organisation Name',
    email: 'Company Email',
    org_email:'Organisation Email',
    org_domain:'Organisation Website',
    domain: 'Company Website',
    country: 'Country',
    currency: 'Currency',
    timezone: 'Time Zone',
    ccode: 'Default phone country code',
    dateformat: 'Date Format',
    timeformat: 'Time Format',
    language: 'Language',
    dsp: 'Description',
    social_links: 'Socials',
    social_links_website: 'Your Website url here',
    social_links_insta: 'Your Instagram url here',
    social_links_twitter: 'Your Twitter url here',
    social_links_linkedin: 'Your Linkedin url here',
    social_links_utube: 'Your Youtube url here',
    slot_duration: 'Default Time slot',
    booking_settings: 'Booking Settings',
    dropzone_label_1: "Drag a image here or",
    dropzone_label_2: "Browse",
    dropzone_label_3: "for a image to upload",
    compony_logo: 'Logo',
    generate: 'Generated',
    business_url: 'Business URL',
    bussinessValidationText: "Your business name can be a combination of small letters and numbers.",
    price_symbol_position: 'Currency position',
    decimals: 'Number of decimals',
    gst_number: 'GST Number',
    cod: 'Cash on delivery',
    slot: 'Slot',
    save_btn: 'Save',
    save_proceed_btn: 'Save & Proceed',
    terms_and_conditions: 'Terms and Conditons',
    tax_name: 'Tax Name',
    tax_value: 'Tax Value',
    business_type: 'Business Type',
    industry_type: 'Industry Type',
    customer_primary_login: 'Customer Primary Login'
  }
];

export const LOCATIONS = [
  {
    heading: 'Locations',
    branch_location: 'Branch Location',
    enter_placeholder: 'Enter',
    add_btn: 'Add',
    col_name: 'Name',
    col_address: 'Address',
    col_status: 'Status',
    col_action: 'Actions',
    add_location: 'Location',
    update_btn: 'Update',
    branch_name: 'Branch Name',
    email_id:'Branch Email',
    phone_number:'Branch Phone Number',
    check_box_default: 'Mark as default branch',
    save_btn: 'Save',
    proceed_btn: 'Proceed',
    close_btn: 'Close'
  }
];

export const STAFF = [
  {
    heading: 'Users',
    add_btn: 'Staff Member',
    new: 'New',
    update: 'Update',
    enter_placeholder: 'Enter',
    field_name: 'First Name',
    field_lastname: 'Last Name',
    field_email: 'Email',
    field_number: 'Phone Number',
    field_role: 'Role',
    field_team: 'Department',
    field_location: 'Location',
    field_password: 'Password',
    field_badge: 'Employee Badge',
    field_booking: 'This user is available for booking from booking website',
    text: "Your password must be at least 5 characters long and should include a combination of uppercase letters, lowercase letters, numbers, and special characters.",
    field_designation: 'Designation',
    field_specialisation: 'Specialization',
    field_qualification: 'Qualification',
    field_experience: 'Years of Experience',
    field_gender: 'Gender',
    field_age: 'Age',
    field_timeZone: 'TimeZone',
    field_call_number: 'Number',
    save_btn: 'Save',
    close_btn: 'Close'
  }
];

export const TEAMS = [
  {
    heading: 'Departments',
    department: 'Department',
    add_btn: 'Add New',
    update_btn: 'Update',
    edit_btn: 'Edit',
    delete_btn: 'Delete',
    enter_placeholder: 'Enter',
    field_name: 'Department Name',
    field_people: 'Add people',
    field_member_list: 'Members List',
    field_member: 'Select member',
    select_placeholder: 'Select',
    save_btn: 'Save',
    close_btn: 'Close'
  }
];

export const dayoffJson = [
  {
    heading: 'Day Off',
    title: 'Title',
    date_range: 'Day Range',
    start_time: 'Start Time',
    end_time: 'End Time',
    update_btn: 'Update',
    save_btn: 'Save',
    close_btn: 'Close'
  }
];

export const DEVELOPERJSON = [
  {
    heading: 'Developer Users',
    title: 'Developer',
    field_name: 'First Name',
    field_lastname: 'Last Name',
    field_email: 'Email',
    field_number: 'Phone Number',
    field_psw: 'Password',
    enter_placeholder: 'Enter',
    add_btn: 'Add Developer',
    update_btn: 'Update',
    save_btn: 'Save',
    close_btn: 'Close',
    delete_btn: 'Delete',
    edit_btn: 'Edit',
    inactivate_btn: 'Inactivate',
    activate_btn: 'Activate',
    reset_psw: 'Reset Password'
  }
];
import { AfterViewInit, Component, ComponentRef, Input, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ContactsModalComponent } from '../../shared/contacts-modal/contacts-modal.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { CONTACTSJSON } from '../../../core/data/contacts_json';
import { ERRORJSON } from '../../../core/data/error_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { environment } from 'src/environments/environment';
import { TaskBoardComponent } from '../../clients/client-managment/task-board/task-board.component';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomFiltersComponent } from '../../shared/custom-filters/custom-filters.component';
import { RANDOM_CHANNEL_COLORS } from '../../../core/data/colorcode';
import { Subject, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { filterConstant } from 'src/app/core/data/filter-storing-data';
import { AuthService } from 'src/app/core/service/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ContactType } from 'src/app/core/data/crm_modal_json';
import { ExportItemSelectionComponent } from '../../shared/export-item-selection/export-item-selection.component';
import { TwilioVoiceService } from 'src/app/core/service/twilio-voice.service';
import { CustomFieldsComponent } from '../../settings/custom-fields/custom-fields.component';
import { ObjectModalComponent } from '../../shared/object-modal/object-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, AfterViewInit {
  contactsJson = CONTACTSJSON[0];
  errorJson = ERRORJSON[0];
  @Input() companyId: any;
  @Input() isInnerScreen!: boolean;
  previewModal!: NgbModalRef;
  modalRef!: NgbModalRef;
  currentPage = 1;
  pageEvent!: PageEvent;
  presignedurl = environment.preSendUrl;
  search_global!: string;
  leftMaxWidth = 200;
  @ViewChild('paginator') paginator!: MatPaginator;
  submitted = false;
  contactForm!: FormGroup;
  public selection = new SelectionModel<any>(true, []);
  files: File[] = [];
  contactsObj: any = {};
  contactListArray: any = [];
  selected_group: any;
  page_size = 20;
  selectedSortOptions: any;
  channel_color_codes = RANDOM_CHANNEL_COLORS;
  initial_color_code_index = 0;
  sortByDropDown = [
    {
      label: 'Contact Name',
      key: 'first_name'

    },
    {
      label: 'Company Name',
      key: 'company'

    },
    {
      label: 'Email',
      key: 'email'

    },
    {
      label: 'Created On',
      key: 'created_at'

    }
  ];
  picked_out_color: any;
  select_type = [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Today',
      value: 'today'
    },
    {
      label: 'Yesterday',
      value: 'yesterday'
    },
    {
      label: 'Last 7 days',
      value: 'last_7_days'
    },
    {
      label: 'Iframe',
      value: 'iframe'
    },
    {
      label: 'Chatbot',
      value: 'chatbot'
    },
    {
      label: 'Facebook',
      value: 'facebook'
    },
    {
      label: 'Manual',
      value: 'manual'
    },
    // {
    //   label: 'Forms',
    //   value: 'web_forms'
    // },
    {
      label: 'Web Forms',
      value: 'web_forms'
    },
    {
      label: 'Call',
      value: 'call'
    },
    {
      label: 'Google',
      value: 'google'
    },
    {
      label: 'Instagram',
      value: 'instagram'
    },
    {
      label: 'Whatsapp',
      value: 'whatsapp'
    },
    {
      label: 'Bulk Upload',
      value: 'bulk_upload'
    },
    {
      label: 'Employee Reference',
      value: 'employee_reference'
    },
    {
      label: 'Just Dial',
      value: 'just_dial'
    },
    {
      label: 'Sulekha',
      value: 'sulekha'
    },
    {
      label: 'Classified Listing',
      value: 'classified_listing'
    },
    {
      label: 'Word of Mouth',
      value: 'word_of_mouth'
    },
    {
      label: 'Walk in Customer',
      value: 'walk_in_customer'
    },
    {
      label: 'Preview',
      value: 'preview'
    },
    {
      label: 'Meetings',
      value: 'meetings'
    },
    {
      label: 'Zapier',
      value: 'zapier'
    }
  ];
  filter: any = this.select_type[0].value;
  selected_filter!: string;
  selected_filter_value: any;
  tableColumns: any[] = [];
  filtersLables = [
    {
      position: 27,
      lableName: 'Contact Id',
      checked: true
    },
    {
      position: 1,
      lableName: 'Contact Name',
      checked: true
    },
    {
      position: 2,
      lableName: 'Email',
      checked: true
    },
    {
      position: 3,
      lableName: 'Phone Number',
      checked: true
    },
    {
      position: 4,
      lableName: 'Contact Type',
      checked: true
    },
    {
      position: 5,
      lableName: 'Next Activity',
      checked: false
    },
    {
      position: 6,
      lableName: 'Company Name',
      checked: true
    },
    {
      position: 7,
      lableName: 'Owner',
      checked: true
    },
    {
      position: 8,
      lableName: 'Channel',
      checked: true
    },
    {
      position: 9,
      lableName: 'Created On',
      checked: true
    },
    {
      position: 10,
      lableName: 'First Name',
      checked: false
    },
    {
      position: 11,
      lableName: 'Last Name',
      checked: false
    },
    {
      position: 12,
      lableName: 'Last Activity Date & Time',
      checked: false
    },
    {
      position: 13,
      lableName: 'Modified by',
      checked: false
    },
    {
      position: 14,
      lableName: 'Modified Date & Time',
      checked: false
    },
    {
      position: 15,
      lableName: 'Mailing Street',
      checked: false
    },
    {
      position: 16,
      lableName: 'Mailing Address',
      checked: false
    },
    {
      position: 17,
      lableName: 'Mailing City',
      checked: false
    },
    {
      position: 18,
      lableName: 'Mailing Country',
      checked: false
    },
    {
      position: 19,
      lableName: 'Mailing State',
      checked: false
    },
    {
      position: 20,
      lableName: 'Shipping Address',
      checked: false
    },
    {
      position: 21,
      lableName: 'Shipping Street',
      checked: false
    },
    {
      position: 22,
      lableName: 'Shipping City',
      checked: false
    },
    {
      position: 23,
      lableName: 'Shipping Country',
      checked: false
    },
    {
      position: 24,
      lableName: 'Zip Code',
      checked: false
    },
    {
      position: 25,
      lableName: 'Title',
      checked: false
    },
    {
      position: 26,
      lableName: 'Actions',
      checked: true
    }
  ];

  grouplistArray = [];

  importModalType: any;
  enableImportBtn = false;
  selectedFile: any;
  display_no_data = false;
  loggedInUser: any;
  staffMembers: any = [];
  companiesListArray: any = [];
  columnFilters!: FormGroup;
  showExtraFilters = false;
  customFiltersArray: any = [];
  listOfTags: any = [];
  departmentsList: any = [];
  companyListArray: any = [];
  group_id!: string;
  filters_modal!: NgbModalRef;
  column_filters: any[] = [];
  pageIndex = 0;
  isSelected: any = {
    flag: false,
    pageIndex: [],
    multiple: false
  };
  checkBoxStorageKeeper: any = {};
  tableSkeletonLoader = true;
  searchSubject: Subject<string> = new Subject<string>();
  operation = 'CREATE';
  stepper = 1;
  imported_id: any;
  featuresData: any;
  activeId = 1;
  contact_type: any;
  inActivetableColumns: any;
  updatedField = {};
  tableColumnResponse: any;
  columnFilterSearch = '';
  columnFieldSearch: any[] = [];
  originalTableColumnLists: any[] = [];
  tableColumnNoResFound = false;
  tagsListArray: any[] = [];
  contactTypeItems = [{ id: 'LEAD', name: 'Lead' }, { id: 'VENDOR', name: 'Vendor' }, { id: 'CANDIDATE', name: 'Candidate' }, { id: 'VISITOR', name: 'Visitor' }];
  bulkimport_addtoList = false;
  searchContactList$ = new Subject();
  fromNumber: any;
  callFeature = false;
  list_filter_enable = false;
  list_max_limit = false;
  refresh_list = false;
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;
  private customFieldsComponentRef!: ComponentRef<CustomFieldsComponent>;
  object_type: any;
  object_data: any;
  tabDetails: any[] = [];
  status_tab: any;
  show_type: any;

  constructor(private modalService: NgbModal,
    private service: CrmService,
    private confirmationMsz: ConfirmationMszService,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private renderer: Renderer2,
    private twilioService: TwilioVoiceService,
    private toastrService: ToastrService
  ) {
    this.searchSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      if (searchTerm.length >= 2) {
        if (this.activeId !== 3) {
          this.filter = this.select_type[0].value;
          this.selected_filter_value = '';
          this.selected_filter = '';
          if (this.selectedSortOptions || this.column_filters.length > 0) {
            this.selectedSortOptions = null;
            this.column_filters = [];
            // this.filterFormSubmit(payload, 1, 'ACTIVE'); Optional Call
          }
          this.currentPage = 1;
          this.pageIndex = 0;
          this.getContactsBySearch(searchTerm);
          this.updateFilterState();
        } else {
          this.searchContactList$.next(searchTerm);
        }
      } else if (searchTerm.length < 1) {
        if (this.activeId !== 3) {
          this.page_size = 20;
          this.getContactsList('clear', 1);
          this.updateFilterState();
        }
        (this.activeId === 3) && this.searchContactList$.next(searchTerm);
      }
    });
    this.twilioService.twilioConnection.subscribe((connection: any) => {
      this.callFeature = connection;
    });
  }

  ngAfterViewInit() {
    if (!this.isInnerScreen) {
      setTimeout(() => {
        const rightControlsWidth = document.querySelector('.right-controls-sec');
        const leftControlsWidth = document.querySelector(".leftMaxWidth");
        rightControlsWidth && (this.leftMaxWidth = rightControlsWidth.clientWidth);
        leftControlsWidth && (this.renderer.setStyle(leftControlsWidth, `max-width`, `calc(100% - ${this.leftMaxWidth}px)`));
      }, 500);
    }
  }
  ngOnInit() {
    // this.tableColumns = this.filtersLables.filter((elem: any) => elem.checked);
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getFeatues();
    // this.dataSource.paginator = this.paginator;
    this.route.queryParams.subscribe(async (params: any) => {
      this.status_tab = '';
      this.object_type = params?.object_type || '';
      this.show_type = params?.show_type || '';
      if (this.object_type) {
        await this.getAllContactFields();
        this.getObjectbyId();
        this.activeId = 1;
      }
      if (params['contact_activeId']) this.activeId = +params['contact_activeId'];
      if (params['groupid']) {
        this.group_id = params['groupid'];
        this.getContactsBasedonGroupId(1, 10);
      } else {
        if (filterConstant.filterInfoData && filterConstant.filterInfoData.page_name === 'contact' && this.activeId === 1) {
          this.updateCurrentPageFiltrData();
        } else if (this.activeId === 1) {
          this.getContactsList('clear', 1);
          this.updateFilterState();
        } else if (this.activeId === 2) {
          this.getInactiveContacts();
          this.updateFilterState();
        } else if (this.activeId >= 4) {
          this.status_tab = this.tabDetails[0].options.filter((ele: any, index: number) => index === this.activeId - 4);
          this.getContactsList('clear', 1);
        }
      }
      this.initilizeFiltersForm();
      this.getColoumsList();
    });
    this.contactForm = new FormGroup({
      email: new FormArray([])
    });
    //this.addEmailFields();
    this.filtersJsonForm();
    this.getTags();
    this.getContactTypes();
    this.getFromNumbers();
  }

  getTags() {
    this.settingsService.getTags('contact').subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }
  getContactTypes() {
    this.service.getContactTypes().subscribe((resp: any) => {
      this.contact_type = resp.data;
    });
  }

  getObjectbyId() {
    this.authService.getObjectTypeById(this.object_type).subscribe((resp: any) => {
      this.object_data = resp.data;
    });
  }

  updateFilterState() {
    filterConstant.filterInfoData = {
      mainFilter: this.column_filters,
      sortValue: this.selectedSortOptions,
      dropdown_filter: this.selected_filter,
      dropdown_filter_value: this.selected_filter_value,
      searchKey: this.search_global,
      page_name: 'contact',
      currentPageIndex: this.currentPage
    };
  }


  updateCurrentPageFiltrData() {
    this.column_filters = filterConstant.filterInfoData.mainFilter;
    this.selectedSortOptions = filterConstant.filterInfoData.sortValue;
    this.selected_filter = filterConstant.filterInfoData.dropdown_filter;
    this.selected_filter_value = filterConstant.filterInfoData.dropdown_filter_value;
    this.search_global = filterConstant.filterInfoData.searchKey;
    this.currentPage = filterConstant.filterInfoData.currentPageIndex;
    this.pageIndex = filterConstant.filterInfoData.currentPageIndex - 1;
    this.filter = this.selected_filter || 'all';
    this.getListsBasedOnFilter();
  }

  onFilterChange(e: any) {
    this.search_global = '';
    if (this.selectedSortOptions || this.column_filters.length > 0) {
      this.selectedSortOptions = null;
      this.column_filters = [];
      // this.filterFormSubmit(payload, 1, 'ACTIVE'); Optional call
    }
    this.filter = e;
    if (this.filter === 'today') {
      this.selected_filter = 'today';
      this.selected_filter_value = true;
    } else if (this.filter === 'yesterday') {
      this.selected_filter = 'yesterday';
      this.selected_filter_value = true;
    } else if (this.filter === 'last_7_days') {
      this.selected_filter = 'last_7_days';
      this.selected_filter_value = true;
    } else if (this.filter === 'iframe') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'iframe';
    } else if (this.filter === 'chatbot') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'bot';
    } else if (this.filter === 'manual') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'manual';
    } else if (this.filter === 'facebook') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'facebook';
    } else if (this.filter === 'web_forms') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'web_forms';
    } else if (this.filter === 'call') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'call';
    } else if (this.filter === 'google') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'google';
    } else if (this.filter === 'instagram') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'instagram';
    } else if (this.filter === 'whatsapp') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'whatsapp';
    } else if (this.filter === 'bulk_upload') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'bulk_upload';
    } else if (this.filter === 'employee_reference') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'employee_reference';
    } else if (this.filter === 'just_dial') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'just_dial';
    } else if (this.filter === 'sulekha') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'sulekha';
    } else if (this.filter === 'classified_listing') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'classified_listing';
    } else if (this.filter === 'word_of_mouth') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'word_of_mouth';
    } else if (this.filter === 'walk_in_customer') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'walk_in_customer';
    } else if (this.filter === 'preview') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'preview';
    } else if (this.filter === 'meetings') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'meeting';
    } else {
      this.selected_filter = '';
      this.selected_filter_value = '';
    }
    this.updateFilterState();
    // console.log(this.selected_filter, this.selected_filter_value);
    this.getContactsList('clear', 1, this.pageEvent?.pageSize || 20, this.selected_filter, this.selected_filter_value);
  }

  getContactsBasedonGroupId(page: number, pageSize: number) {
    this.service.getContactsBasedonGroupId(this.group_id, page, pageSize).subscribe((result: any) => {
      this.contactsObj = result;
      this.contactListArray = result.data;
      // this.selection.clear();
      if (this.isSelected.flag === true && this.getPageIndexValues() > -1) {
        this.masterToggle();
      }
    });
  }

  getContactsList(pageIndexClear: string, page: any, page_size?: number, filter_param?: string, filter?: any) {
    const is_active = this.activeId === 2 ? 'False' : 'True';
    this.tableSkeletonLoader = true;
    this.service.getAllContacts(page, this.object_type, filter_param, filter, this.companyId, page_size, is_active,this.status_tab).subscribe({
      next: (resp: any) => {
        this.tableSkeletonLoader = false;
        if (pageIndexClear === 'clear') {
          this.pageIndex = 0;
          this.currentPage = 1;
          // this.clearSelection();
        }
        this.contactsObj = resp;
        this.contactListArray = resp.data;
        this.usersAccess(this.contactListArray);
        // this.selection.clear();
        // console.log("vmjvjdjfj"+this.isSelected.flag, this.getPageIndexValues());
        // if (this.isSelected.flag === true && this.getPageIndexValues() > -1) {
        this.masterToggle();
        // }
        if (this.contactListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }

        this.organisationMembersList();
        // this.getCompaniesList();
      },
      error: () => {
        this.selectedSortOptions = null;
        this.column_filters = [];
        this.contactListArray = [];
        this.search_global = '';
        this.selected_filter_value = '';
        this.selected_filter = '';
        this.tableSkeletonLoader = false;
      }
    });
  }

  usersAccess(contactDetails: any) {
    let access = false;
    contactDetails.forEach((elem: any) => {
      let assigneeNameIndex = -1;
      if (elem.assignee.length > 0) {
        assigneeNameIndex = elem?.assignee?.findIndex((element: any) => this.loggedInUser.data.id === element);
      }
      if (assigneeNameIndex > -1 || elem?.owner_info?.id === this.loggedInUser?.data?.id || this.loggedInUser?.data?.role === 'ADMIN') {
        access = true;
        elem['allowAccess'] = access;
      } else {
        // const departmentsList = [...elem.assignee_department, elem.owner_department];
        // this.settingsService.getStaff_infoById(this.loggedInUser.data.id).subscribe({
        //   next: (resp: any) => {
        //     elem['allowAccess'] = (resp.data?.department && departmentsList.includes(resp.data?.department) && resp.data?.role_info?.name === "MANAGER") ? true : false;
        //   }
        // });
        elem['allowAccess'] = false;
      }
    });
  }

  openTaskBoardPreview() {
    this.previewModal = this.modalService.open(TaskBoardComponent, { size: 'lg', scrollable: true });
    this.previewModal.componentInstance.crm_type = 'contact';
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
      this.pageIndex = e.pageIndex++;
    } else if (this.pageEvent.pageIndex === 0) {
      this.currentPage = 1;
      this.pageIndex = 0;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
      this.pageIndex = e.pageIndex--;
    }
    if (this.group_id) {
      this.getContactsBasedonGroupId(this.currentPage, this.pageEvent.pageSize);
    } else if (this.activeId === 2) {
      this.getInactiveContacts();
    } else {
      this.updateFilterState();
      this.getListsBasedOnFilter();
    }
  }

  getListsBasedOnFilter() {
    if (this.search_global?.length >= 3) {
      this.getContactsBySearch(this.search_global);
    } else if (Object.keys(this.column_filters).length > 0 || this.selectedSortOptions?.sort_order) {
      const payload = {
        filter: this.column_filters,
        sort_by: this.selectedSortOptions?.sort_by,
        sort_order: this.selectedSortOptions?.sort_order,
        object_type: this.object_type
      };
      this.filterFormSubmit(payload, this.currentPage);
    } else if (this.selected_filter && this.selected_filter_value) {
      this.getContactsList('pgn', this.currentPage, this.pageEvent.pageSize, this.selected_filter, this.selected_filter_value);
    } else {
      this.getContactsList('pgn', this.currentPage, this.pageEvent?.pageSize);
    }
  }

  removeContact(contactId: string) {
    this.service.deleteContact(contactId, { object_type: this.object_type }).subscribe({
      next: () => {
        this.getListsBasedOnFilter();
      }
    });
  }

  confirmDelete(contactId: string) {
    this.confirmationMsz.customDeleteView({ title: 'Are You Sure ?', text: 'Selected contact will be moved to In active', button: 'Yes' }).then((result) => {
      if (result.value) {
        this.removeContact(contactId);
      }
    });
  }

  get f() {
    return this.contactForm.controls;
  }
  get email(): FormArray {
    return this.contactForm.get('email') as FormArray;
  }

  // addEmailFields() {
  //   this.email.push(this.newEmail());
  // }

  removeEmailField(i: number) {
    this.email.removeAt(i);
  }

  newEmail(email: any): FormGroup {
    return new FormGroup(
      {
        email: new FormControl(email, Validators.required)
      }
    );
  }

  addOptionToList = (option: any) => ({ id: '', name: option });

  addToList() {
    const selectedContacts: any[] = [];
    this.selection.selected.filter((item: any) => {
      selectedContacts.push(item.id);
    });
    const body = {
      group_id: this.selected_group.id,
      group_name: this.selected_group.name,
      contacts: selectedContacts,
      list_filter_enable: this.list_filter_enable,
      list_max_limit: this.list_max_limit,
      object_type: this.object_type
    };
    if (body.group_id) {
      this.service.updateContactsToGroup(body).subscribe(() => {
        if (this.activeId === 3) this.refresh_list = true;
        else this.refresh_list = false;
        this.modalService.dismissAll();
        this.clearSelection();
      });
    } else {
      this.service.postGroup(body).subscribe((resp: any) => {
        this.modalService.dismissAll();
        this.selected_group.id = resp.data.id;
        if (this.activeId === 3) this.refresh_list = true;
        else this.refresh_list = false;
        if (this.list_filter_enable) this.openFiltersModal('filters', 'add_to_list');
        this.clearSelection();
      });
    }
  }

  onListchange() {
    if (this.selected_group.id) this.list_filter_enable = this.selected_group?.list_filter_enable;
    else this.list_filter_enable = false;
  }

  getColoumsList() {
    this.service.getTableColoums('contact', this.object_type).subscribe((resp: any) => {
      this.tableColumnResponse = resp.data;
      this.tableColumns = resp.data.customized_fields;
      this.originalTableColumnLists = JSON.parse(JSON.stringify(this.tableColumns));
    });
  }
  onUpdateTableColumns() {
    const payload = JSON.parse(JSON.stringify(this.tableColumnResponse));
    Object.assign(payload.customized_fields, this.originalTableColumnLists);
    this.service.updateTableColumn(payload).subscribe((data: any) => {
      console.log(data);
    });
  }

  selectFilterLable(event: any, item: any, index: number) {
    // if (!['display_id', 'first_name', 'email', 'contact_type', 'tags'].includes(item?.field_name)) {
    const obj = JSON.parse(JSON.stringify(item));
    obj.status = event.checked;
    this.tableColumns[index] = obj;
    this.originalTableColumnLists[index] = obj;
    // }
    // Object.assign(this.originalTableColumnLists, { [item.key]: event.checked });
    // Object.assign(this.tableColumns, { [item.key]: event.checked });
  }

  onColumnFilterSearch(event: any) {
    if (this.columnFilterSearch.length > 1) {
      const searchResult: any[] = [];
      this.tableColumns = this.originalTableColumnLists.filter((item: any) => (item.field_name.split('_').join(" ").toLocaleLowerCase()).includes(this.columnFilterSearch.toLocaleLowerCase()));
      if (this.tableColumns && this.tableColumns.length > 0) {
        this.tableColumnNoResFound = false;
        this.columnFieldSearch = [];

        searchResult.forEach((elem: any) => {
          Object.assign(this.columnFieldSearch, elem);
        });
      } else {
        this.tableColumnNoResFound = true;
        this.columnFieldSearch = [];
        this.tableColumns = [];
      }
      // Object.assign(this.tableColumns,this.columnFieldSearch);
      // this.tableColumns = JSON.parse(JSON.stringify(this.columnFieldSearch));
    } else {
      this.tableColumns = JSON.parse(JSON.stringify(this.originalTableColumnLists));
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    // if (event.currentIndex > 4) {
    moveItemInArray(this.tableColumns, event.previousIndex, event.currentIndex);
    moveItemInArray(this.originalTableColumnLists, event.previousIndex, event.currentIndex);
    // }
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  openDialog(addtolistModel: any) {
    this.getList();
    this.modalService.open(addtolistModel, { size: 'md' });
  }

  getList() {
    this.service.getGroupList(this.object_type).subscribe((resp: any) => {
      this.grouplistArray = resp.data;
    });
  }

  openContactsModalDialog(contact?: any) {
    this.modalRef = this.modalService.open(ObjectModalComponent, { size: 'lg', scrollable: true });
    // this.modalRef = this.modalService.open(ContactsModalComponent, { size: 'lg', scrollable: true });
    this.modalRef.componentInstance.contact = contact;
    this.modalRef.componentInstance.object_type = this.object_type;
    this.modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.selection.clear();
        if (contact) {
          this.getListsBasedOnFilter();
        } else {
          this.getContactsList('clear', 1);
        }

      }
    });
  }

  importEexportModalDialog(importExportModel: any, type: any) {
    // this.getList();
    this.modalService.open(importExportModel, { size: 'md', backdrop: 'static', scrollable: true });
    this.importModalType = type;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.contactListArray.length;
    return numSelected === numRows;
  }

  toggleVisibility(e: any) {
    if (e.target.checked === true) {
      this.bulkimport_addtoList = true;
    } else {
      this.bulkimport_addtoList = false;
    }
  }

  masterToggle(currentPage?: number | string, event?: any) {
    const masterIndex = this.isSelected.pageIndex.findIndex((elem: any) => elem.page === currentPage);
    if ((event && !event.checked)) {
      // if (this.isSelected.pageIndex.length > 1 && currentPage) {
      //   if (testIndex > -1) {
      this.contactListArray.forEach((row: any) => {
        delete this.checkBoxStorageKeeper[row.id];
      });
      this.selection.clear();
      (masterIndex > -1) && this.isSelected.pageIndex.splice(masterIndex, 1);
      // }
      // } else {
      //   this.clearSelection();
      //   this.checkBoxStorageKeeper = {};
      // }
    } else if (typeof currentPage === 'number' && event && event.checked) {
      // this.selection.clear();
      this.contactListArray.forEach((row: any) => {
        const exsistObj = {
          [row.id]: row
        };
        Object.assign(this.checkBoxStorageKeeper, exsistObj);
        this.selection.select(row);
      });
      const index = this.isSelected.pageIndex.findIndex((elem: any) => elem.page === currentPage);
      if (index > -1) {
        this.isSelected.pageIndex[index].qnty = this.contactListArray?.length;
      } else {
        this.isSelected.pageIndex.push({ page: currentPage, qnty: this.contactListArray?.length });
        this.isSelected.flag = true;
      }

      // this.isSelected.pageIndex.push({ page: currentPage, qnty: this.contactListArray?.length });
      // this.isSelected.flag = true;

    } else {
      // this.selection.clear();
      this.contactListArray.forEach((row: any) => {
        if (Object.prototype.hasOwnProperty.call(this.checkBoxStorageKeeper, row.id)) {
          this.selection.select(row);
        }
      });
    }
    this.updateIsSelectedMultipleKey();
  }

  individualRecordToogle(item: any) {
    const exsistObj = {
      [item.id]: item
    };
    if (Object.prototype.hasOwnProperty.call(this.checkBoxStorageKeeper, item.id)) {
      delete this.checkBoxStorageKeeper[item.id];
      const index = this.getPageIndexValues();
      if (index > -1) {
        this.isSelected.pageIndex[index].qnty -= 1;
        if (this.isSelected.pageIndex[index].qnty <= 0) {
          this.isSelected.pageIndex.splice(index, 1);
        }
      }
      if (this.isSelected.pageIndex.length === 0) {
        this.isSelected.flag = false;
      }
    } else {
      Object.assign(this.checkBoxStorageKeeper, exsistObj);
      const index = this.getPageIndexValues();
      if (index > -1) {
        this.isSelected.pageIndex[index].qnty += 1;
        if (this.isSelected.pageIndex[index].qnty <= 0) {
          this.isSelected.pageIndex.splice(index, 1);
        }
        if (this.isSelected.pageIndex.length === 0) {
          this.isSelected.flag = false;
        }
      } else {
        this.isSelected.flag = true;
        this.isSelected.pageIndex.push({ page: this.currentPage, qnty: 1 });
      }
    }
    this.updateIsSelectedMultipleKey();
  }

  updateIsSelectedMultipleKey() {
    (Object.keys(this.checkBoxStorageKeeper).length > 1) ? (this.isSelected.multiple = true) : (this.isSelected.multiple = false);
  }

  getPageIndexValues() {
    return this.isSelected.pageIndex.findIndex((elem: any) => elem.page === this.currentPage);
  }


  clearSelection() {
    this.selection.clear();
    this.isSelected.flag = false;
    this.isSelected.pageIndex = [];
    this.isSelected.multiple = false;
    this.checkBoxStorageKeeper = {};
  }

  // clientDetailsPopup() {
  //   this.modalRef = this.modalService.open(DetailsModalComponent, {
  //     modalDialogClass: 'crm-modal',
  //     centered: false,
  //     size: 'xl'
  //   });
  // }

  checkedContacts() {
    console.log(this.selection.selected); // list of checked contacts
  }

  onFileChange(event: any) {
    this.enableImportBtn = true;
    this.selectedFile = event.target.files[0];
  }

  importContacts() {
    console.log(this.selected_group, "selected_group");

    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    formData.append('group_name', this.selected_group?.name || "");
    formData.append('object_type', this.object_type || "");
    this.service.postCSVFile(formData).subscribe((resp: any) => {
      this.stepper = 2;
      this.imported_id = resp.data.id;
      this.selected_group.id = resp.data.group_id;
    });
  }

  closeImportModal() {
    this.modalService.dismissAll('cross click');
    this.stepper = 1;
  }

  fieldsMapping() {
    console.log(this.selected_group);

    this.service.updateCSVFile({ id: this.imported_id, operation: this.operation, type: 'contact', is_active: true }).subscribe(() => {
      this.modalService.dismissAll('cross click');
      this.router.navigate(['/crm/import'], { queryParams: { module: 'contact', id: this.imported_id, group_id: this.selected_group?.id, object_id: this.object_type } });
    });
  }

  // search(name: any) {
  //   this.searchSubject.next(name);
  // }

  getContactsBySearch(name: string) {
    const is_active = this.activeId === 2 ? 'False' : 'True';
    this.tableSkeletonLoader = true;
    this.service.getContactsBySearch(name, this.currentPage, is_active, this.object_type).subscribe({
      next: (resp: any) => {
        this.contactsObj = resp;
        this.tableSkeletonLoader = false;
        // this.page_size = this.contactsObj.total;
        this.contactListArray = resp.data;
        // this.selection.clear();
        // if (this.isSelected.flag === true && this.getPageIndexValues() > -1) {
        this.masterToggle();
        // }
        if (this.contactListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      },
      error: () => {
        this.selectedSortOptions = null;
        this.tableSkeletonLoader = false;
        this.column_filters = [];
        this.search_global = '';
        this.selected_filter_value = '';
        this.selected_filter = '';
        this.getContactsList('clear', 1);
      }
    });
  }

  downloadContacts(event: any) {
    // console.log(this.checkedContacts());
    let count = this.contactsObj.total;
    const selectedCheckbox = Object.keys(this.checkBoxStorageKeeper).length;
    if (this.checkBoxStorageKeeper && selectedCheckbox > 0) {
      count = selectedCheckbox;
    }
    const modalRef = this.modalService.open(ExportItemSelectionComponent, { size: 'md', centered: true });
    modalRef.componentInstance.payload = {
      type: 'contact',
      object_type: this.object_type,
      data: this.tableColumns,
      disabledItems: ['display_id', 'first_name', 'email', 'contact_type', 'tags'],
      checkBoxStorageKeeper: this.checkBoxStorageKeeper,
      message: `<b class='blue-class'>${count}</b> ${count < 2 ? this.object_data.name : this.object_data?.name_plural || this.object_data.name} will be exported`
    };
    // const outputContacts: any = [];
    // if (this.isSelected.flag === false) {
    //   this.service.getAllContactsForExport().subscribe((resp: any) => {
    //     resp.data.forEach((contact: any) => {
    //       outputContacts.push({
    //         "Display Id": contact.display_id,
    //         "Name": contact.first_name + " " + contact.last_name,
    //         "Email": contact.email,
    //         "CCode": contact.ccode,
    //         "Phone": contact.phone_number
    //       });
    //     });
    //     this.service.jsonToCsv(outputContacts, "Contacts");
    //   });
    // } else {
    //   Object.values(this.checkBoxStorageKeeper).forEach((contact: any) => {
    //     outputContacts.push({
    //       "Name": contact.first_name + " " + contact.last_name,
    //       "Email": contact.email,
    //       "CCode": contact.ccode,
    //       "Phone": contact.phone_number
    //     });
    //   });
    //   this.service.jsonToCsv(outputContacts, "Contacts");
    // }
  }

  downloadSampleFile() {
    this.service.downloadSample('Contacts');
  }

  getCompaniesList() {
    this.service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.companiesListArray = resp.data;
      }
    });
  }

  onUpdatePopOver(event: any, data: any) {
    if(this.object_data?.identifier === 'people') {
      this.toastrService.error("You can't update the details");
      return;
    }
    let input: any = { id: data.id, custom_contact_fields: [] };

    if (event.type === 'dropdown') {
      if (event.labelName === 'Owner') {
        input.info = {
          "owner_info": {
            "id": event.value.id,
            "fname": event.value.fname,
            "lname": event.value.lname
          }
        };
        input.owner = event.value.id;
        input.owner_department = event.value.department_info?.id ? event.value.department_info?.id : '';
        // this.staffMembers.forEach((staff: any) => {
        //   if (staff.id === event.value.id) input.owner_department = staff.department_info.id;
        // });
      } else if (event.labelName === 'Company Name') {
        input.company_id = event.value.id;
        input.company_name = event.value.name;
      } else if (event.labelName === 'Contact Type') {
        input.contact_type = event.value.id;
      } else if (event.labelName === 'Tag') {
        input[event.formName] = event.value.id;
      } else if (event.labelName === 'contact_type') {
        input[event.formName] = event.value.id;
      }
    } else
      input[event.formName] = event.value;
    if (event?.cCode) {
      input['ccode'] = event.cCode;
    }
    if(data?.custom_contact_fields?.custom_contact_template){
      data.custom_contact_fields.contact = data.id;
      data.custom_contact_fields.data = event.value;
      data.custom_contact_fields = [data.custom_contact_fields];
      input = data;
    }

    this.service.patchContact(input).subscribe(() => {
      this.getListsBasedOnFilter();
      // this.getContactsList('clear', 1, this.selected_filter, this.selected_filter_value);
    });
  }

  async getAllContactFields(): Promise<void> {
    this.customFiltersArray = [];
    const resp: any = await firstValueFrom(this.service.getContactsFileds(this.object_type));
    this.tabDetails = resp.data?.custom_contact_templates.filter((ele: any) => ele.data_type === "TAB");
    if(this.tabDetails.length > 0) this.tabDetails[0].options_array = this.tabDetails[0].options.map((item: any) => ({ label: item, value: item }));    
    for (const [key, value] of Object.entries(resp.data)) {
      let formatted_obj: any = {};
      let replaced_text: any;
      if (key === 'custom_contact_templates') {
        const custom_fields_list: any = value;
        custom_fields_list.forEach((element: any) => {
          formatted_obj = {};
          formatted_obj['id'] = element.id;
          formatted_obj['display_label'] = element.label;
          formatted_obj['custom_input'] = true;
          formatted_obj['data_type'] = element.data_type;
          formatted_obj['is_mandatory'] = element.is_mandatory;
          formatted_obj['options'] = element.options;
          if (element?.on_form) {
            this.customFiltersArray.push(formatted_obj);
          }
        });
      } else if (key === 'id' || key === 'organisation' || key === 'contact_fields_order' || key === 'display_picture') {
        console.log("");

      } else {
        const system_fields_value: any = value;
        if (key.includes('_')) {
          replaced_text = key.replace('_', " ");
        } else {
          replaced_text = key;
        }
        formatted_obj['id'] = key;
        formatted_obj['display_label'] = replaced_text;
        formatted_obj['custom_input'] = false;
        formatted_obj['data_type'] = system_fields_value.data_type;
        formatted_obj['is_mandatory'] = system_fields_value.is_mandatory;
        if (system_fields_value?.on_form) {
          this.customFiltersArray.push(formatted_obj);
        }
      }

    }
    const created_at = {
      id: 'created_at',
      is_mandatory: true,
      display_label: 'Created on',
      custom_input: false,
      data_type: 'DATE_TIME'
    };
    const updated_at = {
      id: 'updated_at',
      is_mandatory: true,
      display_label: 'Updated on',
      custom_input: false,
      data_type: 'DATE_TIME'
    };
    this.customFiltersArray.push(created_at);
    this.customFiltersArray.push(updated_at);
    // }
    // });
  }

  forCustomFieldStatus(field: any, index: number) {
    console.log(field, index);
    this.filter_of_column.controls[index].patchValue({
      custom_field: field.custom_input
    });
    if (field.id === 'owner' || field.id === 'assignee') {
      this.organisationMembersList();
    } else if (field.id === 'tags') {
      this.getTagsOfContact();
    } else if (field.id === 'assignee_department') {
      this.getDepartmentsList();
    }
  }

  getFilters() {
    this.showExtraFilters = true;
    this.getAllContactFields();
    this.initilizeFiltersForm();
    this.filtersJsonForm();
  }

  resetFilters() {
    this.columnFilters.reset();
    this.initilizeFiltersForm();
    this.filtersJsonForm();
    this.getContactsList('clear', 1);
  }

  clearCustomFilter() {
    this.column_filters = [];
    this.selectedSortOptions = null;
    this.updateFilterState();
    this.getContactsList('clear', 1);
  }

  initilizeFiltersForm() {
    this.columnFilters = new FormGroup({
      filter: new FormArray([]),
      sort_by: new FormControl(''),
      sort_order: new FormControl('desend')
    });
  }

  get filter_of_column() {
    return this.columnFilters.controls["filter"] as FormArray;
  }

  filtersJsonForm() {
    const filter_form: any = new FormGroup({
      column: new FormControl(''),
      data: new FormControl(''),
      operation: new FormControl(''),
      custom_field: new FormControl(''),
      search: new FormControl('')
    });

    this.filter_of_column.push(filter_form);
  }

  deleteFilter(index: number) {
    this.filter_of_column.removeAt(index);
  }

  filterFormSubmit(filters_data: any, page: number) {
    // this.column_filters = filters_data;
    this.tableSkeletonLoader = true;
    let is_active = 'True';
    if (this.activeId === 2) {
      is_active = 'False';
    }
    this.service.getContactCustomFilteredData(filters_data, page ? page : 1, is_active).subscribe({
      next: (resp: any) => {
        this.tableSkeletonLoader = false;
        this.filter = this.select_type[0].value;
        this.contactsObj = resp;
        this.contactListArray = resp.data;
        // this.contactListArray.forEach((elem: any) => {
        //   const access = this.usersAccess(elem);
        //   elem['allowAccess'] = access;
        // });
        this.usersAccess(this.contactListArray);
        // this.selection.clear();
        // if (this.isSelected.flag === true && this.getPageIndexValues() > -1) {
        this.masterToggle();
        // }
        if (this.contactListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      },
      error: () => {
        this.selectedSortOptions = null;
        this.column_filters = [];
        this.search_global = '';
        this.selected_filter_value = '';
        this.selected_filter = '';
        this.getContactsList('clear', 1);
      }
    });
  }

  searchTriggerConatct(change: any) {
    if (change.term.length >= 3) {
      this.service.getContactsBySearch(change.term).subscribe({
        next: (resp: any) => {
          this.contactListArray = resp.data;
          this.usersAccess(this.contactListArray);
          // this.contactListArray.forEach((elem: any) => {
          //   const access = this.usersAccess(elem);
          //   elem['allowAccess'] = access;
          // });
        }
      });
    }
  }

  searchTriggerCompany(change: any) {
    if (change.term.length >= 3) {
      this.service.getCompaniesBySearch(change.term).subscribe({
        next: (resp: any) => {
          this.companyListArray = resp.data;
        }
      });
    }
  }

  getTagsOfContact() {
    this.settingsService.getTags('contact').subscribe({
      next: (resp: any) => {
        this.listOfTags = resp.data;
      }
    });
  }

  getDepartmentsList() {
    this.settingsService.getTeams(false).subscribe((resp: any) => {
      this.departmentsList = resp.data;
    });

  }

  openFiltersModal(type: string, apiCall?: any) {

    if (type === 'sort') {
      this.filters_modal = this.modalService.open(CustomFiltersComponent, { size: 'md', centered: true });
    } else {
      this.filters_modal = this.modalService.open(CustomFiltersComponent, { size: 'lg', scrollable: true, centered: true });
    }
    this.filters_modal.componentInstance.filters_custm = this.column_filters;
    this.filters_modal.componentInstance.filterType = type;
    if (this.selectedSortOptions) {
      this.filters_modal.componentInstance.selectedSortOptions = this.selectedSortOptions;
    }
    this.filters_modal.componentInstance.sortByOptions = this.sortByDropDown;
    this.filters_modal.componentInstance.customFiltersArray = this.customFiltersArray;
    this.filters_modal.componentInstance.is_filter_for = 'contact';
    this.filters_modal.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.search_global = '';
        if (receivedEntry.from === 'sort') {
          if (receivedEntry.type === 'save') {
            this.selectedSortOptions = receivedEntry.data.sorting;
          } else {
            this.selectedSortOptions = null;
          }
        } else {
          if (receivedEntry.type === 'save') {
            this.column_filters = receivedEntry.data.filter;
          } else {
            this.column_filters = [];
          }
        }
        if (apiCall === 'add_to_list') {
          this.service.updateGroup({ id: this.selected_group.id, list_filter: receivedEntry.data.filter }).subscribe(() => {
            if (this.activeId === 3) this.refresh_list = true;
            else this.refresh_list = false;
            this.modalService.dismissAll();
            this.clearSelection();
          });
        } else {
          this.updateFilterState();
          const payload = {
            filter: receivedEntry.data.filter,
            sort_by: receivedEntry.data?.sorting?.sort_by,
            sort_order: receivedEntry.data?.sorting?.sort_order,
            object_type: this.object_type
          };
          this.pageIndex = 0;
          this.currentPage = 1;
          // this.clearSelection();
          this.filterFormSubmit(payload, 1);
        }
      }
    });
  }

  // pickColor() {
  //   this.initial_color_code_index += 1;
  //   if (this.initial_color_code_index === 10) this.initial_color_code_index = 0;
  //   this.picked_out_color = this.channel_color_codes[this.initial_color_code_index]?.color;
  //   console.log(this.initial_color_code_index, this.picked_out_color);

  //   return this.picked_out_color;
  // }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }

  tabChange(ev: any) {
    this.currentPage = 1;
    this.pageIndex = 0;
    this.filter = this.select_type[0].value;
    this.selected_filter_value = '';
    this.selected_filter = '';
    this.search_global = '';
    if (this.selectedSortOptions || this.column_filters.length > 0) {
      this.selectedSortOptions = null;
      this.column_filters = [];
      // this.filterFormSubmit(payload, 1, 'ACTIVE'); Optional Call
    }
    this.tableSkeletonLoader = true;
    this.activeId = ev.nextId;
    this.contactListArray = [];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { contact_activeId: this.activeId },
      queryParamsHandling: 'merge' // merge to keep existing query params
    });
    this.updateFilterState();
    if (this.activeId === 2) {
      // this.getInactiveContacts();
    } else if (this.activeId === 1) {
      // this.getContactsList('clear', 1);
      this.search_global = '';
    }
  }

  getInactiveContacts() {
    const tableColoums = this.filtersLables.filter((elem: any) => elem.checked);
    tableColoums.pop();
    this.inActivetableColumns = tableColoums;

    this.service.getInactiveContacts(this.currentPage, this.pageEvent?.pageSize || 20, this.object_type, this.companyId).subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.contactsObj = resp;
      this.contactListArray = resp.data;
      if (this.contactListArray.length === 0) {
        this.display_no_data = true;
      } else {
        this.display_no_data = false;
      }
    }, () => {
      this.tableSkeletonLoader = false;
    });
  }

  confirmForInactivation() {
    this.confirmationMsz.customDeleteView({ title: 'Are You Sure ?', text: 'Selected contact will be moved to In active', button: 'Yes' }).then((result) => {
      if (result.value) {
        this.inActivateTheContact();
      }
    });
  }

  inActivateTheContact() {
    const selectedContacts: any[] = [];
    this.selection.selected.filter((item: any) => {
      selectedContacts.push(item.id);
    });
    this.service.InactivatetheContact({ contacts: selectedContacts }).subscribe(() => {
      this.getContactsList('pgn', this.currentPage, this.pageEvent?.pageSize);
    });
  }

  makeaCall(selected_contact: any) {
    const body = {
      to: selected_contact.ccode + selected_contact.phone_number,
      from: this.fromNumber,
      contact_id: selected_contact.id,
      contact_name: selected_contact.first_name + ' ' + selected_contact.last_name
    };
    this.twilioService.makeCall(body);
  }

  getFromNumbers() {
    this.twilioService.getTwilioFromNumbers().subscribe((resp: any) => {
      this.fromNumber = resp.phoneNumbers[0];
    });
  }

  getRolePermissions(item: any, permission: any) {
    return this.authService?.rolefeatureShow(this.show_type+'-'+this.loggedInUser.data.organisation, permission);
  }

  openCustomFieldModal() {
    // Dynamically create the YComponent
    if (this.container) this.container.clear();
    const yComponentFactory = this.container.createComponent(CustomFieldsComponent);
    this.customFieldsComponentRef = yComponentFactory;

    // Call the modal open function in YComponent
    const body = {
      type: 'Record',
      status: true,
      object_type: this.object_type
    };
    setTimeout(() => {
      this.customFieldsComponentRef.instance.openCustomFieldModal(body);
    }, 1000);

    this.customFieldsComponentRef.instance.closeModalEvent.subscribe(() => {
      this.getColoumsList();
    });
  }

  activateContact(contact_id: string){
    this.service.activateInactivatedContact(contact_id).subscribe(()=>{
      this.getContactsList('pgn', this.currentPage, this.pageEvent?.pageSize);
    });
  }
}

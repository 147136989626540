<div class='demo-app'>

  <div class='demo-app-sidebar'>
    <div class='demo-app-sidebar-section d-none'>
      <button class="btn btn-primary w-100 border-radius-50 bg-teal" type="submit">Create</button>
    </div>
    <div class="demo-app-sidebar-section my-calender d-none">
      <mat-card class="demo-inline-calendar-card">
        <mat-calendar [(selected)]="selected"></mat-calendar>
      </mat-card>
    </div>
    <div class='demo-app-sidebar-section'>
      <h2 class="text-teal">Upcoming Events({{upcomingEvents.length}})</h2>
      <ul style="max-height: 75vh;overflow-y: scroll;">
        <li *ngFor='let event of upcomingEvents'>
          <span class="text-light font-size-13">{{event.startStr | dateConversion}} {{event.startStr | date:
            'shortTime'}}</span> <br>
          <i class="font-size-15 fw-600">{{event.title}}</i>
        </li>
      </ul>
    </div>
    <div class='demo-app-sidebar-section pt-2 my-calender d-none'>
      <div class="row">
        <div class="col-md-9">
          <h2 class="text-teal">Categories</h2>
        </div>
        <div class="col-md-3">
          <button class="round-btn font-size-13 ms-3">
            <mat-icon class="text-light">add</mat-icon>
          </button>
        </div>
      </div>
      <label>
        <input type='checkbox' [checked]='calendarVisible' (change)='handleCalendarToggle()' />
        Work
      </label>
      <label>
        <input type='checkbox' [checked]='calendarOptions.weekends' (change)='handleWeekendsToggle()' />
        Personal
      </label>
      <label>
        <input type='checkbox' [checked]='calendarOptions.weekends' (change)='handleWeekendsToggle()' />
        Important
      </label>
      <label>
        <input type='checkbox' [checked]='calendarOptions.weekends' (change)='handleWeekendsToggle()' />
        Travel
      </label>
      <label>
        <input type='checkbox' [checked]='calendarOptions.weekends' (change)='handleWeekendsToggle()' />
        Friends
      </label>
    </div>
    <div class="demo-app-sidebar-section d-none">
      <div class="d-flex align-items-center justify-content-center row">
        <div class="col-md-4">
          <img src="../../../assets/images/settings.png" alt="">
        </div>
        <div class="col-md-4">
          <img src="../../../assets/images/box.png" alt="">
        </div>
        <div class="col-md-4">
          <img src="../../../assets/images/book.png" alt="">
        </div>
      </div>
    </div>
  </div>

  <div class='demo-app-main'>
    <div class="d-flex justify-content-end mb-2">
      <button type="button" class="btn btn-primary" (click)="openIntegartionModel(integrationModal)" *ngIf="!calendarConnection">Connect</button>
      <button type="button" class="btn btn-primary" (click)="disconnectCalendar()" *ngIf="calendarConnection">Disconnect</button>
    </div>
    <full-calendar *ngIf='calendarVisible' [options]='calendarOptions' [events]="eventsPromise | async">
      <ng-template #eventContent let-arg>
        <b>{{ arg.timeText }}</b>
        <i>{{ arg.event.title }}</i>
      </ng-template>
    </full-calendar>
  </div>

</div>
<div class="modal mt-5" [id]="'myModal'+showModal ? 'inactive-modal' : 'myModal'"
  [style.display]="showModal ? 'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
      <!-- Modal Header -->
      <div class="modal-header">
        <h6 class="modal-title">New Event</h6>
        <button type="button" class="close btn-close" data-dismiss="modal" (click)="hide()"></button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <mat-form-field appearance="outline" class="w-100" style="outline: none;">
                <mat-label>Title</mat-label>
                <input matInput (cdkAutofill)="firstNameAutofilled = $event.isAutofilled" />
                <mat-hint *ngIf="firstNameAutofilled">Autofilled!</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-md-12 col-sm-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Company</mat-label>
                <mat-select>
                  <mat-option value="option1">Option 1</mat-option>
                  <mat-option value="option2" disabled>Option 2 (disabled)</mat-option>
                  <mat-option value="option3">Option 3</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6 col-sm-12">
              <mat-form-field appearance="outline" class="example-form-field w-100" style="outline: none;">
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="datepicker">
                <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker>
                  <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>

            </div>
            <div class="col-sm-12 col-md-6">
              <mat-form-field appearance="outline" class="example-form-field w-100" style="outline: none;">
                <mat-label>End Date</mat-label>
                <input matInput [matDatepicker]="enddatepicker">
                <mat-datepicker-toggle matIconSuffix [for]="enddatepicker"></mat-datepicker-toggle>
                <mat-datepicker #enddatepicker>
                  <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-12 col-sm-12">
              <mat-form-field appearance="outline" class="example-full-width w-100">
                <mat-label>Event Details</mat-label>
                <textarea rows="4" matInput placeholder="Ex. It makes me feel..."></textarea>
              </mat-form-field>
            </div>
          </div>
        </form>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <div class="d-flex">
          <button class="btn btn-light me-2" type="submit" (click)="hide()">
            Cancel
          </button>
          <button class="btn btn-primary" type="submit">Save</button>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="modal mt-5" id="myModal" [style.display]="eventModal ? 'block' : 'none'">
  <div class="modal-dialog ">
    <div class="modal-content" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
      <div class="adding-arrow">
      </div>
      <!-- Modal Header -->
      <div class="modal-header">
        <h6 class="modal-title">{{selectedEvent?.type}}</h6>
        <button type="button" class="close btn-close" data-dismiss="modal" (click)="hide()"></button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <h5 class="fw-600">{{selectedEvent?.title}}</h5>
              <h6 class="d-flex align-items-center"><mat-icon
                  class="mat-icon notranslate text-primary font-size-20">date_range</mat-icon> {{selectedEvent?.date |
                dateConversion}} {{selectedEvent?.start_time}} - {{selectedEvent?.end_time }}</h6>
              <h6 class="d-flex align-items-center"><mat-icon
                  class="font-size-20">person</mat-icon>{{selectedEvent?.name}}</h6>
              <h6 class="d-flex align-items-center"><mat-icon
                  class="font-size-20">mail</mat-icon>{{selectedEvent?.email}}</h6>
              <h6 *ngIf="selectedEvent?.guest_email?.length!==0" class="d-flex align-items-center">
                <span class="fw-600 font-size-15">Guest Mails: &nbsp;</span>
                <span *ngFor="let guest of selectedEvent?.guest_email">{{guest.mail}} </span>
              </h6>
              <h6 *ngIf="selectedEvent?.staff" class="d-flex align-items-center"><mat-icon
                  class="font-size-20 mt-1">assignment_ind</mat-icon>
                {{selectedEvent?.staff}}</h6>
              <h6 class="d-flex align-items-start col-12 url-sec" *ngIf="selectedEvent?.meeting_link">
                <span class="me-2">Meeting link :</span>
                <a class="col-8" href="{{selectedEvent?.meeting_link}}"
                  target="_blank">{{selectedEvent?.meeting_link}}</a>
              </h6>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #integrationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-primary">Add Email</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="recommended-cards d-flex flex-wrap align-items-center w-100 mt-4 mb-4">
      <div class="card setup-cards me-4">
        <div class="card-body">
          <div class="d-flex align-items-center cursor" (click)="connect('Gmail')">
            <img src="../../../../assets/images/google-gmail.png" alt="gmail_icon" width="26px" height="24px"
              class="me-2">
            Gmail
          </div>
        </div>
      </div>
      <div class="card setup-cards">
        <div class="card-body">
          <div class="d-flex align-items-center cursor" (click)="connect('Outlook')">
            <img src="../../../../assets/images/outlook-calendar.svg" alt="outlook_icon" width="28px" height="24px"
              class="me-2">
            Outlook
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>